import { injectable } from 'inversify';
import { LegalEntitiesShareholder } from '~/types/legal-entities/shareholder';

import { IPlaceholderDataService } from '../common/reportPlaceholderService.interfaces';

@injectable()
export class LegalEntitiesShareholderPlaceholderDataService implements IPlaceholderDataService<LegalEntitiesShareholder> {
  getData() {
    return {
      Fullname: '',
      VotingSharesPercent: 0,
      CapitalSharesPercent: 0,
      ShareholderType: '',
      EndDate: '',
    };
  }
}
