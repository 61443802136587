import { injectable, inject } from 'inversify';

import { IPrimitiveTypesNormalizationService, IPrimitiveTypesNormalizationServiceKey } from '~/services/normalization/common/primitiveTypesNormalizationService.interfaces';
import { IReportMainEntityFilePlaceholderServiceKey, IPlaceholderDataService } from '~/services/placeholder/common/reportPlaceholderService.interfaces';
import { ReportMainEntityFile } from '~/types/common/reportMainEntityFile';
import { LegalEntitiesTrademark } from '~/types/legal-entities/trademark';
import { INormalizationService, IReportMainEntityFileNormalizationServiceKey } from '../common/reportNormalizationService.interfaces';

@injectable()
export class LegalEntitiesTrademarkNormalizationService implements INormalizationService<LegalEntitiesTrademark> {
  constructor(
    @inject(IReportMainEntityFileNormalizationServiceKey as symbol) private reportMainEntityFileNormalizationService: INormalizationService<ReportMainEntityFile>,
    @inject(IReportMainEntityFilePlaceholderServiceKey as symbol) private reportMainEntityFilePlaceholderService: IPlaceholderDataService<ReportMainEntityFile>,

    @inject(IPrimitiveTypesNormalizationServiceKey as symbol) private primitiveTypesNormalizationService: IPrimitiveTypesNormalizationService,
  ) {}

  normalize(data: any, root: string): LegalEntitiesTrademark | undefined {
    const {
      normalizeString,
      normalizeOptionalString,
      normalizeObject,
      normalizeOptional
    } = this.primitiveTypesNormalizationService;

    return normalizeObject(data, (data) => ({
      Name: normalizeString(data.Name, `${root}.Name`),
      DateEnd: normalizeOptionalString(data.DateEnd, `${root}.DateEnd`),
      DocNumber: normalizeString(data.DocNumber, `${root}.DocNumber`),
      Image: normalizeOptional(data.Image, (data, field) => this.reportMainEntityFileNormalizationService.normalize(data, field), `${root}.Image`),
    }), root);
  }
}
