import { injectable } from 'inversify';
import { ReportSocialNetworkLifePosition } from '~/types/common/reportSocialNetworkLifePosition';
import { IPlaceholderDataService } from './reportPlaceholderService.interfaces';

@injectable()
export class ReportSocialNetworkLifePositionPlaceholderService implements IPlaceholderDataService<ReportSocialNetworkLifePosition> {
  getData(): ReportSocialNetworkLifePosition {
    return {
      PoliticalPreferences: '',
      WorldView: '',
      MainThingInLife: '',
      MainThingInPeople: '',
      SmokingView: '',
      AlcoholView: '',
      Inspires: '',
    };
  }
}
