import { Direction } from '~/types/field-list/direction';

export const VEHICLES_STANDARD_FIELD_LIST_OPTIONS = {
  fieldsWidth: '100%',
  fieldsDirection: 'divider-row' as Direction,
  fieldsValueWidth: '350px',
  rowGap: '16px',
  isBeginningDivider: true
};

export const VEHICLES_RELATION_DEFAULT = {
  SourceType: 0,
  RegDate: '',
  DeregistrationDate: '',
  Name: '',
  Birthday: '',
  Inn: '',
  Passport: '',
  PhoneNumber: '',
  Address: '',
  Sts: '',
  Pts: '',
  PtsDepartmentName: '',
  PtsIssuedDate: '',
};
