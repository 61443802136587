import { injectable } from 'inversify';
import { IndividualAccrual } from '~/types/individual/accrual';
import { IPlaceholderDataService } from '../common/reportPlaceholderService.interfaces';

@injectable()
export class IndividualAccrualPlaceholderService implements IPlaceholderDataService<IndividualAccrual> {
  getData(): IndividualAccrual {
    return {
      Description: '',
      StartDate: '',
      DebtSum: 0,
      DebtType: '',
    };
  }
}
