import { inject, injectable } from 'inversify';
import { IEbitTableItemConfigService } from '~/modules/legalEntities/financialAnalytics/legalEntitiesFinancialAnalysisShort/services/legalEntitiesFinancialAnalysisShortTableItemConfigService.interfaces';
import { TableItem } from '~/types/tableItem';
import { LegalEntitiesReportFinancialIndicatorWithDynamicDTO } from '~/types/dto/legalEntities/reportFinancialIndicatorWithDynamicDTO';
import { FINANCIAL_ANALYSIS_SHORT_TITLES } from '~/modules/legalEntities/financialAnalytics/common/configuration/constants/financialAnalysisShortTitles';
import {
  IInterestPeriodTableIDataService,
  IInterestPeriodTableIDataServiceKey
} from '~/modules/legalEntities/financialAnalytics/common/services/interestPeriodTableIDataService.interfaces';
import {
  IFinancialAnalysisCommonTitlesService,
  IFinancialAnalysisCommonTitlesServiceKey
} from '~/modules/legalEntities/financialAnalytics/common/services/financialAnalysisCommonTitlesService.interfaces';

@injectable()
export class EbitTableItemConfigService implements IEbitTableItemConfigService {
  constructor(
    @inject(IInterestPeriodTableIDataServiceKey as symbol) private interestPeriodTableIDataService: IInterestPeriodTableIDataService,
    @inject(IFinancialAnalysisCommonTitlesServiceKey as symbol) private financialAnalysisCommonTitlesService: IFinancialAnalysisCommonTitlesService
  ) {}
  getItem(initData: LegalEntitiesReportFinancialIndicatorWithDynamicDTO): TableItem {
    return {
      ...this.interestPeriodTableIDataService.getDataItem(initData, this.getColor),
      Indicator: this.financialAnalysisCommonTitlesService.getTitle(FINANCIAL_ANALYSIS_SHORT_TITLES.Ebit, [initData.CurrentYear, initData.LastYear, initData.TwoYearsAgo])
    };
  }

  private getColor(value: number): string {
    if (value > 0) return 'green';
    if (value < 0) return 'red';
    return 'black';
  }
}
