import { injectable, inject } from 'inversify';
import { IndividualPhone } from '~/types/individual/phone';
import { IPrimitiveTypesNormalizationServiceKey, IPrimitiveTypesNormalizationService } from '../common/primitiveTypesNormalizationService.interfaces';
import {
  INormalizationService,
  IReportMentionNormalizationServiceKey,
  IReportMessengerInfoNormalizationServiceKey
} from '../common/reportNormalizationService.interfaces';
import { ReportMessengerInfo } from '~/types/common/reportMessengerInfo';
import { ReportMention } from '~/types/common/reportMention';

@injectable()
export class IndividualPhoneNormalizationService implements INormalizationService<IndividualPhone> {
  constructor(
    @inject(IReportMessengerInfoNormalizationServiceKey as symbol) private messengerInfoNormalizationService: INormalizationService<ReportMessengerInfo>,
    @inject(IReportMentionNormalizationServiceKey as symbol) private mentionNormalizationService: INormalizationService<ReportMention>,

    @inject(IPrimitiveTypesNormalizationServiceKey as symbol) private primitiveTypesNormalizationService: IPrimitiveTypesNormalizationService,
  ) {}

  normalize(data: any, root: string): IndividualPhone | undefined {
    const {
      normalizeObject,
      normalizeNumber,
      normalizeArray,
      normalizeString,
      normalizeBoolean,
      normalizeOptionalString
    } = this.primitiveTypesNormalizationService;

    return normalizeObject(data, (data) => ({
      PhoneType: normalizeString(data.PhoneType, `${root}.PhoneType`),
      Value: normalizeString(data.Value, `${root}.Value`),
      Country: normalizeOptionalString(data.Country, `${root}.Country`),
      Provider: normalizeOptionalString(data.Provider, `${root}.Provider`),
      TimeZone: normalizeOptionalString(data.TimeZone, `${root}.TimeZone`),
      IsVerified: normalizeBoolean(data.IsVerified, `${root}.IsVerified`),
      Messengers: normalizeArray(data.Messengers, (item, field) => this.messengerInfoNormalizationService.normalize(item, field), `${root}.Messengers`),
      ChangeDate: normalizeString(data.ChangeDate, `${root}.ChangeDate`),
      DeviceModel: normalizeString(data.DeviceModel, `${root}.DeviceModel`),
      VerificationCode: normalizeNumber(data.VerificationCode, `${root}.VerificationCode`),
      Mentions: normalizeArray(data.Mentions, (item, field) => this.mentionNormalizationService.normalize(item, field), `${root}.Mentions`),
    }), root);
  }
}
