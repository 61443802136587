import { injectable } from 'inversify';

import { LegalEntitiesCompanyManagementCompanies } from '~/types/legal-entities/companyManagementCompanies';
import { IPlaceholderDataService } from '../common/reportPlaceholderService.interfaces';

@injectable()
export class LegalEntitiesCompanyManagementCompaniesPlaceholderDataService implements IPlaceholderDataService<LegalEntitiesCompanyManagementCompanies> {
  getData(): LegalEntitiesCompanyManagementCompanies {
    return {
      Name: '',
      Inn: '',
      Ogrn: '',
      Date: '',
      FirstDate: '',
      IsInaccuracy: false,
      InaccuracyDate: '',
    };
  }
}
