import { injectable } from 'inversify';

import { LegalEntitiesTotalScore } from '~/types/legal-entities/totalScore';
import { IPlaceholderDataService } from '../common/reportPlaceholderService.interfaces';

@injectable()
export class LegalEntitiesTotalScorePlaceholderDataService implements IPlaceholderDataService<LegalEntitiesTotalScore> {
  getData(): LegalEntitiesTotalScore {
    return {
      IsExists: false,
      TotalScore: '',
      TotalScoreConclusion: '',
      Year: 0
    };
  }
}
