import { injectable } from 'inversify';
import { IReportSocialNetworkJobAdapterService } from '~/services/adapters/common/reportAdapterService.interfeces';
import { ReportSocialNetworkJob } from '~/types/common/reportSocialNetworkJob';
import { ReportSocialNetworkJobDTO } from '~/types/dto/common/reportSocialNetworkJobDTO';
import { StringUtils } from '~/utils/stringUtils';
import { DateUtils } from '~/utils/date';

@injectable()
export class ReportSocialNetworkJobAdapterService implements IReportSocialNetworkJobAdapterService {
  getData(initData: ReportSocialNetworkJob[]): ReportSocialNetworkJobDTO[] {
    return (initData ?? []).map((item) => ({
      Type: item.Type,
      SocialNetworkId: StringUtils.quoteReplacement(item.SocialNetworkId),
      City: StringUtils.quoteReplacement(item.City ?? ''),
      CompanyName: StringUtils.quoteReplacement(item.CompanyName),
      Position: StringUtils.quoteReplacement(item.Position ?? ''),
      StartDate: DateUtils.formatDate(item.StartDate),
      EndDate: DateUtils.formatDate(item.EndDate),
      IsWorkingNow: item.IsWorkingNow ?? false
    }));
  }
}
