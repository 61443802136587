import { IContainerBinder } from '~/configuration/inversify/containerAccessor.interface';
import { IManagementCompaniesMergeServiceKey } from '~/modules/legalEntities/common/servises/managementCompaniesMergeService.interfaces';
import { ManagementCompaniesMergeService } from '~/modules/legalEntities/common/servises/managementCompaniesMergeService';
import { ICompanyHeadFlMergeServiceKey } from '~/modules/legalEntities/common/servises/companyHeadFlMergeService.interfaces';
import { CompanyHeadFlMergeService } from '~/modules/legalEntities/common/servises/companyHeadFlMergeService';

export function bindLegalEntitiesMergeService(bind: IContainerBinder) {
  bind(IManagementCompaniesMergeServiceKey).to(ManagementCompaniesMergeService);
  bind(ICompanyHeadFlMergeServiceKey).to(CompanyHeadFlMergeService);
}
