import { inject, injectable } from 'inversify';

import { ReportUrl } from '~/types/common/reportUrl';
import { LegalEntitiesCommonInfo } from '~/types/legal-entities/commonInfo';
import { IReportUrlPlaceholderServiceKey, IPlaceholderDataService } from '../common/reportPlaceholderService.interfaces';

@injectable()
export class LegalEntitiesCommonPlaceholderDataService implements IPlaceholderDataService<LegalEntitiesCommonInfo> {
  constructor(
    @inject(IReportUrlPlaceholderServiceKey as symbol) private reportUrlPlaceholderService: IPlaceholderDataService<ReportUrl>
  ) {}

  getData(): LegalEntitiesCommonInfo {
    return {
      ReportGenerationDate: '',
      ReportUrl: this.reportUrlPlaceholderService.getData(),
      ActivityTypesUrl: this.reportUrlPlaceholderService.getData(),
      DirectorCompaniesUrl: this.reportUrlPlaceholderService.getData(),
      IsCompanyIp: false,
      CompanyInn: '',
      CompanyOgrn: '',
      CompanyRpn: '',
      CompanyOkpo: '',
      CompanyKpp: '',
      CompanyOkfcVal: '',
      CompanyOkfcDesc: '',
      CompanyOkopfVal: '',
      CompanyOkopfDesc: '',
      CompanyOkoguVal: '',
      CompanyOkoguDesc: '',
      CompanyOkato: '',
      CompanyOkatoDesc: '',
      CompanyOktmoVal: '',
      CompanyOktmoDesc: '',
      CompanyShortName: '',
      CompanyFullName: '',
      CompanyType: '',
      PrincipalActivityOkvedCode: '',
      OtherCompaniesWithDirector: 0,
      Bic: '',
      Swift: '',
      BankRegistrationNumber: '',
      BankCorrespondentAccount: '',
      BankOpfType: '',
      BankAddressDataQc: ''
    };
  }
}
