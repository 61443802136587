import { get } from 'lodash-es';
import { GET_NUMBER_FROM_SUBJECT_OF_EXECUTION_REGEX } from '~/regex/getNumberFromSubjectOfExecution';
import { NumberUtils } from '~/utils/number';

const DEBT = 'Задолженность';
const PERFORMANCE_FEE = 'Исполнительский сбор';
const COMMON_SUM = 'Общая сумма задолженности';
const REPLACED_COMMON_SUM = 'Общая сумма\nзадолженности';

export module EnforcementProceedingUtils {
  export function parseSubjectOfExecution(inputString: string): Array<string> {
    if (inputString.includes(DEBT) && inputString.includes(PERFORMANCE_FEE)) {
      const subjectOfExecution = inputString.split('<br>').filter(item => item);
      const numbersFromSubjectOfExecution = subjectOfExecution
        .map(item => NumberUtils.formatNumberDigits(
          NumberUtils.formattedToNumber(
            get(item.match(GET_NUMBER_FROM_SUBJECT_OF_EXECUTION_REGEX), 0, '')
          )
        ));

      return [`${DEBT}: ${get(numbersFromSubjectOfExecution, 0, 0)} \u{20BD}`,
        `${PERFORMANCE_FEE}: ${get(numbersFromSubjectOfExecution, 1, 0)} \u{20BD}`];
    }

    if (inputString.includes('<br>'))
      return inputString.replaceAll(COMMON_SUM, REPLACED_COMMON_SUM).split('<br>');

    return inputString.replaceAll(COMMON_SUM, REPLACED_COMMON_SUM).split('  ');
  }
}
