import { injectable } from 'inversify';

import { LegalEntitiesTaxMode } from '~/types/legal-entities/taxMode';
import { IPlaceholderDataService } from '../common/reportPlaceholderService.interfaces';

@injectable()
export class LegalEntitiesTaxModePlaceholderService implements IPlaceholderDataService<LegalEntitiesTaxMode> {
  getData(): LegalEntitiesTaxMode {
    return {
      Name: ''
    };
  }
}
