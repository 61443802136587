import { injectable } from 'inversify';
import { get } from 'lodash-es';
import { IDebtEbitTableItemConfigService } from '~/modules/legalEntities/financialAnalytics/legalEntitiesFinancialAnalysisShort/services/legalEntitiesFinancialAnalysisShortTableItemConfigService.interfaces';
import { TableItem } from '~/types/tableItem';
import { LegalEntitiesReportFinancialIndicatorWithConclusionsDTO } from '~/types/dto/legalEntities/reportFinancialIndicatorWithConclusionsDTO';
import { NumberUtils } from '~/utils/number';
import { FINANCIAL_ANALYSIS_SHORT_TITLES } from '~/modules/legalEntities/financialAnalytics/common/configuration/constants/financialAnalysisShortTitles';

@injectable()
export class DebtEbitTableItemConfigService implements IDebtEbitTableItemConfigService {
  getItem(initData: LegalEntitiesReportFinancialIndicatorWithConclusionsDTO): TableItem {
    return ({
      TwoYearsAgo: { value: NumberUtils.formatNumberDigits(initData.TwoYearsAgo, 2, '—'), color: 'black' },
      CurrentYear: { value: NumberUtils.formatNumberDigits(initData.CurrentYear, 2, '—'), color: 'black' },
      LastYear: { value: NumberUtils.formatNumberDigits(initData.LastYear, 2, '—'), color: 'black' },
      TwoYearsAgoToLastYear: { value: '—', color: 'black' },
      LastYearToCurrentYear: { value: '—', color: 'black' },
      Conclusions: get(initData.Conclusions, 0, '—'),
      Indicator: FINANCIAL_ANALYSIS_SHORT_TITLES.DebtEbit
    });
  }
}
