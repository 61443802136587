import { injectable, inject } from 'inversify';
import { ReportMainEntityFile } from '~/types/common/reportMainEntityFile';
import { IndividualWantedMvd } from '~/types/individual/wantedMvd';
import { IPlaceholderDataService, IReportMainEntityFilePlaceholderServiceKey } from '../common/reportPlaceholderService.interfaces';

@injectable()
export class IndividualWantedMvdPlaceholderService implements IPlaceholderDataService<IndividualWantedMvd> {
  constructor(
    @inject(IReportMainEntityFilePlaceholderServiceKey as symbol) private reportMainEntityFilePlaceholderService: IPlaceholderDataService<ReportMainEntityFile>,
  ) {}

  getData(): IndividualWantedMvd {
    return {
      Photo: this.reportMainEntityFilePlaceholderService.getData(),
      Region: '',
      Reason: '',
      SettingData: '',
      ContactInfo: '',
    };
  }
}
