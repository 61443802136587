import { injectable, inject } from 'inversify';
import { IPlaceholderDataService } from '~/services/placeholder/common/reportPlaceholderService.interfaces';
import { IIndividualSpecialSignsPlaceholderServiceKey } from '~/services/placeholder/individual/individualPlaceholderDataService.interfaces';
import { IndividualCommonNegativeInfo } from '~/types/individual/commonNegativeInfo';
import { IndividualSpecialSigns } from '~/types/individual/specialSigns';
import { IPrimitiveTypesNormalizationServiceKey, IPrimitiveTypesNormalizationService } from '../common/primitiveTypesNormalizationService.interfaces';
import { INormalizationService, IStringArrayNormalizationServiceKey } from '../common/reportNormalizationService.interfaces';
import { IIndividualSpecialSignsNormalizationServiceKey } from './individualNormalizationService.interfaces';

@injectable()
export class IndividualCommonNegativeInfoNormalizationService implements INormalizationService<IndividualCommonNegativeInfo> {
  constructor(
    @inject(IIndividualSpecialSignsNormalizationServiceKey as symbol) private specialSignsNormalizationService: INormalizationService<IndividualSpecialSigns>,

    @inject(IIndividualSpecialSignsPlaceholderServiceKey as symbol) private specialSignsPlaceholderService: IPlaceholderDataService<IndividualSpecialSigns>,

    @inject(IPrimitiveTypesNormalizationServiceKey as symbol) private primitiveTypesNormalizationService: IPrimitiveTypesNormalizationService,
    @inject(IStringArrayNormalizationServiceKey as symbol) private stringArrayNormalizationService: INormalizationService<string>,
  ) {}

  normalize(data: any, root: string): IndividualCommonNegativeInfo | undefined {
    const {
      normalizeObject,
      normalizeBoolean,
      normalizeOptionalBoolean,
      normalizeArray,
    } = this.primitiveTypesNormalizationService;

    return normalizeObject(data, (data) => ({
      HasPrejudicedAdministrativePenalties: normalizeOptionalBoolean(data.HasPrejudicedAdministrativePenalties, `${root}.HasPrejudicedAdministrativePenalties`),
      HasGunsNegative: normalizeOptionalBoolean(data.HasGunsNegative, `${root}.HasGunsNegative`),
      SpecialSigns: this.specialSignsNormalizationService.normalize(data.SpecialSigns, `${root}.SpecialSigns`) ?? this.specialSignsPlaceholderService.getData(),
      IncidentsInfo: normalizeArray(data.IncidentsInfo, (item, field) => this.stringArrayNormalizationService.normalize(item, field), `${root}.IncidentsInfo`),
      ArchiveEnforcementProceedings: normalizeArray(data.ArchiveEnforcementProceedings, (item, field) => this.stringArrayNormalizationService.normalize(item, field), `${root}.ArchiveEnforcementProceedings`),
      OuterBlackLists: normalizeArray(data.OuterBlackLists, (item, field) => this.stringArrayNormalizationService.normalize(item, field), `${root}.OuterBlackLists`),
      SpecialAccounting: normalizeArray(data.SpecialAccounting, (item, field) => this.stringArrayNormalizationService.normalize(item, field), `${root}.SpecialAccounting`),
      InInnerBlackList: normalizeBoolean(data.InInnerBlackList, `${root}.InInnerBlackList`),
      InterestInNegativeContent: normalizeArray(data.InterestInNegativeContent, (item, field) => this.stringArrayNormalizationService.normalize(item, field), `${root}.InterestInNegativeContent`),
      OtherNegativeRecords: normalizeArray(data.OtherNegativeRecords, (item, field) => this.stringArrayNormalizationService.normalize(item, field), `${root}.OtherNegativeRecords`),
    }), root);
  }
}
