import { injectable } from 'inversify';
import { ReportMessengerInfo } from '~/types/common/reportMessengerInfo';
import { IPlaceholderDataService } from './reportPlaceholderService.interfaces';

@injectable()
export class ReportMessengerInfoPlaceholderService implements IPlaceholderDataService<ReportMessengerInfo> {
  getData(): ReportMessengerInfo {
    return {
      Type: 0,
      MessengerId: '',
      Status: '',
      OnlineDate: '',
      Photos: []
    };
  }
}
