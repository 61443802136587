import { injectable, inject } from 'inversify';
import { IDataBlobReportOriginalInputFileInfoPlaceholderServiceKey } from '~/services/placeholder/blob/dataBlobPlaceholderService.interfaces';
import { IPlaceholderDataService } from '~/services/placeholder/common/reportPlaceholderService.interfaces';
import { DataBlobReportOriginalInput } from '~/types/blobs/data-blob-report-original-input';
import { DataBlobReportOriginalInputFileInfo } from '~/types/blobs/data-blob-report-original-input-file-info';
import { IPrimitiveTypesNormalizationServiceKey, IPrimitiveTypesNormalizationService } from '../common/primitiveTypesNormalizationService.interfaces';
import { INormalizationService } from '../common/reportNormalizationService.interfaces';
import { IDataBlobReportOriginalInputFileInfoNormalizationServiceKey } from './dataBlobNormalizationService.interfaces';

@injectable()
export class DataBlobReportOriginalInputNormalizationService implements INormalizationService<DataBlobReportOriginalInput> {
  constructor(
    @inject(IDataBlobReportOriginalInputFileInfoNormalizationServiceKey as symbol) private dataBlobReportOriginalInputFileInfoNormalizationService: INormalizationService<DataBlobReportOriginalInputFileInfo>,

    @inject(IDataBlobReportOriginalInputFileInfoPlaceholderServiceKey as symbol) private dataBlobReportOriginalInputFileInfoPlaceholderService: IPlaceholderDataService<DataBlobReportOriginalInputFileInfo>,

    @inject(IPrimitiveTypesNormalizationServiceKey as symbol) private primitiveTypesNormalizationService: IPrimitiveTypesNormalizationService,
  ) {}

  normalize(data: any, root: string): DataBlobReportOriginalInput | undefined {
    const {
      normalizeObject,
      normalizeString,
      normalizeOptionalInteger,
      normalizeOptionalString
    } = this.primitiveTypesNormalizationService;

    return normalizeObject(data, (data) => ({
      Fio: normalizeOptionalString(data.Fio, `${root}.Fio`),
      Birthday: normalizeOptionalString(data.Birthday, `${root}.Birthday`),
      Inn: normalizeOptionalString(data.Inn, `${root}.Inn`),
      Passport: normalizeString(data.Passport, `${root}.Passport`),
      Email: normalizeString(data.Email, `${root}.Email`),
      SocialId: normalizeString(data.SocialId, `${root}.SocialId`),
      Ogrn: normalizeString(data.Ogrn, `${root}.Ogrn`),
      PhoneNumber: normalizeString(data.PhoneNumber, `${root}.PhoneNumber`),
      CarNumber: normalizeString(data.CarNumber, `${root}.CarNumber`),
      PhotoId: normalizeOptionalString(data.PhotoId, `${root}.PhotoId`),
      PhotoInfo: this.dataBlobReportOriginalInputFileInfoNormalizationService.normalize(data.PhotoInfo, `${root}.PhotoInfo`) ?? this.dataBlobReportOriginalInputFileInfoPlaceholderService.getData(),
      InputReportType: normalizeOptionalInteger(data.InputReportType, `${root}.InputReportType`)
    }), root);
  }
}
