import { injectable, inject } from 'inversify';
import { ReportMainEntityFile } from '~/types/common/reportMainEntityFile';
import { IndividualWantedFssp } from '~/types/individual/wantedFssp';
import { IPlaceholderDataService, IReportMainEntityFilePlaceholderServiceKey } from '../common/reportPlaceholderService.interfaces';

@injectable()
export class IndividualWantedFsspPlaceholderService implements IPlaceholderDataService<IndividualWantedFssp> {
  constructor(
    @inject(IReportMainEntityFilePlaceholderServiceKey as symbol) private reportMainEntityFilePlaceholderService: IPlaceholderDataService<ReportMainEntityFile>,
  ) {}

  getData(): IndividualWantedFssp {
    return {
      Photo: this.reportMainEntityFilePlaceholderService.getData(),
      DepartmentName: '',
      InitiatorPhone: '',
      ChangeDate: '',
    };
  }
}
