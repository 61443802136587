import { injectable } from 'inversify';
import { IndividualBusinessTrip } from '~/types/individual/businessTrip';
import { IPlaceholderDataService } from '../common/reportPlaceholderService.interfaces';

@injectable()
export class IndividualBusinessTripPlaceholderService implements IPlaceholderDataService<IndividualBusinessTrip> {
  getData(): IndividualBusinessTrip {
    return {
      StartDate: '',
      EndDate: '',
      SendingCompany: '',
      ReceivingCompany: '',
      Purpose: '',
      Place: '',
    };
  }
}
