import { injectable } from 'inversify';
import { ReportMovementTaxi } from '~/types/common/reportMovementTaxi';
import { IPlaceholderDataService } from './reportPlaceholderService.interfaces';

@injectable()
export class ReportMovementTaxiPlaceholderService implements IPlaceholderDataService<ReportMovementTaxi> {
  getData(): ReportMovementTaxi {
    return {
      MovementFrom: '',
      MovementTo: '',
      MovementDate: '',
    };
  }
}
