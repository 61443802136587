import { injectable, inject } from 'inversify';

import { ReportUrl } from '~/types/common/reportUrl';
import { LegalEntitiesFinancialState } from '~/types/legal-entities/financialState';
import { LegalEntitiesReportDataBuhForm } from '~/types/legal-entities/reportDataBuhForm';
import { IReportUrlPlaceholderServiceKey, IPlaceholderDataService } from '../common/reportPlaceholderService.interfaces';
import { ILegalEntitiesReportDataBuhFormPlaceholderDataServiceKey } from './legalEntitiesPlaceholderDataService.interfaces';

@injectable()
export class LegalEntitiesFinancialStatePlaceholderDataService implements IPlaceholderDataService<LegalEntitiesFinancialState> {
  constructor(
    @inject(ILegalEntitiesReportDataBuhFormPlaceholderDataServiceKey as symbol) private reportDataBuhFormPlaceholderDataService: IPlaceholderDataService<LegalEntitiesReportDataBuhForm>,
    @inject(IReportUrlPlaceholderServiceKey as symbol) private reportUrlPlaceholderService: IPlaceholderDataService<ReportUrl>,
  ) {}

  getData(): LegalEntitiesFinancialState {
    return {
      ListFinancialCommon: [],
      ListDebts: [],
      ListAssets: [],
      ListProfitability: [],
      ListLiquidity: [],
      ListBalanceAsset: [],
      ListBalancePassive: [],
      IncomeAndExpenses: [],
      IncomeAndExpensesOperations: [],
      CoefListAssets: [],
      CoefListDebts: [],
      CoefListLiquidity: [],
      CoefListProfitability: [],
      Coef105: [],
      CoefOther: [],
      DataFNS: [],
      FirstRecordDate: '',
      BuhFormLastYear: this.reportDataBuhFormPlaceholderDataService.getData(),
      BuhFormLast2Year: [],
      ChistieActiviByYear: {},
      RevenueByYear: {},
      ProfitByYear: {},
      FinancialCommonLinks: [],
      LiquidityLinks: [],
      FinancialExpressAllPeriodUrl: this.reportUrlPlaceholderService.getData(),
      FinancialCoefAllPeriodUrl: this.reportUrlPlaceholderService.getData(),
      CompanySize: '',
    };
  }
}
