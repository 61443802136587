import { injectable } from 'inversify';
import {
  IReportSocialNetworkHighEducationAdapterService
} from '~/services/adapters/common/reportAdapterService.interfeces';
import { ReportSocialNetworkHighEducation } from '~/types/common/reportSocialNetworkHighEducation';
import { ReportSocialNetworkHighEducationDTO } from '~/types/dto/common/reportSocialNetworkHighEducationDTO';
import { StringUtils } from '~/utils/stringUtils';
import { DateUtils } from '~/utils/date';

@injectable()
export class ReportSocialNetworkHighEducationAdapterService implements IReportSocialNetworkHighEducationAdapterService {
  getData(initData: ReportSocialNetworkHighEducation[]): ReportSocialNetworkHighEducationDTO[] {
    return (initData ?? []).map((item) => ({
      Type: item.Type,
      SocialNetworkId: StringUtils.quoteReplacement(item.SocialNetworkId),
      LastName: StringUtils.quoteReplacement(item.LastName),
      FirstName: StringUtils.quoteReplacement(item.FirstName),
      Birthday: DateUtils.formatDate(item.Birthday),
      EducationName: StringUtils.quoteReplacement(item.EducationName),
      FacultyName: StringUtils.quoteReplacement(item.FacultyName ?? ''),
      StartDate: DateUtils.formatDate(item.StartDate),
      DiplomaDate: DateUtils.formatDate(item.DiplomaDate)
    }));
  }
}
