import { injectable, inject } from 'inversify';
import { LegalEntitiesPredecessor } from '~/types/legal-entities/predecessor';
import { IPredecessorService } from './getIsFirstDateService.interfaces';
import { CheckIsFirstDateService } from './checkIsFirstDateService/checkIsFirstDateService';
import { ICheckIsFirstDateServiceKey } from './checkIsFirstDateService/checkIsFirstDateService.interface';

@injectable()
export class PredecessorService implements IPredecessorService {
  constructor(
    @inject(ICheckIsFirstDateServiceKey as symbol) private checkIsFirstDateService: CheckIsFirstDateService,
  ) {}
  getIsFirstDate(array: Array<LegalEntitiesPredecessor>): Array<(LegalEntitiesPredecessor & { isFirstDate: boolean, })> {
    return this.checkIsFirstDateService.checkIsFirstDate(
      array,
      'Inn' as keyof LegalEntitiesPredecessor,
      'OrganisationName' as keyof LegalEntitiesPredecessor,
      'Date' as keyof LegalEntitiesPredecessor
    );
  }
}
