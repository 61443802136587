import { injectable } from 'inversify';
import {
  IReportSocialNetworkSecondaryEducationAdapterService
} from '~/services/adapters/common/reportAdapterService.interfeces';
import { ReportSocialNetworkSecondaryEducation } from '~/types/common/reportSocialNetworkSecondaryEducation';
import { ReportSocialNetworkSecondaryEducationDTO } from '~/types/dto/common/reportSocialNetworkSecondaryEducationDTO';
import { StringUtils } from '~/utils/stringUtils';
import { DateUtils } from '~/utils/date';

@injectable()
export class ReportSocialNetworkSecondaryEducationAdapterService implements IReportSocialNetworkSecondaryEducationAdapterService {
  getData(initData: ReportSocialNetworkSecondaryEducation[]): ReportSocialNetworkSecondaryEducationDTO[] {
    return (initData ?? []).map((item) => ({
      Type: item.Type,
      SocialNetworkId: StringUtils.quoteReplacement(item.SocialNetworkId),
      LastName: StringUtils.quoteReplacement(item.LastName),
      FirstName: StringUtils.quoteReplacement(item.FirstName),
      Birthday: DateUtils.formatDate(item.Birthday),
      EducationName: StringUtils.quoteReplacement(item.EducationName ?? ''),
      SpecialtyName: StringUtils.quoteReplacement(item.SpecialtyName),
      StartDate: DateUtils.formatDate(item.StartDate),
      DiplomaDate: DateUtils.formatDate(item.DiplomaDate)
    }));
  }
}
