import { injectable } from 'inversify';
import {
  ILegalEntitiesReportAverageIndustryIndicatorAdapterService
} from '~/services/adapters/legalEntities/legalEntitiesAdapterService.interfaces';
import { LegalEntitiesReportAverageIndustryIndicator } from '~/types/legal-entities/reportAverageIndustryIndicator';
import {
  LegalEntitiesReportAverageIndustryIndicatorDTO
} from '~/types/dto/legalEntities/reportAverageIndustryIndicatorDTO';

@injectable()
export class LegalEntitiesReportAverageIndustryIndicatorAdapterService implements ILegalEntitiesReportAverageIndustryIndicatorAdapterService {
  getData(initData: LegalEntitiesReportAverageIndustryIndicator): LegalEntitiesReportAverageIndustryIndicatorDTO {
    return {
      CurrentYear: initData.CurrentYear ?? 0,
      AverageIndustry: initData.AverageIndustry ?? 0,
      Deviation: initData.Deviation ?? 0
    };
  }
}
