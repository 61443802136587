import { inject, injectable } from 'inversify';

import {
  ILegalEntitiesCommonPlaceholderDataServiceKey,
  ILegalEntitiesBriefInfoPlaceholderDataServiceKey,
  ILegalEntitiesFinancialStatePlaceholderDataServiceKey,
  ILegalEntitiesMarkersPlaceholderDataServiceKey,
  ILegalEntitiesFinancialAnalysisShortPlaceholderDataServiceKey,
  ILegalEntitiesFinancialAnalysisExtendedPlaceholderDataServiceKey,
  ILegalEntitiesTotalScorePlaceholderDataServiceKey, ILegalEntitiesAnalyticsIndicatorsPlaceholderDataServiceKey,
} from '~/services/placeholder/legalEntities/legalEntitiesPlaceholderDataService.interfaces';
import {
  ILegalEntitiesAnalyticsIndicatorsNormalizationServiceKey,
  ILegalEntitiesBriefInfoNormalizationServiceKey,
  ILegalEntitiesCommonNormalizationServiceKey,
  ILegalEntitiesFinancialAnalysisExtendedNormalizationServiceKey,
  ILegalEntitiesFinancialAnalysisShortNormalizationServiceKey,
  ILegalEntitiesFinancialStateNormalizationServiceKey,
  ILegalEntitiesMarkersNormalizationServiceKey,
  ILegalEntitiesTotalScoreNormalizationServiceKey,
} from './legalEntitiesNormalizationService.interfaces';
import { LegalEntitiesDataBlobData } from '~/types/blobs/legal-entities-data-blob-data';
import { LegalEntitiesBriefInfo } from '~/types/legal-entities/briefInfo';
import { LegalEntitiesCommonInfo } from '~/types/legal-entities/commonInfo';
import { LegalEntitiesFinancialAnalysisExtended } from '~/types/legal-entities/financialAnalysisExtended';
import { LegalEntitiesFinancialAnalysisShort } from '~/types/legal-entities/financialAnalysisShort';
import { LegalEntitiesFinancialState } from '~/types/legal-entities/financialState';
import { LegalEntitiesMarkers } from '~/types/legal-entities/markers';
import { LegalEntitiesTotalScore } from '~/types/legal-entities/totalScore';
import { IPlaceholderDataService, IReportVettingInfoPlaceholderServiceKey } from '~/services/placeholder/common/reportPlaceholderService.interfaces';
import { IPrimitiveTypesNormalizationServiceKey, IPrimitiveTypesNormalizationService } from '../common/primitiveTypesNormalizationService.interfaces';
import { INormalizationService, IReportVettingInfoNormalizationServiceKey } from '../common/reportNormalizationService.interfaces';
import { ReportVettingInfo } from '~/types/common/vettingInfo';
import { LegalEntitiesAnalyticsIndicators } from '~/types/legal-entities/analyticsIndicators';

@injectable()
export class LegalEntitiesDataBlobDataNormalizationService implements INormalizationService<LegalEntitiesDataBlobData> {
  constructor(
    @inject(ILegalEntitiesCommonNormalizationServiceKey as symbol) private commonNormalizationService: INormalizationService<LegalEntitiesCommonInfo>,
    @inject(ILegalEntitiesBriefInfoNormalizationServiceKey as symbol) private briefInfoNormalizationService: INormalizationService<LegalEntitiesBriefInfo>,
    @inject(ILegalEntitiesFinancialStateNormalizationServiceKey as symbol) private financialStateNormalizationService: INormalizationService<LegalEntitiesFinancialState>,
    @inject(ILegalEntitiesMarkersNormalizationServiceKey as symbol) private markersNormalizationService: INormalizationService<LegalEntitiesMarkers>,
    @inject(ILegalEntitiesFinancialAnalysisShortNormalizationServiceKey as symbol) private financialAnalysisShortNormalizationService: INormalizationService<LegalEntitiesFinancialAnalysisShort>,
    @inject(ILegalEntitiesFinancialAnalysisExtendedNormalizationServiceKey as symbol) private financialAnalysisExtendedNormalizationService: INormalizationService<LegalEntitiesFinancialAnalysisExtended>,
    @inject(ILegalEntitiesTotalScoreNormalizationServiceKey as symbol) private totalScoreNormalizationService: INormalizationService<LegalEntitiesTotalScore>,
    @inject(ILegalEntitiesAnalyticsIndicatorsNormalizationServiceKey as symbol) private analyticsIndicatorsNormalizationService: INormalizationService<LegalEntitiesAnalyticsIndicators>,
    @inject(IReportVettingInfoNormalizationServiceKey as symbol) private vettingInfoNormalizationService: INormalizationService<ReportVettingInfo>,

    @inject(ILegalEntitiesCommonPlaceholderDataServiceKey as symbol) private commonPlaceholderDataService: IPlaceholderDataService<LegalEntitiesCommonInfo>,
    @inject(ILegalEntitiesBriefInfoPlaceholderDataServiceKey as symbol) private briefInfoPlaceholderDataService: IPlaceholderDataService<LegalEntitiesBriefInfo>,
    @inject(ILegalEntitiesFinancialStatePlaceholderDataServiceKey as symbol) private financialStatePlaceholderDataService: IPlaceholderDataService<LegalEntitiesFinancialState>,
    @inject(ILegalEntitiesMarkersPlaceholderDataServiceKey as symbol) private markersPlaceholderDataService: IPlaceholderDataService<LegalEntitiesMarkers>,
    @inject(ILegalEntitiesFinancialAnalysisShortPlaceholderDataServiceKey as symbol) private financialAnalysisShortPlaceholderDataService: IPlaceholderDataService<LegalEntitiesFinancialAnalysisShort>,
    @inject(ILegalEntitiesFinancialAnalysisExtendedPlaceholderDataServiceKey as symbol) private financialAnalysisExtendedPlaceholderDataService: IPlaceholderDataService<LegalEntitiesFinancialAnalysisExtended>,
    @inject(ILegalEntitiesTotalScorePlaceholderDataServiceKey as symbol) private totalScorePlaceholderDataService: IPlaceholderDataService<LegalEntitiesTotalScore>,
    @inject(ILegalEntitiesAnalyticsIndicatorsPlaceholderDataServiceKey as symbol) private analyticsIndicatorsPlaceholderDataService: IPlaceholderDataService<LegalEntitiesAnalyticsIndicators>,
    @inject(IReportVettingInfoPlaceholderServiceKey as symbol) private vettingInfoPlaceholderDataService: IPlaceholderDataService<ReportVettingInfo>,

    @inject(IPrimitiveTypesNormalizationServiceKey as symbol) private primitiveTypesNormalizationService: IPrimitiveTypesNormalizationService,
  ) {}

  normalize(data: any, root: string): LegalEntitiesDataBlobData | undefined {
    const { normalizeObject } = this.primitiveTypesNormalizationService;

    return normalizeObject(data, (data) => ({
      Common: this.commonNormalizationService.normalize(data.Common, `${root}.Common`) ?? this.commonPlaceholderDataService.getData(),
      BriefInfo: this.briefInfoNormalizationService.normalize(data.BriefInfo, `${root}.BriefInfo`) ?? this.briefInfoPlaceholderDataService.getData(),
      FinancialState: this.financialStateNormalizationService.normalize(data.FinancialState, `${root}.FinancialState`) ?? this.financialStatePlaceholderDataService.getData(),
      Markers: this.markersNormalizationService.normalize(data.Markers, `${root}.Markers`) ?? this.markersPlaceholderDataService.getData(),
      FinancialAnalysisShort: this.financialAnalysisShortNormalizationService.normalize(data.FinancialAnalysisShort, `${root}.FinancialAnalysisShort`) ?? this.financialAnalysisShortPlaceholderDataService.getData(),
      FinancialAnalysisExtended: this.financialAnalysisExtendedNormalizationService.normalize(data.FinancialAnalysisExtended, `${root}.FinancialAnalysisExtended`) ?? this.financialAnalysisExtendedPlaceholderDataService.getData(),
      TotalScore: this.totalScoreNormalizationService.normalize(data.TotalScore, `${root}.TotalScore`) ?? this.totalScorePlaceholderDataService.getData(),
      VettingInfo: this.vettingInfoNormalizationService.normalize(data.VettingInfo, `${root}.VettingInfo`) ?? this.vettingInfoPlaceholderDataService.getData(),
      AnalyticsIndicators: this.analyticsIndicatorsNormalizationService.normalize(data.AnalyticsIndicators, `${root}.AnalyticsIndicators`) ?? this.analyticsIndicatorsPlaceholderDataService.getData()
    }), root);
  }
}
