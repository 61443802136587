/* eslint-disable class-methods-use-this, max-len */

import { injectable } from 'inversify';
import { LegalEntitiesContractsInfoByYear } from '~/types/legal-entities/contractsInfoByYear';
import {
  ILegalEntitiesContractsInfoByYearAdapterService
} from '~/services/adapters/legalEntities/legalEntitiesAdapterService.interfaces';
import { LegalEntitiesContractsInfoByYearDTO } from '~/types/dto/legalEntities/contractsInfoByYearDTO';

@injectable()
export class LegalEntitiesContractsInfoByYearAdapterService implements ILegalEntitiesContractsInfoByYearAdapterService {
  getData(initData: LegalEntitiesContractsInfoByYear[]): LegalEntitiesContractsInfoByYearDTO[] {
    return (initData ?? []).map((item) => ({
      BuysCount: item.BuysCount,
      CompletedContractsCount: item.CompletedContractsCount,
      ContractsCount: item.ContractsCount,
      ContractsSum: item.ContractsSum,
      DiscontinuedContractsCount: item.DiscontinuedContractsCount,
      Year: item.Year,
    }));
  }
}
