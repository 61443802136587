import { injectable, inject } from 'inversify';

import { IPrimitiveTypesNormalizationServiceKey, IPrimitiveTypesNormalizationService } from './primitiveTypesNormalizationService.interfaces';
import { ReportMainEntityFileUrl } from '~/types/common/reportMainEntityFileUrl';
import { ReportRoadAccident } from '~/types/common/reportRoadAccident';
import { INormalizationService, IReportMainEntityFileUrlNormalizationServiceKey } from './reportNormalizationService.interfaces';
import { IPlaceholderDataService, IReportMainEntityFileUrlPlaceholderServiceKey } from '~/services/placeholder/common/reportPlaceholderService.interfaces';

@injectable()
export class ReportRoadAccidentNormalizationService implements INormalizationService<ReportRoadAccident> {
  constructor(
    @inject(IReportMainEntityFileUrlNormalizationServiceKey as symbol) private reportMainEntityFileUrlNormalizationService: INormalizationService<ReportMainEntityFileUrl>,

    @inject(IReportMainEntityFileUrlPlaceholderServiceKey as symbol) private reportMainEntityFileUrlPlaceholderService: IPlaceholderDataService<ReportMainEntityFileUrl>,

    @inject(IPrimitiveTypesNormalizationServiceKey as symbol) private primitiveTypesNormalizationService: IPrimitiveTypesNormalizationService,
  ) {}

  normalize(data: any, root: string): ReportRoadAccident | undefined {
    const {
      normalizeString,
      normalizeOptionalString,
      normalizeObject,
    } = this.primitiveTypesNormalizationService;

    return normalizeObject(data, (data) => ({
      Date: normalizeOptionalString(data.Date, `${root}.Date`),
      AccidentType: normalizeString(data.AccidentType, `${root}.AccidentType`),
      Region: normalizeString(data.Region, `${root}.Region`),
      SchemePhotoUrl: this.reportMainEntityFileUrlNormalizationService.normalize(data.SchemePhotoUrl, `${root}.SchemePhotoUrl`) ?? this.reportMainEntityFileUrlPlaceholderService.getData(),
    }), root);
  }
}
