import { injectable, inject } from 'inversify';
import { ReportWantedCar } from '~/types/common/reportWantedCar';
import { IPrimitiveTypesNormalizationServiceKey, IPrimitiveTypesNormalizationService } from './primitiveTypesNormalizationService.interfaces';
import { INormalizationService } from './reportNormalizationService.interfaces';

@injectable()
export class ReportWantedCarNormalizationService implements INormalizationService<ReportWantedCar> {
  constructor(
    @inject(IPrimitiveTypesNormalizationServiceKey as symbol) private primitiveTypesNormalizationService: IPrimitiveTypesNormalizationService,
  ) {}

  normalize(data: any, root: string): ReportWantedCar | undefined {
    const { normalizeOptionalString, normalizeObject } = this.primitiveTypesNormalizationService;

    return normalizeObject(data, (data) => ({
      StartDate: normalizeOptionalString(data.StartDate, `${root}.StartDate`)
    }), root);
  }
}
