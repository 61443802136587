import { injectable } from 'inversify';

import { LegalEntitiesReorganisationMember } from '~/types/legal-entities/reorganisationMember';
import { IPlaceholderDataService } from '../common/reportPlaceholderService.interfaces';

@injectable()
export class LegalEntitiesReorganizationMemberPlaceholderDataService implements IPlaceholderDataService<LegalEntitiesReorganisationMember> {
  getData(): LegalEntitiesReorganisationMember {
    return {
      OrganisationName: '',
      Inn: '',
      Ogrn: '',
      Date: '',
      Status: '',
    };
  }
}
