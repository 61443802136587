import { injectable, inject } from 'inversify';

import { IPrimitiveTypesNormalizationServiceKey, IPrimitiveTypesNormalizationService } from './primitiveTypesNormalizationService.interfaces';
import { ReportVehicleOwnership } from '~/types/common/reportVehicleOwnership';
import { INormalizationService } from './reportNormalizationService.interfaces';

@injectable()
export class ReportVehicleOwnershipNormalizationService implements INormalizationService<ReportVehicleOwnership> {
  constructor(
    @inject(IPrimitiveTypesNormalizationServiceKey as symbol) private primitiveTypesNormalizationService: IPrimitiveTypesNormalizationService,
  ) {}

  normalize(data: any, root: string): ReportVehicleOwnership | undefined {
    const { normalizeString, normalizeOptionalString, normalizeObject } = this.primitiveTypesNormalizationService;

    return normalizeObject(data, (data) => ({
      OwnerType: normalizeString(data.OwnerType, `${root}.OwnerType`),
      RegDate: normalizeOptionalString(data.RegDate, `${root}.RegDate`),
      RegDateRaw: normalizeOptionalString(data.RegDateRaw, `${root}.RegDateRaw`),
      DeregistrationDate: normalizeOptionalString(data.DeregistrationDate, `${root}.DeregistrationDate`),
    }), root);
  }
}
