import { injectable } from 'inversify';

import { ReportAddress } from '~/types/common/address';
import { ReportAddressDTO } from '~/types/dto/common/addressDTO';
import { StringUtils } from '~/utils/stringUtils';
import { NUMBER_DIGIT_GROUPS_EXTRACTION_REGEX } from '~/regex/numberDigitGroupsExtraction';
import { IReportAddressAdapterService } from '~/services/adapters/common/reportAdapterService.interfeces';

@injectable()
export class ReportAddressAdapterService implements IReportAddressAdapterService {
  getData(initData: ReportAddress[]): ReportAddressDTO[] {
    return (initData ?? []).map((item) => ({
      AddressType: item.AddressType,
      Address: StringUtils.quoteReplacement(item.Address),
      FlatType: StringUtils.quoteReplacement(item.FlatType ?? ''),
      FlatArea: StringUtils.quoteReplacement(item.FlatArea ?? ''),
      SquareMeterPrice: (item.SquareMeterPrice ?? '').toString().replace(NUMBER_DIGIT_GROUPS_EXTRACTION_REGEX, ' '),
      FlatPrice: (item.FlatPrice ?? '').toString().replace(NUMBER_DIGIT_GROUPS_EXTRACTION_REGEX, ' '),
      PostalBox: StringUtils.quoteReplacement(item.PostalBox ?? ''),
      FiasId: StringUtils.quoteReplacement(item.FiasId ?? ''),
      FiasLevel: StringUtils.quoteReplacement(item.FiasLevel ?? ''),
      FiasActualityState: StringUtils.quoteReplacement(item.FiasActualityState ?? ''),
      KladrId: StringUtils.quoteReplacement(item.KladrId ?? ''),
      CapitalMarker: StringUtils.quoteReplacement(item.CapitalMarker ?? ''),
      TaxOffice: StringUtils.quoteReplacement(item.TaxOffice ?? ''),
      TaxOfficeLegal: StringUtils.quoteReplacement(item.TaxOfficeLegal ?? ''),
      TimeZone: item.TimeZone ?? '',
      GeoLat: StringUtils.quoteReplacement(item.GeoLat ?? ''),
      GeoLon: StringUtils.quoteReplacement(item.GeoLon ?? ''),
      CoordinateAccuracyCode: item.CoordinateAccuracyCode,
      CodeSuitabilityMailing: item.CodeSuitabilityMailing,
      AvailabilityHouseInFias: StringUtils.quoteReplacement(item.AvailabilityHouseInFias ?? ''),
      VerificationCode: item.VerificationCode,
      UnparsedParts: StringUtils.quoteReplacement(item.UnparsedParts ?? ''),
      NameStationMetros: StringUtils.quoteReplacement(item.NameStationMetros ?? ''),
    }));
  }
}
