import { injectable } from 'inversify';
import { IndividualMessageAboutBankruptcy } from '~/types/individual/messageAboutBankruptcy';
import { IPlaceholderDataService } from '../common/reportPlaceholderService.interfaces';

@injectable()
export class IndividualMessageAboutBankruptcyPlaceholderService implements IPlaceholderDataService<IndividualMessageAboutBankruptcy> {
  getData(): IndividualMessageAboutBankruptcy {
    return {
      MessageId: '',
      PubDate: '',
      DecisionResultTypeName: '',
      DecisionResultDate: '',
      AnnulationMessageId: '',
      AnnulationReason: '',
    };
  }
}
