/* eslint-disable max-len, class-methods-use-this */
import { injectable } from 'inversify';
import { LegalEntitiesCommonInfoDTO } from '~/types/dto/legalEntities/commonInfo';
import { LegalEntitiesCommonInfo } from '~/types/legal-entities/commonInfo';
import { ILegalEntitiesCommonInfoAdapterService } from '~/services/adapters/legalEntities/legalEntitiesAdapterService.interfaces';
import { StringUtils } from '~/utils/stringUtils';

@injectable()
export class LegalEntitiesCommonInfoAdapterService implements ILegalEntitiesCommonInfoAdapterService {
  getData(data: LegalEntitiesCommonInfo): LegalEntitiesCommonInfoDTO {
    return {
      CompanyShortName: StringUtils.companyQuoteReplacement(data.CompanyShortName ?? ''),
      CompanyFullName: StringUtils.companyQuoteReplacement(data.CompanyFullName ?? ''),
      IsCompanyIp: data.IsCompanyIp,
      CompanyOgrn: data.CompanyOgrn ?? '',
      CompanyInn: data.CompanyInn,
      OtherCompaniesWithDirector: data.OtherCompaniesWithDirector,
      PrincipalActivityOkvedCode: data.PrincipalActivityOkvedCode,
      CompanyOkpo: data.CompanyOkpo ?? '',
      CompanyKpp: data.CompanyKpp ?? '',
      CompanyOkfcVal: data.CompanyOkfcVal ?? '',
      CompanyOkfcDesc: data.CompanyOkfcDesc ?? '',
      CompanyOkopfVal: data.CompanyOkopfVal ?? '',
      CompanyOkopfDesc: data.CompanyOkopfDesc ?? '',
      CompanyOkoguVal: data.CompanyOkoguVal ?? '',
      CompanyOkoguDesc: data.CompanyOkoguDesc,
      CompanyOkato: data.CompanyOkato ?? '',
      CompanyOktmoVal: data.CompanyOktmoVal ?? '',
      CompanyOktmoDesc: data.CompanyOktmoDesc,
      Bic: data.Bic ?? '',
      Swift: data.Swift ?? '',
      BankRegistrationNumber: data.BankRegistrationNumber ?? '',
      BankOpfType: data.BankOpfType,
      BankCorrespondentAccount: data.BankCorrespondentAccount ?? '',
      ReportGenerationDate: data.ReportGenerationDate ?? '',
    };
  }
}
