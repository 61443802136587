import { injectable } from 'inversify';
import { IndividualAviaPerson } from '~/types/individual/aviaPerson';
import { IPlaceholderDataService } from '../common/reportPlaceholderService.interfaces';

@injectable()
export class IndividualAviaPersonPlaceholderService implements IPlaceholderDataService<IndividualAviaPerson> {
  getData(): IndividualAviaPerson {
    return {
      FlightDate: '',
      FlightStartTime: '',
      From: '',
      To: '',
      FlightNumber: '',
      Airline: '',
      AirlineCode: '',
      Companions: [],
      AccompanyingPersons: []
    };
  }
}
