import { injectable } from 'inversify';

import { ReportMarker } from '~/types/common/reportMarker';
import { IPlaceholderDataService } from './reportPlaceholderService.interfaces';

@injectable()
export class ReportMarkerPlaceholderService implements IPlaceholderDataService<ReportMarker> {
  getData(): ReportMarker {
    return {
      Name: ''
    };
  }
}
