import { injectable } from 'inversify';
import {
  IIndividualMessageAboutBankruptcyAdapterService
} from '~/services/adapters/individual/individualAdapterService.interfaces';
import { IndividualMessageAboutBankruptcy } from '~/types/individual/messageAboutBankruptcy';
import { IndividualMessageAboutBankruptcyDTO } from '~/types/dto/individual/messageAboutBankruptcyDTO';
import { StringUtils } from '~/utils/stringUtils';

@injectable()
export class IndividualMessageAboutBankruptcyAdapterService implements IIndividualMessageAboutBankruptcyAdapterService {
  getData(initData: IndividualMessageAboutBankruptcy[]): IndividualMessageAboutBankruptcyDTO[] {
    return (initData ?? []).map((item) => ({
      MessageId: StringUtils.quoteReplacement(item.MessageId),
      PubDate: item.PubDate ?? '',
      DecisionResultTypeName: StringUtils.quoteReplacement(item.DecisionResultTypeName ?? ''),
      DecisionResultDate: item.DecisionResultDate ?? '',
      AnnulationMessageId: StringUtils.quoteReplacement(item.AnnulationMessageId ?? ''),
      AnnulationReason: StringUtils.quoteReplacement(item.AnnulationReason ?? ''),
    }));
  }
}
