import { injectable, inject } from 'inversify';
import { LegalEntitiesPerson } from '~/types/legal-entities/person';
import { IPrimitiveTypesNormalizationServiceKey, IPrimitiveTypesNormalizationService } from '../common/primitiveTypesNormalizationService.interfaces';
import { INormalizationService } from '../common/reportNormalizationService.interfaces';

@injectable()
export class LegalEntitiesPersonNormalizationService implements INormalizationService<LegalEntitiesPerson> {
  constructor(
    @inject(IPrimitiveTypesNormalizationServiceKey as symbol) private primitiveTypesNormalizationService: IPrimitiveTypesNormalizationService,
  ) {}

  normalize(data: any, root: string): LegalEntitiesPerson | undefined {
    const { normalizeString, normalizeObject } = this.primitiveTypesNormalizationService;

    return normalizeObject(data, (data) => ({
      Fio: normalizeString(data.Fio, `${root}.Fio`),
      Role: normalizeString(data.Role, `${root}.Role`),
      CompanyName: normalizeString(data.CompanyName, `${root}.CompanyName`),
    }), root);
  }
}
