import { InjectionKey } from 'vue';
import { ITableItemService } from '~/modules/table/services/tableItemService.interface';
import {
  LegalEntitiesReportFinancialIndicatorWithDynamicDTO
} from '~/types/dto/legalEntities/reportFinancialIndicatorWithDynamicDTO';
import {
  LegalEntitiesReportFinancialIndicatorWithConclusionsDTO
} from '~/types/dto/legalEntities/reportFinancialIndicatorWithConclusionsDTO';

export type IRevenuesTableItemConfigService = ITableItemService<LegalEntitiesReportFinancialIndicatorWithDynamicDTO>
export type IProfitFromSalesTableItemConfigService = ITableItemService<LegalEntitiesReportFinancialIndicatorWithDynamicDTO>
export type IEbitTableItemConfigService = ITableItemService<LegalEntitiesReportFinancialIndicatorWithDynamicDTO>
export type IDebtShareTableItemConfigService = ITableItemService<LegalEntitiesReportFinancialIndicatorWithDynamicDTO>

export type IDebtEbitTableItemConfigService = ITableItemService<LegalEntitiesReportFinancialIndicatorWithConclusionsDTO>
export type IEbitPercentTableItemConfigService = ITableItemService<LegalEntitiesReportFinancialIndicatorWithConclusionsDTO>

export const IRevenuesTableItemConfigServiceKey: InjectionKey<IRevenuesTableItemConfigService> = Symbol('IRevenuesTableItemConfigService');
export const IProfitFromSalesTableItemConfigServiceKey: InjectionKey<IProfitFromSalesTableItemConfigService> = Symbol('IProfitFromSalesTableItemConfigService');
export const IEbitTableItemConfigServiceKey: InjectionKey<IEbitTableItemConfigService> = Symbol('IEbitTableItemConfigService');
export const IDebtShareTableItemConfigServiceKey: InjectionKey<IDebtShareTableItemConfigService> = Symbol('IDebtShareTableItemConfigService');
export const IDebtEbitTableItemConfigServiceKey: InjectionKey<IDebtEbitTableItemConfigService> = Symbol('IDebtEbitTableItemConfigService');
export const IEbitPercentTableItemConfigServiceKey: InjectionKey<IEbitPercentTableItemConfigService> = Symbol('IEbitPercentTableItemConfigService');
