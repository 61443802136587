import { injectable } from 'inversify';
import { IMessageBoardsDescriptionInfoConfigService } from '~/modules/reportsCommon/reportMessageBoardRecord/services/messageBoardsConfigService.interfaces';
import { ReportMessageBoardRecordDTO } from '~/types/dto/common/messageBoardsDTO';
import { Config } from '~/types/field-list/config';

@injectable()
export class MessageBoardsDescriptionInfoConfigService implements IMessageBoardsDescriptionInfoConfigService {
  getConfig(initData: ReportMessageBoardRecordDTO): Config {
    const product = initData.MessageTitle ? `${initData.MessageTitle} ${initData.MessageText}` : initData.MessageText;
    const brandAndModel = initData.Brand ? `${initData.Brand} ${initData.Model}` : initData.Model;
    
    return {
      fieldsDirection: 'row',
      fieldsWidth: '100%',
      columnGap: '16px',
      isBeginningDivider: true,
      fields: [
        {
          key: 'product',
          label: 'Товар:',
          value: product || brandAndModel,
        },
        {
          key: 'category',
          label: 'Категория:',
          value: initData.Category
        }
      ].filter((item) => item.value)
    };
  }
}
