import { injectable } from 'inversify';
import { IIndividualTypeAdapterService } from '~/services/adapters/individual/individualAdapterService.interfaces';
import { IndividualType } from '~/types/individual/type';
import { IndividualTypeDTO } from '~/types/dto/individual/typesDTO';
import { StringUtils } from '~/utils/stringUtils';

@injectable()
export class IndividualTypeAdapterService implements IIndividualTypeAdapterService {
  getData(initData: IndividualType[]): IndividualTypeDTO[] {
    return (initData ?? []).map((item) => ({
      Type: StringUtils.quoteReplacement(item.Type)
    }));
  }
}
