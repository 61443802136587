import { injectable, inject } from 'inversify';
import { LegalEntitiesCompanyName } from '~/types/legal-entities/companyName';
import { IPrimitiveTypesNormalizationServiceKey, IPrimitiveTypesNormalizationService } from '../common/primitiveTypesNormalizationService.interfaces';
import { INormalizationService } from '../common/reportNormalizationService.interfaces';

@injectable()
export class LegalEntitiesCompanyNameNormalizationService implements INormalizationService<LegalEntitiesCompanyName> {
  constructor(
    @inject(IPrimitiveTypesNormalizationServiceKey as symbol) private primitiveTypesNormalizationService: IPrimitiveTypesNormalizationService,
  ) {}

  normalize(data: any, root: string): LegalEntitiesCompanyName | undefined {
    const { normalizeString, normalizeOptionalString, normalizeObject } = this.primitiveTypesNormalizationService;

    return normalizeObject(data, (data) => ({
      Name: normalizeString(data.Name, `${root}.Name`),
      From: normalizeOptionalString(data.From, `${root}.From`),
    }), root);
  }
}
