import { injectable, inject } from 'inversify';

import { ReportMarker } from '~/types/common/reportMarker';
import { LegalEntitiesMarkers } from '~/types/legal-entities/markers';
import { IPrimitiveTypesNormalizationServiceKey, IPrimitiveTypesNormalizationService } from '../common/primitiveTypesNormalizationService.interfaces';
import { INormalizationService, IReportMarkerNormalizationServiceKey } from '../common/reportNormalizationService.interfaces';

@injectable()
export class LegalEntitiesMarkersNormalizationService implements INormalizationService<LegalEntitiesMarkers> {
  constructor(
    @inject(IReportMarkerNormalizationServiceKey as symbol) private reportMarkerNormalizationService: INormalizationService<ReportMarker>,
    @inject(IPrimitiveTypesNormalizationServiceKey as symbol) private primitiveTypesNormalizationService: IPrimitiveTypesNormalizationService,
  ) {}

  normalize(data: any, root: string): LegalEntitiesMarkers | undefined {
    const { normalizeNumber, normalizeArray, normalizeObject } = this.primitiveTypesNormalizationService;

    return normalizeObject(data, (data) => ({
      MarkersGood: normalizeArray(data.MarkersGood, (item, field) => this.reportMarkerNormalizationService.normalize(item, field), `${root}.MarkersGood`),
      MarkersWarning: normalizeArray(data.MarkersWarning, (item, field) => this.reportMarkerNormalizationService.normalize(item, field), `${root}.MarkersWarning`),
      MarkersBad: normalizeArray(data.MarkersBad, (item, field) => this.reportMarkerNormalizationService.normalize(item, field), `${root}.MarkersBad`),
      Rate: normalizeNumber(data.Rate, `${root}.Rate`),
      RiskIndicator: normalizeNumber(data.Rate, `${root}.Rate`),
    }), root);
  }
}
