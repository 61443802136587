import { InjectionKey } from 'vue';
import { ReportIndicatorsOfCashFlow } from '~/types/blobs/report-indicators-of-cash-flow';
import { ReportFine } from '~/types/common/reportFine';
import { ReportBoundedPersonPhone } from '~/types/common/reportBoundedPersonPhone';
import { ReportColleague } from '~/types/common/reportColleague';
import { ReportEducation } from '~/types/common/reportEducation';
import { ReportEmail } from '~/types/common/reportEmail';
import { ReportIndividualEntrepreneur } from '~/types/common/reportIndividualEntrepreneur';
import { ReportJob } from '~/types/common/reportJob';
import { ReportLegalEntitiesParticipation } from '~/types/common/reportLegalEntitiesParticipation';
import { ReportMainEntityFile } from '~/types/common/reportMainEntityFile';
import { ReportMainEntityFileUrl } from '~/types/common/reportMainEntityFileUrl';
import { ReportMarker } from '~/types/common/reportMarker';
import { ReportMention } from '~/types/common/reportMention';
import { ReportMovementCovid } from '~/types/common/reportMovementCovid';
import { ReportMessageBoardRecord } from '~/types/common/reportMessageBoardRecord';
import { ReportMovementTaxi } from '~/types/common/reportMovementTaxi';
import { ReportPassport } from '~/types/common/reportPassport';
import { ReportPayment } from '~/types/common/reportPayment';
import { ReportPersonInn } from '~/types/common/reportPersonInn';
import { ReportPersonSite } from '~/types/common/reportPersonSite';
import { ReportPersonSnils } from '~/types/common/reportPersonSnils';
import { ReportPledgesRegistry } from '~/types/common/reportPledgesRegistry';
import { ReportPossibleBirthdays } from '~/types/common/reportPossibleBirthdays';
import { ReportPossibleFullName } from '~/types/common/reportPossibleFullName';
import { ReportRoadAccident } from '~/types/common/reportRoadAccident';
import { ReportSocialNetworkHighEducation } from '~/types/common/reportSocialNetworkHighEducation';
import { ReportSocialNetworkId } from '~/types/common/reportSocialNetworkId';
import { ReportSocialNetworkJob } from '~/types/common/reportSocialNetworkJob';
import { ReportSocialNetworkMilitaryRecord } from '~/types/common/reportSocialNetworkMilitaryRecord';
import { ReportSocialNetworkSchool } from '~/types/common/reportSocialNetworkSchool';
import { ReportSocialNetworkSecondaryEducation } from '~/types/common/reportSocialNetworkSecondaryEducation';
import { ReportSocialNetworkTrack } from '~/types/common/reportSocialNetworkTrack';
import { ReportTag } from '~/types/common/reportTag';
import { ReportUrl } from '~/types/common/reportUrl';
import { ReportVehicle } from '~/types/common/reportVehicle';
import { ReportVehicleInsurance } from '~/types/common/reportVehicleInsurance';
import { ReportVehicleOwnership } from '~/types/common/reportVehicleOwnership';
import { ReportVehicleParking } from '~/types/common/reportVehicleParking';
import { ReportVehiclePhone } from '~/types/common/reportVehiclePhone';
import { ReportPhotosUrl } from '~/types/common/reportVehiclePhotosUrl';
import { ReportVehicleRelation } from '~/types/common/reportVehicleRelation';
import { ReportVehicleRestrict } from '~/types/common/reportVehicleRestrict';
import { ReportVehicleRisks } from '~/types/common/reportVehicleRisks';
import { ReportWantedCar } from '~/types/common/reportWantedCar';
import { ReportAnalysisOfAbsoluteLiquidity } from '~/types/legal-entities/reportAnalysisOfAbsoluteLiquidity';
import { ReportIndicatorsOfProfitability } from '~/types/legal-entities/reportIndicatorsOfProfitability';
import { ReportMovementCovidForVehicle } from '~/types/common/reportMovementCovidForVehicle';
import { ReportMessengerInfo } from '~/types/common/reportMessengerInfo';
import { ReportVettingInfo } from '~/types/common/vettingInfo';
import { ReportConnectionRelated } from '~/types/common/reportConnectionRelated';
import { ReportConnectionRelatedChain } from '~/types/common/reportCompanyRelatedChain';
import { ReportConnectionRelatedRelation } from '~/types/common/reportConnectionRelatedRelation';
import { ReportConnectionRelatedEntity } from '~/types/common/reportConnectionRelatedEntity';
import { ReportConnectionRelatedEntityDisplay } from '~/types/common/reportConnectionRelatedEntityDisplay';
import { ReportAddress } from '~/types/common/address';
import { ReportSocialNetwork } from '~/types/common/reportSocialNetwork';
import { ReportSocialNetworkAboutInfo } from '~/types/common/reportSocialNetworkAboutInfo';
import { ReportSocialNetworkLifePosition } from '~/types/common/reportSocialNetworkLifePosition';
import { ReportSocialNetworkOtherPerson } from '~/types/common/reportSocialNetworkOtherPerson';

export interface INormalizationService<T> {
  normalize(data: any, root: string): T | undefined;
}

export const IReportAddressNormalizationServiceKey: InjectionKey<INormalizationService<ReportAddress>> = Symbol('INormalizationService<ReportAddress>');
export const IReportUrlNormalizationServiceKey: InjectionKey<INormalizationService<ReportUrl>> = Symbol('INormalizationService<ReportUrl>');
export const IReportMainEntityFileNormalizationServiceKey: InjectionKey<INormalizationService<ReportMainEntityFile>> = Symbol('INormalizationService<ReportMainEntityFile>');
export const IReportLegalEntitiesParticipationNormalizationServiceKey: InjectionKey<INormalizationService<ReportLegalEntitiesParticipation>> = Symbol('INormalizationService<ReportLegalEntitiesParticipation>');
export const IReportVehicleOwnershipNormalizationServiceKey: InjectionKey<INormalizationService<ReportVehicleOwnership>> = Symbol('INormalizationService<ReportVehicleOwnership>');
export const IReportVehicleRelationNormalizationServiceKey: InjectionKey<INormalizationService<ReportVehicleRelation>> = Symbol('INormalizationService<ReportVehicleRelation>');
export const IReportVehicleInsuranceNormalizationServiceKey: InjectionKey<INormalizationService<ReportVehicleInsurance>> = Symbol('INormalizationService<ReportVehicleInsurance>');
export const IReportPhotosUrlNormalizationServiceKey: InjectionKey<INormalizationService<ReportPhotosUrl>> = Symbol('INormalizationService<ReportPhotosUrl>');
export const IReportVehicleRestrictNormalizationServiceKey: InjectionKey<INormalizationService<ReportVehicleRestrict>> = Symbol('INormalizationService<ReportVehicleRestrict>');
export const IReportMainEntityFileUrlNormalizationServiceKey: InjectionKey<INormalizationService<ReportMainEntityFileUrl>> = Symbol('INormalizationService<ReportMainEntityFileUrl>');
export const IReportRoadAccidentNormalizationServiceKey: InjectionKey<INormalizationService<ReportRoadAccident>> = Symbol('INormalizationService<ReportRoadAccident>');
export const IReportWantedCarNormalizationServiceKey: InjectionKey<INormalizationService<ReportWantedCar>> = Symbol('INormalizationService<ReportWantedCar>');
export const IReportVehiclePhoneNormalizationServiceKey: InjectionKey<INormalizationService<ReportVehiclePhone>> = Symbol('INormalizationService<ReportVehiclePhone>');
export const IReportPledgesRegistryNormalizationServiceKey: InjectionKey<INormalizationService<ReportPledgesRegistry>> = Symbol('INormalizationService<ReportPledgesRegistry>');
export const IReportVehicleRisksNormalizationServiceKey: InjectionKey<INormalizationService<ReportVehicleRisks>> = Symbol('INormalizationService<ReportVehicleRisks>');
export const IReportVehicleNormalizationServiceKey: InjectionKey<INormalizationService<ReportVehicle>> = Symbol('INormalizationService<ReportVehicle>');
export const IReportMentionNormalizationServiceKey: InjectionKey<INormalizationService<ReportMention>> = Symbol('INormalizationService<ReportMention>');
export const IReportVehicleParkingNormalizationServiceKey: InjectionKey<INormalizationService<ReportVehicleParking>> = Symbol('INormalizationService<ReportVehicleParking>');

export const IStringArrayNormalizationServiceKey: InjectionKey<INormalizationService<string>> = Symbol('INormalizationService<string>');
export const IReportMarkerNormalizationServiceKey: InjectionKey<INormalizationService<ReportMarker>> = Symbol('INormalizationService<ReportMarker>');
export const IReportAnalysisOfAbsoluteLiquidityNormalizationServiceKey: InjectionKey<INormalizationService<ReportAnalysisOfAbsoluteLiquidity>> = Symbol('INormalizationService<ReportAnalysisOfAbsoluteLiquidity>');
export const IReportIndicatorsOfProfitabilityNormalizationServiceKey: InjectionKey<INormalizationService<ReportIndicatorsOfProfitability>> = Symbol('INormalizationService<ReportIndicatorsOfProfitability>');
export const IReportIndicatorsOfCashFlowNormalizationServiceKey: InjectionKey<INormalizationService<ReportIndicatorsOfCashFlow>> = Symbol('INormalizationService<ReportIndicatorsOfCashFlow>');
export const IReportSocialNetworkTrackNormalizationServiceKey: InjectionKey<INormalizationService<ReportSocialNetworkTrack>> = Symbol('INormalizationService<ReportSocialNetworkTrack>');
export const IReportSocialNetworkSchoolNormalizationServiceKey: InjectionKey<INormalizationService<ReportSocialNetworkSchool>> = Symbol('INormalizationService<ReportSocialNetworkSchool>');
export const IReportSocialNetworkSecondaryEducationNormalizationServiceKey: InjectionKey<INormalizationService<ReportSocialNetworkSecondaryEducation>> = Symbol('INormalizationService<ReportSocialNetworkSecondaryEducation>');
export const IReportSocialNetworkHighEducationNormalizationServiceKey: InjectionKey<INormalizationService<ReportSocialNetworkHighEducation>> = Symbol('INormalizationService<ReportSocialNetworkHighEducation>');
export const IReportSocialNetworkMilitaryRecordNormalizationServiceKey: InjectionKey<INormalizationService<ReportSocialNetworkMilitaryRecord>> = Symbol('INormalizationService<ReportSocialNetworkMilitaryRecord>');
export const IReportSocialNetworkJobNormalizationServiceKey: InjectionKey<INormalizationService<ReportSocialNetworkJob>> = Symbol('INormalizationService<ReportSocialNetworkJob>');
export const IReportPersonInnNormalizationServiceKey: InjectionKey<INormalizationService<ReportPersonInn>> = Symbol('INormalizationService<ReportPersonInn>');
export const IReportPersonSnilsNormalizationServiceKey: InjectionKey<INormalizationService<ReportPersonSnils>> = Symbol('INormalizationService<ReportPersonSnils>');
export const IReportPassportNormalizationServiceKey: InjectionKey<INormalizationService<ReportPassport>> = Symbol('INormalizationService<ReportPassport>');
export const IReportBoundedPersonPhoneNormalizationServiceKey: InjectionKey<INormalizationService<ReportBoundedPersonPhone>> = Symbol('INormalizationService<ReportBoundedPersonPhone>');
export const IReportTagNormalizationServiceKey: InjectionKey<INormalizationService<ReportTag>> = Symbol('INormalizationService<ReportTag>');
export const IReportEmailNormalizationServiceKey: InjectionKey<INormalizationService<ReportEmail>> = Symbol('INormalizationService<ReportEmail>');
export const IReportPersonSiteNormalizationServiceKey: InjectionKey<INormalizationService<ReportPersonSite>> = Symbol('INormalizationService<ReportPersonSite>');
export const IReportSocialNetworkIdNormalizationServiceKey: InjectionKey<INormalizationService<ReportSocialNetworkId>> = Symbol('INormalizationService<ReportSocialNetworkId>');
export const IReportEducationNormalizationServiceKey: InjectionKey<INormalizationService<ReportEducation>> = Symbol('INormalizationService<ReportEducation>');
export const IReportJobNormalizationServiceKey: InjectionKey<INormalizationService<ReportJob>> = Symbol('INormalizationService<ReportJob>');
export const IReportPaymentNormalizationServiceKey: InjectionKey<INormalizationService<ReportPayment>> = Symbol('INormalizationService<ReportPayment>');
export const IReportColleagueNormalizationServiceKey: InjectionKey<INormalizationService<ReportColleague>> = Symbol('INormalizationService<ReportColleague>');
export const IReportIndividualEntrepreneurNormalizationServiceKey: InjectionKey<INormalizationService<ReportIndividualEntrepreneur>> = Symbol('INormalizationService<ReportIndividualEntrepreneur>');
export const IReportFineNormalizationServiceKey: InjectionKey<INormalizationService<ReportFine>> = Symbol('INormalizationService<ReportFine>');
export const IReportPossibleFullNameNormalizationServiceKey: InjectionKey<INormalizationService<ReportPossibleFullName>> = Symbol('INormalizationService<ReportPossibleFullName>');
export const IReportMovementCovidForVehicleNormalizationServiceKey: InjectionKey<INormalizationService<ReportMovementCovidForVehicle>> = Symbol('INormalizationService<ReportMovementCovidForVehicle>');
export const IReportPossibleBirthdaysNormalizationServiceKey: InjectionKey<INormalizationService<ReportPossibleBirthdays>> = Symbol('INormalizationService<ReportPossibleBirthdays>');
export const IReportMovementCovidNormalizationServiceKey: InjectionKey<INormalizationService<ReportMovementCovid>> = Symbol('INormalizationService<ReportMovementCovid>');
export const IReportMovementTaxiNormalizationServiceKey: InjectionKey<INormalizationService<ReportMovementTaxi>> = Symbol('INormalizationService<ReportMovementTaxi>');
export const IReportMessageBoardRecordNormalizationServiceKey: InjectionKey<INormalizationService<ReportMessageBoardRecord>> = Symbol('INormalizationService<ReportMessageBoardRecord>');
export const IReportMessengerInfoNormalizationServiceKey: InjectionKey<INormalizationService<ReportMessengerInfo>> = Symbol('INormalizationService<ReportMessengerInfo>');
export const IReportVettingInfoNormalizationServiceKey: InjectionKey<INormalizationService<ReportVettingInfo>> = Symbol('INormalizationService<ReportVettingInfo>');
export const IReportConnectionRelatedNormalizationServiceKey: InjectionKey<INormalizationService<ReportConnectionRelated>> = Symbol('INormalizationService<ReportConnectionRelated>');
export const IReportConnectionRelatedChainNormalizationServiceKey: InjectionKey<INormalizationService<ReportConnectionRelatedChain>> = Symbol('INormalizationService<ReportConnectionRelatedChain>');
export const IReportConnectionRelatedEntityNormalizationServiceKey: InjectionKey<INormalizationService<ReportConnectionRelatedEntity>> = Symbol('INormalizationService<ReportConnectionRelatedEntity>');
export const IReportConnectionRelatedRelationNormalizationServiceKey: InjectionKey<INormalizationService<ReportConnectionRelatedRelation>> = Symbol('INormalizationService<ReportConnectionRelatedRelation>');
export const IReportConnectionRelatedEntityDisplayNormalizationServiceKey: InjectionKey<INormalizationService<ReportConnectionRelatedEntityDisplay>> = Symbol('INormalizationService<ReportConnectionRelatedEntityDisplay>');
export const IReportSocialNetworkNormalizationServiceKey: InjectionKey<INormalizationService<ReportSocialNetwork>> = Symbol('INormalizationService<ReportSocialNetwork>');
export const IReportSocialNetworkAboutInfoNormalizationServiceKey: InjectionKey<INormalizationService<ReportSocialNetworkAboutInfo>> = Symbol('INormalizationService<ReportSocialNetworkAboutInfo>');
export const IReportSocialNetworkLifePositionNormalizationServiceKey: InjectionKey<INormalizationService<ReportSocialNetworkLifePosition>> = Symbol('INormalizationService<ReportSocialNetworkLifePosition>');
export const IReportSocialNetworkOtherPersonNormalizationServiceKey: InjectionKey<INormalizationService<ReportSocialNetworkOtherPerson>> = Symbol('INormalizationService<ReportSocialNetworkOtherPerson>');
