import { injectable } from 'inversify';
import { ReportEducation } from '~/types/common/reportEducation';
import { IPlaceholderDataService } from './reportPlaceholderService.interfaces';

@injectable()
export class ReportEducationPlaceholderService implements IPlaceholderDataService<ReportEducation> {
  getData(): ReportEducation {
    return {
      EducationType: '',
      EducationName: '',
      FacultyName: '',
      SpecialtyName: '',
      EducationForm: '',
      DiplomaDate: '',
      DiplomaSeries: '',
      DiplomaNumber: '',
    };
  }
}
