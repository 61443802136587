import { injectable } from 'inversify';
import { orderBy } from 'lodash-es';
import { IReportEmailAdapterService } from '~/services/adapters/common/reportAdapterService.interfeces';
import { ReportEmail } from '~/types/common/reportEmail';
import { ReportEmailDTO } from '~/types/dto/common/emailDTO';
import { StringUtils } from '~/utils/stringUtils';

@injectable()
export class ReportEmailAdapterService implements IReportEmailAdapterService {
  getData(initData: ReportEmail[]): ReportEmailDTO[] {
    return orderBy(initData ?? [], ['IsSuitableForSending'], ['desc'])
      .map((item) => ({
        Value: StringUtils.quoteReplacement(item.Value),
        VerificationCode: item.VerificationCode,
        Type: item.Type,
        IsSuitableForSending: item.IsSuitableForSending ?? false
      })).filter((item) => !item.Value.toLowerCase().includes('localhost.net'));
  }
}
