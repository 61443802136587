import { injectable } from 'inversify';

import { LegalEntitiesConditionAssessment } from '~/types/legal-entities/conditionAssessment';
import { IPlaceholderDataService } from '../common/reportPlaceholderService.interfaces';

@injectable()
export class LegalEntitiesConditionAssessmentPlaceholderService implements IPlaceholderDataService<LegalEntitiesConditionAssessment> {
  getData(): LegalEntitiesConditionAssessment {
    return {
      Code: '',
      Description: ''
    };
  }
}
