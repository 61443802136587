import { injectable } from 'inversify';

import { LegalEntitiesReportSocialNetworkId } from '~/types/legal-entities/reportSocialNetworkId';
import { IPlaceholderDataService } from '../common/reportPlaceholderService.interfaces';

@injectable()
export class LegalEntitiesReportSocialNetworkIdPlaceholderDataService implements IPlaceholderDataService<LegalEntitiesReportSocialNetworkId> {
  getData(): LegalEntitiesReportSocialNetworkId {
    return {
      Type: 0,
      SocialId: '',
    };
  }
}
