import { injectable } from 'inversify';
import { IIndividualBankruptcyAdapterService } from '~/services/adapters/individual/individualAdapterService.interfaces';
import { IndividualBankruptcy } from '~/types/individual/bankruptcy';
import { IndividualBankruptcyDTO } from '~/types/dto/individual/bankruptcyDTO';
import { StringUtils } from '~/utils/stringUtils';
import { DateUtils } from '~/utils/date';

@injectable()
export class IndividualBankruptcyAdapterService implements IIndividualBankruptcyAdapterService {
  getData(initData: IndividualBankruptcy[]): IndividualBankruptcyDTO[] {
    return (initData ?? []).map((item) => ({
      Stage: StringUtils.quoteReplacement(item.Stage),
      StageDate: DateUtils.formatDate(item.StageDate ?? ''),
      CaseNumber: StringUtils.quoteReplacement(item.CaseNumber),
      LastMessageDate: item.LastMessageDate ?? '',
      ExtrajudicialStatus: StringUtils.quoteReplacement(item.ExtrajudicialStatus ?? ''),
      ExtrajudicialStartDate: item.ExtrajudicialStartDate ?? '',
      ExtrajudicialEndDate: item.ExtrajudicialEndDate ?? '',
      ExtrajudicialReasonForTermination: StringUtils.quoteReplacement(item.ExtrajudicialReasonForTermination ?? ''),
      ExtrajudicialReasonForReturn: StringUtils.quoteReplacement(item.ExtrajudicialReasonForReturn ?? ''),
      ArbitrManagerFio: StringUtils.quoteReplacement(item.ArbitrManagerFio ?? ''),
      ArbitrManagerInn: StringUtils.quoteReplacement(item.ArbitrManagerInn),
      ArbitrManagerOgrn: StringUtils.quoteReplacement(item.ArbitrManagerOgrn ?? '')
    }));
  }
}
