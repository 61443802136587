export class CompositeNodeSizeSnapshot {
  top = 0;
  bottom = 0;
  height = 0;
    
  constructor(public elements: Element[]) {
    this.update();
  }
    
  update() {
    const rects = this.elements.map(x => x.getBoundingClientRect());
    this.top = Math.min(...rects.map(x => x.top));
    this.bottom = Math.max(...rects.map(x => x.bottom));
    this.height = this.bottom - this.top;
  }
}
