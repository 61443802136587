import { injectable, inject } from 'inversify';
import { ReportBoundedPersonPhone } from '~/types/common/reportBoundedPersonPhone';
import { ReportEducation } from '~/types/common/reportEducation';
import { ReportEmail } from '~/types/common/reportEmail';
import { ReportIndividualEntrepreneur } from '~/types/common/reportIndividualEntrepreneur';
import { ReportJob } from '~/types/common/reportJob';
import { ReportLegalEntitiesParticipation } from '~/types/common/reportLegalEntitiesParticipation';
import { ReportMessageBoardRecord } from '~/types/common/reportMessageBoardRecord';
import { ReportMovementCovid } from '~/types/common/reportMovementCovid';
import { ReportMovementTaxi } from '~/types/common/reportMovementTaxi';
import { ReportPassport } from '~/types/common/reportPassport';
import { ReportPersonInn } from '~/types/common/reportPersonInn';
import { ReportPersonSite } from '~/types/common/reportPersonSite';
import { ReportPersonSnils } from '~/types/common/reportPersonSnils';
import { ReportPossibleFullName } from '~/types/common/reportPossibleFullName';
import { ReportSocialNetworkTrack } from '~/types/common/reportSocialNetworkTrack';
import { ReportUrl } from '~/types/common/reportUrl';
import { ReportVehicle } from '~/types/common/reportVehicle';
import { ReportVehicleParking } from '~/types/common/reportVehicleParking';
import { ReportAddress } from '~/types/common/address';
import { ReportSocialNetwork } from '~/types/common/reportSocialNetwork';
import { PhoneNumberRelatedPhonePerson } from '~/types/phone-number/relatedPhonePerson';
import { PhoneNumberRelatedPhonePersonCommon } from '~/types/phone-number/relatedPhonePersonCommon';
import { IPhoneNumberRelatedPhonePersonCommonNormalizationServiceKey } from './phoneNumberNormalizationService.interface';
import { IPlaceholderDataService, IReportSocialNetworkTrackPlaceholderServiceKey } from '~/services/placeholder/common/reportPlaceholderService.interfaces';
import { IPhoneNumberRelatedPhonePersonCommonPlaceholderServiceKey } from '~/services/placeholder/phoneNumber/phoneNumberPlaceholderService.interface';
import { IPrimitiveTypesNormalizationServiceKey, IPrimitiveTypesNormalizationService } from '../common/primitiveTypesNormalizationService.interfaces';
import {
  INormalizationService,
  IReportSocialNetworkTrackNormalizationServiceKey,
  IReportPossibleFullNameNormalizationServiceKey,
  IReportPersonInnNormalizationServiceKey,
  IReportPersonSnilsNormalizationServiceKey,
  IReportPassportNormalizationServiceKey,
  IReportEmailNormalizationServiceKey,
  IReportPersonSiteNormalizationServiceKey,
  IReportVehicleNormalizationServiceKey,
  IReportEducationNormalizationServiceKey,
  IReportJobNormalizationServiceKey,
  IReportLegalEntitiesParticipationNormalizationServiceKey,
  IReportIndividualEntrepreneurNormalizationServiceKey,
  IReportBoundedPersonPhoneNormalizationServiceKey,
  IReportVehicleParkingNormalizationServiceKey,
  IReportMovementCovidNormalizationServiceKey,
  IReportMovementTaxiNormalizationServiceKey,
  IReportUrlNormalizationServiceKey,
  IReportMessageBoardRecordNormalizationServiceKey,
  IReportPossibleBirthdaysNormalizationServiceKey, IReportAddressNormalizationServiceKey, IReportSocialNetworkNormalizationServiceKey
} from '../common/reportNormalizationService.interfaces';
import { ReportPossibleBirthdays } from '~/types/common/reportPossibleBirthdays';

@injectable()
export class PhoneNumberRelatedPhonePersonNormalizationService implements INormalizationService<PhoneNumberRelatedPhonePerson> {
  constructor(

    @inject(IPhoneNumberRelatedPhonePersonCommonNormalizationServiceKey as symbol) private phoneNumberRelatedPhonePersonCommonNormalizationService: INormalizationService<PhoneNumberRelatedPhonePersonCommon>,
    @inject(IReportSocialNetworkTrackNormalizationServiceKey as symbol) private reportSocialNetworkTrackNormalizationService: INormalizationService<ReportSocialNetworkTrack>,
    @inject(IReportPossibleFullNameNormalizationServiceKey as symbol) private reportPossibleFullNameNormalizationService: INormalizationService<ReportPossibleFullName>,
    @inject(IReportPersonInnNormalizationServiceKey as symbol) private reportPersonInnNormalizationService: INormalizationService<ReportPersonInn>,
    @inject(IReportPersonSnilsNormalizationServiceKey as symbol) private reportPersonSnilsNormalizationService: INormalizationService<ReportPersonSnils>,
    @inject(IReportPassportNormalizationServiceKey as symbol) private reportPassportNormalizationService: INormalizationService<ReportPassport>,
    @inject(IReportEmailNormalizationServiceKey as symbol) private reportEmailNormalizationService: INormalizationService<ReportEmail>,
    @inject(IReportPersonSiteNormalizationServiceKey as symbol) private reportPersonSiteNormalizationService: INormalizationService<ReportPersonSite>,
    @inject(IReportSocialNetworkNormalizationServiceKey as symbol) private flSocialNetworkNormalizationService: INormalizationService<ReportSocialNetwork>,
    @inject(IReportAddressNormalizationServiceKey as symbol) private reportAddressNormalizationService: INormalizationService<ReportAddress>,
    @inject(IReportVehicleNormalizationServiceKey as symbol) private reportVehicleNormalizationService: INormalizationService<ReportVehicle>,
    @inject(IReportEducationNormalizationServiceKey as symbol) private reportEducationNormalizationService: INormalizationService<ReportEducation>,
    @inject(IReportJobNormalizationServiceKey as symbol) private reportJobNormalizationService: INormalizationService<ReportJob>,
    @inject(IReportLegalEntitiesParticipationNormalizationServiceKey as symbol) private reportLegalEntitiesParticipationNormalizationService: INormalizationService<ReportLegalEntitiesParticipation>,
    @inject(IReportIndividualEntrepreneurNormalizationServiceKey as symbol) private reportIndividualEntrepreneurNormalizationService: INormalizationService<ReportIndividualEntrepreneur>,
    @inject(IReportBoundedPersonPhoneNormalizationServiceKey as symbol) private reportBoundedPersonPhoneNormalizationService: INormalizationService<ReportBoundedPersonPhone>,
    @inject(IReportVehicleParkingNormalizationServiceKey as symbol) private reportVehicleParkingNormalizationService: INormalizationService<ReportVehicleParking>,
    @inject(IReportMovementCovidNormalizationServiceKey as symbol) private reportMovementCovidNormalizationService: INormalizationService<ReportMovementCovid>,
    @inject(IReportMovementTaxiNormalizationServiceKey as symbol) private reportMovementTaxiNormalizationService: INormalizationService<ReportMovementTaxi>,
    @inject(IReportUrlNormalizationServiceKey as symbol) private reportUrlNormalizationService: INormalizationService<ReportUrl>,
    @inject(IReportMessageBoardRecordNormalizationServiceKey as symbol) private reportMessageBoardRecordNormalizationService: INormalizationService<ReportMessageBoardRecord>,
    @inject(IReportPossibleBirthdaysNormalizationServiceKey as symbol) private reportPossibleBirthdaysNormalizationService: INormalizationService<ReportPossibleBirthdays>,

    @inject(IPhoneNumberRelatedPhonePersonCommonPlaceholderServiceKey as symbol) private phoneNumberRelatedPhonePersonCommonPlaceholderService: IPlaceholderDataService<PhoneNumberRelatedPhonePersonCommon>,
    @inject(IReportSocialNetworkTrackPlaceholderServiceKey as symbol) private reportSocialNetworkTrackPlaceholderService: IPlaceholderDataService<ReportSocialNetworkTrack>,

    @inject(IPrimitiveTypesNormalizationServiceKey as symbol) private primitiveTypesNormalizationService: IPrimitiveTypesNormalizationService,
  ) {}

  normalize(data: any, root: string): PhoneNumberRelatedPhonePerson | undefined {
    const { normalizeObject, normalizeArray } = this.primitiveTypesNormalizationService;

    return normalizeObject(data, (data) => ({
      Common: this.phoneNumberRelatedPhonePersonCommonNormalizationService.normalize(data.Common, `${root}.Common`) ?? this.phoneNumberRelatedPhonePersonCommonPlaceholderService.getData(),
      PossibleFios: normalizeArray(data.PossibleFios, (item, field) => this.reportPossibleFullNameNormalizationService.normalize(item, field), `${root}.PossibleFios`),
      Inns: normalizeArray(data.Inns, (item, field) => this.reportPersonInnNormalizationService.normalize(item, field), `${root}.Inns`),
      Snilses: normalizeArray(data.Snilses, (item, field) => this.reportPersonSnilsNormalizationService.normalize(item, field), `${root}.Snilses`),
      Passports: normalizeArray(data.Passports, (item, field) => this.reportPassportNormalizationService.normalize(item, field), `${root}.Passports`),
      Emails: normalizeArray(data.Emails, (item, field) => this.reportEmailNormalizationService.normalize(item, field), `${root}.Emails`),
      Sites: normalizeArray(data.Sites, (item, field) => this.reportPersonSiteNormalizationService.normalize(item, field), `${root}.Sites`),
      SocialNetworks: normalizeArray(data.SocialNetworks, (item, field) => this.flSocialNetworkNormalizationService.normalize(item, field), `${root}.SocialNetworks`),
      Addresses: normalizeArray(data.Addresses, (item, field) => this.reportAddressNormalizationService.normalize(item, field), `${root}.Addresses`),
      Vehicles: normalizeArray(data.Vehicles, (item, field) => this.reportVehicleNormalizationService.normalize(item, field), `${root}.Vehicles`),
      Educations: normalizeArray(data.Educations, (item, field) => this.reportEducationNormalizationService.normalize(item, field), `${root}.Educations`),
      Jobs: normalizeArray(data.Jobs, (item, field) => this.reportJobNormalizationService.normalize(item, field), `${root}.Jobs`),
      LegalEntitiesParticipation: normalizeArray(data.LegalEntitiesParticipation, (item, field) => this.reportLegalEntitiesParticipationNormalizationService.normalize(item, field), `${root}.LegalEntitiesParticipation`),
      IndividualEntrepreneurs: normalizeArray(data.IndividualEntrepreneurs, (item, field) => this.reportIndividualEntrepreneurNormalizationService.normalize(item, field), `${root}.IndividualEntrepreneurs`),
      SocialNetworkTrack: this.reportSocialNetworkTrackNormalizationService.normalize(data.SocialNetworkTrack, `${root}.SocialNetworkTrack`) ?? this.reportSocialNetworkTrackPlaceholderService.getData(),
      BoundedPersonPhones: normalizeArray(data.BoundedPersonPhones, (item, field) => this.reportBoundedPersonPhoneNormalizationService.normalize(item, field), `${root}.BoundedPersonPhones`),
      VehicleParkings: normalizeArray(data.VehicleParkings, (item, field) => this.reportVehicleParkingNormalizationService.normalize(item, field), `${root}.VehicleParkings`),
      CovidMovements: normalizeArray(data.CovidMovements, (item, field) => this.reportMovementCovidNormalizationService.normalize(item, field), `${root}.CovidMovements`),
      TaxiMovements: normalizeArray(data.TaxiMovements, (item, field) => this.reportMovementTaxiNormalizationService.normalize(item, field), `${root}.TaxiMovements`),
      PhotoLinks: normalizeArray(data.PhotoLinks, (item, field) => this.reportUrlNormalizationService.normalize(item, field), `${root}.PhotoLinks`),
      MessageBoards: normalizeArray(data.MessageBoards, (item, field) => this.reportMessageBoardRecordNormalizationService.normalize(item, field), `${root}.MessageBoards`),
      PossibleBirthdays: normalizeArray(data.PossibleBirthdays, (item, field) => this.reportPossibleBirthdaysNormalizationService.normalize(item, field), `${root}.PossibleBirthdays`),
    }), root);
  }
}
