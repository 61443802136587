import { injectable } from 'inversify';
import { LegalEntitiesInsuranceFundInfo } from '~/types/legal-entities/insuranceFundInfo';
import { IPlaceholderDataService } from '../common/reportPlaceholderService.interfaces';

@injectable()
export class LegalEntitiesInsuranceFundInfoPlaceholderDataService implements IPlaceholderDataService<LegalEntitiesInsuranceFundInfo> {
  getData(): LegalEntitiesInsuranceFundInfo {
    return {
      RegNumber: '',
      RegDate: '',
      DepartmentCode: '',
      DepartmentName: '',
    };
  }
}
