import { injectable, inject } from 'inversify';
import {
  IReportUrlPlaceholderServiceKey,
  IPlaceholderDataService,
  IReportMainEntityFilePlaceholderServiceKey,
  IReportSocialNetworkTrackPlaceholderServiceKey
} from '~/services/placeholder/common/reportPlaceholderService.interfaces';
import {
  INormalizationService,
  IReportUrlNormalizationServiceKey,
  IReportMainEntityFileNormalizationServiceKey,
  IReportSocialNetworkTrackNormalizationServiceKey,
  IReportSocialNetworkIdNormalizationServiceKey
} from './reportNormalizationService.interfaces';
import { ReportMainEntityFile } from '~/types/common/reportMainEntityFile';
import { ReportSocialNetworkId } from '~/types/common/reportSocialNetworkId';
import { ReportSocialNetworkTrack } from '~/types/common/reportSocialNetworkTrack';
import { ReportUrl } from '~/types/common/reportUrl';
import { ReportSocialNetworkOtherPerson } from '~/types/common/reportSocialNetworkOtherPerson';
import { IPrimitiveTypesNormalizationServiceKey, IPrimitiveTypesNormalizationService } from './primitiveTypesNormalizationService.interfaces';

@injectable()
export class ReportSocialNetworkOtherPersonNormalizationService implements INormalizationService<ReportSocialNetworkOtherPerson> {
  constructor(

    @inject(IReportUrlNormalizationServiceKey as symbol) private reportUrlNormalizationService: INormalizationService<ReportUrl>,
    @inject(IReportMainEntityFileNormalizationServiceKey as symbol) private reportMainEntityFileNormalizationService: INormalizationService<ReportMainEntityFile>,
    @inject(IReportSocialNetworkTrackNormalizationServiceKey as symbol) private reportSocialNetworkTrackNormalizationService: INormalizationService<ReportSocialNetworkTrack>,
    @inject(IReportSocialNetworkIdNormalizationServiceKey as symbol) private reportSocialNetworkIdNormalizationService: INormalizationService<ReportSocialNetworkId>,

    @inject(IReportUrlPlaceholderServiceKey as symbol) private reportUrlPlaceholderService: IPlaceholderDataService<ReportUrl>,
    @inject(IReportMainEntityFilePlaceholderServiceKey as symbol) private reportMainEntityFilePlaceholderService: IPlaceholderDataService<ReportMainEntityFile>,
    @inject(IReportSocialNetworkTrackPlaceholderServiceKey as symbol) private reportSocialNetworkTrackPlaceholderService: IPlaceholderDataService<ReportSocialNetworkTrack>,

    @inject(IPrimitiveTypesNormalizationServiceKey as symbol) private primitiveTypesNormalizationService: IPrimitiveTypesNormalizationService,
  ) {}

  normalize(data: any, root: string): ReportSocialNetworkOtherPerson | undefined {
    const {
      normalizeObject,
      normalizeString,
      normalizeOptionalString,
      normalizeArray
    } = this.primitiveTypesNormalizationService;

    return normalizeObject(data, (data) => ({
      FirstName: normalizeString(data.FirstName, `${root}.FirstName`),
      LastName: normalizeString(data.LastName, `${root}.LastName`),
      NickName: normalizeString(data.NickName, `${root}.NickName`),
      Birthday: normalizeOptionalString(data.Birthday, `${root}.Birthday`),
      Url: this.reportUrlNormalizationService.normalize(data.Url, `${root}.Url`) ?? this.reportUrlPlaceholderService.getData(),
      Photo: this.reportMainEntityFileNormalizationService.normalize(data.Photo, `${root}.Photo`) ?? this.reportMainEntityFilePlaceholderService.getData(),
      City: normalizeString(data.City, `${root}.City`),
      SocialNetworkTrack: this.reportSocialNetworkTrackNormalizationService.normalize(data.SocialNetworkTrack, `${root}.SocialNetworkTrack`) ?? this.reportSocialNetworkTrackPlaceholderService.getData(),
      SocialNetworks: normalizeArray(data.SocialNetworks, (item, field) => this.reportSocialNetworkIdNormalizationService.normalize(item, field), `${root}.SocialNetworks`),
      PhoneNumber: normalizeString(data.PhoneNumber, `${root}.PhoneNumber`),
    }), root);
  }
}
