import { inject, injectable } from 'inversify';
import { CompositeNodeSizeSnapshot } from '~/models/nodes/snapshots/compositeNodeSizeSnapshot';
import {
  ISplittableRowLayoutingService, ISplittableRowLayoutingServiceInitialLayoutOptions,
  ISplittableRowLayoutingServiceLayoutOptions
} from '~/services/splittables/splittableRowLayoutingService.interfaces';
import { ISplittableSection } from '~/types/splittables/splittableSection';
import { ISplittablesHub } from '~/services/splittables/splittablesHub.interfaces';
import SplittableRow from '~/models/splittables/splittableRow';
import { ISplittableContext } from '~/types/splittables/splittableContext';
import NodesPagingRow from '~/models/nodes/paging/nodesPagingRow';
import {
  IFakeSplittablesFactory,
  IFakeSplittablesFactoryKey
} from '~/factories/splittables/fakeSplittablesFactory.interfaces';

@injectable()
export default class SplittableRowLayoutingService implements ISplittableRowLayoutingService {
  constructor(
    @inject(IFakeSplittablesFactoryKey as symbol) private fakeSplittablesFactory: IFakeSplittablesFactory
  ) { }

  async layoutAsync<T>(hub: ISplittablesHub, row: NodesPagingRow<T>, options: ISplittableRowLayoutingServiceLayoutOptions): Promise<ISplittableSection<T>[]> {
    const splittableRowContext = await this.getInitialLayoutAsync<T>(hub, row, {
      pageHeight: options.pageHeight(0)
    });

    let pageIdx = 1;

    while (splittableRowContext.hasFreeContent() && await splittableRowContext.createSectionAsync(options.pageHeight(pageIdx)))
      pageIdx += 1;

    return await splittableRowContext.applyLayoutAsync();
  }

  async getInitialLayoutAsync<T>(hub: ISplittablesHub, row: NodesPagingRow<T>, options: ISplittableRowLayoutingServiceInitialLayoutOptions): Promise<ISplittableContext<T>> {
    const splittables = row.cells.map((c) => (c.elements.length == 1 ? hub.getSplittable(c.elements[0]) : undefined) ?? this.fakeSplittablesFactory.create(new CompositeNodeSizeSnapshot(c.elements), c.data));
    const splittableRow = new SplittableRow(splittables);
    const splittableRowContext = await splittableRow.createContextAsync();

    await splittableRowContext.createSectionAsync(options.pageHeight, false);

    return splittableRowContext;
  }
}
