import { injectable } from 'inversify';
import {
  IIndividualEnforcementProceedingConfigService
} from '~/modules/individual/negativeInformation/individualEnforcementProceeding/services/individualEnforcementProceedingConfigService.interfaces';
import { IndividualEnforcementProceedingDTO } from '~/types/dto/individual/enforcementProceedingDTO';
import { PROCEEDINGS_FIELD_LIST_OPTIONS } from '~/modules/individual/negativeInformation/individualEnforcementProceeding/configuration/constants';
import { StringUtils } from '~/utils/stringUtils';

@injectable()
export class IndividualEnforcementProceedingDepartmentConfigService implements IIndividualEnforcementProceedingConfigService {
  getConfig(enforcementProceeding: IndividualEnforcementProceedingDTO) {
    const address = StringUtils.cleanString(enforcementProceeding.BailiffsOfficeAddress);

    const bailiffNameAddress = [enforcementProceeding.BailiffsOfficeName, address]
      .filter((item) => item);

    const bailiffNameAddressData = bailiffNameAddress.length !== 0 ? bailiffNameAddress : '';

    const executorPhoneNumber = [enforcementProceeding.BailiffFullName,
      enforcementProceeding.BailiffContactInfo].filter((item) => item);

    const executorPhoneNumberData = executorPhoneNumber.length !== 0 ? executorPhoneNumber : '';

    return {
      ...PROCEEDINGS_FIELD_LIST_OPTIONS,
      fields: [
        {
          key: 'Bailiff',
          label: 'Наименование и адрес отдела',
          value: bailiffNameAddressData
        },
        {
          key: 'BailiffExecutor',
          label: 'Судебный пристав-исполнитель,\nтелефон для получения информации',
          value: executorPhoneNumberData,
          classes: 'bailiff-executor-item'
        },
      ]
    };
  }
}
