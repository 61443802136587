import { injectable } from 'inversify';

import { ReportVehicleInsurance } from '~/types/common/reportVehicleInsurance';
import { IPlaceholderDataService } from './reportPlaceholderService.interfaces';

@injectable()
export class ReportVehicleInsurancePlaceholderService implements IPlaceholderDataService<ReportVehicleInsurance> {
  getData(): ReportVehicleInsurance {
    return {
      InsuranceType: '',
      InsuranceCompany: '',
      PolicySeries: '',
      PolicyNumber: '',
      PolicyRestrictions: '',
      Cbm: '',
      IsActive: false,
      PolicyStartDate: '',
      PolicyEndDate: '',
    };
  }
}
