import { injectable, inject } from 'inversify';
import { IndividualFullNameChange } from '~/types/individual/fullNameChange';
import { IPrimitiveTypesNormalizationServiceKey, IPrimitiveTypesNormalizationService } from '../common/primitiveTypesNormalizationService.interfaces';
import { INormalizationService } from '../common/reportNormalizationService.interfaces';

@injectable()
export class IndividualFullNameChangeNormalizationService implements INormalizationService<IndividualFullNameChange> {
  constructor(
    @inject(IPrimitiveTypesNormalizationServiceKey as symbol) private primitiveTypesNormalizationService: IPrimitiveTypesNormalizationService,
  ) {}

  normalize(data: any, root: string): IndividualFullNameChange | undefined {
    const { normalizeObject, normalizeString, normalizeOptionalString } = this.primitiveTypesNormalizationService;

    return normalizeObject(data, (data) => ({
      FirstName: normalizeString(data.FirstName, `${root}.FirstName`),
      LastName: normalizeString(data.LastName, `${root}.LastName`),
      Patronymic: normalizeString(data.Patronymic, `${root}.Patronymic`),
      FullName: normalizeString(data.FullName, `${root}.FullName`),
      ChangeDate: normalizeOptionalString(data.ChangeDate, `${root}.ChangeDate`),
      ChangeReason: normalizeString(data.ChangeReason, `${root}.ChangeReason`),
    }), root);
  }
}
