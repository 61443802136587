import { IContainerBinder } from '~/configuration/inversify/containerAccessor.interface';
import { LegalEntitiesClaimsAndDebtsConfigService } from '~/modules/legalEntities/commonInfo/legalEntitiesClaimsAndDebts/services/legalEntitiesClaimsAndDebtsConfigService';
import { ILegalEntitiesClaimsAndDebtsConfigServiceKey } from '~/modules/legalEntities/commonInfo/legalEntitiesClaimsAndDebts/services/legalEntitiesClaimsAndDebtsConfigService.interface';
import { LegalEntitiesHasClaimsAndDebtsService } from '~/modules/legalEntities/commonInfo/legalEntitiesClaimsAndDebts/services/legalEntitiesHasClaimsAndDebtsService';
import { ILegalEntitiesHasClaimsAndDebtsServiceKey } from '~/modules/legalEntities/commonInfo/legalEntitiesClaimsAndDebts/services/legalEntitiesHasClaimsAndDebtsService.interface';

export function bindLegalEntitiesClaimsAndDebtsService(bind: IContainerBinder) {
  bind(ILegalEntitiesClaimsAndDebtsConfigServiceKey).to(LegalEntitiesClaimsAndDebtsConfigService);

  bind(ILegalEntitiesHasClaimsAndDebtsServiceKey).to(LegalEntitiesHasClaimsAndDebtsService);
}
