import { default as icons_45page3Ac4Tu7j1WMeta } from "/app/node_modules/@nuxtjs/svg-sprite/dist/runtime/components/icons-page.vue?macro=true";
import { default as _404STh7p7T7N0Meta } from "/app/pages/404.vue?macro=true";
import { default as tasksyE1r12Y6JCMeta } from "/app/pages/admin/async-pdf/tasks.vue?macro=true";
import { default as auth7tE1sZcZDUMeta } from "/app/pages/auth.vue?macro=true";
import { default as _1x7Bwqzl0znMeta } from "/app/pages/charts/1.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as tasks0zOjgdDRH4Meta } from "/app/pages/main/async-pdf/tasks.vue?macro=true";
import { default as footerA2mLotzjVCMeta } from "/app/pages/puppeteer/footer.vue?macro=true";
import { default as headerRiom54EYjlMeta } from "/app/pages/puppeteer/header.vue?macro=true";
import { default as individualIPLntG6LPCMeta } from "/app/pages/report/individual.vue?macro=true";
import { default as legal_45entitiespmQawhc7lkMeta } from "/app/pages/report/legal-entities.vue?macro=true";
import { default as phone_45numberGiSZHO8CUiMeta } from "/app/pages/report/phone-number.vue?macro=true";
import { default as vehicleuRaAKbS3orMeta } from "/app/pages/report/vehicle.vue?macro=true";
export default [
  {
    name: icons_45page3Ac4Tu7j1WMeta?.name ?? "icons-page",
    path: icons_45page3Ac4Tu7j1WMeta?.path ?? "/_icons",
    meta: {...(icons_45page3Ac4Tu7j1WMeta || {}), ...{"layout":"svg-sprite"}},
    alias: icons_45page3Ac4Tu7j1WMeta?.alias || [],
    redirect: icons_45page3Ac4Tu7j1WMeta?.redirect || undefined,
    component: () => import("/app/node_modules/@nuxtjs/svg-sprite/dist/runtime/components/icons-page.vue").then(m => m.default || m)
  },
  {
    name: _404STh7p7T7N0Meta?.name ?? "404",
    path: _404STh7p7T7N0Meta?.path ?? "/404",
    meta: _404STh7p7T7N0Meta || {},
    alias: _404STh7p7T7N0Meta?.alias || [],
    redirect: _404STh7p7T7N0Meta?.redirect || undefined,
    component: () => import("/app/pages/404.vue").then(m => m.default || m)
  },
  {
    name: tasksyE1r12Y6JCMeta?.name ?? "admin-async-pdf-tasks",
    path: tasksyE1r12Y6JCMeta?.path ?? "/admin/async-pdf/tasks",
    meta: tasksyE1r12Y6JCMeta || {},
    alias: tasksyE1r12Y6JCMeta?.alias || [],
    redirect: tasksyE1r12Y6JCMeta?.redirect || undefined,
    component: () => import("/app/pages/admin/async-pdf/tasks.vue").then(m => m.default || m)
  },
  {
    name: auth7tE1sZcZDUMeta?.name ?? "auth",
    path: auth7tE1sZcZDUMeta?.path ?? "/auth",
    meta: auth7tE1sZcZDUMeta || {},
    alias: auth7tE1sZcZDUMeta?.alias || [],
    redirect: auth7tE1sZcZDUMeta?.redirect || undefined,
    component: () => import("/app/pages/auth.vue").then(m => m.default || m)
  },
  {
    name: _1x7Bwqzl0znMeta?.name ?? "charts-1",
    path: _1x7Bwqzl0znMeta?.path ?? "/charts/1",
    meta: _1x7Bwqzl0znMeta || {},
    alias: _1x7Bwqzl0znMeta?.alias || [],
    redirect: _1x7Bwqzl0znMeta?.redirect || undefined,
    component: () => import("/app/pages/charts/1.vue").then(m => m.default || m)
  },
  {
    name: indexN6pT4Un8hYMeta?.name ?? "index",
    path: indexN6pT4Un8hYMeta?.path ?? "/",
    meta: indexN6pT4Un8hYMeta || {},
    alias: indexN6pT4Un8hYMeta?.alias || [],
    redirect: indexN6pT4Un8hYMeta?.redirect || undefined,
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: tasks0zOjgdDRH4Meta?.name ?? "main-async-pdf-tasks",
    path: tasks0zOjgdDRH4Meta?.path ?? "/main/async-pdf/tasks",
    meta: tasks0zOjgdDRH4Meta || {},
    alias: tasks0zOjgdDRH4Meta?.alias || [],
    redirect: tasks0zOjgdDRH4Meta?.redirect || undefined,
    component: () => import("/app/pages/main/async-pdf/tasks.vue").then(m => m.default || m)
  },
  {
    name: footerA2mLotzjVCMeta?.name ?? "puppeteer-footer",
    path: footerA2mLotzjVCMeta?.path ?? "/puppeteer/footer",
    meta: footerA2mLotzjVCMeta || {},
    alias: footerA2mLotzjVCMeta?.alias || [],
    redirect: footerA2mLotzjVCMeta?.redirect || undefined,
    component: () => import("/app/pages/puppeteer/footer.vue").then(m => m.default || m)
  },
  {
    name: headerRiom54EYjlMeta?.name ?? "puppeteer-header",
    path: headerRiom54EYjlMeta?.path ?? "/puppeteer/header",
    meta: headerRiom54EYjlMeta || {},
    alias: headerRiom54EYjlMeta?.alias || [],
    redirect: headerRiom54EYjlMeta?.redirect || undefined,
    component: () => import("/app/pages/puppeteer/header.vue").then(m => m.default || m)
  },
  {
    name: individualIPLntG6LPCMeta?.name ?? "report-individual",
    path: individualIPLntG6LPCMeta?.path ?? "/report/individual",
    meta: individualIPLntG6LPCMeta || {},
    alias: individualIPLntG6LPCMeta?.alias || [],
    redirect: individualIPLntG6LPCMeta?.redirect || undefined,
    component: () => import("/app/pages/report/individual.vue").then(m => m.default || m)
  },
  {
    name: legal_45entitiespmQawhc7lkMeta?.name ?? "report-legal-entities",
    path: legal_45entitiespmQawhc7lkMeta?.path ?? "/report/legal-entities",
    meta: legal_45entitiespmQawhc7lkMeta || {},
    alias: legal_45entitiespmQawhc7lkMeta?.alias || [],
    redirect: legal_45entitiespmQawhc7lkMeta?.redirect || undefined,
    component: () => import("/app/pages/report/legal-entities.vue").then(m => m.default || m)
  },
  {
    name: phone_45numberGiSZHO8CUiMeta?.name ?? "report-phone-number",
    path: phone_45numberGiSZHO8CUiMeta?.path ?? "/report/phone-number",
    meta: phone_45numberGiSZHO8CUiMeta || {},
    alias: phone_45numberGiSZHO8CUiMeta?.alias || [],
    redirect: phone_45numberGiSZHO8CUiMeta?.redirect || undefined,
    component: () => import("/app/pages/report/phone-number.vue").then(m => m.default || m)
  },
  {
    name: vehicleuRaAKbS3orMeta?.name ?? "report-vehicle",
    path: vehicleuRaAKbS3orMeta?.path ?? "/report/vehicle",
    meta: vehicleuRaAKbS3orMeta || {},
    alias: vehicleuRaAKbS3orMeta?.alias || [],
    redirect: vehicleuRaAKbS3orMeta?.redirect || undefined,
    component: () => import("/app/pages/report/vehicle.vue").then(m => m.default || m)
  }
]