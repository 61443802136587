import { injectable } from 'inversify';
import { isEqual } from 'lodash-es';
import { MergeService } from '~/services/merge/merge-service';
import { ReportJobDTO } from '~/types/dto/common/reportJobDTO';

@injectable()
export class ReportJobMergeService extends MergeService<ReportJobDTO> {
  protected canMerge(leftItem: ReportJobDTO, rightItem: ReportJobDTO): boolean {
    return isEqual(leftItem, rightItem);
  }

  protected mergeFields(leftItem: ReportJobDTO, rightItem: ReportJobDTO): ReportJobDTO {
    return {
      CompanyName: leftItem.CompanyName || rightItem.CompanyName,
      CompanyInn: leftItem.CompanyInn || rightItem.CompanyInn,
      DepartmentName: leftItem.DepartmentName || rightItem.DepartmentName,
      Position: leftItem.Position || rightItem.Position,
      StartDate: leftItem.StartDate || rightItem.StartDate,
      EndDate: leftItem.EndDate || rightItem.EndDate,
      CompanyAddress: leftItem.CompanyAddress || rightItem.CompanyAddress,
      CompanyPhone: leftItem.CompanyPhone || rightItem.CompanyPhone,
      CompanyHeadPhone: leftItem.CompanyHeadPhone || rightItem.CompanyHeadPhone,
      CompanyBookkeeperPhone: leftItem.CompanyBookkeeperPhone || rightItem.CompanyBookkeeperPhone,
      ContractType: leftItem.ContractType || rightItem.ContractType,
      ArticleDissmissal: leftItem.ArticleDissmissal || rightItem.ArticleDissmissal,
      Payments: leftItem.Payments || rightItem.Payments,
      Colleagues: leftItem.Colleagues || rightItem.Colleagues,
    };
  }
}
