import {
  ILegalEntitiesDataBlobDataNormalizationServiceKey,
  ILegalEntitiesCommonNormalizationServiceKey,
  ILegalEntitiesBriefInfoNormalizationServiceKey,
  ILegalEntitiesTaxServiceNormalizationServiceKey,
  ILegalEntitiesCompanyActualAddressNormalizationServiceKey,
  ILegalEntitiesConditionAssessmentNormalizationServiceKey,
  ILegalEntitiesTaxModeNormalizationServiceKey,
  ILegalEntitiesRegistrarOfShareholdersNormalizationServiceKey,
  ILegalEntitiesLesseeSummaryNormalizationServiceKey,
  ILegalEntitiesActivitySummaryNormalizationServiceKey,
  ILegalEntitiesCompanySiteNormalizationServiceKey,
  ILegalEntitiesCompanyPhoneNormalizationServiceKey,
  ILegalEntitiesCompanyEmailNormalizationServiceKey,
  ILegalEntitiesSocialNormalizationServiceKey,
  ILegalEntitiesCompanyStatedCapitalNormalizationServiceKey,
  ILegalEntitiesCompanyHistoryAddressNormalizationServiceKey,
  ILegalEntitiesCompanyHeadFlNormalizationServiceKey,
  ILegalEntitiesCompanyManagementCompaniesNormalizationServiceKey,
  ILegalEntitiesCompanyFounderShareNormalizationServiceKey,
  ILegalEntitiesCompanyFounderNormalizationServiceKey,
  ILegalEntitiesCourtCasesByYearNormalizationServiceKey,
  ILegalEntitiesGovernmentControlNormalizationServiceKey,
  ILegalEntitiesEnforcementProceedingsInfoBySubjectNormalizationServiceKey,
  ILegalEntitiesRelatedCompanyNormalizationServiceKey,
  ILegalEntitiesRelatedEgrulNormalizationServiceKey,
  ILegalEntitiesPersonNormalizationServiceKey,
  ILegalEntitiesRelatedReasonNormalizationServiceKey,
  ILegalEntitiesConflictSignNormalizationServiceKey,
  ILegalEntitiesTaxNormalizationServiceKey,
  ILegalEntitiesFineAndTaxDebtNormalizationServiceKey,
  ILegalEntitiesContractsInfoByYearNormalizationServiceKey,
  ILegalEntitiesSubCompanyInfoNormalizationServiceKey,
  ILegalEntitiesPredecessorNormalizationServiceKey,
  ILegalEntitiesSuccessorNormalizationServiceKey,
  ILegalEntitiesReorganizationMemberNormalizationServiceKey,
  ILegalEntitiesShareholderNormalizationServiceKey,
  ILegalEntitiesEgrRecordNormalizationServiceKey,
  ILegalEntitiesCertificateSummaryNormalizationServiceKey,
  ILegalEntitiesLicenseSummaryNormalizationServiceKey,
  ILegalEntitiesSelfRegulatoryOrganizationNormalizationServiceKey,
  ILegalEntitiesTrademarkNormalizationServiceKey,
  ILegalEntitiesCompanyNameNormalizationServiceKey,
  ILegalEntitiesInsuranceFundInfoNormalizationServiceKey,
  ILegalEntitiesReportSocialNetworkIdNormalizationServiceKey,
  ILegalEntitiesCompanyEmployeeInfoNormalizationServiceKey,
  ILegalEntitiesFinancialStateNormalizationServiceKey,
  ILegalEntitiesReportDataBuhFormDetailNormalizationServiceKey,
  ILegalEntitiesReportDataBuhFormNormalizationServiceKey,
  ILegalEntitiesFinancialCommonNormalizationServiceKey,
  ILegalEntitiesMarkersNormalizationServiceKey,
  ILegalEntitiesFinancialAnalysisShortNormalizationServiceKey,
  ILegalEntitiesReportFinancialIndicatorWithDynamicNormalizationServiceKey,
  ILegalEntitiesReportFinancialIndicatorWithConclusionsNormalizationServiceKey,
  ILegalEntitiesReportFinancialIndicatorBaseNormalizationServiceKey,
  ILegalEntitiesFinancialAnalysisExtendedNormalizationServiceKey,
  ILegalEntitiesReportLiquidityAndSolvencyNormalizationServiceKey,
  ILegalEntitiesReportFinancialStabilityNormalizationServiceKey,
  ILegalEntitiesReportAnalysisOfAbsoluteLiquidityIndicatorNormalizationServiceKey,
  ILegalEntitiesReportAverageIndustryIndicatorNormalizationServiceKey,
  ILegalEntitiesTotalScoreNormalizationServiceKey,
  ILegalEntitiesTaxesPenaltiesNormalizationServiceKey,
  ILegalEntitiesCompanyTaxRegimesNormalizationServiceKey,
  ILegalEntitiesBankAccountNormalizationServiceKey,
  ILegalEntitiesAverageHeadcountNormalizationServiceKey,
  ILegalEntitiesAnalyticsIndicatorsNormalizationServiceKey,
} from '~/services/normalization/legalEntities/legalEntitiesNormalizationService.interfaces';
import { LegalEntitiesActivitySummaryNormalizationService } from '~/services/normalization/legalEntities/legalEntitiesActivitySummaryNormalizationService';
import { LegalEntitiesBriefInfoNormalizationService } from '~/services/normalization/legalEntities/legalEntitiesBriefInfoNormalizationService';
import { LegalEntitiesCertificateSummaryNormalizationService } from '~/services/normalization/legalEntities/legalEntitiesCertificateSummaryNormalizationService';
import { LegalEntitiesCommonNormalizationService } from '~/services/normalization/legalEntities/legalEntitiesCommonNormalizationService';
import { LegalEntitiesCompanyActualAddressNormalizationService } from '~/services/normalization/legalEntities/legalEntitiesCompanyActualAddressNormalizationService';
import { LegalEntitiesCompanyEmailNormalizeService } from '~/services/normalization/legalEntities/legalEntitiesCompanyEmailNormalizationService';
import { LegalEntitiesCompanyEmployeeInfoNormalizationService } from '~/services/normalization/legalEntities/legalEntitiesCompanyEmployeeInfoNormalizationService';
import { LegalEntitiesCompanyFounderNormalizationService } from '~/services/normalization/legalEntities/legalEntitiesCompanyFounderNormalizationService';
import { LegalEntitiesCompanyFounderShareNormalizationService } from '~/services/normalization/legalEntities/legalEntitiesCompanyFounderShareNormalizationService';
import { LegalEntitiesCompanyHeadFlNormalizationService } from '~/services/normalization/legalEntities/legalEntitiesCompanyHeadFlNormalizationService';
import { LegalEntitiesCompanyHistoryAddressNormalizationService } from '~/services/normalization/legalEntities/legalEntitiesCompanyHistoryAddressNormalizationService';
import { LegalEntitiesCompanyManagementCompaniesNormalizationService } from '~/services/normalization/legalEntities/legalEntitiesCompanyManagementCompaniesNormalizationService';
import { LegalEntitiesCompanyNameNormalizationService } from '~/services/normalization/legalEntities/legalEntitiesCompanyNameNormalizationService';
import { LegalEntitiesCompanyPhoneNormalizationService } from '~/services/normalization/legalEntities/legalEntitiesCompanyPhoneNormalizationService';
import { LegalEntitiesCompanySiteNormalizationService } from '~/services/normalization/legalEntities/legalEntitiesCompanySiteNormalizationService';
import { LegalEntitiesCompanyStatedCapitalNormalizationService } from '~/services/normalization/legalEntities/legalEntitiesCompanyStatedCapitalNormalizationService';
import { LegalEntitiesConditionAssessmentNormalizationService } from '~/services/normalization/legalEntities/legalEntitiesConditionAssessmentNormalizationService';
import { LegalEntitiesConflictSignNormalizationService } from '~/services/normalization/legalEntities/legalEntitiesConflictSignNormalizationService';
import { LegalEntitiesContractsInfoByYearNormalizationService } from '~/services/normalization/legalEntities/legalEntitiesContractsInfoByYearNormalizationService';
import { LegalEntitiesCourtCasesByYearNormalizationService } from '~/services/normalization/legalEntities/legalEntitiesCourtCasesByYearNormalizationService';
import { LegalEntitiesDataBlobDataNormalizationService } from '~/services/normalization/legalEntities/legalEntitiesDataBlobDataNormalizationService';
import { LegalEntitiesEgrRecordNormalizationService } from '~/services/normalization/legalEntities/legalEntitiesEgrRecordNormalizationService';
import { LegalEntitiesEnforcementProceedingsInfoBySubjectNormalizationService } from '~/services/normalization/legalEntities/legalEntitiesEnforcementProceedingsInfoBySubjectNormalizationService';
import { LegalEntitiesFinancialAnalysisExtendedNormalizationService } from '~/services/normalization/legalEntities/legalEntitiesFinancialAnalysisExtendedNormalizationService';
import { LegalEntitiesFinancialAnalysisShortNormalizationService } from '~/services/normalization/legalEntities/legalEntitiesFinancialAnalysisShortNormalizationService';
import { LegalEntitiesFinancialCommonNormalizationService } from '~/services/normalization/legalEntities/legalEntitiesFinancialCommonNormalizationService';
import { LegalEntitiesFinancialStateNormalizationService } from '~/services/normalization/legalEntities/legalEntitiesFinancialStateNormalizationService';
import { LegalEntitiesFineAndTaxDebtNormalizationService } from '~/services/normalization/legalEntities/legalEntitiesFineAndTaxDebtNormalizationService';
import { LegalEntitiesGovernmentControlNormalizationService } from '~/services/normalization/legalEntities/legalEntitiesGovernmentControlNormalizationService';
import { LegalEntitiesInsuranceFundInfoNormalizationService } from '~/services/normalization/legalEntities/legalEntitiesInsuranceFundInfoNormalizationService';
import { LegalEntitiesLesseeSummaryNormalizationService } from '~/services/normalization/legalEntities/legalEntitiesLesseeSummaryNormalizationService';
import { LegalEntitiesLicenseSummaryNormalizationService } from '~/services/normalization/legalEntities/legalEntitiesLicenseSummaryNormalizationService';
import { LegalEntitiesMarkersNormalizationService } from '~/services/normalization/legalEntities/legalEntitiesMarkersNormalizationService';
import { LegalEntitiesPersonNormalizationService } from '~/services/normalization/legalEntities/legalEntitiesPersonNormalizationService';
import { LegalEntitiesPredecessorNormalizationService } from '~/services/normalization/legalEntities/legalEntitiesPredecessorNormalizationService';
import { LegalEntitiesRegistrarOfShareholdersNormalizationService } from '~/services/normalization/legalEntities/legalEntitiesRegistrarOfShareholdersNormalizationService';
import { LegalEntitiesRelatedCompanyNormalizationService } from '~/services/normalization/legalEntities/legalEntitiesRelatedCompanyNormalizationService';
import { LegalEntitiesRelatedEgrulNormalizationService } from '~/services/normalization/legalEntities/legalEntitiesRelatedEgrulNormalizationService';
import { LegalEntitiesRelatedReasonNormalizationService } from '~/services/normalization/legalEntities/legalEntitiesRelatedReasonNormalizationService';
import { LegalEntitiesReorganizationMemberNormalizationService } from '~/services/normalization/legalEntities/legalEntitiesReorganizationMemberNormalizationService';
import { LegalEntitiesReportAnalysisOfAbsoluteLiquidityIndicatorNormalizationService } from '~/services/normalization/legalEntities/legalEntitiesReportAnalysisOfAbsoluteLiquidityIndicatorNormalizationService';
import { LegalEntitiesReportAverageIndustryIndicatorNormalizationService } from '~/services/normalization/legalEntities/legalEntitiesReportAverageIndustryIndicatorNormalizationService';
import { LegalEntitiesReportDataBuhFormDetailNormalizationService } from '~/services/normalization/legalEntities/legalEntitiesReportDataBuhFormDetailNormalizationService';
import { LegalEntitiesReportDataBuhFormNormalizationService } from '~/services/normalization/legalEntities/legalEntitiesReportDataBuhFormNormalizationService';
import { LegalEntitiesReportFinancialIndicatorBaseNormalizationService } from '~/services/normalization/legalEntities/legalEntitiesReportFinancialIndicatorBaseNormalizationService';
import { LegalEntitiesReportFinancialIndicatorWithConclusionsNormalizationService } from '~/services/normalization/legalEntities/legalEntitiesReportFinancialIndicatorWithConclusionsNormalizationService';
import { LegalEntitiesReportFinancialIndicatorWithDynamicNormalizationService } from '~/services/normalization/legalEntities/legalEntitiesReportFinancialIndicatorWithDynamicNormalizationService';
import { LegalEntitiesReportFinancialStabilityNormalizationService } from '~/services/normalization/legalEntities/legalEntitiesReportFinancialStabilityNormalizationService';
import { LegalEntitiesReportLiquidityAndSolvencyNormalizationService } from '~/services/normalization/legalEntities/legalEntitiesReportLiquidityAndSolvencyNormalizationService';
import { LegalEntitiesReportSocialNetworkIdNormalizationService } from '~/services/normalization/legalEntities/legalEntitiesReportSocialNetworkIdNormalizationService';
import { LegalEntitiesSelfRegulatoryOrganizationNormalizationService } from '~/services/normalization/legalEntities/legalEntitiesSelfRegulatoryOrganizationNormalizationService';
import { LegalEntitiesShareholderNormalizationService } from '~/services/normalization/legalEntities/legalEntitiesShareholderNormalizationService';
import { LegalEntitiesSocialNormalizationService } from '~/services/normalization/legalEntities/legalEntitiesSocialNormalizationService';
import { LegalEntitiesSubCompanyInfoNormalizationService } from '~/services/normalization/legalEntities/legalEntitiesSubCompanyInfoNormalizationService';
import { LegalEntitiesSuccessorNormalizationService } from '~/services/normalization/legalEntities/legalEntitiesSuccessorNormalizationService';
import { LegalEntitiesTaxModeNormalizationService } from '~/services/normalization/legalEntities/legalEntitiesTaxModeNormalizationService';
import { LegalEntitiesTaxNormalizationService } from '~/services/normalization/legalEntities/legalEntitiesTaxNormalizationService';
import { LegalEntitiesTaxServiceNormalizationService } from '~/services/normalization/legalEntities/legalEntitiesTaxServiceNormalizationService';
import { LegalEntitiesTotalScoreNormalizationService } from '~/services/normalization/legalEntities/legalEntitiesTotalScoreNormalizationService';
import { LegalEntitiesTrademarkNormalizationService } from '~/services/normalization/legalEntities/legalEntitiesTrademarkNormalizationService';
import { IContainerBinder } from './containerAccessor.interface';
import { ILegalEntitiesCompanyStatusNormalizationServiceKey, LegalEntitiesCompanyStatusNormalizationService } from '~/services/normalization/legalEntities/legalEntitiesCompanyStatusNormalizationService';
import { LegalEntitiesTaxesPenaltiesNormalizationService } from '~/services/normalization/legalEntities/legalEntitiesTaxesPenaltiesNormalizationService';
import { LegalEntitiesCompanyTaxRegimesNormalizationService } from '~/services/normalization/legalEntities/legalEntitiesCompanyTaxRegimesNormalizationService';
import { LegalEntitiesBankAccountNormalizationService } from '~/services/normalization/legalEntities/legalEntitiesBankAccountNormalizationService';
import { LegalEntitiesAverageHeadcountNormalizationService } from '~/services/normalization/legalEntities/legalEntitiesAverageHeadcountNormalizationService';
import {
  LegalEntitiesAnalyticsIndicatorsNormalizationService
} from '~/services/normalization/legalEntities/legalEntitiesAnalyticsIndicatorsNormalizationService';

export function bindLegalEntitiesNormalizationServices(bind: IContainerBinder) {
  bind(ILegalEntitiesCompanyStatusNormalizationServiceKey).to(LegalEntitiesCompanyStatusNormalizationService);
  bind(ILegalEntitiesDataBlobDataNormalizationServiceKey).to(LegalEntitiesDataBlobDataNormalizationService);
  bind(ILegalEntitiesCommonNormalizationServiceKey).to(LegalEntitiesCommonNormalizationService);
  bind(ILegalEntitiesBriefInfoNormalizationServiceKey).to(LegalEntitiesBriefInfoNormalizationService);
  bind(ILegalEntitiesTaxServiceNormalizationServiceKey).to(LegalEntitiesTaxServiceNormalizationService);
  bind(ILegalEntitiesCompanyActualAddressNormalizationServiceKey).to(LegalEntitiesCompanyActualAddressNormalizationService);
  bind(ILegalEntitiesCompanyTaxRegimesNormalizationServiceKey).to(LegalEntitiesCompanyTaxRegimesNormalizationService);
  bind(ILegalEntitiesConditionAssessmentNormalizationServiceKey).to(LegalEntitiesConditionAssessmentNormalizationService);
  bind(ILegalEntitiesTaxModeNormalizationServiceKey).to(LegalEntitiesTaxModeNormalizationService);
  bind(ILegalEntitiesRegistrarOfShareholdersNormalizationServiceKey).to(LegalEntitiesRegistrarOfShareholdersNormalizationService);
  bind(ILegalEntitiesLesseeSummaryNormalizationServiceKey).to(LegalEntitiesLesseeSummaryNormalizationService);
  bind(ILegalEntitiesActivitySummaryNormalizationServiceKey).to(LegalEntitiesActivitySummaryNormalizationService);
  bind(ILegalEntitiesCompanySiteNormalizationServiceKey).to(LegalEntitiesCompanySiteNormalizationService);
  bind(ILegalEntitiesCompanyPhoneNormalizationServiceKey).to(LegalEntitiesCompanyPhoneNormalizationService);
  bind(ILegalEntitiesCompanyEmailNormalizationServiceKey).to(LegalEntitiesCompanyEmailNormalizeService);
  bind(ILegalEntitiesSocialNormalizationServiceKey).to(LegalEntitiesSocialNormalizationService);
  bind(ILegalEntitiesCompanyStatedCapitalNormalizationServiceKey).to(LegalEntitiesCompanyStatedCapitalNormalizationService);
  bind(ILegalEntitiesCompanyHistoryAddressNormalizationServiceKey).to(LegalEntitiesCompanyHistoryAddressNormalizationService);
  bind(ILegalEntitiesCompanyHeadFlNormalizationServiceKey).to(LegalEntitiesCompanyHeadFlNormalizationService);
  bind(ILegalEntitiesCompanyManagementCompaniesNormalizationServiceKey).to(LegalEntitiesCompanyManagementCompaniesNormalizationService);
  bind(ILegalEntitiesCompanyFounderShareNormalizationServiceKey).to(LegalEntitiesCompanyFounderShareNormalizationService);
  bind(ILegalEntitiesCompanyFounderNormalizationServiceKey).to(LegalEntitiesCompanyFounderNormalizationService);
  bind(ILegalEntitiesCourtCasesByYearNormalizationServiceKey).to(LegalEntitiesCourtCasesByYearNormalizationService);
  bind(ILegalEntitiesGovernmentControlNormalizationServiceKey).to(LegalEntitiesGovernmentControlNormalizationService);
  bind(ILegalEntitiesEnforcementProceedingsInfoBySubjectNormalizationServiceKey).to(LegalEntitiesEnforcementProceedingsInfoBySubjectNormalizationService);
  bind(ILegalEntitiesRelatedCompanyNormalizationServiceKey).to(LegalEntitiesRelatedCompanyNormalizationService);
  bind(ILegalEntitiesRelatedEgrulNormalizationServiceKey).to(LegalEntitiesRelatedEgrulNormalizationService);
  bind(ILegalEntitiesPersonNormalizationServiceKey).to(LegalEntitiesPersonNormalizationService);
  bind(ILegalEntitiesRelatedReasonNormalizationServiceKey).to(LegalEntitiesRelatedReasonNormalizationService);
  bind(ILegalEntitiesConflictSignNormalizationServiceKey).to(LegalEntitiesConflictSignNormalizationService);
  bind(ILegalEntitiesTaxNormalizationServiceKey).to(LegalEntitiesTaxNormalizationService);
  bind(ILegalEntitiesFineAndTaxDebtNormalizationServiceKey).to(LegalEntitiesFineAndTaxDebtNormalizationService);
  bind(ILegalEntitiesTaxesPenaltiesNormalizationServiceKey).to(LegalEntitiesTaxesPenaltiesNormalizationService);
  bind(ILegalEntitiesContractsInfoByYearNormalizationServiceKey).to(LegalEntitiesContractsInfoByYearNormalizationService);
  bind(ILegalEntitiesSubCompanyInfoNormalizationServiceKey).to(LegalEntitiesSubCompanyInfoNormalizationService);
  bind(ILegalEntitiesPredecessorNormalizationServiceKey).to(LegalEntitiesPredecessorNormalizationService);
  bind(ILegalEntitiesSuccessorNormalizationServiceKey).to(LegalEntitiesSuccessorNormalizationService);
  bind(ILegalEntitiesReorganizationMemberNormalizationServiceKey).to(LegalEntitiesReorganizationMemberNormalizationService);
  bind(ILegalEntitiesShareholderNormalizationServiceKey).to(LegalEntitiesShareholderNormalizationService);
  bind(ILegalEntitiesEgrRecordNormalizationServiceKey).to(LegalEntitiesEgrRecordNormalizationService);
  bind(ILegalEntitiesCertificateSummaryNormalizationServiceKey).to(LegalEntitiesCertificateSummaryNormalizationService);
  bind(ILegalEntitiesLicenseSummaryNormalizationServiceKey).to(LegalEntitiesLicenseSummaryNormalizationService);
  bind(ILegalEntitiesSelfRegulatoryOrganizationNormalizationServiceKey).to(LegalEntitiesSelfRegulatoryOrganizationNormalizationService);
  bind(ILegalEntitiesTrademarkNormalizationServiceKey).to(LegalEntitiesTrademarkNormalizationService);
  bind(ILegalEntitiesCompanyNameNormalizationServiceKey).to(LegalEntitiesCompanyNameNormalizationService);
  bind(ILegalEntitiesInsuranceFundInfoNormalizationServiceKey).to(LegalEntitiesInsuranceFundInfoNormalizationService);
  bind(ILegalEntitiesReportSocialNetworkIdNormalizationServiceKey).to(LegalEntitiesReportSocialNetworkIdNormalizationService);
  bind(ILegalEntitiesCompanyEmployeeInfoNormalizationServiceKey).to(LegalEntitiesCompanyEmployeeInfoNormalizationService);
  bind(ILegalEntitiesFinancialStateNormalizationServiceKey).to(LegalEntitiesFinancialStateNormalizationService);
  bind(ILegalEntitiesReportDataBuhFormDetailNormalizationServiceKey).to(LegalEntitiesReportDataBuhFormDetailNormalizationService);
  bind(ILegalEntitiesReportDataBuhFormNormalizationServiceKey).to(LegalEntitiesReportDataBuhFormNormalizationService);
  bind(ILegalEntitiesFinancialCommonNormalizationServiceKey).to(LegalEntitiesFinancialCommonNormalizationService);
  bind(ILegalEntitiesMarkersNormalizationServiceKey).to(LegalEntitiesMarkersNormalizationService);
  bind(ILegalEntitiesFinancialAnalysisShortNormalizationServiceKey).to(LegalEntitiesFinancialAnalysisShortNormalizationService);
  bind(ILegalEntitiesReportFinancialIndicatorWithDynamicNormalizationServiceKey).to(LegalEntitiesReportFinancialIndicatorWithDynamicNormalizationService);
  bind(ILegalEntitiesReportFinancialIndicatorWithConclusionsNormalizationServiceKey).to(LegalEntitiesReportFinancialIndicatorWithConclusionsNormalizationService);
  bind(ILegalEntitiesReportFinancialIndicatorBaseNormalizationServiceKey).to(LegalEntitiesReportFinancialIndicatorBaseNormalizationService);
  bind(ILegalEntitiesFinancialAnalysisExtendedNormalizationServiceKey).to(LegalEntitiesFinancialAnalysisExtendedNormalizationService);
  bind(ILegalEntitiesReportLiquidityAndSolvencyNormalizationServiceKey).to(LegalEntitiesReportLiquidityAndSolvencyNormalizationService);
  bind(ILegalEntitiesReportFinancialStabilityNormalizationServiceKey).to(LegalEntitiesReportFinancialStabilityNormalizationService);
  bind(ILegalEntitiesReportAnalysisOfAbsoluteLiquidityIndicatorNormalizationServiceKey).to(LegalEntitiesReportAnalysisOfAbsoluteLiquidityIndicatorNormalizationService);
  bind(ILegalEntitiesReportAverageIndustryIndicatorNormalizationServiceKey).to(LegalEntitiesReportAverageIndustryIndicatorNormalizationService);
  bind(ILegalEntitiesTotalScoreNormalizationServiceKey).to(LegalEntitiesTotalScoreNormalizationService);
  bind(ILegalEntitiesBankAccountNormalizationServiceKey).to(LegalEntitiesBankAccountNormalizationService);
  bind(ILegalEntitiesAverageHeadcountNormalizationServiceKey).to(LegalEntitiesAverageHeadcountNormalizationService);
  bind(ILegalEntitiesAnalyticsIndicatorsNormalizationServiceKey).to(LegalEntitiesAnalyticsIndicatorsNormalizationService);
}
