import { injectable } from "inversify";
import { MergeService } from "~/services/merge/merge-service";
import { IndividualTrustScoringDTO } from "~/types/dto/individual/trustScoringDTO";

@injectable()
export class IndividualTrustScoringMergeService extends MergeService<IndividualTrustScoringDTO> {
  protected canMerge(leftItem: IndividualTrustScoringDTO, rightItem: IndividualTrustScoringDTO): boolean {
    return leftItem.CheckedFio === rightItem.CheckedFio;
  }

  protected mergeFields(leftItem: IndividualTrustScoringDTO, rightItem: IndividualTrustScoringDTO): IndividualTrustScoringDTO {
    return {
      CheckedBirthday: leftItem.CheckedBirthday ?? rightItem.CheckedBirthday,
      CheckedFio: leftItem.CheckedFio ?? rightItem.CheckedFio,
      IncidentColorOne: leftItem.IncidentColorOne ?? rightItem.IncidentColorOne,
      IncidentColorTwo: leftItem.IncidentColorTwo ?? rightItem.IncidentColorTwo,
      IncidentColorThree: leftItem.IncidentColorThree ?? rightItem.IncidentColorThree,
      IncidentColorFour: leftItem.IncidentColorFour ?? rightItem.IncidentColorFour,
      IncidentColorFive: leftItem.IncidentColorFive ?? rightItem.IncidentColorFive,
      IncidentColorSix: leftItem.IncidentColorSix ?? rightItem.IncidentColorSix,
      IncidentTypeOne: leftItem.IncidentTypeOne ?? rightItem.IncidentTypeOne,
      IncidentTypeTwo: leftItem.IncidentTypeTwo ?? rightItem.IncidentTypeTwo,
      IncidentTypeThree: leftItem.IncidentTypeThree ?? rightItem.IncidentTypeThree,
      IncidentTypeFive: leftItem.IncidentTypeFive ?? rightItem.IncidentTypeFive,
      IncidentTypeFour: leftItem.IncidentTypeFour ?? rightItem.IncidentTypeFour,
      IncidentTypeSix: leftItem.IncidentTypeSix ?? rightItem.IncidentTypeSix,
    }
  }
}