import { injectable, inject } from 'inversify';
import { IIndividualWantedFsinAdapterService } from '~/services/adapters/individual/individualAdapterService.interfaces';
import {
  IReportMainEntityFileAdapterService,
  IReportMainEntityFileAdapterServiceKey
} from '~/services/adapters/common/reportAdapterService.interfeces';
import { IndividualWantedFsin } from '~/types/individual/wantedFsin';
import { IndividualWantedFsinDTO } from '~/types/dto/individual/individualWantedFsinDTO';
import { StringUtils } from '~/utils/stringUtils';

@injectable()
export class IndividualWantedFsinAdapterService implements IIndividualWantedFsinAdapterService {
  constructor(
    @inject(IReportMainEntityFileAdapterServiceKey as symbol) private reportMainEntityFileAdapterService: IReportMainEntityFileAdapterService
  ) {}
  getData(initData: IndividualWantedFsin[]): IndividualWantedFsinDTO[] {
    return (initData ?? []).map((item) => ({
      Photo: this.reportMainEntityFileAdapterService.getData(item.Photo),
      WantedInfo: StringUtils.quoteReplacement(item.WantedInfo),
      TerritorialDepartmentName: StringUtils.quoteReplacement(item.TerritorialDepartmentName),
      FederalDepartmentName: StringUtils.quoteReplacement(item.FederalDepartmentName)
    }));
  }
}
