import { injectable, inject } from 'inversify';
import { LegalEntitiesCompanyStatedCapital } from '~/types/legal-entities/companyStatedCapital';
import { IPrimitiveTypesNormalizationServiceKey, IPrimitiveTypesNormalizationService } from '../common/primitiveTypesNormalizationService.interfaces';
import { INormalizationService } from '../common/reportNormalizationService.interfaces';

@injectable()
export class LegalEntitiesCompanyStatedCapitalNormalizationService implements INormalizationService<LegalEntitiesCompanyStatedCapital> {
  constructor(
    @inject(IPrimitiveTypesNormalizationServiceKey as symbol) private primitiveTypesNormalizationService: IPrimitiveTypesNormalizationService,
  ) {}

  normalize(data: any, root: string): LegalEntitiesCompanyStatedCapital | undefined {
    const { normalizeOptionalInteger, normalizeOptionalString, normalizeObject } = this.primitiveTypesNormalizationService;

    return normalizeObject(data, (data) => ({
      Sum: normalizeOptionalInteger(data.Sum, `${root}.Sum`),
      From: normalizeOptionalString(data.From, `${root}.From`),
      To: normalizeOptionalString(data.To, `${root}.To`)
    }), root);
  }
}
