import { injectable } from 'inversify';
import { LegalEntitiesCompanyHistoryAddress } from '~/types/legal-entities/companyHistoryAddress';
import { IPlaceholderDataService } from '../common/reportPlaceholderService.interfaces';

@injectable()
export class LegalEntitiesCompanyHistoryAddressPlaceholderDataService implements IPlaceholderDataService<LegalEntitiesCompanyHistoryAddress> {
  getData(): LegalEntitiesCompanyHistoryAddress {
    return {
      Address: '',
      DateStart: '',
      DateEnd: '',
      CompanyStatus: [],
      UnreliabilityCode: '',
      UnreliabilityDate: '',
      IsRegionChanged: false
    };
  }
}
