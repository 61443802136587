/* eslint-disable class-methods-use-this, max-len */

import { injectable } from 'inversify';
import { LegalEntitiesCourtCasesByYear } from '~/types/legal-entities/courtCasesByYear';
import {
  ILegalEntitiesCourtCasesByYearAdapterService
} from '~/services/adapters/legalEntities/legalEntitiesAdapterService.interfaces';
import { LegalEntitiesCourtCasesByYearDTO } from '~/types/dto/legalEntities/courtCasesByYear';

@injectable()
export class LegalEntitiesCourtCasesByYearAdapterService implements ILegalEntitiesCourtCasesByYearAdapterService {
  getData(initData: LegalEntitiesCourtCasesByYear[]): LegalEntitiesCourtCasesByYearDTO[] {
    return (initData ?? []).map((item) => ({
      CaseCount: item.CaseCount,
      CaseType: item.CaseType,
      Sum: item.Sum,
      Year: item.Year
    }));
  }
}
