import { injectable, inject } from 'inversify';
import { ReportMainEntityFile } from '~/types/common/reportMainEntityFile';

import { LegalEntitiesTrademark } from '~/types/legal-entities/trademark';
import { IPlaceholderDataService, IReportMainEntityFilePlaceholderServiceKey } from '../common/reportPlaceholderService.interfaces';

@injectable()
export class LegalEntitiesTrademarkPlaceholderDataService implements IPlaceholderDataService<LegalEntitiesTrademark> {
  constructor(
        @inject(IReportMainEntityFilePlaceholderServiceKey as symbol) private reportMainEntityFilePlaceholderService: IPlaceholderDataService<ReportMainEntityFile>
  ) {}

  getData(): LegalEntitiesTrademark {
    return {
      Name: '',
      DateEnd: '',
      DocNumber: '',
      Image: this.reportMainEntityFilePlaceholderService.getData()
    };
  }
}
