import { injectable } from 'inversify';

import { LegalEntitiesCertificateSummary } from '~/types/legal-entities/certificateSummary';
import { IPlaceholderDataService } from '../common/reportPlaceholderService.interfaces';

@injectable()
export class LegalEntitiesCertificateSummaryPlaceholderDataService implements IPlaceholderDataService<LegalEntitiesCertificateSummary> {
  getData(): LegalEntitiesCertificateSummary {
    return {
      CertificateType: '',
      TotalCount: 0,
      ActualCount: 0,
    };
  }
}
