import { InjectionKey } from 'vue';
import { LegalEntitiesDataBlobData } from '~/types/blobs/legal-entities-data-blob-data';
import { LegalEntitiesActivitySummary } from '~/types/legal-entities/activitySummary';
import { LegalEntitiesBriefInfo } from '~/types/legal-entities/briefInfo';
import { LegalEntitiesCertificateSummary } from '~/types/legal-entities/certificateSummary';
import { LegalEntitiesCommonInfo } from '~/types/legal-entities/commonInfo';
import { LegalEntitiesCompanyActualAddress } from '~/types/legal-entities/companyActualAddress';
import { LegalEntitiesCompanyEmail } from '~/types/legal-entities/companyEmail';
import { LegalEntitiesCompanyEmployeeInfo } from '~/types/legal-entities/companyEmployeeInfo';
import { LegalEntitiesCompanyFounder } from '~/types/legal-entities/companyFounder';
import { LegalEntitiesCompanyFounderShare } from '~/types/legal-entities/companyFounderShare';
import { LegalEntitiesCompanyHeadFl } from '~/types/legal-entities/companyHeadFl';
import { LegalEntitiesCompanyHistoryAddress } from '~/types/legal-entities/companyHistoryAddress';
import { LegalEntitiesCompanyManagementCompanies } from '~/types/legal-entities/companyManagementCompanies';
import { LegalEntitiesCompanyName } from '~/types/legal-entities/companyName';
import { LegalEntitiesCompanyPhone } from '~/types/legal-entities/companyPhone';
import { LegalEntitiesCompanySite } from '~/types/legal-entities/companySite';
import { LegalEntitiesCompanyStatedCapital } from '~/types/legal-entities/companyStatedCapital';
import { LegalEntitiesConditionAssessment } from '~/types/legal-entities/conditionAssessment';
import { LegalEntitiesConflictSign } from '~/types/legal-entities/conflictSign';
import { LegalEntitiesContractsInfoByYear } from '~/types/legal-entities/contractsInfoByYear';
import { LegalEntitiesCourtCasesByYear } from '~/types/legal-entities/courtCasesByYear';
import { LegalEntitiesEgrRecord } from '~/types/legal-entities/egrRecord';
import { LegalEntitiesEnforcementProceedingsInfoBySubject } from '~/types/legal-entities/enforcementProceedingsInfoBySubject';
import { LegalEntitiesFinancialAnalysisExtended } from '~/types/legal-entities/financialAnalysisExtended';
import { LegalEntitiesFinancialAnalysisShort } from '~/types/legal-entities/financialAnalysisShort';
import { LegalEntitiesFinancialCommon } from '~/types/legal-entities/financialCommon';
import { LegalEntitiesFinancialState } from '~/types/legal-entities/financialState';
import { LegalEntitiesFineAndTaxDebt } from '~/types/legal-entities/fineAndTaxDebt';
import { LegalEntitiesGovernmentControl } from '~/types/legal-entities/governmentControl';
import { LegalEntitiesInsuranceFundInfo } from '~/types/legal-entities/insuranceFundInfo';
import { LegalEntitiesLesseeSummary } from '~/types/legal-entities/lesseeSummary';
import { LegalEntitiesLicenseSummary } from '~/types/legal-entities/licenseSummary';
import { LegalEntitiesMarkers } from '~/types/legal-entities/markers';
import { LegalEntitiesPerson } from '~/types/legal-entities/person';
import { LegalEntitiesPredecessor } from '~/types/legal-entities/predecessor';
import { LegalEntitiesRegistrarOfShareholders } from '~/types/legal-entities/registrarOfShareholders';
import { LegalEntitiesRelatedCompany } from '~/types/legal-entities/relatedCompany';
import { LegalEntitiesRelatedEgrul } from '~/types/legal-entities/relatedEgrul';
import { LegalEntitiesRelatedReason } from '~/types/legal-entities/relatedReason';
import { LegalEntitiesReorganisationMember } from '~/types/legal-entities/reorganisationMember';
import { LegalEntitiesReportAnalysisOfAbsoluteLiquidityIndicator } from '~/types/legal-entities/reportAnalysisOfAbsoluteLiquidityIndicator';
import { LegalEntitiesReportAverageIndustryIndicator } from '~/types/legal-entities/reportAverageIndustryIndicator';
import { LegalEntitiesReportDataBuhForm } from '~/types/legal-entities/reportDataBuhForm';
import { LegalEntitiesReportDataBuhFormDetail } from '~/types/legal-entities/reportDataBuhFormDetail';
import { LegalEntitiesReportFinancialIndicatorBase } from '~/types/legal-entities/reportFinancialIndicatorBase';
import { LegalEntitiesReportFinancialIndicatorWithConclusions } from '~/types/legal-entities/reportFinancialIndicatorWithConclusions';
import { LegalEntitiesReportFinancialIndicatorWithDynamic } from '~/types/legal-entities/reportFinancialIndicatorWithDynamic';
import { LegalEntitiesReportFinancialStability } from '~/types/legal-entities/reportFinancialStability';
import { LegalEntitiesReportLiquidityAndSolvency } from '~/types/legal-entities/reportLiquidityAndSolvency';
import { LegalEntitiesReportSocialNetworkId } from '~/types/legal-entities/reportSocialNetworkId';
import { LegalEntitiesSelfRegulatoryOrganization } from '~/types/legal-entities/selfRegulatoryOrganization';
import { LegalEntitiesShareholder } from '~/types/legal-entities/shareholder';
import { LegalEntitiesSocial } from '~/types/legal-entities/social';
import { LegalEntitiesSubCompanyInfo } from '~/types/legal-entities/subCompanyInfo';
import { LegalEntitiesSuccessor } from '~/types/legal-entities/successor';
import { LegalEntitiesTax } from '~/types/legal-entities/tax';
import { LegalEntitiesTaxMode } from '~/types/legal-entities/taxMode';
import { LegalEntitiesTaxServiceInfo } from '~/types/legal-entities/taxServiceInfo';
import { LegalEntitiesTotalScore } from '~/types/legal-entities/totalScore';
import { LegalEntitiesTrademark } from '~/types/legal-entities/trademark';
import { INormalizationService } from '../common/reportNormalizationService.interfaces';
import { LegalEntitiesTaxesPenalties } from '~/types/legal-entities/taxesPenalties';
import { LegalEntitiesCompanyTaxRegimes } from '~/types/legal-entities/companyTaxRegimes';
import { LegalEntitiesBankAccount } from '~/types/legal-entities/bankAccount';
import { LegalEntitiesAverageHeadcount } from '~/types/legal-entities/averageHeadcount';
import { LegalEntitiesAnalyticsIndicators } from '~/types/legal-entities/analyticsIndicators';

export const ILegalEntitiesCompanyTaxRegimesNormalizationServiceKey: InjectionKey<INormalizationService<LegalEntitiesCompanyTaxRegimes>> = Symbol('INormalizationService<LegalEntitiesCompanyTaxRegimes>');
export const ILegalEntitiesDataBlobDataNormalizationServiceKey: InjectionKey<INormalizationService<LegalEntitiesDataBlobData>> = Symbol('INormalizationService<LegalEntitiesDataBlobData>');
export const ILegalEntitiesCommonNormalizationServiceKey: InjectionKey<INormalizationService<LegalEntitiesCommonInfo>> = Symbol('INormalizationService<LegalEntitiesCommonInfo>');
export const ILegalEntitiesBriefInfoNormalizationServiceKey: InjectionKey<INormalizationService<LegalEntitiesBriefInfo>> = Symbol('INormalizationService<LegalEntitiesBriefInfo>');
export const ILegalEntitiesTaxServiceNormalizationServiceKey: InjectionKey<INormalizationService<LegalEntitiesTaxServiceInfo>> = Symbol('INormalizationService<LegalEntitiesTaxServiceInfo>');
export const ILegalEntitiesCompanyActualAddressNormalizationServiceKey: InjectionKey<INormalizationService<LegalEntitiesCompanyActualAddress>> = Symbol('INormalizationService<LegalEntitiesCompanyActualAddress>');
export const ILegalEntitiesConditionAssessmentNormalizationServiceKey: InjectionKey<INormalizationService<LegalEntitiesConditionAssessment>> = Symbol('INormalizationService<LegalEntitiesConditionAssessment>');
export const ILegalEntitiesTaxModeNormalizationServiceKey: InjectionKey<INormalizationService<LegalEntitiesTaxMode>> = Symbol('INormalizationService<LegalEntitiesTaxMode>');
export const ILegalEntitiesRegistrarOfShareholdersNormalizationServiceKey: InjectionKey<INormalizationService<LegalEntitiesRegistrarOfShareholders>> = Symbol('INormalizationService<LegalEntitiesRegistrarOfShareholders>');
export const ILegalEntitiesLesseeSummaryNormalizationServiceKey: InjectionKey<INormalizationService<LegalEntitiesLesseeSummary>> = Symbol('INormalizationService<LegalEntitiesLesseeSummary>');
export const ILegalEntitiesActivitySummaryNormalizationServiceKey: InjectionKey<INormalizationService<LegalEntitiesActivitySummary>> = Symbol('INormalizationService<LegalEntitiesActivitySummary>');
export const ILegalEntitiesCompanySiteNormalizationServiceKey: InjectionKey<INormalizationService<LegalEntitiesCompanySite>> = Symbol('INormalizationService<LegalEntitiesCompanySite>');
export const ILegalEntitiesCompanyPhoneNormalizationServiceKey: InjectionKey<INormalizationService<LegalEntitiesCompanyPhone>> = Symbol('INormalizationService<LegalEntitiesCompanyPhone>');
export const ILegalEntitiesCompanyEmailNormalizationServiceKey: InjectionKey<INormalizationService<LegalEntitiesCompanyEmail>> = Symbol('INormalizationService<LegalEntitiesCompanyEmail>');
export const ILegalEntitiesSocialNormalizationServiceKey: InjectionKey<INormalizationService<LegalEntitiesSocial>> = Symbol('INormalizationService<LegalEntitiesSocial>');
export const ILegalEntitiesCompanyStatedCapitalNormalizationServiceKey: InjectionKey<INormalizationService<LegalEntitiesCompanyStatedCapital>> = Symbol('INormalizationService<LegalEntitiesCompanyStatedCapital>');
export const ILegalEntitiesCompanyHistoryAddressNormalizationServiceKey: InjectionKey<INormalizationService<LegalEntitiesCompanyHistoryAddress>> = Symbol('INormalizationService<LegalEntitiesCompanyHistoryAddress>');
export const ILegalEntitiesCompanyHeadFlNormalizationServiceKey: InjectionKey<INormalizationService<LegalEntitiesCompanyHeadFl>> = Symbol('INormalizationService<LegalEntitiesCompanyHeadFl>');
export const ILegalEntitiesCompanyManagementCompaniesNormalizationServiceKey: InjectionKey<INormalizationService<LegalEntitiesCompanyManagementCompanies>> = Symbol('INormalizationService<LegalEntitiesCompanyManagementCompanies>');
export const ILegalEntitiesCompanyFounderShareNormalizationServiceKey: InjectionKey<INormalizationService<LegalEntitiesCompanyFounderShare>> = Symbol('INormalizationService<LegalEntitiesCompanyFounderShare>');
export const ILegalEntitiesCompanyFounderNormalizationServiceKey: InjectionKey<INormalizationService<LegalEntitiesCompanyFounder>> = Symbol('INormalizationService<LegalEntitiesCompanyFounder>');
export const ILegalEntitiesCourtCasesByYearNormalizationServiceKey: InjectionKey<INormalizationService<LegalEntitiesCourtCasesByYear>> = Symbol('INormalizationService<LegalEntitiesCourtCasesByYear>');
export const ILegalEntitiesGovernmentControlNormalizationServiceKey: InjectionKey<INormalizationService<LegalEntitiesGovernmentControl>> = Symbol('INormalizationService<LegalEntitiesGovernmentControl>');
export const ILegalEntitiesEnforcementProceedingsInfoBySubjectNormalizationServiceKey: InjectionKey<INormalizationService<LegalEntitiesEnforcementProceedingsInfoBySubject>> = Symbol('INormalizationService<LegalEntitiesEnforcementProceedingsInfoBySubject>');
export const ILegalEntitiesRelatedCompanyNormalizationServiceKey: InjectionKey<INormalizationService<LegalEntitiesRelatedCompany>> = Symbol('INormalizationService<LegalEntitiesRelatedCompany>');
export const ILegalEntitiesRelatedEgrulNormalizationServiceKey: InjectionKey<INormalizationService<LegalEntitiesRelatedEgrul>> = Symbol('INormalizationService<LegalEntitiesRelatedEgrul>');
export const ILegalEntitiesPersonNormalizationServiceKey: InjectionKey<INormalizationService<LegalEntitiesPerson>> = Symbol('INormalizationService<LegalEntitiesPerson>');
export const ILegalEntitiesRelatedReasonNormalizationServiceKey: InjectionKey<INormalizationService<LegalEntitiesRelatedReason>> = Symbol('INormalizationService<LegalEntitiesRelatedReason>');
export const ILegalEntitiesConflictSignNormalizationServiceKey: InjectionKey<INormalizationService<LegalEntitiesConflictSign>> = Symbol('INormalizationService<LegalEntitiesConflictSign>');
export const ILegalEntitiesTaxNormalizationServiceKey: InjectionKey<INormalizationService<LegalEntitiesTax>> = Symbol('INormalizationService<LegalEntitiesTax>');
export const ILegalEntitiesFineAndTaxDebtNormalizationServiceKey: InjectionKey<INormalizationService<LegalEntitiesFineAndTaxDebt>> = Symbol('INormalizationService<LegalEntitiesFineAndTaxDebt>');
export const ILegalEntitiesTaxesPenaltiesNormalizationServiceKey: InjectionKey<INormalizationService<LegalEntitiesTaxesPenalties>> = Symbol('INormalizationService<LegalEntitiesTaxesPenalties>');
export const ILegalEntitiesContractsInfoByYearNormalizationServiceKey: InjectionKey<INormalizationService<LegalEntitiesContractsInfoByYear>> = Symbol('INormalizationService<LegalEntitiesContractsInfoByYear>');
export const ILegalEntitiesSubCompanyInfoNormalizationServiceKey: InjectionKey<INormalizationService<LegalEntitiesSubCompanyInfo>> = Symbol('INormalizationService<LegalEntitiesSubCompanyInfo>');
export const ILegalEntitiesPredecessorNormalizationServiceKey: InjectionKey<INormalizationService<LegalEntitiesPredecessor>> = Symbol('INormalizationService<LegalEntitiesPredecessor>');
export const ILegalEntitiesSuccessorNormalizationServiceKey: InjectionKey<INormalizationService<LegalEntitiesSuccessor>> = Symbol('INormalizationService<LegalEntitiesSuccessor>');
export const ILegalEntitiesReorganizationMemberNormalizationServiceKey: InjectionKey<INormalizationService<LegalEntitiesReorganisationMember>> = Symbol('INormalizationService<LegalEntitiesReorganisationMember>');
export const ILegalEntitiesShareholderNormalizationServiceKey: InjectionKey<INormalizationService<LegalEntitiesShareholder>> = Symbol('INormalizationService<LegalEntitiesShareholder>');
export const ILegalEntitiesEgrRecordNormalizationServiceKey: InjectionKey<INormalizationService<LegalEntitiesEgrRecord>> = Symbol('INormalizationService<LegalEntitiesEgrRecord>');
export const ILegalEntitiesCertificateSummaryNormalizationServiceKey: InjectionKey<INormalizationService<LegalEntitiesCertificateSummary>> = Symbol('INormalizationService<LegalEntitiesCertificateSummary>');
export const ILegalEntitiesLicenseSummaryNormalizationServiceKey: InjectionKey<INormalizationService<LegalEntitiesLicenseSummary>> = Symbol('INormalizationService<LegalEntitiesLicenseSummary>');
export const ILegalEntitiesSelfRegulatoryOrganizationNormalizationServiceKey: InjectionKey<INormalizationService<LegalEntitiesSelfRegulatoryOrganization>> = Symbol('INormalizationService<LegalEntitiesSelfRegulatoryOrganization>');
export const ILegalEntitiesTrademarkNormalizationServiceKey: InjectionKey<INormalizationService<LegalEntitiesTrademark>> = Symbol('INormalizationService<LegalEntitiesTrademark>');
export const ILegalEntitiesCompanyNameNormalizationServiceKey: InjectionKey<INormalizationService<LegalEntitiesCompanyName>> = Symbol('INormalizationService<LegalEntitiesCompanyName>');
export const ILegalEntitiesInsuranceFundInfoNormalizationServiceKey: InjectionKey<INormalizationService<LegalEntitiesInsuranceFundInfo>> = Symbol('INormalizationService<LegalEntitiesInsuranceFundInfo>');
export const ILegalEntitiesReportSocialNetworkIdNormalizationServiceKey: InjectionKey<INormalizationService<LegalEntitiesReportSocialNetworkId>> = Symbol('INormalizationService<LegalEntitiesReportSocialNetworkId>');
export const ILegalEntitiesCompanyEmployeeInfoNormalizationServiceKey: InjectionKey<INormalizationService<LegalEntitiesCompanyEmployeeInfo>> = Symbol('INormalizationService<LegalEntitiesCompanyEmployeeInfo>');
export const ILegalEntitiesFinancialStateNormalizationServiceKey: InjectionKey<INormalizationService<LegalEntitiesFinancialState>> = Symbol('INormalizationService<LegalEntitiesFinancialState>');
export const ILegalEntitiesReportDataBuhFormDetailNormalizationServiceKey: InjectionKey<INormalizationService<LegalEntitiesReportDataBuhFormDetail>> = Symbol('INormalizationService<LegalEntitiesReportDataBuhFormDetail>');
export const ILegalEntitiesReportDataBuhFormNormalizationServiceKey: InjectionKey<INormalizationService<LegalEntitiesReportDataBuhForm>> = Symbol('INormalizationService<LegalEntitiesReportDataBuhForm>');
export const ILegalEntitiesFinancialCommonNormalizationServiceKey: InjectionKey<INormalizationService<LegalEntitiesFinancialCommon>> = Symbol('INormalizationService<LegalEntitiesFinancialCommon>');
export const ILegalEntitiesMarkersNormalizationServiceKey: InjectionKey<INormalizationService<LegalEntitiesMarkers>> = Symbol('INormalizationService<LegalEntitiesMarkers>');
export const ILegalEntitiesFinancialAnalysisShortNormalizationServiceKey: InjectionKey<INormalizationService<LegalEntitiesFinancialAnalysisShort>> = Symbol('INormalizationService<LegalEntitiesFinancialAnalysisShort>');
export const ILegalEntitiesReportFinancialIndicatorWithDynamicNormalizationServiceKey: InjectionKey<INormalizationService<LegalEntitiesReportFinancialIndicatorWithDynamic>> = Symbol('INormalizationService<LegalEntitiesReportFinancialIndicatorWithDynamic>');
export const ILegalEntitiesReportFinancialIndicatorWithConclusionsNormalizationServiceKey: InjectionKey<INormalizationService<LegalEntitiesReportFinancialIndicatorWithConclusions>> = Symbol('INormalizationService<LegalEntitiesReportFinancialIndicatorWithConclusions>');
export const ILegalEntitiesReportFinancialIndicatorBaseNormalizationServiceKey: InjectionKey<INormalizationService<LegalEntitiesReportFinancialIndicatorBase>> = Symbol('INormalizationService<LegalEntitiesReportFinancialIndicatorBase>');
export const ILegalEntitiesFinancialAnalysisExtendedNormalizationServiceKey: InjectionKey<INormalizationService<LegalEntitiesFinancialAnalysisExtended>> = Symbol('INormalizationService<LegalEntitiesFinancialAnalysisExtended>');
export const ILegalEntitiesReportLiquidityAndSolvencyNormalizationServiceKey: InjectionKey<INormalizationService<LegalEntitiesReportLiquidityAndSolvency>> = Symbol('INormalizationService<LegalEntitiesReportLiquidityAndSolvency>');
export const ILegalEntitiesReportFinancialStabilityNormalizationServiceKey: InjectionKey<INormalizationService<LegalEntitiesReportFinancialStability>> = Symbol('INormalizationService<LegalEntitiesReportFinancialStability>');
export const ILegalEntitiesReportAnalysisOfAbsoluteLiquidityIndicatorNormalizationServiceKey: InjectionKey<INormalizationService<LegalEntitiesReportAnalysisOfAbsoluteLiquidityIndicator>> = Symbol('INormalizationService<LegalEntitiesReportAnalysisOfAbsoluteLiquidityIndicator>');
export const ILegalEntitiesReportAverageIndustryIndicatorNormalizationServiceKey: InjectionKey<INormalizationService<LegalEntitiesReportAverageIndustryIndicator>> = Symbol('INormalizationService<LegalEntitiesReportAverageIndustryIndicator>');
export const ILegalEntitiesTotalScoreNormalizationServiceKey: InjectionKey<INormalizationService<LegalEntitiesTotalScore>> = Symbol('INormalizationService<LegalEntitiesTotalScore>');
export const ILegalEntitiesBankAccountNormalizationServiceKey: InjectionKey<INormalizationService<LegalEntitiesBankAccount>> = Symbol('INormalizationService<BankAccount>');
export const ILegalEntitiesAverageHeadcountNormalizationServiceKey: InjectionKey<INormalizationService<LegalEntitiesAverageHeadcount>> = Symbol('INormalizationService<LegalEntitiesAverageHeadcount>');
export const ILegalEntitiesAnalyticsIndicatorsNormalizationServiceKey: InjectionKey<INormalizationService<LegalEntitiesAnalyticsIndicators>> = Symbol('INormalizationService<LegalEntitiesAnalyticsIndicators>');
