import { injectable } from 'inversify';
import { ILegalEntitiesGeneralInformationBaseConfigService } from '~/modules/legalEntities/commonInfo/legalEntitiesGeneralInformation/services/legalEntitiesGeneralInformationConfigServices.interfaces';
import { LegalEntitiesBriefInfoDTO } from '~/types/dto/legalEntities/briefInfo';
import { Config } from '~/types/field-list/config';
import { DateUtils } from '~/utils/date';
import { Direction } from '~/types/field-list/direction';

@injectable()
export class LegalEntitiesGeneralInformationBaseConfigService implements ILegalEntitiesGeneralInformationBaseConfigService {
  getConfig(initData: LegalEntitiesBriefInfoDTO): Config {
    const companyRegistrationDate = DateUtils.formatDate(initData.CompanyRegistrationDate);
    const companyLiquidationDate = DateUtils.formatDate(initData.CompanyLiquidationDate);

    const companyStatus = initData.CompanyStatus.trim();

    return {
      fieldsDirection: 'divider-row',
      fieldsWidth: '100%',
      fieldsValueWidth: '350px',
      isBeginningDivider: true,
      rowGap: '20px',
      fields: [
        {
          label: 'Статус',
          key: 'CompanyStatus',
          value: companyStatus
        },
        {
          label: 'Дата прекращения деятельности',
          key: 'CompanyLiquidationDate',
          value: companyLiquidationDate
        },
        {
          label: 'Дата первичной регистрации',
          key: 'CompanyRegistrationDate',
          value: companyRegistrationDate
        },
      ]
    };
  }
}
