import { InjectionKey } from 'vue';
import { LegalEntitiesCompanyStatedCapitalDTO } from '~/types/dto/legalEntities/companyStatedCapitalDTO';

export interface ILegalEntitiesAuthorizedCapitalSort {
  getSortData(initData: Array<LegalEntitiesCompanyStatedCapitalDTO>): Array<LegalEntitiesCompanyStatedCapitalDTO>;
}

export type ILegalEntitiesAuthorizedCapitalSortService = ILegalEntitiesAuthorizedCapitalSort;

export const ILegalEntitiesAuthorizedCapitalSortServiceKey: InjectionKey<ILegalEntitiesAuthorizedCapitalSortService> = Symbol('ILegalEntitiesAuthorizedCapitalSortService');
