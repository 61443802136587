import { IContainerBinder } from '~/configuration/inversify/containerAccessor.interface';
import { IndividualFamilyConfigService } from '~/modules/individual/family/individualFamilyList/services/individualFamilyConfigService';
import {
  IIndividualFamilyConfigServiceKey,
  IIndividualFamilyMaritalStatusConfigServiceKey
} from '~/modules/individual/family/individualFamilyList/services/individualFamilyConfigService.interfaces';
import {
  IndividualFamilyMaritalStatusConfigService
} from '~/modules/individual/family/individualFamilyList/services/individualFamilyMaritalStatusConfigService';

export function bindIndividualFamilyServices(bind: IContainerBinder) {
  bind(IIndividualFamilyConfigServiceKey).to(IndividualFamilyConfigService);
  bind(IIndividualFamilyMaritalStatusConfigServiceKey).to(IndividualFamilyMaritalStatusConfigService);
}
