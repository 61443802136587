import { injectable } from 'inversify';
import { IFinancialAnalysisCommonTitlesService } from '~/modules/legalEntities/financialAnalytics/common/services/financialAnalysisCommonTitlesService.interfaces';
import { NumberUtils } from '~/utils/number';

@injectable()
export class FinancialAnalysisCommonTitlesService implements IFinancialAnalysisCommonTitlesService {
  getTitle(title: string, data: Array<number>): string {
    if (title.includes('()')) {
      const maxValue = NumberUtils.getMaxAbs(data);
      const numberFormat = NumberUtils.findPostfixQualifiers(maxValue);

      if (numberFormat !== undefined) {
        return title.replace(/\(\)/, `${numberFormat.symbol} \u{20BD}`);
      }
    }
    return title;
  }
}
