import { useInjectable } from '~/hooks/useInjectable';
import { UIReportType } from '~/types/reportType';
import { createReportEntitiesStore } from '~/factories/reportEntitiesStoreFactory';
import { IDataListAdapterKey } from '~/services/adapters/dataListAdapterServise.interface';
import { LegalEntitiesDataBlobData } from '~/types/blobs/legal-entities-data-blob-data';
import { ILegalEntitiesDataBlobDataServiceKey } from '~/services/adapters/legalEntities/legalEntitiesAdapterService.interfaces';

export default createReportEntitiesStore(UIReportType.ul, (data) => {
  const companiesDataList = useInjectable(IDataListAdapterKey);
  const legalEntitiesService = useInjectable(ILegalEntitiesDataBlobDataServiceKey);

  return ({
    companies: companiesDataList.getDataList(data)
      .map((company) => legalEntitiesService.getData(company as LegalEntitiesDataBlobData))
  });
});
