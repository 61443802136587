import { injectable } from 'inversify';
import { ILegalEntitiesGeneralInformationRegistrationDataConfigService } from '~/modules/legalEntities/commonInfo/legalEntitiesGeneralInformation/services/legalEntitiesGeneralInformationConfigServices.interfaces';
import { Config } from '~/types/field-list/config';
import { NumberUtils } from '~/utils/number';
import { LegalEntitiesDataBlobDataDTO } from '~/types/dto/blobs/legalEntitiesDataBlobDataDTO';

@injectable()
export class LegalEntitiesGeneralInformationRegistrationDataConfigService implements ILegalEntitiesGeneralInformationRegistrationDataConfigService {
  getConfig(initData: LegalEntitiesDataBlobDataDTO): Config {
    const { briefInfo } = initData;
    const { financialInfo } = initData;
    const companySize = financialInfo.CompanySize !== 'Нет данных'
      ? financialInfo.CompanySize
      : '';
    const companyStatedCapitalSum = briefInfo.CompanyStatedCapitalSum
      ? NumberUtils.formatNumberDigits((briefInfo.CompanyStatedCapitalSum), 2)
      : '';

    return {
      fieldsDirection: 'divider-row',
      fieldsWidth: '100%',
      fieldsValueWidth: '350px',
      isBeginningDivider: true,
      rowGap: '20px',
      fields: [
        {
          label: 'Размер компании',
          key: 'CompanySize',
          value: companySize,
        },
        {
          label: 'Категория в реестре МСП',
          key: 'MspCategory',
          value: briefInfo.MspCategory,
        },
        {
          label: 'Уставный капитал, \u{20BD}',
          key: 'CompanyStatedCapitalSum',
          value: companyStatedCapitalSum,
        },
      ]
    };
  }
}
