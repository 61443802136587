import { inject, injectable } from 'inversify';
import { IReportUrlPlaceholderServiceKey, IPlaceholderDataService } from '~/services/placeholder/common/reportPlaceholderService.interfaces';
import { ReportUrl } from '~/types/common/reportUrl';
import { LegalEntitiesCommonInfo } from '~/types/legal-entities/commonInfo';
import { IPrimitiveTypesNormalizationServiceKey, IPrimitiveTypesNormalizationService } from '../common/primitiveTypesNormalizationService.interfaces';
import { INormalizationService, IReportUrlNormalizationServiceKey } from '../common/reportNormalizationService.interfaces';

@injectable()
export class LegalEntitiesCommonNormalizationService implements INormalizationService<LegalEntitiesCommonInfo> {
  constructor(
    @inject(IReportUrlNormalizationServiceKey as symbol) private reportUrlNormalizationService: INormalizationService<ReportUrl>,

    @inject(IReportUrlPlaceholderServiceKey as symbol) private reportUrlPlaceholderService: IPlaceholderDataService<ReportUrl>,

    @inject(IPrimitiveTypesNormalizationServiceKey as symbol) private primitiveTypesNormalizationService: IPrimitiveTypesNormalizationService,
  ) {}

  normalize(data: any, root: string): LegalEntitiesCommonInfo | undefined {
    const {
      normalizeBoolean,
      normalizeString,
      normalizeNumber,
      normalizeOptionalString,
      normalizeObject,
      normalizeOptional
    } = this.primitiveTypesNormalizationService;

    return normalizeObject(data, (data) => ({
      ReportGenerationDate: normalizeOptionalString(data.ReportGenerationDate, `${root}.ReportGenerationDate`),
      ReportUrl: normalizeOptional(data.ReportUrl, (data, field) => this.reportUrlNormalizationService.normalize(data, field), `${root}.ReportUrl`),
      ActivityTypesUrl: normalizeOptional(data.ActivityTypesUrl, (data, field) => this.reportUrlNormalizationService.normalize(data, field), `${root}.ActivityTypesUrl`),
      DirectorCompaniesUrl: normalizeOptional(data.DirectorCompaniesUrl, (data, field) => this.reportUrlNormalizationService.normalize(data, field), `${root}.DirectorCompaniesUrl`),
      IsCompanyIp: normalizeBoolean(data.IsCompanyIp as boolean, `${root}.IsCompanyIp`),
      CompanyInn: normalizeString(data.CompanyInn, `${root}.CompanyInn`),
      CompanyOgrn: normalizeOptionalString(data.CompanyOgrn, `${root}.CompanyOgrn`),
      CompanyRpn: normalizeString(data.CompanyRpn, `${root}.CompanyRpn`),
      CompanyOkpo: normalizeOptionalString(data.CompanyOkpo, `${root}.CompanyOkpo`),
      CompanyKpp: normalizeOptionalString(data.CompanyKpp, `${root}.CompanyKpp`),
      CompanyOkfcVal: normalizeOptionalString(data.CompanyOkfcVal, `${root}.CompanyOkfcVal`),
      CompanyOkfcDesc: normalizeOptionalString(data.CompanyOkfcDesc, `${root}.CompanyOkfcDesc`),
      CompanyOkopfVal: normalizeOptionalString(data.CompanyOkopfVal, `${root}.CompanyOkopfVal`),
      CompanyOkopfDesc: normalizeOptionalString(data.CompanyOkopfDesc, `${root}.CompanyOkopfDesc`),
      CompanyOkoguVal: normalizeOptionalString(data.CompanyOkoguVal, `${root}.CompanyOkoguVal`),
      CompanyOkoguDesc: normalizeString(data.CompanyOkoguDesc, `${root}.CompanyOkoguDesc`),
      CompanyOkato: normalizeOptionalString(data.CompanyOkato, `${root}.CompanyOkato`),
      CompanyOkatoDesc: normalizeString(data.CompanyOkatoDesc, `${root}.CompanyOkatoDesc`),
      CompanyOktmoVal: normalizeOptionalString(data.CompanyOktmoVal, `${root}.CompanyOktmoVal`),
      CompanyOktmoDesc: normalizeString(data.CompanyOktmoDesc, `${root}.CompanyOktmoDesc`),
      CompanyShortName: normalizeOptionalString(data.CompanyShortName, `${root}.CompanyShortName`),
      CompanyFullName: normalizeOptionalString(data.CompanyFullName, `${root}.CompanyFullName`),
      CompanyType: normalizeString(data.CompanyType, `${root}.CompanyType`),
      PrincipalActivityOkvedCode: normalizeString(data.PrincipalActivityOkvedCode, `${root}.PrincipalActivityOkvedCode`),
      OtherCompaniesWithDirector: normalizeNumber(data.OtherCompaniesWithDirector, `${root}.OtherCompaniesWithDirector`),
      Bic: normalizeOptionalString(data.Bic, `${root}.Bic`),
      Swift: normalizeOptionalString(data.Swift, `${root}.Swift`),
      BankRegistrationNumber: normalizeOptionalString(data.BankRegistrationNumber, `${root}.BankRegistrationNumber`),
      BankCorrespondentAccount: normalizeOptionalString(data.BankCorrespondentAccount, `${root}.BankCorrespondentAccount`),
      BankOpfType: normalizeString(data.BankOpfType, `${root}.BankOpfType`),
      BankAddressDataQc: normalizeString(data.BankAddressDataQc, `${root}.BankAddressDataQc`)
    }), root);
  }
}
