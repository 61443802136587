import { injectable, inject } from 'inversify';
import { ReportSocialNetworkAboutInfo } from '~/types/common/reportSocialNetworkAboutInfo';
import { IPrimitiveTypesNormalizationServiceKey, IPrimitiveTypesNormalizationService } from './primitiveTypesNormalizationService.interfaces';
import { INormalizationService } from './reportNormalizationService.interfaces';

@injectable()
export class ReportSocialNetworkAboutInfoNormalizationService implements INormalizationService<ReportSocialNetworkAboutInfo> {
  constructor(
    @inject(IPrimitiveTypesNormalizationServiceKey as symbol) private primitiveTypesNormalizationService: IPrimitiveTypesNormalizationService,
  ) {}

  normalize(data: any, root: string): ReportSocialNetworkAboutInfo | undefined {
    const { normalizeObject, normalizeString } = this.primitiveTypesNormalizationService;

    return normalizeObject(data, (data) => ({
      Activity: normalizeString(data.Activity, `${root}.Activity`),
      Interests: normalizeString(data.Interests, `${root}.Interests`),
      FavoriteMusic: normalizeString(data.FavoriteMusic, `${root}.FavoriteMusic`),
      FavoriteFilms: normalizeString(data.FavoriteFilms, `${root}.FavoriteFilms`),
      FavoriteTvShows: normalizeString(data.FavoriteTvShows, `${root}.FavoriteTvShows`),
      FavoriteBooks: normalizeString(data.FavoriteBooks, `${root}.FavoriteBooks`),
      FavoriteGames: normalizeString(data.FavoriteGames, `${root}.FavoriteGames`),
      FavoriteQuotes: normalizeString(data.FavoriteQuotes, `${root}.FavoriteQuotes`),
      About: normalizeString(data.About, `${root}.About`),
    }), root);
  }
}
