import { injectable } from 'inversify';
import { IPhoneNumberPhoneCommonAdapterService } from '~/services/adapters/phoneNimber/phoneNumberAdapterService.interfaces';
import { PhoneNumberPhoneCommon } from '~/types/phone-number/phoneCommon';
import { PhoneNumberPhoneCommonDTO } from '~/types/dto/phone-number/phoneCommonDTO';
import { StringUtils } from '~/utils/stringUtils';

@injectable()
export class PhoneNumberPhoneCommonAdapterService implements IPhoneNumberPhoneCommonAdapterService {
  getData(initData: PhoneNumberPhoneCommon): PhoneNumberPhoneCommonDTO {
    return {
      PhoneType: initData.PhoneType ?? '',
      PhoneNumber: initData.PhoneNumber ?? '',
      Country: initData.Country ?? '',
      Provider: StringUtils.quoteReplacement(initData.Provider ?? ''),
      TimeZone: initData.TimeZone ?? '',
      DeviceModel: StringUtils.quoteReplacement(initData.DeviceModel ?? ''),
      VerificationCode: initData.VerificationCode
    };
  }
}
