import { injectable } from 'inversify';

import { LegalEntitiesReportFinancialIndicatorBase } from '~/types/legal-entities/reportFinancialIndicatorBase';
import { IPlaceholderDataService } from '../common/reportPlaceholderService.interfaces';

@injectable()
export class LegalEntitiesReportFinancialIndicatorBasePlaceholderDataService implements IPlaceholderDataService<LegalEntitiesReportFinancialIndicatorBase> {
  getData(): LegalEntitiesReportFinancialIndicatorBase {
    return {
      TwoYearsAgo: 0,
      LastYear: 0,
      CurrentYear: 0,
    };
  }
}
