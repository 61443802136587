import { inject, injectable } from 'inversify';
import {
  INodeLayoutSnapshotsService, INodeLayoutSnapshotsServiceBuildSnapshotOptions
} from '~/services/nodes/nodeLayoutSnapshotsService.interfaces';
import { NodeLayoutSnapshot } from '~/models/nodes/snapshots/nodeLayoutSnapshot';
import {
  INodesSizeSnapshotsService,
  INodesSizeSnapshotsServiceKey
} from '~/services/nodes/nodesSizeSnapshotsService.interfaces';

@injectable()
export class NodeLayoutSnapshotsService implements INodeLayoutSnapshotsService {
  constructor(
    @inject(INodesSizeSnapshotsServiceKey as symbol) private nodeSizeSnapshotsService: INodesSizeSnapshotsService
  ) { }
  buildSnapshot(options: INodeLayoutSnapshotsServiceBuildSnapshotOptions): NodeLayoutSnapshot {
    const { root } = options;

    if (root != undefined) {
      const rowSnapshots = this.nodeSizeSnapshotsService.buildRowsSnapshots({
        root: root,
        elementsExtractor: options.elementsExtractor
      });

      return new NodeLayoutSnapshot(root, rowSnapshots);
    }

    return new NodeLayoutSnapshot(root, []);
  }
}
