import { injectable } from 'inversify';
import { ILegalEntitiesRelatedEgrulConfigService } from '~/modules/legalEntities/connection/legalEntitiesRelatedEgrul/services/legalEntitiesRelatedEgrulConfigService.interfaces';
import { LegalEntitiesRelatedEgrulDTO } from '~/types/dto/legalEntities/relatedEgrulDTO';
import { Config } from '~/types/field-list/config';

@injectable()
export class LegalEntitiesRelatedEgrulConfigService implements ILegalEntitiesRelatedEgrulConfigService {
  getConfig(initData: LegalEntitiesRelatedEgrulDTO): Config {
    return {
      fieldsWidth: '100%',
      fieldsDirection: 'divider-row',
      fieldsValueWidth: '350px',
      isBeginningDivider: true,
      rowGap: '8px',
      fields: [
        {
          label: 'Связанная компания',
          key: 'ObjectName',
          direction: 'column',
          value: {
            objectName: initData.ObjectName,
            relationObject: initData.RelationObject
          },
        },
        {
          label: 'ИНН',
          key: 'ObjectInn',
          value: initData.ObjectInn
        },
        {
          label: 'ОГРН',
          key: 'ObjectOgrn',
          value: initData.ObjectOgrn
        },
        {
          label: 'Статус',
          key: 'status',
          value: initData.ObjectStatus
        },
        {
          label: 'Адрес',
          key: 'ObjectLegalAddress',
          value: initData.ObjectLegalAddress
        },
        {
          label: 'Вид деятельности',
          key: 'ObjectMainActivity',
          value: initData.ObjectMainActivity
        }
      ]
    };
  }
}
