import { Dictionary } from '~/types/common/dictionary';

export module LegalEntitiesUtils {
  export function replaceTaxText(text: string): string {
    const replacements: Dictionary<string> = {
      'применение системы налогообложения в виде единого сельскохозяйственного налога': 'Единый сельскохозяйственный налог (ЕСХН)',
      'упрощенной системы налогообложения': 'Упрощенная система налогообложения (УСН)',
      'применение системы налогообложения в виде единого налога на вмененный доход': 'Единый налог на вмененный доход (ЕНВД)',
      'системы налогообложения при выполнении соглашения о разделе продукции': 'Система налогообложения при выполнении соглашения о разделе продукции',
      'упрощенной системы налогообложения,применение системы налогообложения в виде единого налога на вмененный доход': 'Упрощенная система налогообложения, применение системы налогообложения в виде единого налога на вмененный доход',
    };

    return replacements[text.toLowerCase()] ?? text;
  }
}
