import { injectable, inject } from 'inversify';

import { IndividualDataBlobData } from '~/types/blobs/individual-data-blob-data';
import { ReportSocialNetworkTrack } from '~/types/common/reportSocialNetworkTrack';
import { IndividualCommonInfo } from '~/types/individual/commonInfo';
import { IndividualCommonNegativeInfo } from '~/types/individual/commonNegativeInfo';
import { IndividualGroup } from '~/types/individual/group';
import { IndividualMarkers } from '~/types/individual/markers';
import { ReportPersonInn } from '~/types/common/reportPersonInn';
import { ReportPersonSnils } from '~/types/common/reportPersonSnils';
import { IndividualUserList } from '~/types/individual/userList';
import { ReportPassport } from '~/types/common/reportPassport';
import { IndividualMilitaryRecord } from '~/types/individual/militaryRecord';
import { IndividualPhone } from '~/types/individual/phone';
import { ReportBoundedPersonPhone } from '~/types/common/reportBoundedPersonPhone';
import { IndividualSkype } from '~/types/individual/skype';
import { ReportEmail } from '~/types/common/reportEmail';
import { ReportPersonSite } from '~/types/common/reportPersonSite';
import { ReportSocialNetwork } from '~/types/common/reportSocialNetwork';
import { ReportAddress } from '~/types/common/address';
import { IndividualDriverLicense } from '~/types/individual/driverLicense';
import { ReportVehicle } from '~/types/common/reportVehicle';
import { ReportVehicleParking } from '~/types/common/reportVehicleParking';
import { ReportEducation } from '~/types/common/reportEducation';
import { ReportJob } from '~/types/common/reportJob';
import { IndividualJobRecommendation } from '~/types/individual/jobRecommendation';
import { IndividualBusinessTrip } from '~/types/individual/businessTrip';
import { IndividualHoliday } from '~/types/individual/holiday';
import { ReportLegalEntitiesParticipation } from '~/types/common/reportLegalEntitiesParticipation';
import { IndividualLegalEntitiesParticipationRestriction } from '~/types/individual/legalEntitiesParticipationRestriction';
import { ReportIndividualEntrepreneur } from '~/types/common/reportIndividualEntrepreneur';
import { ReportMainEntityFile } from '~/types/common/reportMainEntityFile';
import { IndividualBankruptcy } from '~/types/individual/bankruptcy';
import { IndividualStageBankruptcy } from '~/types/individual/stageBankruptcy';
import { IndividualMessageAboutBankruptcy } from '~/types/individual/messageAboutBankruptcy';
import { IndividualCriminalPenalties } from '~/types/individual/criminalPenalties';
import { IndividualFinancialScoring } from '~/types/individual/financialScoring';
import { IndividualEnforcementProceeding } from '~/types/individual/enforcementProceeding';
import { ReportFine } from '~/types/common/reportFine';
import { IndividualWantedMvd } from '~/types/individual/wantedMvd';
import { IndividualWantedFsin } from '~/types/individual/wantedFsin';
import { IndividualWantedFssp } from '~/types/individual/wantedFssp';
import { IndividualFullNameChange } from '~/types/individual/fullNameChange';
import { ReportPossibleFullName } from '~/types/common/reportPossibleFullName';
import { ReportPossibleBirthdays } from '~/types/common/reportPossibleBirthdays';
import { IndividualType } from '~/types/individual/type';
import { IndividualTerroristsAndExtremistsRecord } from '~/types/individual/terroristsAndExtremistsRecord';
import { IndividualAccrual } from '~/types/individual/accrual';
import { IndividualWanted } from '~/types/individual/wanted';
import { IndividualAviaPerson } from '~/types/individual/aviaPerson';
import { ReportMovementCovid } from '~/types/common/reportMovementCovid';
import { ReportMovementTaxi } from '~/types/common/reportMovementTaxi';
import { IndividualMovementBus } from '~/types/individual/movementBus';
import { IndividualFamilyRelation } from '~/types/individual/familyRelation';
import { IndividualMaritalStatusHistory } from '~/types/individual/maritalStatusHistory';
import { ReportMessageBoardRecord } from '~/types/common/reportMessageBoardRecord';
import {
  IReportSocialNetworkTrackPlaceholderServiceKey,
  IPlaceholderDataService,
  IReportVettingInfoPlaceholderServiceKey,
  IReportConnectionRelatedPlaceholderDataServiceKey
} from '~/services/placeholder/common/reportPlaceholderService.interfaces';
import { IIndividualCommonInfoPlaceholderServiceKey, IIndividualCommonNegativeInfoPlaceholderServiceKey, IIndividualMarkersPlaceholderServiceKey } from '~/services/placeholder/individual/individualPlaceholderDataService.interfaces';
import { IPrimitiveTypesNormalizationServiceKey, IPrimitiveTypesNormalizationService } from '../common/primitiveTypesNormalizationService.interfaces';
import {
  INormalizationService,
  IReportSocialNetworkTrackNormalizationServiceKey,
  IReportPersonInnNormalizationServiceKey,
  IReportPersonSnilsNormalizationServiceKey,
  IReportPassportNormalizationServiceKey,
  IReportBoundedPersonPhoneNormalizationServiceKey,
  IReportEmailNormalizationServiceKey,
  IReportPersonSiteNormalizationServiceKey,
  IReportVehicleNormalizationServiceKey,
  IReportVehicleParkingNormalizationServiceKey,
  IReportEducationNormalizationServiceKey,
  IReportJobNormalizationServiceKey,
  IReportLegalEntitiesParticipationNormalizationServiceKey,
  IReportIndividualEntrepreneurNormalizationServiceKey,
  IReportMainEntityFileNormalizationServiceKey,
  IReportFineNormalizationServiceKey,
  IReportPossibleFullNameNormalizationServiceKey,
  IReportPossibleBirthdaysNormalizationServiceKey,
  IReportMovementCovidNormalizationServiceKey,
  IReportMovementTaxiNormalizationServiceKey,
  IReportMessageBoardRecordNormalizationServiceKey,
  IStringArrayNormalizationServiceKey,
  IReportVettingInfoNormalizationServiceKey,
  IReportConnectionRelatedNormalizationServiceKey,
  IReportAddressNormalizationServiceKey,
  IReportSocialNetworkNormalizationServiceKey
} from '../common/reportNormalizationService.interfaces';
import {
  IIndividualCommonInfoNormalizationServiceKey,
  IIndividualCommonNegativeInfoNormalizationServiceKey,
  IIndividualMarkersNormalizationServiceKey,
  IIndividualGroupNormalizationServiceKey,
  IIndividualUserListNormalizationServiceKey,
  IIndividualMilitaryRecordNormalizationServiceKey,
  IIndividualPhoneNormalizationServiceKey,
  IIndividualSkypeNormalizationServiceKey,
  IIndividualDriverLicenseNormalizationServiceKey,
  IIndividualJobRecommendationNormalizationServiceKey,
  IIndividualBusinessTripNormalizationServiceKey,
  IIndividualHolidayNormalizationServiceKey,
  IIndividualLegalEntitiesParticipationRestrictionNormalizationServiceKey,
  IIndividualBankruptcyNormalizationServiceKey,
  IIndividualStageBankruptcyNormalizationServiceKey,
  IIndividualMessageAboutBankruptcyNormalizationServiceKey,
  IIndividualCriminalPenaltiesNormalizationServiceKey,
  IIndividualFinancialScoringNormalizationServiceKey,
  IIndividualTrustScoringNormalizationServiceKey,
  IIndividualEnforcementProceedingNormalizationServiceKey,
  IIndividualWantedMvdNormalizationServiceKey,
  IIndividualWantedFsinNormalizationServiceKey,
  IIndividualWantedFsspNormalizationServiceKey,
  IIndividualFullNameChangeNormalizationServiceKey,
  IIndividualTypeNormalizationServiceKey,
  IIndividualTerroristsAndExtremistsRecordNormalizationServiceKey,
  IIndividualAccrualNormalizationServiceKey,
  IIndividualWantedNormalizationServiceKey,
  IIndividualAviaPersonNormalizationServiceKey,
  IIndividualMovementBusNormalizationServiceKey,
  IIndividualFamilyRelationNormalizationServiceKey,
  IIndividualMaritalStatusHistoryNormalizationServiceKey,
} from './individualNormalizationService.interfaces';
import { ReportVettingInfo } from '~/types/common/vettingInfo';
import { IndividualTrustScoring } from '~/types/individual/trustScoring';
import { ReportConnectionRelated } from '~/types/common/reportConnectionRelated';

@injectable()
export class IndividualDataBlobDataNormalizationService implements INormalizationService<IndividualDataBlobData> {
  constructor(
    @inject(IIndividualCommonInfoNormalizationServiceKey as symbol) private commonInfoNormalizationService: INormalizationService<IndividualCommonInfo>,
    @inject(IReportSocialNetworkTrackNormalizationServiceKey as symbol) private reportSocialNetworkTrackNormalizationService: INormalizationService<ReportSocialNetworkTrack>,
    @inject(IIndividualCommonNegativeInfoNormalizationServiceKey as symbol) private commonNegativeInfoNormalizationService: INormalizationService<IndividualCommonNegativeInfo>,
    @inject(IIndividualMarkersNormalizationServiceKey as symbol) private markersNormalizationService: INormalizationService<IndividualMarkers>,
    @inject(IIndividualGroupNormalizationServiceKey as symbol) private groupNormalizationService: INormalizationService<IndividualGroup>,
    @inject(IReportPersonInnNormalizationServiceKey as symbol) private reportPersonInnNormalizationService: INormalizationService<ReportPersonInn>,
    @inject(IReportPersonSnilsNormalizationServiceKey as symbol) private reportPersonSnilsNormalizationService: INormalizationService<ReportPersonSnils>,
    @inject(IIndividualUserListNormalizationServiceKey as symbol) private userListNormalizationService: INormalizationService<IndividualUserList>,
    @inject(IReportPassportNormalizationServiceKey as symbol) private reportPassportNormalizationService: INormalizationService<ReportPassport>,
    @inject(IIndividualMilitaryRecordNormalizationServiceKey as symbol) private militaryRecordNormalizationService: INormalizationService<IndividualMilitaryRecord>,
    @inject(IIndividualPhoneNormalizationServiceKey as symbol) private phoneNormalizationService: INormalizationService<IndividualPhone>,
    @inject(IReportBoundedPersonPhoneNormalizationServiceKey as symbol) private reportBoundedPersonPhoneNormalizationService: INormalizationService<ReportBoundedPersonPhone>,
    @inject(IIndividualSkypeNormalizationServiceKey as symbol) private skypeNormalizationService: INormalizationService<IndividualSkype>,
    @inject(IReportEmailNormalizationServiceKey as symbol) private reportEmailNormalization: INormalizationService<ReportEmail>,
    @inject(IReportPersonSiteNormalizationServiceKey as symbol) private reportPersonSiteNormalizationService: INormalizationService<ReportPersonSite>,
    @inject(IReportSocialNetworkNormalizationServiceKey as symbol) private socialNetworkNormalizationService: INormalizationService<ReportSocialNetwork>,
    @inject(IReportAddressNormalizationServiceKey as symbol) private reportAddressNormalizationService: INormalizationService<ReportAddress>,
    @inject(IIndividualDriverLicenseNormalizationServiceKey as symbol) private driverLicenseNormalizationService: INormalizationService<IndividualDriverLicense>,
    @inject(IReportVehicleNormalizationServiceKey as symbol) private reportVehicleNormalizationService: INormalizationService<ReportVehicle>,
    @inject(IReportVehicleParkingNormalizationServiceKey as symbol) private reportVehicleParkingNormalizationService: INormalizationService<ReportVehicleParking>,
    @inject(IReportEducationNormalizationServiceKey as symbol) private reportEducationNormalizationService: INormalizationService<ReportEducation>,
    @inject(IReportJobNormalizationServiceKey as symbol) private reportJobNormalizationService: INormalizationService<ReportJob>,
    @inject(IIndividualJobRecommendationNormalizationServiceKey as symbol) private jobRecommendationNormalizationService: INormalizationService<IndividualJobRecommendation>,
    @inject(IIndividualBusinessTripNormalizationServiceKey as symbol) private businessTripNormalizationService: INormalizationService<IndividualBusinessTrip>,
    @inject(IIndividualHolidayNormalizationServiceKey as symbol) private holidayNormalizationService: INormalizationService<IndividualHoliday>,
    @inject(IReportLegalEntitiesParticipationNormalizationServiceKey as symbol) private reportLegalEntitiesParticipationNormalizationService: INormalizationService<ReportLegalEntitiesParticipation>,
    @inject(IIndividualLegalEntitiesParticipationRestrictionNormalizationServiceKey as symbol) private legalEntitiesParticipationRestrictionNormalizationService: INormalizationService<IndividualLegalEntitiesParticipationRestriction>,
    @inject(IReportIndividualEntrepreneurNormalizationServiceKey as symbol) private reportIndividualEntrepreneurNormalizationService: INormalizationService<ReportIndividualEntrepreneur>,
    @inject(IReportMainEntityFileNormalizationServiceKey as symbol) private reportMainEntityFileNormalizationService: INormalizationService<ReportMainEntityFile>,
    @inject(IIndividualBankruptcyNormalizationServiceKey as symbol) private bankruptcyNormalizationService: INormalizationService<IndividualBankruptcy>,
    @inject(IIndividualStageBankruptcyNormalizationServiceKey as symbol) private stageBankruptcyNormalizationService: INormalizationService<IndividualStageBankruptcy>,
    @inject(IIndividualMessageAboutBankruptcyNormalizationServiceKey as symbol) private messageAboutBankruptcyNormalizationService: INormalizationService<IndividualMessageAboutBankruptcy>,
    @inject(IIndividualCriminalPenaltiesNormalizationServiceKey as symbol) private criminalPenaltiesNormalizationService: INormalizationService<IndividualCriminalPenalties>,
    @inject(IIndividualFinancialScoringNormalizationServiceKey as symbol) private financialScoringNormalizationService: INormalizationService<IndividualFinancialScoring>,
    @inject(IIndividualTrustScoringNormalizationServiceKey as symbol) private trustScoringNormalizationService: INormalizationService<IndividualTrustScoring>,
    @inject(IIndividualEnforcementProceedingNormalizationServiceKey as symbol) private enforcementProceedingNormalizationService: INormalizationService<IndividualEnforcementProceeding>,
    @inject(IReportFineNormalizationServiceKey as symbol) private reportFineNormalizationService: INormalizationService<ReportFine>,
    @inject(IIndividualWantedMvdNormalizationServiceKey as symbol) private wantedMvdNormalizationService: INormalizationService<IndividualWantedMvd>,
    @inject(IIndividualWantedFsinNormalizationServiceKey as symbol) private wantedFsinNormalizationService: INormalizationService<IndividualWantedFsin>,
    @inject(IIndividualWantedFsspNormalizationServiceKey as symbol) private wantedFsspNormalizationService: INormalizationService<IndividualWantedFssp>,
    @inject(IIndividualFullNameChangeNormalizationServiceKey as symbol) private fullNameChangeNormalizationService: INormalizationService<IndividualFullNameChange>,
    @inject(IReportPossibleFullNameNormalizationServiceKey as symbol) private reportPossibleFullNameNormalizationService: INormalizationService<ReportPossibleFullName>,
    @inject(IReportPossibleBirthdaysNormalizationServiceKey as symbol) private reportPossibleBirthdaysNormalizationService: INormalizationService<ReportPossibleBirthdays>,
    @inject(IIndividualTypeNormalizationServiceKey as symbol) private typeNormalizationService: INormalizationService<IndividualType>,
    @inject(IIndividualTerroristsAndExtremistsRecordNormalizationServiceKey as symbol) private terroristsAndExtremistsRecordNormalizationService: INormalizationService<IndividualTerroristsAndExtremistsRecord>,
    @inject(IIndividualAccrualNormalizationServiceKey as symbol) private accrualNormalizationService: INormalizationService<IndividualAccrual>,
    @inject(IIndividualWantedNormalizationServiceKey as symbol) private wantedNormalizationService: INormalizationService<IndividualWanted>,
    @inject(IIndividualAviaPersonNormalizationServiceKey as symbol) private aviaPersonNormalizationService: INormalizationService<IndividualAviaPerson>,
    @inject(IReportMovementCovidNormalizationServiceKey as symbol) private reportMovementCovidNormalizationService: INormalizationService<ReportMovementCovid>,
    @inject(IReportMovementTaxiNormalizationServiceKey as symbol) private reportMovementTaxiNormalizationService: INormalizationService<ReportMovementTaxi>,
    @inject(IIndividualMovementBusNormalizationServiceKey as symbol) private movementBusNormalizationService: INormalizationService<IndividualMovementBus>,
    @inject(IIndividualFamilyRelationNormalizationServiceKey as symbol) private familyRelationNormalizationService: INormalizationService<IndividualFamilyRelation>,
    @inject(IIndividualMaritalStatusHistoryNormalizationServiceKey as symbol) private maritalStatusHistoryNormalizationService: INormalizationService<IndividualMaritalStatusHistory>,
    @inject(IReportMessageBoardRecordNormalizationServiceKey as symbol) private reportMessageBoardRecordNormalizationService: INormalizationService<ReportMessageBoardRecord>,
    @inject(IReportVettingInfoNormalizationServiceKey as symbol) private vettingInfoNormalizationService: INormalizationService<ReportVettingInfo>,
    @inject(IReportConnectionRelatedNormalizationServiceKey as symbol) private reportConnectionRelatedNormalizationService: INormalizationService<ReportConnectionRelated>,

    @inject(IReportSocialNetworkTrackPlaceholderServiceKey as symbol) private reportSocialNetworkTrackPlaceholderService: IPlaceholderDataService<ReportSocialNetworkTrack>,
    @inject(IIndividualCommonInfoPlaceholderServiceKey as symbol) private commonInfoPlaceholderService: IPlaceholderDataService<IndividualCommonInfo>,
    @inject(IIndividualCommonNegativeInfoPlaceholderServiceKey as symbol) private commonNegativeInfoPlaceholderService: IPlaceholderDataService<IndividualCommonNegativeInfo>,
    @inject(IIndividualMarkersPlaceholderServiceKey as symbol) private markersPlaceholderServiceKey: IPlaceholderDataService<IndividualMarkers>,
    @inject(IReportConnectionRelatedPlaceholderDataServiceKey as symbol) private reportConnectionRelatedPlaceholderDataService: IPlaceholderDataService<ReportConnectionRelated>,
    @inject(IReportVettingInfoPlaceholderServiceKey as symbol) private vettingInfoPlaceholderDataService: IPlaceholderDataService<ReportVettingInfo>,
    @inject(IPrimitiveTypesNormalizationServiceKey as symbol) private primitiveTypesNormalizationService: IPrimitiveTypesNormalizationService,
    @inject(IStringArrayNormalizationServiceKey as symbol) private stringArrayNormalizationService: INormalizationService<string>,
  ) {}

  normalize(data: any, root: string): IndividualDataBlobData | undefined {
    const { normalizeObject, normalizeArray } = this.primitiveTypesNormalizationService;

    return normalizeObject(data, (data) => ({
      Common: this.commonInfoNormalizationService.normalize(data.Common, `${root}.Common`) ?? this.commonInfoPlaceholderService.getData(),
      PersonGroups: normalizeArray(data.PersonGroups, (item, field) => this.groupNormalizationService.normalize(item, field), `${root}.PersonGroups`),
      Inns: normalizeArray(data.Inns, (item, field) => this.reportPersonInnNormalizationService.normalize(item, field), `${root}.Inns`),
      Snilses: normalizeArray(data.Snilses, (item, field) => this.reportPersonSnilsNormalizationService.normalize(item, field), `${root}.Snilses`),
      UsersLists: normalizeArray(data.UsersLists, (item, field) => this.userListNormalizationService.normalize(item, field), `${root}.UsersLists`),
      Passports: normalizeArray(data.Passports, (item, field) => this.reportPassportNormalizationService.normalize(item, field), `${root}.Passports`),
      MilitaryRecords: normalizeArray(data.MilitaryRecords, (item, field) => this.militaryRecordNormalizationService.normalize(item, field), `${root}.MilitaryRecords`),
      Phones: normalizeArray(data.Phones, (item, field) => this.phoneNormalizationService.normalize(item, field), `${root}.Phones`),
      BoundedPersonPhones: normalizeArray(data.BoundedPersonPhones, (item, field) => this.reportBoundedPersonPhoneNormalizationService.normalize(item, field), `${root}.BoundedPersonPhones`),
      Skypes: normalizeArray(data.Skypes, (item, field) => this.skypeNormalizationService.normalize(item, field), `${root}.Skypes`),
      Emails: normalizeArray(data.Emails, (item, field) => this.reportEmailNormalization.normalize(item, field), `${root}.Emails`),
      Sites: normalizeArray(data.Sites, (item, field) => this.reportPersonSiteNormalizationService.normalize(item, field), `${root}.Sites`),
      SocialNetworks: normalizeArray(data.SocialNetworks, (item, field) => this.socialNetworkNormalizationService.normalize(item, field), `${root}.SocialNetworks`),
      Addresses: normalizeArray(data.Addresses, (item, field) => this.reportAddressNormalizationService.normalize(item, field), `${root}.Addresses`),
      Realties: normalizeArray(data.Realties, (item, field) => this.reportAddressNormalizationService.normalize(item, field), `${root}.Realties`),
      DriverLicenses: normalizeArray(data.DriverLicenses, (item, field) => this.driverLicenseNormalizationService.normalize(item, field), `${root}.DriverLicenses`),
      Vehicles: normalizeArray(data.Vehicles, (item, field) => this.reportVehicleNormalizationService.normalize(item, field), `${root}.Vehicles`),
      VehicleParkings: normalizeArray(data.VehicleParkings, (item, field) => this.reportVehicleParkingNormalizationService.normalize(item, field), `${root}.VehicleParkings`),
      Educations: normalizeArray(data.Educations, (item, field) => this.reportEducationNormalizationService.normalize(item, field), `${root}.Educations`),
      Jobs: normalizeArray(data.Jobs, (item, field) => this.reportJobNormalizationService.normalize(item, field), `${root}.Jobs`),
      JobRecommendations: normalizeArray(data.JobRecommendations, (item, field) => this.jobRecommendationNormalizationService.normalize(item, field), `${root}.JobRecommendations`),
      BusinessTrips: normalizeArray(data.BusinessTrips, (item, field) => this.businessTripNormalizationService.normalize(item, field), `${root}.BusinessTrips`),
      Holidays: normalizeArray(data.Holidays, (item, field) => this.holidayNormalizationService.normalize(item, field), `${root}.Holidays`),
      LegalEntitiesParticipation: normalizeArray(data.LegalEntitiesParticipation, (item, field) => this.reportLegalEntitiesParticipationNormalizationService.normalize(item, field), `${root}.LegalEntitiesParticipation`),
      LegalEntitiesParticipationRestrictions: normalizeArray(data.LegalEntitiesParticipationRestrictions, (item, field) => this.legalEntitiesParticipationRestrictionNormalizationService.normalize(item, field), `${root}.LegalEntitiesParticipationRestrictions`),
      IndividualEntrepreneurs: normalizeArray(data.IndividualEntrepreneurs, (item, field) => this.reportIndividualEntrepreneurNormalizationService.normalize(item, field), `${root}.IndividualEntrepreneurs`),
      SocialNetworkTrack: this.reportSocialNetworkTrackNormalizationService.normalize(data.SocialNetworkTrack, `${root}.SocialNetworkTrack`) ?? this.reportSocialNetworkTrackPlaceholderService.getData(),
      CommonNegativeInfo: this.commonNegativeInfoNormalizationService.normalize(data.CommonNegativeInfo, `${root}.CommonNegativeInfo`) ?? this.commonNegativeInfoPlaceholderService.getData(),
      Bankruptcies: normalizeArray(data.Bankruptcies, (item, field) => this.bankruptcyNormalizationService.normalize(item, field), `${root}.Bankruptcies`),
      StagesBankruptcy: normalizeArray(data.StagesBankruptcy, (item, field) => this.stageBankruptcyNormalizationService.normalize(item, field), `${root}.StagesBankruptcy`),
      MessagesAboutBankruptcy: normalizeArray(data.MessagesAboutBankruptcy, (item, field) => this.messageAboutBankruptcyNormalizationService.normalize(item, field), `${root}.MessagesAboutBankruptcy`),
      CriminalPenalties: normalizeArray(data.CriminalPenalties, (item, field) => this.criminalPenaltiesNormalizationService.normalize(item, field), `${root}.CriminalPenalties`),
      FinancialScoring: normalizeArray(data.FinancialScoring, (item, field) => this.financialScoringNormalizationService.normalize(item, field), `${root}.FinancialScoring`),
      TrustScoring: normalizeArray(data.TrustScoring, (item, field) => this.trustScoringNormalizationService.normalize(item, field), `${root}.TrustScoring`),
      EnforcementProceedings: normalizeArray(data.EnforcementProceedings, (item, field) => this.enforcementProceedingNormalizationService.normalize(item, field), `${root}.EnforcementProceedings`),
      Fines: normalizeArray(data.Fines, (item, field) => this.reportFineNormalizationService.normalize(item, field), `${root}.Fines`),
      WantedMvd: normalizeArray(data.WantedMvd, (item, field) => this.wantedMvdNormalizationService.normalize(item, field), `${root}.WantedMvd`),
      WantedFsin: normalizeArray(data.WantedFsin, (item, field) => this.wantedFsinNormalizationService.normalize(item, field), `${root}.WantedFsin`),
      WantedFssp: normalizeArray(data.WantedFssp, (item, field) => this.wantedFsspNormalizationService.normalize(item, field), `${root}.WantedFssp`),
      FiosChanges: normalizeArray(data.FiosChanges, (item, field) => this.fullNameChangeNormalizationService.normalize(item, field), `${root}.FiosChanges`),
      PossibleFios: normalizeArray(data.PossibleFios, (item, field) => this.reportPossibleFullNameNormalizationService.normalize(item, field), `${root}.PossibleFios`),
      PossibleBirthdays: normalizeArray(data.PossibleBirthdays, (item, field) => this.reportPossibleBirthdaysNormalizationService.normalize(item, field), `${root}.PossibleBirthdays`),
      IndividualTypes: normalizeArray(data.IndividualTypes, (item, field) => this.typeNormalizationService.normalize(item, field), `${root}.IndividualTypes`),
      TerroristsAndExtremistsList: normalizeArray(data.TerroristsAndExtremistsList, (item, field) => this.terroristsAndExtremistsRecordNormalizationService.normalize(item, field), `${root}.TerroristsAndExtremistsList`),
      TaxDebtsAccruals: normalizeArray(data.TaxDebtsAccruals, (item, field) => this.accrualNormalizationService.normalize(item, field), `${root}.TaxDebtsAccruals`),
      FineAccruals: normalizeArray(data.FineAccruals, (item, field) => this.accrualNormalizationService.normalize(item, field), `${root}.FineAccruals`),
      StateDutyAccruals: normalizeArray(data.StateDutyAccruals, (item, field) => this.accrualNormalizationService.normalize(item, field), `${root}.StateDutyAccruals`),
      LegalDebtAccruals: normalizeArray(data.LegalDebtAccruals, (item, field) => this.accrualNormalizationService.normalize(item, field), `${root}.LegalDebtAccruals`),
      ServiceBillAccruals: normalizeArray(data.ServiceBillAccruals, (item, field) => this.accrualNormalizationService.normalize(item, field), `${root}.ServiceBillAccruals`),
      PossiblePasswords: normalizeArray(data.PossiblePasswords, (item, field) => this.stringArrayNormalizationService.normalize(item, field), `${root}.PossiblePasswords`),
      Wanted: normalizeArray(data.Wanted, (item, field) => this.wantedNormalizationService.normalize(item, field), `${root}.Wanted`),
      Avias: normalizeArray(data.Avias, (item, field) => this.aviaPersonNormalizationService.normalize(item, field), `${root}.Avias`),
      CovidMovements: normalizeArray(data.CovidMovements, (item, field) => this.reportMovementCovidNormalizationService.normalize(item, field), `${root}.CovidMovements`),
      TaxiMovements: normalizeArray(data.TaxiMovements, (item, field) => this.reportMovementTaxiNormalizationService.normalize(item, field), `${root}.TaxiMovements`),
      BusMovements: normalizeArray(data.BusMovements, (item, field) => this.movementBusNormalizationService.normalize(item, field), `${root}.BusMovements`),
      FamilyList: normalizeArray(data.FamilyList, (item, field) => this.familyRelationNormalizationService.normalize(item, field), `${root}.FamilyList`),
      MaritalStatusHistory: normalizeArray(data.MaritalStatusHistory, (item, field) => this.maritalStatusHistoryNormalizationService.normalize(item, field), `${root}.MaritalStatusHistory`),
      RelationsWithCounterparties: this.reportConnectionRelatedNormalizationService.normalize(data.RelationsWithCounterparties, `${root}.RelationsWithCounterparties`) ?? this.reportConnectionRelatedPlaceholderDataService.getData(),
      RelationsWithCompetitors: this.reportConnectionRelatedNormalizationService.normalize(data.RelationsWithCompetitors, `${root}.RelationsWithCompetitors`) ?? this.reportConnectionRelatedPlaceholderDataService.getData(),
      RelationsWithBlackListed: this.reportConnectionRelatedNormalizationService.normalize(data.RelationsWithBlackListed, `${root}.RelationsWithBlackListed`) ?? this.reportConnectionRelatedPlaceholderDataService.getData(),
      RelationsWithEmployees: this.reportConnectionRelatedNormalizationService.normalize(data.RelationsWithEmployees, `${root}.RelationsWithEmployees`) ?? this.reportConnectionRelatedPlaceholderDataService.getData(),
      FoundRelations: this.reportConnectionRelatedNormalizationService.normalize(data.FoundRelations, `${root}.FoundRelations`) ?? this.reportConnectionRelatedPlaceholderDataService.getData(),
      Markers: this.markersNormalizationService.normalize(data.Markers, `${root}.Markers`) ?? this.markersPlaceholderServiceKey.getData(),
      Files: normalizeArray(data.Files, (item, field) => this.reportMainEntityFileNormalizationService.normalize(item, field), `${root}.Files`),
      Photos: normalizeArray(data.Photos, (item, field) => this.reportMainEntityFileNormalizationService.normalize(item, field), `${root}.Photos`),
      MessageBoards: normalizeArray(data.MessageBoards, (item, field) => this.reportMessageBoardRecordNormalizationService.normalize(item, field), `${root}.MessageBoards`),
      VettingInfo: this.vettingInfoNormalizationService.normalize(data.VettingInfo, `${root}.VettingInfo`) ?? this.vettingInfoPlaceholderDataService.getData()
    }), root);
  }
}
