import NodesPagingCell from '~/models/nodes/paging/nodesPagingCell';

export default class NodesPagingRow<T> {
  top: number;

  bottom: number;

  height: number;

  cells: NodesPagingCell<T>[];

  constructor(cells: NodesPagingCell<T>[]) {
    this.top = Math.min(...cells.map(s => s.top));
    this.height = Math.max(...cells.map(s => s.height));
    this.bottom = this.top + this.height;
    this.cells = cells;
  }
}
