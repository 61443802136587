import { injectable } from 'inversify';
import { isEqual } from 'lodash-es';
import { MergeService } from '~/services/merge/merge-service';
import { LegalEntitiesCompanyHeadFlDTO } from '~/types/dto/legalEntities/companyHeadFlDTO';

@injectable()
export class CompanyHeadFlMergeService extends MergeService<LegalEntitiesCompanyHeadFlDTO> {
  protected canMerge(leftItem: LegalEntitiesCompanyHeadFlDTO, rightItem: LegalEntitiesCompanyHeadFlDTO): boolean {
    return isEqual(leftItem, rightItem);
  }

  protected mergeFields(leftItem: LegalEntitiesCompanyHeadFlDTO, rightItem: LegalEntitiesCompanyHeadFlDTO): LegalEntitiesCompanyHeadFlDTO {
    return {
      Fio: leftItem.Fio || rightItem.Fio,
      Innfl: leftItem.Innfl || rightItem.Innfl,
      Position: leftItem.Position || rightItem.Position,
      Date: leftItem.Date || rightItem.Date,
      FirstDate: leftItem.FirstDate || rightItem.FirstDate,
      IsInaccuracy: leftItem.IsInaccuracy || rightItem.IsInaccuracy,
      InaccuracyDate: leftItem.InaccuracyDate || rightItem.InaccuracyDate,
      IsCompanyCoOwner: leftItem.IsCompanyCoOwner || rightItem.IsCompanyCoOwner,
      OwnershipPercent: leftItem.OwnershipPercent || rightItem.OwnershipPercent,
    };
  }
}
