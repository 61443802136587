import { injectable, inject } from 'inversify';
import { IIndividualMarkersAdapterService } from '~/services/adapters/individual/individualAdapterService.interfaces';
import {
  IReportMarkerAdapterService,
  IReportMarkerAdapterServiceKey
} from '~/services/adapters/common/reportAdapterService.interfeces';
import { IndividualMarkers } from '~/types/individual/markers';
import { IndividualMarkersDTO } from '~/types/dto/individual/markersDTO';

@injectable()
export class IndividualMarkersAdapterService implements IIndividualMarkersAdapterService {
  constructor(
    @inject(IReportMarkerAdapterServiceKey as symbol) private reportMarkerAdapterService: IReportMarkerAdapterService
  ) {}
  getData(initData: IndividualMarkers): IndividualMarkersDTO {
    return {
      Markers: this.reportMarkerAdapterService.getData(initData.Markers),
      Rate: initData.Rate,
      RateIndicator: initData.RateIndicator
    };
  }
}
