/* eslint-disable class-methods-use-this */
import { injectable } from 'inversify';

import { ErrorMessages } from '~/resources/errorMessages';
import { IPrimitiveTypesNormalizationService } from './primitiveTypesNormalizationService.interfaces';

import { ErrorMessagesUtils } from '~/utils/errorMessagesUtils';

const { formatErrorMessage } = ErrorMessagesUtils;
@injectable()
export class PrimitiveTypesNormalizationService implements IPrimitiveTypesNormalizationService {
  normalizeOptionalString(value: any, field: string): string | undefined {
    if (value == undefined) return undefined;
    if (typeof value === 'string') return value;

    throw new Error(formatErrorMessage(field, ErrorMessages.INVALID_STRING, value));
  }

  normalizeString(value: string, field: string): string {
    if (typeof value === 'string') return value;

    throw new Error(formatErrorMessage(field, ErrorMessages.INVALID_STRING, value));
  }

  normalizeOptionalInteger(value: any, field: string): number | undefined {
    if (value == undefined) return undefined;
    if (typeof value === 'number') return value;

    throw new Error(formatErrorMessage(field, ErrorMessages.INVALID_INTEGER, value));
  }

  normalizeNumber(value: number, field: string): number {
    if (typeof value === 'number') return value;

    throw new Error(formatErrorMessage(field, ErrorMessages.INVALID_INTEGER, value));
  }

  normalizeOptionalBoolean(value: any, field: string): boolean | undefined {
    if (value == undefined) return undefined;
    if (typeof value === 'boolean') return value;

    throw new Error(formatErrorMessage(field, ErrorMessages.INVALID_BOOLEAN, value));
  }

  normalizeBoolean(value: boolean, field: string): boolean {
    if (typeof value === 'boolean') return value;

    throw new Error(formatErrorMessage(field, ErrorMessages.INVALID_BOOLEAN, value));
  }

  normalizeObject<T>(data: any, normalize: (data: T) => T | undefined, field: string): T | undefined {
    try {
      if (typeof data === 'object' && !Array.isArray(data) && data != undefined) return normalize(data);
      throw new Error(formatErrorMessage(field, ErrorMessages.INVALID_OBJECT, data));
    } catch (error) {
      console.log(`normalizeObject: ${field}`, error);
      return undefined;
    }
  }

  normalizeArray<T>(data: T[], predicate: (item: T, field: string) => T | undefined, field: string): T[] {
    if (Array.isArray(data)) {
      return data.reduce<T[]>((acc, item) => {
        try {
          const normalizeItem = predicate(item, field);
          if (normalizeItem != undefined) return acc.concat(normalizeItem);
          throw new Error();
        } catch (error) {
          return acc;
        }
      }, []);
    }
    return [];
  }

  normalizeOptional<T>(data: any, normalize: (data: T, field: string) => T | undefined, field: string): T | undefined {
    if (data == undefined) return undefined;
    if (typeof data === 'object' || Array.isArray(data)) {
      return normalize(data, field);
    }

    throw new Error(formatErrorMessage(field, ErrorMessages.INVALID_OBJECT, typeof data));
  }

  normalizeFieldObject<T>(data: object): T {
    return Object.fromEntries(Object.entries(data).map(([key, value]) => [
      [key[0].toUpperCase() + key.slice(1)], value
    ]));
  }
}
