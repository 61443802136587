import { injectable, inject } from 'inversify';
import { IndividualFinancialScoring } from '~/types/individual/financialScoring';
import { IPrimitiveTypesNormalizationServiceKey, IPrimitiveTypesNormalizationService } from '../common/primitiveTypesNormalizationService.interfaces';
import { INormalizationService } from '../common/reportNormalizationService.interfaces';

@injectable()
export class IndividualFinancialScoringNormalizationService implements INormalizationService<IndividualFinancialScoring> {
  constructor(
    @inject(IPrimitiveTypesNormalizationServiceKey as symbol) private primitiveTypesNormalizationService: IPrimitiveTypesNormalizationService,
  ) {}

  normalize(data: any, root: string): IndividualFinancialScoring | undefined {
    const { normalizeObject, normalizeOptionalInteger, normalizeOptionalString } = this.primitiveTypesNormalizationService;

    return normalizeObject(data, (data) => ({
      Score: normalizeOptionalInteger(data.Score, `${root}.Score`),
      Reason: normalizeOptionalString(data.Reason, `${root}.Reason`),
      CheckedFio: normalizeOptionalString(data.CheckedFio, `${root}.CheckedFio`),
      CheckedPhone: normalizeOptionalString(data.CheckedPhone, `${root}.CheckedPhone`),
      CheckedBirthday: normalizeOptionalString(data.CheckedBirthday, `${root}.CheckedBirthday`),
      CheckedPassportNumber: normalizeOptionalString(data.CheckedPassportNumber, `${root}.CheckedPassportNumber`),
      CheckedPassportIssueDate: normalizeOptionalString(data.CheckedPassportIssueDate, `${root}.CheckedPassportIssueDate`),
      FinancialScoringType: normalizeOptionalInteger(data.FinancialScoringType, `${root}.FinancialScoringType`)
    }), root);
  }
}
