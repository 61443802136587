import { injectable } from 'inversify';
import { Config } from '~/types/field-list/config';
import { IReportVehicleConfigService } from './reportVehicleConfigService.interfaces';
import { ReportVehicleRestricts } from '../types/reportVehicleRestricts';

@injectable()
export class ReportVehicleConfigService implements IReportVehicleConfigService {
  getConfig(initData: ReportVehicleRestricts): Config {
    return {
      fieldsDirection: 'divider-row',
      fieldsWidth: '100%',
      fieldsValueWidth: '450px',
      isBeginningDivider: true,
      columnGap: '10px',
      rowGap: '10px',
      fields: [
        {
          key: 'reason',
          label: 'Основание ограничения',
          value: initData.Reason,
          isInner: true,
          margin: {
            bottom: '24px'
          }
        },
        {
          label: 'Марка и модель',
          key: 'brand',
          value: initData.Brand
        },
        {
          label: 'Регион',
          key: 'region',
          value: initData.Region
        },
        {
          label: 'Ограничение',
          key: 'type',
          value: initData.Type
        },
      ]
    };
  }
}
