import { injectable, inject } from 'inversify';

import { ReportAnalysisOfAbsoluteLiquidity } from '~/types/legal-entities/reportAnalysisOfAbsoluteLiquidity';
import { LegalEntitiesReportAnalysisOfAbsoluteLiquidityIndicator } from '~/types/legal-entities/reportAnalysisOfAbsoluteLiquidityIndicator';
import { IPlaceholderDataService } from '~/services/placeholder/common/reportPlaceholderService.interfaces';
import { IPrimitiveTypesNormalizationService, IPrimitiveTypesNormalizationServiceKey } from './primitiveTypesNormalizationService.interfaces';
import { INormalizationService } from './reportNormalizationService.interfaces';
import { ILegalEntitiesReportAnalysisOfAbsoluteLiquidityIndicatorNormalizationServiceKey } from '../legalEntities/legalEntitiesNormalizationService.interfaces';
import { ILegalEntitiesReportAnalysisOfAbsoluteLiquidityIndicatorPlaceholderDataServiceKey } from '~/services/placeholder/legalEntities/legalEntitiesPlaceholderDataService.interfaces';

@injectable()
export class ReportAnalysisOfAbsoluteLiquidityNormalizationService implements INormalizationService<ReportAnalysisOfAbsoluteLiquidity> {
  constructor(
    @inject(ILegalEntitiesReportAnalysisOfAbsoluteLiquidityIndicatorNormalizationServiceKey as symbol) private reportAnalysisOfAbsoluteLiquidityIndicatorNormalizationService: INormalizationService<LegalEntitiesReportAnalysisOfAbsoluteLiquidityIndicator>,

    @inject(ILegalEntitiesReportAnalysisOfAbsoluteLiquidityIndicatorPlaceholderDataServiceKey as symbol) private reportAnalysisOfAbsoluteLiquidityIndicatorPlaceholderDataService: IPlaceholderDataService<LegalEntitiesReportAnalysisOfAbsoluteLiquidityIndicator>,

    @inject(IPrimitiveTypesNormalizationServiceKey as symbol) private primitiveTypesNormalizationService: IPrimitiveTypesNormalizationService,
  ) {}

  normalize(data: any, root: string): ReportAnalysisOfAbsoluteLiquidity | undefined {
    const { normalizeBoolean, normalizeOptionalInteger, normalizeString, normalizeObject } = this.primitiveTypesNormalizationService;

    return normalizeObject(data, (data) => ({
      MostLiquidAssetsToMostUrgentLiabilities: this.reportAnalysisOfAbsoluteLiquidityIndicatorNormalizationService.normalize(data.MostLiquidAssetsToMostUrgentLiabilities, `${root}.MostLiquidAssetsToMostUrgentLiabilities`) ?? this.reportAnalysisOfAbsoluteLiquidityIndicatorPlaceholderDataService.getData(),
      MarketableAssetsToOneYearLoanObligations: this.reportAnalysisOfAbsoluteLiquidityIndicatorNormalizationService.normalize(data.MarketableAssetsToOneYearLoanObligations, `${root}.MarketableAssetsToOneYearLoanObligations`) ?? this.reportAnalysisOfAbsoluteLiquidityIndicatorPlaceholderDataService.getData(),
      SlowSellingAssetsToLongTermBorrowings: this.reportAnalysisOfAbsoluteLiquidityIndicatorNormalizationService.normalize(data.SlowSellingAssetsToLongTermBorrowings, `${root}.SlowSellingAssetsToLongTermBorrowings`) ?? this.reportAnalysisOfAbsoluteLiquidityIndicatorPlaceholderDataService.getData(),
      HardSellingAssetsToCapitalAndReserves: this.reportAnalysisOfAbsoluteLiquidityIndicatorNormalizationService.normalize(data.HardSellingAssetsToCapitalAndReserves, `${root}.HardSellingAssetsToCapitalAndReserves`) ?? this.reportAnalysisOfAbsoluteLiquidityIndicatorPlaceholderDataService.getData(),
      DegreeOfRepaymentObligationsWithAssets: normalizeOptionalInteger(data.DegreeOfRepaymentObligationsWithAssets, `${root}.DegreeOfRepaymentObligationsWithAssets`),
      IsSolventInShortAndMediumTerm: normalizeBoolean(data.IsSolventInShortAndMediumTerm, `${root}.IsSolventInShortAndMediumTerm`),
      IsSolventInShortAndMediumTermConclusion: normalizeString(data.IsSolventInShortAndMediumTermConclusion, `${root}.IsSolventInShortAndMediumTermConclusion`),
      IsSolventInLongTerm: normalizeBoolean(data.IsSolventInLongTerm, `${root}.IsSolventInLongTerm`),
      IsSolventInLongTermConclusion: normalizeString(data.IsSolventInLongTermConclusion, `${root}.IsSolventInLongTermConclusion`),
    }), root);
  }
}
