/* eslint-disable class-methods-use-this, max-len */

import { injectable } from 'inversify';
import { LegalEntitiesLesseeSummary } from '~/types/legal-entities/lesseeSummary';
import {
  ILegalEntitiesLesseeSummaryAdapterService
} from '~/services/adapters/legalEntities/legalEntitiesAdapterService.interfaces';
import { LegalEntitiesLesseeSummaryDTO } from '~/types/dto/legalEntities/lesseeSummaryDTO';

@injectable()
export class LegalEntitiesLesseeSummaryAdapterService implements ILegalEntitiesLesseeSummaryAdapterService {
  getData(initData: LegalEntitiesLesseeSummary): LegalEntitiesLesseeSummaryDTO {
    return {
      ActualCount: initData.ActualCount,
      ArchiveCount: initData.ArchiveCount
    };
  }
}
