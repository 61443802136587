import { injectable } from 'inversify';
import { isEqual } from 'lodash-es';
import { MergeService } from '~/services/merge/merge-service';
import { ReportIndividualEntrepreneurDTO } from '~/types/dto/common/reportIndividualEntrepreneurDTO';

@injectable()
export class EntrepreneursMergeService extends MergeService<ReportIndividualEntrepreneurDTO> {
  protected canMerge(leftItem: ReportIndividualEntrepreneurDTO, rightItem: ReportIndividualEntrepreneurDTO): boolean {
    return isEqual(leftItem, rightItem);
  }

  protected mergeFields(leftItem: ReportIndividualEntrepreneurDTO, rightItem: ReportIndividualEntrepreneurDTO): ReportIndividualEntrepreneurDTO {
    return {
      Status: rightItem.Status || leftItem.Status,
      PrincipalActivity: rightItem.PrincipalActivity || leftItem.PrincipalActivity,
      RegistrationDate: rightItem.RegistrationDate || leftItem.RegistrationDate,
      LiquidationDate: rightItem.LiquidationDate || leftItem.LiquidationDate,
      Ogrn: rightItem.Ogrn || leftItem.Ogrn,
      Name: rightItem.Name || leftItem.Name,
      Inn: rightItem.Inn || leftItem.Inn,
    };
  }
}
