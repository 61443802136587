import { InjectionKey } from 'vue';
import { IConfigService } from '~/services/configService.interface';
import { IndividualAviasDTO } from '~/types/dto/individual/aviasDTO';
import { IndividualMovementBusDTO } from '~/types/dto/individual/movementBusDTO';
import { IReportCovidMovementsConfigService } from '~/modules/reportsCommon/reportMovement/common/services/reportMovementsConfigService.interfaces';

export type IIndividualMovementsAviasConfigService = IConfigService<IndividualAviasDTO>;

export type IIndividualMovementsBusConfigService = IConfigService<IndividualMovementBusDTO>;

export const IIndividualMovementsAviasConfigServiceKey: InjectionKey<IIndividualMovementsAviasConfigService> = Symbol('IIndividualMovementsAviasConfigService'); 
export const IIndividualMovementsBusConfigServiceKey: InjectionKey<IIndividualMovementsBusConfigService> = Symbol('IIndividualMovementsBusConfigService');
export const IIndividualMovementsCovidConfigServiceKey: InjectionKey<IReportCovidMovementsConfigService> = Symbol('IReportCovidMovementsConfigService');
