import { injectable } from 'inversify';
import { ReportPersonSite } from '~/types/common/reportPersonSite';
import { IPlaceholderDataService } from './reportPlaceholderService.interfaces';

@injectable()
export class ReportPersonSitePlaceholderService implements IPlaceholderDataService<ReportPersonSite> {
  getData(): ReportPersonSite {
    return {
      DomainName: '',
      Status: '',
      Administrator: '',
      RegistrationDate: '',
      ExpirationDate: '',
    };
  }
}
