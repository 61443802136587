import { injectable, inject } from 'inversify';

import { ReportVehicleRelation } from '~/types/common/reportVehicleRelation';
import { IPrimitiveTypesNormalizationServiceKey, IPrimitiveTypesNormalizationService } from './primitiveTypesNormalizationService.interfaces';
import { INormalizationService } from './reportNormalizationService.interfaces';

@injectable()
export class ReportVehicleRelationNormalizationService implements INormalizationService<ReportVehicleRelation> {
  constructor(
    @inject(IPrimitiveTypesNormalizationServiceKey as symbol) private primitiveTypesNormalizationService: IPrimitiveTypesNormalizationService,
  ) {}

  normalize(data: any, root: string): ReportVehicleRelation | undefined {
    const {
      normalizeNumber,
      normalizeString,
      normalizeOptionalString,
      normalizeObject
    } = this.primitiveTypesNormalizationService;

    return normalizeObject(data, (data) => ({
      SourceType: normalizeNumber(data.SourceType, `${root}.SourceType`),
      RegDate: normalizeOptionalString(data.RegDate, `${root}.RegDate`),
      DeregistrationDate: normalizeOptionalString(data.DeregistrationDate, `${root}.DeregistrationDate`),
      Name: normalizeOptionalString(data.Name, `${root}.Name`),
      Birthday: normalizeOptionalString(data.Birthday, `${root}.Birthday`),
      Inn: normalizeOptionalString(data.Inn, `${root}.Inn`),
      Passport: normalizeString(data.Passport, `${root}.Passport`),
      PhoneNumber: normalizeOptionalString(data.PhoneNumber, `${root}.PhoneNumber`),
      Address: normalizeOptionalString(data.Address, `${root}.Address`),
      Sts: normalizeOptionalString(data.Sts, `${root}.Sts`),
      Pts: normalizeOptionalString(data.Pts, `${root}.Pts`),
      PtsDepartmentName: normalizeString(data.PtsDepartmentName, `${root}.PtsDepartmentName`),
      PtsIssuedDate: normalizeOptionalString(data.PtsIssuedDate, `${root}.PtsIssuedDate`),
    }), root);
  }
}
