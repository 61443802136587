import { injectable } from 'inversify';
import { ReportBoundedPersonPhone } from '~/types/common/reportBoundedPersonPhone';
import { IPlaceholderDataService } from './reportPlaceholderService.interfaces';

@injectable()
export class ReportBoundedPersonPhonePlaceholderService implements IPlaceholderDataService<ReportBoundedPersonPhone> {
  getData(): ReportBoundedPersonPhone {
    return {
      Value: '',
      Source: 0,
      SourceStr: '',
      Tag: '',
      Tags: [],
      FullName: '',
      RelatedAddress: '',
      RelatedBirth: '',
      Emails: [],
      OriginalRequestData: '',
    };
  }
}
