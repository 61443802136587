/* eslint-disable class-methods-use-this, max-len */

import { injectable } from 'inversify';
import { LegalEntitiesCompanyStatusDTO } from '~/types/dto/legalEntities/legalEntitiesCompanyStatusDTO';
import { LegalEntitiesCompanyStatus } from '~/types/legal-entities/LegalEntitiesCompanyStatus';
import {
  ILegalEntitiesCompanyStatusAdapterService
} from '~/services/adapters/legalEntities/legalEntitiesAdapterService.interfaces';

@injectable()
export class LegalEntitiesCompanyStatusAdapterService implements ILegalEntitiesCompanyStatusAdapterService {
  getData(initData: LegalEntitiesCompanyStatus[]): LegalEntitiesCompanyStatusDTO[] {
    return (initData ?? []).map((companyStatus) => ({
      EndDate: companyStatus.EndDate ?? '',
      StartDate: companyStatus.StartDate ?? '',
      StatusOnPeriodDate: companyStatus.StatusOnPeriodDate ?? ''
    }));
  }
}
