import { injectable, inject } from 'inversify';
import { LegalEntitiesCompanyActualAddress } from '~/types/legal-entities/companyActualAddress';
import { IPrimitiveTypesNormalizationServiceKey, IPrimitiveTypesNormalizationService } from '../common/primitiveTypesNormalizationService.interfaces';
import { INormalizationService } from '../common/reportNormalizationService.interfaces';

@injectable()
export class LegalEntitiesCompanyActualAddressNormalizationService implements INormalizationService<LegalEntitiesCompanyActualAddress> {
  constructor(
    @inject(IPrimitiveTypesNormalizationServiceKey as symbol) private primitiveTypesNormalizationService: IPrimitiveTypesNormalizationService,
  ) {}

  normalize(data: any, root: string): LegalEntitiesCompanyActualAddress | undefined {
    const {
      normalizeString,
      normalizeOptionalString,
      normalizeNumber,
      normalizeOptionalBoolean,
      normalizeObject
    } = this.primitiveTypesNormalizationService;

    return normalizeObject(data, (data) => ({
      Address: normalizeString(data.Address, `${root}.Address`),
      CompaniesWithThisAddress: normalizeNumber(data.CompaniesWithThisAddress, `${root}.CompaniesWithThisAddress`),
      UnreliabilityCode: normalizeString(data.UnreliabilityCode, `${root}.UnreliabilityCode`),
      UnreliabilityDate: normalizeOptionalString(data.UnreliabilityDate, `${root}.UnreliabilityDate`),
      IsRegionChanged: normalizeOptionalBoolean(data.IsRegionChanged, `${root}.IsRegionChanged`)
    }), root);
  }
}
