import { injectable } from 'inversify';
import { ILegalEntitiesCompanyFounderFullNameConfigService } from '~/modules/legalEntities/common/legalEntitiesCompanyFounder/services/legalEntitiesCompanyFounderConfigService.interfaces';
import { Config } from '~/types/field-list/config';
import { LegalEntitiesCompanyFounderDTO } from '~/types/dto/legalEntities/companyFounder';
import { LegalEntitiesCompanyFounderUtils } from '~/modules/legalEntities/common/legalEntitiesCompanyFounder/utils/legalEntitiesCompanyFounderUtils';

@injectable()
export class LegalEntitiesCompanyFounderFullNameConfigService implements ILegalEntitiesCompanyFounderFullNameConfigService {
  getConfig(initData: LegalEntitiesCompanyFounderDTO): Config {
    const founderType = LegalEntitiesCompanyFounderUtils.convertStringToLegalEntitiesFoundersType(initData.FounderType);
    return {
      fieldsDirection: 'column',
      fieldsWidth: '100%',
      fieldsValueWidth: '100%',
      fields: [
        {
          label: LegalEntitiesCompanyFounderUtils.convertLegalEntitiesFoundersToString(founderType),
          key: 'fullName',
          value: initData.Fullname,
        },
      ]
    };
  }
}
