import { injectable, inject } from 'inversify';
import { IndividualAccrual } from '~/types/individual/accrual';
import { IPrimitiveTypesNormalizationServiceKey, IPrimitiveTypesNormalizationService } from '../common/primitiveTypesNormalizationService.interfaces';
import { INormalizationService } from '../common/reportNormalizationService.interfaces';

@injectable()
export class IndividualAccrualNormalizationService implements INormalizationService<IndividualAccrual> {
  constructor(
    @inject(IPrimitiveTypesNormalizationServiceKey as symbol) private primitiveTypesNormalizationService: IPrimitiveTypesNormalizationService,
  ) {}

  normalize(data: any, root: string): IndividualAccrual | undefined {
    const {
      normalizeObject,
      normalizeString,
      normalizeOptionalString,
      normalizeOptionalInteger
    } = this.primitiveTypesNormalizationService;

    return normalizeObject(data, (data) => ({
      Description: normalizeString(data.Description, `${root}.Description`),
      StartDate: normalizeOptionalString(data.StartDate, `${root}.StartDate`),
      DebtSum: normalizeOptionalInteger(data.DebtSum, `${root}.DebtSum`),
      DebtType: normalizeString(data.DebtType, `${root}.DebtType`),
    }), root);
  }
}
