import { injectable, inject } from 'inversify';
import { ReportSocialNetworkSecondaryEducation } from '~/types/common/reportSocialNetworkSecondaryEducation';

import { IPrimitiveTypesNormalizationService, IPrimitiveTypesNormalizationServiceKey } from './primitiveTypesNormalizationService.interfaces';
import { INormalizationService } from './reportNormalizationService.interfaces';

@injectable()
export class ReportSocialNetworkSecondaryEducationNormalizationService implements INormalizationService<ReportSocialNetworkSecondaryEducation> {
  constructor(
    @inject(IPrimitiveTypesNormalizationServiceKey as symbol) private primitiveTypesNormalizationService: IPrimitiveTypesNormalizationService,
  ) {}

  normalize(data: any, root: string): ReportSocialNetworkSecondaryEducation | undefined {
    const {
      normalizeObject,
      normalizeNumber,
      normalizeString,
      normalizeOptionalString
    } = this.primitiveTypesNormalizationService;

    return normalizeObject(data, (data) => ({
      Type: normalizeNumber(data.Type, `${root}.Type`),
      SocialNetworkId: normalizeString(data.SocialNetworkId, `${root}.SocialNetworkId`),
      LastName: normalizeString(data.LastName, `${root}.LastName`),
      FirstName: normalizeString(data.FirstName, `${root}.FirstName`),
      Birthday: normalizeOptionalString(data.Birthday, `${root}.Birthday`),
      EducationName: normalizeString(data.EducationName, `${root}.EducationName`),
      SpecialtyName: normalizeString(data.SpecialtyName, `${root}.SpecialtyName`),
      StartDate: normalizeOptionalString(data.StartDate, `${root}.StartDate`),
      DiplomaDate: normalizeOptionalString(data.DiplomaDate, `${root}.DiplomaDate`),
    }), root);
  }
}
