import { get } from 'lodash-es';
import { createReportEntitiesStore } from '~/factories/reportEntitiesStoreFactory';
import { UIReportType } from '~/types/reportType';
import { useInjectable } from '~/hooks/useInjectable';
import { IDataListAdapterKey } from '~/services/adapters/dataListAdapterServise.interface';
import { IPhoneNumberDataBlobDataAdapterServiceKey } from '~/services/adapters/phoneNimber/phoneNumberAdapterService.interfaces';
import { PhoneNumbersDataBlobData } from '~/types/blobs/phone-numbers-data-blob-data';

export default createReportEntitiesStore(UIReportType.phoneNumber, (data) => {
  const phoneNumberDataList = useInjectable(IDataListAdapterKey);
  const phoneNumberService = useInjectable(IPhoneNumberDataBlobDataAdapterServiceKey);

  const phoneNumberData = get(phoneNumberDataList.getDataList(data).map((phoneNumber) => phoneNumberService.getData(phoneNumber as PhoneNumbersDataBlobData)), 0);
  const relatedPerson = get(phoneNumberData.RelatedPersons, 0);
  return { phoneNumberData, relatedPerson };
});
