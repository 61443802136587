import { injectable } from 'inversify';

import { LegalEntitiesSocial } from '~/types/legal-entities/social';
import { IPlaceholderDataService } from '../common/reportPlaceholderService.interfaces';

@injectable()
export class LegalEntitiesSocialPlaceholderDataService implements IPlaceholderDataService<LegalEntitiesSocial> {
  getData() {
    return {
      Text: '',
      Links: []
    };
  }
}
