import { inject, injectable } from 'inversify';
import { IIndicatorsOfCashFlowLineChartService } from '~/modules/legalEntities/financialAnalytics/lineCharts/indicatorsOfCashFlowLineChart/services/indicatorsOfCashFlowLineChartService.interfaces';
import { LegalEntitiesFinancialAnalysisExtendedDTO } from '~/types/dto/legalEntities/financialAnalysisExtended';
import { DataOptionLineChart } from '~/modules/legalEntities/financialAnalytics/lineCharts/common/types/DataOptionLineChart';
import {
  IDataOptionsPropertiesLineChartService,
  IDataOptionsPropertiesLineChartServiceKey
} from '~/modules/legalEntities/financialAnalytics/lineCharts/common/services/dataOptionsPropertiesLineChartService.interfaces';
import { LINE_CHART_COLOR_PALETTE } from '~/modules/legalEntities/financialAnalytics/lineCharts/common/configuration/constants/colorPalette';
import { LINE_CHART_GRADIENT_PALETTE } from '~/modules/legalEntities/financialAnalytics/lineCharts/common/configuration/constants/gradientPalette';
import { INDICATORS_OF_CASH_FLOW } from '~/modules/legalEntities/financialAnalytics/common/configuration/constants/indicatorsOfCashFlow';
import {
  IFinancialAnalysisCommonTitlesService,
  IFinancialAnalysisCommonTitlesServiceKey
} from '~/modules/legalEntities/financialAnalytics/common/services/financialAnalysisCommonTitlesService.interfaces';

@injectable()
export class IndicatorsOfCashFlowLineChartService implements IIndicatorsOfCashFlowLineChartService {
  constructor(
    @inject(IDataOptionsPropertiesLineChartServiceKey as symbol) private dataOptionsPropertiesLineChartService: IDataOptionsPropertiesLineChartService,
    @inject(IFinancialAnalysisCommonTitlesServiceKey as symbol) private financialAnalysisCommonTitlesService: IFinancialAnalysisCommonTitlesService
  ) {}

  private readonly FILL_GRADIENT = {
    OperatingCashFlow: LINE_CHART_GRADIENT_PALETTE.turquoise,
    CashFlowAvailableForDebtService: LINE_CHART_GRADIENT_PALETTE.green,
    InvestmentCashFlow: LINE_CHART_GRADIENT_PALETTE.red,
    FinancialCashFlow: LINE_CHART_GRADIENT_PALETTE.blue,
    DebtServiceCoverageRatio: LINE_CHART_GRADIENT_PALETTE.orange,
  };

  private readonly BORDER_COLOR = {
    OperatingCashFlow: LINE_CHART_COLOR_PALETTE.turquoise,
    CashFlowAvailableForDebtService: LINE_CHART_COLOR_PALETTE.green,
    InvestmentCashFlow: LINE_CHART_COLOR_PALETTE.red,
    FinancialCashFlow: LINE_CHART_COLOR_PALETTE.blue,
    DebtServiceCoverageRatio: LINE_CHART_COLOR_PALETTE.orange,
  };
  getDataOptions(initData: LegalEntitiesFinancialAnalysisExtendedDTO): Array<DataOptionLineChart> {
    const { IndicatorsOfCashFlow } = initData;

    return Object.keys(IndicatorsOfCashFlow).map(key => {
      const typedKey = key as keyof typeof IndicatorsOfCashFlow;

      const data = this.dataOptionsPropertiesLineChartService.getData(IndicatorsOfCashFlow[typedKey]);

      return {
        title: this.financialAnalysisCommonTitlesService.getTitle(INDICATORS_OF_CASH_FLOW[typedKey], data),
        colors: this.FILL_GRADIENT[typedKey],
        borderColors: this.BORDER_COLOR[typedKey],
        data,
        labels: this.dataOptionsPropertiesLineChartService.getLabels(initData.TwoYearsAgo, initData.LastYear, initData.CurrentYear)
      };
    });
  }
}
