import { injectable } from 'inversify';
import { ReportPersonSnils } from '~/types/common/reportPersonSnils';
import { IPlaceholderDataService } from './reportPlaceholderService.interfaces';

@injectable()
export class ReportPersonSnilsPlaceholderService implements IPlaceholderDataService<ReportPersonSnils> {
  getData(): ReportPersonSnils {
    return {
      Value: '',
      CorrespondingPerson: '',
    };
  }
}
