import { injectable, inject } from 'inversify';
import { DataBlobReportOriginalInput } from '~/types/blobs/data-blob-report-original-input';
import { DataBlobReportOriginalInputFileInfo } from '~/types/blobs/data-blob-report-original-input-file-info';
import { IPlaceholderDataService } from '../common/reportPlaceholderService.interfaces';
import { IDataBlobReportOriginalInputFileInfoPlaceholderServiceKey } from './dataBlobPlaceholderService.interfaces';

@injectable()
export class DataBlobReportOriginalInputPlaceholderService implements IPlaceholderDataService<DataBlobReportOriginalInput> {
  constructor(
    @inject(IDataBlobReportOriginalInputFileInfoPlaceholderServiceKey as symbol) private dataBlobReportOriginalInputFileInfoPlaceholderService: IPlaceholderDataService<DataBlobReportOriginalInputFileInfo>,
  ) {}

  getData(): DataBlobReportOriginalInput {
    return {
      Fio: '',
      Birthday: '',
      Inn: '',
      Passport: '',
      Email: '',
      SocialId: '',
      Ogrn: '',
      PhoneNumber: '',
      CarNumber: '',
      PhotoId: '',
      PhotoInfo: this.dataBlobReportOriginalInputFileInfoPlaceholderService.getData(),
      InputReportType: 0
    };
  }
}
