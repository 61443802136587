import { injectable } from 'inversify';
import { IndividualGroup } from '~/types/individual/group';
import { IPlaceholderDataService } from '../common/reportPlaceholderService.interfaces';

@injectable()
export class IndividualGroupPlaceholderService implements IPlaceholderDataService<IndividualGroup> {
  getData(): IndividualGroup {
    return {
      Name: '',
    };
  }
}
