import { injectable, inject } from 'inversify';
import { INormalizationService } from '../common/reportNormalizationService.interfaces';

import { IPrimitiveTypesNormalizationServiceKey, IPrimitiveTypesNormalizationService } from '../common/primitiveTypesNormalizationService.interfaces';
import { LegalEntitiesTaxesPenalties } from '~/types/legal-entities/taxesPenalties';

@injectable()
export class LegalEntitiesTaxesPenaltiesNormalizationService implements INormalizationService<LegalEntitiesTaxesPenalties> {
  constructor(
    @inject(IPrimitiveTypesNormalizationServiceKey as symbol) private primitiveTypesNormalizationService: IPrimitiveTypesNormalizationService,
  ) {}

  normalize(data: any, root: string): LegalEntitiesTaxesPenalties | undefined {
    const { normalizeString, normalizeNumber, normalizeObject, normalizeFieldObject } = this.primitiveTypesNormalizationService;

    const normalizeFieldData = normalizeFieldObject(data);

    return normalizeObject(normalizeFieldData, (data) => ({
      Name: normalizeString(data.Name, `${root}.Name`),
      Sum: normalizeNumber(data.Sum, `${root}.Sum`),
      Year: normalizeNumber(data.Year, `${root}.Year`),
    }), root);
  }
}
