import { injectable, inject } from 'inversify';

import { IPrimitiveTypesNormalizationServiceKey, IPrimitiveTypesNormalizationService } from './primitiveTypesNormalizationService.interfaces';
import { ReportMainEntityFile } from '~/types/common/reportMainEntityFile';
import { INormalizationService } from './reportNormalizationService.interfaces';

@injectable()
export class ReportMainEntityFileNormalizationService implements INormalizationService<ReportMainEntityFile> {
  constructor(
    @inject(IPrimitiveTypesNormalizationServiceKey as symbol) private primitiveTypesNormalizationService: IPrimitiveTypesNormalizationService,
  ) {}

  normalize(data: any, root: string): ReportMainEntityFile | undefined {
    const {
      normalizeBoolean,
      normalizeNumber,
      normalizeOptionalString,
      normalizeString,
      normalizeObject,
    } = this.primitiveTypesNormalizationService;

    return normalizeObject(data, (data) => ({
      FileData: normalizeOptionalString(data.FileData, `${root}.FileData`),
      DataLength: normalizeNumber(data.DataLength, `${root}.DataLength`),
      FileHash: normalizeOptionalString(data.FileHash, `${root}.FileHash`),
      FileUrl: normalizeOptionalString(data.FileUrl, `${root}.FileUrl`),
      Filename: normalizeOptionalString(data.Filename, `${root}.Filename`),
      FilenameFull: normalizeOptionalString(data.FilenameFull, `${root}.FilenameFull`),
      Extension: normalizeString(data.Extension, `${root}.Extension`),
      Comment: normalizeOptionalString(data.Comment, `${root}.Comment`),
      PostDate: normalizeOptionalString(data.PostDate, `${root}.PostDate`),
      IsExists: normalizeBoolean(data.IsExists, `${root}.IsExists`),
    }), root);
  }
}
