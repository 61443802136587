import { IContainerBinder } from '~/configuration/inversify/containerAccessor.interface';
import {
  IReportCovidMovementsConfigServiceKey,
  IReportTaxiMovementsConfigServiceKey
} from '~/modules/reportsCommon/reportMovement/common/services/reportMovementsConfigService.interfaces';
import { ReportCovidMovementsConfigService } from '~/modules/reportsCommon/reportMovement/common/services/reportCovidMovementsConfigService';
import { ReportTaxiMovementsConfigService } from '~/modules/reportsCommon/reportMovement/common/services/reportTaxiMovementsConfigService';

export function bindReportMovementsServices(bind: IContainerBinder) {
  bind(IReportCovidMovementsConfigServiceKey).to(ReportCovidMovementsConfigService);
  bind(IReportTaxiMovementsConfigServiceKey).to(ReportTaxiMovementsConfigService);
}
