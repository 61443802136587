import { injectable } from 'inversify';
import { IndividualBankruptcy } from '~/types/individual/bankruptcy';
import { IPlaceholderDataService } from '../common/reportPlaceholderService.interfaces';

@injectable()
export class IndividualBankruptcyPlaceholderService implements IPlaceholderDataService<IndividualBankruptcy> {
  getData(): IndividualBankruptcy {
    return {
      Stage: '',
      StageDate: '',
      CaseNumber: '',
      LastMessageDate: '',
      ExtrajudicialStatus: '',
      ExtrajudicialStartDate: '',
      ExtrajudicialEndDate: '',
      ExtrajudicialReasonForTermination: '',
      ExtrajudicialReasonForReturn: '',
      ArbitrManagerFio: '',
      ArbitrManagerInn: '',
      ArbitrManagerOgrn: '',
    };
  }
}
