import { injectable } from 'inversify';
import { ReportAddress } from '~/types/common/address';
import { IPlaceholderDataService } from './reportPlaceholderService.interfaces';

@injectable()
export class ReportAddressPlaceholderService implements IPlaceholderDataService<ReportAddress> {
  getData(): ReportAddress {
    return {
      AddressType: 0,
      Address: '',
      FlatType: '',
      FlatArea: '',
      SquareMeterPrice: '',
      FlatPrice: '',
      PostalBox: '',
      FiasId: '',
      FiasLevel: '',
      FiasActualityState: '',
      KladrId: '',
      CapitalMarker: '',
      TaxOffice: '',
      TaxOfficeLegal: '',
      TimeZone: '',
      GeoLat: '',
      GeoLon: '',
      CoordinateAccuracyCode: 0,
      CodeSuitabilityMailing: 0,
      AvailabilityHouseInFias: '',
      VerificationCode: 0,
      UnparsedParts: '',
      NameStationMetros: '',
    };
  }
}
