import { injectable, inject } from 'inversify';

import { LegalEntitiesTaxServiceInfo } from '~/types/legal-entities/taxServiceInfo';
import { IPrimitiveTypesNormalizationServiceKey, IPrimitiveTypesNormalizationService } from '../common/primitiveTypesNormalizationService.interfaces';
import { INormalizationService } from '../common/reportNormalizationService.interfaces';

@injectable()
export class LegalEntitiesTaxServiceNormalizationService implements INormalizationService<LegalEntitiesTaxServiceInfo> {
  constructor(
    @inject(IPrimitiveTypesNormalizationServiceKey as symbol) private primitiveTypesNormalizationService: IPrimitiveTypesNormalizationService
  ) {}

  normalize(data: any, root: string): LegalEntitiesTaxServiceInfo | undefined {
    const { normalizeString, normalizeOptionalString, normalizeObject } = this.primitiveTypesNormalizationService;

    return normalizeObject(data, (data) => ({
      RegistrationDate: normalizeOptionalString(data.RegistrationDate, `${root}.RegistrationDate`),
      ServiceCode: normalizeString(data.ServiceCode, `${root}.ServiceCode`),
      ServiceName: normalizeString(data.ServiceName, `${root}.ServiceName`),
    }), root);
  }
}
