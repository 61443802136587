import { EventHandler } from '@/types/event-bus/handler';
import { UnsubscribeFn } from '@/types/event-bus/unsubscribe';

export class Event<T extends Array<any> = []> {
  private handlers: Set<EventHandler<T>>;

  constructor() {
    this.handlers = new Set();
  }

  on(handler: EventHandler<T>): UnsubscribeFn {
    if (this.handlers.has(handler)) {
      throw new Error('Handler already exists');
    }

    this.handlers.add(handler);

    return () => this.off(handler);
  }

  off(handler: EventHandler<T>) {
    this.handlers.delete(handler);
  }

  emit(...data: T) {
    this.handlers.forEach((handler) => handler(...data));
  }
}
