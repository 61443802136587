import { injectable, inject } from 'inversify';
import { LegalEntitiesReportFinancialIndicatorBase } from '~/types/legal-entities/reportFinancialIndicatorBase';
import { IPrimitiveTypesNormalizationServiceKey, IPrimitiveTypesNormalizationService } from '../common/primitiveTypesNormalizationService.interfaces';
import { INormalizationService } from '../common/reportNormalizationService.interfaces';

@injectable()
export class LegalEntitiesReportFinancialIndicatorBaseNormalizationService implements INormalizationService<LegalEntitiesReportFinancialIndicatorBase> {
  constructor(
    @inject(IPrimitiveTypesNormalizationServiceKey as symbol) private primitiveTypesNormalizationService: IPrimitiveTypesNormalizationService
  ) {}

  normalize(data: any, root: string): LegalEntitiesReportFinancialIndicatorBase | undefined {
    const { normalizeOptionalInteger, normalizeObject } = this.primitiveTypesNormalizationService;

    return normalizeObject(data, (data) => ({
      TwoYearsAgo: normalizeOptionalInteger(data.TwoYearsAgo, `${root}.TwoYearsAgo`),
      LastYear: normalizeOptionalInteger(data.LastYear, `${root}.LastYear`),
      CurrentYear: normalizeOptionalInteger(data.CurrentYear, `${root}.CurrentYear`),
    }), root);
  }
}
