import { injectable } from 'inversify';
import { IndividualPhone } from '~/types/individual/phone';
import { IPlaceholderDataService } from '../common/reportPlaceholderService.interfaces';

@injectable()
export class IndividualPhonePlaceholderService implements IPlaceholderDataService<IndividualPhone> {
  getData(): IndividualPhone {
    return {
      PhoneType: '',
      Value: '',
      Country: '',
      Provider: '',
      TimeZone: '',
      IsVerified: false,
      Messengers: [],
      ChangeDate: '',
      DeviceModel: '',
      VerificationCode: 0,
      Mentions: [],
    };
  }
}
