import { inject, injectable } from 'inversify';
import { ReportConnectionRelatedDTO } from '~/types/dto/common/reportConnectionRelatedDTO';
import { ReportConnectionRelated } from '~/types/common/reportConnectionRelated';
import {
  IReportConnectionRelatedAdapterService, IReportConnectionRelatedChainService, IReportConnectionRelatedChainServiceKey,
  IReportConnectionRelatedEntitiesAdapterService,
  IReportConnectionRelatedEntitiesAdapterServiceKey,
  IReportConnectionRelatedRelationsAdapterService,
  IReportConnectionRelatedRelationsAdapterServiceKey,
} from '~/services/adapters/common/reportAdapterService.interfeces';
import { ReportConnectionRelatedChainService } from '~/services/adapters/common/reportConnectionRelatedChainService';

@injectable()
export class ReportConnectionRelatedAdapterService implements IReportConnectionRelatedAdapterService {
  constructor(
    @inject(IReportConnectionRelatedChainServiceKey as symbol) private reportConnectionRelatedChainService: IReportConnectionRelatedChainService,
  ) {}
  getData(initData: ReportConnectionRelated): ReportConnectionRelatedDTO {
    return {
      Chains: this.reportConnectionRelatedChainService.getData(initData.Chains)
    };
  }
}
