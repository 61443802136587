import { injectable, inject } from 'inversify';
import { IndividualBankruptcy } from '~/types/individual/bankruptcy';
import { IPrimitiveTypesNormalizationServiceKey, IPrimitiveTypesNormalizationService } from '../common/primitiveTypesNormalizationService.interfaces';
import { INormalizationService } from '../common/reportNormalizationService.interfaces';

@injectable()
export class IndividualBankruptcyNormalizationService implements INormalizationService<IndividualBankruptcy> {
  constructor(
    @inject(IPrimitiveTypesNormalizationServiceKey as symbol) private primitiveTypesNormalizationService: IPrimitiveTypesNormalizationService,
  ) {}

  normalize(data: any, root: string): IndividualBankruptcy | undefined {
    const { normalizeObject, normalizeString, normalizeOptionalString } = this.primitiveTypesNormalizationService;

    return normalizeObject(data, (data) => ({
      Stage: normalizeString(data.Stage, `${root}.Stage`),
      StageDate: normalizeOptionalString(data.StageDate, `${root}.StageDate`),
      CaseNumber: normalizeString(data.CaseNumber, `${root}.CaseNumber`),
      LastMessageDate: normalizeOptionalString(data.LastMessageDate, `${root}.LastMessageDate`),
      ExtrajudicialStatus: normalizeOptionalString(data.ExtrajudicialStatus, `${root}.ExtrajudicialStatus`),
      ExtrajudicialStartDate: normalizeOptionalString(data.ExtrajudicialStartDate, `${root}.ExtrajudicialStartDate`),
      ExtrajudicialEndDate: normalizeOptionalString(data.ExtrajudicialEndDate, `${root}.ExtrajudicialEndDate`),
      ExtrajudicialReasonForTermination: normalizeOptionalString(data.ExtrajudicialReasonForTermination, `${root}.ExtrajudicialReasonForTermination`),
      ExtrajudicialReasonForReturn: normalizeOptionalString(data.ExtrajudicialReasonForReturn, `${root}.ExtrajudicialReasonForReturn`),
      ArbitrManagerFio: normalizeString(data.ArbitrManagerFio, `${root}.ArbitrManagerFio`),
      ArbitrManagerInn: normalizeString(data.ArbitrManagerInn, `${root}.ArbitrManagerInn`),
      ArbitrManagerOgrn: normalizeOptionalString(data.ArbitrManagerOgrn, `${root}.ArbitrManagerOgrn`),
    }), root);
  }
}
