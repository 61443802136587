import { StringMap } from '~/types/stringMap';
import { UIReportType } from '~/types/reportType';
import { MockDataFile } from '~/types/mockDataFile';

export const mockFiles: StringMap<MockDataFile[]> = {
  [UIReportType.ul]: new Array<MockDataFile>(
    {
      name: 'companyReport_inn7842155505.json'
    },
  ),

  [UIReportType.phoneNumber]: new Array<MockDataFile>(
    {
      name: '79055762271.json'
    },
  ),

  [UIReportType.fl]: new Array<MockDataFile>(
    {
      name: 'personReportБабаянФЛ.json'
    },
  ),

  [UIReportType.vehicle]: new Array<MockDataFile>(
    {
      name: 'К606НА77.json'
    },
  )
};
