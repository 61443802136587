import { IContainerBinder } from '~/configuration/inversify/containerAccessor.interface';
import {
  IMessageBoardsDescriptionInfoConfigServiceKey,
  IMessageBoardsGeneralInfoConfigServiceKey,
  IMessageBoardsInfoConfigServiceKey
} from '~/modules/reportsCommon/reportMessageBoardRecord/services/messageBoardsConfigService.interfaces';
import { MessageBoardsGeneralInfoConfigService } from '~/modules/reportsCommon/reportMessageBoardRecord/services/messageBoardsGeneralInfoConfigService';
import { MessageBoardsDescriptionInfoConfigService } from '~/modules/reportsCommon/reportMessageBoardRecord/services/messageBoardsDescriptionInfoConfigService';
import { MessageBoardsInfoConfigService } from '~/modules/reportsCommon/reportMessageBoardRecord/services/messageBoardsInfoConfigService';

import { bindPhoneNumberMessageBoardsService } from '~/modules/reportsCommon/reportMessageBoardRecord/phoneNumber/configurations/inversify/container.phoneNumberMessageBoards';

export function bindMessageBoardsService(bind: IContainerBinder) {
  bind(IMessageBoardsGeneralInfoConfigServiceKey).to(MessageBoardsGeneralInfoConfigService);
  bind(IMessageBoardsDescriptionInfoConfigServiceKey).to(MessageBoardsDescriptionInfoConfigService);
  bind(IMessageBoardsInfoConfigServiceKey).to(MessageBoardsInfoConfigService);

  bindPhoneNumberMessageBoardsService(bind);
}
