import { injectable, inject } from 'inversify';
import { IReportMainEntityFilePlaceholderServiceKey, IPlaceholderDataService } from '~/services/placeholder/common/reportPlaceholderService.interfaces';
import { ReportMainEntityFile } from '~/types/common/reportMainEntityFile';
import { IndividualWantedFssp } from '~/types/individual/wantedFssp';
import { IPrimitiveTypesNormalizationServiceKey, IPrimitiveTypesNormalizationService } from '../common/primitiveTypesNormalizationService.interfaces';
import { INormalizationService, IReportMainEntityFileNormalizationServiceKey } from '../common/reportNormalizationService.interfaces';

@injectable()
export class IndividualWantedFsspNormalizationService implements INormalizationService<IndividualWantedFssp> {
  constructor(
    @inject(IReportMainEntityFileNormalizationServiceKey as symbol) private reportMainEntityFileNormalizationService: INormalizationService<ReportMainEntityFile>,
    @inject(IReportMainEntityFilePlaceholderServiceKey as symbol) private reportMainEntityFilePlaceholderService: IPlaceholderDataService<ReportMainEntityFile>,

    @inject(IPrimitiveTypesNormalizationServiceKey as symbol) private primitiveTypesNormalizationService: IPrimitiveTypesNormalizationService,
  ) {}

  normalize(data: any, root: string): IndividualWantedFssp | undefined {
    const { normalizeObject, normalizeString, normalizeOptionalString } = this.primitiveTypesNormalizationService;

    return normalizeObject(data, (data) => ({
      Photo: this.reportMainEntityFileNormalizationService.normalize(data.Photo, `${root}.Photo`) ?? this.reportMainEntityFilePlaceholderService.getData(),
      DepartmentName: normalizeString(data.DepartmentName, `${root}.DepartmentName`),
      InitiatorPhone: normalizeString(data.InitiatorPhone, `${root}.InitiatorPhone`),
      ChangeDate: normalizeOptionalString(data.ChangeDate, `${root}.ChangeDate`),
    }), root);
  }
}
