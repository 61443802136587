import { injectable, inject } from 'inversify';
import { IndividualMessageAboutBankruptcy } from '~/types/individual/messageAboutBankruptcy';
import { IPrimitiveTypesNormalizationServiceKey, IPrimitiveTypesNormalizationService } from '../common/primitiveTypesNormalizationService.interfaces';
import { INormalizationService } from '../common/reportNormalizationService.interfaces';

@injectable()
export class IndividualMessageAboutBankruptcyNormalizationService implements INormalizationService<IndividualMessageAboutBankruptcy> {
  constructor(
    @inject(IPrimitiveTypesNormalizationServiceKey as symbol) private primitiveTypesNormalizationService: IPrimitiveTypesNormalizationService,
  ) {}

  normalize(data: any, root: string): IndividualMessageAboutBankruptcy | undefined {
    const { normalizeObject, normalizeString, normalizeOptionalString } = this.primitiveTypesNormalizationService;

    return normalizeObject(data, (data) => ({
      MessageId: normalizeString(data.MessageId, `${root}.MessageId`),
      PubDate: normalizeOptionalString(data.PubDate, `${root}.PubDate`),
      DecisionResultTypeName: normalizeOptionalString(data.DecisionResultTypeName, `${root}.DecisionResultTypeName`),
      DecisionResultDate: normalizeOptionalString(data.DecisionResultDate, `${root}.DecisionResultDate`),
      AnnulationMessageId: normalizeOptionalString(data.AnnulationMessageId, `${root}.AnnulationMessageId`),
      AnnulationReason: normalizeOptionalString(data.AnnulationReason, `${root}.AnnulationReason`),
    }), root);
  }
}
