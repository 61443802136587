import { injectable, inject } from 'inversify';
import { IndividualTerroristsAndExtremistsRecord } from '~/types/individual/terroristsAndExtremistsRecord';
import { IPrimitiveTypesNormalizationServiceKey, IPrimitiveTypesNormalizationService } from '../common/primitiveTypesNormalizationService.interfaces';
import { INormalizationService } from '../common/reportNormalizationService.interfaces';

@injectable()
export class IndividualTerroristsAndExtremistsRecordNormalizationServiceKey implements INormalizationService<IndividualTerroristsAndExtremistsRecord> {
  constructor(
    @inject(IPrimitiveTypesNormalizationServiceKey as symbol) private primitiveTypesNormalizationService: IPrimitiveTypesNormalizationService,
  ) {}

  normalize(data: any, root: string): IndividualTerroristsAndExtremistsRecord | undefined {
    const { normalizeObject, normalizeString } = this.primitiveTypesNormalizationService;

    return normalizeObject(data, (data) => ({
      Description: normalizeString(data.Description, `${root}.Description`),
      LastName: normalizeString(data.LastName, `${root}.LastName`),
      FirstName: normalizeString(data.FirstName, `${root}.FirstName`),
      Patronymic: normalizeString(data.Patronymic, `${root}.Patronymic`),
      OtherInfo: normalizeString(data.OtherInfo, `${root}.OtherInfo`),
    }), root);
  }
}
