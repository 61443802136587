import { injectable } from 'inversify';
import { IndividualSpecialSigns } from '~/types/individual/specialSigns';
import { IPlaceholderDataService } from '../common/reportPlaceholderService.interfaces';

@injectable()
export class IndividualSpecialSignsPlaceholderService implements IPlaceholderDataService<IndividualSpecialSigns> {
  getData(): IndividualSpecialSigns {
    return {
      CriminalNickName: '',
      SpecialSigns: '',
    };
  }
}
