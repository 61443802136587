import { injectable } from 'inversify';
import { DateUtils } from '~/utils/date';
import { ICheckIsFirstDateService } from './checkIsFirstDateService.interface';

@injectable()
export class CheckIsFirstDateService implements ICheckIsFirstDateService {
  checkIsFirstDate<T, K extends keyof T>(
    array: Array<T>,
    innName: K, 
    fullName: K,
    firstDate: K,
    secondDate?: K
  ) { 
    const previousInns: string[] = [];
    const previousNames: string[] = [];

    return array.reverse().map(
      (successor) => {
        let condition: boolean;

        const dateStart = successor[firstDate];
        const dateEnd = secondDate ? successor[secondDate] : undefined;
        const inn = successor[innName];
        const name = successor[fullName];
      
        const dateStartWithCheckIsString = typeof dateStart === 'string' ? dateStart : undefined;
        const dateEndWithCheckIsString = typeof dateEnd === 'string' ? dateEnd : undefined;

        const isDateOne = DateUtils.checkIfDateIsOne(dateStartWithCheckIsString, dateEndWithCheckIsString); 

        if (typeof inn === 'string') {
          const checkInn = !previousInns.includes(inn);
          condition = isDateOne && checkInn;
        } else if (typeof name === 'string') {
          const checkName = !previousNames.includes(name);
          condition = isDateOne && checkName;
        } else condition = false;

        if (typeof inn === 'string') previousInns.push(inn);

        if (typeof name === 'string') previousNames.push(name);

        if (condition) return { ...successor, isFirstDate: true };

        return { ...successor, isFirstDate: false };
      }
    ).reverse();
  }
}
