import { IContainerBinder } from './containerAccessor.interface';

import { bindCommonPlaceholderServices } from './container.commonPlaceholderServices';
import { bindIndividualPlaceholderServices } from './container.individualPlaceholderServices';
import { bindLegalEntitiesPlaceholderServices } from './container.legalEntitiesPlaceholderServices';
import { bindVehiclesPlaceholderServices } from './container.vehiclesPlaceholderServices';
import { bindDataBlobDataWrapperPlaceholderServices } from './container.dataBlobDataWrapperPlaceholderServices';
import { bindPhoneNumberPlaceholderService } from './container.phoneNumberPlaceholderService';

export function bindPlaceholderServices(bind: IContainerBinder) {
  bindLegalEntitiesPlaceholderServices(bind);
  bindCommonPlaceholderServices(bind);
  bindIndividualPlaceholderServices(bind);
  bindVehiclesPlaceholderServices(bind);
  bindPhoneNumberPlaceholderService(bind);
  bindDataBlobDataWrapperPlaceholderServices(bind);
}
