import { injectable, inject } from 'inversify';
import { AxiosInstance } from 'axios';
import { GenericDataBlob } from '@/types/blobs/generic-data-blob';
import { appSettings } from '@/settings';
import { IAPIAsyncService } from '@/services/api-async-service.interface';
import { IApiEndpointServiceKey } from '~/endpoints/services/apiEndpointService.interface';
import { IApiEndpointAuthServiceKey } from '~/endpoints/services/apiEndpointAuthService.interface';

@injectable()
export class APIAsyncService implements IAPIAsyncService {
  private apiEndpoint: AxiosInstance;

  constructor(@inject(IApiEndpointAuthServiceKey as symbol) apiEndpoint: AxiosInstance) {
    this.apiEndpoint = apiEndpoint;
  }

  async loadDataFromAPIAsync(): Promise<GenericDataBlob> {
    try {
      this.logSettings();
      return await this.loadDataFromAPIInternalAsync();
    } catch (ex) {
      console.error('loadDataFromAPIAsync: error', ex);
      throw ex;
    }
  }

  private async loadDataFromAPIInternalAsync() {
    if (appSettings.dataId != undefined)
      return await this.loadDataFromAPIByUidAsync(appSettings.dataId);
  
    if (appSettings.taskId != undefined)
      return await this.loadDataFromAPIByTaskIdAsync(appSettings.taskId);

    console.error('One of the query parameters is missing. dataId or taskId');

    throw new Error('Invalid method call');
  }
  
  private async loadDataFromAPIByUidAsync(uid: string): Promise<GenericDataBlob> {
    const { data: report } = await this.apiEndpoint.get(`/api/pdf?uid=${uid}`);

    return report;
  }

  private async loadDataFromAPIByTaskIdAsync(taskId: string): Promise<GenericDataBlob> {
    const { data: response } = await this.apiEndpoint.get(`/api/v2/async-pdf/tasks/${taskId}/json`);

    return JSON.parse(response.json);
  }

  private logSettings() {
    const dataEndpointInternalURL = <string>appSettings.dataEndpointInternalURL;
    const dataEndpointURL = <string>appSettings.dataEndpointURL;
    const token = <string>appSettings.token;
    const uid = <string>appSettings.dataId;
    const taskId = <string>appSettings.taskId;
    const runtimeConf = useRuntimeConfig();
 
    const endpointURL = process.server
      ? dataEndpointInternalURL
      : dataEndpointURL;
 
    console.log('loadDataFromAPIAsync: appSettings', {
      uid,
      taskId,
      endpointURL,
      token,
      dataEndpointInternalURL,
      dataEndpointURL
    });
 
    console.log('loadDataFromAPIAsync: runtimeConf', runtimeConf);
  }
}
