import { injectable } from 'inversify';
import { ReportPossibleBirthdays } from '~/types/common/reportPossibleBirthdays';
import { IPlaceholderDataService } from './reportPlaceholderService.interfaces';

@injectable()
export class ReportPossibleBirthdaysPlaceholderService implements IPlaceholderDataService<ReportPossibleBirthdays> {
  getData(): ReportPossibleBirthdays {
    return {
      Birthday: '',
      Credibility: 0,
      CredibilityStr: '',
    };
  }
}
