import { injectable, inject } from "inversify";
import { DataBlobReportOriginalInput } from "~/types/blobs/data-blob-report-original-input";
import { DataBlobReportOriginalInputDTO } from "~/types/dto/common/dataBlobReportOriginalInputDTO";
import { IMetaDataBlobReportOriginalInputAdapterService } from "./metaDataBlobReportOriginalInputAdapterService.interfaces";
import { DataBlobReportOriginalInputReportType } from "~/types/blobs/data-blob-report-original-input-report-type";
import { IDataBlobReportOriginalInputFileInfoAdapterServiceKey, IDataBlobReportOriginalInputFileInfoAdapterService } from "./dataBlobReportOriginalInputFileInfoAdapterService.interfaces";

@injectable()
export class MetaDataBlobReportOriginalInputAdapterService implements IMetaDataBlobReportOriginalInputAdapterService {
  constructor(
    @inject(IDataBlobReportOriginalInputFileInfoAdapterServiceKey as symbol) private dataBlobReportOriginalInputFileInfoAdapterService: IDataBlobReportOriginalInputFileInfoAdapterService
  ) {}
  
  getData(initData: DataBlobReportOriginalInput): DataBlobReportOriginalInputDTO {
    return {
      Birthday: initData.Birthday ?? '',
      CarNumber: initData.CarNumber ?? '',
      Email: initData.Email ?? '',
      Fio: initData.Fio ?? '',
      Inn: initData.Inn ?? '',
      InputReportType: initData.InputReportType ?? DataBlobReportOriginalInputReportType.unknown,
      Ogrn: initData.Ogrn ?? '',
      Passport: initData.Passport ?? '',
      PhoneNumber: initData.PhoneNumber ?? '',
      PhotoId: initData.PhotoId ?? '',
      PhotoInfo: this.dataBlobReportOriginalInputFileInfoAdapterService.getData(initData.PhotoInfo),
      SocialId: initData.SocialId ?? '',
    }
  }
}