import { injectable } from 'inversify';
import {
  IIndividualFamilyMaritalStatusConfigService
} from '~/modules/individual/family/individualFamilyList/services/individualFamilyConfigService.interfaces';
import { FAMILY_MARITAL_STATUS_FIELD_LIST_OPTIONS } from '~/modules/individual/family/individualFamilyList/configuration/constants';
import { Config } from '~/types/field-list/config';

@injectable()
export class IndividualFamilyMaritalStatusConfigService implements IIndividualFamilyMaritalStatusConfigService {
  getConfig(maritalStatus: string): Config {
    return {
      ...FAMILY_MARITAL_STATUS_FIELD_LIST_OPTIONS,
      fields: [
        {
          key: 'maritalStatus', 
          label: 'Семейное положение',
          value: maritalStatus
        },
      ]
    };
  }
}
