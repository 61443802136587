import { injectable } from 'inversify';
import { IReportSocialNetworkSchoolAdapterService } from '~/services/adapters/common/reportAdapterService.interfeces';
import { ReportSocialNetworkSchool } from '~/types/common/reportSocialNetworkSchool';
import { ReportSocialNetworkSchoolDTO } from '~/types/dto/common/reportSocialNetworkSchoolDTO';
import { StringUtils } from '~/utils/stringUtils';
import { DateUtils } from '~/utils/date';

@injectable()
export class ReportSocialNetworkSchoolAdapterService implements IReportSocialNetworkSchoolAdapterService {
  getData(initData: ReportSocialNetworkSchool[]): ReportSocialNetworkSchoolDTO[] {
    return (initData ?? []).map((item) => ({
      Type: item.Type,
      SocialNetworkId: StringUtils.quoteReplacement(item.SocialNetworkId),
      LastName: StringUtils.quoteReplacement(item.LastName),
      FirstName: StringUtils.quoteReplacement(item.FirstName),
      Birthday: DateUtils.formatDate(item.Birthday),
      City: StringUtils.quoteReplacement(item.City),
      SchoolName: StringUtils.quoteReplacement(item.SchoolName),
      StartDate: DateUtils.formatDate(item.StartDate),
      DiplomaDate: DateUtils.formatDate(item.DiplomaDate)
    }));
  }
}
