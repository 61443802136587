import { injectable, inject } from 'inversify';
import { IIndividualCommonInfoAdapterService } from '~/services/adapters/individual/individualAdapterService.interfaces';
import {
  IReportMainEntityFileAdapterService,
  IReportMainEntityFileAdapterServiceKey
} from '~/services/adapters/common/reportAdapterService.interfeces';
import { IndividualCommonInfo } from '~/types/individual/commonInfo';
import { IndividualCommonInfoDTO } from '~/types/dto/individual/commonInfoDTO';
import { StringUtils } from '~/utils/stringUtils';
import { DateUtils } from '~/utils/date';

@injectable()
export class IndividualCommonInfoAdapterService implements IIndividualCommonInfoAdapterService {
  constructor(
    @inject(IReportMainEntityFileAdapterServiceKey as symbol) private reportMainEntityFileAdapterService: IReportMainEntityFileAdapterService
  ) {}
  getData(initData: IndividualCommonInfo): IndividualCommonInfoDTO {
    return {
      LastUpdate: initData.LastUpdate ?? '',
      FullName: StringUtils.quoteReplacement(initData.FullName ?? ''),
      Birthday: initData.Birthday ?? '',
      DeathDate: DateUtils.formatDate(initData.DeathDate),
      BirthPlace: initData.BirthPlace,
      Nationality: StringUtils.quoteReplacement(initData.Nationality ?? ''),
      Note: StringUtils.quoteReplacement(initData.Note ?? ''),
      Citizenship: StringUtils.quoteReplacement(initData.Citizenship ?? ''),
      ReportMainPhoto: this.reportMainEntityFileAdapterService.getData(initData.MainPhoto),
      Gender: initData.Gender,
      MaritalStatus: initData.MaritalStatus,
    };
  }
}
