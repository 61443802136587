import { injectable, inject } from 'inversify';
import { DateUtils } from '~/utils/date';
import { StringUtils } from '~/utils/stringUtils';
import {

} from '~/services/adapters/individual/individualAdapterService.interfaces';
import {
  IReportMainEntityFileAdapterService,
  IReportMainEntityFileAdapterServiceKey,
  IReportSocialNetworkAboutInfoAdapterService,
  IReportSocialNetworkAboutInfoAdapterServiceKey,
  IReportSocialNetworkAdapterService,
  IReportSocialNetworkLifePositionAdapterService,
  IReportSocialNetworkLifePositionAdapterServiceKey,
  IReportSocialNetworkOtherPersonAdapterService,
  IReportSocialNetworkOtherPersonAdapterServiceKey,
  IReportUrlAdapterService,
  IReportUrlAdapterServiceKey
} from '~/services/adapters/common/reportAdapterService.interfeces';
import {
  IArrayAdapterService,
  IReportArrayAdapterServiceKey
} from '~/services/adapters/common/reportArrayAdapterService.interfaces';
import { ReportSocialNetwork } from '~/types/common/reportSocialNetwork';
import { ReportSocialNetworkDTO } from '~/types/dto/common/reportSocialNetworkDTO';
import {
  IPlaceholderDataService,
  IReportMainEntityFilePlaceholderServiceKey
} from '~/services/placeholder/common/reportPlaceholderService.interfaces';
import { ReportMainEntityFile } from '~/types/common/reportMainEntityFile';

@injectable()
export class ReportSocialNetworkAdapterService implements IReportSocialNetworkAdapterService {
  constructor(
    @inject(IReportUrlAdapterServiceKey as symbol) private reportUrlAdapterService: IReportUrlAdapterService,
    @inject(IReportMainEntityFileAdapterServiceKey as symbol) private reportMainEntityFileAdapterService: IReportMainEntityFileAdapterService,
    @inject(IReportArrayAdapterServiceKey as symbol) private reportArrayAdapterService: IArrayAdapterService,
    @inject(IReportMainEntityFilePlaceholderServiceKey as symbol) private reportMainEntityFilePlaceholderService: IPlaceholderDataService<ReportMainEntityFile>,

    @inject(IReportSocialNetworkOtherPersonAdapterServiceKey as symbol) private reportSocialNetworkOtherPersonAdapterService: IReportSocialNetworkOtherPersonAdapterService,
    @inject(IReportSocialNetworkAboutInfoAdapterServiceKey as symbol) private reportSocialNetworkAboutInfoAdapterService: IReportSocialNetworkAboutInfoAdapterService,
    @inject(IReportSocialNetworkLifePositionAdapterServiceKey as symbol) private reportSocialNetworkLifePositionAdapterService: IReportSocialNetworkLifePositionAdapterService
  ) {}
  getData(initData: ReportSocialNetwork[]): ReportSocialNetworkDTO[] {
    const { mapData } = this.reportArrayAdapterService;
    return (initData ?? []).map((item) => {
      const birthday = item.Birthday ?? '';
      const formattedBirthday = DateUtils.formatDate(birthday);
      const finalBirthday = DateUtils.isValidDate(formattedBirthday) ? formattedBirthday : '';

      return {
        Type: item.Type,
        SocialId: StringUtils.quoteReplacement(item.SocialId),
        FullName: StringUtils.quoteReplacement(item.FullName),
        Birthday: finalBirthday,
        IsVerified: item.IsVerified,
        IsPrivateAccount: item.IsPrivateAccount,
        LastDevice: StringUtils.quoteReplacement(item.LastDevice),
        Status: StringUtils.quoteReplacement(item.Status),
        Languages: StringUtils.quoteReplacement(item.Languages),
        Subscribers: this.reportSocialNetworkOtherPersonAdapterService.getData(item.Subscribers),
        Friends: this.reportSocialNetworkOtherPersonAdapterService.getData(item.Friends),
        WhoLikes: this.reportSocialNetworkOtherPersonAdapterService.getData(item.WhoLikes),
        WhoComments: this.reportSocialNetworkOtherPersonAdapterService.getData(item.WhoComments),
        WhoReposts: this.reportSocialNetworkOtherPersonAdapterService.getData(item.WhoReposts),
        WhoMentions: this.reportSocialNetworkOtherPersonAdapterService.getData(item.WhoMentions),
        WhoIsLikedByAccountOwner: this.reportSocialNetworkOtherPersonAdapterService.getData(item.WhoIsLikedByAccountOwner),
        WhoIsRepostedByAccountOwner: this.reportSocialNetworkOtherPersonAdapterService.getData(item.WhoIsRepostedByAccountOwner),
        WhoIsCommentedByAccountOwner: this.reportSocialNetworkOtherPersonAdapterService.getData(item.WhoIsCommentedByAccountOwner),
        WhoIsMentionedByAccountOwner: this.reportSocialNetworkOtherPersonAdapterService.getData(item.WhoIsMentionedByAccountOwner),
        Photos: mapData(item.Photos, (item) => this.reportMainEntityFileAdapterService.getData(item)),
        Avatar: this.reportMainEntityFileAdapterService.getData(item.Avatar ?? this.reportMainEntityFilePlaceholderService.getData()),
        SocialNetworkAboutInfos: this.reportSocialNetworkAboutInfoAdapterService.getData(item.SocialNetworkAboutInfos),
        SocialNetworkLifePosition: this.reportSocialNetworkLifePositionAdapterService.getData(item.SocialNetworkLifePosition),
        SocialGroups: mapData(item.SocialGroups, (item) => this.reportUrlAdapterService.getData(item)),
        VideoUrls: mapData(item.VideoUrls, (item) => this.reportUrlAdapterService.getData(item)),
        AudioUrls: mapData(item.AudioUrls, (item) => this.reportUrlAdapterService.getData(item))
      };
    });
  }
}
