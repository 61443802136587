import { InjectionKey } from 'vue';
import { IAdapterService } from '~/services/adapters/adapterService.interfaces';

import { IndividualDataBlobData } from '~/types/blobs/individual-data-blob-data';
import { IndividualDataBlobDataDTO } from '~/types/dto/blobs/individualDataBlobDataDTO';
import { IndividualCommonInfoDTO } from '~/types/dto/individual/commonInfoDTO';
import { IndividualCommonInfo } from '~/types/individual/commonInfo';
import { IndividualGroup } from '~/types/individual/group';
import { IndividualGroupDTO } from '~/types/dto/individual/groupDTO';
import { IndividualUserListDTO } from '~/types/dto/individual/userListDTO';
import { IndividualUserList } from '~/types/individual/userList';
import { IndividualMilitaryRecordDTO } from '~/types/dto/individual/militaryRecordDTO';
import { IndividualMilitaryRecord } from '~/types/individual/militaryRecord';
import { IndividualPhoneDTO } from '~/types/dto/individual/phoneDTO';
import { IndividualPhone } from '~/types/individual/phone';
import { IndividualSkypeDTO } from '~/types/dto/individual/skypeDTO';
import { IndividualSkype } from '~/types/individual/skype';
import { IndividualDriverLicense } from '~/types/individual/driverLicense';
import { IndividualDriverLicenseDTO } from '~/types/dto/individual/driverLicenseDTO';
import { IndividualLegalEntitiesParticipationRestriction } from '~/types/individual/legalEntitiesParticipationRestriction';
import { IndividualJobRecommendation } from '~/types/individual/jobRecommendation';
import { IndividualJobRecommendationDTO } from '~/types/dto/individual/jobRecommendationDTO';
import { IndividualBusinessTrip } from '~/types/individual/businessTrip';
import { IndividualBusinessTripDTO } from '~/types/dto/individual/businessTripDTO';
import { IndividualHolidayDTO } from '~/types/dto/individual/holidayDTO';
import { IndividualHoliday } from '~/types/individual/holiday';
import { IndividualEnforcementProceeding } from '~/types/individual/enforcementProceeding';
import { IndividualEnforcementProceedingDTO } from '~/types/dto/individual/enforcementProceedingDTO';
import { IndividualAviaPerson } from '~/types/individual/aviaPerson';
import { IndividualAviasDTO } from '~/types/dto/individual/aviasDTO';
import { IndividualCommonNegativeInfo } from '~/types/individual/commonNegativeInfo';
import { IndividualCommonNegativeInfoDTO } from '~/types/dto/individual/commonNegativeInfoDTO';
import { IndividualSpecialSignsDTO } from '~/types/dto/individual/individualSpecialSignsDTO';
import { IndividualSpecialSigns } from '~/types/individual/specialSigns';
import { IndividualBankruptcyDTO } from '~/types/dto/individual/bankruptcyDTO';
import { IndividualBankruptcy } from '~/types/individual/bankruptcy';
import { IndividualFamilyRelation } from '~/types/individual/familyRelation';
import { IndividualFamilyListDTO } from '~/types/dto/individual/familyListDTO';
import { IndividualStageBankruptcyDTO } from '~/types/dto/individual/stageBankruptcyDTO';
import { IndividualStageBankruptcy } from '~/types/individual/stageBankruptcy';
import { IndividualMessageAboutBankruptcyDTO } from '~/types/dto/individual/messageAboutBankruptcyDTO';
import { IndividualMessageAboutBankruptcy } from '~/types/individual/messageAboutBankruptcy';
import { IndividualCriminalPenaltiesDTO } from '~/types/dto/individual/criminalPenaltiesDTO';
import { IndividualCriminalPenalties } from '~/types/individual/criminalPenalties';
import { IndividualFinancialScoringDTO } from '~/types/dto/individual/financialScoringDTO';
import { IndividualFinancialScoring } from '~/types/individual/financialScoring';
import { IndividualTrustScoringDTO } from '~/types/dto/individual/trustScoringDTO';
import { IndividualTrustScoring } from '~/types/individual/trustScoring';
import { IndividualWantedMvdDTO } from '~/types/dto/individual/wantedMvdDTO';
import { IndividualWantedMvd } from '~/types/individual/wantedMvd';
import { IndividualWantedFsinDTO } from '~/types/dto/individual/individualWantedFsinDTO';
import { IndividualWantedFsin } from '~/types/individual/wantedFsin';
import { IndividualWantedFssp } from '~/types/individual/wantedFssp';
import { IndividualWantedFsspDTO } from '~/types/dto/individual/individualWantedFsspDTO';
import { IndividualFullNameChangeDTO } from '~/types/dto/individual/fullNameChangeDTO';
import { IndividualFullNameChange } from '~/types/individual/fullNameChange';
import { IndividualTypeDTO } from '~/types/dto/individual/typesDTO';
import { IndividualType } from '~/types/individual/type';
import { IndividualTerroristsAndExtremistsRecordDTO } from '~/types/dto/individual/terroristsAndExtremistsRecordDTO';
import { IndividualTerroristsAndExtremistsRecord } from '~/types/individual/terroristsAndExtremistsRecord';
import { IndividualLegalEntitiesParticipationRestrictionDTO } from '~/types/dto/individual/legalEntitiesParticipationRestrictionDTO';
import { IndividualAccrualDTO } from '~/types/dto/individual/accrualDTO';
import { IndividualAccrual } from '~/types/individual/accrual';
import { IndividualWantedDTO } from '~/types/dto/individual/wantedDTO';
import { IndividualWanted } from '~/types/individual/wanted';
import { IndividualMovementBusDTO } from '~/types/dto/individual/movementBusDTO';
import { IndividualMovementBus } from '~/types/individual/movementBus';
import { IndividualMaritalStatusHistoryDTO } from '~/types/dto/individual/maritalStatusHistoryDTO';
import { IndividualMaritalStatusHistory } from '~/types/individual/maritalStatusHistory';
import { IndividualMarkersDTO } from '~/types/dto/individual/markersDTO';
import { IndividualMarkers } from '~/types/individual/markers';

export type IIndividualDataBlobDataService = IAdapterService<IndividualDataBlobData, IndividualDataBlobDataDTO>;
export type IIndividualCommonInfoAdapterService = IAdapterService<IndividualCommonInfo, IndividualCommonInfoDTO>
export type IIndividualGroupAdapterService = IAdapterService<IndividualGroup[], IndividualGroupDTO[]>
export type IIndividualUserListAdapterService = IAdapterService<IndividualUserList[], IndividualUserListDTO[]>
export type IIndividualMilitaryRecordAdapterService = IAdapterService<IndividualMilitaryRecord[], IndividualMilitaryRecordDTO[]>
export type IIndividualPhoneAdapterService = IAdapterService<IndividualPhone[], IndividualPhoneDTO[]>
export type IIndividualSkypeAdapterService = IAdapterService<IndividualSkype[], IndividualSkypeDTO[]>
export type IIndividualDriverLicenseAdapterService = IAdapterService<IndividualDriverLicense[], IndividualDriverLicenseDTO[]>
export type IIndividualLegalEntitiesParticipationRestrictionsAdapterService = IAdapterService<IndividualLegalEntitiesParticipationRestriction[], IndividualLegalEntitiesParticipationRestrictionDTO[]>
export type IIndividualJobRecommendationAdapterService = IAdapterService<IndividualJobRecommendation[], IndividualJobRecommendationDTO[]>
export type IIndividualBusinessTripAdapterService = IAdapterService<IndividualBusinessTrip[], IndividualBusinessTripDTO[]>
export type IIndividualHolidayAdapterService = IAdapterService<IndividualHoliday[], IndividualHolidayDTO[]>
export type IIndividualEnforcementProceedingsAdapterService = IAdapterService<IndividualEnforcementProceeding[], IndividualEnforcementProceedingDTO[]>
export type IIndividualAviasAdapterService = IAdapterService<IndividualAviaPerson[], IndividualAviasDTO[]>
export type IIndividualCommonNegativeInfoAdapterService = IAdapterService<IndividualCommonNegativeInfo, IndividualCommonNegativeInfoDTO>
export type IIndividualSpecialSignsAdapterService = IAdapterService<IndividualSpecialSigns, IndividualSpecialSignsDTO>
export type IIndividualBankruptcyAdapterService = IAdapterService<IndividualBankruptcy[], IndividualBankruptcyDTO[]>
export type IIndividualFamilyListAdapterService = IAdapterService<IndividualFamilyRelation[], IndividualFamilyListDTO[]>
export type IIndividualStageBankruptcyAdapterService = IAdapterService<IndividualStageBankruptcy[], IndividualStageBankruptcyDTO[]>
export type IIndividualMessageAboutBankruptcyAdapterService = IAdapterService<IndividualMessageAboutBankruptcy[], IndividualMessageAboutBankruptcyDTO[]>
export type IIndividualCriminalPenaltiesAdapterService = IAdapterService<IndividualCriminalPenalties[], IndividualCriminalPenaltiesDTO[]>
export type IIndividualFinancialScoringAdapterService = IAdapterService<IndividualFinancialScoring[], IndividualFinancialScoringDTO[]>
export type IIndividualTrustScoringAdapterService = IAdapterService<IndividualTrustScoring[], IndividualTrustScoringDTO[]>
export type IIndividualWantedMvdAdapterService = IAdapterService<IndividualWantedMvd[], IndividualWantedMvdDTO[]>
export type IIndividualWantedFsinAdapterService = IAdapterService<IndividualWantedFsin[], IndividualWantedFsinDTO[]>
export type IIndividualWantedFsspAdapterService = IAdapterService<IndividualWantedFssp[], IndividualWantedFsspDTO[]>
export type IIndividualFullNameChangeAdapterService = IAdapterService<IndividualFullNameChange[], IndividualFullNameChangeDTO[]>
export type IIndividualTypeAdapterService = IAdapterService<IndividualType[], IndividualTypeDTO[]>
export type IIndividualTerroristsAndExtremistsRecordAdapterService = IAdapterService<IndividualTerroristsAndExtremistsRecord[], IndividualTerroristsAndExtremistsRecordDTO[]>
export type IIndividualAccrualAdapterService = IAdapterService<IndividualAccrual[], IndividualAccrualDTO[]>
export type IIndividualWantedAdapterService = IAdapterService<IndividualWanted[], IndividualWantedDTO[]>
export type IIndividualMovementBusAdapterService = IAdapterService<IndividualMovementBus[], IndividualMovementBusDTO[]>
export type IIndividualMaritalStatusHistoryAdapterService = IAdapterService<IndividualMaritalStatusHistory[], IndividualMaritalStatusHistoryDTO[]>
export type IIndividualMarkersAdapterService = IAdapterService<IndividualMarkers, IndividualMarkersDTO>

export const IIndividualMarkersAdapterServiceKey: InjectionKey<IIndividualMarkersAdapterService> = Symbol('IIndividualMarkersAdapterService');
export const IIndividualMaritalStatusHistoryAdapterServiceKey: InjectionKey<IIndividualMaritalStatusHistoryAdapterService> = Symbol('IIndividualMaritalStatusHistoryAdapterService');
export const IIndividualMovementBusAdapterServiceKey: InjectionKey<IIndividualMovementBusAdapterService> = Symbol('IIndividualMovementBusAdapterService');
export const IIndividualWantedAdapterServiceKey: InjectionKey<IIndividualWantedAdapterService> = Symbol('IIndividualWantedAdapterService');
export const IIndividualAccrualAdapterServiceKey: InjectionKey<IIndividualAccrualAdapterService> = Symbol('IIndividualAccrualAdapterService');
export const IIndividualTerroristsAndExtremistsRecordAdapterServiceKey: InjectionKey<IIndividualTerroristsAndExtremistsRecordAdapterService> = Symbol('IIndividualTerroristsAndExtremistsRecordAdapterService');
export const IIndividualTypeAdapterServiceKey: InjectionKey<IIndividualTypeAdapterService> = Symbol('IIndividualTypeAdapterService');
export const IIndividualFullNameChangeAdapterServiceKey: InjectionKey<IIndividualFullNameChangeAdapterService> = Symbol('IIndividualFullNameChangeAdapterService');
export const IIndividualWantedFsspAdapterServiceKey: InjectionKey<IIndividualWantedFsspAdapterService> = Symbol('IIndividualWantedFsspAdapterService');
export const IIndividualWantedFsinAdapterServiceKey: InjectionKey<IIndividualWantedFsinAdapterService> = Symbol('IIndividualWantedFsinAdapterService');
export const IIndividualWantedMvdAdapterServiceKey: InjectionKey<IIndividualWantedMvdAdapterService> = Symbol('IIndividualWantedMvdAdapterService');
export const IIndividualTrustScoringAdapterServiceKey: InjectionKey<IIndividualTrustScoringAdapterService> = Symbol('IIndividualTrustScoringAdapterService');
export const IIndividualFinancialScoringAdapterServiceKey: InjectionKey<IIndividualFinancialScoringAdapterService> = Symbol('IIndividualFinancialScoringAdapterService');
export const IIndividualCriminalPenaltiesAdapterServiceKey: InjectionKey<IIndividualCriminalPenaltiesAdapterService> = Symbol('IIndividualCriminalPenaltiesAdapterService');
export const IIndividualMessageAboutBankruptcyAdapterServiceKey: InjectionKey<IIndividualMessageAboutBankruptcyAdapterService> = Symbol('IIndividualMessageAboutBankruptcyAdapterService');
export const IIndividualStageBankruptcyAdapterServiceKey: InjectionKey<IIndividualStageBankruptcyAdapterService> = Symbol('IIndividualStageBankruptcyAdapterService');
export const IIndividualBankruptcyAdapterServiceKey: InjectionKey<IIndividualBankruptcyAdapterService> = Symbol('IIndividualBankruptcyAdapterService');
export const IIndividualSpecialSignsAdapterServiceKey: InjectionKey<IIndividualSpecialSignsAdapterService> = Symbol('IIndividualSpecialSignsAdapterService');
export const IIndividualCommonNegativeInfoAdapterServiceKey: InjectionKey<IIndividualCommonNegativeInfoAdapterService> = Symbol('IIndividualCommonNegativeInfoAdapterService');
export const IIndividualHolidayAdapterServiceKey: InjectionKey<IIndividualHolidayAdapterService> = Symbol('IIndividualHolidayAdapterService');
export const IIndividualBusinessTripAdapterServiceKey: InjectionKey<IIndividualBusinessTripAdapterService> = Symbol('IIndividualBusinessTripAdapterService');
export const IIndividualJobRecommendationAdapterServiceKey: InjectionKey<IIndividualJobRecommendationAdapterService> = Symbol('IIndividualJobRecommendationAdapterService');
export const IIndividualDriverLicenseAdapterServiceKey: InjectionKey<IIndividualDriverLicenseAdapterService> = Symbol('IIndividualDriverLicenseAdapterService');
export const IIndividualSkypeAdapterServiceKey: InjectionKey<IIndividualSkypeAdapterService> = Symbol('IIndividualSkypeAdapterService');
export const IIndividualPhoneAdapterServiceKey: InjectionKey<IIndividualPhoneAdapterService> = Symbol('IIndividualPhoneAdapterService');
export const IIndividualMilitaryRecordAdapterServiceKey: InjectionKey<IIndividualMilitaryRecordAdapterService> = Symbol('IIndividualMilitaryRecordAdapterService');
export const IIndividualUserListAdapterServiceKey: InjectionKey<IIndividualUserListAdapterService> = Symbol('IIndividualUserListAdapterService');
export const IIndividualDataBlobDataServiceKey: InjectionKey<IIndividualDataBlobDataService> = Symbol('IIndividualDataBlobDataService');
export const IIndividualCommonInfoAdapterServiceKey: InjectionKey<IIndividualCommonInfoAdapterService> = Symbol('IIndividualCommonInfoAdapterService');
export const IIndividualGroupAdapterServiceKey: InjectionKey<IIndividualGroupAdapterService> = Symbol('IIndividualGroupAdapterService');
export const IIndividualLegalEntitiesParticipationRestrictionsAdapterServiceKey: InjectionKey<IIndividualLegalEntitiesParticipationRestrictionsAdapterService> = Symbol('IIndividualLegalEntitiesParticipationRestrictionsAdapterService');
export const IIndividualEnforcementProceedingsAdapterServiceKey: InjectionKey<IIndividualEnforcementProceedingsAdapterService> = Symbol('IIndividualEnforcementProceedingsAdapterService');
export const IIndividualAviasAdapterServiceKey: InjectionKey<IIndividualAviasAdapterService> = Symbol('IIndividualAviasAdapterService');
export const IIndividualFamilyListAdapterServiceKey: InjectionKey<IIndividualFamilyListAdapterService> = Symbol('IIndividualFamilyListAdapterService');
