import { injectable } from 'inversify';

import { LegalEntitiesReportAnalysisOfAbsoluteLiquidityIndicator } from '~/types/legal-entities/reportAnalysisOfAbsoluteLiquidityIndicator';
import { IPlaceholderDataService } from '../common/reportPlaceholderService.interfaces';

@injectable()
export class LegalEntitiesReportAnalysisOfAbsoluteLiquidityIndicatorPlaceholderDataService implements IPlaceholderDataService<LegalEntitiesReportAnalysisOfAbsoluteLiquidityIndicator> {
  getData(): LegalEntitiesReportAnalysisOfAbsoluteLiquidityIndicator {
    return {
      AssetsValue: 0,
      LiabilitiesValue: 0,
      ActualRatio: false
    };
  }
}
