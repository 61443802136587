import { injectable, inject } from 'inversify';
import { LegalEntitiesLesseeSummary } from '~/types/legal-entities/lesseeSummary';
import { IPrimitiveTypesNormalizationServiceKey, IPrimitiveTypesNormalizationService } from '../common/primitiveTypesNormalizationService.interfaces';
import { INormalizationService } from '../common/reportNormalizationService.interfaces';

@injectable()
export class LegalEntitiesLesseeSummaryNormalizationService implements INormalizationService<LegalEntitiesLesseeSummary> {
  constructor(
    @inject(IPrimitiveTypesNormalizationServiceKey as symbol) private primitiveTypesNormalizationService: IPrimitiveTypesNormalizationService
  ) {}

  normalize(data: any, root: string): LegalEntitiesLesseeSummary | undefined {
    const { normalizeNumber, normalizeObject } = this.primitiveTypesNormalizationService;

    return normalizeObject(data, (data) => ({
      ArchiveCount: normalizeNumber(data.ArchiveCount, `${root}.ArchiveCount`),
      ActualCount: normalizeNumber(data.ActualCount, `${root}.ActualCount`),
    }), root);
  }
}
