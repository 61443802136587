import { injectable } from 'inversify';
import { MergeService } from '~/services/merge/merge-service';
import { LegalEntitiesCompanyStatusDTO } from '~/types/dto/legalEntities/legalEntitiesCompanyStatusDTO';

@injectable()
export class LegalEntitiesCompanyStatusMergeService extends MergeService<LegalEntitiesCompanyStatusDTO> {  
  protected canMerge(leftItem: LegalEntitiesCompanyStatusDTO, rightItem: LegalEntitiesCompanyStatusDTO): boolean {
    return leftItem.StatusOnPeriodDate === rightItem.StatusOnPeriodDate;
  }

  protected mergeFields(leftItem: LegalEntitiesCompanyStatusDTO, rightItem: LegalEntitiesCompanyStatusDTO): LegalEntitiesCompanyStatusDTO {
    const leftMinDate = leftItem.StartDate || leftItem.EndDate;
    const leftMaxDate = leftItem.EndDate || leftItem.StartDate;
    const rightMinDate = rightItem.StartDate || rightItem.EndDate;
    const rightMaxDate = rightItem.EndDate || rightItem.StartDate;

    return {
      StatusOnPeriodDate: leftItem.StatusOnPeriodDate,
      StartDate: +(new Date(leftMinDate)) > +(new Date(rightMinDate)) ? rightMinDate : leftMinDate,
      EndDate: +(new Date(leftMaxDate)) > +(new Date(rightMaxDate)) ? leftMaxDate : rightMaxDate
    };
  }
}
