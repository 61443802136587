import { injectable, inject } from 'inversify';
import { LegalEntitiesEgrRecord } from '~/types/legal-entities/egrRecord';
import { IPrimitiveTypesNormalizationServiceKey, IPrimitiveTypesNormalizationService } from '../common/primitiveTypesNormalizationService.interfaces';
import { INormalizationService } from '../common/reportNormalizationService.interfaces';

@injectable()
export class LegalEntitiesEgrRecordNormalizationService implements INormalizationService<LegalEntitiesEgrRecord> {
  constructor(
    @inject(IPrimitiveTypesNormalizationServiceKey as symbol) private primitiveTypesNormalizationService: IPrimitiveTypesNormalizationService,
  ) {}

  normalize(data: any, root: string): LegalEntitiesEgrRecord | undefined {
    const {
      normalizeString,
      normalizeOptionalString,
      normalizeOptionalBoolean,
      normalizeObject
    } = this.primitiveTypesNormalizationService;

    return normalizeObject(data, (data) => ({
      Grn: normalizeString(data.Grn, `${root}.Grn`),
      Date: normalizeOptionalString(data.Date, `${root}.Date`),
      Name: normalizeString(data.Name, `${root}.Name`),
      IsInvalid: normalizeOptionalBoolean(data.IsInvalid, `${root}.IsInvalid`),
      InvalidSince: normalizeOptionalString(data.IsInvalid, `${root}.IsInvalid`),
      RegName: normalizeString(data.RegName, `${root}.RegName`),
      RegCode: normalizeString(data.RegCode, `${root}.RegCode`),
      DocumentName: normalizeString(data.DocumentName, `${root}.DocumentName`),
      DocumentDate: normalizeOptionalString(data.DocumentDate, `${root}.DocumentDate`),
      CertificateSerialNumber: normalizeString(data.CertificateSerialNumber, `${root}.CertificateSerialNumber`),
      CertificateDate: normalizeOptionalString(data.CertificateDate, `${root}.CertificateDate`),
    }), root);
  }
}
