import { injectable, inject } from 'inversify';
import { IPrimitiveTypesNormalizationServiceKey, IPrimitiveTypesNormalizationService } from './primitiveTypesNormalizationService.interfaces';
import {
  INormalizationService, IReportConnectionRelatedChainNormalizationServiceKey,
} from './reportNormalizationService.interfaces';
import { ReportConnectionRelated } from '~/types/common/reportConnectionRelated';
import { ReportConnectionRelatedChain } from '~/types/common/reportCompanyRelatedChain';

@injectable()
export class ReportConnectionRelatedNormalizationService implements INormalizationService<ReportConnectionRelated> {
  constructor(
    @inject(IReportConnectionRelatedChainNormalizationServiceKey as symbol) private reportConnectionRelatedChainNormalizationService: INormalizationService<ReportConnectionRelatedChain>,

    @inject(IPrimitiveTypesNormalizationServiceKey as symbol) private primitiveTypesNormalizationService: IPrimitiveTypesNormalizationService,
  ) {}

  normalize(data: any, root: string): ReportConnectionRelated | undefined {
    const {
      normalizeArray,
      normalizeObject
    } = this.primitiveTypesNormalizationService;

    return normalizeObject(data, (data) => ({
      Chains: normalizeArray(
        data.Chains,
        (item, field) => this.reportConnectionRelatedChainNormalizationService.normalize(item, field),
        `${root}.Chains`
      ),
    }), root);
  }
}
