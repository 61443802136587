import { injectable } from 'inversify';

import { LegalEntitiesRelatedCompany } from '~/types/legal-entities/relatedCompany';
import { IPlaceholderDataService } from '../common/reportPlaceholderService.interfaces';

@injectable()
export class LegalEntitiesRelatedCompanyPlaceholderDataService implements IPlaceholderDataService<LegalEntitiesRelatedCompany> {
  getData(): LegalEntitiesRelatedCompany {
    return {
      Fio: '',
      Role: '',
      Reason: '',
      Percent: 0,
      CompanyName: '',
      CompanyStatus: [],
      CompanyStatusLevel: 0,
      CompanyAddress: '',
      CompanyMainActivityText: '',
      CompanyInn: '',
      CompanyOgrn: '',
    };
  }
}
