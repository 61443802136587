import { Direction } from '~/types/field-list/direction';

export const FIELD_LIST_SOCIAL_EDUCATION_OPTIONS = {
  fieldsWidth: '100%',
  fieldsDirection: 'divider-row' as Direction,
  fieldsValueWidth: '350px',
  rowGap: '8px',
  isBeginningDivider: true,
};

export const FIELD_LIST_SOCIAL_OPTIONS = {
  fieldsWidth: '100%',
  fieldsDirection: 'divider-row' as Direction,
  fieldsValueWidth: '468px',
  rowGap: '8px',
  isBeginningDivider: true,
};
