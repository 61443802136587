import { IDataBlobReportOriginalInputFileInfoNormalizationServiceKey, IDataBlobReportOriginalInputNormalizationServiceKey, IDataBlobInputDataListNormalizationServiceKey } from '~/services/normalization/blob/dataBlobNormalizationService.interfaces';
import { DataBlobReportOriginalInputFileInfoNormalizationService } from '~/services/normalization/blob/dataBlobReportOriginalInputFileInfoNormalizationService';
import { DataBlobReportOriginalInputNormalizationService } from '~/services/normalization/blob/dataBlobReportOriginalInputNormalizationService';
import { DataBlobInputDataListNormalizationService } from '~/services/normalization/blob/dataBlobInputDataListNormalizationService';
import { DataBlobDataWrapperNormalizationService } from '~/services/normalization/blob/dataBlobDataWrapperNormalizationService';
import { IDataBlobDataWrapperNormalizationServiceKey } from '~/services/normalization/blob/dataBlobDataWrapperNormalizationService.interfaces';
import { IContainerBinder } from './containerAccessor.interface';

export function bindDataBlobDataWrapperNormalizationServices(bind: IContainerBinder) {
  bind(IDataBlobReportOriginalInputFileInfoNormalizationServiceKey).to(DataBlobReportOriginalInputFileInfoNormalizationService);
  bind(IDataBlobReportOriginalInputNormalizationServiceKey).to(DataBlobReportOriginalInputNormalizationService);
  bind(IDataBlobInputDataListNormalizationServiceKey).to(DataBlobInputDataListNormalizationService);
  bind(IDataBlobDataWrapperNormalizationServiceKey).to(DataBlobDataWrapperNormalizationService);
}
