/* eslint-disable class-methods-use-this, max-len */

import { injectable, inject } from 'inversify';
import { LegalEntitiesFinancialAnalysisExtendedDTO } from '~/types/dto/legalEntities/financialAnalysisExtended';
import {
  ILegalEntitiesFinancialAnalysisExtendedAdapterService,
  ILegalEntitiesReportAnalysisOfAbsoluteLiquidityAdapterService,
  ILegalEntitiesReportAnalysisOfAbsoluteLiquidityAdapterServiceKey,
  ILegalEntitiesReportFinancialStabilityAdapterService,
  ILegalEntitiesReportFinancialStabilityAdapterServiceKey,
  ILegalEntitiesReportIndicatorsOfCashFlowAdapterService,
  ILegalEntitiesReportIndicatorsOfCashFlowAdapterServiceKey,
  ILegalEntitiesReportIndicatorsOfProfitabilityAdapterService,
  ILegalEntitiesReportIndicatorsOfProfitabilityAdapterServiceKey,
  ILegalEntitiesReportLiquidityAndSolvencyAdapterService,
  ILegalEntitiesReportLiquidityAndSolvencyAdapterServiceKey
} from '~/services/adapters/legalEntities/legalEntitiesAdapterService.interfaces';
import { LegalEntitiesFinancialAnalysisExtended } from '~/types/legal-entities/financialAnalysisExtended';

@injectable()
export class LegalEntitiesFinancialAnalysisExtendedAdapterService implements ILegalEntitiesFinancialAnalysisExtendedAdapterService {
  constructor(
    @inject(ILegalEntitiesReportLiquidityAndSolvencyAdapterServiceKey as symbol) private legalEntitiesReportLiquidityAndSolvencyAdapterService: ILegalEntitiesReportLiquidityAndSolvencyAdapterService,
    @inject(ILegalEntitiesReportAnalysisOfAbsoluteLiquidityAdapterServiceKey as symbol) private legalEntitiesReportAnalysisOfAbsoluteLiquidityAdapterService: ILegalEntitiesReportAnalysisOfAbsoluteLiquidityAdapterService,
    @inject(ILegalEntitiesReportFinancialStabilityAdapterServiceKey as symbol) private legalEntitiesReportFinancialStabilityAdapterService: ILegalEntitiesReportFinancialStabilityAdapterService,
    @inject(ILegalEntitiesReportIndicatorsOfProfitabilityAdapterServiceKey as symbol) private legalEntitiesReportIndicatorsOfProfitabilityAdapterService: ILegalEntitiesReportIndicatorsOfProfitabilityAdapterService,
    @inject(ILegalEntitiesReportIndicatorsOfCashFlowAdapterServiceKey as symbol) private legalEntitiesReportIndicatorsOfCashFlowAdapterService: ILegalEntitiesReportIndicatorsOfCashFlowAdapterService,
  ) {
  }
  getData(initData: LegalEntitiesFinancialAnalysisExtended): LegalEntitiesFinancialAnalysisExtendedDTO {
    return {
      IsExists: initData.IsExists,
      TwoYearsAgo: initData.TwoYearsAgo,
      LastYear: initData.LastYear ?? 0,
      CurrentYear: initData.CurrentYear ?? 0,
      LiquidityAndSolvency: this.legalEntitiesReportLiquidityAndSolvencyAdapterService.getData(initData.LiquidityAndSolvency),
      AnalysisOfAbsoluteLiquidity: this.legalEntitiesReportAnalysisOfAbsoluteLiquidityAdapterService.getData(initData.AnalysisOfAbsoluteLiquidity),
      FinancialStability: this.legalEntitiesReportFinancialStabilityAdapterService.getData(initData.FinancialStability),
      IndicatorsOfProfitability: this.legalEntitiesReportIndicatorsOfProfitabilityAdapterService.getData(initData.IndicatorsOfProfitability),
      IndicatorsOfCashFlow: this.legalEntitiesReportIndicatorsOfCashFlowAdapterService.getData(initData.IndicatorsOfCashFlow),
    };
  }
}
