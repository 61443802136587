import { injectable, inject } from 'inversify';
import { ReportAddress } from '~/types/common/address';
import { IPrimitiveTypesNormalizationServiceKey, IPrimitiveTypesNormalizationService } from './primitiveTypesNormalizationService.interfaces';
import { INormalizationService } from './reportNormalizationService.interfaces';

@injectable()
export class ReportAddressNormalizationService implements INormalizationService<ReportAddress> {
  constructor(
    @inject(IPrimitiveTypesNormalizationServiceKey as symbol) private primitiveTypesNormalizationService: IPrimitiveTypesNormalizationService,
  ) {}

  normalize(data: any, root: string): ReportAddress | undefined {
    const {
      normalizeObject,
      normalizeString,
      normalizeNumber,
      normalizeOptionalString
    } = this.primitiveTypesNormalizationService;

    return normalizeObject(data, (data) => ({
      AddressType: normalizeNumber(data.AddressType, `${root}.AddressType`),
      Address: normalizeString(data.Address, `${root}.Address`),
      FlatType: normalizeOptionalString(data.FlatType, `${root}.FlatType`),
      FlatArea: normalizeOptionalString(data.FlatArea, `${root}.FlatArea`),
      SquareMeterPrice: normalizeOptionalString(data.SquareMeterPrice, `${root}.SquareMeterPrice`),
      FlatPrice: normalizeOptionalString(data.FlatPrice, `${root}.FlatPrice`),
      PostalBox: normalizeOptionalString(data.PostalBox, `${root}.PostalBox`),
      FiasId: normalizeOptionalString(data.FiasId, `${root}.FiasId`),
      FiasLevel: normalizeOptionalString(data.FiasLevel, `${root}.FiasLevel`),
      FiasActualityState: normalizeOptionalString(data.FiasActualityState, `${root}.FiasActualityState`),
      KladrId: normalizeOptionalString(data.KladrId, `${root}.KladrId`),
      CapitalMarker: normalizeOptionalString(data.CapitalMarker, `${root}.CapitalMarker`),
      TaxOffice: normalizeOptionalString(data.TaxOffice, `${root}.TaxOffice`),
      TaxOfficeLegal: normalizeOptionalString(data.TaxOfficeLegal, `${root}.TaxOfficeLegal`),
      TimeZone: normalizeOptionalString(data.TimeZone, `${root}.TimeZone`),
      GeoLat: normalizeOptionalString(data.GeoLat, `${root}.GeoLat`),
      GeoLon: normalizeOptionalString(data.GeoLon, `${root}.GeoLon`),
      CoordinateAccuracyCode: normalizeNumber(data.CoordinateAccuracyCode, `${root}.CoordinateAccuracyCode`),
      CodeSuitabilityMailing: normalizeNumber(data.CodeSuitabilityMailing, `${root}.CodeSuitabilityMailing`),
      AvailabilityHouseInFias: normalizeOptionalString(data.AvailabilityHouseInFias, `${root}.AvailabilityHouseInFias`),
      VerificationCode: normalizeNumber(data.VerificationCode, `${root}.VerificationCode`),
      UnparsedParts: normalizeOptionalString(data.UnparsedParts, `${root}.UnparsedParts`),
      NameStationMetros: normalizeOptionalString(data.NameStationMetros, `${root}.NameStationMetros`),
    }), root);
  }
}
