import { injectable, inject } from 'inversify';
import { IndividualMaritalStatusHistory } from '~/types/individual/maritalStatusHistory';
import { IPrimitiveTypesNormalizationServiceKey, IPrimitiveTypesNormalizationService } from '../common/primitiveTypesNormalizationService.interfaces';
import { INormalizationService } from '../common/reportNormalizationService.interfaces';

@injectable()
export class IndividualMaritalStatusHistoryNormalizationService implements INormalizationService<IndividualMaritalStatusHistory> {
  constructor(
    @inject(IPrimitiveTypesNormalizationServiceKey as symbol) private primitiveTypesNormalizationService: IPrimitiveTypesNormalizationService,
  ) {}

  normalize(data: any, root: string): IndividualMaritalStatusHistory | undefined {
    const { normalizeObject, normalizeString, normalizeOptionalString } = this.primitiveTypesNormalizationService;

    return normalizeObject(data, (data) => ({
      MaritalStatus: normalizeString(data.MaritalStatus, `${root}.MaritalStatus`),
      ChangeDate: normalizeOptionalString(data.ChangeDate, `${root}.ChangeDate`),
    }), root);
  }
}
