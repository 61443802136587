import { injectable, inject } from 'inversify';
import { ReportBoundedPersonPhone } from '~/types/common/reportBoundedPersonPhone';
import { ReportTag } from '~/types/common/reportTag';
import { IPrimitiveTypesNormalizationServiceKey, IPrimitiveTypesNormalizationService } from './primitiveTypesNormalizationService.interfaces';
import { INormalizationService, IReportEmailNormalizationServiceKey, IReportTagNormalizationServiceKey } from './reportNormalizationService.interfaces';
import { ReportEmailNormalizationService } from './reportEmailNormalizationService';

@injectable()
export class ReportBoundedPersonPhoneNormalizationService implements INormalizationService<ReportBoundedPersonPhone> {
  constructor(
    @inject(IReportTagNormalizationServiceKey as symbol) private reportTagNormalizationService: INormalizationService<ReportTag>,

    @inject(IPrimitiveTypesNormalizationServiceKey as symbol) private primitiveTypesNormalizationService: IPrimitiveTypesNormalizationService,

    @inject(IReportEmailNormalizationServiceKey as symbol) private reportEmailNormalizationService: ReportEmailNormalizationService
  ) {}

  normalize(data: any, root: string): ReportBoundedPersonPhone | undefined {
    const {
      normalizeObject,
      normalizeString,
      normalizeNumber,
      normalizeArray,
      normalizeOptionalString,
      normalizeFieldObject,
    } = this.primitiveTypesNormalizationService;

    const normalizeFieldData = normalizeFieldObject(data);

    return normalizeObject(normalizeFieldData, (data) => ({
      Value: normalizeString(data.Value, `${root}.Value`),
      Source: normalizeNumber(data.Source, `${root}.Source`),
      SourceStr: normalizeString(data.SourceStr, `${root}.SourceStr`),
      Tag: normalizeOptionalString(data.Tag, `${root}.Tag`),
      Tags: normalizeArray(data.Tags, (item, field) => this.reportTagNormalizationService.normalize(item, field), `${root}.Tags`),
      FullName: normalizeString(data.FullName, `${root}.FullName`),
      Emails: normalizeArray(data.Emails, (item, field) => this.reportEmailNormalizationService.normalize(item, field), `${root}.Emails`),
      RelatedAddress: normalizeOptionalString(data.RelatedAddress, `${root}.RelatedAddress`),
      RelatedBirth: normalizeOptionalString(data.RelatedAddress, `${root}.RelatedBirth`),
      OriginalRequestData: normalizeOptionalString(data.OriginalRequestData, `${root}.OriginalRequestData`),
    }), root);
  }
}
