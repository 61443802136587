import { ReportVehicleRelationDTO } from '~/types/dto/common/reportVehicleRelationDTO';
import { Config } from '~/types/field-list/config';
import { IOwnerInfoConfigService } from './ownerInfoConfigService.interfaces';
import { injectable } from 'inversify';

@injectable()
export class OwnerInfoConfigService implements IOwnerInfoConfigService {
  getConfig(vehicleOwner: ReportVehicleRelationDTO): Config {
    return {
      fields: [
        {
          key: 'name',
          label: 'ФИО',
          value: vehicleOwner.Name,
          width: '539px',
        },
        {
          key: 'phoneNumber',
          label: 'Абонентский номер',
          value: vehicleOwner.PhoneNumber
        }
      ]
    }
  }
}
