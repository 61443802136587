/* eslint-disable class-methods-use-this, max-len */

import { injectable } from 'inversify';
import { StringUtils } from '~/utils/stringUtils';
import { LegalEntitiesReorganisationMember } from '~/types/legal-entities/reorganisationMember';
import { LegalEntitiesReorganisationMemberDTO } from '~/types/dto/legalEntities/reorganisationMemberDTO';
import {
  ILegalEntitiesReorganisationMemberAdapterService
} from '~/services/adapters/legalEntities/legalEntitiesAdapterService.interfaces';

@injectable()
export class LegalEntitiesReorganisationMemberAdapterService implements ILegalEntitiesReorganisationMemberAdapterService {
  getData(initData: LegalEntitiesReorganisationMember[]): LegalEntitiesReorganisationMemberDTO[] {
    return initData.map((item) => ({
      Ogrn: StringUtils.quoteReplacement(item.Ogrn ?? ''),
      OrganisationName: StringUtils.quoteReplacement(item.OrganisationName ?? ''),
      Date: item.Date ?? '',
      Inn: StringUtils.quoteReplacement(item.Inn ?? ''),
      Status: StringUtils.quoteReplacement(item.Status ?? ''),
    }));
  }
}
