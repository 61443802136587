import { injectable } from 'inversify';

import { LegalEntitiesActivitySummary } from '~/types/legal-entities/activitySummary';
import { IPlaceholderDataService } from '../common/reportPlaceholderService.interfaces';

@injectable()
export class LegalEntitiesActivitySummaryPlaceholderDataService implements IPlaceholderDataService<LegalEntitiesActivitySummary> {
  getData(): LegalEntitiesActivitySummary {
    return {
      ArchiveCount: 0,
      ActualCount: 0
    };
  }
}
