import { injectable } from 'inversify';
import { LegalEntitiesPredecessor } from '~/types/legal-entities/predecessor';
import { IPlaceholderDataService } from '../common/reportPlaceholderService.interfaces';

@injectable()
export class LegalEntitiesPredecessorPlaceholderDataService implements IPlaceholderDataService<LegalEntitiesPredecessor> {
  getData(): LegalEntitiesPredecessor {
    return {
      OrganisationName: '',
      Inn: '',
      Ogrn: '',
      Date: '',
      Status: '',
    };
  }
}
