import { injectable } from 'inversify';
import { IndividualJobRecommendation } from '~/types/individual/jobRecommendation';
import { IPlaceholderDataService } from '../common/reportPlaceholderService.interfaces';

@injectable()
export class IndividualJobRecommendationPlaceholderService implements IPlaceholderDataService<IndividualJobRecommendation> {
  getData(): IndividualJobRecommendation {
    return {
      RecommenderFullName: '',
      RecommenderCompanyName: '',
      RecommenderCompanyOpf: '',
      RecommenderCompanyInn: '',
      RecommenderPosition: '',
      RecommenderPhone: '',
    };
  }
}
