import { injectable } from 'inversify';
import { findLastIndex } from 'lodash-es';
import { DateUtils } from '~/utils/date';
import { LegalEntitiesCompanyStatedCapitalDTO } from '~/types/dto/legalEntities/companyStatedCapitalDTO';
import {
  ILegalEntitiesAuthorizedCapitalLastIndexService
} from '~/modules/legalEntities/changeHistory/legalEntitiesAuthorizedCapital/services/legalEntitiesAuthorizedCapitalLastIndexService.interfaces';

@injectable()
export class LegalEntitiesAuthorizedCapitalLastIndexService implements ILegalEntitiesAuthorizedCapitalLastIndexService {
  getLastIndex(initData: Array<LegalEntitiesCompanyStatedCapitalDTO>): number {
    return findLastIndex(
      initData,
      (capital) => DateUtils.checkIfDateIsOne(capital.From, capital.To)
    );
  }
}
