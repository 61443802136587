import { InjectionKey } from 'vue';
import { ITableItemService } from '~/modules/table/services/tableItemService.interface';
import { LegalEntitiesReportFinancialIndicatorWithDynamicDTO } from '~/types/dto/legalEntities/reportFinancialIndicatorWithDynamicDTO';

export type ILegalEntitiesCashFlowPerformanceTableItemConfig = ITableItemService<LegalEntitiesReportFinancialIndicatorWithDynamicDTO>

export type IOperatingCashFlowTableItemConfig = ITableItemService<LegalEntitiesReportFinancialIndicatorWithDynamicDTO>
export type ICashFlowAvailableForDebtServiceTableItemConfig = ITableItemService<LegalEntitiesReportFinancialIndicatorWithDynamicDTO>
export type IInvestmentCashFlowTableItemConfig = ITableItemService<LegalEntitiesReportFinancialIndicatorWithDynamicDTO>
export type IFinancialCashFlowTableItemConfig = ITableItemService<LegalEntitiesReportFinancialIndicatorWithDynamicDTO>
export type IDebtServiceCoverageRatioTableItemConfig = ITableItemService<LegalEntitiesReportFinancialIndicatorWithDynamicDTO>

export const IOperatingCashFlowTableItemConfigKey: InjectionKey<IOperatingCashFlowTableItemConfig> = Symbol('IOperatingCashFlowTableItemConfig');
export const ICashFlowAvailableForDebtServiceTableItemConfigKey: InjectionKey<ICashFlowAvailableForDebtServiceTableItemConfig> = Symbol('ICashFlowAvailableForDebtServiceTableItemConfig');
export const IInvestmentCashFlowTableItemConfigKey: InjectionKey<IInvestmentCashFlowTableItemConfig> = Symbol('IInvestmentCashFlowTableItemConfig');
export const IFinancialCashFlowTableItemConfigKey: InjectionKey<IFinancialCashFlowTableItemConfig> = Symbol('IFinancialCashFlowTableItemConfig');
export const IDebtServiceCoverageRatioTableItemConfigKey: InjectionKey<IDebtServiceCoverageRatioTableItemConfig> = Symbol('IDebtServiceCoverageRatioTableItemConfig');
export const ILegalEntitiesCashFlowPerformanceTableItemConfigKey: InjectionKey<ILegalEntitiesCashFlowPerformanceTableItemConfig> = Symbol('ILegalEntitiesCashFlowPerformanceTableItemConfig');
