import { IContainerBinder } from '~/configuration/inversify/containerAccessor.interface';
import { IReportPassportMergeServiceKey } from '../../common/services/reportPassportMergeService.interfaces';
import { ReportPassportMergeService } from '../../common/services/reportPassportMergeService';
import { IReportPassportConfigServiceKey } from '../../common/services/reportPassportConfigService.interfaces';
import { ReportPassportConfigService } from '../../common/services/reportPassportConfigService';

export function bindPassportsService(bind: IContainerBinder) {
  bind(IReportPassportMergeServiceKey).to(ReportPassportMergeService);
  bind(IReportPassportConfigServiceKey).to(ReportPassportConfigService);
}
