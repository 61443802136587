import { injectable, inject } from 'inversify';
import { PhoneNumberRelatedPhonePersonCommon } from '~/types/phone-number/relatedPhonePersonCommon';
import { IPrimitiveTypesNormalizationServiceKey, IPrimitiveTypesNormalizationService } from '../common/primitiveTypesNormalizationService.interfaces';
import { INormalizationService } from '../common/reportNormalizationService.interfaces';

@injectable()
export class PhoneNumberRelatedPhonePersonCommonNormalizationService implements INormalizationService<PhoneNumberRelatedPhonePersonCommon> {
  constructor(
    @inject(IPrimitiveTypesNormalizationServiceKey as symbol) private primitiveTypesNormalizationService: IPrimitiveTypesNormalizationService,
  ) {}

  normalize(data: any, root: string): PhoneNumberRelatedPhonePersonCommon | undefined {
    const { normalizeObject, normalizeString, normalizeOptionalString } = this.primitiveTypesNormalizationService;

    return normalizeObject(data, (data) => ({
      Birthday: normalizeOptionalString(data.Birthday, `${root}.Birthday`),
      BirthPlace: normalizeOptionalString(data.BirthPlace, `${root}.BirthPlace`),
      Citizenship: normalizeOptionalString(data.Citizenship, `${root}.Citizenship`),
      Nationality: normalizeString(data.Nationality, `${root}.Nationality`),
    }), root);
  }
}
