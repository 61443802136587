import { injectable } from 'inversify';
import { get } from 'lodash-es';
import { IRatioOfSecurityOfInterestToPaymentItemConfigService } from '~/modules/legalEntities/financialAnalytics/legalEntitiesFinancialStability/services/legalEntitiesFinancialStabilityTableItemConfigService.interfaces';
import { LegalEntitiesReportFinancialIndicatorWithConclusionsDTO } from '~/types/dto/legalEntities/reportFinancialIndicatorWithConclusionsDTO';
import { TableItem } from '~/modules/table/types/tableItem';
import { NumberUtils } from '~/utils/number';
import { FINANCIAL_STABILITY_TITLES } from '~/modules/legalEntities/financialAnalytics/common/configuration/constants/financialStabilityTitles';

@injectable()
export class RatioOfSecurityOfInterestToPaymentItemConfigService implements IRatioOfSecurityOfInterestToPaymentItemConfigService {
  getItem(initData: LegalEntitiesReportFinancialIndicatorWithConclusionsDTO): TableItem {
    const choiceConclusions = initData.TwoYearsAgo == 0 && initData.LastYear == 0 && initData.CurrentYear === 0
      ? ['Отсутвуют обязательства по уплате процентов'] : initData.Conclusions;

    return {
      TwoYearsAgo: { value: NumberUtils.formatNumberDigits(initData.TwoYearsAgo, 2, '—'), color: this.getColor(initData.TwoYearsAgo) },
      LastYear: { value: NumberUtils.formatNumberDigits(initData.LastYear, 2, '—'), color: this.getColor(initData.LastYear) },
      CurrentYear: { value: NumberUtils.formatNumberDigits(initData.CurrentYear, 2, '—'), color: this.getColor(initData.CurrentYear) },
      Conclusions: get(choiceConclusions, 0, '—'),
      indicator: {
        title: FINANCIAL_STABILITY_TITLES.RatioOfSecurityOfInterestToPayment,
      },
      referenceValue: '>= 4',
    };
  }

  private getColor(value: number): string {
    if (value >= 4) return 'black';
    if (value <= 4 && value > 2.5) return 'yellow';
    return 'red';
  }
}
