import { injectable, inject } from 'inversify';
import {
  ILegalEntitiesObjectReportDataBuhFormAdapterService,
  ILegalEntitiesReportDataBuhFormDetailAdapterService,
  ILegalEntitiesReportDataBuhFormDetailAdapterServiceKey
} from '~/services/adapters/legalEntities/legalEntitiesAdapterService.interfaces';
import { LegalEntitiesReportDataBuhForm } from '~/types/legal-entities/reportDataBuhForm';
import { LegalEntitiesReportDataBuhFormDTO } from '~/types/dto/legalEntities/reportDataBuhFormDTO';

@injectable()
export class LegalEntitiesObjectReportDataBuhFormAdapterService implements ILegalEntitiesObjectReportDataBuhFormAdapterService {
  constructor(
    @inject(ILegalEntitiesReportDataBuhFormDetailAdapterServiceKey as symbol) private legalEntitiesReportDataBuhFormDetailAdapterService: ILegalEntitiesReportDataBuhFormDetailAdapterService,
  ) {}
  getData(initData: LegalEntitiesReportDataBuhForm): LegalEntitiesReportDataBuhFormDTO {
    return {
      Year: initData.Year,
      OrganizationType: initData.OrganizationType,
      Form1: this.legalEntitiesReportDataBuhFormDetailAdapterService.getData(initData.Form1),
      Form2: this.legalEntitiesReportDataBuhFormDetailAdapterService.getData(initData.Form2),
    };
  }
}
