import { injectable } from 'inversify';

import { ReportSocialNetworkAboutInfo } from '~/types/common/reportSocialNetworkAboutInfo';
import { ReportSocialNetworkAboutInfoDTO } from '~/types/dto/common/reportSocialNetworkAboutInfoDTO';
import { StringUtils } from '~/utils/stringUtils';
import { IReportSocialNetworkAboutInfoAdapterService } from './reportAdapterService.interfeces';

@injectable()
export class ReportSocialNetworkAboutInfoAdapterService implements IReportSocialNetworkAboutInfoAdapterService {
  getData(initData: ReportSocialNetworkAboutInfo): ReportSocialNetworkAboutInfoDTO {
    return {
      Activity: StringUtils.quoteReplacement(initData.Activity),
      Interests: StringUtils.quoteReplacement(initData.Interests),
      FavoriteMusic: StringUtils.quoteReplacement(initData.FavoriteMusic),
      FavoriteFilms: StringUtils.quoteReplacement(initData.FavoriteFilms),
      FavoriteTvShows: StringUtils.quoteReplacement(initData.FavoriteTvShows),
      FavoriteBooks: StringUtils.quoteReplacement(initData.FavoriteBooks),
      FavoriteGames: StringUtils.quoteReplacement(initData.FavoriteGames),
      FavoriteQuotes: StringUtils.quoteReplacement(initData.FavoriteQuotes),
      About: StringUtils.quoteReplacement(initData.About)
    };
  }
}
