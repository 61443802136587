import { injectable, inject } from 'inversify';

import { LegalEntitiesTaxMode } from '~/types/legal-entities/taxMode';
import { IPrimitiveTypesNormalizationServiceKey, IPrimitiveTypesNormalizationService } from '../common/primitiveTypesNormalizationService.interfaces';
import { INormalizationService } from '../common/reportNormalizationService.interfaces';

@injectable()
export class LegalEntitiesTaxModeNormalizationService implements INormalizationService<LegalEntitiesTaxMode> {
  constructor(
    @inject(IPrimitiveTypesNormalizationServiceKey as symbol) private primitiveTypesNormalizationService: IPrimitiveTypesNormalizationService
  ) {}

  normalize(data: any, root: string): LegalEntitiesTaxMode | undefined {
    const { normalizeOptionalString, normalizeObject } = this.primitiveTypesNormalizationService;

    return normalizeObject(data, (data) => ({
      Name: normalizeOptionalString(data.Name, `${root}.Name`)
    }), root);
  }
}
