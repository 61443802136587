import { injectable, inject } from 'inversify';
import { ReportMessageBoardRecord } from '~/types/common/reportMessageBoardRecord';
import { ReportUrl } from '~/types/common/reportUrl';
import { IPlaceholderDataService, IReportUrlPlaceholderServiceKey } from './reportPlaceholderService.interfaces';

@injectable()
export class ReportMessageBoardRecordPlaceholderService implements IPlaceholderDataService<ReportMessageBoardRecord> {
  constructor(
    @inject(IReportUrlPlaceholderServiceKey as symbol) private reportUrlPlaceholderService: IPlaceholderDataService<ReportUrl>
  ) {}

  getData(): ReportMessageBoardRecord {
    return {
      MessageSourceType: '',
      MessageBoardDataType: '',
      Category: '',
      MessageTitle: '',
      MessageText: '',
      RegistrationDate: '',
      Region: '',
      Price: 0,
      Currency: '',
      Year: 0,
      CarNumber: '',
      Brand: '',
      Model: '',
      Vin: '',
      Contactface: '',
      Link: this.reportUrlPlaceholderService.getData()
    };
  }
}
