import ISplittable from '~/types/splittables/splittable';
import SplittableRowContext from '~/models/splittables/splittableRowContext';
import { ISplittableContext } from '~/types/splittables/splittableContext';

export default class SplittableRow<T = unknown> implements ISplittable<T> {
  constructor(private splittables: ISplittable<T>[]) { }

  async createContextAsync(): Promise<ISplittableContext<T>> {
    return new SplittableRowContext(await Promise.all(this.splittables.map(s => s.createContextAsync())));
  }
}
