import { injectable, inject } from 'inversify';
import { LegalEntitiesReportFinancialIndicatorBase } from '~/types/legal-entities/reportFinancialIndicatorBase';

import { LegalEntitiesReportFinancialIndicatorWithConclusions } from '~/types/legal-entities/reportFinancialIndicatorWithConclusions';
import { IPlaceholderDataService } from '../common/reportPlaceholderService.interfaces';
import { ILegalEntitiesReportFinancialIndicatorBasePlaceholderDataServiceKey } from './legalEntitiesPlaceholderDataService.interfaces';

@injectable()
export class LegalEntitiesReportFinancialIndicatorWithConclusionsPlaceholderDataService implements IPlaceholderDataService<LegalEntitiesReportFinancialIndicatorWithConclusions> {
  constructor(
    @inject(ILegalEntitiesReportFinancialIndicatorBasePlaceholderDataServiceKey as symbol) private reportFinancialIndicatorBasePlaceholderDataService: IPlaceholderDataService<LegalEntitiesReportFinancialIndicatorBase>,
  ) {}

  getData(): LegalEntitiesReportFinancialIndicatorWithConclusions {
    return {
      ...this.reportFinancialIndicatorBasePlaceholderDataService.getData(),
      Conclusions: [],
    };
  }
}
