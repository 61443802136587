import { InjectionKey } from 'vue';
import { ITableItemService } from '~/modules/table/services/tableItemService.interface';
import { LegalEntitiesReportFinancialIndicatorWithConclusionsDTO } from '~/types/dto/legalEntities/reportFinancialIndicatorWithConclusionsDTO';

export type INetAssetsTableItemConfigService = ITableItemService<LegalEntitiesReportFinancialIndicatorWithConclusionsDTO>;
export type IRatioOfConcentrationOfEquityItemConfigService = ITableItemService<LegalEntitiesReportFinancialIndicatorWithConclusionsDTO>;
export type ILevelOfFinancialLeverageItemConfigService = ITableItemService<LegalEntitiesReportFinancialIndicatorWithConclusionsDTO>;
export type IRatioOfSecurityOfInterestToPaymentItemConfigService = ITableItemService<LegalEntitiesReportFinancialIndicatorWithConclusionsDTO>;
export type IRatioOfTotalDebtToProfitFromSalesItemConfigService = ITableItemService<LegalEntitiesReportFinancialIndicatorWithConclusionsDTO>;

export const INetAssetsTableItemConfigServiceKey: InjectionKey<INetAssetsTableItemConfigService> = Symbol('INetAssetsTableItemConfigService');
export const IRatioOfConcentrationOfEquityItemConfigServiceKey: InjectionKey<IRatioOfConcentrationOfEquityItemConfigService> = Symbol('IRatioOfConcentrationOfEquityItemConfigService');
export const ILevelOfFinancialLeverageItemConfigServiceKey: InjectionKey<ILevelOfFinancialLeverageItemConfigService> = Symbol('ILevelOfFinancialLeverageItemConfigService');
export const IRatioOfSecurityOfInterestToPaymentItemConfigServiceKey: InjectionKey<IRatioOfSecurityOfInterestToPaymentItemConfigService> = Symbol('IRatioOfSecurityOfInterestToPaymentItemConfigService');
export const IRatioOfTotalDebtToProfitFromSalesItemConfigServiceKey: InjectionKey<IRatioOfTotalDebtToProfitFromSalesItemConfigService> = Symbol('IRatioOfTotalDebtToProfitFromSalesItemConfigService');
