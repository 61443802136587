import { injectable } from 'inversify';
import { IReportVehicleOwnershipAdapterService } from '~/services/adapters/common/reportAdapterService.interfeces';
import { ReportVehicleOwnership } from '~/types/common/reportVehicleOwnership';
import { ReportVehicleOwnershipDTO } from '~/types/dto/common/reportVehicleOwnershipDTO';
import { DateUtils } from '~/utils/date';

@injectable()
export class ReportVehicleOwnershipAdapterService implements IReportVehicleOwnershipAdapterService {
  getData(initData: ReportVehicleOwnership[]): ReportVehicleOwnershipDTO[] {
    return (initData ?? []).map((owner) => ({
      RegDate: DateUtils.formatDate(owner.RegDate),
      OriginalRegDate: DateUtils.parseDate(owner.RegDate),
      DeregistrationDate: DateUtils.formatDate(owner.DeregistrationDate),
      OriginalDeregistrationDate: DateUtils.parseDate(owner.DeregistrationDate),
      OwnerType: owner.OwnerType,
      RegDateRaw: owner.RegDateRaw ?? '',
    }));
  }
}
