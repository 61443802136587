import { injectable } from 'inversify';
import { ReportUrl } from '~/types/common/reportUrl';
import { IPlaceholderDataService } from './reportPlaceholderService.interfaces';

@injectable()
export class ReportUrlPlaceholderService implements IPlaceholderDataService<ReportUrl> {
  getData(): ReportUrl {
    return {
      Text: '',
      Value: ''
    };
  }
}
