import { injectable, inject } from 'inversify';

import { LegalEntitiesFinancialAnalysisShort } from '~/types/legal-entities/financialAnalysisShort';
import { LegalEntitiesReportFinancialIndicatorBase } from '~/types/legal-entities/reportFinancialIndicatorBase';
import { LegalEntitiesReportFinancialIndicatorWithConclusions } from '~/types/legal-entities/reportFinancialIndicatorWithConclusions';
import { LegalEntitiesReportFinancialIndicatorWithDynamic } from '~/types/legal-entities/reportFinancialIndicatorWithDynamic';
import { IPlaceholderDataService } from '../common/reportPlaceholderService.interfaces';
import {
  ILegalEntitiesReportFinancialIndicatorBasePlaceholderDataServiceKey,
  ILegalEntitiesReportFinancialIndicatorWithConclusionsPlaceholderDataServiceKey,
  ILegalEntitiesReportFinancialIndicatorWithDynamicPlaceholderDataServiceKey
} from './legalEntitiesPlaceholderDataService.interfaces';

@injectable()
export class LegalEntitiesFinancialAnalysisShortPlaceholderDataService implements IPlaceholderDataService<LegalEntitiesFinancialAnalysisShort> {
  constructor(
    @inject(ILegalEntitiesReportFinancialIndicatorWithDynamicPlaceholderDataServiceKey as symbol) private reportFinancialIndicatorWithDynamicPlaceholderDataService: IPlaceholderDataService<LegalEntitiesReportFinancialIndicatorWithDynamic>,
    @inject(ILegalEntitiesReportFinancialIndicatorWithConclusionsPlaceholderDataServiceKey as symbol) private reportFinancialIndicatorWithConclusionsPlaceholderDataService: IPlaceholderDataService<LegalEntitiesReportFinancialIndicatorWithConclusions>,
    @inject(ILegalEntitiesReportFinancialIndicatorBasePlaceholderDataServiceKey as symbol) private reportFinancialIndicatorBasePlaceholderDataService: IPlaceholderDataService<LegalEntitiesReportFinancialIndicatorBase>
  ) {}

  getData(): LegalEntitiesFinancialAnalysisShort {
    return {
      IsExists: false,
      TwoYearsAgo: 0,
      LastYear: 0,
      CurrentYear: 0,
      Revenues: this.reportFinancialIndicatorWithDynamicPlaceholderDataService.getData(),
      ProfitFromSales: this.reportFinancialIndicatorWithDynamicPlaceholderDataService.getData(),
      Ebit: this.reportFinancialIndicatorWithDynamicPlaceholderDataService.getData(),
      DebtShare: this.reportFinancialIndicatorWithDynamicPlaceholderDataService.getData(),
      DebtEbit: this.reportFinancialIndicatorWithConclusionsPlaceholderDataService.getData(),
      EbitPercent: this.reportFinancialIndicatorWithConclusionsPlaceholderDataService.getData(),
      EbitMargin: this.reportFinancialIndicatorBasePlaceholderDataService.getData(),
      OwnCapital: this.reportFinancialIndicatorBasePlaceholderDataService.getData(),
    };
  }
}
