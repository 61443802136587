import { inject, injectable } from 'inversify';
import { get } from 'lodash-es';
import { IInvestmentCashFlowTableItemConfig } from '~/modules/legalEntities/financialAnalytics/legalEntitiesCashFlowPerformance/services/legalEntitiesCashFlowPerformanceTableItemConfig.interfaces';
import { TableItem } from '~/modules/table/types/tableItem';
import { NumberUtils } from '~/utils/number';
import { LegalEntitiesReportFinancialIndicatorWithDynamicDTO } from '~/types/dto/legalEntities/reportFinancialIndicatorWithDynamicDTO';
import { INDICATORS_OF_CASH_FLOW } from '~/modules/legalEntities/financialAnalytics/common/configuration/constants/indicatorsOfCashFlow';
import {
  IFinancialAnalysisCommonTitlesService,
  IFinancialAnalysisCommonTitlesServiceKey
} from '~/modules/legalEntities/financialAnalytics/common/services/financialAnalysisCommonTitlesService.interfaces';
import {
  IConvertingPrimitiveTableTypes,
  IConvertingPrimitiveTableTypesKey
} from '~/modules/legalEntities/financialAnalytics/common/services/convertingPrimitiveTableTypes.interfaces';

@injectable()
export class InvestmentCashFlowTableItemConfig implements IInvestmentCashFlowTableItemConfig {
  constructor(
    @inject(IFinancialAnalysisCommonTitlesServiceKey as symbol) private financialAnalysisCommonTitlesService: IFinancialAnalysisCommonTitlesService,
    @inject(IConvertingPrimitiveTableTypesKey as symbol) private convertingPrimitiveTableTypes: IConvertingPrimitiveTableTypes
  ) {}
  getItem(initData: LegalEntitiesReportFinancialIndicatorWithDynamicDTO): TableItem {
    const maxValue = NumberUtils.getMaxAbs([initData.LastYear, initData.TwoYearsAgo, initData.CurrentYear]);

    return ({

      TwoYearsAgo: { value: this.convertingPrimitiveTableTypes.currencyConversion(initData.TwoYearsAgo, maxValue), color: 'black' },
      CurrentYear: { value: this.convertingPrimitiveTableTypes.currencyConversion(initData.CurrentYear, maxValue), color: 'black' },
      LastYear: { value: this.convertingPrimitiveTableTypes.currencyConversion(initData.LastYear, maxValue), color: 'black' },
      TwoYearsAgoToLastYear: { value: this.convertingPrimitiveTableTypes.percentageConversion(initData.TwoYearsAgoToLastYear), color: 'black' },
      LastYearToCurrentYear: { value: this.convertingPrimitiveTableTypes.percentageConversion(initData.LastYearToCurrentYear), color: 'black' },
      Conclusions: get(initData.Conclusions, 0, '—'),
      indicator: this.financialAnalysisCommonTitlesService.getTitle(INDICATORS_OF_CASH_FLOW.InvestmentCashFlow, [initData.LastYear, initData.TwoYearsAgo, initData.CurrentYear]),
    });
  }
}
