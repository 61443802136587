import { injectable, inject } from 'inversify';

import { LegalEntitiesCompanyFounder } from '~/types/legal-entities/companyFounder';
import { LegalEntitiesCompanyFounderShare } from '~/types/legal-entities/companyFounderShare';
import { IPlaceholderDataService } from '../common/reportPlaceholderService.interfaces';
import { ILegalEntitiesCompanyFounderSharePlaceholderDataServiceKey } from './legalEntitiesPlaceholderDataService.interfaces';

@injectable()
export class LegalEntitiesCompanyFounderPlaceholderDataService implements IPlaceholderDataService<LegalEntitiesCompanyFounder> {
  constructor(
    @inject(ILegalEntitiesCompanyFounderSharePlaceholderDataServiceKey as symbol) private companyFounderSharePlaceholderDataService:IPlaceholderDataService<LegalEntitiesCompanyFounderShare>
  ) {}

  getData(): LegalEntitiesCompanyFounder {
    return {
      Inn: '',
      Fullname: '',
      Country: '',
      FounderType: '',
      Share: this.companyFounderSharePlaceholderDataService.getData(),
      Date: '',
      FirstDate: '',
      IsInaccuracy: false,
      InaccuracyDate: '',
      Capital: 0,
      Percent: 0
    };
  }
}
