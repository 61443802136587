export module CommonUtils {
  export function isReliable(value: string): boolean {
    return !value.toLowerCase().includes('недостоверный');
  }

  export function getRandomNumber(min: number, max: number): number {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  export function getRandomArrayElement<T>(array: T[]): T {
    const index = getRandomNumber(0, array.length - 1);
    return array[index];
  }

  export function formatName(name: string) {
    const splitName = name.toLowerCase().split(' ');

    return splitName
      .map((name) => {
        const newName = name.split('');

        if (!newName.length) return '';

        newName[0] = newName[0].toUpperCase();

        return newName.join('');
      })
      .join(' ').trim();
  }

}
