import { injectable } from 'inversify';
import { ReportPersonInn } from '~/types/common/reportPersonInn';
import { IPlaceholderDataService } from './reportPlaceholderService.interfaces';

@injectable()
export class ReportPersonInnPlaceholderService implements IPlaceholderDataService<ReportPersonInn> {
  getData(): ReportPersonInn {
    return {
      Value: '',
      IsValid: false,
      IsActual: false,
    };
  }
}
