import { injectable, inject } from 'inversify';
import { GenericDataBlobData } from '~/types/blobs/generic-data-blob-data';
import { ListDataBlobWrapperReportData } from '~/types/blobs/list-data-blob-wrapper';
import { IPrimitiveTypesNormalizationServiceKey, IPrimitiveTypesNormalizationService } from '../common/primitiveTypesNormalizationService.interfaces';
import { IDataBlobDataWrapperNormalizationService } from './dataBlobDataWrapperNormalizationService.interfaces';
import { UIReportType } from '~/types/reportType';

@injectable()
export class DataBlobInputDataListNormalizationService implements IDataBlobDataWrapperNormalizationService<ListDataBlobWrapperReportData<GenericDataBlobData>> {
  constructor(

    @inject(IPrimitiveTypesNormalizationServiceKey as symbol) private primitiveTypesNormalizationService:IPrimitiveTypesNormalizationService,
  ) {}

  normalize(
    type: UIReportType,
    data: any,
    dataNormalize: (type: any, data: any) => GenericDataBlobData | undefined,
  ): ListDataBlobWrapperReportData<GenericDataBlobData> | undefined {
    const { normalizeArray, normalizeObject } = this.primitiveTypesNormalizationService;
    return normalizeObject(data, (data) => ({
      ReportInputDataList: normalizeArray(data.ReportInputDataList, (item) => dataNormalize(type, item), `${type}.ReportInputDataList`)
    }), type);
  }
}
