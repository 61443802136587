import { injectable, inject } from 'inversify';

import { ReportIndicatorsOfCashFlow } from '~/types/blobs/report-indicators-of-cash-flow';
import { LegalEntitiesReportFinancialIndicatorWithDynamic } from '~/types/legal-entities/reportFinancialIndicatorWithDynamic';
import { IPlaceholderDataService } from '~/services/placeholder/common/reportPlaceholderService.interfaces';
import { IPrimitiveTypesNormalizationService, IPrimitiveTypesNormalizationServiceKey } from './primitiveTypesNormalizationService.interfaces';
import { INormalizationService } from './reportNormalizationService.interfaces';
import { ILegalEntitiesReportFinancialIndicatorWithDynamicPlaceholderDataServiceKey } from '~/services/placeholder/legalEntities/legalEntitiesPlaceholderDataService.interfaces';
import { ILegalEntitiesReportFinancialIndicatorWithDynamicNormalizationServiceKey } from '../legalEntities/legalEntitiesNormalizationService.interfaces';

@injectable()
export class ReportIndicatorsOfCashFlowNormalizationService implements INormalizationService<ReportIndicatorsOfCashFlow> {
  constructor(
    @inject(ILegalEntitiesReportFinancialIndicatorWithDynamicNormalizationServiceKey as symbol) private reportFinancialIndicatorWithDynamicNormalizationServiceKey: INormalizationService<LegalEntitiesReportFinancialIndicatorWithDynamic>,

    @inject(ILegalEntitiesReportFinancialIndicatorWithDynamicPlaceholderDataServiceKey as symbol) private reportFinancialIndicatorWithDynamicPlaceholderDataService: IPlaceholderDataService<LegalEntitiesReportFinancialIndicatorWithDynamic>,

    @inject(IPrimitiveTypesNormalizationServiceKey as symbol) private primitiveTypesNormalizationService: IPrimitiveTypesNormalizationService,
  ) {}

  normalize(data: any, root: string): ReportIndicatorsOfCashFlow | undefined {
    const { normalizeObject } = this.primitiveTypesNormalizationService;

    return normalizeObject(data, (data) => ({
      OperatingCashFlow: this.reportFinancialIndicatorWithDynamicNormalizationServiceKey.normalize(data.OperatingCashFlow, `${root}.OperatingCashFlow`) ?? this.reportFinancialIndicatorWithDynamicPlaceholderDataService.getData(),
      CashFlowAvailableForDebtService: this.reportFinancialIndicatorWithDynamicNormalizationServiceKey.normalize(data.CashFlowAvailableForDebtService, `${root}.CashFlowAvailableForDebtService`) ?? this.reportFinancialIndicatorWithDynamicPlaceholderDataService.getData(),
      InvestmentCashFlow: this.reportFinancialIndicatorWithDynamicNormalizationServiceKey.normalize(data.InvestmentCashFlow, `${root}.InvestmentCashFlow`) ?? this.reportFinancialIndicatorWithDynamicPlaceholderDataService.getData(),
      FinancialCashFlow: this.reportFinancialIndicatorWithDynamicNormalizationServiceKey.normalize(data.FinancialCashFlow, `${root}.FinancialCashFlow`) ?? this.reportFinancialIndicatorWithDynamicPlaceholderDataService.getData(),
      DebtServiceCoverageRatio: this.reportFinancialIndicatorWithDynamicNormalizationServiceKey.normalize(data.DebtServiceCoverageRatio, `${root}.DebtServiceCoverageRatio`) ?? this.reportFinancialIndicatorWithDynamicPlaceholderDataService.getData(),
    }), root);
  }
}
