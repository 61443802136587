import { IContainerBinder } from '~/configuration/inversify/containerAccessor.interface';
import {
  IIndividualSpecialSignsConfigServiceKey
} from '~/modules/individual/negativeInformation/IndividualSpecialSigns/services/individualSpecialSignsConfigService.interface';
import {
  IndividualSpecialSignsConfigService
} from '~/modules/individual/negativeInformation/IndividualSpecialSigns/services/individualSpecialSignsConfigService';

export function bindNegativeInformationServices(bind: IContainerBinder) {
  bind(IIndividualSpecialSignsConfigServiceKey).to(IndividualSpecialSignsConfigService);
}
