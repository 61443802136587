import { injectable } from 'inversify';
import { StringUtils } from '~/utils/stringUtils';
import { ReportSocialNetworkLifePosition } from '~/types/common/reportSocialNetworkLifePosition';
import { ReportSocialNetworkLifePositionDTO } from '~/types/dto/common/reportSocialNetworkLifePositionDTO';
import { IReportSocialNetworkLifePositionAdapterService } from './reportAdapterService.interfeces';

@injectable()
export class ReportSocialNetworkLifePositionAdapterService implements IReportSocialNetworkLifePositionAdapterService {
  getData(initData: ReportSocialNetworkLifePosition): ReportSocialNetworkLifePositionDTO {
    return {
      PoliticalPreferences: StringUtils.quoteReplacement(initData.PoliticalPreferences),
      WorldView: StringUtils.quoteReplacement(initData.WorldView),
      MainThingInLife: StringUtils.quoteReplacement(initData.MainThingInLife),
      MainThingInPeople: StringUtils.quoteReplacement(initData.MainThingInPeople),
      SmokingView: StringUtils.quoteReplacement(initData.SmokingView),
      AlcoholView: StringUtils.quoteReplacement(initData.AlcoholView),
      Inspires: StringUtils.quoteReplacement(initData.Inspires)
    };
  }
}
