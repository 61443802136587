import { injectable, inject } from 'inversify';
import { ReportEducation } from '~/types/common/reportEducation';
import { IPrimitiveTypesNormalizationServiceKey, IPrimitiveTypesNormalizationService } from './primitiveTypesNormalizationService.interfaces';
import { INormalizationService } from './reportNormalizationService.interfaces';

@injectable()
export class ReportEducationNormalizationService implements INormalizationService<ReportEducation> {
  constructor(
    @inject(IPrimitiveTypesNormalizationServiceKey as symbol) private primitiveTypesNormalizationService: IPrimitiveTypesNormalizationService,
  ) {}

  normalize(data: any, root: string): ReportEducation | undefined {
    const { normalizeObject, normalizeString, normalizeOptionalString } = this.primitiveTypesNormalizationService;

    return normalizeObject(data, (data) => ({
      EducationType: normalizeString(data.EducationType, `${root}.EducationType`),
      EducationName: normalizeString(data.EducationName, `${root}.EducationName`),
      FacultyName: normalizeOptionalString(data.FacultyName, `${root}.FacultyName`),
      SpecialtyName: normalizeOptionalString(data.SpecialtyName, `${root}.SpecialtyName`),
      EducationForm: normalizeOptionalString(data.EducationForm, `${root}.EducationForm`),
      DiplomaDate: normalizeOptionalString(data.DiplomaDate, `${root}.DiplomaDate`),
      DiplomaSeries: normalizeOptionalString(data.DiplomaSeries, `${root}.DiplomaSeries`),
      DiplomaNumber: normalizeOptionalString(data.DiplomaNumber, `${root}.DiplomaNumber`),
    }), root);
  }
}
