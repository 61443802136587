import { injectable } from 'inversify';
import { ReportSocialNetworkSchoolDTO } from '~/types/dto/common/reportSocialNetworkSchoolDTO';
import { Config } from '~/types/field-list/config';
import { FIELD_LIST_SOCIAL_EDUCATION_OPTIONS } from '../../configuration/constants';
import { IReportSocialNetworkSchoolConfigService } from './reportSocialNetworkConfigService.interfaces';

@injectable()
export class ReportSocialNetworkSchoolConfigService implements IReportSocialNetworkSchoolConfigService {
  getConfig(educationItem: ReportSocialNetworkSchoolDTO): Config {
    return {
      ...FIELD_LIST_SOCIAL_EDUCATION_OPTIONS,
      fields: [
        {
          key: 'EducationView',
          label: 'Вид образования',
          value: 'Начальное',
        },
        {
          key: 'City',
          label: 'Город',
          value: educationItem.City,
        },
        {
          key: 'StartDate',
          label: 'Год начала обучения',
          value: educationItem.StartDate,
        },
        {
          key: 'DiplomaDate',
          label: 'Дата выдачи документа',
          value: educationItem.DiplomaDate,
        },
      ].filter(item => item.value)
    };
  }
}
