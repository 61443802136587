/* eslint-disable class-methods-use-this, max-len */

import { injectable } from 'inversify';
import { StringUtils } from '~/utils/stringUtils';
import { LegalEntitiesSelfRegulatoryOrganization } from '~/types/legal-entities/selfRegulatoryOrganization';
import { LegalEntitiesSelfRegulatoryOrganizationDTO } from '~/types/dto/legalEntities/selfRegulatoryOrganizationDTO';
import {
  ILegalEntitiesSelfRegulatoryOrganizationAdapterService
} from '~/services/adapters/legalEntities/legalEntitiesAdapterService.interfaces';

@injectable()
export class LegalEntitiesSelfRegulatoryOrganizationAdapterService implements ILegalEntitiesSelfRegulatoryOrganizationAdapterService {
  getData(initData: LegalEntitiesSelfRegulatoryOrganization[]): LegalEntitiesSelfRegulatoryOrganizationDTO[] {
    return (initData ?? []).map((item) => ({
      SroInn: item.SroInn,
      SroName: item.SroName,
      SroRegistryName: StringUtils.quoteReplacement(item.SroRegistryName),
      SroRegistryNumber: item.SroRegistryNumber,
      ParticipantRegistrationDate: item.ParticipantRegistrationDate ?? '',
      ParticipantTerminationDate: item.ParticipantTerminationDate ?? ''
    }));
  }
}
