export enum UIReportType {
  ul = 'UL',
  fl = 'FL',
  phoneNumber = 'PHONE_NUMBER',
  vehicle = 'VEHICLE'
}

export enum APIReportType {
  company = 0,
  person = 1,
  phoneNumber = 2,
  carNumber = 3,
  connection = 4,
  unknown = 5
}

export enum DataBlobReportType {
  unknown = 0,
  person = 10,
  company = 20,
  phoneNumber = 30,
  carNumber = 40
}
