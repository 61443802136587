import { injectable } from 'inversify';
import { ReportPossibleFullName } from '~/types/common/reportPossibleFullName';
import { IPlaceholderDataService } from './reportPlaceholderService.interfaces';

@injectable()
export class ReportPossibleFullNamePlaceholderService implements IPlaceholderDataService<ReportPossibleFullName> {
  getData(): ReportPossibleFullName {
    return {
      FirstName: '',
      LastName: '',
      Patronymic: '',
      FullName: '',
    };
  }
}
