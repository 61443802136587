import { injectable } from 'inversify';
import { IndividualFullNameChange } from '~/types/individual/fullNameChange';
import { IPlaceholderDataService } from '../common/reportPlaceholderService.interfaces';

@injectable()
export class IndividualFullNameChangePlaceholderService implements IPlaceholderDataService<IndividualFullNameChange> {
  getData(): IndividualFullNameChange {
    return {
      FirstName: '',
      LastName: '',
      Patronymic: '',
      FullName: '',
      ChangeDate: '',
      ChangeReason: '',
    };
  }
}
