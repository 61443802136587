/* eslint-disable class-methods-use-this, max-len */

import { injectable } from 'inversify';
import { orderBy } from 'lodash-es';
import { StringUtils } from '~/utils/stringUtils';
import { LegalEntitiesTax } from '~/types/legal-entities/tax';
import { LegalEntitiesTaxDTO } from '~/types/dto/legalEntities/LegalEntitiesTax';
import {
  ILegalEntitiesTaxAdapterService
} from '~/services/adapters/legalEntities/legalEntitiesAdapterService.interfaces';

@injectable()
export class LegalEntitiesTaxAdapterService implements ILegalEntitiesTaxAdapterService {
  getData(initData: LegalEntitiesTax[]): LegalEntitiesTaxDTO[] {
    const data = (initData ?? []).map((tax) => ({
      Name: StringUtils.quoteReplacement(tax.Name),
      Sum: tax.Sum,
      Year: tax.Year,
    }));
    return orderBy(data, 'Year', ['asc']);
  }
}
