import { injectable } from 'inversify';
import { IIndividualEntrepreneursStatusColorService } from '~/modules/individual/lifePath/individualEntrepreneurs/services/individualEntrepreneursStatusColorService.interface';
import { UITextAccentContainerColorType } from '~/modules/containers/UIContainer/UITextAccentContainer/types/UITextAccentContainerColorType';

@injectable()
export class IndividualEntrepreneursStatusColorService implements IIndividualEntrepreneursStatusColorService {
  getColorStatus(initData: string): UITextAccentContainerColorType {
    switch (initData.toLowerCase()) {
      case 'активный': return 'green';
      default: return 'red';
    }
  }
}
