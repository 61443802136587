import { injectable, inject } from 'inversify';

import { IPrimitiveTypesNormalizationServiceKey, IPrimitiveTypesNormalizationService } from './primitiveTypesNormalizationService.interfaces';
import { ReportMention } from '~/types/common/reportMention';
import { ReportVehicleParking } from '~/types/common/reportVehicleParking';
import { INormalizationService, IReportMentionNormalizationServiceKey } from './reportNormalizationService.interfaces';

@injectable()
export class ReportVehicleParkingNormalizationService implements INormalizationService<ReportVehicleParking> {
  constructor(
    @inject(IReportMentionNormalizationServiceKey as symbol) private reportMentionNormalizationService: INormalizationService<ReportMention>,

    @inject(IPrimitiveTypesNormalizationServiceKey as symbol) private primitiveTypesNormalizationService: IPrimitiveTypesNormalizationService,
  ) {}

  normalize(data: any, root: string): ReportVehicleParking | undefined {
    const {
      normalizeOptionalString,
      normalizeString,
      normalizeArray,
      normalizeObject
    } = this.primitiveTypesNormalizationService;

    return normalizeObject(data, (data) => ({
      CarNumber: normalizeString(data.CarNumber, `${root}.CarNumber`),
      StartDate: normalizeOptionalString(data.StartDate, `${root}.StartDate`),
      EndDate: normalizeOptionalString(data.EndDate, `${root}.EndDate`),
      Duration: normalizeOptionalString(data.Duration, `${root}.Duration`),
      PlaceCode: normalizeString(data.PlaceCode, `${root}.PlaceCode`),
      Phone: normalizeString(data.Phone, `${root}.Phone`),
      SessionId: normalizeString(data.SessionId, `${root}.SessionId`),
      Tags: normalizeArray(data.Tags, (item, field) => this.reportMentionNormalizationService.normalize(item, field), `${root}.Tags`)
    }), root);
  }
}
