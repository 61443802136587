import { injectable } from 'inversify';

import { ReportVehiclePhone } from '~/types/common/reportVehiclePhone';
import { IPlaceholderDataService } from './reportPlaceholderService.interfaces';

@injectable()
export class ReportVehiclePhonePlaceholderService implements IPlaceholderDataService<ReportVehiclePhone> {
  getData(): ReportVehiclePhone {
    return {
      Phone: ''
    };
  }
}
