import { injectable, inject } from 'inversify';
import { IPrimitiveTypesNormalizationServiceKey, IPrimitiveTypesNormalizationService } from './primitiveTypesNormalizationService.interfaces';
import {
  INormalizationService,
  IReportConnectionRelatedEntityNormalizationServiceKey, IReportConnectionRelatedRelationNormalizationServiceKey
} from './reportNormalizationService.interfaces';
import { ReportConnectionRelatedChain } from '~/types/common/reportCompanyRelatedChain';
import { ReportConnectionRelatedEntity } from '~/types/common/reportConnectionRelatedEntity';
import { ReportConnectionRelatedRelation } from '~/types/common/reportConnectionRelatedRelation';

@injectable()
export class ReportConnectionRelatedChainNormalizationsService implements INormalizationService<ReportConnectionRelatedChain> {
  constructor(
    @inject(IPrimitiveTypesNormalizationServiceKey as symbol) private primitiveTypesNormalizationService: IPrimitiveTypesNormalizationService,
    @inject(IReportConnectionRelatedEntityNormalizationServiceKey as symbol) private reportConnectionRelatedEntityNormalizationService: INormalizationService<ReportConnectionRelatedEntity>,
    @inject(IReportConnectionRelatedRelationNormalizationServiceKey as symbol) private reportConnectionRelatedRelationNormalizationService: INormalizationService<ReportConnectionRelatedRelation>,
  ) {}

  normalize(data: any, root: string): ReportConnectionRelatedChain | undefined {
    const {
      normalizeArray,
      normalizeObject
    } = this.primitiveTypesNormalizationService;

    return normalizeObject(data, (data) => ({
      Entities: normalizeArray(
        data.Entities,
        (item, field) => this.reportConnectionRelatedEntityNormalizationService.normalize(item, field),
        `${root}.Entities`
      ),
      Relations: normalizeArray(
        data.Relations,
        (item, field) => this.reportConnectionRelatedRelationNormalizationService.normalize(item, field),
        `${root}.Relations`
      ),
    }), root);
  }
}
