import { injectable } from 'inversify';
import { Config } from '~/types/field-list/config';
import { IndividualPhoneDTO } from '~/types/dto/individual/phoneDTO';
import { IReportSubscriberNumberPhoneConfigService } from './reportSubscriberNumberPhoneConfigService.interface';
import { PhoneUtils } from '~/utils/phone';

@injectable()
export class ReportSubscriberNumberPhoneConfigService implements IReportSubscriberNumberPhoneConfigService {
  getConfig(item: IndividualPhoneDTO): Config {
    return {
      fieldsDirection: 'column',
      fieldsWidth: 'auto',
      fields: [
        {
          key: 'phoneNumber',
          label: 'Абонентский номер',
          value: PhoneUtils.formatPhoneNumberWithDash(item.Value),
        }
      ]
    };
  }
}
