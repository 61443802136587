import { injectable } from 'inversify';
import {
  IReportSocialNetworkMilitaryRecordAdapterService
} from '~/services/adapters/common/reportAdapterService.interfeces';
import { ReportSocialNetworkMilitaryRecord } from '~/types/common/reportSocialNetworkMilitaryRecord';
import { ReportSocialNetworkMilitaryRecordDTO } from '~/types/dto/common/reportSocialNetworkMilitaryRecordDTO';
import { StringUtils } from '~/utils/stringUtils';

@injectable()
export class ReportSocialNetworkMilitaryRecordAdapterService implements IReportSocialNetworkMilitaryRecordAdapterService {
  getData(initData: ReportSocialNetworkMilitaryRecord[]): ReportSocialNetworkMilitaryRecordDTO[] {
    return (initData ?? []).map((item) => ({
      Type: item.Type,
      SocialNetworkId: StringUtils.quoteReplacement(item.SocialNetworkId),
      LastName: StringUtils.quoteReplacement(item.LastName),
      FirstName: StringUtils.quoteReplacement(item.FirstName),
      Birthday: item.Birthday ?? '',
      EducationName: StringUtils.quoteReplacement(item.EducationName),
      City: StringUtils.quoteReplacement(item.City),
      MilitaryUnit: StringUtils.quoteReplacement(item.MilitaryUnit),
      StartDate: item.StartDate ?? '',
      EndDate: item.EndDate ?? '',
      IsServingNow: item.IsServingNow ?? false
    }));
  }
}
