import { injectable } from 'inversify';
import { ReportVehicleRelation } from '~/types/common/reportVehicleRelation';
import { IPlaceholderDataService } from './reportPlaceholderService.interfaces';

@injectable()
export class ReportVehicleRelationPlaceholderService implements IPlaceholderDataService<ReportVehicleRelation> {
  getData(): ReportVehicleRelation {
    return {
      SourceType: 0,
      RegDate: '',
      DeregistrationDate: '',
      Name: '',
      Birthday: '',
      Inn: '',
      Passport: '',
      PhoneNumber: '',
      Address: '',
      Sts: '',
      Pts: '',
      PtsDepartmentName: '',
      PtsIssuedDate: '',
    };
  }
}
