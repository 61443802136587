import { injectable } from 'inversify';
import { IPhoneNumberRelatedPersonCommonAdapterServices } from '~/services/adapters/phoneNimber/phoneNumberAdapterService.interfaces';
import { PhoneNumberRelatedPhonePersonCommon } from '~/types/phone-number/relatedPhonePersonCommon';
import { PhoneNumberRelatedPersonCommonDTO } from '~/types/dto/phone-number/relatedPersonCommonDTO';

@injectable()
export class PhoneNumberRelatedPersonCommonAdapterServices implements IPhoneNumberRelatedPersonCommonAdapterServices {
  getData(initData: PhoneNumberRelatedPhonePersonCommon): PhoneNumberRelatedPersonCommonDTO {
    return {
      BirthPlace: initData.BirthPlace ?? '',
      BirthDay: initData.Birthday ?? '',
      Citizenship: initData.Citizenship ?? '',
      Nationality: initData.Nationality ?? ''
    };
  }
}
