import { IContainerBinder } from '~/configuration/inversify/containerAccessor.interface';
import { IReportSocialNetworkSchoolConfigServiceKey,
  IReportSocialNetworkSecondaryEducationConfigServiceKey,
  IReportSocialNetworkHighEducationConfigServiceKey,
  IReportSocialNetworkLifePositionConfigServiceKey,
  IReportSocialNetworkConfigServiceKey, 
  IReportSocialNetworkJobConfigServiceKey,
  IReportSocialNetworkInterestsConfigServiceKey, 
  IReportSocialNetworkHistoryMaritalStatusKey} from '../../common/services/reportSocialNetworkConfigService.interfaces';
import { ReportSocialNetworkConfigService } from '../../common/services/reportSocialNetworkConfigService';
import { ReportSocialNetworkHighEducationConfigService } from '../../common/services/reportSocialNetworkHighEducationConfigService';
import { ReportSocialNetworkInterestsConfigService } from '../../common/services/reportSocialNetworkInterestsConfigService';
import { ReportSocialNetworkJobConfigService } from '../../common/services/reportSocialNetworkJobConfigService';
import { ReportSocialNetworkLifePositionConfigService } from '../../common/services/reportSocialNetworkLifePositionConfigService';
import { ReportSocialNetworkSchoolConfigService } from '../../common/services/reportSocialNetworkSchoolConfigService';
import { ReportSocialNetworkSecondaryEducationConfigService } from '../../common/services/reportSocialNetworkSecondaryEducationConfigService';
import { ReportSocialNetworkHistoryMaritalStatus } from '../../common/services/reportSocialNetworkHistoryMaritalStatus';

export function bindSocialNetworksServices(bind: IContainerBinder) {
  bind(IReportSocialNetworkSchoolConfigServiceKey).to(ReportSocialNetworkSchoolConfigService);
  bind(IReportSocialNetworkSecondaryEducationConfigServiceKey).to(ReportSocialNetworkSecondaryEducationConfigService);
  bind(IReportSocialNetworkHighEducationConfigServiceKey).to(ReportSocialNetworkHighEducationConfigService);
  bind(IReportSocialNetworkLifePositionConfigServiceKey).to(ReportSocialNetworkLifePositionConfigService);
  bind(IReportSocialNetworkInterestsConfigServiceKey).to(ReportSocialNetworkInterestsConfigService);
  bind(IReportSocialNetworkConfigServiceKey).to(ReportSocialNetworkConfigService);
  bind(IReportSocialNetworkJobConfigServiceKey).to(ReportSocialNetworkJobConfigService);
  bind(IReportSocialNetworkHistoryMaritalStatusKey).to(ReportSocialNetworkHistoryMaritalStatus);
}
