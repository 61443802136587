import { injectable } from 'inversify';
import { IReportPaymentAdapterService } from '~/services/adapters/common/reportAdapterService.interfeces';
import { ReportPayment } from '~/types/common/reportPayment';
import { ReportPaymentDTO } from '~/types/dto/common/reportPaymentDTO';
import { StringUtils } from '~/utils/stringUtils';

@injectable()
export class ReportPaymentAdapterService implements IReportPaymentAdapterService {
  getData(initData: ReportPayment[]): ReportPaymentDTO[] {
    return (initData ?? []).map((item) => ({
      Type: StringUtils.quoteReplacement(item.Type),
      Month: StringUtils.quoteReplacement(item.Month ?? ''),
      Amount: item.Amount
    }));
  }
}
