import { injectable } from 'inversify';
import { IConvertingPrimitiveTableTypes } from '~/modules/legalEntities/financialAnalytics/common/services/convertingPrimitiveTableTypes.interfaces';
import { NumberUtils } from '~/utils/number';

@injectable()
export class ConvertingPrimitiveTableTypes implements IConvertingPrimitiveTableTypes {
  currencyConversion(rawValue: number, maxPossible: number): string {
    const currentValue = NumberUtils.formatByMaxValue(rawValue, maxPossible);

    if (rawValue > 0 && parseFloat(currentValue.toFixed(2)) === 0.00) {
      return '>= 0.01';
    } else if (rawValue < 0 && parseFloat(currentValue.toFixed(2)) === 0.00) {
      return '<= 0.01';
    }

    return NumberUtils.formatNumberDigits(currentValue, 2, '—');
  }

  percentageConversion(rawValue: number | null, postfix?: boolean): string {
    return rawValue == null ? '—' : `${NumberUtils.formatNumberDigits(rawValue * 100, 2, '—')}${postfix ? '%' : ''}`;
  }
}
