import { ISplittableContext } from '~/types/splittables/splittableContext';
import { ISplittableSection } from '~/types/splittables/splittableSection';
import { ISplittableCell } from '~/types/splittables/splittableCell';
import { CompositeNodeSizeSnapshot } from '~/models/nodes/snapshots/compositeNodeSizeSnapshot';

export default class FakeSplittableContext<T> implements ISplittableContext<T> {
  private allowToCreateSections = true;

  constructor(
    private snapshot: CompositeNodeSizeSnapshot,
    private data: T | undefined
  ) { }

  async applyLayoutAsync(): Promise<ISplittableSection<T>[]> {
    const { top, bottom, height, elements } = this.snapshot;
    const { data } = this;

    return new Array<ISplittableSection<T>>(
      {
        top,
        bottom,
        height,

        cells: new Array<ISplittableCell<T>>(
          {
            top,
            bottom,
            height,
            elements,
            data
          }
        )
      }
    );
  }

  async createSectionAsync(availableHeight: number, forceFit?: boolean): Promise<boolean> {
    if (this.allowToCreateSections && this.snapshot.height <= availableHeight) {
      this.allowToCreateSections = false;
      return true;
    }

    return false;
  }

  hasFreeContent(): boolean {
    return this.allowToCreateSections;
  }
  
  isSplittable(): boolean {
    return false;
  }
}
