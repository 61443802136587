import { injectable } from 'inversify';
import { orderBy } from 'lodash-es';
import { LegalEntitiesCompanyStatedCapitalDTO } from '~/types/dto/legalEntities/companyStatedCapitalDTO';
import {
  ILegalEntitiesAuthorizedCapitalSortService
} from '~/modules/legalEntities/changeHistory/legalEntitiesAuthorizedCapital/services/legalEntitiesAuthorizedCapitalSortService.interfaces';

@injectable()
export class LegalEntitiesAuthorizedCapitalSortService implements ILegalEntitiesAuthorizedCapitalSortService {
  getSortData(initData: Array<LegalEntitiesCompanyStatedCapitalDTO>): Array<LegalEntitiesCompanyStatedCapitalDTO> {
    return orderBy(
      initData,
      [(item) => item.To && item.To !== item.From ? 0 : 1, 'From'], // Сначала сортируем по "To", затем по "From"
      ['asc', 'desc']
    );
  }
}
