import { injectable } from 'inversify';
import { IIndividualWantedOtherSiftingService } from '~/modules/individual/negativeInformation/IndividualWantedOther/services/individualWantedOtherSiftingService.interfaces';
import { IndividualWantedDTO } from '~/types/dto/individual/wantedDTO';
import { StringUtils } from '~/utils/stringUtils';

@injectable()
export class IndividualWantedOtherSiftingService implements IIndividualWantedOtherSiftingService {
  private siftingData = [
    'в розыске не находится.',
    'года рождения предположительно находится в розыске.',
  ];
  
  getSiftedData(initData: Array<IndividualWantedDTO>): Array<IndividualWantedDTO> {
    return initData.filter((item) => this.siftingData.every((data) => !StringUtils.cleanString(item.WantedNote).includes(data)));
  }
}
