import { IContainerBinder } from '~/configuration/inversify/containerAccessor.interface';
import { IReportSubscriberNumberConfigServiceKey } from '../../services/reportSubscriberNumberConfigService.interface';
import { ReportSubscriberNumberConfigService } from '../../services/reportSubscriberNumberConfigService';
import { IReportSubscriberNumberMessengerConfigServiceKey } from '../../services/reportSubscriberNumberMessengerConfigService.interface';
import { ReportSubscriberNumberMessengerConfigService } from '../../services/reportSubscriberNumberMessengerConfigService';
import { ReportSubscriberNumberPhoneConfigService } from '../../services/reportSubscriberNumberPhoneConfigService';
import { IReportSubscriberNumberPhoneConfigServiceKey } from '../../services/reportSubscriberNumberPhoneConfigService.interface';

export function bindSubscriberNumberService(bind: IContainerBinder) {
  bind(IReportSubscriberNumberConfigServiceKey).to(ReportSubscriberNumberConfigService);
  bind(IReportSubscriberNumberMessengerConfigServiceKey).to(ReportSubscriberNumberMessengerConfigService);
  bind(IReportSubscriberNumberPhoneConfigServiceKey).to(ReportSubscriberNumberPhoneConfigService);
}
