import { injectable, inject } from 'inversify';
import {
  ILegalEntitiesReportFinancialIndicatorWithConclusionsAdapterService,
  ILegalEntitiesReportFinancialIndicatorWithConclusionsAdapterServiceKey,
  ILegalEntitiesReportLiquidityAndSolvencyAdapterService
} from '~/services/adapters/legalEntities/legalEntitiesAdapterService.interfaces';
import { LegalEntitiesReportLiquidityAndSolvency } from '~/types/legal-entities/reportLiquidityAndSolvency';
import { LegalEntitiesReportLiquidityAndSolvencyDTO } from '~/types/dto/legalEntities/reportLiquidityAndSolvency';

@injectable()
export class LegalEntitiesReportLiquidityAndSolvencyAdapterService implements ILegalEntitiesReportLiquidityAndSolvencyAdapterService {
  constructor(
    @inject(ILegalEntitiesReportFinancialIndicatorWithConclusionsAdapterServiceKey as symbol) private legalEntitiesReportFinancialIndicatorWithConclusions: ILegalEntitiesReportFinancialIndicatorWithConclusionsAdapterService
  ) {}
  getData(initData: LegalEntitiesReportLiquidityAndSolvency): LegalEntitiesReportLiquidityAndSolvencyDTO {
    return {
      OwnWorkingCapital: this.legalEntitiesReportFinancialIndicatorWithConclusions.getData(initData.OwnWorkingCapital),
      RatioOfCurrentLiquidity: this.legalEntitiesReportFinancialIndicatorWithConclusions.getData(initData.RatioOfCurrentLiquidity),
      RatioOfSolvency: this.legalEntitiesReportFinancialIndicatorWithConclusions.getData(initData.RatioOfSolvency),
      DegreeOfSolvency: this.legalEntitiesReportFinancialIndicatorWithConclusions.getData(initData.DegreeOfSolvency),
      RatioOfReceivablesToTotalAssets: this.legalEntitiesReportFinancialIndicatorWithConclusions.getData(initData.RatioOfReceivablesToTotalAssets),
    };
  }
}
