import { InjectionKey } from 'vue';
import { LegalEntitiesCompanyStatedCapitalDTO } from '~/types/dto/legalEntities/companyStatedCapitalDTO';
import { Config } from '~/types/field-list/config';

export interface ILegalEntitiesAuthorizedCapital {
  getData(initData: Array<LegalEntitiesCompanyStatedCapitalDTO>): Array<Config>;
}

export type ILegalEntitiesAuthorizedCapitalService = ILegalEntitiesAuthorizedCapital;

export const ILegalEntitiesAuthorizedCapitalServiceKey: InjectionKey<ILegalEntitiesAuthorizedCapitalService> = Symbol('ILegalEntitiesAuthorizedCapitalService');
