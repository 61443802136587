import { injectable, inject } from 'inversify';
import { IndividualWanted } from '~/types/individual/wanted';
import { IPrimitiveTypesNormalizationServiceKey, IPrimitiveTypesNormalizationService } from '../common/primitiveTypesNormalizationService.interfaces';
import { INormalizationService } from '../common/reportNormalizationService.interfaces';

@injectable()
export class IndividualWantedNormalizationService implements INormalizationService<IndividualWanted> {
  constructor(
    @inject(IPrimitiveTypesNormalizationServiceKey as symbol) private primitiveTypesNormalizationService: IPrimitiveTypesNormalizationService,
  ) {}

  normalize(data: any, root: string): IndividualWanted | undefined {
    const { normalizeObject, normalizeString, normalizeOptionalString } = this.primitiveTypesNormalizationService;

    return normalizeObject(data, (data) => ({
      WantedDateStart: normalizeOptionalString(data.WantedDateStart, `${root}.WantedDateStart`),
      WantedBasis: normalizeOptionalString(data.WantedBasis, `${root}.WantedBasis`),
      WantedSearchType: normalizeOptionalString(data.WantedSearchType, `${root}.WantedSearchType`),
      WantedSearchCategory: normalizeOptionalString(data.WantedSearchCategory, `${root}.WantedSearchCategory`),
      WantedPreventiveMeasure: normalizeString(data.WantedPreventiveMeasure, `${root}.WantedPreventiveMeasure`),
      WantedSearchInitiator: normalizeString(data.WantedSearchInitiator, `${root}.WantedSearchInitiator`),
      WantedDateStartCriminal: normalizeOptionalString(data.WantedDateStartCriminal, `${root}.WantedDateStartCriminal`),
      WantedDateEndCriminal: normalizeOptionalString(data.WantedDateEndCriminal, `${root}.WantedDateEndCriminal`),
      WantedDateTerminationSearch: normalizeOptionalString(data.WantedDateTerminationSearch, `${root}.WantedDateTerminationSearch`),
      WantedArmed: normalizeString(data.WantedArmed, `${root}.WantedArmed`),
      WantedPreviouslyJudged: normalizeString(data.WantedPreviouslyJudged, `${root}.WantedPreviouslyJudged`),
      WantedNote: normalizeString(data.WantedNote, `${root}.WantedNote`),
    }), root);
  }
}
