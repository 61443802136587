import { PrimitiveTypesMergeService } from '~/services/merge/primitive-types-merge-service';
import { IPrimitiveTypesMergeServiceKey } from '~/services/merge/primitive-types-merge-service.interface';

import { IContainerBinder } from './containerAccessor.interface';

import { VehicleRelationsMergeService } from '~/services/merge/vehicle-relations-merge-service';
import { IVehicleRelationsMergeServiceKey } from '~/services/merge/vehicle-relations-merge-service.interfaces';

import { ILegalEntitiesTaxByYearMergeServiceKey } from '~/services/merge/legal-entities-tax-by-year-merge-service.interfaces';
import { LegalEntitiesTaxByYearMergeService } from '~/services/merge/legal-entities-tax-by-year-merge-service';
import { ILegalEntitiesVehiclesMergeServiceKey } from '~/services/merge/legal-entities-vehicles-merge-service.interfaces';
import { LegalEntitiesVehiclesMergeService } from '~/services/merge/legal-entities-vehicles-merge-service';
import { ILegalEntitiesCompanyHistoryAddressMergeServiceKey } from '~/services/merge/legalEntitiesCompanyHistoryAddress.interfaces';
import { LegalEntitiesCompanyHistoryAddressMergeService } from '~/services/merge/legalEntitiesCompanyHistoryAddressMergeService';
import { EmailsMergeService } from '~/services/merge/emailsMergeService';
import { IEmailsMergeServiceKey } from '~/services/merge/emailsMergeService.interfaces';
import { PassportsMergeService } from '~/services/merge/passportsMergeService';
import { IPassportsMergeServiceKey } from '~/services/merge/passportsMergeService.interfaces';
import { ILegalEntitiesCompanyStatusMergeServiceKey } from '~/services/merge/legalEntitiesCompanyStatusMergeService.interfaces';
import { LegalEntitiesCompanyStatusMergeService } from '~/services/merge/legalEntitiesCompanyStatusMergeService';
import { IEntrepreneursMergeServiceKey } from '~/services/merge/entrepreneursMergeService.interfaces';
import { EntrepreneursMergeService } from '~/services/merge/entrepreneursMergeService';
import { CommonEmailsMergeService } from '~/services/merge/commonEmailsMergeService';
import { ICommonEmailsMergeServiceKey } from '~/services/merge/commonEmailsMergeService.interfaces';
import { ReportTagMergeService } from '~/services/merge/reportTagMergeService';
import { IReportTagMergeServiceKey } from '~/services/merge/reportTagMergeService.interfaces';
import { IReportSocialNetworksMergeServiceKey } from '~/services/merge/reportSocialNetworksMergeService.interfaces';
import { ReportSocialNetworksMergeService } from '~/services/merge/reportSocialNetworksMergeService';
import { bindLegalEntitiesMergeService } from '~/modules/legalEntities/common/configurations/inversify/container.legalEntitiesMergeServises';
import { IReportUrlMergeServiceKey } from '~/services/merge/common/reportMergeService.interfaces';
import { ReportUrlMergeService } from '~/services/merge/common/reportUrlMergeService';
import { IReportFineMergeServiceKey } from '~/services/merge/reportFineMergeService.interfaces';
import { ReportFineMergeService } from '~/services/merge/reportFineMergeService';

export function bindMergeServices(bind: IContainerBinder) {
  bind(ILegalEntitiesVehiclesMergeServiceKey).to(LegalEntitiesVehiclesMergeService);
  bind(IVehicleRelationsMergeServiceKey).to(VehicleRelationsMergeService);
  bind(IPrimitiveTypesMergeServiceKey).to(PrimitiveTypesMergeService);
  bind(ILegalEntitiesTaxByYearMergeServiceKey).to(LegalEntitiesTaxByYearMergeService);
  bind(IPassportsMergeServiceKey).to(PassportsMergeService);
  bind(ILegalEntitiesCompanyHistoryAddressMergeServiceKey).to(LegalEntitiesCompanyHistoryAddressMergeService);
  bind(IEmailsMergeServiceKey).to(EmailsMergeService);
  bind(ILegalEntitiesCompanyStatusMergeServiceKey).to(LegalEntitiesCompanyStatusMergeService);
  bind(IEntrepreneursMergeServiceKey).to(EntrepreneursMergeService);
  bind(ICommonEmailsMergeServiceKey).to(CommonEmailsMergeService);
  bind(IReportTagMergeServiceKey).to(ReportTagMergeService);
  bind(IReportSocialNetworksMergeServiceKey).to(ReportSocialNetworksMergeService);
  bind(IReportUrlMergeServiceKey).to(ReportUrlMergeService);
  bind(IReportFineMergeServiceKey).to(ReportFineMergeService);

  bindLegalEntitiesMergeService(bind);
}
