import { injectable } from 'inversify';
import { IReportMovementCovidAdapterService } from '~/services/adapters/common/reportAdapterService.interfeces';
import { ReportMovementCovid } from '~/types/common/reportMovementCovid';
import { ReportMovementCovidDTO } from '~/types/dto/common/reportMovementCovidDTO';
import { StringUtils } from '~/utils/stringUtils';
import { PhoneUtils } from '~/utils/phone';

@injectable()
export class ReportMovementCovidAdapterService implements IReportMovementCovidAdapterService {
  getData(initData: ReportMovementCovid[]): ReportMovementCovidDTO[] {
    return (initData ?? []).map((item) => ({
      Email: item.Email ?? '',
      PhoneNumber: PhoneUtils.normalizePhoneNumber(item.PhoneNumber ?? ''),
      Inn: item.Inn ?? '',
      Passport: item.Passport ?? '',
      MovementFrom: StringUtils.quoteReplacement(item.MovementFrom ?? ''),
      MovementTroykaCardNum: StringUtils.quoteReplacement(item.MovementTroykaCardNum ?? ''),
      MovementSocialCardNum: StringUtils.quoteReplacement(item.MovementSocialCardNum ?? ''),
      MovementVehicleNum: StringUtils.quoteReplacement(item.MovementVehicleNum ?? ''),
      MovementStrelkaCardNum: StringUtils.quoteReplacement(item.MovementStrelkaCardNum ?? ''),
      MovementDirection: item.MovementDirection ?? '',
      MovementTo: StringUtils.quoteReplacement(item.MovementTo ?? ''),
      MovementPurpose: item.MovementPurpose ?? '',
      Organisation: item.Organisation ?? '',
      OrganisationInn: item.OrganisationInn ?? '',
      FullName: item.FullName ?? ''
    }))
      .filter(
        (movement) => StringUtils.isStringNotEmpty(movement.MovementFrom)
                || StringUtils.isStringNotEmpty(movement.MovementTroykaCardNum)
                || StringUtils.isStringNotEmpty(movement.MovementSocialCardNum)
                || StringUtils.isStringNotEmpty(movement.MovementVehicleNum)
                || StringUtils.isStringNotEmpty(movement.MovementStrelkaCardNum)
                || StringUtils.isStringNotEmpty(movement.MovementDirection)
                || StringUtils.isStringNotEmpty(movement.MovementTo),
      );
  }
}
