import { injectable } from 'inversify';
import { IIndividualGroupAdapterService } from '~/services/adapters/individual/individualAdapterService.interfaces';
import { IndividualGroup } from '~/types/individual/group';
import { IndividualGroupDTO } from '~/types/dto/individual/groupDTO';
import { StringUtils } from '~/utils/stringUtils';

@injectable()
export class IndividualGroupAdapterService implements IIndividualGroupAdapterService {
  getData(initData: IndividualGroup[]): IndividualGroupDTO[] {
    return (initData ?? []).map((item) => ({
      Name: StringUtils.quoteReplacement(item.Name)
    }));
  }
}
