import { injectable } from 'inversify';
import { IPlaceholderDataService } from '~/services/placeholder/common/reportPlaceholderService.interfaces';
import { LegalEntitiesAnalyticsIndicators } from '~/types/legal-entities/analyticsIndicators';

@injectable()
export class LegalEntitiesAnalyticsIndicatorsPlaceholderDataService implements IPlaceholderDataService<LegalEntitiesAnalyticsIndicators> {
  getData(): LegalEntitiesAnalyticsIndicators {
    return {
      CountOfCompaniesOnHead: 0,
      CountOfCompaniesOnFounder: 0
    };
  }
}
