import { injectable } from 'inversify';

import { LegalEntitiesRegistrarOfShareholders } from '~/types/legal-entities/registrarOfShareholders';
import { IPlaceholderDataService } from '../common/reportPlaceholderService.interfaces';

@injectable()
export class LegalEntitiesRegistrarOfShareholdersPlaceholderService implements IPlaceholderDataService<LegalEntitiesRegistrarOfShareholders> {
  getData(): LegalEntitiesRegistrarOfShareholders {
    return {
      Name: '',
      Inn: '',
      Ogrn: '',
      Date: '',
      FirstDate: '',
      Status: ''
    };
  }
}
