import { IContainerBinder } from '~/configuration/inversify/containerAccessor.interface';
import { IndividualTrustScoringConversionItemService } from '~/modules/individual/scoringScores/individualTrustScoring/service/individualTrustScoringConversionItemService';
import { IIndividualTrustScoringConversionDataServiceKey } from '~/modules/individual/scoringScores/individualTrustScoring/service/IndividualTrustScoringConversionService.inteface';
import { IndividualTrustScoringConversionService } from '~/modules/individual/scoringScores/individualTrustScoring/service/individualTrustScoringConversionService';
import { IIndividualTrustScoringConversionItemServiceKey } from '~/modules/individual/scoringScores/individualTrustScoring/service/individualTrustScoringConversionItem.interface';
import { IIndividualTrustScoringMergeServiceKey } from '../../service/individualTrustScoringMergeService.interfaces';
import { IndividualTrustScoringMergeService } from '../../service/individualTrustScoringMergeService';

export function bindIndividualTrustScoringServices(bind: IContainerBinder) {
  bind(IIndividualTrustScoringConversionItemServiceKey).to(IndividualTrustScoringConversionItemService);
  bind(IIndividualTrustScoringConversionDataServiceKey).to(IndividualTrustScoringConversionService);
  bind(IIndividualTrustScoringMergeServiceKey).to(IndividualTrustScoringMergeService);
}
