import { injectable, inject } from 'inversify';

import { IPrimitiveTypesNormalizationServiceKey, IPrimitiveTypesNormalizationService } from '../common/primitiveTypesNormalizationService.interfaces';
import { INormalizationService } from '../common/reportNormalizationService.interfaces';
import { LegalEntitiesAverageHeadcount } from '~/types/legal-entities/averageHeadcount';

@injectable()
export class LegalEntitiesAverageHeadcountNormalizationService implements INormalizationService<LegalEntitiesAverageHeadcount> {
  constructor(
    @inject(IPrimitiveTypesNormalizationServiceKey as symbol) private primitiveTypesNormalizationService: IPrimitiveTypesNormalizationService,
  ) {}

  normalize(data: any, root: string): LegalEntitiesAverageHeadcount | undefined {
    const {
      normalizeOptionalString,
      normalizeString,
      normalizeObject,
      normalizeNumber,
      normalizeOptionalBoolean
    } = this.primitiveTypesNormalizationService;

    return normalizeObject(data, (data) => ({
      Count: normalizeNumber(data.Count, `${root}.Count`),
      DateStart: normalizeString(data.DateStart, `${root}.DateStart`),
      DateEnd: normalizeOptionalString(data.DateEnd, `${root}.DateEnd`),
      IsHistoric: normalizeOptionalBoolean(data.IsHistoric, `${root}.IsHistoric`),
    }), root);
  }
}
