import { injectable, inject } from 'inversify';
import { LegalEntitiesConflictSign } from '~/types/legal-entities/conflictSign';
import { LegalEntitiesPerson } from '~/types/legal-entities/person';
import { IPlaceholderDataService } from '../common/reportPlaceholderService.interfaces';
import { ILegalEntitiesPersonPlaceholderDataServiceKey } from './legalEntitiesPlaceholderDataService.interfaces';

@injectable()
export class LegalEntitiesConflictSignPlaceholderDataService implements IPlaceholderDataService<LegalEntitiesConflictSign> {
  constructor(
    @inject(ILegalEntitiesPersonPlaceholderDataServiceKey as symbol) private personPlaceholderDataService: IPlaceholderDataService<LegalEntitiesPerson>,
  ) {}

  getData(): LegalEntitiesConflictSign {
    return {
      Person1: this.personPlaceholderDataService.getData(),
      Person2: this.personPlaceholderDataService.getData(),
      Reasons: []
    };
  }
}
