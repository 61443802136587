import { injectable, inject } from 'inversify';

import { ReportAnalysisOfAbsoluteLiquidity } from '~/types/legal-entities/reportAnalysisOfAbsoluteLiquidity';
import { LegalEntitiesReportAnalysisOfAbsoluteLiquidityIndicator } from '~/types/legal-entities/reportAnalysisOfAbsoluteLiquidityIndicator';
import { ILegalEntitiesReportAnalysisOfAbsoluteLiquidityIndicatorPlaceholderDataServiceKey } from '../legalEntities/legalEntitiesPlaceholderDataService.interfaces';
import { IPlaceholderDataService } from './reportPlaceholderService.interfaces';

@injectable()
export class ReportAnalysisOfAbsoluteLiquidityPlaceholderService implements IPlaceholderDataService<ReportAnalysisOfAbsoluteLiquidity> {
  constructor(
    @inject(ILegalEntitiesReportAnalysisOfAbsoluteLiquidityIndicatorPlaceholderDataServiceKey as symbol) private reportAnalysisOfAbsoluteLiquidityIndicatorPlaceholderDataService: IPlaceholderDataService<LegalEntitiesReportAnalysisOfAbsoluteLiquidityIndicator>,
  ) {}

  getData(): ReportAnalysisOfAbsoluteLiquidity {
    return {
      MostLiquidAssetsToMostUrgentLiabilities: this.reportAnalysisOfAbsoluteLiquidityIndicatorPlaceholderDataService.getData(),
      MarketableAssetsToOneYearLoanObligations: this.reportAnalysisOfAbsoluteLiquidityIndicatorPlaceholderDataService.getData(),
      SlowSellingAssetsToLongTermBorrowings: this.reportAnalysisOfAbsoluteLiquidityIndicatorPlaceholderDataService.getData(),
      HardSellingAssetsToCapitalAndReserves: this.reportAnalysisOfAbsoluteLiquidityIndicatorPlaceholderDataService.getData(),
      DegreeOfRepaymentObligationsWithAssets: 0,
      IsSolventInShortAndMediumTerm: false,
      IsSolventInShortAndMediumTermConclusion: '',
      IsSolventInLongTerm: false,
      IsSolventInLongTermConclusion: '',
    };
  }
}
