import { isDate as _isDate } from 'lodash-es';
import { DateTime, DurationUnits } from 'luxon';
import { StringUtils } from '~/utils/stringUtils';

export module DateUtils {
  export function isDate(date: any): date is Date {
    return _isDate(date);
  }

  export function parseDate(date: string | Date | undefined): Date | undefined {
    if (isDate(date)) {
      return date;
    }

    return StringUtils.isString(date) ? new Date(date) : undefined;
  }

  export function formatDate(
    unFormatDate?: string | Date,
    monthType: Intl.DateTimeFormatOptions['month'] = 'numeric',
  ): string {
    if (!unFormatDate) return '';

    const newFormatDate = unFormatDate instanceof Date ? unFormatDate : new Date(unFormatDate);

    return newFormatDate.toLocaleString('ru', {
      year: 'numeric',
      month: monthType,
      day: 'numeric',
    });
  }

  export function formatTime(unFormatDate?: string | Date): string {
    if (unFormatDate === undefined) return '';

    const newFormatDate = unFormatDate instanceof Date ? unFormatDate : new Date(unFormatDate);
    return newFormatDate.toLocaleTimeString('ru');
  }

  export function formatTimeToHoursAndMinutes(unFormatDate?: string | Date): string {
    if (unFormatDate === undefined) return '';

    const newFormatDate = unFormatDate instanceof Date ? unFormatDate : new Date(unFormatDate);
    return newFormatDate.toLocaleTimeString('ru', {
      hour: '2-digit',
      minute: '2-digit'
    });
  }

  export function getTime(unFormatDate: string | undefined, options?: Intl.DateTimeFormatOptions): string {
    if (!StringUtils.isStringNotEmpty(unFormatDate)) {
      return '';
    }

    const newFormatDate = new Date(unFormatDate);
    return newFormatDate.toLocaleString('ru', {
      hour: 'numeric',
      minute: 'numeric',
      ...(options ?? {}),
    });
  }

  export function getYear(unFormatDate: string | Date): string {
    const newFormatDate = unFormatDate instanceof Date ? unFormatDate : new Date(unFormatDate);
    return newFormatDate.toLocaleString('ru', {
      year: 'numeric',
    });
  }

  export function isValidDate(date: string) {
    const dateRegex = /^\d{2}\.\d{2}\.\d{4}$/;
    return dateRegex.test(date);
  }

  export function isValidFormatDate(date: string) {
    // проверка на формат даты '2018-11-29T00:00:00'
    const dateRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}$/;
    return dateRegex.test(date);
  }

  export function formatDateToDateAndTime(unFormatDate: string | Date): string {
    if (!unFormatDate) return '';

    const newFormatDate = unFormatDate instanceof Date ? unFormatDate : new Date(unFormatDate);

    return newFormatDate.toLocaleString('ru', {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
  }

  export function calcDateDiff(firstDate?: Date, secondDate?: Date, options: DurationUnits = ['years', 'days']) {
    if (firstDate == undefined || secondDate == undefined) return { years: 0, days: 0 };
    // если вторая дата не валидна - значит по настоящее время 
    const validSecondDate = isValidDate(formatDate(secondDate)) ? secondDate : new Date();

    const firstFormattedDate = DateTime.fromJSDate(firstDate); 
    const secondFormattedDate = DateTime.fromJSDate(validSecondDate);

    const dateDiff = secondFormattedDate.diff(firstFormattedDate, options).toObject();
    dateDiff.days = Math.round(dateDiff.days ?? 0);
    dateDiff.years = Math.round(dateDiff.years ?? 0);

    return dateDiff;
  }

  export function checkIfDateIsOne(startDate?: string, endDate?: string) {
    return Boolean(startDate !== endDate && (startDate || endDate));
  }
}
