import { injectable } from 'inversify';
import { IMessageBoardsInfoConfigService } from '~/modules/reportsCommon/reportMessageBoardRecord/services/messageBoardsConfigService.interfaces';
import { ReportMessageBoardRecordDTO } from '~/types/dto/common/messageBoardsDTO';
import { Config } from '~/types/field-list/config';

@injectable()
export class MessageBoardsInfoConfigService implements IMessageBoardsInfoConfigService {
  getConfig(initData: ReportMessageBoardRecordDTO): Config {
    return {
      fieldsDirection: 'divider-row',
      fieldsWidth: '100%',
      fieldsValueWidth: '350px',
      isBeginningDivider: true,
      rowGap: '8px',
      fields: [
        {
          label: 'Дата размещения',
          key: 'RegistrationDate',
          value: initData.RegistrationDate
        },
        {
          label: 'Город',
          key: 'Region',
          value: initData.Region
        },
        {
          label: 'VIN',
          key: 'Vin',
          value: initData.Vin
        },
        {
          label: 'Гос. номер',
          key: 'CarNumber',
          value: initData.CarNumber
        },
        {
          label: 'Стоимость, \u{20BD}',
          key: 'Price',
          value: initData.Price
        },
      ].filter((infoItem) => infoItem.key === 'Стоимость, \u{20BD}'
        ? infoItem.value !== '0'
        : infoItem.value)
    };
  }
}
