import { injectable } from 'inversify';
import { uniq } from 'lodash-es';
import { IReportJobCompanyPhoneConfigService } from '~/modules/reportsCommon/reportJobs/services/reportJobConfigService.interfaces';
import { ReportJobDTO } from '~/types/dto/common/reportJobDTO';
import { Config } from '~/types/field-list/config';

@injectable()
export class ReportJobCompanyPhoneConfigService implements IReportJobCompanyPhoneConfigService {
  getConfig(item: ReportJobDTO): Config {
    const uniqNumber = uniq([item.CompanyPhone || item.CompanyHeadPhone || item.CompanyBookkeeperPhone]);
    const hasUniqNumber = uniqNumber.length > 1;
    const companyHeadPhone = hasUniqNumber ? item.CompanyHeadPhone : '';
    const companyBookkeeperPhone = hasUniqNumber ? item.CompanyBookkeeperPhone : '';
    return {
      fieldsWidth: '100%',
      fieldsDirection: 'column',
      fields: [
        {
          key: 'currentPhone',
          label: 'Телефон компании',
          value: item.CompanyPhone || item.CompanyHeadPhone || item.CompanyBookkeeperPhone,
        },
        {
          key: 'CompanyHeadPhone',
          label: 'Телефон руководителя',
          value: companyHeadPhone,
        },
        {
          key: 'CompanyBookkeeperPhone',
          label: 'Телефон бухгалтера',
          value: companyBookkeeperPhone,
        },
      ].filter((item) => item.value)
    };
  }
}
