import NodesPagingRow from '~/models/nodes/paging/nodesPagingRow';

export interface NodesPagingPageOptions {
    height: number
}

export default class NodesPagingPage<T> {
  readonly rows = new Array<NodesPagingRow<T>>();

  get top(): number {
    return this.getTop(this.rows);
  }

  get bottom(): number {
    return this.getBottom(this.rows);
  }

  get height(): number {
    return this.getHeight(this.rows);
  }

  get freeSpace(): number {
    const result = this.options.height - this.height;
    return result > 0 ? result : 0;
  }

  get isEmpty(): boolean {
    return this.rows.length == 0;
  }

  constructor(private options: NodesPagingPageOptions) { }

  addRow(row: NodesPagingRow<T>) {
    this.rows.push(row);
  }

  tryAddRow(row: NodesPagingRow<T>) {
    const newRows = [...this.rows, row];
    const newHeight = this.getHeight(newRows);

    if (newHeight <= this.options.height) {
      this.rows.push(row);
      return true;
    }

    return false;
  }
  
  clone(): NodesPagingPage<T> {
    return new NodesPagingPage<T>({ height: this.options.height });
  }

  private getTop(rows: NodesPagingRow<T>[]): number {
    if (rows.length > 0)
      return Math.min(...rows.map(row => row.top));

    return 0;
  }

  private getBottom(rows: NodesPagingRow<T>[]): number {
    if (rows.length > 0)
      return Math.max(...rows.map(row => row.bottom));

    return 0;
  }

  private getHeight(rows: NodesPagingRow<T>[]): number {
    return this.getBottom(rows) - this.getTop(rows);
  }
}
