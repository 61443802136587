import { injectable, inject } from 'inversify';
import { ReportMainEntityFile } from '~/types/common/reportMainEntityFile';
import { ReportSocialNetwork } from '~/types/common/reportSocialNetwork';
import { ReportSocialNetworkAboutInfo } from '~/types/common/reportSocialNetworkAboutInfo';
import { ReportSocialNetworkLifePosition } from '~/types/common/reportSocialNetworkLifePosition';
import { IPlaceholderDataService, IReportMainEntityFilePlaceholderServiceKey, IReportSocialNetworkAboutInfoPlaceholderServiceKey, IReportSocialNetworkLifePositionPlaceholderServiceKey } from './reportPlaceholderService.interfaces';

@injectable()
export class ReportSocialNetworkPlaceholderService implements IPlaceholderDataService<ReportSocialNetwork> {
  constructor(
    @inject(IReportMainEntityFilePlaceholderServiceKey as symbol) private reportMainEntityFilePlaceholderService: IPlaceholderDataService<ReportMainEntityFile>,
    @inject(IReportSocialNetworkAboutInfoPlaceholderServiceKey as symbol) private socialNetworkAboutInfoPlaceholderService: IPlaceholderDataService<ReportSocialNetworkAboutInfo>,
    @inject(IReportSocialNetworkLifePositionPlaceholderServiceKey as symbol) private socialNetworkLifePositionPlaceholderService: IPlaceholderDataService<ReportSocialNetworkLifePosition>,
  ) {}

  getData(): ReportSocialNetwork {
    return {
      Type: 0,
      SocialId: '',
      FullName: '',
      Birthday: '',
      IsVerified: false,
      IsPrivateAccount: false,
      LastDevice: '',
      Status: '',
      Languages: '',
      Subscribers: [],
      Friends: [],
      WhoLikes: [],
      WhoComments: [],
      WhoReposts: [],
      WhoMentions: [],
      WhoIsLikedByAccountOwner: [],
      WhoIsRepostedByAccountOwner: [],
      WhoIsCommentedByAccountOwner: [],
      WhoIsMentionedByAccountOwner: [],
      Photos: [],
      Avatar: this.reportMainEntityFilePlaceholderService.getData(),
      SocialNetworkAboutInfos: this.socialNetworkAboutInfoPlaceholderService.getData(),
      SocialNetworkLifePosition: this.socialNetworkLifePositionPlaceholderService.getData(),
      SocialGroups: [],
      VideoUrls: [],
      AudioUrls: [],
    };
  }
}
