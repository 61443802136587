import { InjectionKey } from 'vue';
import { IndividualTrustScoringDTO } from '~/types/dto/individual/trustScoringDTO';
import { IndividualTrustScoringConversion } from '~/modules/individual/scoringScores/individualTrustScoring/types/IndividualTrustScoringConversion';
import {
  IndividualTrustScoringConversionItem
} from '~/modules/individual/scoringScores/individualTrustScoring/types/IndividualTrustScoringConversionItem';

interface IIndividualTrustScoringConversionService<T, O> {
  getData(initDta: T): O;
}

export type IIndividualTrustScoringConversionDataService = IIndividualTrustScoringConversionService<Array<IndividualTrustScoringDTO>, Array<IndividualTrustScoringConversion>>

export const IIndividualTrustScoringConversionDataServiceKey: InjectionKey<IIndividualTrustScoringConversionDataService> = Symbol('IIndividualTrustScoringConversionDataService');
