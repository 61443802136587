import { injectable } from 'inversify';
import { isEqual } from 'lodash-es';
import { MergeService } from '~/services/merge/merge-service';
import { ReportPassportDTO } from '~/types/dto/common/reportPassportDTO';

@injectable()
export class PassportsMergeService extends MergeService<ReportPassportDTO> {
  protected canMerge(leftItem: ReportPassportDTO, rightItem: ReportPassportDTO): boolean {
    return isEqual(leftItem, rightItem);
  }

  protected mergeFields(leftItem: ReportPassportDTO, rightItem: ReportPassportDTO): ReportPassportDTO {
    return {
      Type: leftItem.Type || rightItem.Type,
      IsActual: leftItem.IsActual || rightItem.IsActual,
      Series: leftItem.Series || rightItem.Series,
      Number: leftItem.Number || rightItem.Number,
      IssuedDate: leftItem.IssuedDate || rightItem.IssuedDate,
      IssuedBy: leftItem.IssuedBy || rightItem.IssuedBy,
      DepartmentCode: leftItem.DepartmentCode || rightItem.DepartmentCode
    };
  }
}
