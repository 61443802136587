import { appSettings } from '~/settings';
import { ViewMode } from '~/types/viewMode';

type WhichVewMode = {
  isPdfView: boolean,
  isWebView: boolean
}

export function useViewModeClass(): WhichVewMode {
  const enumViewMode = appSettings.getQueryViewMode;

  return {
    isPdfView: enumViewMode == ViewMode.pdf,
    isWebView: enumViewMode == ViewMode.web
  };
}
