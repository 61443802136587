import { injectable } from 'inversify';

import { LegalEntitiesCompanyHeadFl } from '~/types/legal-entities/companyHeadFl';
import { IPlaceholderDataService } from '../common/reportPlaceholderService.interfaces';

@injectable()
export class LegalEntitiesCompanyHeadFlPlaceholderDataService implements IPlaceholderDataService<LegalEntitiesCompanyHeadFl> {
  getData(): LegalEntitiesCompanyHeadFl {
    return {
      Fio: '',
      Innfl: '',
      Position: '',
      Date: '',
      FirstDate: '',
      IsInaccuracy: false,
      InaccuracyDate: '',
      IsCompanyCoOwner: false,
      OwnershipPercent: 0
    };
  }
}
