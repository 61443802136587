import { injectable, inject } from 'inversify';
import { IndividualBusinessTrip } from '~/types/individual/businessTrip';
import { IPrimitiveTypesNormalizationServiceKey, IPrimitiveTypesNormalizationService } from '../common/primitiveTypesNormalizationService.interfaces';
import { INormalizationService } from '../common/reportNormalizationService.interfaces';

@injectable()
export class IndividualBusinessTripNormalizationService implements INormalizationService<IndividualBusinessTrip> {
  constructor(
    @inject(IPrimitiveTypesNormalizationServiceKey as symbol) private primitiveTypesNormalizationService: IPrimitiveTypesNormalizationService,
  ) {}

  normalize(data: any, root: string): IndividualBusinessTrip | undefined {
    const { normalizeObject, normalizeString, normalizeOptionalString } = this.primitiveTypesNormalizationService;

    return normalizeObject(data, (data) => ({
      StartDate: normalizeOptionalString(data.StartDate, `${root}.StartDate`),
      EndDate: normalizeOptionalString(data.EndDate, `${root}.EndDate`),
      SendingCompany: normalizeString(data.SendingCompany, `${root}.SendingCompany`),
      ReceivingCompany: normalizeString(data.ReceivingCompany, `${root}.ReceivingCompany`),
      Purpose: normalizeString(data.Purpose, `${root}.Purpose`),
      Place: normalizeString(data.Place, `${root}.Place`),
    }), root);
  }
}
