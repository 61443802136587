import axios, { AxiosInstance } from 'axios';
import { InjectionKey } from 'vue';

export const nuxtEndpointKey: InjectionKey<AxiosInstance> = Symbol('AxiosInstance');

export function useNuxtEndpoint() {
  return axios.create({
    baseURL: '',
  });
}
