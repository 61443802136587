import { injectable, inject } from 'inversify';
import { IndividualDriverLicense } from '~/types/individual/driverLicense';
import { IPrimitiveTypesNormalizationServiceKey, IPrimitiveTypesNormalizationService } from '../common/primitiveTypesNormalizationService.interfaces';
import { INormalizationService } from '../common/reportNormalizationService.interfaces';

@injectable()
export class IndividualDriverLicenseNormalizationService implements INormalizationService<IndividualDriverLicense> {
  constructor(
    @inject(IPrimitiveTypesNormalizationServiceKey as symbol) private primitiveTypesNormalizationService: IPrimitiveTypesNormalizationService,
  ) {}

  normalize(data: any, root: string): IndividualDriverLicense | undefined {
    const {
      normalizeObject,
      normalizeString,
      normalizeOptionalString,
      normalizeBoolean,
      normalizeNumber
    } = this.primitiveTypesNormalizationService;
    return normalizeObject(data, (data) => ({
      Series: normalizeString(data.Series, `${root}.Series`),
      Number: normalizeString(data.Number, `${root}.Number`),
      IssueDate: normalizeOptionalString(data.IssueDate, `${root}.IssueDate`),
      IssuePlace: normalizeString(data.IssuePlace, `${root}.IssuePlace`),
      DrivingExperience: normalizeNumber(data.DrivingExperience, `${root}.DrivingExperience`),
      ExpireDate: normalizeOptionalString(data.ExpireDate, `${root}.ExpireDate`),
      Categories: normalizeOptionalString(data.Categories, `${root}.Categories`),
      IsWanted: normalizeBoolean(data.IsWanted, `${root}.IsWanted`),
      DeprivateDecision: normalizeString(data.DeprivateDecision, `${root}.DeprivateDecision`),
    }), root);
  }
}
