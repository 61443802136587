// to-do Раскидать по уже существующим утилитам
export module AviasUtils {
  export function formatFlightStartTime(time: string | undefined) {
    if (!time) {
      return '';
    }
    if (time.includes(':')) {
      return time;
    }
    return time.length === 4 ? `${time.slice(0, 2)}:${time.slice(2, 4)}` : '';
  }
}
