import { injectable, inject } from 'inversify';
import { IndividualGroup } from '~/types/individual/group';
import { IPrimitiveTypesNormalizationServiceKey, IPrimitiveTypesNormalizationService } from '../common/primitiveTypesNormalizationService.interfaces';
import { INormalizationService } from '../common/reportNormalizationService.interfaces';

@injectable()
export class IndividualGroupNormalizationService implements INormalizationService<IndividualGroup> {
  constructor(
    @inject(IPrimitiveTypesNormalizationServiceKey as symbol) private primitiveTypesNormalizationService: IPrimitiveTypesNormalizationService,
  ) {}

  normalize(data: any, root: string): IndividualGroup | undefined {
    const { normalizeObject, normalizeString } = this.primitiveTypesNormalizationService;

    return normalizeObject(data, (data) => ({
      Name: normalizeString(data.Name, `${root}.Name`)
    }), root);
  }
}
