import { injectable, inject } from 'inversify';
import { INormalizationService } from '~/services/normalization/common/reportNormalizationService.interfaces';
import { LegalEntitiesAnalyticsIndicators } from '~/types/legal-entities/analyticsIndicators';
import {
  IPrimitiveTypesNormalizationService,
  IPrimitiveTypesNormalizationServiceKey
} from '~/services/normalization/common/primitiveTypesNormalizationService.interfaces';

@injectable()
export class LegalEntitiesAnalyticsIndicatorsNormalizationService implements INormalizationService<LegalEntitiesAnalyticsIndicators> {
  constructor(
    @inject(IPrimitiveTypesNormalizationServiceKey as symbol) private primitiveTypesNormalizationService: IPrimitiveTypesNormalizationService,
  ) {}
  normalize(data: any, root: string): LegalEntitiesAnalyticsIndicators | undefined {
    const { normalizeObject, normalizeOptionalInteger } = this.primitiveTypesNormalizationService;
    return normalizeObject(data, (data) => ({
      CountOfCompaniesOnHead: normalizeOptionalInteger(data.CountOfCompaniesOnHead, `${root}.CountOfCompaniesOnHead`),
      CountOfCompaniesOnFounder: normalizeOptionalInteger(data.CountOfCompaniesOnFounder, `${root}.CountOfCompaniesOnFounder`)
    }), root);
  }
}
