import { injectable, inject } from 'inversify';

import { IPrimitiveTypesNormalizationServiceKey, IPrimitiveTypesNormalizationService } from './primitiveTypesNormalizationService.interfaces';
import { ReportPledgesRegistry } from '~/types/common/reportPledgesRegistry';
import { INormalizationService } from './reportNormalizationService.interfaces';

@injectable()
export class ReportPledgesRegistryNormalizationService implements INormalizationService<ReportPledgesRegistry> {
  constructor(
    @inject(IPrimitiveTypesNormalizationServiceKey as symbol) private primitiveTypesNormalizationService: IPrimitiveTypesNormalizationService,
  ) {}

  normalize(data: any, root: string): ReportPledgesRegistry | undefined {
    const { normalizeString, normalizeOptionalString, normalizeObject } = this.primitiveTypesNormalizationService;

    return normalizeObject(data, (data) => ({
      RegistrationDate: normalizeOptionalString(data.RegistrationDate, `${root}.RegistrationDate`),
      ExclusionDate: normalizeOptionalString(data.ExclusionDate, `${root}.ExclusionDate`),
      RegistrationNumber: normalizeString(data.RegistrationNumber, `${root}.RegistrationNumber`),
      Property: normalizeOptionalString(data.Property, `${root}.Property`),
      Pledger: normalizeOptionalString(data.Pledger, `${root}.Pledger`),
      Pledgee: normalizeOptionalString(data.Pledgee, `${root}.Pledgee`),
    }), root);
  }
}
