import { injectable } from 'inversify';
import { IReportCovidMovementsConfigService } from '~/modules/reportsCommon/reportMovement/common/services/reportMovementsConfigService.interfaces';
import { MOVEMENTS_FIELD_LIST_OPTIONS } from '~/modules/reportsCommon/reportMovement/common/configuration/constants';
import { Config } from '~/types/field-list/config';
import { ReportMovementCovidDTO } from '~/types/dto/common/reportMovementCovidDTO';

@injectable()
export class IndividualMovementsCovidConfigServices implements IReportCovidMovementsConfigService {
  getConfig(covidMovement: ReportMovementCovidDTO): Config {
    return {
      ...MOVEMENTS_FIELD_LIST_OPTIONS,
      fields: [
        {
          key: 'Passport',
          label: 'Паспорт',
          value: covidMovement.Passport
        },
        {
          key: 'Email',
          label: 'E-mail',
          value: covidMovement.Email
        },
        {
          key: 'PhoneNumber',
          label: 'Телефон',
          value: covidMovement.PhoneNumber
        },
        {
          key: 'MovementVehicleNum',
          label: 'Государственный номер',
          value: covidMovement.MovementVehicleNum
        },
        {
          key: 'MovementDirection',
          label: 'Направление',
          value: covidMovement.MovementDirection
        }
      ]
    };
  }
}
