/* eslint-disable class-methods-use-this */
import { injectable } from 'inversify';
import { CommonUtils } from '~/utils/common';
import { ReportMentionDTO } from '~/types/dto/common/reportMentionDTO';

@injectable()
export class TagService {
  getTagsFromMentions(data: ReportMentionDTO[]): Array< string > {
    return data.flatMap((mention) => {
      if (mention.Source.toLowerCase() === 'одиссей') {
        if (mention.OriginalFullName.split(';').length > 1) {
          return mention.OriginalFullName.split(';');
        }

        return mention.FullName;
      }

      return mention.OriginalFullName;
    })
      .map((mention) => CommonUtils.formatName(mention));
  }
}
