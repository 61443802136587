import { defineStore, StoreDefinition } from 'pinia';
import { v4 as uuidv4 } from 'uuid';
import { UIReportType } from '~/types/reportType';
import { DataAdapter } from '~/types/dataAdapter';
import { useDataStore } from '~/stores/dataStore';
import { LegalEntitiesDataBlob } from '~/types/blobs/legal-entities-data-blob';
import { IndividualDataBlob } from '~/types/blobs/individual-data-blob';
import { PhoneNumbersDataBlob } from '~/types/blobs/phone-numbers-data-blob';
import { VehiclesDataBlob } from '~/types/blobs/vehicles-data-blob';
import { GenericDataBlob } from '~/types/blobs/generic-data-blob';
import useEnvironment from '~/composables/useEnvironment';
import { Environment } from '~/types/Environment';

interface State<K> {
  value: K;
}

interface Getters {}

interface Actions {
  initialize(): Promise<void>;
}

export function createReportEntitiesStore<K>(
  reportType: UIReportType.ul,
  adapter: DataAdapter<LegalEntitiesDataBlob, K>
): StoreDefinition<string, State<K>, Getters, Actions>;
export function createReportEntitiesStore<K>(
  reportType: UIReportType.fl,
  adapter: DataAdapter<IndividualDataBlob, K>
): StoreDefinition<string, State<K>, Getters, Actions>;
export function createReportEntitiesStore<K>(
  reportType: UIReportType.phoneNumber,
  adapter: DataAdapter<PhoneNumbersDataBlob, K>
): StoreDefinition<string, State<K>, Getters, Actions>;
export function createReportEntitiesStore<K>(
  reportType: UIReportType.vehicle,
  adapter: DataAdapter<VehiclesDataBlob, K>
): StoreDefinition<string, State<K>, Getters, Actions>;
export function createReportEntitiesStore<K>(
  reportType: UIReportType,
  adapter: DataAdapter<any, K>
) {
  const storeFactory = defineStore(uuidv4(), () => {
    const dataStore = useDataStore<GenericDataBlob>(reportType);
    const value = computed(() => {
      const data = dataStore.data as GenericDataBlob | undefined;

      return data != undefined ? adapter(data) : undefined;
    });

    async function initialize() {
      await dataStore.initialize();
    }

    return { value, initialize };
  });

  return () => {
    const environment = useEnvironment();
    const store = storeFactory();
    let isSuccess = useState('isSuccess', () => false);

    if (environment == Environment.server) {
      useLazyAsyncData(async () => {
        try {
          await store.initialize()
          isSuccess.value = true;
        } catch {
          isSuccess.value = false;
        }
      });
    }

    onMounted(() => {
      if (!isSuccess.value) {
        navigateTo('/404');
      }
    })

    return store;
  };
}
