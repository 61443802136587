/* eslint-disable class-methods-use-this, max-len */

import { injectable } from 'inversify';
import { LegalEntitiesLicenseSummary } from '~/types/legal-entities/licenseSummary';
import {
  ILegalEntitiesLicenseSummaryAdapterService
} from '~/services/adapters/legalEntities/legalEntitiesAdapterService.interfaces';
import { LegalEntitiesLicenseSummaryDTO } from '~/types/dto/legalEntities/licenseSummaryDTO';

@injectable()
export class LegalEntitiesLicenseSummaryAdapterService implements ILegalEntitiesLicenseSummaryAdapterService {
  getData(initData: LegalEntitiesLicenseSummary[]): LegalEntitiesLicenseSummaryDTO[] {
    return (initData ?? []).map((item) => ({
      Activity: item.Activity,
      ActualCount: item.ActualCount,
      ArchiveCount: item.ArchiveCount
    }));
  }
}
