import { injectable } from 'inversify';
import { IIndividualCriminalPenaltiesAdapterService } from '~/services/adapters/individual/individualAdapterService.interfaces';
import { IndividualCriminalPenalties } from '~/types/individual/criminalPenalties';
import { IndividualCriminalPenaltiesDTO } from '~/types/dto/individual/criminalPenaltiesDTO';
import { StringUtils } from '~/utils/stringUtils';

@injectable()
export class IndividualCriminalPenaltiesAdapterService implements IIndividualCriminalPenaltiesAdapterService {
  getData(initData: IndividualCriminalPenalties[]): IndividualCriminalPenaltiesDTO[] {
    return (initData ?? []).map((item) => ({
      Article: StringUtils.quoteReplacement(item.Article ?? ''),
      Part: StringUtils.quoteReplacement(item.Part ?? ''),
      Point: StringUtils.quoteReplacement(item.Point ?? ''),
      ArticleContent: StringUtils.quoteReplacement(item.ArticleContent ?? ''),
      CaseInitiationDate: item.CaseInitiationDate ?? '',
      ConvictionDate: item.ConvictionDate ?? '',
      CaseNumber: StringUtils.quoteReplacement(item.CaseNumber ?? ''),
      Comment: StringUtils.quoteReplacement(item.Comment)
    }));
  }
}
