import { injectable, inject } from 'inversify';
import { ReportIndicatorsOfCashFlow } from '~/types/blobs/report-indicators-of-cash-flow';

import { LegalEntitiesFinancialAnalysisExtended } from '~/types/legal-entities/financialAnalysisExtended';
import { ReportAnalysisOfAbsoluteLiquidity } from '~/types/legal-entities/reportAnalysisOfAbsoluteLiquidity';
import { LegalEntitiesReportFinancialStability } from '~/types/legal-entities/reportFinancialStability';
import { ReportIndicatorsOfProfitability } from '~/types/legal-entities/reportIndicatorsOfProfitability';
import { LegalEntitiesReportLiquidityAndSolvency } from '~/types/legal-entities/reportLiquidityAndSolvency';
import { IReportAnalysisOfAbsoluteLiquidityPlaceholderServiceKey, IReportIndicatorsOfCashFlowPlaceholderServiceKey, IReportIndicatorsOfProfitabilityPlaceholderServiceKey, IPlaceholderDataService } from '../common/reportPlaceholderService.interfaces';
import { ILegalEntitiesReportLiquidityAndSolvencyPlaceholderDataServiceKey, ILegalEntitiesReportFinancialStabilityPlaceholderDataServiceKey } from './legalEntitiesPlaceholderDataService.interfaces';

@injectable()
export class LegalEntitiesFinancialAnalysisExtendedPlaceholderDataService implements IPlaceholderDataService<LegalEntitiesFinancialAnalysisExtended> {
  constructor(
    @inject(ILegalEntitiesReportLiquidityAndSolvencyPlaceholderDataServiceKey as symbol) private reportLiquidityAndSolvencyPlaceholderDataService: IPlaceholderDataService<LegalEntitiesReportLiquidityAndSolvency>,
    @inject(ILegalEntitiesReportFinancialStabilityPlaceholderDataServiceKey as symbol) private reportFinancialStabilityPlaceholderDataService: IPlaceholderDataService<LegalEntitiesReportFinancialStability>,
    @inject(IReportAnalysisOfAbsoluteLiquidityPlaceholderServiceKey as symbol) private reportAnalysisOfAbsoluteLiquidityPlaceholderService: IPlaceholderDataService<ReportAnalysisOfAbsoluteLiquidity>,
    @inject(IReportIndicatorsOfProfitabilityPlaceholderServiceKey as symbol) private reportIndicatorsOfProfitabilityPlaceholderService: IPlaceholderDataService<ReportIndicatorsOfProfitability>,
    @inject(IReportIndicatorsOfCashFlowPlaceholderServiceKey as symbol) private reportIndicatorsOfCashFlowPlaceholderService: IPlaceholderDataService<ReportIndicatorsOfCashFlow>,
  ) {}

  getData(): LegalEntitiesFinancialAnalysisExtended {
    return {
      IsExists: false,
      TwoYearsAgo: 0,
      LastYear: 0,
      CurrentYear: 0,
      LiquidityAndSolvency: this.reportLiquidityAndSolvencyPlaceholderDataService.getData(),
      AnalysisOfAbsoluteLiquidity: this.reportAnalysisOfAbsoluteLiquidityPlaceholderService.getData(),
      FinancialStability: this.reportFinancialStabilityPlaceholderDataService.getData(),
      IndicatorsOfProfitability: this.reportIndicatorsOfProfitabilityPlaceholderService.getData(),
      IndicatorsOfCashFlow: this.reportIndicatorsOfCashFlowPlaceholderService.getData(),
    };
  }
}
