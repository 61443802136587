import { injectable, inject } from 'inversify';
import { IReportUrlPlaceholderServiceKey, IPlaceholderDataService } from '~/services/placeholder/common/reportPlaceholderService.interfaces';
import { ReportMessageBoardRecord } from '~/types/common/reportMessageBoardRecord';
import { ReportUrl } from '~/types/common/reportUrl';
import { IPrimitiveTypesNormalizationServiceKey, IPrimitiveTypesNormalizationService } from './primitiveTypesNormalizationService.interfaces';
import { INormalizationService, IReportUrlNormalizationServiceKey } from './reportNormalizationService.interfaces';

@injectable()
export class ReportMessageBoardRecordNormalizationService implements INormalizationService<ReportMessageBoardRecord> {
  constructor(
    @inject(IReportUrlNormalizationServiceKey as symbol) private reportUrlNormalizationService: INormalizationService<ReportUrl>,

    @inject(IReportUrlPlaceholderServiceKey as symbol) private reportUrlPlaceholderService: IPlaceholderDataService<ReportUrl>,

    @inject(IPrimitiveTypesNormalizationServiceKey as symbol) private primitiveTypesNormalizationService: IPrimitiveTypesNormalizationService,
  ) {}

  normalize(data: any, root: string): ReportMessageBoardRecord | undefined {
    const {
      normalizeObject,
      normalizeString,
      normalizeOptionalString,
      normalizeOptionalInteger
    } = this.primitiveTypesNormalizationService;

    return normalizeObject(data, (data) => ({
      MessageSourceType: normalizeString(data.MessageSourceType, `${root}.MessageSourceType`),
      MessageBoardDataType: normalizeString(data.MessageBoardDataType, `${root}.MessageBoardDataType`),
      Category: normalizeOptionalString(data.Category, `${root}.Category`),
      MessageTitle: normalizeOptionalString(data.MessageTitle, `${root}.MessageTitle`),
      MessageText: normalizeOptionalString(data.MessageText, `${root}.MessageText`),
      RequestNumber: normalizeOptionalString(data.RequestNumber, `${root}.RequestNumber`),
      RegistrationDate: normalizeOptionalString(data.RegistrationDate, `${root}.RegistrationDate`),
      Region: normalizeString(data.Region, `${root}.Region`),
      Price: normalizeOptionalInteger(data.Price, `${root}.Price`),
      Currency: normalizeString(data.Currency, `${root}.Currency`),
      Year: normalizeOptionalInteger(data.Year, `${root}.Year`),
      CarNumber: normalizeOptionalString(data.CarNumber, `${root}.CarNumber`),
      Brand: normalizeOptionalString(data.Brand, `${root}.Brand`),
      Model: normalizeOptionalString(data.Model, `${root}.Model`),
      Vin: normalizeOptionalString(data.Vin, `${root}.Vin`),
      Contactface: normalizeOptionalString(data.Contactface, `${root}.Contactface`),
      Link: this.reportUrlNormalizationService.normalize(data.Link, `${root}.Link`) ?? this.reportUrlPlaceholderService.getData()
    }), root);
  }
}
