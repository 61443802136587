import { injectable, inject } from 'inversify';
import { ReportPersonInn } from '~/types/common/reportPersonInn';
import { IPrimitiveTypesNormalizationServiceKey, IPrimitiveTypesNormalizationService } from './primitiveTypesNormalizationService.interfaces';
import { INormalizationService } from './reportNormalizationService.interfaces';

@injectable()
export class ReportPersonInnNormalizationService implements INormalizationService<ReportPersonInn> {
  constructor(
    @inject(IPrimitiveTypesNormalizationServiceKey as symbol) private primitiveTypesNormalizationService: IPrimitiveTypesNormalizationService,
  ) {}

  normalize(data: any, root: string): ReportPersonInn | undefined {
    const { normalizeObject, normalizeString, normalizeBoolean } = this.primitiveTypesNormalizationService;

    return normalizeObject(data, (data) => ({
      Value: normalizeString(data.Value, `${root}.Value`),
      IsValid: normalizeBoolean(data.IsValid, `${root}.IsValid`),
      IsActual: normalizeBoolean(data.IsActual, `${root}.IsActual`),
    }), root);
  }
}
