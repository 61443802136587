import { get } from 'lodash-es';
import { createReportEntitiesStore } from '~/factories/reportEntitiesStoreFactory';
import { UIReportType } from '~/types/reportType';
import { useInjectable } from '~/hooks/useInjectable';
import { IIndividualDataBlobDataServiceKey } from '~/services/adapters/individual/individualAdapterService.interfaces';
import { IDataListAdapterKey } from '~/services/adapters/dataListAdapterServise.interface';
import { IndividualDataBlobData } from '~/types/blobs/individual-data-blob-data';

export default createReportEntitiesStore(UIReportType.fl, (data) => {
  const individualDataList = useInjectable(IDataListAdapterKey);
  const individualService = useInjectable(IIndividualDataBlobDataServiceKey);

  return get(individualDataList.getDataList(data).map((individual) => individualService.getData(individual as IndividualDataBlobData)), 0);
});
