/* eslint-disable class-methods-use-this */

import { ViewMode } from './types/viewMode';
import { ReportUtils } from './utils/reportUtils';

class AppSettings {
  get pageHeight() {
    return 1430;
  }
  
  get graphBaseURL(): string {
    return useRuntimeConfig().public.graphBaseURL;
  }

  get dataEndpointURL() {
    return useRuntimeConfig().public.dataEndpointURL;
  }

  get dataEndpointInternalURL() {
    return useRuntimeConfig().public.dataEndpointInternalURL;
  }

  get token() {
    return useRuntimeConfig().public.apiKey;
  }

  get buildHash() {
    return useRuntimeConfig().public.CI_COMMIT_SHORT_SHA;
  }

  get shouldUseMockData(): boolean {
    return (
      this.dataEndpointURL == undefined
      || this.token == undefined
      || this.dataId == undefined
      && this.taskId == undefined
    );
  }

  get targetMock(): string | undefined {
    const route = useRoute();
    return route.query.targetMock as string | undefined;
  }

  get dataId(): string | undefined {
    const route = useRoute();
    return route.query.uid as string | undefined;
  }

  get taskId(): string | undefined {
    const route = useRoute();
    return route.query.taskId as string | undefined;
  }

  get allowDevTools(): boolean {
    const route = useRoute();
    return route.query.devtools == '1';
  }

  get allowSplitting(): boolean {
    // TODO: отключено до внедрения новой версии разделителя
    // const route = useRoute();
    // return route.query.disableSplitter !== '1';
    return false;
  }

  get timeBetweenStatusUpdates() {
    return 1000;
  }

  get getQueryViewMode(): ViewMode {
    const route = useRoute();
    const query = String(route.query.mode);

    const queryViewMode = ReportUtils.parseViewModeByQuery(query);
    return queryViewMode;
  }

  get adminReportsPerPage() {
    return 50;
  }
}

export const appSettings = new AppSettings();
