import { injectable } from 'inversify';
import { IIndividualUserListAdapterService } from '~/services/adapters/individual/individualAdapterService.interfaces';
import { IndividualUserListDTO } from '~/types/dto/individual/userListDTO';
import { IndividualUserList } from '~/types/individual/userList';

@injectable()
export class IndividualUserListAdapterService implements IIndividualUserListAdapterService {
  getData(initData: IndividualUserList[]): IndividualUserListDTO[] {
    return (initData ?? []).map((item) => ({
      ListName: item.ListName,
      ListType: item.ListType
    }));
  }
}
