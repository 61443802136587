import { inject, injectable } from 'inversify';
import {
  IReportConnectionRelatedChainService,
  IReportConnectionRelatedEntitiesAdapterService,
  IReportConnectionRelatedEntitiesAdapterServiceKey,
  IReportConnectionRelatedRelationsAdapterService,
  IReportConnectionRelatedRelationsAdapterServiceKey
} from '~/services/adapters/common/reportAdapterService.interfeces';
import { ReportConnectionRelatedChain } from '~/types/common/reportCompanyRelatedChain';
import { ReportConnectionRelatedChainDTO } from '~/types/dto/common/connectionRelatedChainDTO';

@injectable()
export class ReportConnectionRelatedChainService implements IReportConnectionRelatedChainService {
  constructor(
    @inject(IReportConnectionRelatedEntitiesAdapterServiceKey as symbol) private reportConnectionRelatedEntitiesAdapterService: IReportConnectionRelatedEntitiesAdapterService,
    @inject(IReportConnectionRelatedRelationsAdapterServiceKey as symbol) private reportConnectionRelatedRelationsAdapterService: IReportConnectionRelatedRelationsAdapterService
  ) {}
  getData(initDta: Array<ReportConnectionRelatedChain>): Array<ReportConnectionRelatedChainDTO> {
    return (initDta ?? []).map((item) => ({
      Entities: this.reportConnectionRelatedEntitiesAdapterService.getData(item.Entities),
      Relations: this.reportConnectionRelatedRelationsAdapterService.getData(item.Relations)
    }));
  }
}
