import { injectable } from 'inversify';
import { get } from 'lodash-es';
import { IRatioOfTotalDebtToProfitFromSalesItemConfigService } from '~/modules/legalEntities/financialAnalytics/legalEntitiesFinancialStability/services/legalEntitiesFinancialStabilityTableItemConfigService.interfaces';
import { TableItem } from '~/modules/table/types/tableItem';
import { LegalEntitiesReportFinancialIndicatorWithConclusionsDTO } from '~/types/dto/legalEntities/reportFinancialIndicatorWithConclusionsDTO';
import { NumberUtils } from '~/utils/number';
import { FINANCIAL_STABILITY_TITLES } from '~/modules/legalEntities/financialAnalytics/common/configuration/constants/financialStabilityTitles';

@injectable()
export class RatioOfTotalDebtToProfitFromSalesItemConfigService implements IRatioOfTotalDebtToProfitFromSalesItemConfigService {
  getItem(initData: LegalEntitiesReportFinancialIndicatorWithConclusionsDTO): TableItem {
    return {
      TwoYearsAgo: { value: NumberUtils.formatNumberDigits(initData.TwoYearsAgo, 2, '—'), color: this.getColor(initData.TwoYearsAgo) },
      LastYear: { value: NumberUtils.formatNumberDigits(initData.LastYear, 2, '—'), color: this.getColor(initData.LastYear) },
      CurrentYear: { value: NumberUtils.formatNumberDigits(initData.CurrentYear, 2, '—'), color: this.getColor(initData.CurrentYear) },

      Conclusions: get(initData.Conclusions, 0, '—'),
      indicator: {
        title: FINANCIAL_STABILITY_TITLES.RatioOfTotalDebtToProfitFromSales,
      },
      referenceValue: 'от 0 до 5',
    };
  }

  private getColor(value: number): string {
    return (value > 0 && value < 5) ? 'black' : 'red';
  }
}
