import { injectable, inject } from 'inversify';

import {
  IPlaceholderDataService,
  IReportConnectionRelatedPlaceholderDataServiceKey,
  IReportUrlPlaceholderServiceKey
} from '../common/reportPlaceholderService.interfaces';
import { ReportUrl } from '~/types/common/reportUrl';
import { LegalEntitiesActivitySummary } from '~/types/legal-entities/activitySummary';
import { LegalEntitiesBriefInfo } from '~/types/legal-entities/briefInfo';
import { LegalEntitiesCompanyActualAddress } from '~/types/legal-entities/companyActualAddress';
import { LegalEntitiesConditionAssessment } from '~/types/legal-entities/conditionAssessment';
import { LegalEntitiesLesseeSummary } from '~/types/legal-entities/lesseeSummary';
import { LegalEntitiesRegistrarOfShareholders } from '~/types/legal-entities/registrarOfShareholders';
import { LegalEntitiesTaxMode } from '~/types/legal-entities/taxMode';
import { LegalEntitiesTaxServiceInfo } from '~/types/legal-entities/taxServiceInfo';
import { ReportConnectionRelated } from '~/types/common/reportConnectionRelated';
import {
  ILegalEntitiesTaxServicePlaceholderServiceKey,
  ILegalEntitiesCompanyActualAddressPlaceholderServiceKey,
  ILegalEntitiesConditionAssessmentPlaceholderDataServiceKey,
  ILegalEntitiesTaxModePlaceholderDataServiceKey,
  ILegalEntitiesRegistrarOfShareholdersPlaceholderDataServiceKey,
  ILegalEntitiesLesseeSummaryPlaceholderDataServiceKey,
  ILegalEntitiesActivitySummaryPlaceholderDataServiceKey
} from './legalEntitiesPlaceholderDataService.interfaces';

@injectable()
export class LegalEntitiesBriefInfoPlaceholderService implements IPlaceholderDataService<LegalEntitiesBriefInfo> {
  constructor(
    @inject(IReportUrlPlaceholderServiceKey as symbol) private reportUrlPlaceholderService: IPlaceholderDataService<ReportUrl>,
    @inject(ILegalEntitiesTaxServicePlaceholderServiceKey as symbol) private taxServicePlaceholderService: IPlaceholderDataService<LegalEntitiesTaxServiceInfo>,
    @inject(ILegalEntitiesCompanyActualAddressPlaceholderServiceKey as symbol) private companyActualAddressPlaceholderService: IPlaceholderDataService<LegalEntitiesCompanyActualAddress>,
    @inject(ILegalEntitiesConditionAssessmentPlaceholderDataServiceKey as symbol) private conditionAssessmentPlaceholderData: IPlaceholderDataService<LegalEntitiesConditionAssessment>,
    @inject(ILegalEntitiesTaxModePlaceholderDataServiceKey as symbol) private taxModePlaceholderDataService: IPlaceholderDataService<LegalEntitiesTaxMode>,
    @inject(ILegalEntitiesRegistrarOfShareholdersPlaceholderDataServiceKey as symbol) private registrarOfShareholdersPlaceholderDataService: IPlaceholderDataService<LegalEntitiesRegistrarOfShareholders>,
    @inject(ILegalEntitiesLesseeSummaryPlaceholderDataServiceKey as symbol) private lesseeSummaryPlaceholderDataService:IPlaceholderDataService<LegalEntitiesLesseeSummary>,
    @inject(ILegalEntitiesActivitySummaryPlaceholderDataServiceKey as symbol) private activitySummaryPlaceholderDataService:IPlaceholderDataService<LegalEntitiesActivitySummary>,
    @inject(IReportConnectionRelatedPlaceholderDataServiceKey as symbol) private reportConnectionRelatedPlaceholderDataService: IPlaceholderDataService<ReportConnectionRelated>,
  ) {}

  getData(): LegalEntitiesBriefInfo {
    return {
      CompanyTaxRegimes: [],
      BankAccounts: [],
      CompanyStatuses: [],
      SitesLinks: [],
      Sites: [],
      SitesUrl: this.reportUrlPlaceholderService.getData(),
      Phones: [],
      PhonesUrl: this.reportUrlPlaceholderService.getData(),
      EmailsUrl: this.reportUrlPlaceholderService.getData(),
      EmailsLinks: [],
      Emails: [],
      SocialsUrl: this.reportUrlPlaceholderService.getData(),
      Socials: [],
      HtagsUrl: this.reportUrlPlaceholderService.getData(),
      Htags: [],
      CompanyRegistrationDate: '',
      CompanyLiquidationDate: '',
      CompanyAgeInYears: 0,
      DateGetOgrn: '',
      RegistrationTaxService: this.taxServicePlaceholderService.getData(),
      CurrentTaxService: this.taxServicePlaceholderService.getData(),
      CompanyStatedCapitalSum: 0,
      HistoryOfCompanyStatedCapital: [],
      CompanyMainActivityText: '',
      CompanyAddress: this.companyActualAddressPlaceholderService.getData(),
      CompanyAddressStory: [],
      CompanyFlHeads: [],
      HistoryOfCompanyHeads: [],
      ManagementCompanies: [],
      HistoryOfManagementCompanies: [],
      HistoryOfCompanyFounders: [],
      CompanyFounders: [],
      CompanyStatus: '',
      CompanyStatusDate: '',
      EmployeeAvgCount: 0,
      MspCategory: '',
      SubCompaniesCount: 0,
      FilialsCount: 0,
      IscClaims: 0,
      IscObligation: 0,
      FsspDept: 0,
      CourtCasesByYearsPlaintiff: [],
      CourtCasesByYearsDefendant: [],
      CourtCasesByYearsThirdParty: [],
      GovernmentControl: [],
      GovernmentControlAllUrl: this.reportUrlPlaceholderService.getData(),
      NonCompleatedCasesBySubject: [],
      CompleatedCasesBySubject: [],
      ConditionAssessment: this.conditionAssessmentPlaceholderData.getData(),
      RelatedCompanies: [],
      EgrulRelations: [],
      ConflictSigns: [],
      TaxesAndFees: [],
      TaxesPenalties: [],
      FinesAndTaxDebts: [],
      TaxMode: this.taxModePlaceholderDataService.getData(),
      ContractsInfoByParticipator: [],
      ContractsInfoByCustomer: [],
      ContractsInfoLinks: [],
      LeasingLinks: [],
      CaseLinks: [],
      Filials: [],
      TaxesAndFeesAllUrl: this.reportUrlPlaceholderService.getData(),
      ConflictSignsGrafUrl: this.reportUrlPlaceholderService.getData(),
      Predecessors: [],
      Successors: [],
      ReorganisationMembers: [],
      RegistrarOfShareholders: this.registrarOfShareholdersPlaceholderDataService.getData(),
      Shareholders: [],
      HistoryOfShareholders: [],
      EgrRecords: [],
      CertificatesSummary: [],
      LicensesSummary: [],
      LesseeSummary: this.lesseeSummaryPlaceholderDataService.getData(),
      ActivitySummary: this.activitySummaryPlaceholderDataService.getData(),
      SelfRegulatoryOrganizations: [],
      Trademarks: [],
      RelationsWithCounterparties: this.reportConnectionRelatedPlaceholderDataService.getData(),
      RelationsWithCompetitors: this.reportConnectionRelatedPlaceholderDataService.getData(),
      RelationsWithBlacklisted: this.reportConnectionRelatedPlaceholderDataService.getData(),
      RelationsWithEmployees: this.reportConnectionRelatedPlaceholderDataService.getData(),
      FoundRelations: this.reportConnectionRelatedPlaceholderDataService.getData(),
      HistoryOfCompanyNames: [],
      PensionInsuranceFundRecords: [],
      SocialInsuranceFundRecords: [],
      MedicalInsuranceFundRecords: [],
      Participations: [],
      RelatedEmployees: [],
      Vehicles: [],
      VehicleParkings: [],
      ChangeAverageNumberEmployee: [],
    };
  }
}
