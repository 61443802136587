import { injectable } from 'inversify';
import { orderBy } from 'lodash-es';
import { IReportEducationAdapterService } from '~/services/adapters/common/reportAdapterService.interfeces';
import { ReportEducation } from '~/types/common/reportEducation';
import { ReportEducationDTO } from '~/types/dto/common/reportEducationDTO';
import { StringUtils } from '~/utils/stringUtils';
import { DateUtils } from '~/utils/date';

@injectable()
export class ReportEducationAdapterService implements IReportEducationAdapterService {
  getData(initData: ReportEducation[]): ReportEducationDTO[] {
    const education = (initData ?? []).map((item) => ({
      EducationType: StringUtils.quoteReplacement(item.EducationType),
      EducationName: StringUtils.quoteReplacement(item.EducationName),
      FacultyName: StringUtils.quoteReplacement(item.FacultyName ?? ''),
      SpecialtyName: StringUtils.quoteReplacement(item.SpecialtyName ?? ''),
      EducationForm: StringUtils.quoteReplacement(item.EducationForm ?? ''),
      DiplomaDate: DateUtils.isValidFormatDate(item.DiplomaDate ?? '') ? DateUtils.formatDate(item.DiplomaDate) : '',
      DiplomaSeries: StringUtils.quoteReplacement(item.DiplomaSeries ?? ''),
      DiplomaNumber: StringUtils.quoteReplacement(item.DiplomaNumber ?? '')
    }));
    return orderBy(education, ['DiplomaDate'], ['desc']);
  }
}
