import { IContainerBinder } from '~/configuration/inversify/containerAccessor.interface';
import { IndividualEntrepreneursConfigService } from '../../services/individualEntrepreneursConfigService';
import { IIndividualEntrepreneursConfigServiceKey } from '../../services/individualEntrepreneursConfigService.interface';
import { IndividualEntrepreneursStatusColorService } from '~/modules/individual/lifePath/individualEntrepreneurs/services/individualEntrepreneursStatusColorService';
import { IIndividualEntrepreneursStatusColorServiceKey } from '~/modules/individual/lifePath/individualEntrepreneurs/services/individualEntrepreneursStatusColorService.interface';

export function bindIndividualEntrepreneursServices(bind: IContainerBinder) {
  bind(IIndividualEntrepreneursConfigServiceKey).to(IndividualEntrepreneursConfigService);

  bind(IIndividualEntrepreneursStatusColorServiceKey).to(IndividualEntrepreneursStatusColorService);
}
