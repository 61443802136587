import { injectable } from 'inversify';
import { IReportUrlAdapterService } from '~/services/adapters/common/reportAdapterService.interfeces';
import { StringUtils } from '~/utils/stringUtils';
import { ReportUrl } from '~/types/common/reportUrl';
import { ReportUrlDTO } from '~/types/dto/common/reportUrlDTO';

@injectable()
export class ReportUrlAdapterService implements IReportUrlAdapterService {
  getData(initData: ReportUrl): ReportUrlDTO {
    return {
      Text: StringUtils.quoteReplacement(initData.Text ?? ''),
      Value: StringUtils.quoteReplacement(initData.Value ?? '')
    };
  }
}
