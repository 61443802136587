import { injectable } from 'inversify';
import ISplittable from '~/types/splittables/splittable';
import { IFakeSplittablesFactory } from './fakeSplittablesFactory.interfaces';
import FakeSplittableContext from '~/models/splittables/contexts/fakeSplittableContext';
import { CompositeNodeSizeSnapshot } from '~/models/nodes/snapshots/compositeNodeSizeSnapshot';

@injectable()
export default class FakeSplittablesFactory implements IFakeSplittablesFactory {
  create<T>(
    snapshot: CompositeNodeSizeSnapshot,
    data: T | undefined
  ): ISplittable<T> {
    return {
      async createContextAsync() {
        return new FakeSplittableContext(snapshot, data);
      }
    };
  }
}
