/* eslint-disable class-methods-use-this, max-len */

import { injectable } from 'inversify';
import { LegalEntitiesCompanyFounderShareDTO } from '~/types/dto/legalEntities/companyFounderShare';
import { LegalEntitiesCompanyFounderShare } from '~/types/legal-entities/companyFounderShare';
import {
  ILegalEntitiesCompanyFounderShareAdapterService
} from '~/services/adapters/legalEntities/legalEntitiesAdapterService.interfaces';

@injectable()
export class LegalEntitiesCompanyFounderShareAdapterService implements ILegalEntitiesCompanyFounderShareAdapterService {
  getData(initData: LegalEntitiesCompanyFounderShare): LegalEntitiesCompanyFounderShareDTO {    
    return {
      Sum: initData.Sum ?? 0,
      PercentagePlain: initData.PercentagePlain ?? 0,
      PercentageNominator: initData.PercentageNominator ?? 0,
      PercentageDenominator: initData.PercentageDenominator ?? 0
    };
  }
}
