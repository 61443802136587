import { injectable } from 'inversify';
import { isEqual } from 'lodash-es';
import { MergeService } from '~/services/merge/merge-service';
import { LegalEntitiesCompanyPhoneDTO } from '~/types/dto/legalEntities/companyPhoneDTO';

@injectable()
export class LegalEntitiesPhonesMergeService extends MergeService<LegalEntitiesCompanyPhoneDTO> {
  protected canMerge(leftItem: LegalEntitiesCompanyPhoneDTO, rightItem: LegalEntitiesCompanyPhoneDTO): boolean {
    return isEqual(leftItem, rightItem);
  }

  protected mergeFields(leftItem: LegalEntitiesCompanyPhoneDTO, rightItem: LegalEntitiesCompanyPhoneDTO): LegalEntitiesCompanyPhoneDTO {
    return {
      Phone: leftItem.Phone || rightItem.Phone,
      Name: leftItem.Name || rightItem.Name,
      Hashtags: leftItem.Hashtags || rightItem.Hashtags,
      CompaniesWithThisPhone: leftItem.CompaniesWithThisPhone || rightItem.CompaniesWithThisPhone,
    };
  }
}
