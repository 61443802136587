import { IContainerBinder } from '~/configuration/inversify/containerAccessor.interface';
import {
  IDebtEbitTableItemConfigServiceKey,
  IDebtShareTableItemConfigServiceKey,
  IEbitPercentTableItemConfigServiceKey,
  IEbitTableItemConfigServiceKey,
  IProfitFromSalesTableItemConfigServiceKey,
  IRevenuesTableItemConfigServiceKey
} from '~/modules/legalEntities/financialAnalytics/legalEntitiesFinancialAnalysisShort/services/legalEntitiesFinancialAnalysisShortTableItemConfigService.interfaces';
import { RevenuesTableItemConfigService } from '~/modules/legalEntities/financialAnalytics/legalEntitiesFinancialAnalysisShort/services/revenuesTableItemConfigService';
import { ProfitFromSalesTableItemConfigService } from '~/modules/legalEntities/financialAnalytics/legalEntitiesFinancialAnalysisShort/services/profitFromSalesTableItemConfigService';
import { EbitTableItemConfigService } from '~/modules/legalEntities/financialAnalytics/legalEntitiesFinancialAnalysisShort/services/ebitTableItemConfigService';
import { DebtShareTableItemConfigService } from '~/modules/legalEntities/financialAnalytics/legalEntitiesFinancialAnalysisShort/services/debtShareTableItemConfigService';
import { DebtEbitTableItemConfigService } from '~/modules/legalEntities/financialAnalytics/legalEntitiesFinancialAnalysisShort/services/debtEbitTableItemConfigService';
import { EbitPercentTableItemConfigService } from '~/modules/legalEntities/financialAnalytics/legalEntitiesFinancialAnalysisShort/services/ebitPercentTableItemConfigService';
import { LegalEntitiesFinancialAnalysisShortTableConfigService } from '~/modules/legalEntities/financialAnalytics/legalEntitiesFinancialAnalysisShort/services/legalEntitiesFinancialAnalysisShortTableConfigService';
import { ILegalEntitiesFinancialAnalysisShortTableConfigServiceKey } from '~/modules/legalEntities/financialAnalytics/legalEntitiesFinancialAnalysisShort/services/legalEntitiesFinancialAnalysisShortTableConfigService.interfaces';

export function bindLegalEntitiesFinancialAnalysisShortServices(bind: IContainerBinder) {
  bind(IRevenuesTableItemConfigServiceKey).to(RevenuesTableItemConfigService);
  bind(IProfitFromSalesTableItemConfigServiceKey).to(ProfitFromSalesTableItemConfigService);
  bind(IEbitTableItemConfigServiceKey).to(EbitTableItemConfigService);
  bind(IDebtShareTableItemConfigServiceKey).to(DebtShareTableItemConfigService);
  bind(IDebtEbitTableItemConfigServiceKey).to(DebtEbitTableItemConfigService);
  bind(IEbitPercentTableItemConfigServiceKey).to(EbitPercentTableItemConfigService);

  bind(ILegalEntitiesFinancialAnalysisShortTableConfigServiceKey).to(LegalEntitiesFinancialAnalysisShortTableConfigService);
}
