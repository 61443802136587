import { injectable } from 'inversify';
import { get } from 'lodash-es';
import { IRatioOfConcentrationOfEquityItemConfigService } from '~/modules/legalEntities/financialAnalytics/legalEntitiesFinancialStability/services/legalEntitiesFinancialStabilityTableItemConfigService.interfaces';
import { LegalEntitiesReportFinancialIndicatorWithConclusionsDTO } from '~/types/dto/legalEntities/reportFinancialIndicatorWithConclusionsDTO';
import { TableItem } from '~/types/tableItem';
import { NumberUtils } from '~/utils/number';
import { FINANCIAL_STABILITY_TITLES } from '~/modules/legalEntities/financialAnalytics/common/configuration/constants/financialStabilityTitles';

@injectable()
export class RatioOfConcentrationOfEquityItemConfigService implements IRatioOfConcentrationOfEquityItemConfigService {
  getItem(initData: LegalEntitiesReportFinancialIndicatorWithConclusionsDTO): TableItem {
    return {
      TwoYearsAgo: { value: NumberUtils.formatNumberDigits(initData.TwoYearsAgo, 2, '—'), color: this.getColor(initData.TwoYearsAgo) },
      LastYear: { value: NumberUtils.formatNumberDigits(initData.LastYear, 2, '—'), color: this.getColor(initData.LastYear) },
      CurrentYear: { value: NumberUtils.formatNumberDigits(initData.CurrentYear, 2, '—'), color: this.getColor(initData.CurrentYear) },
      Conclusions: get(initData.Conclusions, 0, '—'),
      indicator: {
        title: FINANCIAL_STABILITY_TITLES.RatioOfConcentrationOfEquity,
        subtitle: 'Cоотношение собственного капитала к общей сумме источников средств (краткорочных и долгосрочных)'
      },
      referenceValue: '> 0,5',
    };
  }

  private getColor(value: number): string {
    if (value > 0.5) return 'green';
    if (value < 0.5 && value > 0.3) return 'yellow';
    return 'red';
  }
}
