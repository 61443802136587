import { APIReportType, UIReportType } from '~/types/reportType';
import { StringMap } from '~/types/stringMap';

export const reportRoutes: StringMap<string> = {
  [UIReportType.ul]: '/report/legal-entities',
  [UIReportType.fl]: '/report/individual',
  [UIReportType.phoneNumber]: '/report/phone-number',
  [UIReportType.vehicle]: '/report/vehicle'
};

export const reportRoutesFromAPI: StringMap<string> = {
  [APIReportType.company]: '/report/legal-entities',
  [APIReportType.person]: '/report/individual',
  [APIReportType.phoneNumber]: '/report/phone-number',
  [APIReportType.carNumber]: '/report/vehicle',
};

export const reportAPIFromRoutes: StringMap<number> = {
  '/report/legal-entities': APIReportType.company,
  '/report/individual': APIReportType.person,
  '/report/phone-number': APIReportType.phoneNumber,
  '/report/vehicle': APIReportType.carNumber
}