import { injectable } from 'inversify';
import { IReportTaxiMovementsConfigService } from '~/modules/reportsCommon/reportMovement/common/services/reportMovementsConfigService.interfaces';
import { MOVEMENTS_FIELD_LIST_OPTIONS } from '~/modules/reportsCommon/reportMovement/common/configuration/constants';
import { Config } from '~/types/field-list/config';
import { ReportMovementTaxiDTO } from '~/types/dto/common/reportMovementTaxiDTO';

@injectable()
export class ReportTaxiMovementsConfigService implements IReportTaxiMovementsConfigService {
  getConfig(taxiMovements: ReportMovementTaxiDTO): Config {
    return {
      ...MOVEMENTS_FIELD_LIST_OPTIONS,
      fields: [
        { 
          key: 'MovementDate', 
          label: 'Дата поездки', 
          value: taxiMovements.MovementDate 
        }
      ]
    };
  }
}
