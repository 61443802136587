import { injectable, inject } from 'inversify';
import { ReportEmail } from '~/types/common/reportEmail';
import { IPrimitiveTypesNormalizationServiceKey, IPrimitiveTypesNormalizationService } from './primitiveTypesNormalizationService.interfaces';
import { INormalizationService } from './reportNormalizationService.interfaces';

@injectable()
export class ReportEmailNormalizationService implements INormalizationService<ReportEmail> {
  constructor(
    @inject(IPrimitiveTypesNormalizationServiceKey as symbol) private primitiveTypesNormalizationService: IPrimitiveTypesNormalizationService,
  ) {}

  normalize(data: any, root: string): ReportEmail | undefined {
    const {
      normalizeObject,
      normalizeString,
      normalizeNumber,
      normalizeOptionalBoolean
    } = this.primitiveTypesNormalizationService;

    return normalizeObject(data, (data) => ({
      Value: normalizeString(data.Value, `${root}.Value`),
      VerificationCode: normalizeNumber(data.VerificationCode, `${root}.VerificationCode`),
      Type: normalizeNumber(data.Type, `${root}.Type`),
      IsSuitableForSending: normalizeOptionalBoolean(data.IsSuitableForSending, `${root}.IsSuitableForSending`),
    }), root);
  }
}
