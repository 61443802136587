import { injectable, inject } from 'inversify';

import { LegalEntitiesReportAverageIndustryIndicator } from '~/types/legal-entities/reportAverageIndustryIndicator';
import { IPrimitiveTypesNormalizationServiceKey, IPrimitiveTypesNormalizationService } from '../common/primitiveTypesNormalizationService.interfaces';
import { INormalizationService } from '../common/reportNormalizationService.interfaces';

@injectable()
export class LegalEntitiesReportAverageIndustryIndicatorNormalizationService implements INormalizationService<LegalEntitiesReportAverageIndustryIndicator> {
  constructor(
    @inject(IPrimitiveTypesNormalizationServiceKey as symbol) private primitiveTypesNormalizationService: IPrimitiveTypesNormalizationService,
  ) {}

  normalize(data: any, root: string): LegalEntitiesReportAverageIndustryIndicator | undefined {
    const { normalizeOptionalInteger, normalizeObject } = this.primitiveTypesNormalizationService;

    return normalizeObject(data, (data) => ({
      CurrentYear: normalizeOptionalInteger(data.CurrentYear, `${root}.CurrentYear`),
      AverageIndustry: normalizeOptionalInteger(data.AverageIndustry, `${root}.AverageIndustry`),
      Deviation: normalizeOptionalInteger(data.Deviation, `${root}.Deviation`),
    }), root);
  }
}
