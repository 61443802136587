import { inject, injectable } from 'inversify';
import { get } from 'lodash-es';
import { IInterestPeriodTableIDataService } from '~/modules/legalEntities/financialAnalytics/common/services/interestPeriodTableIDataService.interfaces';
import { LegalEntitiesReportFinancialIndicatorWithDynamicDTO } from '~/types/dto/legalEntities/reportFinancialIndicatorWithDynamicDTO';
import { InterestPeriodTableIData } from '~/modules/legalEntities/financialAnalytics/common/types/interestPeriodTableIData';
import {
  IConvertingPrimitiveTableTypes,
  IConvertingPrimitiveTableTypesKey
} from '~/modules/legalEntities/financialAnalytics/common/services/convertingPrimitiveTableTypes.interfaces';
import { NumberUtils } from '~/utils/number';

@injectable()
export class InterestPeriodTableIDataService implements IInterestPeriodTableIDataService {
  constructor(
    @inject(IConvertingPrimitiveTableTypesKey as symbol) private convertingPrimitiveTableTypes: IConvertingPrimitiveTableTypes,
  ) {}

  getDataItem(initData: LegalEntitiesReportFinancialIndicatorWithDynamicDTO, color: (value: number) => string): InterestPeriodTableIData {
    const maxValue = NumberUtils.getMaxAbs([initData.TwoYearsAgo, initData.CurrentYear, initData.LastYear]);

    return {
      TwoYearsAgo: { value: this.convertingPrimitiveTableTypes.currencyConversion(initData.TwoYearsAgo, maxValue), color: 'black' },
      CurrentYear: { value: this.convertingPrimitiveTableTypes.currencyConversion(initData.CurrentYear, maxValue), color: 'black' },
      LastYear: { value: this.convertingPrimitiveTableTypes.currencyConversion(initData.LastYear, maxValue), color: 'black' },
      TwoYearsAgoToLastYear: { value: this.convertingPrimitiveTableTypes.percentageConversion(initData.TwoYearsAgoToLastYear, true), color: color((initData.TwoYearsAgoToLastYear ?? 0) * 100) },
      LastYearToCurrentYear: { value: this.convertingPrimitiveTableTypes.percentageConversion(initData.LastYearToCurrentYear, true), color: color((initData.LastYearToCurrentYear ?? 0) * 100) },
      Conclusions: get(initData.Conclusions, 0, '—'),
    };
  }
}
