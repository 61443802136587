import { injectable } from 'inversify';
import {
  IIndividualMovementsBusConfigService
} from '~/modules/reportsCommon/reportMovement/individual/services/individualMovementsConfigService.interfaces';
import { IndividualMovementBusDTO } from '~/types/dto/individual/movementBusDTO';
import { MOVEMENTS_FIELD_LIST_OPTIONS } from '~/modules/reportsCommon/reportMovement/common/configuration/constants';

@injectable()
export class IndividualMovementsBusConfigService implements IIndividualMovementsBusConfigService {
  getConfig(busMovements: IndividualMovementBusDTO) {
    return {
      ...MOVEMENTS_FIELD_LIST_OPTIONS,
      fields: [
        {
          key: 'MovementDate',
          label: 'Дата поездки',
          value: busMovements.MovementDate 
        }
      ]
    };
  }
}
