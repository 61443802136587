import { InjectionKey } from 'vue';
import { IConfigService } from '~/services/configService.interface';
import { ReportFineDTO } from '~/types/dto/common/fineDTO';

export type IFineGeneralInfoConfigService = IConfigService<Array<ReportFineDTO>>;
export type IFinePrivateCashInfoConfigService = IConfigService<ReportFineDTO>;
export type IFinePrivateArticleInfoConfigService = IConfigService<ReportFineDTO>;

export const IFineGeneralInfoConfigServiceKey: InjectionKey<IFineGeneralInfoConfigService> = Symbol('IFineGeneralInfoConfigService');
export const IFinePrivateCashInfoConfigServiceKey: InjectionKey<IFinePrivateCashInfoConfigService> = Symbol('IFinePrivateCashInfoConfigService');

export const IFinePrivateArticleInfoConfigServiceKey: InjectionKey<IFinePrivateArticleInfoConfigService> = Symbol('IFinePrivateArticleInfoConfigService');
