import { injectable } from 'inversify';
import {
  ILegalEntitiesReportFinancialIndicatorBaseAdapterService
} from '~/services/adapters/legalEntities/legalEntitiesAdapterService.interfaces';
import { LegalEntitiesReportFinancialIndicatorBase } from '~/types/legal-entities/reportFinancialIndicatorBase';
import { LegalEntitiesReportFinancialIndicatorBaseDTO } from '~/types/dto/legalEntities/reportFinancialIndicatorBaseDTO';

@injectable()
export class LegalEntitiesReportFinancialIndicatorBaseAdapterService implements ILegalEntitiesReportFinancialIndicatorBaseAdapterService {
  getData(initData: LegalEntitiesReportFinancialIndicatorBase): LegalEntitiesReportFinancialIndicatorBaseDTO {
    return {
      TwoYearsAgo: initData.TwoYearsAgo ?? 0,
      LastYear: initData.LastYear ?? 0,
      CurrentYear: initData.CurrentYear ?? 0
    };
  }
}
