import { injectable } from 'inversify';

import { LegalEntitiesLicenseSummary } from '~/types/legal-entities/licenseSummary';
import { IPlaceholderDataService } from '../common/reportPlaceholderService.interfaces';

@injectable()
export class LegalEntitiesLicenseSummaryPlaceholderDataService implements IPlaceholderDataService<LegalEntitiesLicenseSummary> {
  getData(): LegalEntitiesLicenseSummary {
    return {
      Activity: '',
      ArchiveCount: 0,
      ActualCount: 0,
    };
  }
}
