import { injectable } from 'inversify';
import { ReportLegalEntitiesParticipation } from '~/types/common/reportLegalEntitiesParticipation';
import { IPlaceholderDataService } from './reportPlaceholderService.interfaces';

@injectable()
export class ReportLegalEntitiesParticipationPlaceholderService implements IPlaceholderDataService<ReportLegalEntitiesParticipation> {
  getData(): ReportLegalEntitiesParticipation {
    return {
      ParticipationType: '',
      CompanyName: '',
      Inn: '',
      Ogrn: '',
      Status: '',
      PrincipalActivity: '',
      RiskIndicator: '',
      Address: '',
      RegistrationDate: '',
      LiquidationDate: '',
      Position: '',
      IsHistoric: false,
    };
  }
}
