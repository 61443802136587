import { defineStore } from 'pinia';
import { ref } from 'vue';
import { UIReportType } from '~/types/reportType';
import { FileUtils } from '~/utils/fileUtils';
import { GenericDataBlob } from '~/types/blobs/generic-data-blob';
import { useInjectable } from '~/hooks/useInjectable';
import { IDataServiceKey } from '~/services/data-service.interface';
import { IGenericDataBlobNormalizationServiceKey } from '~/services/normalization/blob/genericDataBlobNormalizationService.interfaces';
import { IMocksServiceKey } from '~/services/mocks-service.interfaces';

export function useDataStore<T extends GenericDataBlob>(
  reportType: UIReportType
) {
  return defineStore(`dataStore: ${reportType}`, () => {
    const dataService = useInjectable(IDataServiceKey);
    const genericDataNormalizationService = useInjectable(IGenericDataBlobNormalizationServiceKey);
    const data = ref<T>();
    const initializationTask = ref<Promise<void>>();

    function setData(value: T) {
      console.log('dataStore:set', value);
      data.value = value;

      onMounted(() => console.log(`datastore(${reportType}): value`, value));
    }

    async function update() {
      data.value = (await dataService.getDataAsync(reportType)) as T;
    }

    async function initialize() {
      if (initializationTask.value == undefined) {
        initializationTask.value = update();
      }

      return initializationTask.value;
    }

    async function loadMockFileByName(id: string) {
      const mocksService = useInjectable(IMocksServiceKey);
      const data = await mocksService.getMockDataByNameAsync(reportType, id);
      const normalizedData = genericDataNormalizationService.normalize(data, reportType);

      if (normalizedData != undefined)
        setData(normalizedData as T);
      else
        console.error('mock not found', id);
    }

    async function loadFileAsync(file: File) {
      try {
        const data = JSON.parse(
          await FileUtils.ReadFileContentAsync(file)
        ) as T;
        const normalizedData = genericDataNormalizationService.normalize(data, reportType);
        setData(normalizedData as T);
      } catch (e) {
        console.error(e);
      }
    }

    return {
      data,
      initialize,
      loadMockFileById: loadMockFileByName,
      loadFileAsync
    };
  })();
}
