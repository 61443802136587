import { injectable, inject } from 'inversify';
import { CommonEmailsMergeService } from '~/services/merge/commonEmailsMergeService';
import { ICommonEmailsMergeServiceKey } from '~/services/merge/commonEmailsMergeService.interfaces';
import { MergeService } from '~/services/merge/merge-service';
import { ReportTagMergeService } from '~/services/merge/reportTagMergeService';
import { IReportTagMergeServiceKey } from '~/services/merge/reportTagMergeService.interfaces';
import { ReportBoundedPersonPhoneDTO } from '~/types/dto/common/boundedPersonPhoneDTO';
import { StringUtils } from '~/utils/stringUtils';

@injectable()
export class ReportBoundedPhonesMergeService extends MergeService<ReportBoundedPersonPhoneDTO> {
  constructor(
    @inject(IReportTagMergeServiceKey as symbol) private reportTagMergeService: ReportTagMergeService,
    @inject(ICommonEmailsMergeServiceKey as symbol) private commonEmailsMergeService: CommonEmailsMergeService,
  ) {
    super();
  }
  
  protected canMerge(leftItem: ReportBoundedPersonPhoneDTO, rightItem: ReportBoundedPersonPhoneDTO): boolean {
    return StringUtils.cyrillicToTranslit(leftItem.Value) === StringUtils.cyrillicToTranslit(rightItem.Value)
     && leftItem.FullName === rightItem.FullName;
  }

  protected mergeFields(leftItem: ReportBoundedPersonPhoneDTO, rightItem: ReportBoundedPersonPhoneDTO): ReportBoundedPersonPhoneDTO {
    const mergedTags = this.reportTagMergeService.merge([...(leftItem.Tags || []), ...(rightItem.Tags ?? [])]);
    const mergedEmails = this.commonEmailsMergeService.merge([...leftItem.Emails, ...rightItem.Emails]);

    return {
      Value: leftItem.Value || rightItem.Value,
      Source: leftItem.Source || rightItem.Source,
      SourceStr: leftItem.SourceStr || rightItem.SourceStr,
      Tag: leftItem.Tag || rightItem.Tag,
      Tags: mergedTags,
      FullName: leftItem.FullName || rightItem.FullName,
      RelatedBirth: leftItem.RelatedBirth || rightItem.RelatedBirth,
      RelatedAddress: leftItem.RelatedAddress || rightItem.RelatedAddress,
      Emails: mergedEmails,
      OriginalRequestData: leftItem.OriginalRequestData ?? rightItem.OriginalRequestData
    };
  }
}
