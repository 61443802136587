import { injectable, inject } from 'inversify';
import { ReportIndicatorsOfCashFlow } from '~/types/blobs/report-indicators-of-cash-flow';
import { LegalEntitiesFinancialAnalysisExtended } from '~/types/legal-entities/financialAnalysisExtended';
import { ReportAnalysisOfAbsoluteLiquidity } from '~/types/legal-entities/reportAnalysisOfAbsoluteLiquidity';
import { LegalEntitiesReportFinancialStability } from '~/types/legal-entities/reportFinancialStability';
import { ReportIndicatorsOfProfitability } from '~/types/legal-entities/reportIndicatorsOfProfitability';
import { LegalEntitiesReportLiquidityAndSolvency } from '~/types/legal-entities/reportLiquidityAndSolvency';
import { ILegalEntitiesReportFinancialStabilityNormalizationServiceKey, ILegalEntitiesReportLiquidityAndSolvencyNormalizationServiceKey } from './legalEntitiesNormalizationService.interfaces';
import { ILegalEntitiesReportLiquidityAndSolvencyPlaceholderDataServiceKey, ILegalEntitiesReportFinancialStabilityPlaceholderDataServiceKey } from '~/services/placeholder/legalEntities/legalEntitiesPlaceholderDataService.interfaces';
import { IPrimitiveTypesNormalizationServiceKey, IPrimitiveTypesNormalizationService } from '../common/primitiveTypesNormalizationService.interfaces';
import { IPlaceholderDataService, IReportAnalysisOfAbsoluteLiquidityPlaceholderServiceKey, IReportIndicatorsOfProfitabilityPlaceholderServiceKey, IReportIndicatorsOfCashFlowPlaceholderServiceKey } from '~/services/placeholder/common/reportPlaceholderService.interfaces';
import { INormalizationService, IReportAnalysisOfAbsoluteLiquidityNormalizationServiceKey, IReportIndicatorsOfProfitabilityNormalizationServiceKey, IReportIndicatorsOfCashFlowNormalizationServiceKey } from '../common/reportNormalizationService.interfaces';

@injectable()
export class LegalEntitiesFinancialAnalysisExtendedNormalizationService implements INormalizationService<LegalEntitiesFinancialAnalysisExtended> {
  constructor(
    @inject(IPrimitiveTypesNormalizationServiceKey as symbol) private primitiveTypesNormalizationService: IPrimitiveTypesNormalizationService,

    @inject(ILegalEntitiesReportLiquidityAndSolvencyNormalizationServiceKey as symbol) private reportLiquidityAndSolvencyNormalizationService: INormalizationService<LegalEntitiesReportLiquidityAndSolvency>,
    @inject(ILegalEntitiesReportFinancialStabilityNormalizationServiceKey as symbol) private reportFinancialStabilityNormalizationServiceKey: INormalizationService<LegalEntitiesReportFinancialStability>,
    @inject(IReportAnalysisOfAbsoluteLiquidityNormalizationServiceKey as symbol) private reportAnalysisOfAbsoluteLiquidityNormalizationServiceKey: INormalizationService<ReportAnalysisOfAbsoluteLiquidity>,
    @inject(IReportIndicatorsOfProfitabilityNormalizationServiceKey as symbol) private reportIndicatorsOfProfitabilityNormalizationServiceKey: INormalizationService<ReportIndicatorsOfProfitability>,
    @inject(IReportIndicatorsOfCashFlowNormalizationServiceKey as symbol) private reportIndicatorsOfCashFlowNormalizationServiceKey: INormalizationService<ReportIndicatorsOfCashFlow>,

    @inject(ILegalEntitiesReportLiquidityAndSolvencyPlaceholderDataServiceKey as symbol) private reportLiquidityAndSolvencyPlaceholderDataService: IPlaceholderDataService<LegalEntitiesReportLiquidityAndSolvency>,
    @inject(ILegalEntitiesReportFinancialStabilityPlaceholderDataServiceKey as symbol) private reportFinancialStabilityPlaceholderDataService: IPlaceholderDataService<LegalEntitiesReportFinancialStability>,
    @inject(IReportAnalysisOfAbsoluteLiquidityPlaceholderServiceKey as symbol) private reportAnalysisOfAbsoluteLiquidityPlaceholderService: IPlaceholderDataService<ReportAnalysisOfAbsoluteLiquidity>,
    @inject(IReportIndicatorsOfProfitabilityPlaceholderServiceKey as symbol) private reportIndicatorsOfProfitabilityPlaceholderService: IPlaceholderDataService<ReportIndicatorsOfProfitability>,
    @inject(IReportIndicatorsOfCashFlowPlaceholderServiceKey as symbol) private reportIndicatorsOfCashFlowPlaceholderService: IPlaceholderDataService<ReportIndicatorsOfCashFlow>,
  ) {}

  normalize(data: any, root: string): LegalEntitiesFinancialAnalysisExtended | undefined {
    const {
      normalizeBoolean,
      normalizeNumber,
      normalizeOptionalInteger,
      normalizeObject
    } = this.primitiveTypesNormalizationService;

    return normalizeObject(data, (data) => ({
      IsExists: normalizeBoolean(data.IsExists, `${root}.IsExists`),
      TwoYearsAgo: normalizeNumber(data.TwoYearsAgo, `${root}.TwoYearsAgo`),
      LastYear: normalizeOptionalInteger(data.LastYear, `${root}.LastYear`),
      CurrentYear: normalizeOptionalInteger(data.CurrentYear, `${root}.CurrentYear`),
      LiquidityAndSolvency: this.reportLiquidityAndSolvencyNormalizationService.normalize(data.LiquidityAndSolvency, `${root}.LiquidityAndSolvency`) ?? this.reportLiquidityAndSolvencyPlaceholderDataService.getData(),
      AnalysisOfAbsoluteLiquidity: this.reportAnalysisOfAbsoluteLiquidityNormalizationServiceKey.normalize(data.AnalysisOfAbsoluteLiquidity, `${root}.AnalysisOfAbsoluteLiquidity`) ?? this.reportAnalysisOfAbsoluteLiquidityPlaceholderService.getData(),
      FinancialStability: this.reportFinancialStabilityNormalizationServiceKey.normalize(data.FinancialStability, `${root}.FinancialStability`) ?? this.reportFinancialStabilityPlaceholderDataService.getData(),
      IndicatorsOfProfitability: this.reportIndicatorsOfProfitabilityNormalizationServiceKey.normalize(data.IndicatorsOfProfitability, `${root}.IndicatorsOfProfitability`) ?? this.reportIndicatorsOfProfitabilityPlaceholderService.getData(),
      IndicatorsOfCashFlow: this.reportIndicatorsOfCashFlowNormalizationServiceKey.normalize(data.IndicatorsOfCashFlow, `${root}.IndicatorsOfCashFlow`) ?? this.reportIndicatorsOfCashFlowPlaceholderService.getData(),
    }), root);
  }
}
