import { CompositeNodeSizeSnapshot } from '~/models/nodes/snapshots/compositeNodeSizeSnapshot';

export default class NodesPagingCell<T> {
  top: number;

  bottom: number;

  height: number;

  elements: Element[];

  data?: T;

  constructor(private snapshot: CompositeNodeSizeSnapshot, data: T | undefined) {
    this.top = snapshot.top;
    this.height = snapshot.height;
    this.bottom = this.top + this.height;
    this.elements = snapshot.elements;
    this.data = data;
  }
}
