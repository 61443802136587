/* eslint-disable class-methods-use-this, max-len */

import { injectable, inject } from 'inversify';
import { StringUtils } from '~/utils/stringUtils';
import { LegalEntitiesCompanyEmployeeInfo } from '~/types/legal-entities/companyEmployeeInfo';
import {
  ILegalEntitiesCompanyEmployeeInfoAdapterService,
} from '~/services/adapters/legalEntities/legalEntitiesAdapterService.interfaces';
import { LegalEntitiesCompanyEmployeeInfoDTO } from '~/types/dto/legalEntities/companyEmployeeInfoDTO';
import {
  IReportSocialNetworkIdAdapterService,
  IReportSocialNetworkIdAdapterServiceKey
} from '~/services/adapters/common/reportAdapterService.interfeces';

@injectable()
export class LegalEntitiesCompanyEmployeeInfoAdapterService implements ILegalEntitiesCompanyEmployeeInfoAdapterService {
  constructor(
    @inject(IReportSocialNetworkIdAdapterServiceKey as symbol) private reportSocialNetworkIdAdapterService: IReportSocialNetworkIdAdapterService
  ) {}
  getData(initData: LegalEntitiesCompanyEmployeeInfo[]): LegalEntitiesCompanyEmployeeInfoDTO[] {
    return (initData ?? []).map((companyEmployeeInfo) => ({
      FullName: StringUtils.quoteReplacement(companyEmployeeInfo.FullName ?? ''),
      PhoneNumber: companyEmployeeInfo.PhoneNumber ?? '',
      SocialNetworks: this.reportSocialNetworkIdAdapterService.getData(companyEmployeeInfo.SocialNetworks),
      Address: StringUtils.quoteReplacement(companyEmployeeInfo.Address ?? ''),
      BirthDate: companyEmployeeInfo.BirthDate ?? '',
      CarNumber: companyEmployeeInfo.CarNumber ?? '',
      Email: companyEmployeeInfo.Email ?? '',
      IdentityDocument: StringUtils.quoteReplacement(companyEmployeeInfo.IdentityDocument ?? ''),
      Inn: StringUtils.quoteReplacement(companyEmployeeInfo.Inn ?? ''),
      Snils: StringUtils.quoteReplacement(companyEmployeeInfo.Snils ?? ''),
    }));
  }
}
