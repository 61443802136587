import { injectable } from 'inversify';
import { IndividualMarkers } from '~/types/individual/markers';
import { IPlaceholderDataService } from '../common/reportPlaceholderService.interfaces';

@injectable()
export class IndividualMarkersPlaceholderService implements IPlaceholderDataService<IndividualMarkers> {
  getData(): IndividualMarkers {
    return {
      Markers: [],
      Rate: 0,
      RateIndicator: 0
    };
  }
}
