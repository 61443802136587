import { sortBy } from 'lodash-es';
import { injectable, inject } from 'inversify';
import { ReportRoadAccidentDTO } from '~/types/dto/common/reportRoadAccidentDTO';
import { ReportRoadAccident } from '~/types/common/reportRoadAccident';
import { DateUtils } from '~/utils/date';
import { StringUtils } from '~/utils/stringUtils';
import {
  IReportMainEntityFileUrlAdapterService,
  IReportMainEntityFileUrlAdapterServiceKey, IReportRoadAccidentAdapterService
} from '~/services/adapters/common/reportAdapterService.interfeces';

@injectable()
export class ReportRoadAccidentAdapterService implements IReportRoadAccidentAdapterService {
  constructor(
    @inject(IReportMainEntityFileUrlAdapterServiceKey as symbol) private reportMainEntityFileUrlAdapterService: IReportMainEntityFileUrlAdapterService,
  ) {}
  getData(initData: ReportRoadAccident[]): ReportRoadAccidentDTO[] {
    return sortBy(initData ?? [], o => o.Date ? Date.parse(o.Date) : -1)
      .reverse()
      .map((accident) => ({
        AccidentType: accident.AccidentType,
        Date: `${DateUtils.formatDate(accident.Date)}, ${DateUtils.getTime(accident.Date)}`,
        Region: StringUtils.quoteReplacement(accident.Region ?? ''),
        SchemePhoto: this.reportMainEntityFileUrlAdapterService.getData(accident.SchemePhotoUrl),
      }));
  }
}
