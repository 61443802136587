import { injectable } from 'inversify';
import { IIndividualFinancialScoringAdapterService } from '~/services/adapters/individual/individualAdapterService.interfaces';
import { IndividualFinancialScoring } from '~/types/individual/financialScoring';
import { IndividualFinancialScoringDTO } from '~/types/dto/individual/financialScoringDTO';
import { StringUtils } from '~/utils/stringUtils';
import { PhoneUtils } from '~/utils/phone';
import { DateUtils } from '~/utils/date';

@injectable()
export class IndividualFinancialScoringAdapterService implements IIndividualFinancialScoringAdapterService {
  getData(initData: IndividualFinancialScoring[]): IndividualFinancialScoringDTO[] {
    return (initData ?? []).map((item) => ({
      Score: item.Score ?? 0,
      Reason: StringUtils.quoteReplacement(item.Reason ?? ''),
      CheckedFio: StringUtils.quoteReplacement(item.CheckedFio ?? ''),
      CheckedPhone: PhoneUtils.getIsValidPhoneNumber(item.CheckedPhone ?? '') ? PhoneUtils.formatPhoneNumberWithDash(item.CheckedPhone ?? '') : '',
      CheckedBirthday: DateUtils.formatDate(item.CheckedBirthday ?? ''),
      CheckedPassportNumber: item.CheckedPassportNumber ?? '',
      CheckedPassportIssueDate: DateUtils.formatDate(item.CheckedPassportIssueDate ?? ''),
      FinancialScoringType: item.FinancialScoringType ?? 100,
    }));
  }
}
