import { injectable } from 'inversify';
import { ReportSocialNetworkHighEducation } from '~/types/common/reportSocialNetworkHighEducation';

import { IPlaceholderDataService } from './reportPlaceholderService.interfaces';

@injectable()
export class ReportSocialNetworkHighEducationPlaceholderService implements IPlaceholderDataService<ReportSocialNetworkHighEducation> {
  getData(): ReportSocialNetworkHighEducation {
    return {
      Type: 0,
      SocialNetworkId: '',
      LastName: '',
      FirstName: '',
      Birthday: '',
      EducationName: '',
      FacultyName: '',
      StartDate: '',
      DiplomaDate: '',
    };
  }
}
