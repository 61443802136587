import { injectable } from 'inversify';
import { ILegalEntitiesGeneralInformationManagementCompanyInfoConfigService } from '~/modules/legalEntities/commonInfo/legalEntitiesGeneralInformation/services/legalEntitiesGeneralInformationConfigServices.interfaces';
import { Config } from '~/types/field-list/config';
import { LegalEntitiesCompanyManagementCompaniesDTO } from '~/types/dto/legalEntities/companyManagementCompaniesDTO';
import { DateUtils } from '~/utils/date';

@injectable()
export class LegalEntitiesGeneralInformationManagementCompanyInfoConfigService implements ILegalEntitiesGeneralInformationManagementCompanyInfoConfigService {
  getConfig(initData: LegalEntitiesCompanyManagementCompaniesDTO): Config {
    const firstDate = initData.FirstDate ? `от ${DateUtils.formatDate(initData.FirstDate)}` : '';
    return {
      fieldsDirection: 'row',
      fieldsWidth: '100%',
      fieldsValueWidth: '100%',
      columnGap: '8px',
      rowGap: '4px',
      isBeginningDivider: true,
      fields: [
        {
          label: 'Дата первичной регистрации:',
          key: 'data',
          value: firstDate,
        },
        {
          label: 'ИНН:',
          key: 'data',
          value: initData.Inn,
        },
        {
          label: 'note/flash',
          key: 'alert',
          value: initData.IsInaccuracy === true ? 'Недостоверные сведения в ЕГРЮЛ' : '',
        }
      ].filter((item) => item.value)
    };
  }
}
