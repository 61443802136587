/* eslint-disable class-methods-use-this, max-len */

import { injectable, inject } from 'inversify';
import { StringUtils } from '~/utils/stringUtils';
import { LegalEntitiesTrademark } from '~/types/legal-entities/trademark';
import { LegalEntitiesTrademarkDTO } from '~/types/dto/legalEntities/trademarkDTO';
import {
  ILegalEntitiesTrademarkAdapterService
} from '~/services/adapters/legalEntities/legalEntitiesAdapterService.interfaces';
import {
  IReportMainEntityFileAdapterService,
  IReportMainEntityFileAdapterServiceKey
} from '~/services/adapters/common/reportAdapterService.interfeces';
import {
  IPlaceholderDataService,
  IReportMainEntityFilePlaceholderServiceKey
} from '~/services/placeholder/common/reportPlaceholderService.interfaces';
import { ReportMainEntityFile } from '~/types/common/reportMainEntityFile';

@injectable()
export class LegalEntitiesTrademarkAdapterService implements ILegalEntitiesTrademarkAdapterService {
  constructor(
    @inject(IReportMainEntityFileAdapterServiceKey as symbol) private reportMainEntityFileAdapterService: IReportMainEntityFileAdapterService,
    @inject(IReportMainEntityFilePlaceholderServiceKey as symbol) private reportMainEntityFilePlaceholderService: IPlaceholderDataService<ReportMainEntityFile>
  ) {}
  getData(initData: LegalEntitiesTrademark[]): LegalEntitiesTrademarkDTO[] {
    return (initData ?? []).map((item) => ({
      DocNumber: item.DocNumber,
      Name: StringUtils.quoteReplacement(item.Name),
      DateEnd: item.DateEnd ?? '',
      Image: this.reportMainEntityFileAdapterService.getData(item.Image ?? this.reportMainEntityFilePlaceholderService.getData())
    }));
  }
}
