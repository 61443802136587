import { IContainerBinder } from '~/configuration/inversify/containerAccessor.interface';
import {
  bindLegalEntitiesTaxRegimesServices
} from '~/modules/legalEntities/changeHistory/legalEntitiesCompanyTaxRegimes/configuration/inversify/container.legalEntitiesCompanyTaxRegimesServices';
import {
  bindLegalEntitiesChangeHistoryServices
} from '~/modules/legalEntities/changeHistory/common/configuration/inversify/container.legalEntitiesChangeHistory';
import {
  bindLegalEntitiesAuthorityToSignServices
} from '~/modules/legalEntities/changeHistory/LegalEntitiesAuthorityToSignChangeHistory/configuration/inversify/container.legalEntitiesAuthorityToSignServices';
import {
  bindLegalEntitiesAuthorizedCapitalServices
} from '~/modules/legalEntities/changeHistory/legalEntitiesAuthorizedCapital/configuration/inversify/container.legalEntitiesAuthorizedCapitalServices';

export function bindLegalEntitiesChangeHistoryModulesServices(bind: IContainerBinder) {
  bindLegalEntitiesTaxRegimesServices(bind);
  bindLegalEntitiesAuthorityToSignServices(bind);
  bindLegalEntitiesChangeHistoryServices(bind);
  bindLegalEntitiesAuthorizedCapitalServices(bind);
}
