import { IContainerBinder } from '~/configuration/inversify/containerAccessor.interface';
import { LegalEntitiesRelatedEgrulConfigService } from '~/modules/legalEntities/connection/legalEntitiesRelatedEgrul/services/legalEntitiesRelatedEgrulConfigService';
import { ILegalEntitiesRelatedEgrulConfigServiceKey } from '~/modules/legalEntities/connection/legalEntitiesRelatedEgrul/services/legalEntitiesRelatedEgrulConfigService.interfaces';
import { LegalEntitiesRelatedEgrulRelationObjectService } from '~/modules/legalEntities/connection/legalEntitiesRelatedEgrul/services/legalEntitiesRelatedEgrulRelationObjectService';
import { ILegalEntitiesRelatedEgrulRelationObjectServiceKey } from '~/modules/legalEntities/connection/legalEntitiesRelatedEgrul/services/legalEntitiesRelatedEgrulRelationObjectService.interface';

export function bindLegalEntitiesRelatedEgrulService(bind: IContainerBinder) {
  bind(ILegalEntitiesRelatedEgrulConfigServiceKey).to(LegalEntitiesRelatedEgrulConfigService);
  bind(ILegalEntitiesRelatedEgrulRelationObjectServiceKey).to(LegalEntitiesRelatedEgrulRelationObjectService);
}
