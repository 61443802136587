import { injectable } from 'inversify';
import { get } from 'lodash-es';
import { IDegreeOfSolvencyTableItemConfigService } from '~/modules/legalEntities/financialAnalytics/legalEntitiesLiquidityAndSolvency/services/legalEntitiesLiquidityAndSolvencyTableItemConfigService.interfaces';
import { TableItem } from '~/modules/table/types/tableItem';
import { LegalEntitiesReportFinancialIndicatorWithConclusionsDTO } from '~/types/dto/legalEntities/reportFinancialIndicatorWithConclusionsDTO';
import { NumberUtils } from '~/utils/number';
import { FINANCIAL_ANALYSIS_EXTENDED_TITLES } from '~/modules/legalEntities/financialAnalytics/common/configuration/constants/financialAnalysisExtendedTitles';

@injectable()
export class DegreeOfSolvencyTableItemConfigService implements IDegreeOfSolvencyTableItemConfigService {
  getItem(initData: LegalEntitiesReportFinancialIndicatorWithConclusionsDTO):TableItem {
    return {
      TwoYearsAgo: { value: NumberUtils.formatNumberDigits(initData.TwoYearsAgo, 2, '—'), color: this.getColor(initData.TwoYearsAgo) },
      LastYear: { value: NumberUtils.formatNumberDigits(initData.LastYear, 2, '—'), color: this.getColor(initData.LastYear) },
      CurrentYear: { value: NumberUtils.formatNumberDigits(initData.CurrentYear, 2, '—'), color: this.getColor(initData.CurrentYear) },

      Conclusions: get(initData.Conclusions, 0, '—'),
      indicator: {
        title: FINANCIAL_ANALYSIS_EXTENDED_TITLES.DegreeOfSolvency,
        subtitle: 'Оценивается период (количество месяцев), в течение которого компания может погасить текущую задолженность перед кредиторами за счет выручки (число месяцев)'
      },
      referenceValue: '< 6',
    };
  }

  private getColor(value: number): string {
    return value < 6 ? 'green' : 'red';
  }
}
