import { InjectionKey } from 'vue';
import { ReportPossibleFullNameDTO } from '~/types/dto/common/reportPossibleFullNameDTO';

interface IReportPossibleNamesShifting {
  getSiftedData(initData: Array<ReportPossibleFullNameDTO>, fullName: string): Array<ReportPossibleFullNameDTO>;
}

export type IPossibleNamesSiftingService = IReportPossibleNamesShifting;

export const IPossibleNamesSiftingServiceKey: InjectionKey<IPossibleNamesSiftingService> = Symbol('IPossibleNamesSiftingService');
