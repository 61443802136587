import { injectable } from 'inversify';

import { LegalEntitiesLesseeSummary } from '~/types/legal-entities/lesseeSummary';
import { IPlaceholderDataService } from '../common/reportPlaceholderService.interfaces';

@injectable()
export class LegalEntitiesLesseeSummaryPlaceholderDataService implements IPlaceholderDataService<LegalEntitiesLesseeSummary> {
  getData(): LegalEntitiesLesseeSummary {
    return {
      ArchiveCount: 0,
      ActualCount: 0
    };
  }
}
