import { injectable, inject } from 'inversify';
import { IPrimitiveTypesNormalizationServiceKey, IPrimitiveTypesNormalizationService } from './primitiveTypesNormalizationService.interfaces';
import { INormalizationService } from './reportNormalizationService.interfaces';
import { ReportVettingInfo } from '~/types/common/vettingInfo';

@injectable()
export class ReportVettingInfoNormalizationService implements INormalizationService<ReportVettingInfo> {
  constructor(
    @inject(IPrimitiveTypesNormalizationServiceKey as symbol) private primitiveTypesNormalizationService: IPrimitiveTypesNormalizationService,
  ) {}

  normalize(data: any, root: string): ReportVettingInfo | undefined {
    const { normalizeObject, normalizeOptionalString, normalizeNumber, normalizeOptionalInteger } = this.primitiveTypesNormalizationService;

    return normalizeObject(data, (data) => ({
      StartDate: normalizeOptionalString(data.StartDate, `${root}.StartDate`),
      EndDate: normalizeOptionalString(data.EndDate, `${root}.EndDate`),
      RootType: normalizeNumber(data.RootType, `${root}.RootType`),
      InputType: normalizeOptionalInteger(data.InputType, `${root}.InputType`),
      Decision: normalizeNumber(data.Decision, `${root}.Decision`),
      Comment: normalizeOptionalString(data.Comment, `${root}.Comment`),
      UserId: normalizeOptionalString(data.UserId, `${root}.UserId`),
      User: normalizeOptionalString(data.User, `${root}.User`),
      UserFullName: normalizeOptionalString(data.UserFullName, `${root}.UserFullName`),
      UserPosition: normalizeOptionalString(data.UserPosition, `${root}.UserPosition`),
    }), root);
  }
}
