import { injectable, inject } from 'inversify';
import { LegalEntitiesGovernmentControl } from '~/types/legal-entities/governmentControl';
import { IPrimitiveTypesNormalizationServiceKey, IPrimitiveTypesNormalizationService } from '../common/primitiveTypesNormalizationService.interfaces';
import { INormalizationService } from '../common/reportNormalizationService.interfaces';

@injectable()
export class LegalEntitiesGovernmentControlNormalizationService implements INormalizationService<LegalEntitiesGovernmentControl> {
  constructor(
    @inject(IPrimitiveTypesNormalizationServiceKey as symbol) private primitiveTypesNormalizationService: IPrimitiveTypesNormalizationService,
  ) {}

  normalize(data: any, root: string): LegalEntitiesGovernmentControl | undefined {
    const {
      normalizeNumber,
      normalizeOptionalString,
      normalizeString,
      normalizeObject
    } = this.primitiveTypesNormalizationService;

    return normalizeObject(data, (data) => ({
      Date: normalizeOptionalString(data.Date, `${root}.Date`),
      OrganName: normalizeOptionalString(data.OrganName, `${root}.OrganName`),
      ControlType: normalizeString(data.ControlType, `${root}.ControlType`),
      ViolationsCount: normalizeNumber(data.ViolationsCount, `${root}.ViolationsCount`),
      Violations: normalizeString(data.Violations, `${root}.Violations`),
    }), root);
  }
}
