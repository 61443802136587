import { IContainerBinder } from './containerAccessor.interface';

import {
  IIndividualAccrualPlaceholderServiceKey,
  IIndividualAviaPersonPlaceholderServiceKey,
  IIndividualBankruptcyPlaceholderServiceKey,
  IIndividualBusinessTripPlaceholderServiceKey,
  IIndividualCommonInfoPlaceholderServiceKey,
  IIndividualCommonNegativeInfoPlaceholderServiceKey,
  IIndividualCriminalPenaltiesPlaceholderServiceKey,
  IIndividualDataBlobDataPlaceholderServiceKey,
  IIndividualDriverLicensePlaceholderServiceKey,
  IIndividualEnforcementProceedingPlaceholderServiceKey,
  IIndividualFamilyRelationPlaceholderServiceKey,
  IIndividualFinancialScoringPlaceholderServiceKey,
  IIndividualFullNameChangePlaceholderServiceKey,
  IIndividualGroupPlaceholderServiceKey,
  IIndividualHolidayPlaceholderServiceKey,
  IIndividualJobRecommendationPlaceholderServiceKey,
  IIndividualLegalEntitiesParticipationRestrictionPlaceholderServiceKey,
  IIndividualMaritalStatusHistoryPlaceholderServiceKey,
  IIndividualMarkersPlaceholderServiceKey,
  IIndividualMessageAboutBankruptcyPlaceholderServiceKey,
  IIndividualMilitaryRecordPlaceholderServiceKey,
  IIndividualMovementBusPlaceholderServiceKey,
  IIndividualPhonePlaceholderServiceKey,
  IIndividualSkypePlaceholderServiceKey,
  IIndividualSpecialSignsPlaceholderServiceKey,
  IIndividualStageBankruptcyPlaceholderServiceKey,
  IIndividualTerroristsAndExtremistsRecordPlaceholderServiceKey,
  IIndividualTypePlaceholderServiceKey,
  IIndividualUserListPlaceholderServiceKey,
  IIndividualWantedFsinPlaceholderServiceKey,
  IIndividualWantedFsspPlaceholderServiceKey,
  IIndividualWantedMvdPlaceholderServiceKey,
  IIndividualWantedPlaceholderServiceKey
} from '~/services/placeholder/individual/individualPlaceholderDataService.interfaces';
import { IndividualDataBlobDataPlaceholderService } from '~/services/placeholder/individual/individualDataBlobDataPlaceholderService';
import { IndividualCommonInfoPlaceholderService } from '~/services/placeholder/individual/individualCommonInfoPlaceholderService';
import { IndividualCommonNegativeInfoPlaceholderService } from '~/services/placeholder/individual/individualCommonNegativeInfoPlaceholderService';
import { IndividualSpecialSignsPlaceholderService } from '~/services/placeholder/individual/individualSpecialSignsPlaceholderService';
import { IndividualMarkersPlaceholderService } from '~/services/placeholder/individual/individualMarkersPlaceholderService';
import { IndividualGroupPlaceholderService } from '~/services/placeholder/individual/individualGroupPlaceholderService';
import { IndividualUserListPlaceholderService } from '~/services/placeholder/individual/individualUserListPlaceholderService';
import { IndividualMilitaryRecordPlaceholderService } from '~/services/placeholder/individual/individualMilitaryRecordPlaceholderService';
import { IndividualPhonePlaceholderService } from '~/services/placeholder/individual/individualPhonePlaceholderService';
import { IndividualSkypePlaceholderService } from '~/services/placeholder/individual/individualSkypePlaceholderService';
import { IndividualDriverLicensePlaceholderService } from '~/services/placeholder/individual/individualDriverLicensePlaceholderService';
import { IndividualJobRecommendationPlaceholderService } from '~/services/placeholder/individual/individualJobRecommendationPlaceholderService';
import { IndividualBusinessTripPlaceholderService } from '~/services/placeholder/individual/individualBusinessTripPlaceholderService';
import { IndividualHolidayPlaceholderService } from '~/services/placeholder/individual/individualHolidayPlaceholderService';
import { IndividualLegalEntitiesParticipationRestrictionPlaceholderService } from '~/services/placeholder/individual/individualLegalEntitiesParticipationRestrictionPlaceholderService';
import { IndividualBankruptcyPlaceholderService } from '~/services/placeholder/individual/individualBankruptcyPlaceholderService';
import { IndividualStageBankruptcyPlaceholderService } from '~/services/placeholder/individual/individualStageBankruptcyPlaceholderService';
import { IndividualMessageAboutBankruptcyPlaceholderService } from '~/services/placeholder/individual/individualMessageAboutBankruptcyPlaceholderService';
import { IndividualCriminalPenaltiesPlaceholderService } from '~/services/placeholder/individual/individualCriminalPenaltiesPlaceholderService';
import { IndividualFinancialScoringPlaceholderService } from '~/services/placeholder/individual/individuallFinancialScoringPlaceholderService';
import { IndividualEnforcementProceedingPlaceholderService } from '~/services/placeholder/individual/individualEnforcementProceedingPlaceholderService';
import { IndividualWantedMvdPlaceholderService } from '~/services/placeholder/individual/individualWantedMvdPlaceholderService';
import { IndividualWantedFsinPlaceholderService } from '~/services/placeholder/individual/individualWantedFsinPlaceholderService';
import { IndividualWantedFsspPlaceholderService } from '~/services/placeholder/individual/individualWantedFsspPlaceholderService';
import { IndividualFullNameChangePlaceholderService } from '~/services/placeholder/individual/individualFullNameChangePlaceholderService';
import { IndividualTypePlaceholderService } from '~/services/placeholder/individual/individualTypePlaceholderService';
import { IndividualTerroristsAndExtremistsRecordPlaceholderService } from '~/services/placeholder/individual/individualTerroristsAndExtremistsRecordPlaceholderService';
import { IndividualAccrualPlaceholderService } from '~/services/placeholder/individual/individualAccrualPlaceholderService';
import { IndividualWantedPlaceholderService } from '~/services/placeholder/individual/individualWantedPlaceholderService';
import { IndividualAviaPersonPlaceholderService } from '~/services/placeholder/individual/individualAviaPersonPlaceholderService';
import { IndividualMovementBusPlaceholderService } from '~/services/placeholder/individual/individualMovementBusPlaceholderService';
import { IndividualFamilyRelationPlaceholderService } from '~/services/placeholder/individual/individualFamilyRelationPlaceholderService';
import { IndividualMaritalStatusHistoryPlaceholderService } from '~/services/placeholder/individual/individualMaritalStatusHistoryPlaceholderService';

export function bindIndividualPlaceholderServices(bind: IContainerBinder) {
  bind(IIndividualDataBlobDataPlaceholderServiceKey).to(IndividualDataBlobDataPlaceholderService);
  bind(IIndividualCommonInfoPlaceholderServiceKey).to(IndividualCommonInfoPlaceholderService);
  bind(IIndividualCommonNegativeInfoPlaceholderServiceKey).to(IndividualCommonNegativeInfoPlaceholderService);
  bind(IIndividualSpecialSignsPlaceholderServiceKey).to(IndividualSpecialSignsPlaceholderService);
  bind(IIndividualMarkersPlaceholderServiceKey).to(IndividualMarkersPlaceholderService);
  bind(IIndividualGroupPlaceholderServiceKey).to(IndividualGroupPlaceholderService);
  bind(IIndividualUserListPlaceholderServiceKey).to(IndividualUserListPlaceholderService);
  bind(IIndividualMilitaryRecordPlaceholderServiceKey).to(IndividualMilitaryRecordPlaceholderService);
  bind(IIndividualPhonePlaceholderServiceKey).to(IndividualPhonePlaceholderService);
  bind(IIndividualSkypePlaceholderServiceKey).to(IndividualSkypePlaceholderService);
  bind(IIndividualDriverLicensePlaceholderServiceKey).to(IndividualDriverLicensePlaceholderService);
  bind(IIndividualJobRecommendationPlaceholderServiceKey).to(IndividualJobRecommendationPlaceholderService);
  bind(IIndividualBusinessTripPlaceholderServiceKey).to(IndividualBusinessTripPlaceholderService);
  bind(IIndividualHolidayPlaceholderServiceKey).to(IndividualHolidayPlaceholderService);
  bind(IIndividualLegalEntitiesParticipationRestrictionPlaceholderServiceKey).to(IndividualLegalEntitiesParticipationRestrictionPlaceholderService);
  bind(IIndividualBankruptcyPlaceholderServiceKey).to(IndividualBankruptcyPlaceholderService);
  bind(IIndividualStageBankruptcyPlaceholderServiceKey).to(IndividualStageBankruptcyPlaceholderService);
  bind(IIndividualMessageAboutBankruptcyPlaceholderServiceKey).to(IndividualMessageAboutBankruptcyPlaceholderService);
  bind(IIndividualCriminalPenaltiesPlaceholderServiceKey).to(IndividualCriminalPenaltiesPlaceholderService);
  bind(IIndividualFinancialScoringPlaceholderServiceKey).to(IndividualFinancialScoringPlaceholderService);
  bind(IIndividualEnforcementProceedingPlaceholderServiceKey).to(IndividualEnforcementProceedingPlaceholderService);
  bind(IIndividualWantedMvdPlaceholderServiceKey).to(IndividualWantedMvdPlaceholderService);
  bind(IIndividualWantedFsinPlaceholderServiceKey).to(IndividualWantedFsinPlaceholderService);
  bind(IIndividualWantedFsspPlaceholderServiceKey).to(IndividualWantedFsspPlaceholderService);
  bind(IIndividualFullNameChangePlaceholderServiceKey).to(IndividualFullNameChangePlaceholderService);
  bind(IIndividualTypePlaceholderServiceKey).to(IndividualTypePlaceholderService);
  bind(IIndividualTerroristsAndExtremistsRecordPlaceholderServiceKey).to(IndividualTerroristsAndExtremistsRecordPlaceholderService);
  bind(IIndividualAccrualPlaceholderServiceKey).to(IndividualAccrualPlaceholderService);
  bind(IIndividualWantedPlaceholderServiceKey).to(IndividualWantedPlaceholderService);
  bind(IIndividualAviaPersonPlaceholderServiceKey).to(IndividualAviaPersonPlaceholderService);
  bind(IIndividualMovementBusPlaceholderServiceKey).to(IndividualMovementBusPlaceholderService);
  bind(IIndividualFamilyRelationPlaceholderServiceKey).to(IndividualFamilyRelationPlaceholderService);
  bind(IIndividualMaritalStatusHistoryPlaceholderServiceKey).to(IndividualMaritalStatusHistoryPlaceholderService);
}
