import { IContainerBinder } from '~/configuration/inversify/containerAccessor.interface';
import { PossibleFioMergeService } from '~/modules/reportsCommon/reportPossible/individual/servises/possibleFioMergeService';
import { IPossibleFioMergeServiceKey } from '~/modules/reportsCommon/reportPossible/individual/servises/possibleFioMergeService.interfaces';
import { PossibleFioServiceConfig } from '~/modules/reportsCommon/reportPossible/individual/servises/possibleFioServiceConfig';
import {
  IPossibleBirthdayServiceConfigKey,
  IPossibleFioServiceConfigKey
} from '~/modules/reportsCommon/reportPossible/individual/servises/possibleDataServiceConfig.interfaces';
import { PossibleBirthdayServiceConfig } from '~/modules/reportsCommon/reportPossible/individual/servises/possibleBirthdayServiceConfig';
import {
  IPossibleBirthdaysSiftingServiceKey
} from '~/modules/reportsCommon/reportPossible/individual/servises/possibleBirthdaysSiftingService.interfaces';
import {
  PossibleBirthdaysSiftingService
} from '~/modules/reportsCommon/reportPossible/individual/servises/possibleBirthdaysSiftingService';
import {
  IPossibleNamesSiftingServiceKey
} from '~/modules/reportsCommon/reportPossible/individual/servises/possibleNamesSiftingService.interfaces';
import {
  PossibleNamesSiftingService
} from '~/modules/reportsCommon/reportPossible/individual/servises/possibleNamesSiftingService';

export function bindPossibleDataServices(bind: IContainerBinder) {
  bind(IPossibleFioMergeServiceKey).to(PossibleFioMergeService);
  bind(IPossibleFioServiceConfigKey).to(PossibleFioServiceConfig);
  bind(IPossibleBirthdayServiceConfigKey).to(PossibleBirthdayServiceConfig);
  bind(IPossibleBirthdaysSiftingServiceKey).to(PossibleBirthdaysSiftingService);
  bind(IPossibleNamesSiftingServiceKey).to(PossibleNamesSiftingService);
}
