import { injectable } from 'inversify';
import { IndividualFamilyRelation } from '~/types/individual/familyRelation';
import { IPlaceholderDataService } from '../common/reportPlaceholderService.interfaces';

@injectable()
export class IndividualFamilyRelationPlaceholderService implements IPlaceholderDataService<IndividualFamilyRelation> {
  getData(): IndividualFamilyRelation {
    return {
      FamilyRelationType: 0,
      FirstName: '',
      LastName: '',
      Patronymic: '',
      Birthday: '',
      Inn: '',
      SocialNetworks: []
    };
  }
}
