/* eslint-disable class-methods-use-this, max-len */

import { injectable } from 'inversify';
import { LegalEntitiesCompanyName } from '~/types/legal-entities/companyName';
import { StringUtils } from '~/utils/stringUtils';
import { LegalEntitiesCompanyNameDTO } from '~/types/dto/legalEntities/companyNameDTO';
import {
  ILegalEntitiesCompanyNameAdapterService
} from '~/services/adapters/legalEntities/legalEntitiesAdapterService.interfaces';

@injectable()
export class LegalEntitiesCompanyNameAdapterService implements ILegalEntitiesCompanyNameAdapterService {
  getData(initData: LegalEntitiesCompanyName[]): LegalEntitiesCompanyNameDTO[] {
    return (initData ?? []).map((item) => ({
      Name: StringUtils.quoteReplacement(item.Name ?? ''),
      From: StringUtils.quoteReplacement(item.From ?? '')
    }));
  }
}
