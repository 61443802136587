import { injectable, inject } from 'inversify';

import { LegalEntitiesSelfRegulatoryOrganization } from '~/types/legal-entities/selfRegulatoryOrganization';
import { IPrimitiveTypesNormalizationServiceKey, IPrimitiveTypesNormalizationService } from '../common/primitiveTypesNormalizationService.interfaces';
import { INormalizationService } from '../common/reportNormalizationService.interfaces';

@injectable()
export class LegalEntitiesSelfRegulatoryOrganizationNormalizationService implements INormalizationService<LegalEntitiesSelfRegulatoryOrganization> {
  constructor(
    @inject(IPrimitiveTypesNormalizationServiceKey as symbol) private primitiveTypesNormalizationService: IPrimitiveTypesNormalizationService,
  ) {}

  normalize(data: any, root: string): LegalEntitiesSelfRegulatoryOrganization | undefined {
    const { normalizeOptionalString, normalizeString, normalizeObject } = this.primitiveTypesNormalizationService;

    return normalizeObject(data, (data) => ({
      SroName: normalizeString(data.SroName, `${root}.SroName`),
      SroInn: normalizeString(data.SroInn, `${root}.SroInn`),
      SroRegistryName: normalizeString(data.SroRegistryName, `${root}.SroRegistryName`),
      SroRegistryNumber: normalizeString(data.SroRegistryNumber, `${root}.SroRegistryNumber`),
      ParticipantRegistrationDate: normalizeOptionalString(data.ParticipantRegistrationDate, `${root}.ParticipantRegistrationDate`),
      ParticipantTerminationDate: normalizeOptionalString(data.ParticipantTerminationDate, `${root}.ParticipantTerminationDate`),
    }), root);
  }
}
