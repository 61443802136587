import { injectable } from 'inversify';
import { Config } from '~/types/field-list/config';
import { IndividualSpecialSignsDTO } from '~/types/dto/individual/individualSpecialSignsDTO';
import {
  IIndividualSpecialSignsConfigService
} from '~/modules/individual/negativeInformation/IndividualSpecialSigns/services/individualSpecialSignsConfigService.interface';

@injectable()
export class IndividualSpecialSignsConfigService implements IIndividualSpecialSignsConfigService {
  getConfig(initData: IndividualSpecialSignsDTO): Config {
    return {
      fieldsDirection: 'divider-row',
      fieldsWidth: '100%',
      fieldsValueWidth: '350px',
      isBeginningDivider: true,
      fields: [
        {
          label: 'Кличка',
          key: 'CriminalNickName',
          value: initData.CriminalNickName
        },
        {
          label: 'Особые приметы',
          key: 'SpecialSigns',
          value: initData.SpecialSigns
        }
      ]
    };
  }
}
