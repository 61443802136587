import { injectable, inject } from 'inversify';
import { ReportFine } from '~/types/common/reportFine';
import { ReportMainEntityFile } from '~/types/common/reportMainEntityFile';
import { IPrimitiveTypesNormalizationServiceKey, IPrimitiveTypesNormalizationService } from './primitiveTypesNormalizationService.interfaces';
import { INormalizationService, IReportMainEntityFileNormalizationServiceKey } from './reportNormalizationService.interfaces';

@injectable()
export class ReportFineNormalizationService implements INormalizationService<ReportFine> {
  constructor(
    @inject(IReportMainEntityFileNormalizationServiceKey as symbol) private reportMainEntityFileNormalizationService: INormalizationService<ReportMainEntityFile>,

    @inject(IPrimitiveTypesNormalizationServiceKey as symbol) private primitiveTypesNormalizationService: IPrimitiveTypesNormalizationService,
  ) {}

  normalize(data: any, root: string): ReportFine | undefined {
    const {
      normalizeObject,
      normalizeString,
      normalizeOptionalString,
      normalizeOptionalInteger,
      normalizeArray
    } = this.primitiveTypesNormalizationService;

    return normalizeObject(data, (data) => ({
      Initiator: normalizeString(data.Initiator, `${root}.Initiator`),
      Sum: normalizeOptionalInteger(data.Sum, `${root}.Sum`),
      RestSum: normalizeOptionalInteger(data.RestSum, `${root}.RestSum`),
      SubjectOfExecution: normalizeString(data.SubjectOfExecution, `${root}.SubjectOfExecution`),
      KoapCode: normalizeString(data.KoapCode, `${root}.KoapCode`),
      Date: normalizeOptionalString(data.Date, `${root}.Date`),
      Status: normalizeOptionalString(data.Status, `${root}.Status`),
      Photos: normalizeArray(data.Photos, (item, field) => this.reportMainEntityFileNormalizationService.normalize(item, field), `${root}.Photos`),
    }), root);
  }
}
