import { injectable } from 'inversify';
import { IIndividualEnforcementProceedingConfigService } from '~/modules/individual/negativeInformation/individualEnforcementProceeding/services/individualEnforcementProceedingConfigService.interfaces';
import { IndividualEnforcementProceedingDTO } from '~/types/dto/individual/enforcementProceedingDTO';
import { PROCEEDINGS_FIELD_LIST_OPTIONS } from '~/modules/individual/negativeInformation/individualEnforcementProceeding/configuration/constants';
import { EnforcementProceedingUtils } from '../utils/enforcementProceedingUtils';
import { NumberUtils } from '~/utils/number';

@injectable()
export class IndividualEnforcementProceedingMainConfigService implements IIndividualEnforcementProceedingConfigService {
  getConfig(enforcementProceeding: IndividualEnforcementProceedingDTO) {
    const replacedSubjectOfExecutionsValue = EnforcementProceedingUtils
      .parseSubjectOfExecution(enforcementProceeding.SubjectOfExecution);

    const endReason = enforcementProceeding.EndReasonArticle ? `${enforcementProceeding.EndReasonArticle} 
      ${enforcementProceeding.EndReasonPart} ${enforcementProceeding.EndReasonParagraph}` : '';

    return {
      ...PROCEEDINGS_FIELD_LIST_OPTIONS,
      isBeginningDivider: true,
      fields: [
        {
          key: 'SubjectOfExecution',
          label: 'Исполнительное производство',
          value: enforcementProceeding.Number
        },
        {
          key: 'BoldAndBlueItem',
          label: 'Предмет исполнения, сумма непогашенной задолженности',
          value: replacedSubjectOfExecutionsValue
        },
        {
          key: 'SubjectOfExecution',
          label: 'Дата окончания или прекращения',
          value: enforcementProceeding.EndDate
        },
        {
          key: 'SubjectOfExecution',
          label: 'Причина окончания или прекращения',
          value: endReason
        },
        {
          key: 'SubjectOfExecution',
          label: 'Реквизиты исполнительного документа',
          value: enforcementProceeding.ExecutiveDocumentAgencyName
        },
        {
          key: 'AmountOwed',
          label: 'Сумма непогашенной задолженности, \u{20BD}',
          value: NumberUtils.formatNumberDigits(enforcementProceeding.AmountOwed, 0, '')
        },
        {
          key: 'SubjectOfExecution',
          label: 'Исполнитель',
          value: enforcementProceeding.BailiffFullName
        },
      ]
    };
  }
}
