import { injectable } from 'inversify';
import { IPhoneNumberMessageBoardsSiftingService } from '~/modules/reportsCommon/reportMessageBoardRecord/phoneNumber/services/phoneNumberMessageBoardsService.interfaces';
import { ReportMessageBoardRecordDTO } from '~/types/dto/common/messageBoardsDTO';

@injectable()
export class PhoneNumberMessageBoardsSiftingService implements IPhoneNumberMessageBoardsSiftingService {
  getSiftedData(initData: Array<ReportMessageBoardRecordDTO>): Array<ReportMessageBoardRecordDTO> {
    return initData.map((message) => ({
      MessageSourceType: message.MessageSourceType,
      MessageBoardDataType: message.MessageBoardDataType,
      RequestNumber: '',
      Category: message.Category,
      MessageTitle: message.MessageTitle,
      MessageText: message.MessageText,
      RegistrationDate: message.RegistrationDate,
      Region: message.Region,
      Price: message.Price,
      Currency: message.Currency,
      Year: message.Year,
      CarNumber: message.CarNumber,
      Brand: message.Brand,
      Model: message.Model,
      Vin: message.Vin,
      Contactface: message.Contactface,
      Link: message.Link
    }));
  }
}
