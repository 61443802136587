import { injectable } from 'inversify';
import { orderBy } from 'lodash-es';
import { IReportPersonInnAdapterService } from '~/services/adapters/common/reportAdapterService.interfeces';
import { ReportPersonInn } from '~/types/common/reportPersonInn';
import { ReportPersonInnDTO } from '~/types/dto/common/reportPersonInnDTO';
import { StringUtils } from '~/utils/stringUtils';

@injectable()
export class ReportPersonInnAdapterService implements IReportPersonInnAdapterService {
  getData(initData: ReportPersonInn[]): ReportPersonInnDTO[] {
    return orderBy(initData ?? [], ['IsValid'], ['desc', 'asc'])
      .map((item) => ({
        Value: StringUtils.quoteReplacement(item.Value),
        IsValid: item.IsValid,
        IsActual: item.IsActual
      }));
  }
}
