import { InjectionKey } from 'nuxt/dist/app/compat/capi';
import { GenericDataBlob } from '~/types/blobs/generic-data-blob';
import { UIReportType } from '~/types/reportType';
import { MockDataFile } from '~/types/mockDataFile';

export interface IMocksService {
  loadMockDataAsync(name: string): Promise<GenericDataBlob>
  getMockFiles(reportType: UIReportType): MockDataFile[]
  getMockDataByNameAsync(reportType: UIReportType, mockName?: string): Promise<GenericDataBlob | undefined>
}

export const IMocksServiceKey: InjectionKey<IMocksService> = Symbol('IMocksServiceKey');
