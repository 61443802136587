import { injectable, inject } from 'inversify';
import { DataBlobReportOriginalInputFileInfo } from '~/types/blobs/data-blob-report-original-input-file-info';
import { IPrimitiveTypesNormalizationServiceKey, IPrimitiveTypesNormalizationService } from '../common/primitiveTypesNormalizationService.interfaces';
import { INormalizationService } from '../common/reportNormalizationService.interfaces';

@injectable()
export class DataBlobReportOriginalInputFileInfoNormalizationService implements INormalizationService<DataBlobReportOriginalInputFileInfo> {
  constructor(
    @inject(IPrimitiveTypesNormalizationServiceKey as symbol) private primitiveTypesNormalizationService: IPrimitiveTypesNormalizationService,
  ) {}

  normalize(data: any, root: string): DataBlobReportOriginalInputFileInfo | undefined {
    const { normalizeObject, normalizeNumber, normalizeString } = this.primitiveTypesNormalizationService;

    return normalizeObject(data, (data) => ({
      DataLength: normalizeNumber(data.DataLength, `${root}.DataLength`),
      FileNameWithoutExtension: normalizeString(data.FileNameWithoutExtension, `${root}.FileNameWithoutExtension`),
      FileName: normalizeString(data.FileName, `${root}.FileName`),
    }), root);
  }
}
