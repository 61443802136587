import { injectable, inject } from 'inversify';
import {
  IReportConnectionRelatedEntitiesAdapterService,
  IReportConnectionRelatedEntityDisplayAdapterService,
  IReportConnectionRelatedEntityDisplayAdapterServiceKey
} from '~/services/adapters/common/reportAdapterService.interfeces';
import { ReportConnectionRelatedEntity } from '~/types/common/reportConnectionRelatedEntity';
import { ReportConnectionRelatedEntityDTO } from '~/types/dto/common/reportConnectionRelatedEntityDTO';

@injectable()
export class ReportConnectionRelatedEntitiesAdapterService implements IReportConnectionRelatedEntitiesAdapterService {
  constructor( 
    @inject(IReportConnectionRelatedEntityDisplayAdapterServiceKey as symbol) 
    private reportConnectionEntityDisplayAdapterService: IReportConnectionRelatedEntityDisplayAdapterService
  ) {}
  getData(Entities: Array<ReportConnectionRelatedEntity>): Array<ReportConnectionRelatedEntityDTO> {
    return (Entities ?? []).map((entity) => ({
      Name: entity.Name ?? '',
      Snils: entity.Snils ?? '',
      DisplayJobs: this.reportConnectionEntityDisplayAdapterService.getData(entity.DisplayJobs),
      DisplayAffiliates: this.reportConnectionEntityDisplayAdapterService.getData(entity.DisplayAffiliates),
      Inn: entity.Inn ?? '',
      Depth: entity.Depth ?? 0
    }));
  }
}
