/* eslint-disable class-methods-use-this, max-len */

import { injectable, inject } from 'inversify';
import { LegalEntitiesHashtag } from '~/types/legal-entities/hashtag';
import {
  IReportUrlAdapterService,
  IReportUrlAdapterServiceKey
} from '~/services/adapters/common/reportAdapterService.interfeces';
import { LegalEntitiesHashtagDTO } from '~/types/dto/legalEntities/legalEntitiesHashtagDTO';
import {
  ILegalEntitiesHashtagAdapterService
} from '~/services/adapters/legalEntities/legalEntitiesAdapterService.interfaces';
import {
  IArrayAdapterService,
  IReportArrayAdapterServiceKey
} from '~/services/adapters/common/reportArrayAdapterService.interfaces';
import { IReportUrlMergeService, IReportUrlMergeServiceKey } from '~/services/merge/common/reportMergeService.interfaces';

@injectable()
export class LegalEntitiesHashtagAdapterService implements ILegalEntitiesHashtagAdapterService {
  constructor(
    @inject(IReportArrayAdapterServiceKey as symbol) private reportArrayAdapterService: IArrayAdapterService,
    @inject(IReportUrlAdapterServiceKey as symbol) private reportUrlAdapterService: IReportUrlAdapterService,
    @inject(IReportUrlMergeServiceKey as symbol) private reportUrlMergeService: IReportUrlMergeService,
  ) {}
  getData(initData: LegalEntitiesHashtag[]): LegalEntitiesHashtagDTO[] {
    const { mapData } = this.reportArrayAdapterService;
    const hashTags = mapData(initData, (item) => this.reportUrlAdapterService.getData(item));
    return this.reportUrlMergeService.merge(hashTags);
  }
}
