import { injectable, inject } from 'inversify';

import { LegalEntitiesFinancialCommon } from '~/types/legal-entities/financialCommon';
import { IPrimitiveTypesNormalizationServiceKey, IPrimitiveTypesNormalizationService } from '../common/primitiveTypesNormalizationService.interfaces';
import { INormalizationService } from '../common/reportNormalizationService.interfaces';

@injectable()
export class LegalEntitiesFinancialCommonNormalizationService implements INormalizationService<LegalEntitiesFinancialCommon> {
  constructor(
    @inject(IPrimitiveTypesNormalizationServiceKey as symbol) private primitiveTypesNormalizationService: IPrimitiveTypesNormalizationService
  ) {}

  normalize(data: any, root: string): LegalEntitiesFinancialCommon | undefined {
    const { normalizeNumber, normalizeString, normalizeObject } = this.primitiveTypesNormalizationService;

    return normalizeObject(data, (data) => ({
      Year: normalizeNumber(data.Year, `${root}.Year`),
      SectorName: normalizeString(data.SectorName, `${root}.SectorName`),
      ValuePrevious: normalizeNumber(data.ValuePrevious, `${root}.ValuePrevious`),
      ValueCurrent: normalizeNumber(data.ValueCurrent, `${root}.ValueCurrent`),
      Dynamics: normalizeNumber(data.Dynamics, `${root}.Dynamics`),
    }), root);
  }
}
