import { injectable, inject } from 'inversify';

import { IndividualDataBlobData } from '~/types/blobs/individual-data-blob-data';
import { IndividualDataBlobDataDTO } from '~/types/dto/blobs/individualDataBlobDataDTO';
import {
  IIndividualAviasAdapterService,
  IIndividualAviasAdapterServiceKey,
  IIndividualBusinessTripAdapterService,
  IIndividualBusinessTripAdapterServiceKey,
  IIndividualCommonInfoAdapterService,
  IIndividualCommonInfoAdapterServiceKey,
  IIndividualDataBlobDataService,
  IIndividualDriverLicenseAdapterService,
  IIndividualDriverLicenseAdapterServiceKey,
  IIndividualGroupAdapterService,
  IIndividualGroupAdapterServiceKey,
  IIndividualLegalEntitiesParticipationRestrictionsAdapterService,
  IIndividualLegalEntitiesParticipationRestrictionsAdapterServiceKey,
  IIndividualHolidayAdapterService,
  IIndividualHolidayAdapterServiceKey,
  IIndividualJobRecommendationAdapterService,
  IIndividualJobRecommendationAdapterServiceKey,
  IIndividualMilitaryRecordAdapterService,
  IIndividualMilitaryRecordAdapterServiceKey,
  IIndividualPhoneAdapterService,
  IIndividualPhoneAdapterServiceKey,
  IIndividualSkypeAdapterService,
  IIndividualSkypeAdapterServiceKey,
  IIndividualUserListAdapterService,
  IIndividualUserListAdapterServiceKey,
  IIndividualFamilyListAdapterServiceKey,
  IIndividualFamilyListAdapterService,
  IIndividualCommonNegativeInfoAdapterServiceKey,
  IIndividualCommonNegativeInfoAdapterService,
  IIndividualBankruptcyAdapterServiceKey,
  IIndividualBankruptcyAdapterService,
  IIndividualStageBankruptcyAdapterServiceKey,
  IIndividualStageBankruptcyAdapterService,
  IIndividualMessageAboutBankruptcyAdapterServiceKey,
  IIndividualMessageAboutBankruptcyAdapterService,
  IIndividualCriminalPenaltiesAdapterServiceKey,
  IIndividualCriminalPenaltiesAdapterService,
  IIndividualFinancialScoringAdapterServiceKey,
  IIndividualFinancialScoringAdapterService,
  IIndividualEnforcementProceedingsAdapterServiceKey,
  IIndividualEnforcementProceedingsAdapterService,
  IIndividualTrustScoringAdapterServiceKey,
  IIndividualTrustScoringAdapterService,
  IIndividualWantedMvdAdapterServiceKey,
  IIndividualWantedMvdAdapterService,
  IIndividualWantedFsinAdapterServiceKey,
  IIndividualWantedFsinAdapterService,
  IIndividualWantedFsspAdapterServiceKey,
  IIndividualWantedFsspAdapterService,
  IIndividualFullNameChangeAdapterServiceKey,
  IIndividualFullNameChangeAdapterService,
  IIndividualTypeAdapterServiceKey,
  IIndividualTypeAdapterService,
  IIndividualTerroristsAndExtremistsRecordAdapterServiceKey,
  IIndividualTerroristsAndExtremistsRecordAdapterService,
  IIndividualAccrualAdapterServiceKey,
  IIndividualAccrualAdapterService,
  IIndividualWantedAdapterServiceKey,
  IIndividualWantedAdapterService,
  IIndividualMovementBusAdapterServiceKey,
  IIndividualMovementBusAdapterService,
  IIndividualMaritalStatusHistoryAdapterServiceKey,
  IIndividualMaritalStatusHistoryAdapterService,
  IIndividualMarkersAdapterServiceKey, IIndividualMarkersAdapterService,
} from '~/services/adapters/individual/individualAdapterService.interfaces';
import {
  IReportBoundedPersonPhoneAdapterService,
  IReportBoundedPersonPhoneAdapterServiceKey,
  IReportEmailAdapterService,
  IReportEmailAdapterServiceKey,
  IReportPassportAdapterService,
  IReportPassportAdapterServiceKey,
  IReportConnectionRelatedAdapterService,
  IReportConnectionRelatedAdapterServiceKey,
  IReportPersonInnAdapterService,
  IReportPersonInnAdapterServiceKey,
  IReportPersonSiteAdapterService,
  IReportPersonSiteAdapterServiceKey,
  IReportPersonSnilsAdapterService,
  IReportPersonSnilsAdapterServiceKey,
  IReportSocialNetworkTrackAdapterServiceKey,
  IReportSocialNetworkTrackAdapterService,
  IReportAddressAdapterServiceKey,
  IReportAddressAdapterService,
  IReportVehicleAdapterServiceKey,
  IReportVehicleAdapterService,
  IReportVehicleParkingAdapterServiceKey,
  IReportVehicleParkingAdapterService,
  IReportEducationAdapterServiceKey,
  IReportEducationAdapterService,
  IReportJobAdapterServiceKey,
  IReportJobAdapterService,
  IReportIndividualEntrepreneurAdapterService,
  IReportIndividualEntrepreneurAdapterServiceKey,
  IReportLegalEntitiesParticipationAdapterServiceKey,
  IReportLegalEntitiesParticipationAdapterService,
  IReportFineAdapterService,
  IReportFineAdapterServiceKey,
  IReportPossibleFullNameAdapterServiceKey,
  IReportPossibleFullNameAdapterService,
  IReportPossibleBirthdaysAdapterServiceKey,
  IReportPossibleBirthdaysAdapterService,
  IReportArrayStringAdapterService,
  IReportArrayStringAdapterServiceKey,
  IReportMovementCovidAdapterServiceKey,
  IReportMovementCovidAdapterService,
  IReportMovementTaxiAdapterServiceKey,
  IReportMovementTaxiAdapterService,
  IReportMainEntityFileAdapterServiceKey,
  IReportMainEntityFileAdapterService,
  IReportMessageBoardRecordAdapterServiceKey, IReportMessageBoardRecordAdapterService, IReportSocialNetworkAdapterService, IReportSocialNetworkAdapterServiceKey,
} from '~/services/adapters/common/reportAdapterService.interfeces';
import {
  IArrayAdapterService,
  IReportArrayAdapterServiceKey
} from '~/services/adapters/common/reportArrayAdapterService.interfaces';
import {
  IReportVettingInfoAdapterService,
  IReportVettingInfoAdapterServiceKey
} from '~/services/adapters/reportAdapterService.interface';

@injectable()
export class IndividualDataBlobAdapterService implements IIndividualDataBlobDataService {
  constructor(
    @inject(IIndividualCommonInfoAdapterServiceKey as symbol) private individualCommonInfoAdapterService: IIndividualCommonInfoAdapterService,
    @inject(IIndividualGroupAdapterServiceKey as symbol) private individualGroupAdapterService: IIndividualGroupAdapterService,
    @inject(IIndividualUserListAdapterServiceKey as symbol) private individualUserListAdapterService: IIndividualUserListAdapterService,
    @inject(IIndividualMilitaryRecordAdapterServiceKey as symbol) private individualMilitaryRecordAdapterService: IIndividualMilitaryRecordAdapterService,
    @inject(IIndividualPhoneAdapterServiceKey as symbol) private individualPhoneAdapterService: IIndividualPhoneAdapterService,
    @inject(IIndividualSkypeAdapterServiceKey as symbol) private individualSkypeAdapterService: IIndividualSkypeAdapterService,
    @inject(IReportSocialNetworkAdapterServiceKey as symbol) private reportSocialNetworkAdapterService: IReportSocialNetworkAdapterService,
    @inject(IIndividualDriverLicenseAdapterServiceKey as symbol) private individualDriverLicenseAdapterService: IIndividualDriverLicenseAdapterService,
    @inject(IIndividualJobRecommendationAdapterServiceKey as symbol) private individualJobRecommendationAdapterService: IIndividualJobRecommendationAdapterService,
    @inject(IIndividualBusinessTripAdapterServiceKey as symbol) private individualBusinessTripAdapterService: IIndividualBusinessTripAdapterService,
    @inject(IIndividualHolidayAdapterServiceKey as symbol) private individualHolidayAdapterService: IIndividualHolidayAdapterService,
    @inject(IIndividualEnforcementProceedingsAdapterServiceKey as symbol) private individualEnforcementProceedingAdapterService: IIndividualEnforcementProceedingsAdapterService,
    @inject(IIndividualAviasAdapterServiceKey as symbol) private individualAviasAdapterService: IIndividualAviasAdapterService,
    @inject(IIndividualLegalEntitiesParticipationRestrictionsAdapterServiceKey as symbol) private individualLegalEntitiesParticipationRestrictionsAdapterService: IIndividualLegalEntitiesParticipationRestrictionsAdapterService,
    @inject(IIndividualCommonNegativeInfoAdapterServiceKey as symbol) private individualCommonNegativeInfoAdapterService: IIndividualCommonNegativeInfoAdapterService,
    @inject(IIndividualBankruptcyAdapterServiceKey as symbol) private individualBankruptcyAdapterService: IIndividualBankruptcyAdapterService,
    @inject(IIndividualFamilyListAdapterServiceKey as symbol) private individualFamilyListAdapterService: IIndividualFamilyListAdapterService,
    @inject(IIndividualStageBankruptcyAdapterServiceKey as symbol) private individualStageBankruptcyAdapterService: IIndividualStageBankruptcyAdapterService,
    @inject(IIndividualMessageAboutBankruptcyAdapterServiceKey as symbol) private individualMessageAboutBankruptcyAdapterService: IIndividualMessageAboutBankruptcyAdapterService,
    @inject(IIndividualCriminalPenaltiesAdapterServiceKey as symbol) private individualCriminalPenaltiesAdapterService: IIndividualCriminalPenaltiesAdapterService,
    @inject(IIndividualFinancialScoringAdapterServiceKey as symbol) private individualFinancialScoringAdapterService: IIndividualFinancialScoringAdapterService,
    @inject(IIndividualTrustScoringAdapterServiceKey as symbol) private individualTrustScoringAdapterService: IIndividualTrustScoringAdapterService,
    @inject(IIndividualWantedMvdAdapterServiceKey as symbol) private individualWantedMvdAdapterService: IIndividualWantedMvdAdapterService,
    @inject(IIndividualWantedFsinAdapterServiceKey as symbol) private individualWantedFsinAdapterService: IIndividualWantedFsinAdapterService,
    @inject(IIndividualWantedFsspAdapterServiceKey as symbol) private individualWantedFsspAdapterService: IIndividualWantedFsspAdapterService,
    @inject(IIndividualFullNameChangeAdapterServiceKey as symbol) private individualFullNameChangeAdapterService: IIndividualFullNameChangeAdapterService,
    @inject(IIndividualTypeAdapterServiceKey as symbol) private individualTypeAdapterService: IIndividualTypeAdapterService,
    @inject(IIndividualTerroristsAndExtremistsRecordAdapterServiceKey as symbol) private individualTerroristsAndExtremistsRecordAdapterService: IIndividualTerroristsAndExtremistsRecordAdapterService,
    @inject(IIndividualAccrualAdapterServiceKey as symbol) private individualAccrualAdapterService: IIndividualAccrualAdapterService,
    @inject(IIndividualWantedAdapterServiceKey as symbol) private individualWantedAdapterService: IIndividualWantedAdapterService,
    @inject(IIndividualMovementBusAdapterServiceKey as symbol) private individualMovementBusAdapterService: IIndividualMovementBusAdapterService,
    @inject(IIndividualMaritalStatusHistoryAdapterServiceKey as symbol) private individualMaritalStatusHistoryAdapterService: IIndividualMaritalStatusHistoryAdapterService,
    @inject(IIndividualMarkersAdapterServiceKey as symbol) private individualMarkersAdapterService: IIndividualMarkersAdapterService,

    @inject(IReportIndividualEntrepreneurAdapterServiceKey as symbol) private individualEntrepreneurAdapterService: IReportIndividualEntrepreneurAdapterService,
    @inject(IReportPassportAdapterServiceKey as symbol) private reportPassportAdapterService: IReportPassportAdapterService,
    @inject(IReportPersonSnilsAdapterServiceKey as symbol) private reportPersonSnilsAdapterService: IReportPersonSnilsAdapterService,
    @inject(IReportPersonInnAdapterServiceKey as symbol) private reportPersonInnAdapterService: IReportPersonInnAdapterService,
    @inject(IReportBoundedPersonPhoneAdapterServiceKey as symbol) private reportBoundedPersonPhoneAdapterService: IReportBoundedPersonPhoneAdapterService,
    @inject(IReportEmailAdapterServiceKey as symbol) private reportEmailAdapterService: IReportEmailAdapterService,
    @inject(IReportPersonSiteAdapterServiceKey as symbol) private reportPersonSiteAdapterService: IReportPersonSiteAdapterService,
    @inject(IReportSocialNetworkTrackAdapterServiceKey as symbol) private reportSocialNetworkTrackAdapterService: IReportSocialNetworkTrackAdapterService,
    @inject(IReportConnectionRelatedAdapterServiceKey as symbol) private reportConnectionRelatedAdapterService: IReportConnectionRelatedAdapterService,
    @inject(IReportAddressAdapterServiceKey as symbol) private reportAddressAdapterService: IReportAddressAdapterService,
    @inject(IReportVehicleAdapterServiceKey as symbol) private reportVehicleAdapterService: IReportVehicleAdapterService,
    @inject(IReportVehicleParkingAdapterServiceKey as symbol) private reportVehicleParkingAdapterService: IReportVehicleParkingAdapterService,
    @inject(IReportEducationAdapterServiceKey as symbol) private reportEducationAdapterService: IReportEducationAdapterService,
    @inject(IReportJobAdapterServiceKey as symbol) private reportJobAdapterService: IReportJobAdapterService,
    @inject(IReportLegalEntitiesParticipationAdapterServiceKey as symbol) private reportLegalEntitiesParticipationAdapterService: IReportLegalEntitiesParticipationAdapterService,
    @inject(IReportFineAdapterServiceKey as symbol) private reportFineAdapterService: IReportFineAdapterService,
    @inject(IReportPossibleFullNameAdapterServiceKey as symbol) private reportPossibleFullNameAdapterService: IReportPossibleFullNameAdapterService,
    @inject(IReportPossibleBirthdaysAdapterServiceKey as symbol) private reportPossibleBirthdaysAdapterService: IReportPossibleBirthdaysAdapterService,
    @inject(IReportArrayStringAdapterServiceKey as symbol) private reportArrayStringAdapterService: IReportArrayStringAdapterService,
    @inject(IReportMovementCovidAdapterServiceKey as symbol) private reportMovementCovidAdapterService: IReportMovementCovidAdapterService,
    @inject(IReportMovementTaxiAdapterServiceKey as symbol) private reportMovementTaxiAdapterService: IReportMovementTaxiAdapterService,
    @inject(IReportMainEntityFileAdapterServiceKey as symbol) private reportMainEntityFileAdapterService: IReportMainEntityFileAdapterService,
    @inject(IReportArrayAdapterServiceKey as symbol) private reportArrayAdapterService: IArrayAdapterService,
    @inject(IReportMessageBoardRecordAdapterServiceKey as symbol) private reportMessageBoardRecordAdapterService: IReportMessageBoardRecordAdapterService,
    @inject(IReportVettingInfoAdapterServiceKey as symbol) private reportVettingInfoAdapterService: IReportVettingInfoAdapterService,

  ) {}

  getData(initData: IndividualDataBlobData): IndividualDataBlobDataDTO {
    const { mapData } = this.reportArrayAdapterService;
    return {
      Common: this.individualCommonInfoAdapterService.getData(initData.Common),
      PersonGroups: this.individualGroupAdapterService.getData(initData.PersonGroups),
      Inns: this.reportPersonInnAdapterService.getData(initData.Inns),
      Snilses: this.reportPersonSnilsAdapterService.getData(initData.Snilses),
      UsersLists: this.individualUserListAdapterService.getData(initData.UsersLists),
      Passports: this.reportPassportAdapterService.getData(initData.Passports),
      MilitaryRecords: this.individualMilitaryRecordAdapterService.getData(initData.MilitaryRecords),
      Phones: this.individualPhoneAdapterService.getData(initData.Phones),
      BoundedPersonPhones: this.reportBoundedPersonPhoneAdapterService.getData(initData.BoundedPersonPhones),
      Skypes: this.individualSkypeAdapterService.getData(initData.Skypes),
      Sites: this.reportPersonSiteAdapterService.getData(initData.Sites),
      SocialNetworks: this.reportSocialNetworkAdapterService.getData(initData.SocialNetworks),
      SocialNetworkTrack: this.reportSocialNetworkTrackAdapterService.getData(initData.SocialNetworkTrack),
      Emails: this.reportEmailAdapterService.getData(initData.Emails),
      RelationsWithCounterparties: this.reportConnectionRelatedAdapterService.getData(initData.RelationsWithCounterparties),
      RelationsWithCompetitors: this.reportConnectionRelatedAdapterService.getData(initData.RelationsWithCompetitors),
      RelationsWithBlackListed: this.reportConnectionRelatedAdapterService.getData(initData.RelationsWithBlackListed),
      RelationsWithEmployees: this.reportConnectionRelatedAdapterService.getData(initData.RelationsWithEmployees),
      FoundRelations: this.reportConnectionRelatedAdapterService.getData(initData.FoundRelations),
      Addresses: this.reportAddressAdapterService.getData(initData.Addresses),
      Realties: this.reportAddressAdapterService.getData(initData.Realties),
      DriverLicenses: this.individualDriverLicenseAdapterService.getData(initData.DriverLicenses),
      Vehicles: this.reportVehicleAdapterService.getData(initData.Vehicles),
      LegalEntitiesParticipationRestrictions: this.individualLegalEntitiesParticipationRestrictionsAdapterService.getData(initData.LegalEntitiesParticipationRestrictions),
      VehicleParkings: this.reportVehicleParkingAdapterService.getData(initData.VehicleParkings),
      Educations: this.reportEducationAdapterService.getData(initData.Educations),
      Jobs: this.reportJobAdapterService.getData(initData.Jobs),
      JobRecommendations: this.individualJobRecommendationAdapterService.getData(initData.JobRecommendations),
      BusinessTrips: this.individualBusinessTripAdapterService.getData(initData.BusinessTrips),
      Holidays: this.individualHolidayAdapterService.getData(initData.Holidays),
      IndividualEntrepreneurs: this.individualEntrepreneurAdapterService.getData(initData.IndividualEntrepreneurs),
      EnforcementProceedings: this.individualEnforcementProceedingAdapterService.getData(initData.EnforcementProceedings),
      Avias: this.individualAviasAdapterService.getData(initData.Avias),
      LegalEntitiesParticipation: this.reportLegalEntitiesParticipationAdapterService.getData(initData.LegalEntitiesParticipation),
      CommonNegativeInfo: this.individualCommonNegativeInfoAdapterService.getData(initData.CommonNegativeInfo),
      Bankruptcies: this.individualBankruptcyAdapterService.getData(initData.Bankruptcies),
      FamilyList: this.individualFamilyListAdapterService.getData(initData.FamilyList),
      StagesBankruptcy: this.individualStageBankruptcyAdapterService.getData(initData.StagesBankruptcy),
      MessagesAboutBankruptcy: this.individualMessageAboutBankruptcyAdapterService.getData(initData.MessagesAboutBankruptcy),
      CriminalPenalties: this.individualCriminalPenaltiesAdapterService.getData(initData.CriminalPenalties),
      FinancialScoring: this.individualFinancialScoringAdapterService.getData(initData.FinancialScoring),
      TrustScoring: this.individualTrustScoringAdapterService.getData(initData.TrustScoring),
      Fines: this.reportFineAdapterService.getData(initData.Fines),
      WantedMvd: this.individualWantedMvdAdapterService.getData(initData.WantedMvd),
      WantedFsin: this.individualWantedFsinAdapterService.getData(initData.WantedFsin),
      WantedFssp: this.individualWantedFsspAdapterService.getData(initData.WantedFssp),
      FiosChanges: this.individualFullNameChangeAdapterService.getData(initData.FiosChanges),
      PossibleFios: this.reportPossibleFullNameAdapterService.getData(initData.PossibleFios),
      PossibleBirthdays: this.reportPossibleBirthdaysAdapterService.getData(initData.PossibleBirthdays),
      IndividualTypes: this.individualTypeAdapterService.getData(initData.IndividualTypes),
      TerroristsAndExtremistsList: this.individualTerroristsAndExtremistsRecordAdapterService.getData(initData.TerroristsAndExtremistsList),
      TaxDebtsAccruals: this.individualAccrualAdapterService.getData(initData.TaxDebtsAccruals),
      FineAccruals: this.individualAccrualAdapterService.getData(initData.FineAccruals),
      StateDutyAccruals: this.individualAccrualAdapterService.getData(initData.StateDutyAccruals),
      LegalDebtAccruals: this.individualAccrualAdapterService.getData(initData.LegalDebtAccruals),
      ServiceBillAccruals: this.individualAccrualAdapterService.getData(initData.ServiceBillAccruals),
      PossiblePasswords: this.reportArrayStringAdapterService.getData(initData.PossiblePasswords),
      Wanted: this.individualWantedAdapterService.getData(initData.Wanted),
      CovidMovements: this.reportMovementCovidAdapterService.getData(initData.CovidMovements),
      TaxiMovements: this.reportMovementTaxiAdapterService.getData(initData.TaxiMovements),
      BusMovements: this.individualMovementBusAdapterService.getData(initData.BusMovements),
      MaritalStatusHistory: this.individualMaritalStatusHistoryAdapterService.getData(initData.MaritalStatusHistory),
      Markers: this.individualMarkersAdapterService.getData(initData.Markers),
      Files: mapData(initData.Files, (item) => this.reportMainEntityFileAdapterService.getData(item)),
      Photos: mapData(initData.Photos, (item) => this.reportMainEntityFileAdapterService.getData(item)),
      MessageBoards: this.reportMessageBoardRecordAdapterService.getData(initData.MessageBoards),
      VettingInfo: this.reportVettingInfoAdapterService.getData(initData.VettingInfo)
    };
  }
}
