import { injectable } from 'inversify';
import { IndividualPhoneDTO } from '~/types/dto/individual/phoneDTO';
import { IReportSubscriberNumberConfigService } from './reportSubscriberNumberConfigService.interface';
import { Config } from '~/types/field-list/config';

@injectable()
export class ReportSubscriberNumberConfigService implements IReportSubscriberNumberConfigService {
  getConfig(initData: IndividualPhoneDTO): Config {
    return {
      fieldsDirection: 'divider-row',
      fieldsWidth: '100%',
      fieldsValueWidth: '350px',
      isBeginningDivider: true,
      fields: [
        {
          key: 'phoneType',
          label: 'Тип телефона',
          value: initData.PhoneType
        },
        {
          key: 'provider',
          label: 'Оператор связи',
          value: initData.Provider,
        },
        {
          key: 'country',
          label: 'Страна',
          value: initData.Country
        },
        {
          key: 'deviceModel',
          label: 'Модель телефона',
          value: initData.DeviceModel
        },
        {
          key: 'timeZone',
          label: 'Часовой пояс',
          value: initData.TimeZone
        }
      ]
    };
  }
}
