import { injectable, inject } from 'inversify';
import { LegalEntitiesCompanyFounderShare } from '~/types/legal-entities/companyFounderShare';
import { IPrimitiveTypesNormalizationServiceKey, IPrimitiveTypesNormalizationService } from '../common/primitiveTypesNormalizationService.interfaces';
import { INormalizationService } from '../common/reportNormalizationService.interfaces';

@injectable()
export class LegalEntitiesCompanyFounderShareNormalizationService implements INormalizationService<LegalEntitiesCompanyFounderShare> {
  constructor(
    @inject(IPrimitiveTypesNormalizationServiceKey as symbol) private primitiveTypesNormalizationService: IPrimitiveTypesNormalizationService
  ) {}

  normalize(data: any, root: string): LegalEntitiesCompanyFounderShare | undefined {
    const { normalizeOptionalInteger, normalizeObject } = this.primitiveTypesNormalizationService;

    return normalizeObject(data, (data) => ({
      Sum: normalizeOptionalInteger(data.Sum, `${root}.Sum`),
      PercentagePlain: normalizeOptionalInteger(data.PercentagePlain, `${root}.PercentagePlain`),
      PercentageNominator: normalizeOptionalInteger(data.PercentageNominator, `${root}.PercentageNominator`),
      PercentageDenominator: normalizeOptionalInteger(data.PercentageDenominator, `${root}.PercentageDenominator`)
    }), root);
  }
}
