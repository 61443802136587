/* eslint-disable class-methods-use-this, max-len */

import { injectable } from 'inversify';
import { StringUtils } from '~/utils/stringUtils';
import { LegalEntitiesSubCompanyInfo } from '~/types/legal-entities/subCompanyInfo';
import {
  ILegalEntitiesSubCompanyInfoAdapterService
} from '~/services/adapters/legalEntities/legalEntitiesAdapterService.interfaces';
import { LegalEntitiesSubCompanyInfoDTO } from '~/types/dto/legalEntities/subCompanyInfo';

@injectable()
export class LegalEntitiesSubCompanyInfoAdapterService implements ILegalEntitiesSubCompanyInfoAdapterService {
  getData(initData: LegalEntitiesSubCompanyInfo[]): LegalEntitiesSubCompanyInfoDTO[] {
    return (initData ?? []).map((item) => ({
      CompanyAddress: item.CompanyAddress,
      CompanyName: StringUtils.quoteReplacement(item.CompanyName ?? ''),
      Source: item.Source,
      FirstDate: item.FirstDate ?? '',
      PercentCapital: item.PercentCapital ?? 0
    }));
  }
}
