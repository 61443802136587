import { injectable, inject } from 'inversify';
import { IndividualLegalEntitiesParticipationRestriction } from '~/types/individual/legalEntitiesParticipationRestriction';
import { IPrimitiveTypesNormalizationServiceKey, IPrimitiveTypesNormalizationService } from '../common/primitiveTypesNormalizationService.interfaces';
import { INormalizationService } from '../common/reportNormalizationService.interfaces';

@injectable()
export class IndividualLegalEntitiesParticipationRestrictionNormalizationService implements INormalizationService<IndividualLegalEntitiesParticipationRestriction> {
  constructor(
    @inject(IPrimitiveTypesNormalizationServiceKey as symbol) private primitiveTypesNormalizationService: IPrimitiveTypesNormalizationService,
  ) {}

  normalize(data: any, root: string): IndividualLegalEntitiesParticipationRestriction | undefined {
    const { normalizeObject, normalizeBoolean, normalizeString, normalizeOptionalString } = this.primitiveTypesNormalizationService;

    return normalizeObject(data, (data) => (
      {
        ParticipationType: normalizeOptionalString(data.ParticipationType, `${root}.ParticipationType`),
        CompanyName: normalizeString(data.CompanyName, `${root}.CompanyName`),
        Inn: normalizeString(data.Inn, `${root}.Inn`),
        Ogrn: normalizeString(data.Ogrn, `${root}.Ogrn`),
        Status: normalizeOptionalString(data.Status, `${root}.Status`),
        PrincipalActivity: normalizeString(data.PrincipalActivity, `${root}.PrincipalActivity`),
        RiskIndicator: normalizeOptionalString(data.RiskIndicator, `${root}.RiskIndicator`),
        Address: normalizeString(data.Address, `${root}.Address`),
        RegistrationDate: normalizeString(data.RegistrationDate, `${root}.RegistrationDate`),
        LiquidationDate: normalizeOptionalString(data.LiquidationDate, `${root}.LiquidationDate`),
        Position: normalizeString(data.Position, `${root}.Position`),
        IsHistoric: normalizeBoolean(data.IsHistoric, `${root}.IsHistoric`),
      }), root);
  }
}
