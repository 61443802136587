import { injectable } from 'inversify';
import { IIndividualTrustScoringAdapterService } from '~/services/adapters/individual/individualAdapterService.interfaces';
import { IndividualTrustScoring } from '~/types/individual/trustScoring';
import { IndividualTrustScoringDTO } from '~/types/dto/individual/trustScoringDTO';

@injectable()
export class IndividualTrustScoringAdapterService implements IIndividualTrustScoringAdapterService {
  getData(initData: Array<IndividualTrustScoring>): Array<IndividualTrustScoringDTO> {
    return (initData ?? []).map((item) => ({
      CheckedFio: item.CheckedFio ?? '',
      CheckedBirthday: item.CheckedBirthday ?? '',
      IncidentColorOne: item.IncidentColorOne ?? '',
      IncidentTypeOne: item.IncidentTypeOne ?? '',
      IncidentColorTwo: item.IncidentColorTwo ?? '',
      IncidentTypeTwo: item.IncidentTypeTwo ?? '',
      IncidentColorThree: item.IncidentColorThree ?? '',
      IncidentTypeThree: item.IncidentTypeThree ?? '',
      IncidentColorFour: item.IncidentColorFour ?? '',
      IncidentTypeFour: item.IncidentTypeFour ?? '',
      IncidentColorFive: item.IncidentColorFive ?? '',
      IncidentTypeFive: item.IncidentTypeFive ?? '',
      IncidentColorSix: item.IncidentColorSix ?? '',
      IncidentTypeSix: item.IncidentTypeSix ?? '',
    }));
  }
}
