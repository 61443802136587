import { injectable, inject } from 'inversify';

import {
  ILegalEntitiesActivitySummaryNormalizationServiceKey,
  ILegalEntitiesBankAccountNormalizationServiceKey,
  ILegalEntitiesCertificateSummaryNormalizationServiceKey,
  ILegalEntitiesCompanyActualAddressNormalizationServiceKey,
  ILegalEntitiesCompanyEmailNormalizationServiceKey,
  ILegalEntitiesCompanyEmployeeInfoNormalizationServiceKey,
  ILegalEntitiesCompanyFounderNormalizationServiceKey,
  ILegalEntitiesCompanyHeadFlNormalizationServiceKey,
  ILegalEntitiesCompanyHistoryAddressNormalizationServiceKey,
  ILegalEntitiesCompanyManagementCompaniesNormalizationServiceKey,
  ILegalEntitiesCompanyNameNormalizationServiceKey,
  ILegalEntitiesCompanyPhoneNormalizationServiceKey,
  ILegalEntitiesCompanySiteNormalizationServiceKey,
  ILegalEntitiesCompanyStatedCapitalNormalizationServiceKey,
  ILegalEntitiesCompanyTaxRegimesNormalizationServiceKey,
  ILegalEntitiesConditionAssessmentNormalizationServiceKey,
  ILegalEntitiesConflictSignNormalizationServiceKey,
  ILegalEntitiesContractsInfoByYearNormalizationServiceKey,
  ILegalEntitiesCourtCasesByYearNormalizationServiceKey,
  ILegalEntitiesEgrRecordNormalizationServiceKey,
  ILegalEntitiesEnforcementProceedingsInfoBySubjectNormalizationServiceKey,
  ILegalEntitiesFineAndTaxDebtNormalizationServiceKey,
  ILegalEntitiesGovernmentControlNormalizationServiceKey,
  ILegalEntitiesInsuranceFundInfoNormalizationServiceKey,
  ILegalEntitiesLesseeSummaryNormalizationServiceKey,
  ILegalEntitiesLicenseSummaryNormalizationServiceKey,
  ILegalEntitiesPredecessorNormalizationServiceKey,
  ILegalEntitiesRegistrarOfShareholdersNormalizationServiceKey,
  ILegalEntitiesRelatedCompanyNormalizationServiceKey,
  ILegalEntitiesRelatedEgrulNormalizationServiceKey,
  ILegalEntitiesReorganizationMemberNormalizationServiceKey,
  ILegalEntitiesSelfRegulatoryOrganizationNormalizationServiceKey,
  ILegalEntitiesShareholderNormalizationServiceKey,
  ILegalEntitiesSocialNormalizationServiceKey,
  ILegalEntitiesSubCompanyInfoNormalizationServiceKey,
  ILegalEntitiesSuccessorNormalizationServiceKey,
  ILegalEntitiesTaxModeNormalizationServiceKey,
  ILegalEntitiesTaxNormalizationServiceKey,
  ILegalEntitiesTaxServiceNormalizationServiceKey,
  ILegalEntitiesTaxesPenaltiesNormalizationServiceKey,
  ILegalEntitiesTrademarkNormalizationServiceKey,
  ILegalEntitiesAverageHeadcountNormalizationServiceKey,
} from './legalEntitiesNormalizationService.interfaces';
import {
  ILegalEntitiesActivitySummaryPlaceholderDataServiceKey,
  ILegalEntitiesBriefInfoPlaceholderDataServiceKey,
  ILegalEntitiesCompanyActualAddressPlaceholderServiceKey,
  ILegalEntitiesConditionAssessmentPlaceholderDataServiceKey,
  ILegalEntitiesLesseeSummaryPlaceholderDataServiceKey,
  ILegalEntitiesRegistrarOfShareholdersPlaceholderDataServiceKey,
  ILegalEntitiesTaxModePlaceholderDataServiceKey,
  ILegalEntitiesTaxServicePlaceholderServiceKey
} from '~/services/placeholder/legalEntities/legalEntitiesPlaceholderDataService.interfaces';
import {
  INormalizationService,
  IReportLegalEntitiesParticipationNormalizationServiceKey,
  IReportVehicleNormalizationServiceKey,
  IReportPledgesRegistryNormalizationServiceKey,
  IReportUrlNormalizationServiceKey,
  IReportVehicleParkingNormalizationServiceKey,
  IReportConnectionRelatedNormalizationServiceKey
} from '../common/reportNormalizationService.interfaces';
import { ReportUrl } from '~/types/common/reportUrl';
import { LegalEntitiesBriefInfo } from '~/types/legal-entities/briefInfo';
import { LegalEntitiesTaxServiceInfo } from '~/types/legal-entities/taxServiceInfo';
import { LegalEntitiesCompanyActualAddress } from '~/types/legal-entities/companyActualAddress';
import { LegalEntitiesConditionAssessment } from '~/types/legal-entities/conditionAssessment';
import { LegalEntitiesTaxMode } from '~/types/legal-entities/taxMode';
import { LegalEntitiesRegistrarOfShareholders } from '~/types/legal-entities/registrarOfShareholders';
import { LegalEntitiesLesseeSummary } from '~/types/legal-entities/lesseeSummary';
import { LegalEntitiesActivitySummary } from '~/types/legal-entities/activitySummary';
import { LegalEntitiesCompanySite } from '~/types/legal-entities/companySite';
import { LegalEntitiesCompanyPhone } from '~/types/legal-entities/companyPhone';
import { LegalEntitiesCompanyEmail } from '~/types/legal-entities/companyEmail';
import { LegalEntitiesSocial } from '~/types/legal-entities/social';
import { LegalEntitiesCompanyStatedCapital } from '~/types/legal-entities/companyStatedCapital';
import { LegalEntitiesCompanyHistoryAddress } from '~/types/legal-entities/companyHistoryAddress';
import { LegalEntitiesCompanyHeadFl } from '~/types/legal-entities/companyHeadFl';
import { LegalEntitiesCompanyManagementCompanies } from '~/types/legal-entities/companyManagementCompanies';
import { LegalEntitiesCompanyFounder } from '~/types/legal-entities/companyFounder';
import { LegalEntitiesCourtCasesByYear } from '~/types/legal-entities/courtCasesByYear';
import { LegalEntitiesGovernmentControl } from '~/types/legal-entities/governmentControl';
import { LegalEntitiesEnforcementProceedingsInfoBySubject } from '~/types/legal-entities/enforcementProceedingsInfoBySubject';
import { LegalEntitiesRelatedCompany } from '~/types/legal-entities/relatedCompany';
import { LegalEntitiesRelatedEgrul } from '~/types/legal-entities/relatedEgrul';
import { LegalEntitiesConflictSign } from '~/types/legal-entities/conflictSign';
import { LegalEntitiesTax } from '~/types/legal-entities/tax';
import { LegalEntitiesFineAndTaxDebt } from '~/types/legal-entities/fineAndTaxDebt';
import { LegalEntitiesContractsInfoByYear } from '~/types/legal-entities/contractsInfoByYear';
import { LegalEntitiesSubCompanyInfo } from '~/types/legal-entities/subCompanyInfo';
import { LegalEntitiesPredecessor } from '~/types/legal-entities/predecessor';
import { LegalEntitiesSuccessor } from '~/types/legal-entities/successor';
import { LegalEntitiesReorganisationMember } from '~/types/legal-entities/reorganisationMember';
import { LegalEntitiesShareholder } from '~/types/legal-entities/shareholder';
import { LegalEntitiesEgrRecord } from '~/types/legal-entities/egrRecord';
import { LegalEntitiesCertificateSummary } from '~/types/legal-entities/certificateSummary';
import { LegalEntitiesLicenseSummary } from '~/types/legal-entities/licenseSummary';
import { LegalEntitiesSelfRegulatoryOrganization } from '~/types/legal-entities/selfRegulatoryOrganization';
import { LegalEntitiesTrademark } from '~/types/legal-entities/trademark';
import { LegalEntitiesCompanyName } from '~/types/legal-entities/companyName';
import { LegalEntitiesInsuranceFundInfo } from '~/types/legal-entities/insuranceFundInfo';
import { ReportLegalEntitiesParticipation } from '~/types/common/reportLegalEntitiesParticipation';
import { LegalEntitiesCompanyEmployeeInfo } from '~/types/legal-entities/companyEmployeeInfo';
import { ReportVehicle } from '~/types/common/reportVehicle';
import { ReportPledgesRegistry } from '~/types/common/reportPledgesRegistry';
import { ReportVehicleParking } from '~/types/common/reportVehicleParking';
import {
  IPlaceholderDataService,
  IReportConnectionRelatedPlaceholderDataServiceKey,
  IReportUrlPlaceholderServiceKey
} from '~/services/placeholder/common/reportPlaceholderService.interfaces';
import { IPrimitiveTypesNormalizationServiceKey, IPrimitiveTypesNormalizationService } from '../common/primitiveTypesNormalizationService.interfaces';
import { ILegalEntitiesCompanyStatusNormalizationService, ILegalEntitiesCompanyStatusNormalizationServiceKey } from './legalEntitiesCompanyStatusNormalizationService';
import { LegalEntitiesTaxesPenalties } from '~/types/legal-entities/taxesPenalties';
import { LegalEntitiesCompanyTaxRegimesNormalizationService } from './legalEntitiesCompanyTaxRegimesNormalizationService';
import { LegalEntitiesBankAccount } from '~/types/legal-entities/bankAccount';
import { ReportConnectionRelated } from '~/types/common/reportConnectionRelated';
import { LegalEntitiesAverageHeadcount } from '~/types/legal-entities/averageHeadcount';

@injectable()
export class LegalEntitiesBriefInfoNormalizationService implements INormalizationService<LegalEntitiesBriefInfo> {
  constructor(
    @inject(IReportUrlNormalizationServiceKey as symbol) private reportUrlNormalizationService: INormalizationService<ReportUrl>,
    @inject(ILegalEntitiesBriefInfoPlaceholderDataServiceKey as symbol) private briefInfoPlaceholderDataService: IPlaceholderDataService<LegalEntitiesBriefInfo>,
    @inject(ILegalEntitiesTaxServiceNormalizationServiceKey as symbol) private taxServiceNormalizationService: INormalizationService<LegalEntitiesTaxServiceInfo>,
    @inject(ILegalEntitiesCompanyActualAddressNormalizationServiceKey as symbol) private companyActualAddressNormalizationService: INormalizationService<LegalEntitiesCompanyActualAddress>,
    @inject(ILegalEntitiesConditionAssessmentNormalizationServiceKey as symbol) private conditionAssessmentNormalizationService: INormalizationService<LegalEntitiesConditionAssessment>,
    @inject(ILegalEntitiesTaxModeNormalizationServiceKey as symbol) private taxModeNormalizationService: INormalizationService<LegalEntitiesTaxMode>,
    @inject(ILegalEntitiesRegistrarOfShareholdersNormalizationServiceKey as symbol) private registrarOfShareholdersNormalizationService: INormalizationService<LegalEntitiesRegistrarOfShareholders>,
    @inject(ILegalEntitiesLesseeSummaryNormalizationServiceKey as symbol) private lesseeSummaryNormalizationService: INormalizationService<LegalEntitiesLesseeSummary>,
    @inject(ILegalEntitiesActivitySummaryNormalizationServiceKey as symbol) private activitySummaryNormalizationService: INormalizationService<LegalEntitiesActivitySummary>,
    @inject(ILegalEntitiesCompanySiteNormalizationServiceKey as symbol) private companySiteNormalizationService: INormalizationService<LegalEntitiesCompanySite>,
    @inject(ILegalEntitiesCompanyPhoneNormalizationServiceKey as symbol) private companyPhoneNormalizationService: INormalizationService<LegalEntitiesCompanyPhone>,
    @inject(ILegalEntitiesCompanyEmailNormalizationServiceKey as symbol) private companyEmailNormalizationService: INormalizationService<LegalEntitiesCompanyEmail>,
    @inject(ILegalEntitiesSocialNormalizationServiceKey as symbol) private socialNormalizationService: INormalizationService<LegalEntitiesSocial>,
    @inject(ILegalEntitiesCompanyStatedCapitalNormalizationServiceKey as symbol) private companyStatedCapitalNormalizationService: INormalizationService<LegalEntitiesCompanyStatedCapital>,
    @inject(ILegalEntitiesCompanyHistoryAddressNormalizationServiceKey as symbol) private companyHistoryAddressNormalizationService: INormalizationService<LegalEntitiesCompanyHistoryAddress>,
    @inject(ILegalEntitiesCompanyHeadFlNormalizationServiceKey as symbol) private companyHeadFlNormalizationService: INormalizationService<LegalEntitiesCompanyHeadFl>,
    @inject(ILegalEntitiesCompanyManagementCompaniesNormalizationServiceKey as symbol) private companyManagementCompaniesNormalizationService: INormalizationService<LegalEntitiesCompanyManagementCompanies>,
    @inject(ILegalEntitiesCompanyFounderNormalizationServiceKey as symbol) private companyFounderNormalizationService: INormalizationService<LegalEntitiesCompanyFounder>,
    @inject(ILegalEntitiesCourtCasesByYearNormalizationServiceKey as symbol) private courtCasesByYearNormalizationService: INormalizationService<LegalEntitiesCourtCasesByYear>,
    @inject(ILegalEntitiesGovernmentControlNormalizationServiceKey as symbol) private governmentControlNormalizationService: INormalizationService<LegalEntitiesGovernmentControl>,
    @inject(ILegalEntitiesEnforcementProceedingsInfoBySubjectNormalizationServiceKey as symbol) private enforcementProceedingsInfoBySubjectNormalizationService: INormalizationService<LegalEntitiesEnforcementProceedingsInfoBySubject>,
    @inject(ILegalEntitiesRelatedCompanyNormalizationServiceKey as symbol) private relatedCompanyNormalizationService: INormalizationService<LegalEntitiesRelatedCompany>,
    @inject(ILegalEntitiesRelatedEgrulNormalizationServiceKey as symbol) private relatedEgrulNormalizationService: INormalizationService<LegalEntitiesRelatedEgrul>,
    @inject(ILegalEntitiesConflictSignNormalizationServiceKey as symbol) private conflictSignNormalizationService: INormalizationService<LegalEntitiesConflictSign>,
    @inject(ILegalEntitiesTaxNormalizationServiceKey as symbol) private taxNormalizationService: INormalizationService<LegalEntitiesTax>,
    @inject(ILegalEntitiesFineAndTaxDebtNormalizationServiceKey as symbol) private fineAndTaxDebtNormalizationService: INormalizationService<LegalEntitiesFineAndTaxDebt>,
    @inject(ILegalEntitiesTaxesPenaltiesNormalizationServiceKey as symbol) private taxesPenaltiesNormalizationService: INormalizationService<LegalEntitiesTaxesPenalties>,
    @inject(ILegalEntitiesContractsInfoByYearNormalizationServiceKey as symbol) private contractsInfoByYearNormalizationService: INormalizationService<LegalEntitiesContractsInfoByYear>,
    @inject(ILegalEntitiesSubCompanyInfoNormalizationServiceKey as symbol) private subCompanyInfoNormalizationService: INormalizationService<LegalEntitiesSubCompanyInfo>,
    @inject(ILegalEntitiesPredecessorNormalizationServiceKey as symbol) private predecessorNormalizationService: INormalizationService<LegalEntitiesPredecessor>,
    @inject(ILegalEntitiesSuccessorNormalizationServiceKey as symbol) private successorNormalizationService: INormalizationService<LegalEntitiesSuccessor>,
    @inject(ILegalEntitiesReorganizationMemberNormalizationServiceKey as symbol) private reorganizationMemberNormalizationService: INormalizationService<LegalEntitiesReorganisationMember>,
    @inject(ILegalEntitiesShareholderNormalizationServiceKey as symbol) private shareholderNormalizationService: INormalizationService<LegalEntitiesShareholder>,
    @inject(ILegalEntitiesEgrRecordNormalizationServiceKey as symbol) private egrRecordNormalizationService: INormalizationService<LegalEntitiesEgrRecord>,
    @inject(ILegalEntitiesCertificateSummaryNormalizationServiceKey as symbol) private certificateSummaryNormalizationService: INormalizationService<LegalEntitiesCertificateSummary>,
    @inject(ILegalEntitiesLicenseSummaryNormalizationServiceKey as symbol) private licenseSummaryNormalizationService: INormalizationService<LegalEntitiesLicenseSummary>,
    @inject(ILegalEntitiesSelfRegulatoryOrganizationNormalizationServiceKey as symbol) private selfRegulatoryOrganizationNormalizationService: INormalizationService<LegalEntitiesSelfRegulatoryOrganization>,
    @inject(ILegalEntitiesTrademarkNormalizationServiceKey as symbol) private trademarkNormalizationService: INormalizationService<LegalEntitiesTrademark>,
    @inject(ILegalEntitiesCompanyNameNormalizationServiceKey as symbol) private companyNameNormalizationService: INormalizationService<LegalEntitiesCompanyName>,
    @inject(ILegalEntitiesInsuranceFundInfoNormalizationServiceKey as symbol) private insuranceFundInfoNormalizationService: INormalizationService<LegalEntitiesInsuranceFundInfo>,
    @inject(IReportLegalEntitiesParticipationNormalizationServiceKey as symbol) private reportLegalEntitiesParticipationNormalizationService: INormalizationService<ReportLegalEntitiesParticipation>,
    @inject(ILegalEntitiesCompanyEmployeeInfoNormalizationServiceKey as symbol) private companyEmployeeInfoNormalizationService: INormalizationService<LegalEntitiesCompanyEmployeeInfo>,
    @inject(IReportVehicleNormalizationServiceKey as symbol) private reportVehicleNormalizationService: INormalizationService<ReportVehicle>,
    @inject(IReportPledgesRegistryNormalizationServiceKey as symbol) private reportPledgesRegistryNormalizationService: INormalizationService<ReportPledgesRegistry>,
    @inject(IReportVehicleParkingNormalizationServiceKey as symbol) private reportVehicleParkingNormalizationService: INormalizationService<ReportVehicleParking>,
    @inject(ILegalEntitiesAverageHeadcountNormalizationServiceKey as symbol) private legalEntitiesAverageHeadcountNormalizationService: INormalizationService<LegalEntitiesAverageHeadcount>,

    @inject(IReportConnectionRelatedNormalizationServiceKey as symbol) private reportConnectionRelatedNormalizationService: INormalizationService<ReportConnectionRelated>,
    @inject(IReportConnectionRelatedPlaceholderDataServiceKey as symbol) private reportConnectionRelatedPlaceholderDataService: IPlaceholderDataService<ReportConnectionRelated>,
    @inject(IReportUrlPlaceholderServiceKey as symbol) private reportUrlPlaceholderService: IPlaceholderDataService<ReportUrl>,
    @inject(ILegalEntitiesTaxServicePlaceholderServiceKey as symbol) private taxServicePlaceholderService: IPlaceholderDataService<LegalEntitiesTaxServiceInfo>,
    @inject(ILegalEntitiesCompanyActualAddressPlaceholderServiceKey as symbol) private companyActualAddressPlaceholderService: IPlaceholderDataService<LegalEntitiesCompanyActualAddress>,
    @inject(ILegalEntitiesConditionAssessmentPlaceholderDataServiceKey as symbol) private conditionAssessmentPlaceholderData: IPlaceholderDataService<LegalEntitiesConditionAssessment>,
    @inject(ILegalEntitiesTaxModePlaceholderDataServiceKey as symbol) private taxModePlaceholderDataService: IPlaceholderDataService<LegalEntitiesTaxMode>,
    @inject(ILegalEntitiesRegistrarOfShareholdersPlaceholderDataServiceKey as symbol) private registrarOfShareholdersPlaceholderDataService: IPlaceholderDataService<LegalEntitiesRegistrarOfShareholders>,
    @inject(ILegalEntitiesLesseeSummaryPlaceholderDataServiceKey as symbol) private lesseeSummaryPlaceholderDataService:IPlaceholderDataService<LegalEntitiesLesseeSummary>,
    @inject(ILegalEntitiesActivitySummaryPlaceholderDataServiceKey as symbol) private activitySummaryPlaceholderDataService:IPlaceholderDataService<LegalEntitiesActivitySummary>,
    @inject(ILegalEntitiesCompanyStatusNormalizationServiceKey as symbol) private legalEntitiesCompanyStatusNormalizationService: ILegalEntitiesCompanyStatusNormalizationService,

    @inject(IPrimitiveTypesNormalizationServiceKey as symbol) private primitiveTypesNormalizationService: IPrimitiveTypesNormalizationService,
    @inject(ILegalEntitiesCompanyTaxRegimesNormalizationServiceKey as symbol) private legalEntitiesCompanyTaxRegimesNormalizationService: LegalEntitiesCompanyTaxRegimesNormalizationService,
    @inject(ILegalEntitiesBankAccountNormalizationServiceKey as symbol) private legalEntitiesBankAccountNormalizationService: INormalizationService<LegalEntitiesBankAccount>
  ) {}

  normalize(data: any, root: string): LegalEntitiesBriefInfo | undefined {
    const {
      normalizeString,
      normalizeOptionalInteger,
      normalizeOptionalString,
      normalizeArray,
      normalizeObject
    } = this.primitiveTypesNormalizationService;

    return normalizeObject(data, (data) => ({
      CompanyTaxRegimes: normalizeArray(data.CompanyTaxRegimes, (item, field) => this.legalEntitiesCompanyTaxRegimesNormalizationService.normalize(item, field), `${root}.CompanyTaxRegimes`),
      BankAccounts: normalizeArray(data.BankAccounts, (item, field) => this.legalEntitiesBankAccountNormalizationService.normalize(item, field), `${root}.BankAccounts`),
      CompanyStatuses: normalizeArray(data.CompanyStatuses, (item, field) => this.legalEntitiesCompanyStatusNormalizationService.normalize(item, field), `${root}.CompanyStatuses`),
      SitesLinks: normalizeArray(data.SitesLinks, (item, field) => this.reportUrlNormalizationService.normalize(item, field), `${root}.SitesLinks`),
      Sites: normalizeArray(data.Sites, (item, field) => this.companySiteNormalizationService.normalize(item, field), `${root}.Sites`),
      SitesUrl: this.reportUrlNormalizationService.normalize(data.SitesUrl, `${root}.SitesUrl`) ?? this.reportUrlPlaceholderService.getData(),
      Phones: normalizeArray(data.Phones, (item, field) => this.companyPhoneNormalizationService.normalize(item, field), `${root}.Phones`),
      PhonesUrl: this.reportUrlNormalizationService.normalize(data.PhonesUrl, `${root}.PhonesUrl`) ?? this.reportUrlPlaceholderService.getData(),
      EmailsUrl: this.reportUrlNormalizationService.normalize(data.EmailsUrl, `${root}.EmailsUrl`) ?? this.reportUrlPlaceholderService.getData(),
      EmailsLinks: normalizeArray(data.EmailsLinks, (item, field) => this.reportUrlNormalizationService.normalize(item, field), `${root}.EmailsLinks`),
      Emails: normalizeArray(data.Emails, (item, field) => this.companyEmailNormalizationService.normalize(item, field), `${root}.Emails`),
      SocialsUrl: this.reportUrlNormalizationService.normalize(data.SocialsUrl, `${root}.SocialsUrl`) ?? this.reportUrlPlaceholderService.getData(),
      Socials: normalizeArray(data.Socials, (item, field) => this.socialNormalizationService.normalize(item, field), `${root}.Socials`),
      HtagsUrl: this.reportUrlNormalizationService.normalize(data.HtagsUrl, `${root}.HtagsUrl`) ?? this.reportUrlPlaceholderService.getData(),
      Htags: normalizeArray(data.Htags, (item, field) => this.reportUrlNormalizationService.normalize(item, field), `${root}.HtagsUrl`),
      CompanyRegistrationDate: normalizeOptionalString(data.CompanyRegistrationDate as string, `${root}.CompanyRegistrationDate`),
      CompanyLiquidationDate: normalizeOptionalString(data.CompanyLiquidationDate as string, `${root}.CompanyLiquidationDate`),
      CompanyAgeInYears: normalizeOptionalInteger(data.CompanyAgeInYears as number, `${root}.CompanyAgeInYears`),
      DateGetOgrn: normalizeOptionalString(data.DateGetOgrn as string, `${root}.DateGetOgrn`),
      RegistrationTaxService: this.taxServiceNormalizationService.normalize(data.RegistrationTaxService, `${root}.RegistrationTaxService`) ?? this.taxServicePlaceholderService.getData(),
      CurrentTaxService: this.taxServiceNormalizationService.normalize(data.CurrentTaxService, `${root}.CurrentTaxService`) ?? this.taxServicePlaceholderService.getData(),
      CompanyStatedCapitalSum: normalizeOptionalInteger(data.CompanyStatedCapitalSum as number, `${root}.CompanyStatedCapitalSum`),
      HistoryOfCompanyStatedCapital: normalizeArray(data.HistoryOfCompanyStatedCapital, (item, field) => this.companyStatedCapitalNormalizationService.normalize(item, field), `${root}.HistoryOfCompanyStatedCapital`),
      CompanyMainActivityText: normalizeString(data.CompanyMainActivityText as string, `${root}.CompanyMainActivityText`),
      CompanyAddress: this.companyActualAddressNormalizationService.normalize(data.CompanyAddress, `${root}.CompanyAddress`) ?? this.companyActualAddressPlaceholderService.getData(),
      CompanyAddressStory: normalizeArray(data.CompanyAddressStory, (item, field) => this.companyHistoryAddressNormalizationService.normalize(item, field), `${root}.CompanyAddressStory`),
      CompanyFlHeads: normalizeArray(data.CompanyFlHeads, (item, field) => this.companyHeadFlNormalizationService.normalize(item, field), `${root}.CompanyFlHeads`),
      HistoryOfCompanyHeads: normalizeArray(data.HistoryOfCompanyHeads, (item, field) => this.companyHeadFlNormalizationService.normalize(item, field), `${root}.HistoryOfCompanyHeads`),
      ManagementCompanies: normalizeArray(data.ManagementCompanies, (item, field) => this.companyManagementCompaniesNormalizationService.normalize(item, field), `${root}.ManagementCompanies`),
      HistoryOfManagementCompanies: normalizeArray(data.HistoryOfManagementCompanies, (item, field) => this.companyManagementCompaniesNormalizationService.normalize(item, field), `${root}.HistoryOfManagementCompanies`),
      HistoryOfCompanyFounders: normalizeArray(data.HistoryOfCompanyFounders, (item, field) => this.companyFounderNormalizationService.normalize(item, field), `${root}.HistoryOfCompanyFounders`),
      CompanyFounders: normalizeArray(data.CompanyFounders, (item, field) => this.companyFounderNormalizationService.normalize(item, field), `${root}.CompanyFounders`),
      CompanyStatus: normalizeOptionalString(data.CompanyStatus as string, `${root}.CompanyStatus`),
      CompanyStatusDate: normalizeOptionalString(data.CompanyStatusDate as string, `${root}.CompanyStatusDate`),
      EmployeeAvgCount: normalizeOptionalInteger(data.EmployeeAvgCount as number, `${root}.EmployeeAvgCount`),
      MspCategory: normalizeString(data.MspCategory as string, `${root}.MspCategory`),
      SubCompaniesCount: normalizeOptionalInteger(data.SubCompaniesCount as number, `${root}.SubCompaniesCount`),
      FilialsCount: normalizeOptionalInteger(data.FilialsCount as number, `${root}.FilialsCount`),
      IscClaims: normalizeOptionalInteger(data.IscClaims as number, `${root}.IscClaims`),
      IscObligation: normalizeOptionalInteger(data.IscObligation as number, `${root}.IscObligation`),
      FsspDept: normalizeOptionalInteger(data.FsspDept as number, `${root}.FsspDept`),
      CourtCasesByYearsPlaintiff: normalizeArray(data.CourtCasesByYearsPlaintiff, (item, field) => this.courtCasesByYearNormalizationService.normalize(item, field), `${root}.CourtCasesByYearsPlaintiff`),
      CourtCasesByYearsDefendant: normalizeArray(data.CourtCasesByYearsDefendant, (item, field) => this.courtCasesByYearNormalizationService.normalize(item, field), `${root}.CourtCasesByYearsDefendant`),
      CourtCasesByYearsThirdParty: normalizeArray(data.CourtCasesByYearsThirdParty, (item, field) => this.courtCasesByYearNormalizationService.normalize(item, field), `${root}.CourtCasesByYearsThirdParty`),
      GovernmentControl: normalizeArray(data.GovernmentControl, (item, field) => this.governmentControlNormalizationService.normalize(item, field), `${root}.GovernmentControl`),
      GovernmentControlAllUrl: this.reportUrlNormalizationService.normalize(data.GovernmentControlAllUrl, `${root}.GovernmentControlAllUrl`) ?? this.reportUrlPlaceholderService.getData(),
      NonCompleatedCasesBySubject: normalizeArray(data.NonCompleatedCasesBySubject, (item, field) => this.enforcementProceedingsInfoBySubjectNormalizationService.normalize(item, field), `${root}.NonCompleatedCasesBySubject`),
      CompleatedCasesBySubject: normalizeArray(data.CompleatedCasesBySubject, (item, field) => this.enforcementProceedingsInfoBySubjectNormalizationService.normalize(item, field), `${root}.CompleatedCasesBySubject`),
      ConditionAssessment: this.conditionAssessmentNormalizationService.normalize(data.ConditionAssessment, `${root}.ConditionAssessment`) ?? this.conditionAssessmentPlaceholderData.getData(),
      RelatedCompanies: normalizeArray(data.RelatedCompanies, (item, field) => this.relatedCompanyNormalizationService.normalize(item, field), `${root}.RelatedCompanies`),
      EgrulRelations: normalizeArray(data.EgrulRelations, (item, field) => this.relatedEgrulNormalizationService.normalize(item, field), `${root}.EgrulRelations`),
      ConflictSigns: normalizeArray(data.ConflictSigns, (item, field) => this.conflictSignNormalizationService.normalize(item, field), `${root}.ConflictSigns`),
      TaxesAndFees: normalizeArray(data.TaxesAndFees, (item, field) => this.taxNormalizationService.normalize(item, field), `${root}.TaxesAndFees`),
      FinesAndTaxDebts: normalizeArray(data.FinesAndTaxDebts, (item, field) => this.fineAndTaxDebtNormalizationService.normalize(item, field), `${root}.FinesAndTaxDebts`),
      TaxesPenalties: normalizeArray(data.TaxesPenalties, (item, field) => this.taxesPenaltiesNormalizationService.normalize(item, field), `${root}.TaxesPenalties`),
      TaxMode: this.taxModeNormalizationService.normalize(data.TaxMode, `${root}.TaxMode`) ?? this.taxModePlaceholderDataService.getData(),
      ContractsInfoByParticipator: normalizeArray(data.ContractsInfoByParticipator, (item, field) => this.contractsInfoByYearNormalizationService.normalize(item, field), `${root}.ContractsInfoByParticipator`),
      ContractsInfoByCustomer: normalizeArray(data.ContractsInfoByCustomer, (item, field) => this.contractsInfoByYearNormalizationService.normalize(item, field), `${root}.ContractsInfoByCustomer`),
      ContractsInfoLinks: normalizeArray(data.ContractsInfoLinks, (item, field) => this.reportUrlNormalizationService.normalize(item, field), `${root}.ContractsInfoLinks`),
      LeasingLinks: normalizeArray(data.LeasingLinks, (item, field) => this.reportUrlNormalizationService.normalize(item, field), `${root}.ContractsInfoLinks`),
      CaseLinks: normalizeArray(data.CaseLinks, (item, field) => this.reportUrlNormalizationService.normalize(item, field), `${root}.ContractsInfoLinks`),
      Filials: normalizeArray(data.Filials, (item, field) => this.subCompanyInfoNormalizationService.normalize(item, field), `${root}.Filials`),
      TaxesAndFeesAllUrl: this.reportUrlNormalizationService.normalize(data.TaxesAndFeesAllUrl, `${root}.TaxesAndFeesAllUrl`) ?? this.reportUrlPlaceholderService.getData(),
      ConflictSignsGrafUrl: this.reportUrlNormalizationService.normalize(data.ConflictSignsGrafUrl, `${root}.ConflictSignsGrafUrl`) ?? this.reportUrlPlaceholderService.getData(),
      Predecessors: normalizeArray(data.Predecessors, (item, field) => this.predecessorNormalizationService.normalize(item, field), `${root}.Predecessors`),
      Successors: normalizeArray(data.Successors, (item, field) => this.successorNormalizationService.normalize(item, field), `${root}.Successors`),
      ReorganisationMembers: normalizeArray(data.ReorganisationMembers, (item, field) => this.reorganizationMemberNormalizationService.normalize(item, field), `${root}.ReorganisationMembers`),
      RegistrarOfShareholders: this.registrarOfShareholdersNormalizationService.normalize(data.RegistrarOfShareholders, `${root}.RegistrarOfShareholders`) ?? this.registrarOfShareholdersPlaceholderDataService.getData(),
      Shareholders: normalizeArray(data.Shareholders, (item, field) => this.shareholderNormalizationService.normalize(item, field), `${root}.Shareholders`),
      HistoryOfShareholders: normalizeArray(data.HistoryOfShareholders, (item, field) => this.shareholderNormalizationService.normalize(item, field), `${root}.HistoryOfShareholders`),
      EgrRecords: normalizeArray(data.EgrRecords, (item, field) => this.egrRecordNormalizationService.normalize(item, field), `${root}.EgrRecords`),
      CertificatesSummary: normalizeArray(data.CertificatesSummary, (item, field) => this.certificateSummaryNormalizationService.normalize(item, field), `${root}.CertificatesSummary`),
      LicensesSummary: normalizeArray(data.LicensesSummary, (item, field) => this.licenseSummaryNormalizationService.normalize(item, field), `${root}.LicensesSummary`),
      LesseeSummary: this.lesseeSummaryNormalizationService.normalize(data.LesseeSummary, `${root}.LesseeSummary`) ?? this.lesseeSummaryPlaceholderDataService.getData(),
      ActivitySummary: this.activitySummaryNormalizationService.normalize(data.ActivitySummary, `${root}.ActivitySummary`) ?? this.activitySummaryPlaceholderDataService.getData(),
      SelfRegulatoryOrganizations: normalizeArray(data.SelfRegulatoryOrganizations, (item, field) => this.selfRegulatoryOrganizationNormalizationService.normalize(item, field), `${root}.SelfRegulatoryOrganizations`),
      Trademarks: normalizeArray(data.Trademarks, (item, field) => this.trademarkNormalizationService.normalize(item, field), `${root}.Trademarks`),
      RelationsWithCounterparties: this.reportConnectionRelatedNormalizationService.normalize(data.RelationsWithCounterparties, `${root}.RelationsWithCounterparties`) ?? this.reportConnectionRelatedPlaceholderDataService.getData(),
      RelationsWithCompetitors: this.reportConnectionRelatedNormalizationService.normalize(data.RelationsWithCompetitors, `${root}.RelationsWithCompetitors`) ?? this.reportConnectionRelatedPlaceholderDataService.getData(),
      RelationsWithBlacklisted: this.reportConnectionRelatedNormalizationService.normalize(data.RelationsWithBlacklisted, `${root}.RelationsWithBlacklisted`) ?? this.reportConnectionRelatedPlaceholderDataService.getData(),
      RelationsWithEmployees: this.reportConnectionRelatedNormalizationService.normalize(data.RelationsWithEmployees, `${root}.RelationsWithEmployees`) ?? this.reportConnectionRelatedPlaceholderDataService.getData(),
      FoundRelations: this.reportConnectionRelatedNormalizationService.normalize(data.FoundRelations, `${root}.FoundRelations`) ?? this.reportConnectionRelatedPlaceholderDataService.getData(),
      HistoryOfCompanyNames: normalizeArray(data.HistoryOfCompanyNames, (item, field) => this.companyNameNormalizationService.normalize(item, field), `${root}.HistoryOfCompanyNames`),
      PensionInsuranceFundRecords: normalizeArray(data.PensionInsuranceFundRecords, (item, field) => this.insuranceFundInfoNormalizationService.normalize(item, field), `${root}.PensionInsuranceFundRecords`),
      SocialInsuranceFundRecords: normalizeArray(data.SocialInsuranceFundRecords, (item, field) => this.insuranceFundInfoNormalizationService.normalize(item, field), `${root}.SocialInsuranceFundRecords`),
      MedicalInsuranceFundRecords: normalizeArray(data.MedicalInsuranceFundRecords, (item, field) => this.insuranceFundInfoNormalizationService.normalize(item, field), `${root}.MedicalInsuranceFundRecords`),
      Participations: normalizeArray(data.Participations, (item, field) => this.reportLegalEntitiesParticipationNormalizationService.normalize(item, field), `${root}.Participations`),
      RelatedEmployees: normalizeArray(data.RelatedEmployees, (item, field) => this.companyEmployeeInfoNormalizationService.normalize(item, field), `${root}.RelatedEmployees`),
      Vehicles: normalizeArray(data.Vehicles, (item, field) => this.reportVehicleNormalizationService.normalize(item, field), `${root}.Vehicles`),
      VehicleParkings: normalizeArray(data.VehicleParkings, (item, field) => this.reportVehicleParkingNormalizationService.normalize(item, field), `${root}.VehicleParkings`),
      ChangeAverageNumberEmployee: normalizeArray(data.ChangeAverageNumberEmployee, (item, field) => this.legalEntitiesAverageHeadcountNormalizationService.normalize(item, field), `${root}.ChangeAverageNumberEmployee`),
    }), root);
  }
}
