import { injectable, inject } from 'inversify';
import { IPlaceholderDataService } from '~/services/placeholder/common/reportPlaceholderService.interfaces';
import { ILegalEntitiesReportFinancialIndicatorBasePlaceholderDataServiceKey } from '~/services/placeholder/legalEntities/legalEntitiesPlaceholderDataService.interfaces';
import { LegalEntitiesReportFinancialIndicatorBase } from '~/types/legal-entities/reportFinancialIndicatorBase';
import { LegalEntitiesReportFinancialIndicatorWithConclusions } from '~/types/legal-entities/reportFinancialIndicatorWithConclusions';
import { IPrimitiveTypesNormalizationServiceKey, IPrimitiveTypesNormalizationService } from '../common/primitiveTypesNormalizationService.interfaces';
import { INormalizationService, IStringArrayNormalizationServiceKey } from '../common/reportNormalizationService.interfaces';
import { ILegalEntitiesReportFinancialIndicatorBaseNormalizationServiceKey } from './legalEntitiesNormalizationService.interfaces';

@injectable()
export class LegalEntitiesReportFinancialIndicatorWithConclusionsNormalizationService implements INormalizationService<LegalEntitiesReportFinancialIndicatorWithConclusions> {
  constructor(

    @inject(ILegalEntitiesReportFinancialIndicatorBaseNormalizationServiceKey as symbol) private reportFinancialIndicatorBaseNormalizationService: INormalizationService<LegalEntitiesReportFinancialIndicatorBase>,
    @inject(ILegalEntitiesReportFinancialIndicatorBasePlaceholderDataServiceKey as symbol) private reportFinancialIndicatorBasePlaceholderDataService: IPlaceholderDataService<LegalEntitiesReportFinancialIndicatorBase>,

    @inject(IPrimitiveTypesNormalizationServiceKey as symbol) protected primitiveTypesNormalizationService: IPrimitiveTypesNormalizationService,
    @inject(IStringArrayNormalizationServiceKey as symbol) protected stringArrayNormalizationService: INormalizationService<string>,
  ) {}

  normalize(data: any, root: string): LegalEntitiesReportFinancialIndicatorWithConclusions | undefined {
    const { normalizeArray, normalizeObject } = this.primitiveTypesNormalizationService;
    const extendData = this.reportFinancialIndicatorBaseNormalizationService.normalize(data, root) ?? this.reportFinancialIndicatorBasePlaceholderDataService.getData();

    return normalizeObject(data, (data) => ({
      ...extendData,
      Conclusions: normalizeArray(data.Conclusions, (item, field) => this.stringArrayNormalizationService.normalize(item, field), `${root}.Conclusions`)
    }), root);
  }
}
