import { injectable } from 'inversify';

import { LegalEntitiesTaxServiceInfo } from '~/types/legal-entities/taxServiceInfo';

import { IPlaceholderDataService } from '../common/reportPlaceholderService.interfaces';

@injectable()
export class LegalEntitiesTaxServicePlaceholderService implements IPlaceholderDataService<LegalEntitiesTaxServiceInfo> {
  getData(): LegalEntitiesTaxServiceInfo {
    return {
      RegistrationDate: '',
      ServiceCode: '',
      ServiceName: '',
    };
  }
}
