import { injectable, inject } from 'inversify';
import { LegalEntitiesReportAverageIndustryIndicator } from '~/types/legal-entities/reportAverageIndustryIndicator';
import { LegalEntitiesReportFinancialIndicatorBase } from '~/types/legal-entities/reportFinancialIndicatorBase';

import { ReportIndicatorsOfProfitability } from '~/types/legal-entities/reportIndicatorsOfProfitability';
import { ILegalEntitiesReportAverageIndustryIndicatorPlaceholderDataServiceKey, ILegalEntitiesReportFinancialIndicatorBasePlaceholderDataServiceKey } from '../legalEntities/legalEntitiesPlaceholderDataService.interfaces';
import { IPlaceholderDataService } from './reportPlaceholderService.interfaces';

@injectable()
export class ReportIndicatorsOfProfitabilityPlaceholderService implements IPlaceholderDataService<ReportIndicatorsOfProfitability> {
  constructor(
    @inject(ILegalEntitiesReportFinancialIndicatorBasePlaceholderDataServiceKey as symbol) private reportFinancialIndicatorBasePlaceholderDataService: IPlaceholderDataService<LegalEntitiesReportFinancialIndicatorBase>,
    @inject(ILegalEntitiesReportAverageIndustryIndicatorPlaceholderDataServiceKey as symbol) private reportAverageIndustryIndicatorPlaceholderDataService: IPlaceholderDataService<LegalEntitiesReportAverageIndustryIndicator>
  ) {}

  getData(): ReportIndicatorsOfProfitability {
    return {
      ProfitabilityByProfitFromSales: this.reportFinancialIndicatorBasePlaceholderDataService.getData(),
      ProfitabilityByEbit: this.reportFinancialIndicatorBasePlaceholderDataService.getData(),
      NormOfNetProfit: this.reportFinancialIndicatorBasePlaceholderDataService.getData(),
      BasicEarningPower: this.reportFinancialIndicatorBasePlaceholderDataService.getData(),
      ReturnOnAssets: this.reportFinancialIndicatorBasePlaceholderDataService.getData(),
      ReturnOnInvestedCapital: this.reportFinancialIndicatorBasePlaceholderDataService.getData(),
      ReturnOnEquity: this.reportFinancialIndicatorBasePlaceholderDataService.getData(),
      ProfitabilityOfSalesRatio: this.reportAverageIndustryIndicatorPlaceholderDataService.getData(),
      ReturnOnAssetsRatio: this.reportAverageIndustryIndicatorPlaceholderDataService.getData(),
      Conclusions: [],
    };
  }
}
