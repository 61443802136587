import { injectable } from 'inversify';

import { LegalEntitiesContractsInfoByYear } from '~/types/legal-entities/contractsInfoByYear';
import { IPlaceholderDataService } from '../common/reportPlaceholderService.interfaces';

@injectable()
export class LegalEntitiesContractsInfoByYearPlaceholderDataService implements IPlaceholderDataService<LegalEntitiesContractsInfoByYear> {
  getData(): LegalEntitiesContractsInfoByYear {
    return {
      Year: 0,
      BuysCount: 0,
      ContractsCount: 0,
      ContractsSum: 0,
      DiscontinuedContractsCount: 0,
      CompletedContractsCount: 0,
    };
  }
}
