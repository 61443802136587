import { injectable, inject } from 'inversify';
import {
  IPhoneNumberRelatedPersonCommonAdapterServices,
  IPhoneNumberRelatedPersonCommonAdapterServicesKey,
  IPhoneNumberRelatedPhonePersonAdapterServices
} from '~/services/adapters/phoneNimber/phoneNumberAdapterService.interfaces';
import { PhoneNumberRelatedPhonePerson } from '~/types/phone-number/relatedPhonePerson';
import { PhoneNumberRelatedPhonePersonDTO } from '~/types/dto/phone-number/relatedPersonDTO';
import {
  IReportAddressAdapterService,
  IReportAddressAdapterServiceKey,
  IReportBoundedPersonPhoneAdapterService,
  IReportBoundedPersonPhoneAdapterServiceKey,
  IReportEducationAdapterService,
  IReportEducationAdapterServiceKey,
  IReportEmailAdapterService,
  IReportEmailAdapterServiceKey,
  IReportIndividualEntrepreneurAdapterService,
  IReportIndividualEntrepreneurAdapterServiceKey,
  IReportJobAdapterService,
  IReportJobAdapterServiceKey,
  IReportLegalEntitiesParticipationAdapterService,
  IReportLegalEntitiesParticipationAdapterServiceKey,
  IReportMessageBoardRecordAdapterService,
  IReportMessageBoardRecordAdapterServiceKey,
  IReportMovementCovidAdapterService,
  IReportMovementCovidAdapterServiceKey,
  IReportMovementTaxiAdapterService,
  IReportMovementTaxiAdapterServiceKey,
  IReportPassportAdapterService,
  IReportPassportAdapterServiceKey,
  IReportPersonInnAdapterService,
  IReportPersonInnAdapterServiceKey,
  IReportPersonSiteAdapterService,
  IReportPersonSiteAdapterServiceKey,
  IReportPersonSnilsAdapterService,
  IReportPersonSnilsAdapterServiceKey,
  IReportPossibleBirthdaysAdapterService,
  IReportPossibleBirthdaysAdapterServiceKey,
  IReportPossibleFullNameAdapterService,
  IReportPossibleFullNameAdapterServiceKey,
  IReportSocialNetworkAdapterService,
  IReportSocialNetworkAdapterServiceKey,
  IReportSocialNetworkTrackAdapterService,
  IReportSocialNetworkTrackAdapterServiceKey,
  IReportUrlAdapterService,
  IReportUrlAdapterServiceKey,
  IReportVehicleAdapterService,
  IReportVehicleAdapterServiceKey,
  IReportVehicleParkingAdapterService,
  IReportVehicleParkingAdapterServiceKey
} from '~/services/adapters/common/reportAdapterService.interfeces';
import {
  IArrayAdapterService,
  IReportArrayAdapterServiceKey
} from '~/services/adapters/common/reportArrayAdapterService.interfaces';

@injectable()
export class PhoneNumberRelatedPhonePersonAdapterServices implements IPhoneNumberRelatedPhonePersonAdapterServices {
  constructor(
    @inject(IPhoneNumberRelatedPersonCommonAdapterServicesKey as symbol) private phoneNumberRelatedPersonCommonAdapterServices: IPhoneNumberRelatedPersonCommonAdapterServices,

    @inject(IReportPossibleFullNameAdapterServiceKey as symbol) private reportPossibleFullNameAdapterService: IReportPossibleFullNameAdapterService,
    @inject(IReportPersonInnAdapterServiceKey as symbol) private reportPersonInnAdapterService: IReportPersonInnAdapterService,
    @inject(IReportPersonSnilsAdapterServiceKey as symbol) private reportPersonSnilsAdapterService: IReportPersonSnilsAdapterService,
    @inject(IReportPassportAdapterServiceKey as symbol) private reportPassportAdapterService: IReportPassportAdapterService,
    @inject(IReportEmailAdapterServiceKey as symbol) private reportEmailAdapterService: IReportEmailAdapterService,
    @inject(IReportPersonSiteAdapterServiceKey as symbol) private reportPersonSiteAdapterService: IReportPersonSiteAdapterService,
    @inject(IReportSocialNetworkAdapterServiceKey as symbol) private reportSocialNetworkAdapterService: IReportSocialNetworkAdapterService,
    @inject(IReportAddressAdapterServiceKey as symbol) private reportAddressAdapterService: IReportAddressAdapterService,
    @inject(IReportVehicleAdapterServiceKey as symbol) private reportVehicleAdapterService: IReportVehicleAdapterService,
    @inject(IReportEducationAdapterServiceKey as symbol) private reportEducationAdapterService: IReportEducationAdapterService,
    @inject(IReportJobAdapterServiceKey as symbol) private reportJobAdapterService: IReportJobAdapterService,
    @inject(IReportLegalEntitiesParticipationAdapterServiceKey as symbol) private reportLegalEntitiesParticipationAdapterService: IReportLegalEntitiesParticipationAdapterService,
    @inject(IReportIndividualEntrepreneurAdapterServiceKey as symbol) private reportIndividualEntrepreneurAdapterService: IReportIndividualEntrepreneurAdapterService,
    @inject(IReportSocialNetworkTrackAdapterServiceKey as symbol) private reportSocialNetworkTrackAdapterService: IReportSocialNetworkTrackAdapterService,
    @inject(IReportBoundedPersonPhoneAdapterServiceKey as symbol) private reportBoundedPersonPhoneAdapterService: IReportBoundedPersonPhoneAdapterService,
    @inject(IReportVehicleParkingAdapterServiceKey as symbol) private reportVehicleParkingAdapterService: IReportVehicleParkingAdapterService,
    @inject(IReportMovementCovidAdapterServiceKey as symbol) private reportMovementCovidAdapterService: IReportMovementCovidAdapterService,
    @inject(IReportMovementTaxiAdapterServiceKey as symbol) private reportMovementTaxiAdapterService: IReportMovementTaxiAdapterService,
    @inject(IReportUrlAdapterServiceKey as symbol) private reportUrlAdapterService: IReportUrlAdapterService,
    @inject(IReportMessageBoardRecordAdapterServiceKey as symbol) private reportMessageBoardRecordAdapterService: IReportMessageBoardRecordAdapterService,
    @inject(IReportPossibleBirthdaysAdapterServiceKey as symbol) private reportPossibleBirthdaysAdapterService: IReportPossibleBirthdaysAdapterService,
    @inject(IReportArrayAdapterServiceKey as symbol) private reportArrayAdapterService: IArrayAdapterService,
  ) {}
  getData(initData: PhoneNumberRelatedPhonePerson[]):PhoneNumberRelatedPhonePersonDTO[] {
    const { mapData } = this.reportArrayAdapterService;

    return (initData ?? []).map((item) => ({
      Common: this.phoneNumberRelatedPersonCommonAdapterServices.getData(item.Common),
      PossibleFios: this.reportPossibleFullNameAdapterService.getData(item.PossibleFios),
      Inns: this.reportPersonInnAdapterService.getData(item.Inns),
      Snilses: this.reportPersonSnilsAdapterService.getData(item.Snilses),
      Passports: this.reportPassportAdapterService.getData(item.Passports),
      Emails: this.reportEmailAdapterService.getData(item.Emails),
      Sites: this.reportPersonSiteAdapterService.getData(item.Sites),
      SocialNetworks: this.reportSocialNetworkAdapterService.getData(item.SocialNetworks),
      Addresses: this.reportAddressAdapterService.getData(item.Addresses),
      Vehicles: this.reportVehicleAdapterService.getData(item.Vehicles),
      Educations: this.reportEducationAdapterService.getData(item.Educations),
      Jobs: this.reportJobAdapterService.getData(item.Jobs),
      LegalEntitiesParticipation: this.reportLegalEntitiesParticipationAdapterService.getData(item.LegalEntitiesParticipation),
      IndividualEntrepreneurs: this.reportIndividualEntrepreneurAdapterService.getData(item.IndividualEntrepreneurs),
      SocialNetworkTrack: this.reportSocialNetworkTrackAdapterService.getData(item.SocialNetworkTrack),
      BoundedPersonPhones: this.reportBoundedPersonPhoneAdapterService.getData(item.BoundedPersonPhones),
      VehicleParkings: this.reportVehicleParkingAdapterService.getData(item.VehicleParkings),
      CovidMovements: this.reportMovementCovidAdapterService.getData(item.CovidMovements),
      TaxiMovements: this.reportMovementTaxiAdapterService.getData(item.TaxiMovements),
      PhotoLinks: mapData(item.PhotoLinks, (item) => this.reportUrlAdapterService.getData(item)),
      MessageBoards: this.reportMessageBoardRecordAdapterService.getData(item.MessageBoards),
      PossibleBirthdays: this.reportPossibleBirthdaysAdapterService.getData(item.PossibleBirthdays),
    }));
  }
}
