import { IContainerBinder } from '~/configuration/inversify/containerAccessor.interface';
import { NetAssetsTableItemConfigService } from '~/modules/legalEntities/financialAnalytics/legalEntitiesFinancialStability/services/netAssetsTableItemConfigService';
import {
  ILevelOfFinancialLeverageItemConfigServiceKey,
  INetAssetsTableItemConfigServiceKey,
  IRatioOfConcentrationOfEquityItemConfigServiceKey,
  IRatioOfSecurityOfInterestToPaymentItemConfigServiceKey,
  IRatioOfTotalDebtToProfitFromSalesItemConfigServiceKey
} from '~/modules/legalEntities/financialAnalytics/legalEntitiesFinancialStability/services/legalEntitiesFinancialStabilityTableItemConfigService.interfaces';
import { LegalEntitiesFinancialStabilityTableConfigService } from '~/modules/legalEntities/financialAnalytics/legalEntitiesFinancialStability/services/legalEntitiesFinancialStabilityTableConfigService';
import { ILegalEntitiesFinancialStabilityTableConfigServiceKey } from '~/modules/legalEntities/financialAnalytics/legalEntitiesFinancialStability/services/legalEntitiesFinancialStabilityTableConfigService.interface';
import { RatioOfConcentrationOfEquityItemConfigService } from '~/modules/legalEntities/financialAnalytics/legalEntitiesFinancialStability/services/ratioOfConcentrationOfEquityItemConfigService';
import { LevelOfFinancialLeverageItemConfigService } from '~/modules/legalEntities/financialAnalytics/legalEntitiesFinancialStability/services/levelOfFinancialLeverageItemConfigService';
import { RatioOfSecurityOfInterestToPaymentItemConfigService } from '~/modules/legalEntities/financialAnalytics/legalEntitiesFinancialStability/services/ratioOfSecurityOfInterestToPaymentItemConfigService';
import { RatioOfTotalDebtToProfitFromSalesItemConfigService } from '~/modules/legalEntities/financialAnalytics/legalEntitiesFinancialStability/services/ratioOfTotalDebtToProfitFromSalesItemConfigService';

export function bindLegalEntitiesFinancialStabilityServices(bind: IContainerBinder) {
  bind(ILegalEntitiesFinancialStabilityTableConfigServiceKey).to(LegalEntitiesFinancialStabilityTableConfigService);

  bind(INetAssetsTableItemConfigServiceKey).to(NetAssetsTableItemConfigService);
  bind(IRatioOfConcentrationOfEquityItemConfigServiceKey).to(RatioOfConcentrationOfEquityItemConfigService);
  bind(ILevelOfFinancialLeverageItemConfigServiceKey).to(LevelOfFinancialLeverageItemConfigService);
  bind(IRatioOfSecurityOfInterestToPaymentItemConfigServiceKey).to(RatioOfSecurityOfInterestToPaymentItemConfigService);
  bind(IRatioOfTotalDebtToProfitFromSalesItemConfigServiceKey).to(RatioOfTotalDebtToProfitFromSalesItemConfigService);
}
