import { injectable } from 'inversify';
import { MergeService } from '~/services/merge/merge-service';
import { LegalEntitiesCompanyHistoryAddressDTO } from '~/types/dto/legalEntities/legalEntitiesCompanyHistoryAddress';
import { StringUtils } from '~/utils/stringUtils';

@injectable()
export class LegalEntitiesCompanyHistoryAddressMergeService extends MergeService<LegalEntitiesCompanyHistoryAddressDTO> {
  protected canMerge(leftItem: LegalEntitiesCompanyHistoryAddressDTO, rightItem: LegalEntitiesCompanyHistoryAddressDTO): boolean {
    return StringUtils.cyrillicToTranslit(leftItem.Address) === StringUtils.cyrillicToTranslit(rightItem.Address);
  }

  protected mergeFields(leftItem: LegalEntitiesCompanyHistoryAddressDTO, rightItem: LegalEntitiesCompanyHistoryAddressDTO): LegalEntitiesCompanyHistoryAddressDTO {
    const leftMinDate = leftItem.DateStart || leftItem.DateEnd;
    const leftMaxDate = leftItem.DateEnd || leftItem.DateStart;
    const rightMinDate = rightItem.DateStart || rightItem.DateEnd;
    const rightMaxDate = rightItem.DateEnd || rightItem.DateStart;

    return {
      Address: leftItem.Address || rightItem.Address,
      DateStart: +(new Date(leftMinDate)) > +(new Date(rightMinDate)) ? rightMinDate : leftMinDate,
      DateEnd: +(new Date(leftMaxDate)) > +(new Date(rightMaxDate)) ? leftMaxDate : rightMaxDate,
      CompanyStatus: leftItem.CompanyStatus || rightItem.CompanyStatus,
      UnreliabilityCode: leftItem.UnreliabilityCode || rightItem.UnreliabilityCode,
      UnreliabilityDate: leftItem.UnreliabilityDate || rightItem.UnreliabilityDate,
      IsRegionChanged: leftItem.IsRegionChanged || rightItem.IsRegionChanged
    };
  }
}
