import { InjectionKey } from 'vue';
import { ElementsExtractor } from '~/types/nodes/elementsExtractor';
import { NodeRowSnapshot } from '~/models/nodes/snapshots/nodeRowSnapshot';
import { CompositeNodeSizeSnapshot } from '~/models/nodes/snapshots/compositeNodeSizeSnapshot';

export interface INodesSizeSnapshotsServiceBuildSnapshotOptions {
    root?: HTMLElement
    elementsExtractor: ElementsExtractor
}

export interface INodesSizeSnapshotsService {
    buildSnapshots(options: INodesSizeSnapshotsServiceBuildSnapshotOptions): CompositeNodeSizeSnapshot[]
    buildRowsSnapshots(options: INodesSizeSnapshotsServiceBuildSnapshotOptions): NodeRowSnapshot[]
}

export const INodesSizeSnapshotsServiceKey: InjectionKey<INodesSizeSnapshotsService> = Symbol('INodesSizeSnapshotsService');
