import { IContainerBinder } from './containerAccessor.interface';

import { GenericDataBlobNormalizationService } from '~/services/normalization/blob/genericDataBlobNormalizationService';
import { IGenericDataBlobNormalizationServiceKey } from '~/services/normalization/blob/genericDataBlobNormalizationService.interfaces';
import { bindCommonNormalizationServices } from './container.сommonNormalizationServices';
import { bindIndividualNormalizationServices } from './container.individualNormalizationServices';
import { bindLegalEntitiesNormalizationServices } from './container.legalEntitiesNormalizationServices';
import { bindVehiclesNormalizationServices } from './container.vehiclesNormalizationServices';
import { bindDataBlobDataWrapperNormalizationServices } from './container.dataBlobDataWrapperNormalizationServices';
import { bindPhoneNumberNormalizationServices } from './container.phoneNumberNormalizationService';

export function bindNormalizationServices(bind: IContainerBinder) {
  bindCommonNormalizationServices(bind);
  bindLegalEntitiesNormalizationServices(bind);
  bindIndividualNormalizationServices(bind);
  bindVehiclesNormalizationServices(bind);
  bindPhoneNumberNormalizationServices(bind);
  bindDataBlobDataWrapperNormalizationServices(bind);

  bind(IGenericDataBlobNormalizationServiceKey).to(GenericDataBlobNormalizationService);
}
