import { injectable, inject } from 'inversify';
import { Config } from '~/types/field-list/config';
import { IReportVehicleRelationGeneralConfigService } from '~/modules/reportsCommon/reportVehicle/services/vehicleConfigService.interfaces';
import { ReportVehicleRelationDTO } from '~/types/dto/common/reportVehicleRelationDTO';
import { VEHICLES_STANDARD_FIELD_LIST_OPTIONS } from '~/modules/reportsCommon/reportVehicle/configuration/constants';
import { IPtsServiceKey, IPtsService } from './ptsService.interfaces'

@injectable()
export class ReportVehicleRelationGeneralConfigService implements IReportVehicleRelationGeneralConfigService {
  constructor(
    @inject(IPtsServiceKey as symbol) private readonly ptsService: IPtsService
  ) {}
  
  getConfig(relation: ReportVehicleRelationDTO): Config {
    return {
      ...VEHICLES_STANDARD_FIELD_LIST_OPTIONS,
      fields: [
        {
          key: 'Birthday',
          label: 'Дата рождения',
          value: relation.Birthday,
        },
        {
          key: 'Inn',
          label: 'ИНН',
          value: relation.Inn,
        },
        {
          key: 'Passport',
          label: 'Паспорт',
          value: relation.Passport,
        },
        {
          key: 'Address',
          label: 'Адрес',
          value: relation.Address,
        },
        {
          key: 'Pts',
          label: 'ПТС',
          value: this.ptsService.getPtsFromString(relation.Pts),
        },
        {
          key: 'Sts',
          label: 'CТС',
          value: relation.Sts,
        },
      ] 
    };
  }
}
