import { injectable } from 'inversify';
import { uniq } from 'lodash-es';
import { IPossibleFioServiceConfig } from '~/modules/reportsCommon/reportPossible/individual/servises/possibleDataServiceConfig.interfaces';
import { ReportPossibleFullNameDTO } from '~/types/dto/common/reportPossibleFullNameDTO';
import { Config } from '~/types/field-list/config';

@injectable()
export class PossibleFioServiceConfig implements IPossibleFioServiceConfig {
  getConfig(initData: Array<string>): Config {
    const fullNameList = initData.length !== 0 ? initData.map(item => item) : undefined;

    return {
      fieldsWidth: '100%',
      fieldsDirection: 'divider-row',
      fieldsValueWidth: '350px',
      isBeginningDivider: true,
      rowGap: '8px',
      fields: [
        {
          key: 'PossibleFio',
          label: 'Другие возможные ФИО',
          value: fullNameList,
        },
      ].filter((item) => item.value)
    };
  }
}
