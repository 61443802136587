import { injectable, inject } from 'inversify';

import { IPrimitiveTypesNormalizationServiceKey, IPrimitiveTypesNormalizationService } from './primitiveTypesNormalizationService.interfaces';
import { ReportVehicleInsurance } from '~/types/common/reportVehicleInsurance';
import { INormalizationService } from './reportNormalizationService.interfaces';

@injectable()
export class ReportVehicleInsuranceNormalizationService implements INormalizationService<ReportVehicleInsurance> {
  constructor(
    @inject(IPrimitiveTypesNormalizationServiceKey as symbol) private primitiveTypesNormalizationService: IPrimitiveTypesNormalizationService,
  ) {}

  normalize(data: any, root: string): ReportVehicleInsurance | undefined {
    const {
      normalizeString,
      normalizeOptionalString,
      normalizeOptionalBoolean,
      normalizeObject
    } = this.primitiveTypesNormalizationService;

    return normalizeObject(data, (data) => ({
      InsuranceType: normalizeString(data.InsuranceType, `${root}.InsuranceType`),
      InsuranceCompany: normalizeString(data.InsuranceCompany, `${root}.InsuranceCompany`),
      PolicySeries: normalizeString(data.PolicySeries, `${root}.PolicySeries`),
      PolicyNumber: normalizeString(data.PolicyNumber, `${root}.PolicyNumber`),
      PolicyRestrictions: normalizeString(data.PolicyRestrictions, `${root}.PolicyRestrictions`),
      Cbm: normalizeOptionalString(data.Cbm, `${root}.Cbm`),
      IsActive: normalizeOptionalBoolean(data.IsActive, `${root}.IsActive`),
      PolicyStartDate: normalizeOptionalString(data.PolicyStartDate, `${root}.PolicyStartDate`),
      PolicyEndDate: normalizeOptionalString(data.PolicyEndDate, `${root}.PolicyEndDate`),
    }), root);
  }
}
