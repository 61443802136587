import { IndividualFamilyRelationType } from '~/types/individual/familyRelationType';

export module FamilyUtils {
  export function formatFamilyRelationType(type: IndividualFamilyRelationType): string {
    switch (type) {
      case IndividualFamilyRelationType.Wife:
        return 'Жена';
      case IndividualFamilyRelationType.Husband:
        return 'Муж';
      case IndividualFamilyRelationType.Mother:
        return 'Мать';
      case IndividualFamilyRelationType.Father:
        return 'Отец';
      case IndividualFamilyRelationType.Sister:
        return 'Сестра';
      case IndividualFamilyRelationType.Brother:
        return 'Брат';
      case IndividualFamilyRelationType.Grandmother:
        return 'Бабушка';
      case IndividualFamilyRelationType.Grandfather:
        return 'Дедушка';
      case IndividualFamilyRelationType.Son:
        return 'Сын';
      case IndividualFamilyRelationType.Daughter:
        return 'Дочь';
      case IndividualFamilyRelationType.Grandson:
        return 'Внук';
      case IndividualFamilyRelationType.Granddaughter:
        return 'Внучка';
      case IndividualFamilyRelationType.Uncle:
        return 'Дядя';
      case IndividualFamilyRelationType.Aunt:
        return 'Тетя';
      case IndividualFamilyRelationType.Nephew:
        return 'Племянник';
      case IndividualFamilyRelationType.Niece:
        return 'Племянница';
      case IndividualFamilyRelationType.Stepfather:
        return 'Отчим';
      case IndividualFamilyRelationType.Stepdaughter:
        return 'Падчерица';
      default:
        return '';
    }
  }
}
