import { injectable } from "inversify";
import { IReportSocialNetworkHistoryMaritalStatus } from "./reportSocialNetworkConfigService.interfaces";
import { FIELD_LIST_SOCIAL_OPTIONS } from '../../configuration/constants';
import { Config } from "~/types/field-list/config";

@injectable()
export class ReportSocialNetworkHistoryMaritalStatus implements IReportSocialNetworkHistoryMaritalStatus {
  getConfig(item: any): Config {
    return {
      ...FIELD_LIST_SOCIAL_OPTIONS,
      fields: [
        {
          key: 'date',
          label: 'Дата',
          value: '20.20.2020'
        },
        {
          key: 'status',
          label: 'Статус',
          value: 'Холост'
        }
      ]
    }
  }
}