import { injectable } from 'inversify';
import { LegalEntitiesCompanyHeadFlDTO } from '~/types/dto/legalEntities/companyHeadFlDTO';
import { ILegalEntitiesGeneralInformationLeaderConfigService } from '~/modules/legalEntities/commonInfo/legalEntitiesGeneralInformation/services/legalEntitiesGeneralInformationConfigServices.interfaces';
import { Config } from '~/types/field-list/config';

@injectable()
export class LegalEntitiesGeneralInformationLeaderConfigService implements ILegalEntitiesGeneralInformationLeaderConfigService {
  getConfig(initData: LegalEntitiesCompanyHeadFlDTO): Config {
    return {
      fieldsDirection: 'column',
      fieldsWidth: '100%',
      fieldsValueWidth: '100%',
      fields: [
        {
          label: 'Руководитель',
          key: 'fio',
          value: initData.Fio,
          isInner: true
        }]
    };
  }
}
