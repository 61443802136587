import { FILE_EXTENSION_REG_EXP } from '@/patterns/fileExtensionRegExp';

export module FileUtils {
  export function ReadFileContentAsync(file: File): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();

      reader.readAsText(file);
      reader.onload = (e) => resolve((e.target?.result as string | undefined) ?? '');
      reader.onerror = (ex) => reject(ex);
    });
  }

  export function downloadFile(filename: string, data: Blob) {
    const href = URL.createObjectURL(data);

    const link = document.createElement('a');
    link.href = href;
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    URL.revokeObjectURL(href);
  }

  export function getFilesFromInputChangeEvent(event: Event) {
    const target = event.currentTarget as HTMLInputElement;
    const files = target.files as FileList;

    return files;
  }

  export function getFileFromInputChangeEvent(event: Event) {
    return getFilesFromInputChangeEvent(event)[0];
  }

  export function removeFileNameExtension(fileName: string) {
    console.log(fileName);
    const splitFileName = fileName.split('.');
    console.log(splitFileName);
    return splitFileName.slice(0, splitFileName.length - 1).join('.');
  }

  export function getFileName(fileName: string) {
    return fileName.replace(FILE_EXTENSION_REG_EXP, '');
  }

  export function getExtension(fileName: string) {
    return fileName.match(FILE_EXTENSION_REG_EXP)?.toString() ?? '';
  }
}
