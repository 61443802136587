import { injectable, inject } from 'inversify';
import { IVehiclesDataBlobDataAdapterService } from '~/services/adapters/vehicle/vehicleAdapterService.interfaces';
import { VehiclesDataBlobData } from '~/types/blobs/vehicles-data-blob-data';
import { VehiclesDataBlobDataDTO } from '~/types/dto/blobs/vehiclesDataBlobDataDTO';
import {
  IReportBoundedPersonPhoneAdapterService,
  IReportBoundedPersonPhoneAdapterServiceKey,
  IReportFineAdapterService,
  IReportFineAdapterServiceKey,
  IReportMessageBoardRecordAdapterService,
  IReportMessageBoardRecordAdapterServiceKey,
  IReportMovementCovidAdapterService,
  IReportMovementCovidAdapterServiceKey,
  IReportRoadAccidentAdapterService,
  IReportRoadAccidentAdapterServiceKey,
  IReportVehicleAdapterService,
  IReportVehicleAdapterServiceKey,
  IReportVehicleParkingAdapterService,
  IReportVehicleParkingAdapterServiceKey
} from '~/services/adapters/common/reportAdapterService.interfeces';

@injectable()
export class VehiclesDataBlobDataAdapterService implements IVehiclesDataBlobDataAdapterService {
  constructor(
    @inject(IReportVehicleAdapterServiceKey as symbol) private reportVehicleAdapterService: IReportVehicleAdapterService,
    @inject(IReportVehicleParkingAdapterServiceKey as symbol) private reportVehicleParkingAdapterService: IReportVehicleParkingAdapterService,
    @inject(IReportFineAdapterServiceKey as symbol) private reportFineAdapterService: IReportFineAdapterService,
    @inject(IReportMovementCovidAdapterServiceKey as symbol) private reportMovementCovidAdapterService: IReportMovementCovidAdapterService,
    @inject(IReportRoadAccidentAdapterServiceKey as symbol) private reportRoadAccidentAdapterService: IReportRoadAccidentAdapterService,
    @inject(IReportBoundedPersonPhoneAdapterServiceKey as symbol) private reportBoundedPersonPhoneAdapterService: IReportBoundedPersonPhoneAdapterService,
    @inject(IReportMessageBoardRecordAdapterServiceKey as symbol) private reportMessageBoardRecordAdapterService: IReportMessageBoardRecordAdapterService,
  ) {}
  getData(initData: VehiclesDataBlobData): VehiclesDataBlobDataDTO {
    return {
      Vehicles: this.reportVehicleAdapterService.getData(initData.Vehicles),
      VehicleParkings: this.reportVehicleParkingAdapterService.getData(initData.VehicleParkings),
      Fines: this.reportFineAdapterService.getData(initData.Fines),
      CovidMovements: this.reportMovementCovidAdapterService.getData(initData.CovidMovements),
      RoadAccidents: this.reportRoadAccidentAdapterService.getData(initData.RoadAccidents),
      BoundedPersonPhones: this.reportBoundedPersonPhoneAdapterService.getData(initData.BoundedPersonPhones),
      MessageBoards: this.reportMessageBoardRecordAdapterService.getData(initData.MessageBoards)
    };
  }
}
