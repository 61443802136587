import { inject, injectable } from 'inversify';
import { IIndividualFamilyListAdapterService } from '~/services/adapters/individual/individualAdapterService.interfaces';
import { IndividualFamilyRelation } from '~/types/individual/familyRelation';
import { IndividualFamilyListDTO } from '~/types/dto/individual/familyListDTO';
import {
  IReportSocialNetworkIdAdapterService,
  IReportSocialNetworkIdAdapterServiceKey
} from '~/services/adapters/common/reportAdapterService.interfeces';

@injectable()
export class IndividualFamilyListAdapterService implements IIndividualFamilyListAdapterService {
  constructor(@inject(IReportSocialNetworkIdAdapterServiceKey as symbol) private reportSocialNetworkIdAdapterService: IReportSocialNetworkIdAdapterService) {}
  
  getData(initData: Array<IndividualFamilyRelation>): Array<IndividualFamilyListDTO> {
    return (initData ?? []).map((item) => ({
      FamilyRelationType: item.FamilyRelationType ?? 0,
      FirstName: item.FirstName ?? '',
      LastName: item.LastName ?? '',
      Patronymic: item.Patronymic ?? '',
      Birthday: item.Birthday ?? '',
      Inn: item.Inn ?? '',
      SocialNetworks: this.reportSocialNetworkIdAdapterService.getData(item.SocialNetworks),
      birthdayOnlyYear: item.birthdayOnlyYear ?? false
    }));
  }
}
