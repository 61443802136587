import { injectable, inject } from 'inversify';
import { ReportMainEntityFileUrl } from '~/types/common/reportMainEntityFileUrl';
import { ReportRoadAccident } from '~/types/common/reportRoadAccident';
import { IPlaceholderDataService, IReportMainEntityFileUrlPlaceholderServiceKey } from './reportPlaceholderService.interfaces';

@injectable()
export class ReportRoadAccidentPlaceholderService implements IPlaceholderDataService<ReportRoadAccident> {
  constructor(
    @inject(IReportMainEntityFileUrlPlaceholderServiceKey as symbol) private reportMainEntityFileUrlPlaceholderService: IPlaceholderDataService<ReportMainEntityFileUrl>,
  ) {}

  getData(): ReportRoadAccident {
    return {
      Date: '',
      AccidentType: '',
      Region: '',
      SchemePhotoUrl: this.reportMainEntityFileUrlPlaceholderService.getData(),
    };
  }
}
