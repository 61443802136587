import { injectable } from 'inversify';
import { LegalEntitiesReportDataBuhForm } from '~/types/legal-entities/reportDataBuhForm';
import { IPlaceholderDataService } from '../common/reportPlaceholderService.interfaces';

@injectable()
export class LegalEntitiesReportDataBuhFormPlaceholderDataService implements IPlaceholderDataService<LegalEntitiesReportDataBuhForm> {
  getData(): LegalEntitiesReportDataBuhForm {
    return {
      Year: 0,
      OrganizationType: '',
      Form1: [],
      Form2: [],
    };
  }
}
