import { injectable } from 'inversify';
import { IReportTagAdapterService } from '~/services/adapters/common/reportAdapterService.interfeces';
import { ReportTag } from '~/types/common/reportTag';
import { ReportTagDTO } from '~/types/dto/common/reportTagDTO';
import { StringUtils } from '~/utils/stringUtils';

@injectable()
export class ReportTagAdapterService implements IReportTagAdapterService {
  getData(initData: ReportTag[]): ReportTagDTO[] {
    return (initData ?? []).map((item) => ({
      Tag: StringUtils.quoteReplacement(item.Tag ?? ''),
    }));
  } 
}
