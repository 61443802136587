import { injectable } from 'inversify';
import { IFinePrivateCashInfoConfigService } from '~/modules/reportsCommon/reportFine/services/fines.interfaces';
import { Config } from '~/types/field-list/config';
import { NumberUtils } from '~/utils/number';
import { ReportFineDTO } from '~/types/dto/common/fineDTO';

@injectable()
export class FinePrivateCashInfoConfigService implements IFinePrivateCashInfoConfigService {
  getConfig(initData: ReportFineDTO): Config {
    const fineSum = NumberUtils.formatNumberDigits(initData.Sum);
    const fineRestSum = NumberUtils.formatNumberDigits(initData.RestSum);

    return {
      fieldsWidth: 'fit-content',
      fieldsDirection: 'column',
      columnGap: '16px',
      fields: [
        {
          key: 'Sum',
          label: 'Сумма, ₽',
          value: fineSum,
        },
        {
          key: 'fineRestSum',
          label: 'Остаток, ₽',
          value: fineRestSum
        }
      ].filter((item) => item.value),
    };
  }
}
