import { injectable } from 'inversify';
import { Header } from '~/types/table';
import { ILegalEntitiesContractsInfoByYearTableConfig } from './LegalEntitiesContractsInfoByYearTableConfig.interfaces';
import { TableItem } from '~/types/tableItem';
import { NumberUtils } from '~/utils/number';
import { LegalEntitiesContractsInfoByYearDTO } from '~/types/dto/legalEntities/contractsInfoByYearDTO';

@injectable()
export class LegalEntitiesContractsInfoByYearTableConfig implements ILegalEntitiesContractsInfoByYearTableConfig {
  getItems(contractsInfoItems: LegalEntitiesContractsInfoByYearDTO[]): TableItem[] {
    const years = contractsInfoItems.map((item) => String(item.Year));
    const tableRows = [];
    tableRows.push(years.reduce((acc, curValue, index) => ({
      ...acc,
      [curValue]: `${contractsInfoItems[index].BuysCount}`
    }), { ordersAndContracts: 'Количество участий в закупках, шт.' }));

    tableRows.push(years.reduce((acc, curValue, index) => ({
      ...acc,
      [curValue]: `${contractsInfoItems[index].ContractsCount}`,
    }), { ordersAndContracts: 'Количество заключенных контрактов, шт.' }));

    tableRows.push(years.reduce((acc, curValue, index) => {
      const { ContractsSum } = contractsInfoItems[index];
      const correctedContractSum = `${NumberUtils.formatNumberDigits(NumberUtils.formatByMaxValue(ContractsSum), 1)}
        ${NumberUtils.getPostfix(ContractsSum)}`;
        
      return {
        ...acc,
        [curValue]: correctedContractSum
      }; 
    }, { ordersAndContracts: 'Сумма контрактов за все время, \u{20BD}' }));

    return tableRows;
  }

  getHeaders(items?: LegalEntitiesContractsInfoByYearDTO[]): Header[] {
    if (items === undefined) return [];

    return items.reduce(
      (acc, curValue) => acc.concat({
        label: `${curValue.Year} год`,
        key: `${curValue.Year}`,
      }),
      [{
        label: 'Предмет',
        key: 'ordersAndContracts',
      }],
    );
  }
}
