import { injectable } from 'inversify';
import { ICompanyInnRequisiteMatchingService } from '~/services/matching/companyInnRequisiteMatchingService.interfaces';

@injectable()
export class CompanyInnRequisiteMatchingService implements ICompanyInnRequisiteMatchingService {
  matchPartial(value: string): boolean {
    const regExpInnInclude = /^\d{10}$/;
    const result = value.match(regExpInnInclude);
    return Array.isArray(result);
  }
}
