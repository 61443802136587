import { injectable, inject } from 'inversify';
import { ReportVehicleRisks } from '~/types/common/reportVehicleRisks';
import { IPrimitiveTypesNormalizationServiceKey, IPrimitiveTypesNormalizationService } from './primitiveTypesNormalizationService.interfaces';
import { INormalizationService } from './reportNormalizationService.interfaces';

@injectable()
export class ReportVehicleRisksNormalizationService implements INormalizationService<ReportVehicleRisks> {
  constructor(
    @inject(IPrimitiveTypesNormalizationServiceKey as symbol) private primitiveTypesNormalizationService: IPrimitiveTypesNormalizationService
  ) {}

  normalize(data: any, root: string): ReportVehicleRisks | undefined {
    const { normalizeOptionalBoolean, normalizeObject } = this.primitiveTypesNormalizationService;

    return normalizeObject(data, (data) => ({
      HasRoadAccidents: normalizeOptionalBoolean(data.HasRoadAccidents, `${root}.HasRoadAccidents`),
      IsInPledgesRegistry: normalizeOptionalBoolean(data.IsInPledgesRegistry, `${root}.IsInPledgesRegistry`),
      HasRestricts: normalizeOptionalBoolean(data.HasRestricts, `${root}.HasRestricts`),
      HasCanceledFines: normalizeOptionalBoolean(data.HasCanceledFines, `${root}.HasCanceledFines`),
      HasActiveFines: normalizeOptionalBoolean(data.HasActiveFines, `${root}.HasActiveFines`),
    }), root);
  }
}
