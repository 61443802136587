import { injectable } from "inversify";
import { MergeService } from "~/services/merge/merge-service";
import { ReportPassportDTO } from "~/types/dto/common/reportPassportDTO";

@injectable()
export class ReportPassportMergeService extends MergeService<ReportPassportDTO> {
  protected canMerge(leftItem: ReportPassportDTO, rightItem: ReportPassportDTO): boolean {
    const leftSeriesNumber = `${leftItem.Series} ${leftItem.Number}`;
    const rightSeriesNumber = `${rightItem.Series} ${rightItem.Number}`;

    return leftSeriesNumber === rightSeriesNumber;
  }

  protected mergeFields(leftItem: ReportPassportDTO, rightItem: ReportPassportDTO): ReportPassportDTO {
    return {
      DepartmentCode: leftItem.DepartmentCode ?? rightItem.DepartmentCode,
      IsActual: leftItem.IsActual ?? rightItem.IsActual,
      IssuedBy: leftItem.IssuedBy ?? rightItem.IssuedBy,
      IssuedDate: leftItem.IssuedDate || rightItem.IssuedDate,
      Number: leftItem.Number,
      Series: leftItem.Series,
      Type: leftItem.Type ?? rightItem.Type
    }
  }
}