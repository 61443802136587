import { injectable } from 'inversify';
import { IndividualCriminalPenalties } from '~/types/individual/criminalPenalties';
import { IPlaceholderDataService } from '../common/reportPlaceholderService.interfaces';

@injectable()
export class IndividualCriminalPenaltiesPlaceholderService implements IPlaceholderDataService<IndividualCriminalPenalties> {
  getData(): IndividualCriminalPenalties {
    return {
      Article: '',
      Part: '',
      Point: '',
      ArticleContent: '',
      CaseInitiationDate: '',
      ConvictionDate: '',
      CaseNumber: '',
      Comment: '',
    };
  }
}
