import { injectable, inject } from 'inversify';

import { LegalEntitiesShareholder } from '~/types/legal-entities/shareholder';
import { IPrimitiveTypesNormalizationServiceKey, IPrimitiveTypesNormalizationService } from '../common/primitiveTypesNormalizationService.interfaces';
import { INormalizationService } from '../common/reportNormalizationService.interfaces';

@injectable()
export class LegalEntitiesShareholderNormalizationService implements INormalizationService<LegalEntitiesShareholder> {
  constructor(
    @inject(IPrimitiveTypesNormalizationServiceKey as symbol) private primitiveTypesNormalizationService: IPrimitiveTypesNormalizationService,
  ) {}

  normalize(data: any, root: string): LegalEntitiesShareholder | undefined {
    const {
      normalizeString,
      normalizeOptionalInteger,
      normalizeOptionalString,
      normalizeObject
    } = this.primitiveTypesNormalizationService;

    return normalizeObject(data, (data) => ({
      Fullname: normalizeString(data.Fullname, `${root}.Fullname`),
      VotingSharesPercent: normalizeOptionalInteger(data.VotingSharesPercent, `${root}.VotingSharesPercent`),
      CapitalSharesPercent: normalizeOptionalInteger(data.CapitalSharesPercent, `${root}.CapitalSharesPercent`),
      ShareholderType: normalizeString(data.ShareholderType, `${root}.ShareholderType`),
      EndDate: normalizeOptionalString(data.EndDate, `${root}.EndDate`),
    }), root);
  }
}
