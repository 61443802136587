import { injectable, inject } from 'inversify';
import { IndividualEnforcementProceeding } from '~/types/individual/enforcementProceeding';
import { IPrimitiveTypesNormalizationServiceKey, IPrimitiveTypesNormalizationService } from '../common/primitiveTypesNormalizationService.interfaces';
import { INormalizationService } from '../common/reportNormalizationService.interfaces';

@injectable()
export class IndividualEnforcementProceedingNormalizationService implements INormalizationService<IndividualEnforcementProceeding> {
  constructor(
    @inject(IPrimitiveTypesNormalizationServiceKey as symbol) private primitiveTypesNormalizationService: IPrimitiveTypesNormalizationService,
  ) {}

  normalize(data: any, root: string): IndividualEnforcementProceeding | undefined {
    const {
      normalizeObject,
      normalizeNumber,
      normalizeString,
      normalizeOptionalString
    } = this.primitiveTypesNormalizationService;

    return normalizeObject(data, (data) => ({
      Number: normalizeString(data.Number, `${root}.Number`),
      Type: normalizeOptionalString(data.Type, `${root}.Type`),
      StartDate: normalizeOptionalString(data.StartDate, `${root}.StartDate`),
      ExecutiveDocumentAgencyName: normalizeOptionalString(data.ExecutiveDocumentAgencyName, `${root}.ExecutiveDocumentAgencyName`),
      EndDate: normalizeOptionalString(data.EndDate, `${root}.EndDate`),
      EndReasonArticle: normalizeString(data.EndReasonArticle, `${root}.EndReasonArticle`),
      EndReasonPart: normalizeString(data.EndReasonPart, `${root}.EndReasonPart`),
      EndReasonParagraph: normalizeString(data.EndReasonParagraph, `${root}.EndReasonParagraph`),
      SubjectOfExecution: normalizeString(data.SubjectOfExecution, `${root}.SubjectOfExecution`),
      AmountOwed: normalizeNumber(data.AmountOwed, `${root}.AmountOwed`),
      BailiffsOfficeName: normalizeString(data.BailiffsOfficeName, `${root}.BailiffsOfficeName`),
      BailiffsOfficeAddress: normalizeOptionalString(data.BailiffsOfficeAddress, `${root}.BailiffsOfficeAddress`),
      BailiffFullName: normalizeString(data.BailiffFullName, `${root}.BailiffFullName`),
      BailiffContactInfo: normalizeOptionalString(data.BailiffContactInfo, `${root}.BailiffContactInfo`),
      Region: normalizeString(data.Region, `${root}.Region`),
    }), root);
  }
}
