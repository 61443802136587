import { injectable } from 'inversify';
import { PhoneNumberPhoneCommon } from '~/types/phone-number/phoneCommon';
import { IPlaceholderDataService } from '~/services/placeholder/common/reportPlaceholderService.interfaces';

@injectable()
export class PhoneNumberPhoneCommonPlaceholderService implements IPlaceholderDataService<PhoneNumberPhoneCommon> {
  getData(): PhoneNumberPhoneCommon {
    return {
      PhoneType: '',
      PhoneNumber: '',
      Country: '',
      Provider: '',
      TimeZone: '',
      DeviceModel: '',
      VerificationCode: 0
    };
  }
}
