import { injectable } from 'inversify';
import { IIndividualBoundedPhonesNumberConfigService } from './individualBoundedPhonesNumberConfigService.interface';
import { Config } from '~/types/field-list/config';

@injectable()
export class IndividualBoundedPhonesNumberConfigService implements IIndividualBoundedPhonesNumberConfigService {
  getConfig(item: string): Config {
    return {
      fieldsDirection: 'column',
      fieldsWidth: 'auto',
      fields: [
        {
          key: 'phoneNumber',
          label: 'Номер для поиска связей',
          value: item,
        }
      ]
    };
  }
}
