import { inject, injectable } from 'inversify';

import { IPrimitiveTypesNormalizationServiceKey, IPrimitiveTypesNormalizationService } from './primitiveTypesNormalizationService.interfaces';
import { ReportUrl } from '~/types/common/reportUrl';
import { INormalizationService } from './reportNormalizationService.interfaces';

@injectable()
export class ReportUrlNormalizationService implements INormalizationService<ReportUrl> {
  constructor(
    @inject(IPrimitiveTypesNormalizationServiceKey as symbol) private primitiveTypesNormalizationService: IPrimitiveTypesNormalizationService,
  ) {}

  normalize(data: any, root: string): ReportUrl | undefined {
    const { normalizeOptionalString, normalizeObject } = this.primitiveTypesNormalizationService;

    return normalizeObject(data, (data) => ({
      Text: normalizeOptionalString(data.Text, `${root}.Text`),
      Value: normalizeOptionalString(data.Value, `${root}.Value`),
    }), root);
  }
}
