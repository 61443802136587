import { InjectionKey } from 'vue';
import { ReportPossibleBirthdaysDTO } from '~/types/dto/common/reportPossibleBirthdaysDTO';

interface IReportPossibleBirthdaysShifting {
  getSiftedData(initData: Array<ReportPossibleBirthdaysDTO>, birthday: string): Array<ReportPossibleBirthdaysDTO>;
}

export type IPossibleBirthdaysSiftingService = IReportPossibleBirthdaysShifting;

export const IPossibleBirthdaysSiftingServiceKey: InjectionKey<IPossibleBirthdaysSiftingService> = Symbol('IPossibleBirthdaysSiftingService');
