import { injectable, inject } from 'inversify';

import { ReportSocialNetworkHighEducation } from '~/types/common/reportSocialNetworkHighEducation';
import { ReportSocialNetworkJob } from '~/types/common/reportSocialNetworkJob';
import { ReportSocialNetworkMilitaryRecord } from '~/types/common/reportSocialNetworkMilitaryRecord';
import { ReportSocialNetworkSchool } from '~/types/common/reportSocialNetworkSchool';
import { ReportSocialNetworkSecondaryEducation } from '~/types/common/reportSocialNetworkSecondaryEducation';
import { ReportSocialNetworkTrack } from '~/types/common/reportSocialNetworkTrack';
import { IPrimitiveTypesNormalizationService, IPrimitiveTypesNormalizationServiceKey } from './primitiveTypesNormalizationService.interfaces';
import {
  INormalizationService,
  IReportSocialNetworkHighEducationNormalizationServiceKey,
  IReportSocialNetworkJobNormalizationServiceKey,
  IReportSocialNetworkMilitaryRecordNormalizationServiceKey,
  IReportSocialNetworkSchoolNormalizationServiceKey,
  IReportSocialNetworkSecondaryEducationNormalizationServiceKey
} from './reportNormalizationService.interfaces';

@injectable()
export class ReportSocialNetworkTrackNormalizationService implements INormalizationService<ReportSocialNetworkTrack> {
  constructor(
    @inject(IPrimitiveTypesNormalizationServiceKey as symbol) private primitiveTypesNormalizationService: IPrimitiveTypesNormalizationService,

    @inject(IReportSocialNetworkSchoolNormalizationServiceKey as symbol) private reportSocialNetworkSchoolNormalizationService: INormalizationService<ReportSocialNetworkSchool>,
    @inject(IReportSocialNetworkSecondaryEducationNormalizationServiceKey as symbol) private reportSocialNetworkSecondaryEducationNormalizationService: INormalizationService<ReportSocialNetworkSecondaryEducation>,
    @inject(IReportSocialNetworkHighEducationNormalizationServiceKey as symbol) private reportSocialNetworkHighEducationNormalizationService: INormalizationService<ReportSocialNetworkHighEducation>,
    @inject(IReportSocialNetworkMilitaryRecordNormalizationServiceKey as symbol) private reportSocialNetworkMilitaryRecordNormalizationService: INormalizationService<ReportSocialNetworkMilitaryRecord>,
    @inject(IReportSocialNetworkJobNormalizationServiceKey as symbol) private reportSocialNetworkJobNormalizationService: INormalizationService<ReportSocialNetworkJob>,

  ) {}

  normalize(data: any, root: string): ReportSocialNetworkTrack | undefined {
    const { normalizeObject, normalizeBoolean, normalizeArray } = this.primitiveTypesNormalizationService;
    return normalizeObject(data, (data) => ({
      SocialNetworkSchools: normalizeArray(data.SocialNetworkSchools, (item, field) => this.reportSocialNetworkSchoolNormalizationService.normalize(item, field), `${root}.SocialNetworkSchools`),
      SocialNetworkSecondaryEducations: normalizeArray(data.SocialNetworkSecondaryEducations, (item, field) => this.reportSocialNetworkSecondaryEducationNormalizationService.normalize(item, field), `${root}.SocialNetworkSecondaryEducations`),
      SocialNetworkHighEducations: normalizeArray(data.SocialNetworkHighEducations, (item, field) => this.reportSocialNetworkHighEducationNormalizationService.normalize(item, field), `${root}.SocialNetworkHighEducations`),
      SocialNetworkMilitaryRecords: normalizeArray(data.SocialNetworkMilitaryRecords, (item, field) => this.reportSocialNetworkMilitaryRecordNormalizationService.normalize(item, field), `${root}.SocialNetworkMilitaryRecords`),
      SocialNetworkJobs: normalizeArray(data.SocialNetworkJobs, (item, field) => this.reportSocialNetworkJobNormalizationService.normalize(item, field), `${root}.SocialNetworkJobs`),
      IsExists: normalizeBoolean(data.IsExists, `${root}.IsExists`)
    }), root);
  }
}
