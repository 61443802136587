import { injectable } from 'inversify';
import { IndividualMilitaryRecord } from '~/types/individual/militaryRecord';
import { IPlaceholderDataService } from '../common/reportPlaceholderService.interfaces';

@injectable()
export class IndividualMilitaryRecordPlaceholderService implements IPlaceholderDataService<IndividualMilitaryRecord> {
  getData(): IndividualMilitaryRecord {
    return {
      IsMilitaryDuty: false,
      Series: '',
      Number: '',
      StockCategory: '',
      MilitaryRank: '',
      MilitaryPersonnel: '',
      MilitarySpecialty: '',
      MilitaryShelfLife: '',
      RecruitmentOffice: '',
      MilitaryRegistration: '',
      HasMobilizationOrder: false,
      PartyTeamNumber: '',
      BookingOrganization: '',
    };
  }
}
