/* eslint-disable class-methods-use-this, max-len */

import { injectable, inject } from 'inversify';
import { StringUtils } from '~/utils/stringUtils';
import { LegalEntitiesCompanyHistoryAddress } from '~/types/legal-entities/companyHistoryAddress';
import { LegalEntitiesCompanyHistoryAddressDTO } from '~/types/dto/legalEntities/legalEntitiesCompanyHistoryAddress';
import {
  ILegalEntitiesCompanyHistoryAddressAdapterService
} from '~/services/adapters/legalEntities/legalEntitiesAdapterService.interfaces';
import {
  IReportArrayStringAdapterService,
  IReportArrayStringAdapterServiceKey
} from '~/services/adapters/common/reportAdapterService.interfeces';

@injectable()
export class LegalEntitiesCompanyHistoryAddressAdapterService implements ILegalEntitiesCompanyHistoryAddressAdapterService {
  constructor(
    @inject(IReportArrayStringAdapterServiceKey as symbol) private reportArrayStringAdapterService: IReportArrayStringAdapterService
  ) {
  }
  getData(initData: LegalEntitiesCompanyHistoryAddress[]): LegalEntitiesCompanyHistoryAddressDTO[] {
    return (initData ?? []).map((item) => ({
      Address: StringUtils.quoteReplacement(item.Address ?? ''),
      CompanyStatus: this.reportArrayStringAdapterService.getData(item.CompanyStatus),
      UnreliabilityCode: StringUtils.quoteReplacement(item.UnreliabilityCode ?? ''),
      DateEnd: item.DateEnd ?? '',
      DateStart: item.DateStart ?? '',
      IsRegionChanged: item.IsRegionChanged ?? false,
      UnreliabilityDate: item.UnreliabilityDate ?? '',
    }));
  }
}
