import { injectable, inject } from 'inversify';
import { IndividualMovementBus } from '~/types/individual/movementBus';
import { IPrimitiveTypesNormalizationServiceKey, IPrimitiveTypesNormalizationService } from '../common/primitiveTypesNormalizationService.interfaces';
import { INormalizationService } from '../common/reportNormalizationService.interfaces';

@injectable()
export class IndividualMovementBusNormalizationService implements INormalizationService<IndividualMovementBus> {
  constructor(
    @inject(IPrimitiveTypesNormalizationServiceKey as symbol) private primitiveTypesNormalizationService: IPrimitiveTypesNormalizationService,
  ) {}

  normalize(data: any, root: string): IndividualMovementBus | undefined {
    const { normalizeObject, normalizeString, normalizeOptionalString } = this.primitiveTypesNormalizationService;

    return normalizeObject(data, (data) => ({
      MovementFrom: normalizeString(data.MovementFrom, `${root}.MovementFrom`),
      MovementTo: normalizeString(data.MovementTo, `${root}.MovementTo`),
      MovementDate: normalizeOptionalString(data.MovementDate, `${root}.MovementDate`),
      ArrivalDate: normalizeOptionalString(data.ArrivalDate, `${root}.ArrivalDate`),
      SeatNumber: normalizeString(data.SeatNumber, `${root}.SeatNumber`),
    }), root);
  }
}
