import { injectable, inject } from 'inversify';
import { LegalEntitiesCompanySite } from '~/types/legal-entities/companySite';
import { IPrimitiveTypesNormalizationServiceKey, IPrimitiveTypesNormalizationService } from '../common/primitiveTypesNormalizationService.interfaces';
import { INormalizationService } from '../common/reportNormalizationService.interfaces';

@injectable()
export class LegalEntitiesCompanySiteNormalizationService implements INormalizationService<LegalEntitiesCompanySite> {
  constructor(
    @inject(IPrimitiveTypesNormalizationServiceKey as symbol) private primitiveTypesNormalizationService: IPrimitiveTypesNormalizationService,

  ) {}

  normalize(data: any, root: string): LegalEntitiesCompanySite | undefined {
    const { normalizeString, normalizeObject } = this.primitiveTypesNormalizationService;

    return normalizeObject(data, (data) => ({
      Value: normalizeString(data.Value, `${root}.Value`)
    }), root);
  }
}
