import { get } from 'lodash-es';
import { UIReportType } from '~/types/reportType';
import { createReportEntitiesStore } from '~/factories/reportEntitiesStoreFactory';
import { useInjectable } from '~/hooks/useInjectable';
import { IDataListAdapterKey } from '~/services/adapters/dataListAdapterServise.interface';
import { IVehiclesDataBlobDataAdapterServiceKey } from '~/services/adapters/vehicle/vehicleAdapterService.interfaces';
import { VehiclesDataBlobData } from '~/types/blobs/vehicles-data-blob-data';

export default createReportEntitiesStore(UIReportType.vehicle, (data) => {
  const vehiclesDataList = useInjectable(IDataListAdapterKey);
  const vehiclesAdapterService = useInjectable(IVehiclesDataBlobDataAdapterServiceKey);

  return get(vehiclesDataList.getDataList(data).map((vehicle) => vehiclesAdapterService.getData(vehicle as VehiclesDataBlobData)), 0);
});
