import { InjectionKey } from 'vue';

export interface IPrimitiveTypesNormalizationService {
  normalizeOptionalString(value: any, field: string): string | undefined;
  normalizeString(value: string, field: string): string;
  normalizeOptionalInteger(value: any, field: string): number | undefined;
  normalizeNumber(value: number, field: string): number;
  normalizeOptionalBoolean(value: any, field: string): boolean | undefined;
  normalizeBoolean(value: boolean, field: string): boolean;
  normalizeArray<T>(value: T[], normalize: (item: T, field: string) => T | undefined, field: string): T[];
  normalizeOptional<T>(data: any, normalize: (data: T, field: string) => T | undefined, field: string): T | undefined;
  normalizeObject<T>(data: any, normalize: (data: T) => T | undefined, field: string): T | undefined;
  normalizeFieldObject<T>(data: object): T;
}

export const IPrimitiveTypesNormalizationServiceKey: InjectionKey<IPrimitiveTypesNormalizationService> = Symbol('IPrimitiveTypesNormalizationService');
