import { injectable } from 'inversify';
import { IIndividualWantedAdapterService } from '~/services/adapters/individual/individualAdapterService.interfaces';
import { IndividualWanted } from '~/types/individual/wanted';
import { IndividualWantedDTO } from '~/types/dto/individual/wantedDTO';
import { StringUtils } from '~/utils/stringUtils';

@injectable()
export class IndividualWantedAdapterService implements IIndividualWantedAdapterService {
  getData(initData: IndividualWanted[]): IndividualWantedDTO[] {
    return (initData ?? []).map((item) => ({
      WantedDateStart: item.WantedDateStart ?? '',
      WantedBasis: StringUtils.quoteReplacement(item.WantedBasis ?? ''),
      WantedSearchType: StringUtils.quoteReplacement(item.WantedSearchType ?? ''),
      WantedSearchCategory: StringUtils.quoteReplacement(item.WantedSearchCategory ?? ''),
      WantedPreventiveMeasure: StringUtils.quoteReplacement(item.WantedPreventiveMeasure),
      WantedSearchInitiator: StringUtils.quoteReplacement(item.WantedSearchInitiator),
      WantedDateStartCriminal: item.WantedDateStartCriminal ?? '',
      WantedDateEndCriminal: item.WantedDateEndCriminal ?? '',
      WantedDateTerminationSearch: item.WantedDateTerminationSearch ?? '',
      WantedArmed: StringUtils.quoteReplacement(item.WantedArmed),
      WantedPreviouslyJudged: StringUtils.quoteReplacement(item.WantedPreviouslyJudged),
      WantedNote: StringUtils.quoteReplacement(item.WantedNote),
    }));
  }
}
