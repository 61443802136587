import { injectable } from 'inversify';
import {
  INodesSizeSnapshotsService, INodesSizeSnapshotsServiceBuildSnapshotOptions
} from '~/services/nodes/nodesSizeSnapshotsService.interfaces';
import { NodeRowSnapshot } from '~/models/nodes/snapshots/nodeRowSnapshot';
import { CompositeNodeSizeSnapshot } from '~/models/nodes/snapshots/compositeNodeSizeSnapshot';

@injectable()
export default class NodesSizeSnapshotsService implements INodesSizeSnapshotsService {
  buildSnapshots({ root, elementsExtractor }: INodesSizeSnapshotsServiceBuildSnapshotOptions): CompositeNodeSizeSnapshot[] {
    if (root != undefined) {
      const elements = elementsExtractor(root);
      const parents = [...new Set(elements
        .map(element => element.parentElement)
        .filter(element => element != undefined) as Element[])];

      if (parents.length == 1)
        return this.buildSnapshotsFromBody(parents[0], elements);
      else 
        return this.buildSnapshotsFromElements(elements);
    }

    return [];
  }

  buildRowsSnapshots(options: INodesSizeSnapshotsServiceBuildSnapshotOptions): NodeRowSnapshot[] {
    return this.splitIntoRows(this.buildSnapshots(options));
  }

  private buildSnapshotsFromBody(body: Element, elements: Element[]): CompositeNodeSizeSnapshot[] {
    const result = new Array<CompositeNodeSizeSnapshot>();
    let group = new Array<Element>();
    
    for (const element of body.children) {
      group.push(element);
      
      if (elements.includes(element)) {
        result.push(new CompositeNodeSizeSnapshot(group));        
        group = [];
      }
    }
    
    return result;
  }
  
  private buildSnapshotsFromElements(elements: Element[]): CompositeNodeSizeSnapshot[] {
    return elements.map(element => new CompositeNodeSizeSnapshot([element]));
  }

  private splitIntoRows(snapshot: CompositeNodeSizeSnapshot[]): NodeRowSnapshot[] {
    const rowsMap = new Map<number, CompositeNodeSizeSnapshot[]>();

    [...snapshot.values()].forEach(nodeSnapshot => {
      let row = rowsMap.get(nodeSnapshot.top);

      if (row == undefined) {
        row = new Array<CompositeNodeSizeSnapshot>();
        rowsMap.set(nodeSnapshot.top, row);
      }

      row.push(nodeSnapshot);
    });

    return [...rowsMap.values()].map<NodeRowSnapshot>((snapshots) => new NodeRowSnapshot(snapshots));
  }
}
