import { injectable, inject } from 'inversify';
import { IReportMainEntityFilePlaceholderServiceKey, IPlaceholderDataService, IReportSocialNetworkAboutInfoPlaceholderServiceKey, IReportSocialNetworkLifePositionPlaceholderServiceKey } from '~/services/placeholder/common/reportPlaceholderService.interfaces';
import { ReportMainEntityFile } from '~/types/common/reportMainEntityFile';
import { ReportUrl } from '~/types/common/reportUrl';
import { ReportSocialNetwork } from '~/types/common/reportSocialNetwork';
import { ReportSocialNetworkAboutInfo } from '~/types/common/reportSocialNetworkAboutInfo';
import { ReportSocialNetworkLifePosition } from '~/types/common/reportSocialNetworkLifePosition';
import { ReportSocialNetworkOtherPerson } from '~/types/common/reportSocialNetworkOtherPerson';
import { IPrimitiveTypesNormalizationServiceKey, IPrimitiveTypesNormalizationService } from './primitiveTypesNormalizationService.interfaces';
import { INormalizationService, IReportMainEntityFileNormalizationServiceKey, IReportSocialNetworkAboutInfoNormalizationServiceKey, IReportSocialNetworkLifePositionNormalizationServiceKey, IReportSocialNetworkOtherPersonNormalizationServiceKey, IReportUrlNormalizationServiceKey } from './reportNormalizationService.interfaces';

@injectable()
export class ReportSocialNetworkNormalizationService implements INormalizationService<ReportSocialNetwork> {
  constructor(

    @inject(IReportMainEntityFileNormalizationServiceKey as symbol) private reportMainEntityFileNormalizationService: INormalizationService<ReportMainEntityFile>,
    @inject(IReportSocialNetworkAboutInfoNormalizationServiceKey as symbol) private socialNetworkAboutInfoNormalizationService: INormalizationService<ReportSocialNetworkAboutInfo>,
    @inject(IReportSocialNetworkLifePositionNormalizationServiceKey as symbol) private socialNetworkLifePositionNormalizationService: INormalizationService<ReportSocialNetworkLifePosition>,
    @inject(IReportUrlNormalizationServiceKey as symbol) private reportUrlNormalizationService: INormalizationService<ReportUrl>,
    @inject(IReportSocialNetworkOtherPersonNormalizationServiceKey as symbol) private socialNetworkOtherPersonNormalizationService: INormalizationService<ReportSocialNetworkOtherPerson>,

    @inject(IReportMainEntityFilePlaceholderServiceKey as symbol) private reportMainEntityFilePlaceholderService: IPlaceholderDataService<ReportMainEntityFile>,
    @inject(IReportSocialNetworkAboutInfoPlaceholderServiceKey as symbol) private socialNetworkAboutInfoPlaceholderService: IPlaceholderDataService<ReportSocialNetworkAboutInfo>,
    @inject(IReportSocialNetworkLifePositionPlaceholderServiceKey as symbol) private socialNetworkLifePositionPlaceholderService: IPlaceholderDataService<ReportSocialNetworkLifePosition>,

    @inject(IPrimitiveTypesNormalizationServiceKey as symbol) private primitiveTypesNormalizationService: IPrimitiveTypesNormalizationService,
  ) {}

  normalize(data: any, root: string): ReportSocialNetwork | undefined {
    const {
      normalizeObject,
      normalizeNumber,
      normalizeString,
      normalizeOptionalString,
      normalizeBoolean,
      normalizeArray,
      normalizeOptional
    } = this.primitiveTypesNormalizationService;

    return normalizeObject(data, (data) => ({
      Type: normalizeNumber(data.Type, `${root}.Type`),
      SocialId: normalizeString(data.SocialId, `${root}.SocialId`),
      FullName: normalizeString(data.FullName, `${root}.FullName`),
      Birthday: normalizeOptionalString(data.Birthday, `${root}.Birthday`),
      IsVerified: normalizeBoolean(data.IsVerified, `${root}.IsVerified`),
      IsPrivateAccount: normalizeBoolean(data.IsPrivateAccount, `${root}.IsPrivateAccount`),
      LastDevice: normalizeString(data.LastDevice, `${root}.LastDevice`),
      Status: normalizeString(data.Status, `${root}.Status`),
      Languages: normalizeString(data.Languages, `${root}.Languages`),
      Subscribers: normalizeArray(data.Subscribers, (item, field) => this.socialNetworkOtherPersonNormalizationService.normalize(item, field), `${root}.Subscribers`),
      Friends: normalizeArray(data.Friends, (item, field) => this.socialNetworkOtherPersonNormalizationService.normalize(item, field), `${root}.Friends`),
      WhoLikes: normalizeArray(data.WhoLikes, (item, field) => this.socialNetworkOtherPersonNormalizationService.normalize(item, field), `${root}.WhoLikes`),
      WhoComments: normalizeArray(data.WhoComments, (item, field) => this.socialNetworkOtherPersonNormalizationService.normalize(item, field), `${root}.WhoComments`),
      WhoReposts: normalizeArray(data.WhoReposts, (item, field) => this.socialNetworkOtherPersonNormalizationService.normalize(item, field), `${root}.WhoReposts`),
      WhoMentions: normalizeArray(data.WhoMentions, (item, field) => this.socialNetworkOtherPersonNormalizationService.normalize(item, field), `${root}.WhoMentions`),
      WhoIsLikedByAccountOwner: normalizeArray(data.WhoIsLikedByAccountOwner, (item, field) => this.socialNetworkOtherPersonNormalizationService.normalize(item, field), `${root}.WhoIsLikedByAccountOwner`),
      WhoIsRepostedByAccountOwner: normalizeArray(data.WhoIsRepostedByAccountOwner, (item, field) => this.socialNetworkOtherPersonNormalizationService.normalize(item, field), `${root}.WhoIsRepostedByAccountOwner`),
      WhoIsCommentedByAccountOwner: normalizeArray(data.WhoIsCommentedByAccountOwner, (item, field) => this.socialNetworkOtherPersonNormalizationService.normalize(item, field), `${root}.WhoIsCommentedByAccountOwner`),
      WhoIsMentionedByAccountOwner: normalizeArray(data.WhoIsMentionedByAccountOwner, (item, field) => this.socialNetworkOtherPersonNormalizationService.normalize(item, field), `${root}.WhoIsMentionedByAccountOwner`),
      Photos: normalizeArray(data.Photos, (item, field) => this.reportMainEntityFileNormalizationService.normalize(item, field), `${root}.Photos`),
      Avatar: normalizeOptional(data.Avatar, (data, field) => this.reportMainEntityFileNormalizationService.normalize(data, field), `${root}.Avatar`),
      SocialNetworkAboutInfos: this.socialNetworkAboutInfoNormalizationService.normalize(data.SocialNetworkAboutInfos, `${root}.SocialNetworkAboutInfos`) ?? this.socialNetworkAboutInfoPlaceholderService.getData(),
      SocialNetworkLifePosition: this.socialNetworkLifePositionNormalizationService.normalize(data.SocialNetworkLifePosition, `${root}.SocialNetworkLifePosition`) ?? this.socialNetworkLifePositionPlaceholderService.getData(),
      SocialGroups: normalizeArray(data.SocialGroups, (item, field) => this.reportUrlNormalizationService.normalize(item, field), `${root}.SocialGroups`),
      VideoUrls: normalizeArray(data.VideoUrls, (item, field) => this.reportUrlNormalizationService.normalize(item, field), `${root}.VideoUrls`),
      AudioUrls: normalizeArray(data.AudioUrls, (item, field) => this.reportUrlNormalizationService.normalize(item, field), `${root}.AudioUrls`),
    }), root);
  }
}
