import { DataBlobDataWrapperPlaceholderService } from '~/services/placeholder/blob/dataBlobDataWrapperPlaceholderService';
import { IDataBlobReportOriginalInputFileInfoPlaceholderServiceKey, IDataBlobReportOriginalInputPlaceholderServiceKey, IDataBlobInputDataListPlaceholderServiceKey } from '~/services/placeholder/blob/dataBlobPlaceholderService.interfaces';
import { DataBlobReportOriginalInputFileInfoPlaceholderService } from '~/services/placeholder/blob/dataBlobReportOriginalInputFileInfoPlaceholderService';
import { DataBlobReportOriginalInputPlaceholderService } from '~/services/placeholder/blob/dataBlobReportOriginalInputPlaceholderService';
import { DataBlobInputDataListPlaceholderService } from '~/services/placeholder/blob/dataBlobInputDataListPlaceholderService';
import { IContainerBinder } from './containerAccessor.interface';
import { IDataBlobDataWrapperPlaceholderServiceKey } from '~/services/placeholder/blob/dataBlobDataWrapperPlaceholderService.interfaces';

export function bindDataBlobDataWrapperPlaceholderServices(bind: IContainerBinder) {
  bind(IDataBlobReportOriginalInputFileInfoPlaceholderServiceKey).to(DataBlobReportOriginalInputFileInfoPlaceholderService);
  bind(IDataBlobReportOriginalInputPlaceholderServiceKey).to(DataBlobReportOriginalInputPlaceholderService);
  bind(IDataBlobInputDataListPlaceholderServiceKey).to(DataBlobInputDataListPlaceholderService);
  bind(IDataBlobDataWrapperPlaceholderServiceKey).to(DataBlobDataWrapperPlaceholderService);
}
