import { injectable, inject } from 'inversify';

import { IPrimitiveTypesNormalizationServiceKey, IPrimitiveTypesNormalizationService } from './primitiveTypesNormalizationService.interfaces';
import { ReportPhotosUrl } from '~/types/common/reportVehiclePhotosUrl';
import { INormalizationService } from './reportNormalizationService.interfaces';

@injectable()
export class ReportPhotosUrlNormalizationService implements INormalizationService<ReportPhotosUrl> {
  constructor(
    @inject(IPrimitiveTypesNormalizationServiceKey as symbol) private primitiveTypesNormalizationService: IPrimitiveTypesNormalizationService,
  ) {}

  normalize(data: any, root: string): ReportPhotosUrl | undefined {
    const { normalizeString, normalizeObject } = this.primitiveTypesNormalizationService;

    return normalizeObject(data, (data) => ({
      Text: normalizeString(data.Text, `${root}.Text`),
      Value: normalizeString(data.Value, `${root}.Value`),
    }), root);
  }
}
