import { injectable } from "inversify";
import { MergeService } from "./merge-service";
import { ReportFineDTO } from "~/types/dto/common/fineDTO";

@injectable()
export class ReportFineMergeService extends MergeService<ReportFineDTO> {
  protected canMerge(leftItem: ReportFineDTO, rightItem: ReportFineDTO): boolean {
      return leftItem.Initiator === rightItem.Initiator && 
             leftItem.Date === rightItem.Date && 
             leftItem.Sum === rightItem.Sum && 
             leftItem.SubjectOfExecution === rightItem.SubjectOfExecution;
  }

  protected mergeFields(leftItem: ReportFineDTO, rightItem: ReportFineDTO): ReportFineDTO {
    return {
      Date: leftItem.Date ?? rightItem.Date,
      Initiator: leftItem.Initiator ?? rightItem.Initiator,
      KoapCode: leftItem.KoapCode ?? rightItem.KoapCode,
      Photos: leftItem.Photos ?? rightItem.Photos,
      RestSum: leftItem.RestSum || rightItem.RestSum,
      Status: leftItem.Status ?? rightItem.Status,
      SubjectOfExecution: leftItem.SubjectOfExecution ?? rightItem.SubjectOfExecution,
      Sum: leftItem.Sum ?? rightItem.Sum
    }
  }
}