import { injectable, inject } from 'inversify';
import { orderBy } from 'lodash-es';
import { IReportVehicleRelationAdapterService } from '~/services/adapters/common/reportAdapterService.interfeces';

import { DateUtils } from '~/utils/date';
import { StringUtils } from '~/utils/stringUtils';

import { ReportVehicleRelation } from '~/types/common/reportVehicleRelation';
import { ReportVehicleRelationDTO } from '~/types/dto/common/reportVehicleRelationDTO';
import { IPtsService, IPtsServiceKey } from '~/modules/reportsCommon/reportVehicle/services/ptsService.interfaces';

@injectable()
export class ReportVehicleRelationAdapterService implements IReportVehicleRelationAdapterService {
  constructor(
    @inject(IPtsServiceKey as symbol) private readonly ptsService: IPtsService
  ) {}

  getData(initData: ReportVehicleRelation[]): ReportVehicleRelationDTO[] {
    return orderBy(initData ?? [], ['RegDate'], ['desc']).map((relation) => {
      const name = StringUtils.quoteReplacement(relation.Name ? relation.Name.trim() : '');
      
      return {
        SourceType: relation.SourceType,
        RegDate: DateUtils.formatDate(relation.RegDate),
        DeregistrationDate: DateUtils.formatDate(relation.DeregistrationDate),
        Name: name,
        Birthday: DateUtils.formatDate(relation.Birthday),
        PhoneNumber: relation.PhoneNumber ?? '',
        Sts: relation.Sts ?? '',
        Pts: relation.Pts ?? '',
        Inn: relation.Inn ?? '',
        PtsIssuedDate: relation.PtsIssuedDate ?? '',
        Passport: relation.Passport,
        Address: relation.Address ?? '',
        PtsDepartmentName: relation.PtsDepartmentName,
      }; 
    })
      .filter(
        (item) => item.RegDate
                    || item.DeregistrationDate
                    || item.Name
                    || item.Birthday
                    || item.PhoneNumber
                    || item.Sts
                    || item.Pts,
      );
  }
}
