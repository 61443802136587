import { injectable, inject } from 'inversify';
import { LegalEntitiesRelatedCompany } from '~/types/legal-entities/relatedCompany';
import { IPrimitiveTypesNormalizationServiceKey, IPrimitiveTypesNormalizationService } from '../common/primitiveTypesNormalizationService.interfaces';
import { INormalizationService, IStringArrayNormalizationServiceKey } from '../common/reportNormalizationService.interfaces';

@injectable()
export class LegalEntitiesRelatedCompanyNormalizationService implements INormalizationService<LegalEntitiesRelatedCompany> {
  constructor(
    @inject(IStringArrayNormalizationServiceKey as symbol) private stringArrayNormalizationService: INormalizationService<string>,
    @inject(IPrimitiveTypesNormalizationServiceKey as symbol) private primitiveTypesNormalizationService: IPrimitiveTypesNormalizationService,
  ) {}

  normalize(data: any, root: string): LegalEntitiesRelatedCompany | undefined {
    const {
      normalizeNumber,
      normalizeString,
      normalizeArray,
      normalizeObject
    } = this.primitiveTypesNormalizationService;

    return normalizeObject(data, (data) => ({
      Fio: normalizeString(data.Fio, `${root}.Fio`),
      Role: normalizeString(data.Role, `${root}.Role`),
      Reason: normalizeString(data.Reason, `${root}.Reason`),
      Percent: normalizeNumber(data.Percent, `${root}.Percent`),
      CompanyName: normalizeString(data.CompanyName, `${root}.CompanyName`),
      CompanyStatus: normalizeArray(data.CompanyStatus, (item, field) => this.stringArrayNormalizationService.normalize(item, field), `${root}.CompanyStatus`),
      CompanyStatusLevel: normalizeNumber(data.CompanyStatusLevel, `${root}.CompanyStatusLevel`),
      CompanyAddress: normalizeString(data.CompanyAddress, `${root}.CompanyAddress`),
      CompanyMainActivityText: normalizeString(data.CompanyMainActivityText, `${root}.CompanyMainActivityText`),
      CompanyInn: normalizeString(data.CompanyInn, `${root}.CompanyInn`),
      CompanyOgrn: normalizeString(data.CompanyOgrn, `${root}.CompanyOgrn`),
    }), root);
  }
}
