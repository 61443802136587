import { injectable, inject } from 'inversify';
import { ReportMainEntityFile } from '~/types/common/reportMainEntityFile';
import { ReportMessengerInfo } from '~/types/common/reportMessengerInfo';
import { IPrimitiveTypesNormalizationServiceKey, IPrimitiveTypesNormalizationService } from './primitiveTypesNormalizationService.interfaces';
import { INormalizationService, IReportMainEntityFileNormalizationServiceKey } from './reportNormalizationService.interfaces';

@injectable()
export class ReportMessengerInfoNormalizationService implements INormalizationService<ReportMessengerInfo> {
  constructor(
    @inject(IReportMainEntityFileNormalizationServiceKey as symbol) private reportMainEntityFileNormalizationService: INormalizationService<ReportMainEntityFile>,
    @inject(IPrimitiveTypesNormalizationServiceKey as symbol) private primitiveTypesNormalizationService: IPrimitiveTypesNormalizationService,
  ) {}

  normalize(data: any, root: string): ReportMessengerInfo | undefined {
    const {
      normalizeObject,
      normalizeNumber,
      normalizeOptionalString,
      normalizeArray
    } = this.primitiveTypesNormalizationService;

    return normalizeObject(data, (data) => ({
      Type: normalizeNumber(data.Type, `${root}.Type`),
      MessengerId: normalizeOptionalString(data.MessengerId, `${root}.MessengerId`),
      Status: normalizeOptionalString(data.Status, `${root}.Status`),
      OnlineDate: normalizeOptionalString(data.OnlineDate, `${root}.OnlineDate`),
      Photos: normalizeArray(data.Photos, (item, field) => this.reportMainEntityFileNormalizationService.normalize(item, field), `${root}.Photos`)
    }), root);
  }
}
