import { injectable, inject } from 'inversify';

import { ReportSocialNetworkMilitaryRecord } from '~/types/common/reportSocialNetworkMilitaryRecord';
import { IPrimitiveTypesNormalizationService, IPrimitiveTypesNormalizationServiceKey } from './primitiveTypesNormalizationService.interfaces';
import { INormalizationService } from './reportNormalizationService.interfaces';

@injectable()
export class ReportSocialNetworkMilitaryRecordNormalizationService implements INormalizationService<ReportSocialNetworkMilitaryRecord> {
  constructor(
    @inject(IPrimitiveTypesNormalizationServiceKey as symbol) private primitiveTypesNormalizationService: IPrimitiveTypesNormalizationService,
  ) {}

  normalize(data: any, root: string): ReportSocialNetworkMilitaryRecord | undefined {
    const {
      normalizeObject,
      normalizeNumber,
      normalizeString,
      normalizeOptionalString,
      normalizeOptionalBoolean
    } = this.primitiveTypesNormalizationService;

    return normalizeObject(data, (data) => ({
      Type: normalizeNumber(data.Type, `${root}.Type`),
      SocialNetworkId: normalizeString(data.SocialNetworkId, `${root}.SocialNetworkId`),
      LastName: normalizeString(data.LastName, `${root}.LastName`),
      FirstName: normalizeString(data.FirstName, `${root}.FirstName`),
      Birthday: normalizeOptionalString(data.Birthday, `${root}.Birthday`),
      EducationName: normalizeString(data.EducationName, `${root}.EducationName`),
      City: normalizeString(data.City, `${root}.City`),
      MilitaryUnit: normalizeString(data.MilitaryUnit, `${root}.MilitaryUnit`),
      StartDate: normalizeOptionalString(data.StartDate, `${root}.StartDate`),
      EndDate: normalizeOptionalString(data.EndDate, `${root}.EndDate`),
      IsServingNow: normalizeOptionalBoolean(data.IsServingNow, `${root}.IsServingNow`)
    }), root);
  }
}
