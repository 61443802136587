/* eslint-disable class-methods-use-this, max-len */
import { injectable } from 'inversify';
import { InjectionKey } from 'vue';
import { uniq } from 'lodash-es';
import { MergeService } from '@/services/merge/merge-service';
import { useInjectable } from '~/hooks/useInjectable';
import { IPrimitiveTypesMergeServiceKey } from '@/services/merge/primitive-types-merge-service.interface';
import { ReportVehicleCarNumberSourceType } from '~/types/common/reportVehicleCarNumberSourceType';
import { ReportVehicleRelationDTO } from '~/types/dto/common/reportVehicleRelationDTO';

@injectable()
export class VehicleRelationsMergeService extends MergeService<ReportVehicleRelationDTO> {
  protected canMerge(leftItem: ReportVehicleRelationDTO, rightItem: ReportVehicleRelationDTO): boolean {
    return leftItem.Name === rightItem.Name;
  }

  protected mergeFields(leftRelation: ReportVehicleRelationDTO, rightRelation: ReportVehicleRelationDTO): ReportVehicleRelationDTO {
    const primitiveTypesMergeService = useInjectable(IPrimitiveTypesMergeServiceKey);
    const mergePts = uniq([...leftRelation.Pts.split(', '), ...rightRelation.Pts.split(', ')]).join(', ');

    return {
      Address: primitiveTypesMergeService.mergeStrings(leftRelation.Address, rightRelation.Address),
      Inn: primitiveTypesMergeService.mergeStrings(leftRelation.Inn, rightRelation.Inn),
      Name: primitiveTypesMergeService.mergeStrings(leftRelation.Name, rightRelation.Name),
      Passport: primitiveTypesMergeService.mergeStrings(leftRelation.Passport, rightRelation.Passport),
      PhoneNumber: primitiveTypesMergeService.mergeStrings(leftRelation.PhoneNumber, rightRelation.PhoneNumber),
      Pts: mergePts,
      PtsDepartmentName: primitiveTypesMergeService.mergeStrings(leftRelation.PtsDepartmentName, rightRelation.PtsDepartmentName),
      SourceType: leftRelation.SourceType !== ReportVehicleCarNumberSourceType.Unknown ? leftRelation.SourceType : rightRelation.SourceType,
      Sts: primitiveTypesMergeService.mergeStrings(leftRelation.Sts, rightRelation.Sts),
      Birthday: primitiveTypesMergeService.mergeStrings(leftRelation.Birthday, rightRelation.Birthday),
      DeregistrationDate: primitiveTypesMergeService.mergeStrings(leftRelation.DeregistrationDate, rightRelation.DeregistrationDate),
      PtsIssuedDate: primitiveTypesMergeService.mergeStrings(leftRelation.PtsIssuedDate, rightRelation.PtsIssuedDate),
      RegDate: primitiveTypesMergeService.mergeStrings(leftRelation.RegDate, rightRelation.RegDate),
    };
  }
}

export const IVehicleRelationsMergeServiceKey: InjectionKey<VehicleRelationsMergeService> = Symbol('VehicleRelationsMergeService');
