import { injectable } from 'inversify';
import { IFinePrivateArticleInfoConfigService } from '~/modules/reportsCommon/reportFine/services/fines.interfaces';
import { ReportFineDTO } from '~/types/dto/common/fineDTO';
import { Config } from '~/types/field-list/config';
import { StringUtils } from '~/utils/stringUtils';
import { DateUtils } from '~/utils/date';

@injectable()
export class FinePrivateArticleInfoConfigService implements IFinePrivateArticleInfoConfigService {
  getConfig(initData: ReportFineDTO): Config {
    const dateLabel = initData.Date ? `${DateUtils.formatDate(initData.Date)} / ${DateUtils.getTime(initData.Date)}` : '';
    return {
      fieldsWidth: '100%',
      fieldsDirection: 'column',
      fieldsValueWidth: 'auto',
      fields: [
        {
          label: 'Статья КоАП',
          key: 'SubjectOfExecution',
          value: StringUtils.CapitalizeString(initData.SubjectOfExecution),
        },
        {
          label: 'Подразделение ГИБДД',
          key: 'Initiator',
          value: initData.Initiator,
        },
        {
          label: 'Дата и время нарушения',
          key: 'Date',
          value: {
            text: dateLabel,
            width: '180px'
          },
          direction: 'divider-row'
        }
      ]
    };
  }
}
