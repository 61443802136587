import { injectable } from 'inversify';
import { ReportJob } from '~/types/common/reportJob';
import { IPlaceholderDataService } from './reportPlaceholderService.interfaces';

@injectable()
export class ReportJobPlaceholderService implements IPlaceholderDataService<ReportJob> {
  getData(): ReportJob {
    return {
      CompanyName: '',
      CompanyInn: '',
      DepartmentName: '',
      Position: '',
      StartDate: '',
      EndDate: '',
      CompanyAddress: '',
      CompanyPhone: '',
      CompanyHeadPhone: '',
      CompanyBookkeeperPhone: '',
      ContractType: '',
      ArticleDissmissal: '',
      Payments: [],
      Colleagues: []
    };
  }
}
