import { injectable, inject } from 'inversify';

import { ReportVehicleRestrict } from '~/types/common/reportVehicleRestrict';
import { IPrimitiveTypesNormalizationServiceKey, IPrimitiveTypesNormalizationService } from './primitiveTypesNormalizationService.interfaces';
import { INormalizationService } from './reportNormalizationService.interfaces';

@injectable()
export class ReportVehicleRestrictNormalizationService implements INormalizationService<ReportVehicleRestrict> {
  constructor(
    @inject(IPrimitiveTypesNormalizationServiceKey as symbol) private primitiveTypesNormalizationService: IPrimitiveTypesNormalizationService,
  ) {}

  normalize(data: any, root: string): ReportVehicleRestrict | undefined {
    const { normalizeString, normalizeObject } = this.primitiveTypesNormalizationService;

    return normalizeObject(data, (data) => ({
      Region: normalizeString(data.Region, `${root}.Region`),
      Reason: normalizeString(data.Reason, `${root}.Reason`),
      Type: normalizeString(data.Type, `${root}.Type`),
    }), root);
  }
}
