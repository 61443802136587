import { injectable } from 'inversify';
import { ILegalEntitiesAnalyticsIndicatorsAdapterService } from '~/services/adapters/legalEntities/legalEntitiesAdapterService.interfaces';
import { LegalEntitiesAnalyticsIndicatorsDTO } from '~/types/dto/legalEntities/analyticsIndicators';
import { LegalEntitiesAnalyticsIndicators } from '~/types/legal-entities/analyticsIndicators';

@injectable()
export class LegalEntitiesAnalyticsIndicatorsAdapterService implements ILegalEntitiesAnalyticsIndicatorsAdapterService {
  getData(initData: LegalEntitiesAnalyticsIndicators): LegalEntitiesAnalyticsIndicatorsDTO {
    return {
      CountOfCompaniesOnHead: initData.CountOfCompaniesOnHead ?? 0,
      CountOfCompaniesOnFounder: initData.CountOfCompaniesOnFounder ?? 0
    };
  }
}
