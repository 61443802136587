import { injectable, inject } from 'inversify';

import { IndividualSpecialSigns } from '~/types/individual/specialSigns';
import { IPrimitiveTypesNormalizationServiceKey, IPrimitiveTypesNormalizationService } from '../common/primitiveTypesNormalizationService.interfaces';
import { INormalizationService } from '../common/reportNormalizationService.interfaces';

@injectable()
export class IndividualSpecialSignsNormalizationService implements INormalizationService<IndividualSpecialSigns> {
  constructor(
    @inject(IPrimitiveTypesNormalizationServiceKey as symbol) private primitiveTypesNormalizationService: IPrimitiveTypesNormalizationService,
  ) {}

  normalize(data: any, root: string): IndividualSpecialSigns | undefined {
    const { normalizeObject, normalizeString } = this.primitiveTypesNormalizationService;

    return normalizeObject(data, (data) => ({
      CriminalNickName: normalizeString(data.CriminalNickName, `${root}.CriminalNickName`),
      SpecialSigns: normalizeString(data.SpecialSigns, `${root}.SpecialSigns`)
    }), root);
  }
}
