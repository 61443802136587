import { injectable } from 'inversify';
import { get } from 'lodash-es';
import { IRatioOfCurrentLiquidityTableItemConfigService } from '~/modules/legalEntities/financialAnalytics/legalEntitiesLiquidityAndSolvency/services/legalEntitiesLiquidityAndSolvencyTableItemConfigService.interfaces';
import { TableItem } from '~/modules/table/types/tableItem';
import { LegalEntitiesReportFinancialIndicatorWithConclusionsDTO } from '~/types/dto/legalEntities/reportFinancialIndicatorWithConclusionsDTO';
import { NumberUtils } from '~/utils/number';
import { FINANCIAL_ANALYSIS_EXTENDED_TITLES } from '~/modules/legalEntities/financialAnalytics/common/configuration/constants/financialAnalysisExtendedTitles';

@injectable()
export class RatioOfCurrentLiquidityTableItemConfigService implements IRatioOfCurrentLiquidityTableItemConfigService {
  getItem(initData:LegalEntitiesReportFinancialIndicatorWithConclusionsDTO):TableItem {
    return {
      TwoYearsAgo: { value: NumberUtils.formatNumberDigits(initData.TwoYearsAgo, 2, '—'), color: this.getColor(initData.TwoYearsAgo) },
      LastYear: { value: NumberUtils.formatNumberDigits(initData.LastYear, 2, '—'), color: this.getColor(initData.LastYear) },
      CurrentYear: { value: NumberUtils.formatNumberDigits(initData.CurrentYear, 2, '—'), color: this.getColor(initData.CurrentYear) },

      indicator: {
        title: FINANCIAL_ANALYSIS_EXTENDED_TITLES.RatioOfCurrentLiquidity,
        subtitle: 'Cоотношение оборотных активов к текущим обязательствам'
      },
      Conclusions: get(initData.Conclusions, 0, '—'),
      referenceValue: '1.5 - 2',
    };
  }

  getColor(value: number): string {
    if (value < 1.5) return 'red';
    if (value > 2) return 'green';
    return 'black';
  }
}
