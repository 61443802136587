import { inject, injectable } from 'inversify';
import {
  IPrimitiveTypesNormalizationService,
  IPrimitiveTypesNormalizationServiceKey
} from './primitiveTypesNormalizationService.interfaces';
import { ReportConnectionRelatedEntity } from '~/types/common/reportConnectionRelatedEntity';
import { ReportConnectionRelatedEntityDisplay } from '~/types/common/reportConnectionRelatedEntityDisplay';
import {
  INormalizationService,
  IReportConnectionRelatedEntityDisplayNormalizationServiceKey
} from '~/services/normalization/common/reportNormalizationService.interfaces';

@injectable()
export class ReportConnectionRelatedEntityNormalizationService implements INormalizationService<ReportConnectionRelatedEntity> {
  constructor(
    @inject(IPrimitiveTypesNormalizationServiceKey as symbol) private primitiveTypesNormalizationService: IPrimitiveTypesNormalizationService,
        
    @inject(IReportConnectionRelatedEntityDisplayNormalizationServiceKey as symbol) private reportConnectionRelatedEntityDisplayNormalizationService: INormalizationService<ReportConnectionRelatedEntityDisplay>,
  ) {}

  normalize(data: any, root: string): ReportConnectionRelatedEntity | undefined {
    const {
      normalizeOptionalString,
      normalizeOptionalInteger,
      normalizeArray,
      normalizeObject
    } = this.primitiveTypesNormalizationService;

    return normalizeObject(data, (data) => ({
      Name: normalizeOptionalString(data.Name, `${root}.Name`),
      Snils: normalizeOptionalString(data.Snils, `${root}.Snils`),
      DisplayJobs: normalizeArray(
        data.DisplayJobs,
        (item, field) => this.reportConnectionRelatedEntityDisplayNormalizationService.normalize(item, field),
        `${root}.DisplayJobs`
      ),
      DisplayAffiliates: normalizeArray(
        data.DisplayAffiliates,
        (item, field) => this.reportConnectionRelatedEntityDisplayNormalizationService.normalize(item, field),
        `${root}.DisplayAffiliates`
      ),
      EntityId: normalizeOptionalString(data.EntityId, `${root}.EntityId`),
      EntityDetailUrl: normalizeOptionalString(data.EntityDetailUrl, `${root}.EntityDetailUrl`),
      Inn: normalizeOptionalString(data.Inn, `${root}.Inn`),
      Depth: normalizeOptionalInteger(data.Depth, `${root}.Depth`)
    }), root);
  }
}
