import { injectable } from 'inversify';
import { IndividualLegalEntitiesParticipationRestriction } from '~/types/individual/legalEntitiesParticipationRestriction';
import { IPlaceholderDataService } from '../common/reportPlaceholderService.interfaces';

@injectable()
export class IndividualLegalEntitiesParticipationRestrictionPlaceholderService implements IPlaceholderDataService<IndividualLegalEntitiesParticipationRestriction> {
  getData(): IndividualLegalEntitiesParticipationRestriction {
    return {
      ParticipationType: '',
      CompanyName: '',
      Inn: '',
      Ogrn: '',
      Status: '',
      PrincipalActivity: '',
      RiskIndicator: '',
      Address: '',
      RegistrationDate: '',
      LiquidationDate: '',
      Position: '',
      IsHistoric: false,
    };
  }
}
