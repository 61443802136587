import { injectable, inject } from 'inversify';

import { LegalEntitiesReportDataBuhFormDetail } from '~/types/legal-entities/reportDataBuhFormDetail';
import { IPrimitiveTypesNormalizationServiceKey, IPrimitiveTypesNormalizationService } from '../common/primitiveTypesNormalizationService.interfaces';
import { INormalizationService } from '../common/reportNormalizationService.interfaces';

@injectable()
export class LegalEntitiesReportDataBuhFormDetailNormalizationService implements INormalizationService<LegalEntitiesReportDataBuhFormDetail> {
  constructor(
    @inject(IPrimitiveTypesNormalizationServiceKey as symbol) private primitiveTypesNormalizationService: IPrimitiveTypesNormalizationService,
  ) {}

  normalize(data: any, root: string): LegalEntitiesReportDataBuhFormDetail | undefined {
    const { normalizeNumber, normalizeString, normalizeObject } = this.primitiveTypesNormalizationService;

    return normalizeObject(data, (data) => ({
      Code: normalizeNumber(data.Code, `${root}.Code`),
      Name: normalizeString(data.Name, `${root}.Name`),
      StartValue: normalizeNumber(data.StartValue, `${root}.StartValue`),
      EndValue: normalizeNumber(data.EndValue, `${root}.EndValue`),
    }), root);
  }
}
