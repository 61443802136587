import { injectable } from 'inversify';
import { IIndividualBoundedPhoneSiftingService } from '~/modules/reportsCommon/reportBoundedPhones/individual/services/individualBoundedPhoneSiftingService.interfaces';
import { ReportBoundedPersonPhoneDTO } from '~/types/dto/common/boundedPersonPhoneDTO';

@injectable()
export class IndividualBoundedPhoneSiftingService implements IIndividualBoundedPhoneSiftingService {
  getSiftedData(initData: ReportBoundedPersonPhoneDTO[]): ReportBoundedPersonPhoneDTO[] {
    return initData.filter((item) => item.OriginalRequestData);
  }
}
