import { injectable } from 'inversify';
import { ReportFine } from '~/types/common/reportFine';
import { IPlaceholderDataService } from './reportPlaceholderService.interfaces';

@injectable()
export class ReportFinePlaceholderService implements IPlaceholderDataService<ReportFine> {
  getData(): ReportFine {
    return {
      Initiator: '',
      Sum: 0,
      RestSum: 0,
      SubjectOfExecution: '',
      KoapCode: '',
      Date: '',
      Status: '',
      Photos: []
    };
  }
}
