import { injectable, inject } from 'inversify';

import { IPrimitiveTypesNormalizationServiceKey, IPrimitiveTypesNormalizationService } from './primitiveTypesNormalizationService.interfaces';
import { ReportMention } from '~/types/common/reportMention';
import { INormalizationService } from './reportNormalizationService.interfaces';

@injectable()
export class ReportMentionNormalizationService implements INormalizationService<ReportMention> {
  constructor(
    @inject(IPrimitiveTypesNormalizationServiceKey as symbol) private primitiveTypesNormalizationService: IPrimitiveTypesNormalizationService,
  ) {}

  normalize(data: any, root: string): ReportMention | undefined {
    const { normalizeString, normalizeObject } = this.primitiveTypesNormalizationService;

    return normalizeObject(data, (data) => ({
      Source: normalizeString(data.Source, `${root}.Source`),
      FullName: normalizeString(data.FullName, `${root}.FullName`),
      OriginalFullName: normalizeString(data.OriginalFullName, `${root}.OriginalFullName`),
    }), root);
  }
}
