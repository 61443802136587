import { injectable } from 'inversify';
import { ILegalEntitiesReportDataBuhFormDetailAdapterService } from '~/services/adapters/legalEntities/legalEntitiesAdapterService.interfaces';
import { LegalEntitiesReportDataBuhFormDetailDTO } from '~/types/dto/legalEntities/reportDataBuhFormDetailDTO';
import { LegalEntitiesReportDataBuhFormDetail } from '~/types/legal-entities/reportDataBuhFormDetail';

@injectable()
export class LegalEntitiesReportDataBuhFormDetailAdapterService implements ILegalEntitiesReportDataBuhFormDetailAdapterService {
  getData(initData: LegalEntitiesReportDataBuhFormDetail[]): LegalEntitiesReportDataBuhFormDetailDTO[] {
    return (initData ?? []).map((item) => ({
      Code: item.Code,
      Name: item.Name,
      StartValue: item.StartValue,
      EndValue: item.EndValue
    }));
  }
}
