import { injectable } from 'inversify';
import { ReportSocialNetworkIdDTO } from '~/types/dto/common/reportSocialNetworkIdDTO';
import { IReportSocialNetworkIdAdapterService } from '~/services/adapters/common/reportAdapterService.interfeces';
import { ReportSocialNetworkId } from '~/types/common/reportSocialNetworkId';

@injectable()
export class ReportSocialNetworkIdAdapterService implements IReportSocialNetworkIdAdapterService {
  getData(initData: ReportSocialNetworkId[]): ReportSocialNetworkIdDTO[] {
    return (initData ?? []).map((item) => ({
      Type: item.Type,
      SocialId: item.SocialId
    }));
  }
}
