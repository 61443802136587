import { injectable } from 'inversify';

import { LegalEntitiesCompanyStatedCapital } from '~/types/legal-entities/companyStatedCapital';
import { IPlaceholderDataService } from '../common/reportPlaceholderService.interfaces';

@injectable()
export class LegalEntitiesCompanyStatedCapitalPlaceholderDataService implements IPlaceholderDataService<LegalEntitiesCompanyStatedCapital> {
  getData(): LegalEntitiesCompanyStatedCapital {
    return {
      Sum: 0,
      From: '',
      To: ''
    };
  }
}
