import { injectable } from "inversify";
import { MergeService } from "~/services/merge/merge-service";
import { IndividualFinancialScoringDTO } from "~/types/dto/individual/financialScoringDTO";

@injectable()
export class IndividualFinancialScoringMergeService extends MergeService<IndividualFinancialScoringDTO> {
  protected canMerge(leftItem: IndividualFinancialScoringDTO, rightItem: IndividualFinancialScoringDTO): boolean {
    return leftItem.FinancialScoringType === rightItem.FinancialScoringType && leftItem.Score === rightItem.Score;
  }

  protected mergeFields(leftItem: IndividualFinancialScoringDTO, rightItem: IndividualFinancialScoringDTO): IndividualFinancialScoringDTO {
    return {
      CheckedBirthday: leftItem.CheckedBirthday || rightItem.CheckedBirthday,
      CheckedFio: leftItem.CheckedFio || rightItem.CheckedFio,
      CheckedPassportIssueDate: leftItem.CheckedPassportIssueDate || rightItem.CheckedPassportIssueDate,
      CheckedPassportNumber: leftItem.CheckedPassportNumber || rightItem.CheckedPassportNumber,
      CheckedPhone: leftItem.CheckedPhone || rightItem.CheckedPhone,
      FinancialScoringType: leftItem.FinancialScoringType || rightItem.FinancialScoringType,
      Reason: leftItem.Reason || rightItem.Reason,
      Score: leftItem.Score || rightItem.Score,
    }
  }
}