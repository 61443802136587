import { InjectionKey } from 'vue';
import { ReportBoundedPersonPhoneDTO } from '~/types/dto/common/boundedPersonPhoneDTO';
import { ReportParkedBoundedPersonPhonesDataDTO } from '~/modules/reportsCommon/reportParkedBoundedPersonPhones/common/types/reportParkedBoundedPersonPhoneData';
import { ReportVehicleParkingDTO } from '~/types/dto/common/vehicleParkingDTO';

export interface IReportParkedBoundedPersonPhones {
  getData(parkingSessions: Array<ReportVehicleParkingDTO>,
    boundedPersonsPhones: Array<ReportBoundedPersonPhoneDTO>,): ReportParkedBoundedPersonPhonesDataDTO;
}

export type IReportParkedBoundedPersonPhonesService = IReportParkedBoundedPersonPhones;

export const IReportParkedBoundedPersonPhonesServiceKey: InjectionKey<IReportParkedBoundedPersonPhonesService> = Symbol('IReportParkedBoundedPersonPhonesService');
