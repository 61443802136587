import { injectable } from 'inversify';
import { isEqual } from 'lodash-es';
import { MergeService } from '~/services/merge/merge-service';
import { LegalEntitiesCompanyManagementCompaniesDTO } from '~/types/dto/legalEntities/companyManagementCompaniesDTO';

@injectable()
export class ManagementCompaniesMergeService extends MergeService<LegalEntitiesCompanyManagementCompaniesDTO> {
  protected canMerge(leftItem: LegalEntitiesCompanyManagementCompaniesDTO, rightItem: LegalEntitiesCompanyManagementCompaniesDTO): boolean {
    return isEqual(leftItem, rightItem);
  }

  protected mergeFields(leftItem: LegalEntitiesCompanyManagementCompaniesDTO, rightItem: LegalEntitiesCompanyManagementCompaniesDTO): LegalEntitiesCompanyManagementCompaniesDTO {
    return {
      Name: leftItem.Name || rightItem.Name,
      Inn: leftItem.Inn || rightItem.Inn,
      Ogrn: leftItem.Ogrn || rightItem.Ogrn,
      Date: leftItem.Date || rightItem.Date,
      FirstDate: leftItem.FirstDate || rightItem.FirstDate,
      IsInaccuracy: leftItem.IsInaccuracy || rightItem.IsInaccuracy,
      InaccuracyDate: leftItem.InaccuracyDate || rightItem.InaccuracyDate,
    };
  }
}
