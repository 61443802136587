import { injectable, inject } from 'inversify';
import { IPrimitiveTypesNormalizationServiceKey, IPrimitiveTypesNormalizationService } from './primitiveTypesNormalizationService.interfaces';
import { INormalizationService } from './reportNormalizationService.interfaces';

@injectable()
export class StringArrayNormalizationService implements INormalizationService<string> {
  constructor(
    @inject(IPrimitiveTypesNormalizationServiceKey as symbol) private primitiveTypesNormalizationService: IPrimitiveTypesNormalizationService
  ) {}

  normalize(data: any, root: string): string | undefined {
    const { normalizeString } = this.primitiveTypesNormalizationService;
    return normalizeString(data, `${root}`);
  }
}
