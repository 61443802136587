import { injectable } from 'inversify';
import { ReportVehicleParking } from '~/types/common/reportVehicleParking';
import { IPlaceholderDataService } from './reportPlaceholderService.interfaces';

@injectable()
export class ReportVehicleParkingPlaceholderService implements IPlaceholderDataService<ReportVehicleParking> {
  getData(): ReportVehicleParking {
    return {
      CarNumber: '',
      StartDate: '',
      EndDate: '',
      Duration: '',
      PlaceCode: '',
      Phone: '',
      SessionId: '',
      Tags: []
    };
  }
}
