import { injectable } from 'inversify';
import { ReportSocialNetworkId } from '~/types/common/reportSocialNetworkId';
import { IPlaceholderDataService } from './reportPlaceholderService.interfaces';

@injectable()
export class ReportSocialNetworkIdPlaceholderService implements IPlaceholderDataService<ReportSocialNetworkId> {
  getData(): ReportSocialNetworkId {
    return {
      Type: 0,
      SocialId: '',
    };
  }
}
