import { inject, injectable } from 'inversify';
import { IFinancialAnalysisShortLineChartService } from '~/modules/legalEntities/financialAnalytics/lineCharts/financialAnalysisShortLineChart/services/financialAnalysisShortLineChartService.interfaces';
import { LegalEntitiesFinancialAnalysisShortDTO } from '~/types/dto/legalEntities/financialAnalysisShort';
import { DataOptionLineChart } from '~/modules/legalEntities/financialAnalytics/lineCharts/common/types/DataOptionLineChart';
import { AnalysisShortChartData } from '~/modules/legalEntities/financialAnalytics/lineCharts/financialAnalysisShortLineChart/types/analysisShortChartData';
import {
  IDataOptionsPropertiesLineChartService,
  IDataOptionsPropertiesLineChartServiceKey
} from '~/modules/legalEntities/financialAnalytics/lineCharts/common/services/dataOptionsPropertiesLineChartService.interfaces';
import { LINE_CHART_COLOR_PALETTE } from '~/modules/legalEntities/financialAnalytics/lineCharts/common/configuration/constants/colorPalette';
import { LINE_CHART_GRADIENT_PALETTE } from '~/modules/legalEntities/financialAnalytics/lineCharts/common/configuration/constants/gradientPalette';
import {
  IFinancialAnalysisCommonTitlesService,
  IFinancialAnalysisCommonTitlesServiceKey
} from '~/modules/legalEntities/financialAnalytics/common/services/financialAnalysisCommonTitlesService.interfaces';
import {
  FINANCIAL_ANALYSIS_SHORT_TITLES
} from '~/modules/legalEntities/financialAnalytics/common/configuration/constants/financialAnalysisShortTitles';

@injectable()
export class FinancialAnalysisShortLineChartService implements IFinancialAnalysisShortLineChartService {
  constructor(
    @inject(IDataOptionsPropertiesLineChartServiceKey as symbol) private dataOptionsPropertiesLineChartService: IDataOptionsPropertiesLineChartService,
    @inject(IFinancialAnalysisCommonTitlesServiceKey as symbol) private commonTitlesService: IFinancialAnalysisCommonTitlesService
  ) {}

  private readonly FILL_GRADIENT: AnalysisShortChartData = {
    Revenues: LINE_CHART_GRADIENT_PALETTE.turquoise,
    ProfitFromSales: LINE_CHART_GRADIENT_PALETTE.orange,
    Ebit: LINE_CHART_GRADIENT_PALETTE.red,
    DebtShare: LINE_CHART_GRADIENT_PALETTE.green,
    DebtEbit: LINE_CHART_GRADIENT_PALETTE.turquoise,
    EbitPercent: LINE_CHART_GRADIENT_PALETTE.purple,
  };

  private readonly BORDER_COLOR: AnalysisShortChartData = {
    Revenues: LINE_CHART_COLOR_PALETTE.turquoise,
    ProfitFromSales: LINE_CHART_COLOR_PALETTE.orange,
    Ebit: LINE_CHART_COLOR_PALETTE.red,
    DebtShare: LINE_CHART_COLOR_PALETTE.green,
    DebtEbit: LINE_CHART_COLOR_PALETTE.turquoise,
    EbitPercent: LINE_CHART_COLOR_PALETTE.purple,
  };
  getDataOptions(initData: LegalEntitiesFinancialAnalysisShortDTO): Array<DataOptionLineChart> {
    const dataAnalysisShortChart = {
      Revenues: initData.Revenues,
      ProfitFromSales: initData.ProfitFromSales,
      Ebit: initData.Ebit,
      DebtShare: initData.DebtShare,
      DebtEbit: initData.DebtEbit,
      EbitPercent: initData.EbitPercent,
    };

    return Object.keys(dataAnalysisShortChart).map((key) => {
      const typedKey = key as keyof typeof dataAnalysisShortChart;

      const data = this.dataOptionsPropertiesLineChartService.getData(initData[typedKey]);

      return {
        title: this.commonTitlesService.getTitle(FINANCIAL_ANALYSIS_SHORT_TITLES[typedKey], data),
        labels: this.dataOptionsPropertiesLineChartService.getLabels(initData.TwoYearsAgo, initData.LastYear, initData.CurrentYear),
        colors: this.FILL_GRADIENT[typedKey],
        borderColors: this.BORDER_COLOR[typedKey],
        data,
      };
    });
  }
}
