import { injectable } from 'inversify';

import { ReportMention } from '~/types/common/reportMention';
import { IPlaceholderDataService } from './reportPlaceholderService.interfaces';

@injectable()
export class ReportMentionPlaceholderService implements IPlaceholderDataService<ReportMention> {
  getData(): ReportMention {
    return {
      Source: '',
      FullName: '',
      OriginalFullName: '',
    };
  }
}
