import { inject, injectable } from 'inversify';
import { Config } from '~/types/field-list/config';
import { LegalEntitiesCompanyHeadFlDTO } from '~/types/dto/legalEntities/companyHeadFlDTO';
import {
  ILegalEntitiesGeneralInformationFirstDateAppointmentServices,
  ILegalEntitiesGeneralInformationFirstDateAppointmentServicesKey
} from '~/modules/legalEntities/commonInfo/legalEntitiesGeneralInformation/services/legalEntitiesGeneralInformationFirstDateAppointmentServices.interface';
import { DateUtils } from '~/utils/date';
import { StringUtils } from '~/utils/stringUtils';
import { ILegalEntitiesGeneralInformationLeaderInfoConfigService } from '~/modules/legalEntities/commonInfo/legalEntitiesGeneralInformation/services/legalEntitiesGeneralInformationLeaderInfoConfigService.interfaces';

@injectable()
export class LegalEntitiesGeneralInformationLeaderInfoConfigService implements ILegalEntitiesGeneralInformationLeaderInfoConfigService {
  constructor(
    @inject(ILegalEntitiesGeneralInformationFirstDateAppointmentServicesKey as symbol) private firstDateAppointmentServices: ILegalEntitiesGeneralInformationFirstDateAppointmentServices,
  ) {}

  getConfig(initData: LegalEntitiesCompanyHeadFlDTO, initDataList: Array<LegalEntitiesCompanyHeadFlDTO>):Config {
    const firstDateAppointment = this.firstDateAppointmentServices.getFirstDateAppointment(initData, initDataList);
    const asignFirstDate = DateUtils.formatDate(firstDateAppointment);
    const position = initData.Position ? `${StringUtils.lowerCaseFirstLetter(initData.Position)} (по данным ЕГРЮЛ)` : '';
    const firstDate = initData.FirstDate ? `от ${asignFirstDate}` : '';
    const alert = initData.IsInaccuracy === true ? 'Недостоверные сведения в ЕГРЮЛ' : '';

    return {
      fieldsDirection: 'row',
      fieldsWidth: '100%',
      fieldsValueWidth: '100%',
      columnGap: '8px',
      rowGap: '4px',
      isBeginningDivider: true,
      fields: [
        {
          label: 'Роль:',
          key: 'data',
          value: position,
        },
        {
          label: 'Дата первичной регистрации:',
          key: 'data',
          value: firstDate,
        },
        {
          label: 'ИНН:',
          key: 'data',
          value: initData.Innfl,
        },
        {
          label: 'note/flash',
          key: 'alert',
          value: alert,
        }
      ]
    };
  }
}
