import { injectable } from 'inversify';
import { DataBlobReportOriginalInputFileInfo } from '~/types/blobs/data-blob-report-original-input-file-info';
import { IPlaceholderDataService } from '../common/reportPlaceholderService.interfaces';

@injectable()
export class DataBlobReportOriginalInputFileInfoPlaceholderService implements IPlaceholderDataService<DataBlobReportOriginalInputFileInfo> {
  getData(): DataBlobReportOriginalInputFileInfo {
    return {
      DataLength: 0,
      FileNameWithoutExtension: '',
      FileName: ''
    };
  }
}
