/* eslint-disable class-methods-use-this, max-len */

import { injectable } from 'inversify';
import { StringUtils } from '~/utils/stringUtils';
import { LegalEntitiesGovernmentControl } from '~/types/legal-entities/governmentControl';
import { LegalEntitiesGovernmentControlDTO } from '~/types/dto/legalEntities/governmentControlDTO';
import {
  ILegalEntitiesGovernmentControlAdapterService
} from '~/services/adapters/legalEntities/legalEntitiesAdapterService.interfaces';
import { DateUtils } from '~/utils/date';

@injectable()
export class LegalEntitiesGovernmentControlAdapterService implements ILegalEntitiesGovernmentControlAdapterService {
  getData(initData: LegalEntitiesGovernmentControl[]): LegalEntitiesGovernmentControlDTO[] {
    return (initData ?? []).map((item) => ({
      ControlType: item.ControlType,
      Violations: item.Violations,
      ViolationsCount: item.ViolationsCount,
      Date: DateUtils.getYear(item.Date ?? ''),
      OrganName: StringUtils.quoteReplacement(item.OrganName ?? ''),
    }));
  }
}
