import { injectable, inject } from 'inversify';
import { ICompanyFounderService } from './getIsFirstDateService.interfaces';
import { LegalEntitiesCompanyFounderDTO } from '~/types/dto/legalEntities/companyFounder';
import { CheckIsFirstDateService } from './checkIsFirstDateService/checkIsFirstDateService';
import { ICheckIsFirstDateServiceKey } from './checkIsFirstDateService/checkIsFirstDateService.interface';

@injectable()
export class CompanyFounderService implements ICompanyFounderService {
  constructor(
    @inject(ICheckIsFirstDateServiceKey as symbol) private checkIsFirstDateService: CheckIsFirstDateService,
  ) {}
  getIsFirstDate(array: Array<LegalEntitiesCompanyFounderDTO>): Array<(LegalEntitiesCompanyFounderDTO & { isFirstDate: boolean, })> {
    return this.checkIsFirstDateService.checkIsFirstDate(
      array,
      'Inn' as keyof LegalEntitiesCompanyFounderDTO,
      'Fullname' as keyof LegalEntitiesCompanyFounderDTO,
      'FirstDate' as keyof LegalEntitiesCompanyFounderDTO,
      'Date' as keyof LegalEntitiesCompanyFounderDTO
    );
  }
}
