import { injectable, inject } from 'inversify';

import { ReportSocialNetworkJob } from '~/types/common/reportSocialNetworkJob';
import { IPrimitiveTypesNormalizationService, IPrimitiveTypesNormalizationServiceKey } from './primitiveTypesNormalizationService.interfaces';
import { INormalizationService } from './reportNormalizationService.interfaces';

@injectable()
export class ReportSocialNetworkJobNormalizationService implements INormalizationService<ReportSocialNetworkJob> {
  constructor(
    @inject(IPrimitiveTypesNormalizationServiceKey as symbol) private primitiveTypesNormalizationService: IPrimitiveTypesNormalizationService,
  ) {}

  normalize(data: any, root: string): ReportSocialNetworkJob | undefined {
    const {
      normalizeObject,
      normalizeNumber,
      normalizeString,
      normalizeOptionalString,
      normalizeOptionalBoolean
    } = this.primitiveTypesNormalizationService;

    return normalizeObject(data, (data) => ({
      Type: normalizeNumber(data.Type, `${root}.Type`),
      SocialNetworkId: normalizeString(data.SocialNetworkId, `${root}.SocialNetworkId`),
      City: normalizeOptionalString(data.City, `${root}.City`),
      CompanyName: normalizeString(data.CompanyName, `${root}.CompanyName`),
      Position: normalizeOptionalString(data.Position, `${root}.Position`),
      StartDate: normalizeOptionalString(data.StartDate, `${root}.SocialNetworkId`),
      EndDate: normalizeOptionalString(data.EndDate, `${root}.EndDate`),
      IsWorkingNow: normalizeOptionalBoolean(data.IsWorkingNow, `${root}.IsWorkingNow`),
    }), root);
  }
}
