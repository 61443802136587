import { injectable } from 'inversify';

import { LegalEntitiesFinancialCommon } from '~/types/legal-entities/financialCommon';
import { IPlaceholderDataService } from '../common/reportPlaceholderService.interfaces';

@injectable()
export class LegalEntitiesFinancialCommonPlaceholderDataService implements IPlaceholderDataService<LegalEntitiesFinancialCommon> {
  getData(): LegalEntitiesFinancialCommon {
    return {
      Year: 0,
      SectorName: '',
      ValuePrevious: 0,
      ValueCurrent: 0,
      Dynamics: 0,
    };
  }
}
