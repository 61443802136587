import { injectable } from 'inversify';
import { IIndividualBusinessTripAdapterService } from '~/services/adapters/individual/individualAdapterService.interfaces';
import { IndividualBusinessTrip } from '~/types/individual/businessTrip';
import { IndividualBusinessTripDTO } from '~/types/dto/individual/businessTripDTO';
import { StringUtils } from '~/utils/stringUtils';

@injectable()
export class IndividualBusinessTripAdapterService implements IIndividualBusinessTripAdapterService {
  getData(initData: IndividualBusinessTrip[]): IndividualBusinessTripDTO[] {
    return (initData ?? []).map((item) => ({
      StartDate: item.StartDate ?? '',
      EndDate: item.EndDate ?? '',
      SendingCompany: StringUtils.quoteReplacement(item.SendingCompany),
      ReceivingCompany: StringUtils.quoteReplacement(item.ReceivingCompany),
      Purpose: StringUtils.quoteReplacement(item.Purpose),
      Place: StringUtils.quoteReplacement(item.Place),
    }));
  }
}
