import { injectable } from 'inversify';
import { IndividualTerroristsAndExtremistsRecord } from '~/types/individual/terroristsAndExtremistsRecord';
import { IPlaceholderDataService } from '../common/reportPlaceholderService.interfaces';

@injectable()
export class IndividualTerroristsAndExtremistsRecordPlaceholderService implements IPlaceholderDataService<IndividualTerroristsAndExtremistsRecord> {
  getData(): IndividualTerroristsAndExtremistsRecord {
    return {
      Description: '',
      LastName: '',
      FirstName: '',
      Patronymic: '',
      OtherInfo: '',
    };
  }
}
