import { inject, injectable } from 'inversify';

import { IPlaceholderDataService, IReportVettingInfoPlaceholderServiceKey } from '../common/reportPlaceholderService.interfaces';
import { LegalEntitiesDataBlobData } from '~/types/blobs/legal-entities-data-blob-data';
import { LegalEntitiesBriefInfo } from '~/types/legal-entities/briefInfo';
import { LegalEntitiesCommonInfo } from '~/types/legal-entities/commonInfo';
import { LegalEntitiesFinancialAnalysisExtended } from '~/types/legal-entities/financialAnalysisExtended';
import { LegalEntitiesFinancialAnalysisShort } from '~/types/legal-entities/financialAnalysisShort';
import { LegalEntitiesFinancialState } from '~/types/legal-entities/financialState';
import { LegalEntitiesMarkers } from '~/types/legal-entities/markers';
import { LegalEntitiesTotalScore } from '~/types/legal-entities/totalScore';
import {
  ILegalEntitiesCommonPlaceholderDataServiceKey,
  ILegalEntitiesBriefInfoPlaceholderDataServiceKey,
  ILegalEntitiesFinancialStatePlaceholderDataServiceKey,
  ILegalEntitiesMarkersPlaceholderDataServiceKey,
  ILegalEntitiesFinancialAnalysisShortPlaceholderDataServiceKey,
  ILegalEntitiesFinancialAnalysisExtendedPlaceholderDataServiceKey,
  ILegalEntitiesTotalScorePlaceholderDataServiceKey, ILegalEntitiesAnalyticsIndicatorsPlaceholderDataServiceKey,
} from './legalEntitiesPlaceholderDataService.interfaces';
import { ReportVettingInfo } from '~/types/common/vettingInfo';
import { LegalEntitiesAnalyticsIndicators } from '~/types/legal-entities/analyticsIndicators';

@injectable()
export class LegalEntitiesDataBlobDataPlaceholderService implements IPlaceholderDataService<LegalEntitiesDataBlobData> {
  constructor(
    @inject(ILegalEntitiesCommonPlaceholderDataServiceKey as symbol) private commonPlaceholderService: IPlaceholderDataService<LegalEntitiesCommonInfo>,
    @inject(ILegalEntitiesBriefInfoPlaceholderDataServiceKey as symbol) private briefInfoPlaceholderDataService: IPlaceholderDataService<LegalEntitiesBriefInfo>,
    @inject(ILegalEntitiesFinancialStatePlaceholderDataServiceKey as symbol) private financialStatePlaceholderDataService: IPlaceholderDataService<LegalEntitiesFinancialState>,
    @inject(ILegalEntitiesMarkersPlaceholderDataServiceKey as symbol) private markersPlaceholderDataService: IPlaceholderDataService<LegalEntitiesMarkers>,
    @inject(ILegalEntitiesFinancialAnalysisShortPlaceholderDataServiceKey as symbol) private financialAnalysisShortPlaceholderDataService: IPlaceholderDataService<LegalEntitiesFinancialAnalysisShort>,
    @inject(ILegalEntitiesFinancialAnalysisExtendedPlaceholderDataServiceKey as symbol) private financialAnalysisExtendedPlaceholderDataService: IPlaceholderDataService<LegalEntitiesFinancialAnalysisExtended>,
    @inject(ILegalEntitiesTotalScorePlaceholderDataServiceKey as symbol) private totalScorePlaceholderDataService: IPlaceholderDataService<LegalEntitiesTotalScore>,
    @inject(ILegalEntitiesAnalyticsIndicatorsPlaceholderDataServiceKey as symbol) private analyticsIndicatorsPlaceholderDataService: IPlaceholderDataService<LegalEntitiesAnalyticsIndicators>,

    @inject(IReportVettingInfoPlaceholderServiceKey as symbol) private vettingInfoPlaceholderService: IPlaceholderDataService<ReportVettingInfo>
  ) {}

  getData(): LegalEntitiesDataBlobData {
    return {
      Common: this.commonPlaceholderService.getData(),
      BriefInfo: this.briefInfoPlaceholderDataService.getData(),
      FinancialState: this.financialStatePlaceholderDataService.getData(),
      Markers: this.markersPlaceholderDataService.getData(),
      FinancialAnalysisShort: this.financialAnalysisShortPlaceholderDataService.getData(),
      FinancialAnalysisExtended: this.financialAnalysisExtendedPlaceholderDataService.getData(),
      TotalScore: this.totalScorePlaceholderDataService.getData(),
      VettingInfo: this.vettingInfoPlaceholderService.getData(),
      AnalyticsIndicators: this.analyticsIndicatorsPlaceholderDataService.getData(),
    };
  }
}
