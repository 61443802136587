import { injectable, inject } from 'inversify';
import { IIndividualWantedFsspAdapterService } from '~/services/adapters/individual/individualAdapterService.interfaces';
import {
  IReportMainEntityFileAdapterService,
  IReportMainEntityFileAdapterServiceKey
} from '~/services/adapters/common/reportAdapterService.interfeces';
import { IndividualWantedFssp } from '~/types/individual/wantedFssp';
import { IndividualWantedFsspDTO } from '~/types/dto/individual/individualWantedFsspDTO';
import { StringUtils } from '~/utils/stringUtils';

@injectable()
export class IndividualWantedFsspAdapterService implements IIndividualWantedFsspAdapterService {
  constructor(
    @inject(IReportMainEntityFileAdapterServiceKey as symbol) private reportMainEntityFileAdapterService: IReportMainEntityFileAdapterService
  ) {}
  getData(initData: IndividualWantedFssp[]): IndividualWantedFsspDTO[] {
    return (initData ?? []).map((item) => ({
      Photo: this.reportMainEntityFileAdapterService.getData(item.Photo),
      DepartmentName: StringUtils.quoteReplacement(item.DepartmentName),
      InitiatorPhone: StringUtils.quoteReplacement(item.InitiatorPhone),
      ChangeDate: item.ChangeDate ?? ''
    }));
  }
}
