import { injectable, inject } from 'inversify';
import { LegalEntitiesConditionAssessment } from '~/types/legal-entities/conditionAssessment';
import { IPrimitiveTypesNormalizationServiceKey, IPrimitiveTypesNormalizationService } from '../common/primitiveTypesNormalizationService.interfaces';
import { INormalizationService } from '../common/reportNormalizationService.interfaces';

@injectable()
export class LegalEntitiesConditionAssessmentNormalizationService implements INormalizationService<LegalEntitiesConditionAssessment> {
  constructor(
    @inject(IPrimitiveTypesNormalizationServiceKey as symbol) private primitiveTypesNormalizationService: IPrimitiveTypesNormalizationService
  ) {}

  normalize(data: any, root: string): LegalEntitiesConditionAssessment | undefined {
    const { normalizeObject, normalizeOptionalString } = this.primitiveTypesNormalizationService;

    return normalizeObject(data, (data) => ({
      Code: normalizeOptionalString(data.Code, `${root}.Code`),
      Description: normalizeOptionalString(data.Description, `${root}.Description`),
    }), root);
  }
}
