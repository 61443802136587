import { injectable } from 'inversify';
import { IndividualEnforcementProceeding } from '~/types/individual/enforcementProceeding';
import { IPlaceholderDataService } from '../common/reportPlaceholderService.interfaces';

@injectable()
export class IndividualEnforcementProceedingPlaceholderService implements IPlaceholderDataService<IndividualEnforcementProceeding> {
  getData(): IndividualEnforcementProceeding {
    return {
      Number: '',
      Type: '',
      StartDate: '',
      ExecutiveDocumentAgencyName: '',
      EndDate: '',
      EndReasonArticle: '',
      EndReasonPart: '',
      EndReasonParagraph: '',
      SubjectOfExecution: '',
      AmountOwed: 0,
      BailiffsOfficeName: '',
      BailiffsOfficeAddress: '',
      BailiffFullName: '',
      BailiffContactInfo: '',
      Region: '',
    };
  }
}
