import { injectable, inject } from 'inversify';
import { LegalEntitiesCompanyHeadFl } from '~/types/legal-entities/companyHeadFl';
import { IPrimitiveTypesNormalizationServiceKey, IPrimitiveTypesNormalizationService } from '../common/primitiveTypesNormalizationService.interfaces';
import { INormalizationService } from '../common/reportNormalizationService.interfaces';

@injectable()
export class LegalEntitiesCompanyHeadFlNormalizationService implements INormalizationService<LegalEntitiesCompanyHeadFl> {
  constructor(
    @inject(IPrimitiveTypesNormalizationServiceKey as symbol) private primitiveTypesNormalizationService: IPrimitiveTypesNormalizationService,
  ) {}

  normalize(data: any, root: string): LegalEntitiesCompanyHeadFl | undefined {
    const {
      normalizeString,
      normalizeOptionalString,
      normalizeOptionalBoolean,
      normalizeOptionalInteger,
      normalizeObject
    } = this.primitiveTypesNormalizationService;

    return normalizeObject(data, (data) => ({
      Fio: normalizeString(data.Fio, `${root}.Fio`),
      Innfl: normalizeOptionalString(data.Innfl, `${root}.Innfl`),
      Position: normalizeOptionalString(data.Position, `${root}.Position`),
      Date: normalizeOptionalString(data.Date, `${root}.Date`),
      FirstDate: normalizeOptionalString(data.FirstDate, `${root}.FirstDate`),
      IsInaccuracy: normalizeOptionalBoolean(data.IsInaccuracy, `${root}.IsInaccuracy`),
      InaccuracyDate: normalizeOptionalString(data.InaccuracyDate, `${root}.InaccuracyDate`),
      IsCompanyCoOwner: normalizeOptionalBoolean(data.IsCompanyCoOwner, `${root}.IsCompanyCoOwner`),
      OwnershipPercent: normalizeOptionalInteger(data.OwnershipPercent, `${root}.OwnershipPercent`)
    }), root);
  }
}
