import { injectable, inject } from 'inversify';
import { ReportMovementCovid } from '~/types/common/reportMovementCovid';
import { IPrimitiveTypesNormalizationServiceKey, IPrimitiveTypesNormalizationService } from './primitiveTypesNormalizationService.interfaces';
import { INormalizationService } from './reportNormalizationService.interfaces';

@injectable()
export class ReportMovementCovidNormalizationService implements INormalizationService<ReportMovementCovid> {
  constructor(
    @inject(IPrimitiveTypesNormalizationServiceKey as symbol) private primitiveTypesNormalizationService: IPrimitiveTypesNormalizationService,
  ) {}

  normalize(data: any, root: string): ReportMovementCovid | undefined {
    const { normalizeObject, normalizeString, normalizeOptionalString } = this.primitiveTypesNormalizationService;

    return normalizeObject(data, (data) => ({
      Email: normalizeOptionalString(data.Email, `${root}.Email`),
      PhoneNumber: normalizeOptionalString(data.PhoneNumber, `${root}.PhoneNumber`),
      Inn: normalizeOptionalString(data.Inn, `${root}.Inn`),
      Passport: normalizeOptionalString(data.Passport, `${root}.Passport`),
      FullName: normalizeOptionalString(data.FullName, `${root}.FullName`),
      MovementFrom: normalizeString(data.MovementFrom, `${root}.MovementFrom`),
      MovementTo: normalizeString(data.MovementTo, `${root}.MovementTo`),
      MovementVehicleNum: normalizeOptionalString(data.MovementVehicleNum, `${root}.MovementVehicleNum`),
      MovementSocialCardNum: normalizeOptionalString(data.MovementSocialCardNum, `${root}.MovementSocialCardNum`),
      MovementTroykaCardNum: normalizeOptionalString(data.MovementTroykaCardNum, `${root}.MovementTroykaCardNum`),
      MovementStrelkaCardNum: normalizeOptionalString(data.MovementStrelkaCardNum, `${root}.MovementStrelkaCardNum`),
      MovementDirection: normalizeOptionalString(data.MovementDirection, `${root}.MovementDirection`),
      MovementPurpose: normalizeOptionalString(data.MovementPurpose, `${root}.MovementPurpose`),
      Organisation: normalizeOptionalString(data.Organisation, `${root}.Organisation`),
      OrganisationInn: normalizeOptionalString(data.OrganisationInn, `${root}.OrganisationInn`),
    }), root);
  }
}
