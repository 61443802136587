import { assignWith, isUndefined, partialRight } from 'lodash-es';
import { Participation } from '~/modules/reportsCommon/reportLegalEntitiesParticipation/types/participation';
import { ReportLegalEntitiesParticipationDTO } from '~/types/dto/common/reportLegalEntitiesParticipationDTO';
import { RiskIndicator } from '~/modules/reportsCommon/reportLegalEntitiesParticipation/types/riskIndicator';

export module LegalEntitiesParticipationUtils {
  const PARTICIPATION_CURRENT_FOUNDER = 'Учредитель';
  const PARTICIPATION_CURRENT_LEADER = 'Руководитель';
  const PARTICIPATION_FORMER_FOUNDER = 'Учредитель (бывший)';
  const RISK_HIGH = 'Высокий';
  const RISK_MEDIUM = 'Средний';
  const RISK_LOW = 'Низкий';

  export function convertStringToRiskIndicator(str: string) {
    switch (str.toLowerCase()) {
      case RISK_HIGH.toLowerCase(): return RiskIndicator.high;
      case RISK_MEDIUM.toLowerCase(): return RiskIndicator.medium;
      case RISK_LOW.toLowerCase(): return RiskIndicator.low;
      default: return RiskIndicator.unknown;
    }
  }

  export function convertRiskIndicatorToString(riskIndicator: RiskIndicator) {
    switch (riskIndicator) {
      case RiskIndicator.high: return RISK_HIGH;
      case RiskIndicator.medium: return RISK_MEDIUM;
      case RiskIndicator.low: return RISK_LOW;
      default: return '';
    }
  }

  export function convertStringToParticipation(str: string) {
    switch (str.toLowerCase()) {
      case PARTICIPATION_CURRENT_FOUNDER.toLowerCase(): return Participation.currentFounder;
      case PARTICIPATION_CURRENT_LEADER.toLowerCase(): return Participation.currentLeader;
      case PARTICIPATION_FORMER_FOUNDER.toLowerCase(): return Participation.formerFounder;
      default: return Participation.unknown;
    }
  }

  export function convertParticipationToString(participation: Participation) {
    switch (participation) {
      case Participation.currentFounder: return PARTICIPATION_CURRENT_FOUNDER;
      case Participation.currentLeader: return PARTICIPATION_CURRENT_LEADER;
      case Participation.formerFounder: return PARTICIPATION_FORMER_FOUNDER;
      default: return '';
    }
  }

  export function getRiskIndicatorOrder(riskIndicator: ReportLegalEntitiesParticipationDTO) {
    const getRiskIndicatorType = convertStringToRiskIndicator(riskIndicator.RiskIndicator);
    switch (getRiskIndicatorType) {
      case RiskIndicator.high:
        return 3;
      case RiskIndicator.medium:
        return 2;
      case RiskIndicator.low:
        return 1;
      default:
        return 4;
    }
  }

  export function getParticipationOrder(participation: ReportLegalEntitiesParticipationDTO) {
    const participationTypeLabel = convertStringToParticipation(
      participation.IsHistoric
        ? `${participation.ParticipationType} (бывший)`
        : participation.ParticipationType
    );
    switch (participationTypeLabel) {
      case Participation.currentLeader: return 1;
      case Participation.currentFounder: return 2;
      case Participation.formerFounder: return 3;
      default: return 4;
    }
  }

  export function customizer<T>(objValue: T, srcValue: T): T {
    return isUndefined(objValue) ? srcValue : objValue;
  }
}
