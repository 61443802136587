import { injectable } from 'inversify';
import { DateUtils } from '~/utils/date';
import { FamilyUtils } from '~/modules/individual/family/individualFamilyList/utils/familyUtils';

import { IIndividualFamilyConfigService } from '~/modules/individual/family/individualFamilyList/services/individualFamilyConfigService.interfaces';
import { FAMILY_FIELD_LIST_OPTIONS } from '~/modules/individual/family/individualFamilyList/configuration/constants';
import { Config } from '~/types/field-list/config';
import { IndividualFamilyListDTO } from '~/types/dto/individual/familyListDTO';
import { Direction } from '~/types/field-list/direction';

@injectable()
export class IndividualFamilyConfigService implements IIndividualFamilyConfigService {
  getConfig(familyItem: IndividualFamilyListDTO): Config {
    const fullNameLabel = `${familyItem.LastName ?? ''} ${familyItem.FirstName ?? ''} ${familyItem.Patronymic ?? ''}`;

    return {
      ...FAMILY_FIELD_LIST_OPTIONS,
      fields: [
        {
          key: 'FullName',
          label: 'Родственник',
          value: fullNameLabel,
          isInner: true,
          inner: { icon: 'system/copy', size: '24' },
          direction: 'column' as Direction,
          rowGap: '0px',
        },
        {
          key: 'Birthday',
          label: 'Дата рождения',
          value: DateUtils.formatDate(familyItem.Birthday) 
        },
        {
          key: 'Inn',
          label: 'ИНН',
          value: familyItem.Inn 
        },
        {
          key: 'FamilyRelationType',
          label: 'Тип родства',
          value: FamilyUtils.formatFamilyRelationType(familyItem.FamilyRelationType) 
        },
      ].filter((field) => Boolean(field.value))
    };
  }
}
