import { injectable } from 'inversify';
import { MergeService } from '~/services/merge/merge-service';
import { LegalEntitiesCompanyHeadFlDTO } from '~/types/dto/legalEntities/companyHeadFlDTO';
import { DateUtils } from '~/utils/date';

@injectable()
export class LegalEntitiesAuthorityToSignMergeService extends MergeService<LegalEntitiesCompanyHeadFlDTO> {
  protected canMerge(leftItem: LegalEntitiesCompanyHeadFlDTO, rightItem: LegalEntitiesCompanyHeadFlDTO): boolean {
    const isInnEqual = leftItem.Innfl === rightItem.Innfl;
    const isPositionEqual = leftItem.Position === rightItem.Position;
    const isDateEqual = leftItem.Date === rightItem.Date;
    const isFirstDateEqual = leftItem.FirstDate === rightItem.FirstDate;
    const isInaccuracyEqual = leftItem.IsInaccuracy === rightItem.IsInaccuracy;
    const isInaccuracyDateEqual = leftItem.InaccuracyDate === rightItem.InaccuracyDate;
    const isCompanyCoOwnerEqual = leftItem.IsCompanyCoOwner === rightItem.IsCompanyCoOwner;
    const isOwnershipPercentEqual = leftItem.OwnershipPercent === rightItem.OwnershipPercent;

    return isInnEqual
        && isPositionEqual
        && isDateEqual
        && isFirstDateEqual
        && isInaccuracyEqual
        && isInaccuracyDateEqual
        && isCompanyCoOwnerEqual
        && isOwnershipPercentEqual;
  }

  protected mergeFields(leftItem: LegalEntitiesCompanyHeadFlDTO, rightItem: LegalEntitiesCompanyHeadFlDTO): LegalEntitiesCompanyHeadFlDTO {
    let firstDate: string;
    let lastDate: string;

    if (leftItem.FirstDate && rightItem.FirstDate) {
      const firstDateDiff = DateUtils
        .calcDateDiff(
          DateUtils.parseDate(leftItem.FirstDate),
          DateUtils.parseDate(rightItem.FirstDate),
          ['days']
        ).days;
      firstDate = firstDateDiff && firstDateDiff > 0 ? leftItem.FirstDate : rightItem.FirstDate;
    } else firstDate = leftItem.FirstDate || rightItem.FirstDate;

    if (leftItem.Date && rightItem.Date) {
      const lastDateDiff = DateUtils
        .calcDateDiff(
          DateUtils.parseDate(leftItem.Date),
          DateUtils.parseDate(rightItem.Date),
          ['days']
        ).days;
      lastDate = lastDateDiff && lastDateDiff > 0 ? rightItem.Date : leftItem.Date;
    } else lastDate = leftItem.Date || rightItem.Date;

    const fios = leftItem.Fio !== rightItem.Fio ? `${leftItem.Fio}, ${rightItem.Fio}` : leftItem.Fio || rightItem.Fio;

    return {
      Fio: fios,
      Innfl: leftItem.Innfl || rightItem.Innfl,
      Position: leftItem.Position || rightItem.Position,
      Date: lastDate,
      FirstDate: firstDate,
      IsInaccuracy: leftItem.IsInaccuracy || rightItem.IsInaccuracy,
      InaccuracyDate: leftItem.InaccuracyDate || rightItem.InaccuracyDate,
      IsCompanyCoOwner: leftItem.IsCompanyCoOwner || rightItem.IsCompanyCoOwner,
      OwnershipPercent: leftItem.OwnershipPercent || rightItem.OwnershipPercent
    };
  }
}
