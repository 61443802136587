import { inject, injectable } from 'inversify';
import { get, isString } from 'lodash-es';
import { ICashFlowAvailableForDebtServiceTableItemConfig } from '~/modules/legalEntities/financialAnalytics/legalEntitiesCashFlowPerformance/services/legalEntitiesCashFlowPerformanceTableItemConfig.interfaces';
import { TableItem } from '~/modules/table/types/tableItem';
import { LegalEntitiesReportFinancialIndicatorWithDynamicDTO } from '~/types/dto/legalEntities/reportFinancialIndicatorWithDynamicDTO';
import { NumberUtils } from '~/utils/number';
import { INDICATORS_OF_CASH_FLOW } from '~/modules/legalEntities/financialAnalytics/common/configuration/constants/indicatorsOfCashFlow';
import {
  IInterestPeriodTableIDataService,
  IInterestPeriodTableIDataServiceKey
} from '~/modules/legalEntities/financialAnalytics/common/services/interestPeriodTableIDataService.interfaces';
import {
  IFinancialAnalysisCommonTitlesService,
  IFinancialAnalysisCommonTitlesServiceKey
} from '~/modules/legalEntities/financialAnalytics/common/services/financialAnalysisCommonTitlesService.interfaces';

@injectable()
export class CashFlowAvailableForDebtServiceTableItemConfig implements ICashFlowAvailableForDebtServiceTableItemConfig {
  constructor(
    @inject(IInterestPeriodTableIDataServiceKey as symbol) private interestPeriodTableIDataService: IInterestPeriodTableIDataService,
    @inject(IFinancialAnalysisCommonTitlesServiceKey as symbol) private financialAnalysisCommonTitlesService: IFinancialAnalysisCommonTitlesService
  ) {}
  getItem(initData:LegalEntitiesReportFinancialIndicatorWithDynamicDTO):TableItem {
    return ({
      ...this.interestPeriodTableIDataService.getDataItem(initData, this.getColor),
      indicator: this.financialAnalysisCommonTitlesService.getTitle(INDICATORS_OF_CASH_FLOW.CashFlowAvailableForDebtService, [initData.LastYear, initData.CurrentYear, initData.TwoYearsAgo])
    });
  }

  private getColor(value: number): string {
    if (value >= 25) return 'green';
    if (value <= -25) return 'red';
    return 'black';
  }
}
