/* eslint-disable class-methods-use-this, max-len */

import { injectable } from 'inversify';
import { LegalEntitiesShareholder } from '~/types/legal-entities/shareholder';
import { StringUtils } from '~/utils/stringUtils';
import { LegalEntitiesShareholderDTO } from '~/types/dto/legalEntities/shareholderDTO';
import {
  ILegalEntitiesShareholderAdapterService
} from '~/services/adapters/legalEntities/legalEntitiesAdapterService.interfaces';

@injectable()
export class LegalEntitiesShareholderAdapterService implements ILegalEntitiesShareholderAdapterService {
  getData(initData: LegalEntitiesShareholder[]): LegalEntitiesShareholderDTO[] {
    return (initData ?? []).map((item) => ({
      Fullname: StringUtils.quoteReplacement(item.Fullname ?? ''),
      ShareholderType: StringUtils.quoteReplacement(item.ShareholderType ?? ''),
      CapitalSharesPercent: item.CapitalSharesPercent ?? 0,
      EndDate: item.EndDate ?? '',
      VotingSharesPercent: item.VotingSharesPercent ?? 0,
    }));
  }
}
