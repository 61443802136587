import { IContainerBinder } from '~/configuration/inversify/containerAccessor.interface';
import {
  IIndividualEnforcementProceedingDepartmentConfigServiceKey,
  IIndividualEnforcementProceedingMainConfigServiceKey
} from '~/modules/individual/negativeInformation/individualEnforcementProceeding/services/individualEnforcementProceedingConfigService.interfaces';
import { IndividualEnforcementProceedingDepartmentConfigService } from '~/modules/individual/negativeInformation/individualEnforcementProceeding/services/individualEnforcementProceedingDepartmentConfigService';
import { IndividualEnforcementProceedingMainConfigService } from '~/modules/individual/negativeInformation/individualEnforcementProceeding/services/individualEnforcementProceedingMainConfigService';

export function bindIndividualEnforcementProceedingConfigServices(bind: IContainerBinder) {
  bind(IIndividualEnforcementProceedingMainConfigServiceKey).to(IndividualEnforcementProceedingMainConfigService);
  bind(IIndividualEnforcementProceedingDepartmentConfigServiceKey).to(IndividualEnforcementProceedingDepartmentConfigService);
}
