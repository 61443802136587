import { get, groupBy } from 'lodash-es';
import { injectable } from 'inversify';
import {
  ILegalEntitiesCompanyTaxRegimesMergeService
} from '~/modules/legalEntities/changeHistory/legalEntitiesCompanyTaxRegimes/services/legalEntitiesTaxRegimesMergeService.interface';
import { TableItems } from '~/types/tableItems';
import { LegalEntitiesUtils } from '~/modules/legalEntities/common/utils/legalEntitiesUtils';
import { LegalEntitiesCompanyTaxRegimesDTO } from '~/types/dto/legalEntities/companyTaxRegimesDTO';

@injectable()
export class LegalEntitiesCompanyTaxRegimesMergeService implements ILegalEntitiesCompanyTaxRegimesMergeService {
  getMergeTaxRegimes(initData: LegalEntitiesCompanyTaxRegimesDTO[]): TableItems {
    const groupedTaxModeData = groupBy(initData, 'Year');

    const groupedTaxModeDataValues = Object.values(groupedTaxModeData);

    return groupedTaxModeDataValues.map((group) => {
      const mergedTaxRegimeName = group
        .map(item => LegalEntitiesUtils
          .replaceTaxText(item.TaxRegimeName))
        .join(', ');

      return {
        Year: get(group, '0.Year', 0),
        TaxRegimeName: mergedTaxRegimeName
      };
    });
  }
}
