import { InjectionKey } from 'vue';
import { PhoneNumbersDataBlobData } from '~/types/blobs/phone-numbers-data-blob-data';
import { PhoneNumberPhoneCommon } from '~/types/phone-number/phoneCommon';
import { PhoneNumberRelatedPhonePerson } from '~/types/phone-number/relatedPhonePerson';
import { PhoneNumberRelatedPhonePersonCommon } from '~/types/phone-number/relatedPhonePersonCommon';
import { IPlaceholderDataService } from '~/services/placeholder/common/reportPlaceholderService.interfaces';

export const IPhoneNumbersDataBlobDataPlaceholderServiceKey: InjectionKey<IPlaceholderDataService<PhoneNumbersDataBlobData>> = Symbol('IPlaceholderDataService<PhoneNumbersDataBlobData>');
export const IPhoneNumberPhoneCommonPlaceholderServiceKey: InjectionKey<IPlaceholderDataService<PhoneNumberPhoneCommon>> = Symbol('IPlaceholderDataService<PhoneNumberPhoneCommon>');
export const IPhoneNumberRelatedPhonePersonPlaceholderServiceKey: InjectionKey<IPlaceholderDataService<PhoneNumberRelatedPhonePerson>> = Symbol('IPlaceholderDataService<PhoneNumberRelatedPhonePerson>');
export const IPhoneNumberRelatedPhonePersonCommonPlaceholderServiceKey: InjectionKey<IPlaceholderDataService<PhoneNumberRelatedPhonePersonCommon>> = Symbol('IPlaceholderDataService<PhoneNumberRelatedPhonePersonCommon>');
