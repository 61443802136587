import { injectable, inject } from 'inversify';
import { LegalEntitiesSuccessor } from '~/types/legal-entities/successor';
import { ISuccessorService } from './getIsFirstDateService.interfaces';
import { CheckIsFirstDateService } from './checkIsFirstDateService/checkIsFirstDateService';
import { ICheckIsFirstDateServiceKey } from './checkIsFirstDateService/checkIsFirstDateService.interface';

@injectable()
export class SuccessorService implements ISuccessorService {
  constructor(
    @inject(ICheckIsFirstDateServiceKey as symbol) private checkIsFirstDateService: CheckIsFirstDateService,
  ) {}
  getIsFirstDate(array: Array<LegalEntitiesSuccessor>): Array<(LegalEntitiesSuccessor & { isFirstDate: boolean, })> {
    return this.checkIsFirstDateService.checkIsFirstDate(
      array,
      'Inn' as keyof LegalEntitiesSuccessor,
      'OrganisationName' as keyof LegalEntitiesSuccessor,
      'Date' as keyof LegalEntitiesSuccessor
    );
  }
}
