import { injectable } from 'inversify';
import { ReportConnectionRelatedEntityDisplayDTO } from '~/types/dto/common/reportConnectionRelatedEntityDisplayDTO';
import {
  IReportConnectionRelatedEntityDisplayAdapterService
} from '~/services/adapters/common/reportAdapterService.interfeces';
import { ReportConnectionRelatedEntityDisplay } from '~/types/common/reportConnectionRelatedEntityDisplay';

@injectable()
export class ReportConnectionRelatedEntityDisplayAdapterService implements IReportConnectionRelatedEntityDisplayAdapterService {
  getData(Displays: Array<ReportConnectionRelatedEntityDisplay>): Array<ReportConnectionRelatedEntityDisplayDTO> {
    return (Displays ?? []).map((display) => ({
      Company: display.Company ?? '',
      Position: display.Position ?? '',
      Department: display.Department ?? ''
    }));
  }
}
