import { injectable, inject } from 'inversify';
import { LegalEntitiesCompanyPhone } from '~/types/legal-entities/companyPhone';
import { LegalEntitiesHashtag } from '~/types/legal-entities/hashtag';
import { IPrimitiveTypesNormalizationServiceKey, IPrimitiveTypesNormalizationService } from '../common/primitiveTypesNormalizationService.interfaces';
import { INormalizationService, IReportUrlNormalizationServiceKey } from '../common/reportNormalizationService.interfaces';

@injectable()
export class LegalEntitiesCompanyPhoneNormalizationService implements INormalizationService<LegalEntitiesCompanyPhone> {
  constructor(
    @inject(IReportUrlNormalizationServiceKey as symbol) private reportUrlNormalizationService: INormalizationService<LegalEntitiesHashtag>,

    @inject(IPrimitiveTypesNormalizationServiceKey as symbol) private primitiveTypesNormalizationService: IPrimitiveTypesNormalizationService,
  ) {}

  normalize(data: any, root: string): LegalEntitiesCompanyPhone | undefined {
    const {
      normalizeNumber,
      normalizeString,
      normalizeArray,
      normalizeObject
    } = this.primitiveTypesNormalizationService;

    return normalizeObject(data, (data) => ({
      Phone: normalizeString(data.Phone, `${root}.Phone`),
      Name: normalizeString(data.Name, `${root}.Name`),
      Hashtags: normalizeArray(data.Hashtags, (item, field) => this.reportUrlNormalizationService.normalize(item, field), `${root}.Hashtags`),
      CompaniesWithThisPhone: normalizeNumber(data.CompaniesWithThisPhone, `${root}.CompaniesWithThisPhone`),
    }), root);
  }
}
