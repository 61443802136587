import { injectable, inject } from 'inversify';
import { LegalEntitiesEnforcementProceedingsInfoBySubject } from '~/types/legal-entities/enforcementProceedingsInfoBySubject';
import { IPrimitiveTypesNormalizationServiceKey, IPrimitiveTypesNormalizationService } from '../common/primitiveTypesNormalizationService.interfaces';
import { INormalizationService } from '../common/reportNormalizationService.interfaces';

@injectable()
export class LegalEntitiesEnforcementProceedingsInfoBySubjectNormalizationService implements INormalizationService<LegalEntitiesEnforcementProceedingsInfoBySubject> {
  constructor(
    @inject(IPrimitiveTypesNormalizationServiceKey as symbol) private primitiveTypesNormalizationService: IPrimitiveTypesNormalizationService,
  ) {}

  normalize(data: any, root: string): LegalEntitiesEnforcementProceedingsInfoBySubject | undefined {
    const { normalizeNumber, normalizeString, normalizeObject } = this.primitiveTypesNormalizationService;

    return normalizeObject(data, (data) => ({
      SectorName: normalizeString(data.SectorName, `${root}.SectorName`),
      CasesCount: normalizeNumber(data.CasesCount, `${root}.CasesCount`),
      Sum: normalizeNumber(data.Sum, `${root}.Sum`),
    }), root);
  }
}
