import { IContainerBinder } from './containerAccessor.interface';
import { PhoneNumberPhoneCommonNormalizationService } from '~/services/normalization/phoneNumber/phoneNumberPhoneCommonNormalizationService';
import { PhoneNumberRelatedPhonePersonCommonNormalizationService } from '~/services/normalization/phoneNumber/phoneNumberRelatedPhonePersonCommonNormalizationService';
import { PhoneNumberRelatedPhonePersonNormalizationService } from '~/services/normalization/phoneNumber/phoneNumberRelatedPhonePersonNormalizationService';
import { PhoneNumbersDataBlobDataNormalizationService } from '~/services/normalization/phoneNumber/phoneNumbersDataBlobDataNormalizationService';
import {
  IPhoneNumberPhoneCommonNormalizationServiceKey,
  IPhoneNumberRelatedPhonePersonCommonNormalizationServiceKey,
  IPhoneNumberRelatedPhonePersonNormalizationServiceKey,
  IPhoneNumbersDataBlobDataNormalizationServiceKey
} from '~/services/normalization/phoneNumber/phoneNumberNormalizationService.interface';

export function bindPhoneNumberNormalizationServices(bind: IContainerBinder) {
  bind(IPhoneNumberPhoneCommonNormalizationServiceKey).to(PhoneNumberPhoneCommonNormalizationService);
  bind(IPhoneNumbersDataBlobDataNormalizationServiceKey).to(PhoneNumbersDataBlobDataNormalizationService);
  bind(IPhoneNumberRelatedPhonePersonCommonNormalizationServiceKey).to(PhoneNumberRelatedPhonePersonCommonNormalizationService);
  bind(IPhoneNumberRelatedPhonePersonNormalizationServiceKey).to(PhoneNumberRelatedPhonePersonNormalizationService);
}
