import { injectable, inject } from 'inversify';

import { ReportSocialNetworkHighEducation } from '~/types/common/reportSocialNetworkHighEducation';
import { IPrimitiveTypesNormalizationService, IPrimitiveTypesNormalizationServiceKey } from './primitiveTypesNormalizationService.interfaces';
import { INormalizationService } from './reportNormalizationService.interfaces';

@injectable()
export class ReportSocialNetworkHighEducationNormalizationService implements INormalizationService<ReportSocialNetworkHighEducation> {
  constructor(
    @inject(IPrimitiveTypesNormalizationServiceKey as symbol) private primitiveTypesNormalizationService: IPrimitiveTypesNormalizationService,
  ) {}

  normalize(data: any, root: string): ReportSocialNetworkHighEducation | undefined {
    const {
      normalizeObject,
      normalizeNumber,
      normalizeString,
      normalizeOptionalString
    } = this.primitiveTypesNormalizationService;

    return normalizeObject(data, (data) => ({
      Type: normalizeNumber(data.Type, `${root}.Type`),
      SocialNetworkId: normalizeString(data.SocialNetworkId, `${root}.SocialNetworkId`),
      LastName: normalizeString(data.LastName, `${root}.LastName`),
      FirstName: normalizeString(data.FirstName, `${root}.FirstName`),
      Birthday: normalizeOptionalString(data.Birthday, `${root}.Birthday`),
      EducationName: normalizeString(data.EducationName, `${root}.EducationName`),
      FacultyName: normalizeOptionalString(data.FacultyName, `${root}.FacultyName`),
      StartDate: normalizeOptionalString(data.StartDate, `${root}.StartDate`),
      DiplomaDate: normalizeOptionalString(data.DiplomaDate, `${root}.DiplomaDate`),
    }), root);
  }
}
