import { injectable } from 'inversify';
import { TableHeader } from '~/modules/table/types/tableHeader';
import { TableItem } from '~/modules/table/types/tableItem';
import { LegalEntitiesEnforcementProceedingsInfoBySubjectDTO } from '~/types/dto/legalEntities/enforcementProceedingsInfoBySubject';
import { NumberUtils } from '~/utils/number';
import { ILegalEntitiesExecutiveProductionTableConfigService } from '~/modules/legalEntities/companyActivities/legalEntitiesExecutiveProduction/services/legalEntitiesExecutiveProductionTableConfigService.interface';

@injectable()
export class LegalEntitiesExecutiveProductionTableConfigService implements ILegalEntitiesExecutiveProductionTableConfigService {
  getHeaders():Array<TableHeader> {
    return [
      {
        label: 'Предмет',
        key: 'name',
        width: '52%'
      },
      {
        label: 'Общее количество, шт.',
        key: 'caseload',
        width: '27%'
      },
      {
        label: 'Сумма задолженности, \u{20BD}',
        key: 'sum',
        width: '21%'
      },
    ];
  }

  getItems(initData: Array<LegalEntitiesEnforcementProceedingsInfoBySubjectDTO>): Array<TableItem> {
    const knownItems = initData.filter(item => item.SectorName.length !== 0);
    const unknownItems = initData.filter(item => item.SectorName.length === 0);
    const totalCasesCount = unknownItems.reduce((sum, item) => sum + item.CasesCount, 0);
    const totalSum = unknownItems.reduce((sum, item) => sum + item.Sum, 0);

    const executiveProductionItems = totalCasesCount !== 0 || totalSum !== 0
      ? [...knownItems,
        {
          SectorName: 'Иное',
          CasesCount: totalCasesCount,
          Sum: totalSum
        }
      ]
      : knownItems;

    const executiveProduction = executiveProductionItems.map((item) => {
      const sumCount = NumberUtils.formatNumberDigits(item.Sum);
      const sum = sumCount === '0' ? 'Не определена' : sumCount;

      return {
        name: item.SectorName || 'Иное',
        caseload: item.CasesCount || '-',
        sum,
      };
    });

    return [...executiveProduction];
  }
}
