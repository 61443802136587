import { injectable, inject } from 'inversify';
import {
  IReportMainEntityFileAdapterService,
  IReportMainEntityFileAdapterServiceKey,
  IReportMessengerInfoAdapterService
} from '~/services/adapters/common/reportAdapterService.interfeces';
import { StringUtils } from '~/utils/stringUtils';
import {
  IArrayAdapterService,
  IReportArrayAdapterServiceKey
} from '~/services/adapters/common/reportArrayAdapterService.interfaces';
import { MessengerUtils } from '~/utils/messengerUtils';
import { ReportMessengerInfo } from '~/types/common/reportMessengerInfo';
import { ReportMessengerInfoDTO } from '~/types/dto/common/reportMessengerInfoDTO';
import { DateUtils } from '~/utils/date';

@injectable()
export class ReportMessengerInfoAdapterService implements IReportMessengerInfoAdapterService {
  constructor(
    @inject(IReportMainEntityFileAdapterServiceKey as symbol) private reportMainEntityFileAdapterService: IReportMainEntityFileAdapterService,
    @inject(IReportArrayAdapterServiceKey as symbol) private reportArrayAdapterService: IArrayAdapterService,
  ) {}
  getData(initData: ReportMessengerInfo[]): ReportMessengerInfoDTO[] {
    const { mapData } = this.reportArrayAdapterService;
    return (initData ?? '').map((item) => ({
      Type: item.Type,
      TypeIcon: MessengerUtils.getMessengerTypeIcon(item.Type),
      MessengerId: StringUtils.quoteReplacement(item.MessengerId ?? ''),
      Status: StringUtils.quoteReplacement(item.Status ?? ''),
      OnlineDate: DateUtils.formatDate(item.OnlineDate ?? ''),
      Photos: mapData(item.Photos, item => this.reportMainEntityFileAdapterService.getData(item))
    }));
  }
}
