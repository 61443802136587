import { injectable } from 'inversify';
import {
  IIndividualEnforcementProceedingsAdapterService
} from '~/services/adapters/individual/individualAdapterService.interfaces';
import { IndividualEnforcementProceeding } from '~/types/individual/enforcementProceeding';
import { IndividualEnforcementProceedingDTO } from '~/types/dto/individual/enforcementProceedingDTO';
import { DateUtils } from '~/utils/date';
import { StringUtils } from '~/utils/stringUtils';

@injectable()
export class IndividualEnforcementProceedingsAdapterService implements IIndividualEnforcementProceedingsAdapterService {
  getData(initData: Array<IndividualEnforcementProceeding>): Array<IndividualEnforcementProceedingDTO> {
    return (initData ?? []).map((item) => ({
      Number: item.Number,
      Type: item.Type ?? '',
      StartDate: DateUtils.formatDate(item.StartDate),
      ExecutiveDocumentAgencyName: item?.ExecutiveDocumentAgencyName ?? '',
      EndDate: DateUtils.formatDate(item.EndDate),
      SubjectOfExecution: StringUtils.quoteReplacement(item.SubjectOfExecution ?? ''),
      AmountOwed: item.AmountOwed,
      BailiffFullName: StringUtils.quoteReplacement(item.BailiffFullName ?? ''),
      BailiffContactInfo: StringUtils.quoteReplacement(item.BailiffContactInfo ?? ''),
      Region: StringUtils.quoteReplacement(item.Region ?? ''),
      EndReasonArticle: StringUtils.quoteReplacement(item.EndReasonArticle ?? ''),
      EndReasonPart: StringUtils.quoteReplacement(item.EndReasonPart ?? ''),
      EndReasonParagraph: StringUtils.quoteReplacement(item.EndReasonParagraph ?? ''),
      BailiffsOfficeAddress: StringUtils.quoteReplacement(item.BailiffsOfficeAddress ?? ''),
      BailiffsOfficeName: StringUtils.quoteReplacement(item.BailiffsOfficeName ?? ''),
    }));
  }
}
