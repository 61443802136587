import { injectable, inject } from 'inversify';
import {
  IIndividualCommonNegativeInfoAdapterService,
  IIndividualSpecialSignsAdapterService,
  IIndividualSpecialSignsAdapterServiceKey
} from '~/services/adapters/individual/individualAdapterService.interfaces';
import {
  IReportArrayStringAdapterService,
  IReportArrayStringAdapterServiceKey
} from '~/services/adapters/common/reportAdapterService.interfeces';
import { IndividualCommonNegativeInfo } from '~/types/individual/commonNegativeInfo';
import { IndividualCommonNegativeInfoDTO } from '~/types/dto/individual/commonNegativeInfoDTO';

@injectable()
export class IndividualCommonNegativeInfoAdapterService implements IIndividualCommonNegativeInfoAdapterService {
  constructor(
    @inject(IReportArrayStringAdapterServiceKey as symbol) private reportArrayStringAdapterService: IReportArrayStringAdapterService,
    @inject(IIndividualSpecialSignsAdapterServiceKey as symbol) private individualSpecialSignsAdapterService: IIndividualSpecialSignsAdapterService
  ) {}
  getData(initData: IndividualCommonNegativeInfo): IndividualCommonNegativeInfoDTO {
    return {
      HasPrejudicedAdministrativePenalties: initData.HasPrejudicedAdministrativePenalties ?? false,
      HasGunsNegative: initData.HasGunsNegative ?? false,
      SpecialSigns: this.individualSpecialSignsAdapterService.getData(initData.SpecialSigns),
      IncidentsInfo: this.reportArrayStringAdapterService.getData(initData.IncidentsInfo),
      ArchiveEnforcementProceedings: this.reportArrayStringAdapterService.getData(initData.ArchiveEnforcementProceedings),
      OuterBlackLists: this.reportArrayStringAdapterService.getData(initData.OuterBlackLists),
      SpecialAccounting: this.reportArrayStringAdapterService.getData(initData.SpecialAccounting),
      InInnerBlackList: initData.InInnerBlackList,
      InterestInNegativeContent: this.reportArrayStringAdapterService.getData(initData.InterestInNegativeContent),
      OtherNegativeRecords: this.reportArrayStringAdapterService.getData(initData.OtherNegativeRecords)
    };
  }
}
