/* eslint-disable class-methods-use-this, max-len */

import { injectable } from 'inversify';
import { LegalEntitiesCompanyEmail } from '~/types/legal-entities/companyEmail';
import {
  ILegalEntitiesEmailsAdapterService
} from '~/services/adapters/legalEntities/legalEntitiesAdapterService.interfaces';
import { LegalEntitiesCompanyEmailDTO } from '~/types/dto/common/legalEntitiesCompanyEmailDTO';

@injectable()
export class LegalEntitiesEmailsAdapterService implements ILegalEntitiesEmailsAdapterService {
  getData(initData: LegalEntitiesCompanyEmail[]): LegalEntitiesCompanyEmailDTO[] {
    return (initData ?? []).map((email) => ({
      CompaniesWithThisEmail: email.CompaniesWithThisEmail,
      Value: email.Value
    }));
  }
}
