import { injectable, inject } from 'inversify';
import { ReportPersonSite } from '~/types/common/reportPersonSite';
import { IPrimitiveTypesNormalizationServiceKey, IPrimitiveTypesNormalizationService } from './primitiveTypesNormalizationService.interfaces';
import { INormalizationService } from './reportNormalizationService.interfaces';

@injectable()
export class ReportPersonSiteNormalizationService implements INormalizationService<ReportPersonSite> {
  constructor(
    @inject(IPrimitiveTypesNormalizationServiceKey as symbol) private primitiveTypesNormalizationService: IPrimitiveTypesNormalizationService,
  ) {}

  normalize(data: any, root: string): ReportPersonSite | undefined {
    const {
      normalizeObject,
      normalizeString,
      normalizeOptionalString
    } = this.primitiveTypesNormalizationService;

    return normalizeObject(data, (data) => ({
      DomainName: normalizeString(data.DomainName, `${root}.DomainName`),
      Status: normalizeString(data.Status, `${root}.Status`),
      Administrator: normalizeString(data.Administrator, `${root}.Administrator`),
      RegistrationDate: normalizeOptionalString(data.RegistrationDate, `${root}.RegistrationDate`),
      ExpirationDate: normalizeOptionalString(data.ExpirationDate, `${root}.ExpirationDate`)
    }), root);
  }
}
