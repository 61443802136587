import { injectable } from 'inversify';
import { orderBy } from 'lodash-es';
import { IIndividualMovementBusAdapterService } from '~/services/adapters/individual/individualAdapterService.interfaces';
import { IndividualMovementBus } from '~/types/individual/movementBus';
import { IndividualMovementBusDTO } from '~/types/dto/individual/movementBusDTO';
import { StringUtils } from '~/utils/stringUtils';
import { DateUtils } from '~/utils/date';

@injectable()
export class IndividualMovementBusAdapterService implements IIndividualMovementBusAdapterService {
  getData(initData: IndividualMovementBus[]): IndividualMovementBusDTO[] {
    return orderBy(initData ?? [], ['MovementDate'], ['desc']).map((item) => ({
      MovementFrom: StringUtils.quoteReplacement(item.MovementFrom),
      MovementTo: StringUtils.quoteReplacement(item.MovementTo),
      MovementDate: DateUtils.formatDate(item.MovementDate),
      ArrivalDate: DateUtils.formatDate(item.ArrivalDate),
      SeatNumber: StringUtils.quoteReplacement(item.SeatNumber),
    }));
  }
}
