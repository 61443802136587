import { injectable } from 'inversify';
import { ReportSocialNetworkAboutInfo } from '~/types/common/reportSocialNetworkAboutInfo';
import { IPlaceholderDataService } from './reportPlaceholderService.interfaces';

@injectable()
export class ReportSocialNetworkAboutInfoPlaceholderService implements IPlaceholderDataService<ReportSocialNetworkAboutInfo> {
  getData(): ReportSocialNetworkAboutInfo {
    return {
      Activity: '',
      Interests: '',
      FavoriteMusic: '',
      FavoriteFilms: '',
      FavoriteTvShows: '',
      FavoriteBooks: '',
      FavoriteGames: '',
      FavoriteQuotes: '',
      About: '',
    };
  }
}
