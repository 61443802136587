import { InjectionKey } from 'vue';
import { LegalEntitiesReportFinancialIndicatorWithConclusionsDTO } from '~/types/dto/legalEntities/reportFinancialIndicatorWithConclusionsDTO';
import { LegalEntitiesReportFinancialIndicatorBaseDTO } from '~/types/dto/legalEntities/reportFinancialIndicatorBaseDTO';

export interface IDataOptionsPropertiesLineChartService {
  getLabels(twoYearsAgo: number, lastYear: number, currentYear: number): Array<string>;
  getData(initData: LegalEntitiesReportFinancialIndicatorWithConclusionsDTO | LegalEntitiesReportFinancialIndicatorBaseDTO): Array<number>;
}

export const IDataOptionsPropertiesLineChartServiceKey: InjectionKey<IDataOptionsPropertiesLineChartService> = Symbol('IDataOptionsPropertiesLineChartService');
