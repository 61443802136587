import { injectable, inject } from 'inversify';

import { IPrimitiveTypesNormalizationServiceKey, IPrimitiveTypesNormalizationService } from './primitiveTypesNormalizationService.interfaces';
import { ReportMainEntityFileUrl } from '~/types/common/reportMainEntityFileUrl';
import { INormalizationService } from './reportNormalizationService.interfaces';

@injectable()
export class ReportMainEntityFileUrlNormalizationService implements INormalizationService<ReportMainEntityFileUrl> {
  constructor(
    @inject(IPrimitiveTypesNormalizationServiceKey as symbol) private primitiveTypesNormalizationService: IPrimitiveTypesNormalizationService,
  ) {}

  normalize(data: any, root: string): ReportMainEntityFileUrl | undefined {
    const {
      normalizeString,
      normalizeObject,
      normalizeOptionalString
    } = this.primitiveTypesNormalizationService;

    return normalizeObject(data, (data) => ({
      Text: normalizeString(data.Text, `${root}.Text`),
      Value: normalizeOptionalString(data.Value, `${root}.Value`),
    }), root);
  }
}
