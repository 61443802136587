import { injectable } from 'inversify';
import {
  ILegalEntitiesHasGeneralInformationService
} from '~/modules/legalEntities/commonInfo/legalEntitiesGeneralInformation/services/legalEntitiesHasGeneralInformationServices.interface';
import { LegalEntitiesDataBlobDataDTO } from '~/types/dto/blobs/legalEntitiesDataBlobDataDTO';

@injectable()
export class LegalEntitiesHasGeneralInformationService implements ILegalEntitiesHasGeneralInformationService {
  checkHasGeneralInformation(initData: LegalEntitiesDataBlobDataDTO): boolean {
    const { briefInfo } = initData;
    const { financialInfo } = initData;
    const hasManagementCompanies = briefInfo.ManagementCompanies.length > 0;
    const hasCompanyRegistrationDate = Boolean(briefInfo.CompanyRegistrationDate);
    const hasCompanyLiquidationDate = Boolean(briefInfo.CompanyLiquidationDate);
    const hasCompanyStatus = Boolean(briefInfo.CompanyStatus);
    const hasCompanySize = financialInfo.CompanySize !== 'Нет данных';
    const hasCompanyStatedCapitalSum = Boolean(briefInfo.CompanyStatedCapitalSum);
    const hasMspCategory = Boolean(briefInfo.MspCategory);

    return hasCompanySize
        || hasMspCategory
        || hasCompanyStatus
        || hasManagementCompanies
        || hasCompanyLiquidationDate
        || hasCompanyRegistrationDate
        || hasCompanyStatedCapitalSum;
  }
}
