<template>
  <path d="M15.3845 15.3872C13.6461 17.1248 12.3416 19.2473 11.5769 21.5828C11.5533 21.6511 11.5179 21.7144 11.4728 21.7698C11.4382 21.8123 11.3979 21.8501 11.3528 21.8821C11.2488 21.9561 11.1242 21.9958 10.9965 21.9958C10.8688 21.9958 10.7443 21.9561 10.6403 21.8821C10.5363 21.8081 10.4579 21.7034 10.4161 21.5828C9.6521 19.248 8.34875 17.126 6.61151 15.3884C4.87433 13.6509 2.7522 12.3469 0.416931 11.5818C0.35675 11.5613 0.300476 11.5317 0.249939 11.4946C0.198364 11.4565 0.152771 11.4106 0.11499 11.3579C0.0786743 11.3071 0.0504761 11.2515 0.0311279 11.1929C0.0106201 11.1311 0 11.0659 0 11C0 10.8716 0.0402222 10.7466 0.11499 10.6421C0.189758 10.5378 0.295349 10.4595 0.416931 10.4182C2.75153 9.65259 4.87292 8.34839 6.6095 6.61084C8.34607 4.87329 9.64893 2.75146 10.4127 0.417236C10.4538 0.295654 10.532 0.189941 10.6364 0.11499C10.7408 0.0402832 10.866 0 10.9944 0C11.1229 0 11.248 0.0402832 11.3524 0.11499C11.4568 0.189941 11.535 0.295654 11.5762 0.417236C12.341 2.75269 13.6455 4.87549 15.3842 6.61304C17.1227 8.35059 19.2464 9.65405 21.5831 10.4182C21.7047 10.4595 21.8102 10.5378 21.885 10.6421C21.9277 10.7017 21.9592 10.7681 21.9783 10.8379C21.9926 10.8904 22 10.9448 22 11C22 11.1284 21.9598 11.2534 21.885 11.3579C21.8102 11.4622 21.7047 11.5405 21.5831 11.5818C19.2465 12.3459 17.123 13.6497 15.3845 15.3872Z" fill="url(#paint0_radial_2874_76470)" />
  <path d="M6.61255 15.3843C4.87537 13.6467 2.75323 12.3428 0.417969 11.5776C2.66223 12.3098 6.46136 12.8945 11.0014 12.8945C15.5421 12.8945 19.3378 12.3098 21.5828 11.5776C19.247 12.3423 17.1243 13.6462 15.3866 15.3838C13.6489 17.1213 12.3451 19.2434 11.5807 21.5786C11.5396 21.7002 11.4614 21.8059 11.357 21.8806C11.2526 21.9556 11.1274 21.9958 10.999 21.9958C10.8705 21.9958 10.7454 21.9556 10.641 21.8806C10.5831 21.8391 10.5333 21.7883 10.4933 21.7305C10.4612 21.6838 10.4355 21.6328 10.4172 21.5786C9.65314 19.2439 8.34973 17.1218 6.61255 15.3843Z" fill="url(#paint1_radial_2874_76470)" />
  <defs>
    <radialGradient
      id="paint0_radial_2874_76470"
      cx="0"
      cy="0"
      r="1"
      gradientUnits="userSpaceOnUse"
      gradientTransform="translate(21.3777 -0.470949) scale(24.5318 24.5267)"
    >
      <stop stop-color="#00FFFE" />
      <stop offset="0.228" stop-color="#00FFFE" />
      <stop offset="0.305" stop-color="#11F3FE" />
      <stop offset="0.457" stop-color="#3ED3FE" />
      <stop offset="0.671" stop-color="#87A0FF" />
      <stop offset="0.935" stop-color="#E95AFF" />
      <stop offset="0.953" stop-color="#F055FF" />
      <stop offset="1" stop-color="#F055FF" />
    </radialGradient>
    <radialGradient
      id="paint1_radial_2874_76470"
      cx="0"
      cy="0"
      r="1"
      gradientUnits="userSpaceOnUse"
      gradientTransform="translate(18.4404 12.4588) scale(13.8627 6.82398)"
    >
      <stop stop-color="#053BF2" />
      <stop offset="0.089" stop-color="#0F3CF3" stop-opacity="0.958" />
      <stop offset="0.241" stop-color="#2A3FF4" stop-opacity="0.844" />
      <stop offset="0.439" stop-color="#5544F6" stop-opacity="0.658" />
      <stop offset="0.675" stop-color="#924BFA" stop-opacity="0.402" />
      <stop offset="0.94" stop-color="#DE53FE" stop-opacity="0.078" />
      <stop offset="1" stop-color="#F055FF" stop-opacity="0.01" />
    </radialGradient>
  </defs>
</template>
