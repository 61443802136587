import { injectable } from 'inversify';
import { ReportVehicleRestrict } from '~/types/common/reportVehicleRestrict';
import { IPlaceholderDataService } from './reportPlaceholderService.interfaces';

@injectable()
export class ReportVehicleRestrictPlaceholderService implements IPlaceholderDataService<ReportVehicleRestrict> {
  getData(): ReportVehicleRestrict {
    return {
      Region: '',
      Reason: '',
      Type: '',
    };
  }
}
