import { injectable, inject } from 'inversify';
import { LegalEntitiesFineAndTaxDebt } from '~/types/legal-entities/fineAndTaxDebt';
import { LegalEntitiesTax } from '~/types/legal-entities/tax';
import { IPrimitiveTypesNormalizationServiceKey, IPrimitiveTypesNormalizationService } from '../common/primitiveTypesNormalizationService.interfaces';
import { INormalizationService } from '../common/reportNormalizationService.interfaces';

@injectable()
export class LegalEntitiesFineAndTaxDebtNormalizationService implements INormalizationService<LegalEntitiesFineAndTaxDebt> {
  constructor(
    @inject(IPrimitiveTypesNormalizationServiceKey as symbol) private primitiveTypesNormalizationService: IPrimitiveTypesNormalizationService,
  ) {}

  normalize(data: any, root: string): LegalEntitiesTax | undefined {
    const { normalizeString, normalizeNumber, normalizeObject } = this.primitiveTypesNormalizationService;

    return normalizeObject(data, (data) => ({
      Name: normalizeString(data.Name, `${root}.Name`),
      Sum: normalizeNumber(data.Sum, `${root}.Sum`),
      Year: normalizeNumber(data.Year, `${root}.Year`),
    }), root);
  }
}
