import { injectable } from 'inversify';
import { ReportPassport } from '~/types/common/reportPassport';
import { IPlaceholderDataService } from './reportPlaceholderService.interfaces';

@injectable()
export class ReportPassportPlaceholderService implements IPlaceholderDataService<ReportPassport> {
  getData(): ReportPassport {
    return {
      Type: 0,
      IsActual: false,
      Series: '',
      Number: '',
      IssuedDate: '',
      IssuedBy: '',
      DepartmentCode: '',
    };
  }
}
