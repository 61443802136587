import { injectable, inject } from 'inversify';
import { ReportMovementTaxi } from '~/types/common/reportMovementTaxi';
import { IPrimitiveTypesNormalizationServiceKey, IPrimitiveTypesNormalizationService } from './primitiveTypesNormalizationService.interfaces';
import { INormalizationService } from './reportNormalizationService.interfaces';

@injectable()
export class ReportMovementTaxiNormalizationService implements INormalizationService<ReportMovementTaxi> {
  constructor(
    @inject(IPrimitiveTypesNormalizationServiceKey as symbol) private primitiveTypesNormalizationService: IPrimitiveTypesNormalizationService,
  ) {}

  normalize(data: any, root: string): ReportMovementTaxi | undefined {
    const { normalizeObject, normalizeString, normalizeOptionalString } = this.primitiveTypesNormalizationService;

    return normalizeObject(data, (data) => ({
      MovementFrom: normalizeString(data.MovementFrom, `${root}.MovementFrom`),
      MovementTo: normalizeString(data.MovementTo, `${root}.MovementTo`),
      MovementDate: normalizeOptionalString(data.MovementDate, `${root}.MovementDate`),
    }), root);
  }
}
