import { injectable, inject } from 'inversify';
import { IndividualAviaPerson } from '~/types/individual/aviaPerson';
import { IPrimitiveTypesNormalizationServiceKey, IPrimitiveTypesNormalizationService } from '../common/primitiveTypesNormalizationService.interfaces';
import { INormalizationService, IStringArrayNormalizationServiceKey } from '../common/reportNormalizationService.interfaces';

@injectable()
export class IndividualAviaPersonNormalizationService implements INormalizationService<IndividualAviaPerson> {
  constructor(
    @inject(IPrimitiveTypesNormalizationServiceKey as symbol) private primitiveTypesNormalizationService: IPrimitiveTypesNormalizationService,
    @inject(IStringArrayNormalizationServiceKey as symbol) private stringArrayNormalizationService: INormalizationService<string>
  ) {}

  normalize(data: any, root: string): IndividualAviaPerson | undefined {
    const {
      normalizeObject,
      normalizeString,
      normalizeOptionalString,
      normalizeArray
    } = this.primitiveTypesNormalizationService;

    return normalizeObject(data, (data) => ({
      FlightDate: normalizeOptionalString(data.FlightDate, `${root}.FlightDate`),
      FlightStartTime: normalizeOptionalString(data.FlightStartTime, `${root}.FlightStartTime`),
      From: normalizeOptionalString(data.From, `${root}.From`),
      To: normalizeOptionalString(data.To, `${root}.To`),
      FlightNumber: normalizeString(data.FlightNumber, `${root}.FlightNumber`),
      Airline: normalizeOptionalString(data.Airline, `${root}.Airline`),
      AirlineCode: normalizeOptionalString(data.AirlineCode, `${root}.AirlineCode`),
      Companions: normalizeArray(data.Companions, (item, field) => this.stringArrayNormalizationService.normalize(item, field), `${root}.Companions`),
      AccompanyingPersons: normalizeArray(data.AccompanyingPersons, (item, field) => this.stringArrayNormalizationService.normalize(item, field), `${root}.AccompanyingPersons`),
      FullName: normalizeOptionalString(data.FullName, `${root}.FullName`),
      Passport: normalizeOptionalString(data.Passport, `${root}.Passport`),
    }), root);
  }
}
