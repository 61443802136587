import { Component } from '@nuxt/schema';
import { DefineComponent, Raw } from 'vue';
import { ReportMessengerType } from '~/types/common/reportMessengerType';
import Telegram from '~/modules/svg/social/Telegram.vue';
import Viber from '~/modules/svg/social/Viber.vue';
import WhatsApp from '~/modules/svg/social/WhatsApp.vue';
import Default from '~/modules/svg/social/Default.vue';

export module MessengerUtils {
  export function formatMessengerType(type: ReportMessengerType) {
    switch (type) {
      case ReportMessengerType.Telegram:
        return 'Telegram';
      case ReportMessengerType.Whatsapp:
        return 'Whatsapp';
      case ReportMessengerType.Viber:
        return 'Viber';
      default:
        return '';
    }
  }

  export function getMessengerTypeIcon(type: ReportMessengerType): unknown {
    switch (type) {
      case ReportMessengerType.Telegram: 
        return markRaw(Telegram);
      case ReportMessengerType.Viber: 
        return markRaw(Viber);
      case ReportMessengerType.Whatsapp: 
        return markRaw(WhatsApp);
      default:
        return markRaw(Default);
    }
  }
}
