import { injectable } from 'inversify';
import { isEqual } from 'lodash-es';
import { MergeService } from '~/services/merge/merge-service';
import { ReportUrlDTO } from '~/types/dto/common/reportUrlDTO';

@injectable()
export class ReportUrlMergeService extends MergeService<ReportUrlDTO> {
  protected canMerge(leftItem: ReportUrlDTO, rightItem: ReportUrlDTO): boolean {
    return isEqual(leftItem, rightItem);
  }

  protected mergeFields(leftItem: ReportUrlDTO, rightItem: ReportUrlDTO): ReportUrlDTO {
    return {
      Text: leftItem.Text || rightItem.Text,
      Value: leftItem.Value || rightItem.Value,
    };
  }
}
