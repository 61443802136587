/* eslint-disable class-methods-use-this, max-len */

import { injectable, inject } from 'inversify';
import { LegalEntitiesFinancialAnalysisShortDTO } from '~/types/dto/legalEntities/financialAnalysisShort';
import {
  ILegalEntitiesFinancialAnalysisShortAdapterService,
  ILegalEntitiesReportFinancialIndicatorBaseAdapterService,
  ILegalEntitiesReportFinancialIndicatorBaseAdapterServiceKey,
  ILegalEntitiesReportFinancialIndicatorWithConclusionsAdapterService,
  ILegalEntitiesReportFinancialIndicatorWithConclusionsAdapterServiceKey,
  ILegalEntitiesReportFinancialIndicatorWithDynamicAdapterService,
  ILegalEntitiesReportFinancialIndicatorWithDynamicAdapterServiceKey
} from '~/services/adapters/legalEntities/legalEntitiesAdapterService.interfaces';
import { LegalEntitiesFinancialAnalysisShort } from '~/types/legal-entities/financialAnalysisShort';

@injectable()
export class LegalEntitiesFinancialAnalysisShortAdapterService implements ILegalEntitiesFinancialAnalysisShortAdapterService {
  constructor(
    @inject(ILegalEntitiesReportFinancialIndicatorBaseAdapterServiceKey as symbol) private legalEntitiesReportFinancialIndicatorBaseAdapterService: ILegalEntitiesReportFinancialIndicatorBaseAdapterService,
    @inject(ILegalEntitiesReportFinancialIndicatorWithConclusionsAdapterServiceKey as symbol) private legalEntitiesReportFinancialIndicatorWithConclusionsAdapterService: ILegalEntitiesReportFinancialIndicatorWithConclusionsAdapterService,
    @inject(ILegalEntitiesReportFinancialIndicatorWithDynamicAdapterServiceKey as symbol) private legalEntitiesReportFinancialIndicatorWithDynamicAdapterService: ILegalEntitiesReportFinancialIndicatorWithDynamicAdapterService,
  ) {}
  getData(initData: LegalEntitiesFinancialAnalysisShort): LegalEntitiesFinancialAnalysisShortDTO {
    return {
      IsExists: initData.IsExists,
      TwoYearsAgo: initData.TwoYearsAgo,
      LastYear: initData.LastYear ?? 0,
      CurrentYear: initData.CurrentYear ?? 0,
      Revenues: this.legalEntitiesReportFinancialIndicatorWithDynamicAdapterService.getData(initData.Revenues),
      ProfitFromSales: this.legalEntitiesReportFinancialIndicatorWithDynamicAdapterService.getData(initData.ProfitFromSales),
      Ebit: this.legalEntitiesReportFinancialIndicatorWithDynamicAdapterService.getData(initData.Ebit),
      DebtShare: this.legalEntitiesReportFinancialIndicatorWithDynamicAdapterService.getData(initData.DebtShare),
      DebtEbit: this.legalEntitiesReportFinancialIndicatorWithConclusionsAdapterService.getData(initData.DebtEbit),
      EbitPercent: this.legalEntitiesReportFinancialIndicatorWithConclusionsAdapterService.getData(initData.EbitPercent),
      EbitMargin: this.legalEntitiesReportFinancialIndicatorBaseAdapterService.getData(initData.EbitMargin),
      OwnCapital: this.legalEntitiesReportFinancialIndicatorBaseAdapterService.getData(initData.OwnCapital),
    };
  }
}
