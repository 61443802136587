import { injectable, inject } from 'inversify';
import { IReportMainEntityFilePlaceholderServiceKey, IPlaceholderDataService } from '~/services/placeholder/common/reportPlaceholderService.interfaces';
import { ReportMainEntityFile } from '~/types/common/reportMainEntityFile';
import { IndividualWantedMvd } from '~/types/individual/wantedMvd';
import { IPrimitiveTypesNormalizationServiceKey, IPrimitiveTypesNormalizationService } from '../common/primitiveTypesNormalizationService.interfaces';
import { INormalizationService, IReportMainEntityFileNormalizationServiceKey } from '../common/reportNormalizationService.interfaces';

@injectable()
export class IndividualWantedMvdNormalizationService implements INormalizationService<IndividualWantedMvd> {
  constructor(
    @inject(IReportMainEntityFileNormalizationServiceKey as symbol) private reportMainEntityFileNormalizationService: INormalizationService<ReportMainEntityFile>,

    @inject(IReportMainEntityFilePlaceholderServiceKey as symbol) private reportMainEntityFilePlaceholderService: IPlaceholderDataService<ReportMainEntityFile>,

    @inject(IPrimitiveTypesNormalizationServiceKey as symbol) private primitiveTypesNormalizationService: IPrimitiveTypesNormalizationService,
  ) {}

  normalize(data: any, root: string): IndividualWantedMvd | undefined {
    const { normalizeObject, normalizeString } = this.primitiveTypesNormalizationService;

    return normalizeObject(data, (data) => ({
      Photo: this.reportMainEntityFileNormalizationService.normalize(data.Photo, `${root}.Photo`) ?? this.reportMainEntityFilePlaceholderService.getData(),
      Region: normalizeString(data.Region, `${root}.Region`),
      Reason: normalizeString(data.Reason, `${root}.Reason`),
      SettingData: normalizeString(data.SettingData, `${root}.SettingData`),
      ContactInfo: normalizeString(data.ContactInfo, `${root}.ContactInfo`),
    }), root);
  }
}
