import { InjectionKey } from 'vue';
import { LegalEntitiesOwnerType } from '~/types/legal-entities/ownerType';

export interface IRequisiteOwnerFormatter {
    getRequisiteType(requisite: string): LegalEntitiesOwnerType;
}

export type IRequisiteOwnerFormatterService = IRequisiteOwnerFormatter;

export const IRequisiteOwnerFormatterServiceKey: InjectionKey<IRequisiteOwnerFormatterService> = Symbol('IRequisiteOwnerFormatterService');
