import { injectable, inject } from 'inversify';

import { LegalEntitiesSuccessor } from '~/types/legal-entities/successor';
import { IPrimitiveTypesNormalizationServiceKey, IPrimitiveTypesNormalizationService } from '../common/primitiveTypesNormalizationService.interfaces';
import { INormalizationService } from '../common/reportNormalizationService.interfaces';

@injectable()
export class LegalEntitiesSuccessorNormalizationService implements INormalizationService<LegalEntitiesSuccessor> {
  constructor(
  @inject(IPrimitiveTypesNormalizationServiceKey as symbol) private primitiveTypesNormalizationService: IPrimitiveTypesNormalizationService,
  ) {}

  normalize(data: any, root: string): LegalEntitiesSuccessor | undefined {
    const { normalizeString, normalizeOptionalString, normalizeObject } = this.primitiveTypesNormalizationService;

    return normalizeObject(data, (data) => ({
      OrganisationName: normalizeString(data.OrganisationName, `${root}.OrganisationName`),
      Inn: normalizeString(data.Inn, `${root}.Inn`),
      Ogrn: normalizeString(data.Ogrn, `${root}.Ogrn`),
      Date: normalizeOptionalString(data.Date, `${root}.Date`),
      Status: normalizeString(data.Status, `${root}.Status`),
    }), root);
  }
}
