import { IContainerBinder } from '~/configuration/inversify/containerAccessor.interface';
import FakeSplittablesFactory from '~/factories/splittables/fakeSplittablesFactory';
import { IFakeSplittablesFactoryKey } from '~/factories/splittables/fakeSplittablesFactory.interfaces';
import SplittableSectionsFactory from '~/factories/splittables/splittableSectionsFactory';
import { ISplittableSectionsFactoryKey } from '~/factories/splittables/splittableSectionsFactory.interfaces';
import SplittablesFactory from '~/factories/splittables/splittablesFactory';
import { ISplittablesFactoryKey } from '~/factories/splittables/splittablesFactory.interfaces';
import { NodeLayoutSnapshotsService } from '~/services/nodes/nodeLayoutSnapshotsService';
import { INodeLayoutSnapshotsServiceKey } from '~/services/nodes/nodeLayoutSnapshotsService.interfaces';
import NodesSizeSnapshotsService from '~/services/nodes/nodesSizeSnapshotsService';
import { INodesSizeSnapshotsServiceKey } from '~/services/nodes/nodesSizeSnapshotsService.interfaces';
import SplittableRowLayoutingService from '~/services/splittables/splittableRowLayoutingService';
import { ISplittableRowLayoutingServiceKey } from '~/services/splittables/splittableRowLayoutingService.interfaces';

export function bindSplittableServices(bind: IContainerBinder) {
  bind(INodesSizeSnapshotsServiceKey).to(NodesSizeSnapshotsService);
  bind(INodeLayoutSnapshotsServiceKey).to(NodeLayoutSnapshotsService);
  bind(ISplittableRowLayoutingServiceKey).to(SplittableRowLayoutingService);
  bind(ISplittablesFactoryKey).to(SplittablesFactory);
  bind(IFakeSplittablesFactoryKey).to(FakeSplittablesFactory);
  bind(ISplittableSectionsFactoryKey).to(SplittableSectionsFactory);
}
