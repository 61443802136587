import { injectable } from 'inversify';

import { LegalEntitiesCourtCasesByYear } from '~/types/legal-entities/courtCasesByYear';
import { IPlaceholderDataService } from '../common/reportPlaceholderService.interfaces';

@injectable()
export class LegalEntitiesCourtCasesByYearPlaceholderDataService implements IPlaceholderDataService<LegalEntitiesCourtCasesByYear> {
  getData(): LegalEntitiesCourtCasesByYear {
    return {
      Year: 0,
      Sum: 0,
      CaseCount: 0,
      CaseType: ''
    };
  }
}
