import { injectable } from 'inversify';
import { IReportVehicleInsuranceAdapterService } from '~/services/adapters/common/reportAdapterService.interfeces';
import { ReportVehicleInsurance } from '~/types/common/reportVehicleInsurance';
import { ReportVehicleInsuranceDTO } from '~/types/dto/common/reportVehicleInsuranceDTO';
import { DateUtils } from '~/utils/date';

@injectable()
export class ReportVehicleInsuranceAdapterService implements IReportVehicleInsuranceAdapterService {
  getData(initData: ReportVehicleInsurance[]): ReportVehicleInsuranceDTO[] {
    return (initData ?? []).map((insurance) => ({
      InsuranceType: insurance.InsuranceType,
      PolicySeries: insurance.PolicyNumber,
      PolicyNumber: insurance.PolicyNumber,
      InsuranceCompany: insurance.InsuranceCompany.replaceAll('`', '"'),
      PolicyRestrictions: insurance.PolicyRestrictions,
      PolicyStartDate: DateUtils.formatDate(insurance.PolicyStartDate),
      PolicyEndDate: DateUtils.formatDate(insurance.PolicyEndDate),
      Cbm: insurance.Cbm ?? '',
      IsActive: insurance.IsActive ?? false
    }));
  }
}
