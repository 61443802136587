import { injectable, inject } from 'inversify';
import { IReportMainEntityFilePlaceholderServiceKey, IPlaceholderDataService } from '~/services/placeholder/common/reportPlaceholderService.interfaces';
import { ReportMainEntityFile } from '~/types/common/reportMainEntityFile';
import { IndividualWantedFsin } from '~/types/individual/wantedFsin';
import { IPrimitiveTypesNormalizationServiceKey, IPrimitiveTypesNormalizationService } from '../common/primitiveTypesNormalizationService.interfaces';
import { INormalizationService, IReportMainEntityFileNormalizationServiceKey } from '../common/reportNormalizationService.interfaces';

@injectable()
export class IndividualWantedFsinNormalizationService implements INormalizationService<IndividualWantedFsin> {
  constructor(
    @inject(IReportMainEntityFileNormalizationServiceKey as symbol) private reportMainEntityFileNormalizationService: INormalizationService<ReportMainEntityFile>,

    @inject(IReportMainEntityFilePlaceholderServiceKey as symbol) private reportMainEntityFilePlaceholderService: IPlaceholderDataService<ReportMainEntityFile>,

    @inject(IPrimitiveTypesNormalizationServiceKey as symbol) private primitiveTypesNormalizationService: IPrimitiveTypesNormalizationService,
  ) {}

  normalize(data: any, root: string): IndividualWantedFsin | undefined {
    const { normalizeObject, normalizeString } = this.primitiveTypesNormalizationService;

    return normalizeObject(data, (data) => ({
      Photo: this.reportMainEntityFileNormalizationService.normalize(data.Photo, `${root}.Photo`) ?? this.reportMainEntityFilePlaceholderService.getData(),
      WantedInfo: normalizeString(data.WantedInfo, `${root}.WantedInfo`),
      TerritorialDepartmentName: normalizeString(data.TerritorialDepartmentName, `${root}.TerritorialDepartmentName`),
      FederalDepartmentName: normalizeString(data.FederalDepartmentName, `${root}.FederalDepartmentName`),
    }), root);
  }
}
