import { injectable, inject } from 'inversify';
import { LegalEntitiesInsuranceFundInfo } from '~/types/legal-entities/insuranceFundInfo';
import { IPrimitiveTypesNormalizationServiceKey, IPrimitiveTypesNormalizationService } from '../common/primitiveTypesNormalizationService.interfaces';
import { INormalizationService } from '../common/reportNormalizationService.interfaces';

@injectable()
export class LegalEntitiesInsuranceFundInfoNormalizationService implements INormalizationService<LegalEntitiesInsuranceFundInfo> {
  constructor(
    @inject(IPrimitiveTypesNormalizationServiceKey as symbol) private primitiveTypesNormalizationService: IPrimitiveTypesNormalizationService,
  ) {}

  normalize(data: any, root: string): LegalEntitiesInsuranceFundInfo | undefined {
    const { normalizeString, normalizeOptionalString, normalizeObject } = this.primitiveTypesNormalizationService;

    return normalizeObject(data, (data) => ({
      RegNumber: normalizeString(data.RegNumber, `${root}.RegNumber`),
      RegDate: normalizeOptionalString(data.RegDate, `${root}.RegDate`),
      DepartmentCode: normalizeOptionalString(data.DepartmentCode, `${root}.DepartmentCode`),
      DepartmentName: normalizeOptionalString(data.DepartmentName, `${root}.DepartmentName`),
    }), root);
  }
}
