import { injectable } from 'inversify';
import { ReportSocialNetworkJob } from '~/types/common/reportSocialNetworkJob';

import { IPlaceholderDataService } from './reportPlaceholderService.interfaces';

@injectable()
export class ReportSocialNetworkJobPlaceholderService implements IPlaceholderDataService<ReportSocialNetworkJob> {
  getData(): ReportSocialNetworkJob {
    return {
      Type: 0,
      SocialNetworkId: '',
      City: '',
      CompanyName: '',
      Position: '',
      StartDate: '',
      EndDate: '',
      IsWorkingNow: false
    };
  }
}
