import { LegalEntitiesTaxByYearDTO } from '~/types/dto/legalEntities/legalEntitiesTaxByYear';
import { MergeService } from './merge-service';

export class LegalEntitiesTaxByYearMergeService extends MergeService<LegalEntitiesTaxByYearDTO> {
  protected canMerge(leftItem: LegalEntitiesTaxByYearDTO, rightItem: LegalEntitiesTaxByYearDTO): boolean {
    return leftItem.name === rightItem.name;
  }
  
  protected mergeFields(leftItem: LegalEntitiesTaxByYearDTO, rightItem: LegalEntitiesTaxByYearDTO): LegalEntitiesTaxByYearDTO {
    const { yearsSum } = leftItem;

    rightItem.yearsSum.forEach((yearSum) => {
      const foundSumByYearIndex = yearsSum.findIndex(({ year }) => yearSum.year === year);

      if (foundSumByYearIndex !== -1) {
        yearsSum[foundSumByYearIndex].sum += yearSum.sum;
        return;
      }

      yearsSum.push(yearSum);
    });
    
    return {
      name: leftItem.name,
      yearsSum,
    };
  }
}
