import { injectable } from 'inversify';
import { Config } from '~/types/field-list/config';
import { NumberUtils } from '~/utils/number';
import { ILegalEntitiesContractsInfoByYearConfigService } from './LegalEntitiesContractsInfoByYearConfigService.interfaces';
import { LegalEntitiesContractsInfoByYearDTO } from '~/types/dto/legalEntities/contractsInfoByYearDTO';

@injectable()
export class LegalEntitiesContractsInfoByYearConfigService implements ILegalEntitiesContractsInfoByYearConfigService {
  getConfig(items: LegalEntitiesContractsInfoByYearDTO[]): Config {
    const totalSum = this.getTotalSum(items);

    const contractsSums = items.map(item => item.ContractsSum);

    const maxContractSum = NumberUtils.getMaxAbs(contractsSums);

    const correctedTotalPurchases = `${NumberUtils.formatNumberDigits(NumberUtils.formatByMaxValue(totalSum, maxContractSum), 1)} ${NumberUtils.getPostfix(maxContractSum)}`;
    
    return {
      fieldsDirection: 'row',
      fieldsWidth: '100%',
      isBeginningDivider: true,
      columnGap: '8px',
      rowGap: '8px',
      fields: [
        {   
          label: 'Всего закупок за все время, \u{20BD}',
          key: 'correctedTotalPurchases',
          value: {
            text: correctedTotalPurchases,
            width: 'max-content'
          },
        }
      ]
    };
  }

  private getTotalSum(items: LegalEntitiesContractsInfoByYearDTO[]) {
    return items.reduce((result, item) => result + item.ContractsSum, 0);
  }
}
