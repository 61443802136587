import { injectable, inject } from 'inversify';
import { LegalEntitiesDataBlobData } from '~/types/blobs/legal-entities-data-blob-data';
import { IReportVettingInfoAdapterService, IReportVettingInfoAdapterServiceKey } from '../reportAdapterService.interface';
import { LegalEntitiesDataBlobDataDTO } from '~/types/dto/blobs/legalEntitiesDataBlobDataDTO';
import {
  ILegalEntitiesAnalyticsIndicatorsAdapterService,
  ILegalEntitiesAnalyticsIndicatorsAdapterServiceKey,
  ILegalEntitiesBriefInfoAdapterService,
  ILegalEntitiesBriefInfoAdapterServiceKey,
  ILegalEntitiesCommonInfoAdapterService,
  ILegalEntitiesCommonInfoAdapterServiceKey,
  ILegalEntitiesDataBlobDataService,
  ILegalEntitiesFinancialAnalysisExtendedAdapterService,
  ILegalEntitiesFinancialAnalysisExtendedAdapterServiceKey,
  ILegalEntitiesFinancialAnalysisShortAdapterService,
  ILegalEntitiesFinancialAnalysisShortAdapterServiceKey,
  ILegalEntitiesFinancialStateAdapterService,
  ILegalEntitiesFinancialStateAdapterServiceKey,
  ILegalEntitiesMarkersAdapterService,
  ILegalEntitiesMarkersAdapterServiceKey,
  ILegalEntitiesTotalScoreAdapterService,
  ILegalEntitiesTotalScoreAdapterServiceKey
} from '~/services/adapters/legalEntities/legalEntitiesAdapterService.interfaces';
import { ILegalEntitiesAnalyticsIndicatorsPlaceholderDataServiceKey } from '~/services/placeholder/legalEntities/legalEntitiesPlaceholderDataService.interfaces';
import {
  IPlaceholderDataService,
  IReportVettingInfoPlaceholderServiceKey
} from '~/services/placeholder/common/reportPlaceholderService.interfaces';
import { LegalEntitiesAnalyticsIndicators } from '~/types/legal-entities/analyticsIndicators';
import { ReportVettingInfo } from '~/types/common/vettingInfo';

@injectable()
export class LegalEntitiesDataBlobDataAdapterService implements ILegalEntitiesDataBlobDataService {
  constructor(
    @inject(ILegalEntitiesCommonInfoAdapterServiceKey as symbol) private legalEntitiesCommonAdapterService: ILegalEntitiesCommonInfoAdapterService,
    @inject(ILegalEntitiesBriefInfoAdapterServiceKey as symbol) private legalEntitiesBriefInfoAdapterService: ILegalEntitiesBriefInfoAdapterService,
    @inject(ILegalEntitiesMarkersAdapterServiceKey as symbol) private legalEntitiesMarkersAdapterService: ILegalEntitiesMarkersAdapterService,
    @inject(ILegalEntitiesFinancialStateAdapterServiceKey as symbol) private legalEntitiesFinancialStateAdapterService: ILegalEntitiesFinancialStateAdapterService,
    @inject(ILegalEntitiesFinancialAnalysisShortAdapterServiceKey as symbol) private legalEntitiesFinancialAnalysisShortAdapterService: ILegalEntitiesFinancialAnalysisShortAdapterService,
    @inject(ILegalEntitiesFinancialAnalysisExtendedAdapterServiceKey as symbol) private legalEntitiesFinancialAnalysisExtendedAdapterService: ILegalEntitiesFinancialAnalysisExtendedAdapterService,
    @inject(ILegalEntitiesTotalScoreAdapterServiceKey as symbol) private legalEntitiesTotalScoreAdapterService: ILegalEntitiesTotalScoreAdapterService,
    @inject(ILegalEntitiesAnalyticsIndicatorsAdapterServiceKey as symbol) private legalEntitiesAnalyticsIndicatorsAdapterService: ILegalEntitiesAnalyticsIndicatorsAdapterService,

    @inject(IReportVettingInfoAdapterServiceKey as symbol) private reportVettingInfoAdapterService: IReportVettingInfoAdapterService,

    @inject(ILegalEntitiesAnalyticsIndicatorsPlaceholderDataServiceKey as symbol) private legalEntitiesAnalyticsIndicatorsPlaceholderDataService: IPlaceholderDataService<LegalEntitiesAnalyticsIndicators>,
    @inject(IReportVettingInfoPlaceholderServiceKey as symbol) private reportVettingInfoPlaceholderService: IPlaceholderDataService<ReportVettingInfo>
  ) {} 
  getData(initData: LegalEntitiesDataBlobData): LegalEntitiesDataBlobDataDTO {
    return {
      commonInfo: this.legalEntitiesCommonAdapterService.getData(initData.Common),
      briefInfo: this.legalEntitiesBriefInfoAdapterService.getData(initData.BriefInfo),
      markersInfo: this.legalEntitiesMarkersAdapterService.getData(initData.Markers),
      financialInfo: this.legalEntitiesFinancialStateAdapterService.getData(initData.FinancialState),
      financialAnalysisShortInfo: this.legalEntitiesFinancialAnalysisShortAdapterService.getData(initData.FinancialAnalysisShort),
      financialAnalysisExtendedInfo: this.legalEntitiesFinancialAnalysisExtendedAdapterService.getData(initData.FinancialAnalysisExtended),
      totalScoreInfo: this.legalEntitiesTotalScoreAdapterService.getData(initData.TotalScore),
      vettingInfo: this.reportVettingInfoAdapterService.getData(initData.VettingInfo ?? this.reportVettingInfoPlaceholderService.getData()),
      analyticsIndicators: this.legalEntitiesAnalyticsIndicatorsAdapterService.getData(initData.AnalyticsIndicators ?? this.legalEntitiesAnalyticsIndicatorsPlaceholderDataService.getData()),
    };
  }
}
