import { injectable } from 'inversify';

import { LegalEntitiesTax } from '~/types/legal-entities/tax';
import { IPlaceholderDataService } from '../common/reportPlaceholderService.interfaces';

@injectable()
export class LegalEntitiesTaxPlaceholderDataService implements IPlaceholderDataService<LegalEntitiesTax> {
  getData(): LegalEntitiesTax {
    return {
      Name: '',
      Sum: 0,
      Year: 0,
    };
  }
}
