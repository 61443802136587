import { InjectionKey } from 'vue';
import { ITableItemService } from '~/modules/table/services/tableItemService.interface';
import {
  LegalEntitiesReportFinancialIndicatorWithConclusionsDTO
} from '~/types/dto/legalEntities/reportFinancialIndicatorWithConclusionsDTO';

export type IOwnWorkingCapitalTableItemConfigService = ITableItemService<LegalEntitiesReportFinancialIndicatorWithConclusionsDTO>
export type IRatioOfCurrentLiquidityTableItemConfigService = ITableItemService<LegalEntitiesReportFinancialIndicatorWithConclusionsDTO>
export type IRatioOfSolvencyTableItemConfigService = ITableItemService<LegalEntitiesReportFinancialIndicatorWithConclusionsDTO>
export type IDegreeOfSolvencyTableItemConfigService = ITableItemService<LegalEntitiesReportFinancialIndicatorWithConclusionsDTO>
export type IRatioOfReceivablesToTotalAssetsTableItemConfigService = ITableItemService<LegalEntitiesReportFinancialIndicatorWithConclusionsDTO>

export const IOwnWorkingCapitalTableItemConfigServiceKey: InjectionKey<IOwnWorkingCapitalTableItemConfigService> = Symbol('IOwnWorkingCapitalTableItemConfigService');
export const IRatioOfCurrentLiquidityTableItemConfigServiceKey: InjectionKey<IRatioOfCurrentLiquidityTableItemConfigService> = Symbol('IRatioOfCurrentLiquidityTableItemConfigService');
export const IRatioOfSolvencyTableItemConfigServiceKey: InjectionKey<IRatioOfSolvencyTableItemConfigService> = Symbol('IRatioOfSolvencyTableItemConfigService');
export const IDegreeOfSolvencyTableItemConfigServiceKey: InjectionKey<IDegreeOfSolvencyTableItemConfigService> = Symbol('IDegreeOfSolvencyTableItemConfigService');
export const IRatioOfReceivablesToTotalAssetsTableItemConfigServiceKey: InjectionKey<IRatioOfReceivablesToTotalAssetsTableItemConfigService> = Symbol('IRatioOfReceivablesToTotalAssetsTableItemConfigService');
