import { InjectionKey } from 'vue';

export interface ICheckIsFirstDate {
  checkIsFirstDate<T, K extends keyof T>(
    array: Array<T>,
    innName: K, 
    fullName: K,
    firstDate: K,
    secondDate?: K
  ): Array<T & { isFirstDate: boolean,}>;
}

export type ICheckIsFirstDateService = ICheckIsFirstDate;

export const ICheckIsFirstDateServiceKey: InjectionKey<ICheckIsFirstDateService> = Symbol('IGeneralCheckFirstDateService');
