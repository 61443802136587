/* eslint-disable class-methods-use-this, max-len */

import { injectable } from 'inversify';
import { LegalEntitiesPredecessor } from '~/types/legal-entities/predecessor';
import { StringUtils } from '~/utils/stringUtils';
import { LegalEntitiesPredecessorDTO } from '~/types/dto/legalEntities/predecessorDTO';
import {
  ILegalEntitiesPredecessorAdapterService
} from '~/services/adapters/legalEntities/legalEntitiesAdapterService.interfaces';

@injectable()
export class LegalEntitiesPredecessorAdapterService implements ILegalEntitiesPredecessorAdapterService {
  getData(initData: LegalEntitiesPredecessor[]): LegalEntitiesPredecessorDTO[] {
    return (initData ?? []).map((item) => ({
      Ogrn: StringUtils.quoteReplacement(item.Ogrn ?? ''),
      OrganisationName: StringUtils.quoteReplacement(item.OrganisationName ?? ''),
      Date: item.Date ?? '',
      Inn: StringUtils.quoteReplacement(item.Inn ?? ''),
      Status: StringUtils.quoteReplacement(item.Status ?? ''),
    }));
  }
}
