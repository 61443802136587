import { injectable } from 'inversify';
import { IIndividualSpecialSignsAdapterService } from '~/services/adapters/individual/individualAdapterService.interfaces';
import { IndividualSpecialSigns } from '~/types/individual/specialSigns';
import { IndividualSpecialSignsDTO } from '~/types/dto/individual/individualSpecialSignsDTO';
import { StringUtils } from '~/utils/stringUtils';

@injectable()
export class IndividualSpecialSignsAdapterService implements IIndividualSpecialSignsAdapterService {
  getData(initData: IndividualSpecialSigns): IndividualSpecialSignsDTO {
    return {
      CriminalNickName: StringUtils.quoteReplacement(initData.CriminalNickName),
      SpecialSigns: StringUtils.quoteReplacement(initData.SpecialSigns)
    };
  }
}
