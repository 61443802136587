import { injectable } from 'inversify';
import { IPossibleBirthdayServiceConfig } from '~/modules/reportsCommon/reportPossible/individual/servises/possibleDataServiceConfig.interfaces';
import { Config } from '~/types/field-list/config';

@injectable()
export class PossibleBirthdayServiceConfig implements IPossibleBirthdayServiceConfig {
  getConfig(initData: Array<string>): Config {
    return {
      fieldsWidth: '100%',
      fieldsDirection: 'divider-row',
      fieldsValueWidth: '350px',
      isBeginningDivider: true,
      rowGap: '8px',
      fields: [
        {
          label: 'Другие возможные даты рождения',
          key: 'possibleBirthday',
          value: initData,
        },
      ]
    };
  }
}
