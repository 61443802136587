import { injectable } from 'inversify';
import { ILegalEntitiesRelatedEgrulRelationObjectService } from '~/modules/legalEntities/connection/legalEntitiesRelatedEgrul/services/legalEntitiesRelatedEgrulRelationObjectService.interface';
import { LegalEntitiesRelatedEgrulDTO } from '~/types/dto/legalEntities/relatedEgrulDTO';
import { PhoneUtils } from '~/utils/phone';

@injectable()
export class LegalEntitiesRelatedEgrulRelationObjectService implements ILegalEntitiesRelatedEgrulRelationObjectService {
  getRelationObject(initData: LegalEntitiesRelatedEgrulDTO): string {
    const { RelationType, RelationObject } = initData;

    if (RelationType === 'Телефон' && RelationType) return PhoneUtils.normalizePhoneNumber(RelationObject);
    if (RelationType === 'Адрес' && RelationObject) return RelationObject.charAt(0).toUpperCase() + RelationObject.slice(1);
    return RelationObject;
  }
}
