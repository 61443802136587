import { injectable } from 'inversify';
import { GenericDataBlobData } from '~/types/blobs/generic-data-blob-data';

import { ListDataBlobWrapperReportData } from '~/types/blobs/list-data-blob-wrapper';
import { IPlaceholderDataService } from '../common/reportPlaceholderService.interfaces';

@injectable()
export class DataBlobInputDataListPlaceholderService implements IPlaceholderDataService<ListDataBlobWrapperReportData<GenericDataBlobData>> {
  getData(): ListDataBlobWrapperReportData<GenericDataBlobData> {
    return {
      ReportInputDataList: []
    };
  }
}
