import { Event } from '~/services/event';
import { ReportGenerationTask } from '@/types/report/report';

const taskCreated = new Event<[ReportGenerationTask]>();
const tasksCreated = new Event<[ReportGenerationTask[]]>();

export function useTasksEvents() {
  return {
    taskCreated,
    tasksCreated
  };
}
