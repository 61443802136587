/* eslint-disable prefer-destructuring */
/* eslint-disable no-bitwise */
import { REGEX_PUNYCODE } from '~/regex/punycodeUri/regexPunycode';
import { REGEX_SEPARATOR } from '~/regex/punycodeUri/separatorRegex';

const maxInt = 2147483647; // aka. 0x7FFFFFFF or 2^31-1

const skew = 38;
const initialBias = 72;
const initialN = 128; 
const base = 36;
const damp = 700;
const tMin = 1;
const tMax = 26;
const delimiter = '-'; 
const baseMinusTMin = base - tMin;

const errors: { [key: string]: string } = {
  overflow: 'Overflow: input needs wider integers to process',
  'not-basic': 'Illegal input >= 0x80 (not a basic code point)',
  'invalid-input': 'Invalid input'
};

function error(type: string) {
  throw new RangeError(errors[type]);
}

function map(array: any[], callback: (string: string) => string) {
  const result = [];
  let { length } = array;
  while (length--) {
    result[length] = callback(array[length]);
  }
  return result;
}
function mapDomain(domain: string, callback: (string: string) => string) {
  const parts = domain.split('@');
  let result = '';
  if (parts.length > 1) {
    result = `${parts[0]}@`;
    domain = parts[1];
  }

  domain = domain.replace(REGEX_SEPARATOR, '\x2E');
  const labels = domain.split('.');
  const encoded = map(labels, callback).join('.');
  return result + encoded;
}

function basicToDigit(codePoint: number) {
  if (codePoint >= 0x30 && codePoint < 0x3A) {
    return 26 + (codePoint - 0x30);
  }
  if (codePoint >= 0x41 && codePoint < 0x5B) {
    return codePoint - 0x41;
  }
  if (codePoint >= 0x61 && codePoint < 0x7B) {
    return codePoint - 0x61;
  }
  return base;
}

function adapt(delta: number, numPoints: number, firstTime: boolean) {
  let k = 0;
  delta = firstTime ? Math.floor(delta / damp) : delta >> 1;
  delta += Math.floor(delta / numPoints);
  for (/* no initialization */; delta > baseMinusTMin * tMax >> 1; k += base) {
    delta = Math.floor(delta / baseMinusTMin);
  }
  return Math.floor(k + (baseMinusTMin + 1) * delta / (delta + skew));
}

export module punycodeUri{
    export function decode(input: string) {
      const output = [];
      const inputLength = input.length;
      let i = 0;
      let n = initialN;
      let bias = initialBias;
      let basic = input.lastIndexOf(delimiter);
      if (basic < 0) {
        basic = 0;
      }

      for (let j = 0; j < basic; ++j) {
        if (input.charCodeAt(j) >= 0x80) {
          error('not-basic');
        }
        output.push(input.charCodeAt(j));
      }
      for (let index = basic > 0 ? basic + 1 : 0; index < inputLength;) {
        const oldi = i;
        for (let w = 1, k = base; /* no condition */; k += base) {
          if (index >= inputLength) {
            error('invalid-input');
          }

          const digit = basicToDigit(input.charCodeAt(index++));

          if (digit >= base) {
            error('invalid-input');
          }
          if (digit > Math.floor((maxInt - i) / w)) {
            error('overflow');
          }

          i += digit * w;
          const t = k <= bias ? tMin : (k >= bias + tMax ? tMax : k - bias);

          if (digit < t) {
            break;
          }

          const baseMinusT = base - t;
          if (w > Math.floor(maxInt / baseMinusT)) {
            error('overflow');
          }

          w *= baseMinusT;
        }

        const out = output.length + 1;
        bias = adapt(i - oldi, out, oldi == 0);

        if (Math.floor(i / out) > maxInt - n) {
          error('overflow');
        }

        n += Math.floor(i / out);
        i %= out;

        output.splice(i++, 0, n);
      }

      return String.fromCodePoint(...output);
    }

    export function toUnicode(input: string) {
      return mapDomain(input, (string: string) => REGEX_PUNYCODE.test(string)
        ? decode(string.slice(4).toLowerCase())
        : string);
    }
}
