import { injectable, inject } from 'inversify';

import { ReportMarker } from '~/types/common/reportMarker';
import { INormalizationService } from './reportNormalizationService.interfaces';
import { IPrimitiveTypesNormalizationService, IPrimitiveTypesNormalizationServiceKey } from './primitiveTypesNormalizationService.interfaces';

@injectable()
export class ReportMarkerNormalizationService implements INormalizationService<ReportMarker> {
  constructor(
    @inject(IPrimitiveTypesNormalizationServiceKey as symbol) private primitiveTypesNormalizationService: IPrimitiveTypesNormalizationService,
  ) {}

  normalize(data: any, root: string): ReportMarker | undefined {
    const { normalizeString, normalizeObject } = this.primitiveTypesNormalizationService;

    return normalizeObject(data, (data) => ({
      Name: normalizeString(data.Name, `${root}.Name`),
    }), root);
  }
}
