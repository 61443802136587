import { inject, injectable } from 'inversify';
import { DateUtils } from '~/utils/date';
import { AviasUtils } from '~/utils/aviasUtils';
import { StringUtils } from '~/utils/stringUtils';

import { IndividualAviaPerson } from '~/types/individual/aviaPerson';
import { IndividualAviasDTO } from '~/types/dto/individual/aviasDTO';
import {
  IIndividualAviasAdapterService,
} from '~/services/adapters/individual/individualAdapterService.interfaces';
import {
  IReportArrayStringAdapterService,
  IReportArrayStringAdapterServiceKey
} from '~/services/adapters/common/reportAdapterService.interfeces';

@injectable()
export class IndividualAviasAdapterService implements IIndividualAviasAdapterService {
  constructor(
    @inject(IReportArrayStringAdapterServiceKey as symbol) private reportArrayStringAdapterService: IReportArrayStringAdapterService
  ) {
  }
  
  getData(initData: Array<IndividualAviaPerson>): Array<IndividualAviasDTO> {
    return (initData ?? []).map((item) => ({
      FlightDate: DateUtils.formatDate(item.FlightDate),
      SourceFlightDate: item.FlightDate ?? '',
      FlightStartTime: AviasUtils.formatFlightStartTime(item.FlightStartTime),
      From: StringUtils.quoteReplacement(item.From ?? ''),
      Airline: StringUtils.quoteReplacement(item.Airline ?? ''),
      AirlineCode: item.AirlineCode ?? '',
      FlightNumber: item.FlightNumber
        ? item.FlightNumber.replaceAll(';;', ';<br>').replaceAll(';', ';<br>')
        : '',
      To: StringUtils.quoteReplacement(item.To ?? ''),
      Companions: this.reportArrayStringAdapterService.getData(item.Companions),
      AccompanyingPersons: this.reportArrayStringAdapterService.getData(item.AccompanyingPersons),
      FullName: item.FullName ?? '',
      Passport: item.Passport ?? ''
    }));
  }
}
