import { injectable, inject } from 'inversify';

import { IPrimitiveTypesNormalizationServiceKey, IPrimitiveTypesNormalizationService } from './primitiveTypesNormalizationService.interfaces';
import { ReportLegalEntitiesParticipation } from '~/types/common/reportLegalEntitiesParticipation';
import { INormalizationService } from './reportNormalizationService.interfaces';

@injectable()
export class ReportLegalEntitiesParticipationNormalizationService implements INormalizationService<ReportLegalEntitiesParticipation> {
  constructor(
    @inject(IPrimitiveTypesNormalizationServiceKey as symbol) private primitiveTypesNormalizationService: IPrimitiveTypesNormalizationService,
  ) {}

  normalize(data: any, root: string): ReportLegalEntitiesParticipation | undefined {
    const {
      normalizeOptionalString,
      normalizeOptionalInteger,
      normalizeOptionalBoolean,
      normalizeObject,
    } = this.primitiveTypesNormalizationService;

    return normalizeObject(data, (data) => ({
      ParticipationType: normalizeOptionalString(data.ParticipationType, `${root}.ParticipationType`),
      Shortname: normalizeOptionalString(data.Shortname, `${root}.Shortname`),
      CompanyName: normalizeOptionalString(data.CompanyName, `${root}.CompanyName`),
      Inn: normalizeOptionalString(data.Inn, `${root}.Inn`),
      Ogrn: normalizeOptionalString(data.Ogrn, `${root}.Ogrn`),
      Status: normalizeOptionalString(data.Status, `${root}.Status`),
      PrincipalActivity: normalizeOptionalString(data.PrincipalActivity, `${root}.PrincipalActivity`),
      RiskIndicator: normalizeOptionalString(data.RiskIndicator, `${root}.RiskIndicator`),
      Address: normalizeOptionalString(data.Address, `${root}.Address`),
      RegistrationDate: normalizeOptionalString(data.RegistrationDate, `${root}.RegistrationDate`),
      LiquidationDate: normalizeOptionalString(data.LiquidationDate, `${root}.LiquidationDate`),
      CapitalSharesPercent: normalizeOptionalString(data.CapitalSharesPercent, `${root}.CapitalSharesPercent`),
      AuthorizedCapitalSum: normalizeOptionalInteger(data.AuthorizedCapitalSum, `${root}.AuthorizedCapitalSum`),
      IsHistoric: normalizeOptionalBoolean(data.IsHistoric, `${root}.IsHistoric`),
      Position: normalizeOptionalString(data.Position, `${root}.Position`),
    }), root);
  }
}
