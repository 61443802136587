import { injectable } from 'inversify';
import { clone, isEqual } from 'lodash-es';
import { MergeService } from '~/services/merge/merge-service';
import { ReportEmailDTO } from '~/types/dto/common/emailDTO';

@injectable()
export class CommonEmailsMergeService extends MergeService<ReportEmailDTO> {
  protected canMerge(leftItem: ReportEmailDTO, rightItem: ReportEmailDTO): boolean {
    const leftEmailItem = clone(leftItem);
    leftEmailItem.Value = leftItem.Value.toLowerCase();
    const rightEmailItem = clone(rightItem);
    rightEmailItem.Value = rightItem.Value.toLowerCase();
    return isEqual(leftEmailItem, rightEmailItem);
  }

  protected mergeFields(leftItem: ReportEmailDTO, rightItem: ReportEmailDTO): ReportEmailDTO {
    return {
      Value: rightItem.Value || leftItem.Value,
      VerificationCode: rightItem.VerificationCode || leftItem.VerificationCode,
      Type: rightItem.Type || leftItem.Type,
      IsSuitableForSending: rightItem.IsSuitableForSending || leftItem.IsSuitableForSending
    };
  }
}
