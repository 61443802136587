import { injectable, inject } from 'inversify';
import { LegalEntitiesLicenseSummary } from '~/types/legal-entities/licenseSummary';
import { IPrimitiveTypesNormalizationServiceKey, IPrimitiveTypesNormalizationService } from '../common/primitiveTypesNormalizationService.interfaces';
import { INormalizationService } from '../common/reportNormalizationService.interfaces';

@injectable()
export class LegalEntitiesLicenseSummaryNormalizationService implements INormalizationService<LegalEntitiesLicenseSummary> {
  constructor(
    @inject(IPrimitiveTypesNormalizationServiceKey as symbol) private primitiveTypesNormalizationService: IPrimitiveTypesNormalizationService,
  ) {}

  normalize(data: any, root: string): LegalEntitiesLicenseSummary | undefined {
    const { normalizeString, normalizeNumber, normalizeObject } = this.primitiveTypesNormalizationService;

    return normalizeObject(data, (data) => ({
      Activity: normalizeString(data.Activity, `${root}.Activity`),
      ArchiveCount: normalizeNumber(data.ArchiveCount, `${root}.Activity`),
      ActualCount: normalizeNumber(data.ActualCount, `${root}.Activity`),
    }), root);
  }
}
