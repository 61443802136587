import { injectable } from 'inversify';
import { IndividualType } from '~/types/individual/type';
import { IPlaceholderDataService } from '../common/reportPlaceholderService.interfaces';

@injectable()
export class IndividualTypePlaceholderService implements IPlaceholderDataService<IndividualType> {
  getData(): IndividualType {
    return {
      Type: ''
    };
  }
}
