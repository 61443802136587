import { injectable } from 'inversify';
import { LegalEntitiesCompanyManagementCompaniesDTO } from '~/types/dto/legalEntities/companyManagementCompaniesDTO';
import { Config } from '~/types/field-list/config';
import { ILegalEntitiesGeneralInformationManagementCompanyConfigService } from '~/modules/legalEntities/commonInfo/legalEntitiesGeneralInformation/services/legalEntitiesGeneralInformationConfigServices.interfaces';

@injectable()
export class LegalEntitiesGeneralInformationManagementCompanyConfigService implements ILegalEntitiesGeneralInformationManagementCompanyConfigService {
  getConfig(initData: LegalEntitiesCompanyManagementCompaniesDTO): Config {
    return {
      fieldsDirection: 'column',
      fieldsWidth: '100%',
      fieldsValueWidth: '100%',
      fields: [
        {
          label: 'Управляющая компания',
          key: 'name',
          value: initData.Name,
          isInner: true,
        }
      ]
    };
  }
}
