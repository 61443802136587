<template>
  <circle cx="12" cy="12" r="11" fill="url(#paint0_linear_3073_5779)" />
  <path d="M16.9891 7.94976C17.0904 7.31689 16.4681 6.81736 15.8862 7.06443L4.29641 11.9852C3.87912 12.1624 3.90965 12.7736 4.34244 12.9069L6.73256 13.643C7.18872 13.7834 7.68268 13.7108 8.08101 13.4447L13.4697 9.8445C13.6322 9.73593 13.8093 9.95936 13.6704 10.0978L9.79159 13.9651C9.41532 14.3402 9.49001 14.9759 9.9426 15.2504L14.2854 17.8839C14.7725 18.1793 15.3991 17.8826 15.4902 17.3134L16.9891 7.94976Z" fill="white" />
  <defs>
    <linearGradient
      id="paint0_linear_3073_5779"
      x1="12"
      y1="1"
      x2="12"
      y2="23"
      gradientUnits="userSpaceOnUse"
    >
      <stop stop-color="#37BBFE" />
      <stop offset="1" stop-color="#007DBB" />
    </linearGradient>
  </defs>
</template>
