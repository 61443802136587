import { get } from 'lodash-es';
import { injectable, inject } from 'inversify';
import { ILegalEntitiesFinancialAnalysisShortTableConfigService } from '~/modules/legalEntities/financialAnalytics/legalEntitiesFinancialAnalysisShort/services/legalEntitiesFinancialAnalysisShortTableConfigService.interfaces';
import { LegalEntitiesFinancialAnalysisShortDTO } from '~/types/dto/legalEntities/financialAnalysisShort';
import { TableHeader } from '~/modules/table/types/tableHeader';
import { TableItem } from '~/types/tableItem';
import {
  IDebtEbitTableItemConfigService,
  IDebtEbitTableItemConfigServiceKey,
  IDebtShareTableItemConfigService,
  IDebtShareTableItemConfigServiceKey,
  IEbitPercentTableItemConfigService,
  IEbitPercentTableItemConfigServiceKey,
  IEbitTableItemConfigService,
  IEbitTableItemConfigServiceKey,
  IProfitFromSalesTableItemConfigService,
  IProfitFromSalesTableItemConfigServiceKey,
  IRevenuesTableItemConfigService,
  IRevenuesTableItemConfigServiceKey
} from '~/modules/legalEntities/financialAnalytics/legalEntitiesFinancialAnalysisShort/services/legalEntitiesFinancialAnalysisShortTableItemConfigService.interfaces';

@injectable()
export class LegalEntitiesFinancialAnalysisShortTableConfigService implements ILegalEntitiesFinancialAnalysisShortTableConfigService {
  constructor(
    @inject(IRevenuesTableItemConfigServiceKey as symbol) private revenuesTableItemConfigService: IRevenuesTableItemConfigService,
    @inject(IProfitFromSalesTableItemConfigServiceKey as symbol) private profitFromSalesTableItemConfigService: IProfitFromSalesTableItemConfigService,
    @inject(IEbitTableItemConfigServiceKey as symbol) private ebitTableItemConfigService: IEbitTableItemConfigService,
    @inject(IDebtShareTableItemConfigServiceKey as symbol) private debtShareTableItemConfigService: IDebtShareTableItemConfigService,
    @inject(IDebtEbitTableItemConfigServiceKey as symbol) private debtEbitTableItemConfigService: IDebtEbitTableItemConfigService,
    @inject(IEbitPercentTableItemConfigServiceKey as symbol) private ebitPercentTableItemConfigService: IEbitPercentTableItemConfigService

  ) {}

  getHeaders(initData: Array<LegalEntitiesFinancialAnalysisShortDTO>): Array<TableHeader> {
    return [{
      label: 'Показатель',
      key: 'Indicator',
      width: '356px',
    },
    {
      label: String(get(initData, '0.TwoYearsAgo', '')),
      key: 'TwoYearsAgo',
      width: '116px',
    },
    {
      label: String(get(initData, '0.LastYear', '')),
      key: 'LastYear',
      width: '156px',
    },
    {
      label: String(
        `${String(get(initData, '0.LastYear', ''))}/${String(get(initData, '0.TwoYearsAgo', ''))}`,
      ),
      key: 'TwoYearsAgoToLastYear',
      width: '144px',
    },
    {
      label: String(get(initData, '0.CurrentYear', '')),
      key: 'CurrentYear',
      width: '116px',
    },
    {
      label: String(
        `${String(get(initData, '0.CurrentYear', ''))}/${String(get(initData, '0.LastYear', ''))}`,
      ),
      key: 'LastYearToCurrentYear',
      width: '88px',
    }];
  }

  getItems(initData: Array<LegalEntitiesFinancialAnalysisShortDTO>): Array<TableItem> {
    return initData.map((item) => [
      this.revenuesTableItemConfigService.getItem(item.Revenues),
      this.profitFromSalesTableItemConfigService.getItem(item.ProfitFromSales),
      this.ebitTableItemConfigService.getItem(item.Ebit),
      this.debtShareTableItemConfigService.getItem(item.DebtShare),
      this.debtEbitTableItemConfigService.getItem(item.DebtEbit),
      this.ebitPercentTableItemConfigService.getItem(item.EbitPercent),
    ]);
  }
}
