import { injectable } from 'inversify';

import { ReportSocialNetworkTrack } from '~/types/common/reportSocialNetworkTrack';
import { IPlaceholderDataService } from './reportPlaceholderService.interfaces';

@injectable()
export class ReportSocialNetworkTrackPlaceholderService implements IPlaceholderDataService<ReportSocialNetworkTrack> {
  getData(): ReportSocialNetworkTrack {
    return {
      SocialNetworkSchools: [],
      SocialNetworkSecondaryEducations: [],
      SocialNetworkHighEducations: [],
      SocialNetworkMilitaryRecords: [],
      SocialNetworkJobs: [],
      IsExists: false
    };
  }
}
