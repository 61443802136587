/* eslint-disable class-methods-use-this, max-len */
import { injectable } from 'inversify';
import { StringUtils } from '~/utils/stringUtils';
import { LegalEntitiesTaxServiceInfo } from '~/types/legal-entities/taxServiceInfo';
import {
  ILegalEntitiesTaxServiceInfoAdapterService
} from '~/services/adapters/legalEntities/legalEntitiesAdapterService.interfaces';
import { LegalEntitiesTaxServiceInfoDTO } from '~/types/dto/legalEntities/taxServiceInfoDTO';

@injectable()
export class LegalEntitiesTaxServiceInfoAdapterService implements ILegalEntitiesTaxServiceInfoAdapterService {
  getData(initData: LegalEntitiesTaxServiceInfo): LegalEntitiesTaxServiceInfoDTO {
    return {
      ServiceCode: StringUtils.quoteReplacement(initData.ServiceCode),
      ServiceName: StringUtils.quoteReplacement(initData.ServiceName),
      RegistrationDate: initData.RegistrationDate ?? ''
    };
  }
}
