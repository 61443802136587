import { injectable } from 'inversify';
import { ReportPhotosUrl } from '~/types/common/reportVehiclePhotosUrl';
import { IPlaceholderDataService } from './reportPlaceholderService.interfaces';

@injectable()
export class ReportPhotosUrlPlaceholderService implements IPlaceholderDataService<ReportPhotosUrl> {
  getData(): ReportPhotosUrl {
    return {
      Text: '',
      Value: '',
    };
  }
}
