import { injectable } from 'inversify';
import { ILegalEntitiesClaimsAndDebtsConfigService } from '~/modules/legalEntities/commonInfo/legalEntitiesClaimsAndDebts/services/legalEntitiesClaimsAndDebtsConfigService.interface';
import { LegalEntitiesBriefInfoDTO } from '~/types/dto/legalEntities/briefInfo';
import { Config } from '~/types/field-list/config';
import { NumberUtils } from '~/utils/number';

@injectable()
export class LegalEntitiesClaimsAndDebtsConfigService implements ILegalEntitiesClaimsAndDebtsConfigService {
  getConfig(initData: LegalEntitiesBriefInfoDTO): Config {
    const iscClaims = initData.IscClaims
      ? NumberUtils.formatNumberDigits(initData.IscClaims)
      : '';
    const iscObligation = initData.IscObligation
      ? NumberUtils.formatNumberDigits(initData.IscObligation)
      : '';
    const fsspDept = initData.FsspDept
      ? NumberUtils.formatNumberDigits(initData.FsspDept)
      : '';

    return {
      fieldsDirection: 'divider-row',
      fieldsWidth: '100%',
      fieldsValueWidth: '350px',
      isBeginningDivider: true,
      rowGap: '20px',
      fields: [
        {
          label: 'Исковые требования, \u{20BD}',
          key: 'IscClaims',
          value: iscClaims,
        },
        {
          label: 'Исковые обязательства, \u{20BD}',
          key: 'IscObligation',
          value: iscObligation,
        },
        {
          label: 'Задолженность перед ФССП, \u{20BD}',
          key: 'FsspDept',
          value: fsspDept,
        },
      ]
    };
  }
}
