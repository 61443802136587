import { ReportSocialNetworkType } from '~/types/common/reportSocialNetworkType';

export module Constants {

  export const SOCIAL_LINKS = {
    [ReportSocialNetworkType.Fb]: 'https://facebook.com',
    [ReportSocialNetworkType.Instagram]: 'https://instagram.com',
    [ReportSocialNetworkType.Ok]: 'https://ok.ru/profile',
    [ReportSocialNetworkType.Skype]: 'https://skype.com',
    [ReportSocialNetworkType.Telegram]: 'https://t.me',
    [ReportSocialNetworkType.TikTok]: 'https://tiktok.com',
    [ReportSocialNetworkType.Twitter]: 'https://twitter.com',
    [ReportSocialNetworkType.Unknown]: '',
    [ReportSocialNetworkType.Viber]: 'https://viber.com',
    [ReportSocialNetworkType.Vk]: 'https://vk.com',
    [ReportSocialNetworkType.WhatsApp]: 'https://wa.me',
  };

  export const NOT_AVAILABLE = 'нет данных';

  export const COPY_DURING_TIME = 2000;

  export const CLIENT_APP_DOMAIN = import.meta.env.VITE_CLIENT_APP_DOMAIN ?? 'https://client.easy-reports.phobos.sweep-net.ru';

  export const SCREEN_SIZE_WIDTH = {
    screenWidth1024: 1024,
    screenWidth1280: 1280,
    screenWidth1366: 1366,
    screenWidth1440: 1440,
    screenWidth1600: 1600,
    screenWidth1920: 1920
  };

  export const POSTFIX_QUALIFIERS = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: ' тыс.' },
    { value: 1e6, symbol: ' млн.' },
    // { value: 10e7, symbol: ' млн.' },
    // { value: 100e8, symbol: ' млн.' },
    { value: 1e9, symbol: ' млрд.' },
    // { value: 10e10, symbol: ' млрд.' },
    { value: 1e12, symbol: ' трл.' },
    { value: 1e15, symbol: ' квадрлн.' },
    { value: 1e18, symbol: ' квинтлн.' }
  ];

  export const HTTP_STATUS_CODES = {
    HTTP_STATUS_CODE_401: 401,
  };
}
