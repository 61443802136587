import { injectable } from 'inversify';

import { ReportMainEntityFile } from '~/types/common/reportMainEntityFile';
import { IPlaceholderDataService } from './reportPlaceholderService.interfaces';

@injectable()
export class ReportMainEntityFilePlaceholderService implements IPlaceholderDataService<ReportMainEntityFile> {
  getData(): ReportMainEntityFile {
    return {
      FileData: '',
      DataLength: 0,
      FileHash: '',
      FileUrl: '',
      Filename: '',
      FilenameFull: '',
      Extension: '',
      Comment: '',
      PostDate: '',
      IsExists: false
    };
  }
}
