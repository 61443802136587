import { injectable } from 'inversify';
import { IIndividualLegalEntitiesRestrictionsConfigService } from './IndividualLegalEntitiesRestrictionsConfigService.interface';
import { Config } from '~/types/field-list/config';
import { IndividualLegalEntitiesParticipationRestrictionDTO } from '~/types/dto/individual/legalEntitiesParticipationRestrictionDTO';

@injectable()
export class IndividualLegalEntitiesRestrictionsConfigService implements IIndividualLegalEntitiesRestrictionsConfigService {
  getConfig(item: IndividualLegalEntitiesParticipationRestrictionDTO): Config {
    return {
      fieldsDirection: 'divider-row',
      fieldsWidth: '100%',
      fieldsValueWidth: '350px',
      fields: [
        {
          label: 'Юридическое лицо, в связи с причастностью к которому возникли ограничения',
          key: 'CompanyName',
          value: {
            text: item.CompanyName,
            width: '100%'
          },
          direction: 'column',
        },
        {
          label: 'ИНН',
          key: 'Inn', 
          value: item.Inn 
        },
        {
          label: 'Дата начала действия ограничения',
          key: 'RegistrationDate',
          value: item.RegistrationDate 
        },
        {
          label: 'Дата окончания действия ограничения',
          key: 'LiquidationDate',
          value: item.LiquidationDate 
        },
        {
          label: 'Причина ограничения',
          key: 'PrincipalActivity',
          value: {
            text: item.PrincipalActivity,
            width: '100%'
          },
          direction: 'column',
        },
      ]
    };
  }
}
