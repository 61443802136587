import { IContainerBinder } from '~/configuration/inversify/containerAccessor.interface';
import { OperatingCashFlowTableItemConfig } from '~/modules/legalEntities/financialAnalytics/legalEntitiesCashFlowPerformance/services/operatingCashFlowTableItemConfig';
import {
  ICashFlowAvailableForDebtServiceTableItemConfigKey,
  IDebtServiceCoverageRatioTableItemConfigKey,
  IFinancialCashFlowTableItemConfigKey,
  IInvestmentCashFlowTableItemConfigKey,
  IOperatingCashFlowTableItemConfigKey
} from '~/modules/legalEntities/financialAnalytics/legalEntitiesCashFlowPerformance/services/legalEntitiesCashFlowPerformanceTableItemConfig.interfaces';
import { CashFlowAvailableForDebtServiceTableItemConfig } from '~/modules/legalEntities/financialAnalytics/legalEntitiesCashFlowPerformance/services/cashFlowAvailableForDebtServiceTableItemConfig';
import { InvestmentCashFlowTableItemConfig } from '~/modules/legalEntities/financialAnalytics/legalEntitiesCashFlowPerformance/services/investmentCashFlowTableItemConfig';
import { FinancialCashFlowTableItemConfig } from '~/modules/legalEntities/financialAnalytics/legalEntitiesCashFlowPerformance/services/financialCashFlowTableItemConfig';
import { DebtServiceCoverageRatioTableItemConfig } from '~/modules/legalEntities/financialAnalytics/legalEntitiesCashFlowPerformance/services/debtServiceCoverageRatioTableItemConfig';
import { LegalEntitiesCashFlowPerformanceTableConfigService } from '~/modules/legalEntities/financialAnalytics/legalEntitiesCashFlowPerformance/services/legalEntitiesCashFlowPerformanceTableConfigService';
import { ILegalEntitiesCashFlowPerformanceTableConfigServiceKey } from '~/modules/legalEntities/financialAnalytics/legalEntitiesCashFlowPerformance/services/legalEntitiesCashFlowPerformanceTableConfigService.interface';

export function bindLegalEntitiesCashFlowPerformanceServices(bind: IContainerBinder) {
  bind(IOperatingCashFlowTableItemConfigKey).to(OperatingCashFlowTableItemConfig);
  bind(ICashFlowAvailableForDebtServiceTableItemConfigKey).to(CashFlowAvailableForDebtServiceTableItemConfig);
  bind(IInvestmentCashFlowTableItemConfigKey).to(InvestmentCashFlowTableItemConfig);
  bind(IFinancialCashFlowTableItemConfigKey).to(FinancialCashFlowTableItemConfig);
  bind(IDebtServiceCoverageRatioTableItemConfigKey).to(DebtServiceCoverageRatioTableItemConfig);

  bind(ILegalEntitiesCashFlowPerformanceTableConfigServiceKey).to(LegalEntitiesCashFlowPerformanceTableConfigService);
}
