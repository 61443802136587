import { injectable } from 'inversify';
import { IGraphService } from '~/services/graphService.interfaces';
import { appSettings } from '~/settings';

@injectable()
export default class GraphService implements IGraphService {
  getGraphUrl(graphId: string | undefined): string | undefined {
    return graphId != undefined
      ? `${appSettings.graphBaseURL}/graph-visualization/${graphId}`
      : undefined;
  }
}
