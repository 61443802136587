import { injectable, inject } from 'inversify';
import { IReportMovementCovidPlaceholderServiceKey, IPlaceholderDataService } from '~/services/placeholder/common/reportPlaceholderService.interfaces';
import { ReportMovementCovid } from '~/types/common/reportMovementCovid';
import { ReportMovementCovidForVehicle } from '~/types/common/reportMovementCovidForVehicle';
import { IPrimitiveTypesNormalizationServiceKey, IPrimitiveTypesNormalizationService } from './primitiveTypesNormalizationService.interfaces';
import { INormalizationService, IReportMovementCovidNormalizationServiceKey } from './reportNormalizationService.interfaces';

@injectable()
export class ReportMovementCovidForVehicleNormalizationService implements INormalizationService<ReportMovementCovidForVehicle> {
  constructor(
    @inject(IReportMovementCovidNormalizationServiceKey as symbol) private reportMovementCovidNormalizationService: INormalizationService<ReportMovementCovid>,

    @inject(IReportMovementCovidPlaceholderServiceKey as symbol) private reportMovementCovidPlaceholderService: IPlaceholderDataService<ReportMovementCovid>,

    @inject(IPrimitiveTypesNormalizationServiceKey as symbol) private primitiveTypesNormalizationService: IPrimitiveTypesNormalizationService,
  ) {}

  normalize(data: any, root: string): ReportMovementCovidForVehicle | undefined {
    const { normalizeObject, normalizeOptionalString } = this.primitiveTypesNormalizationService;
    const extendsData = this.reportMovementCovidNormalizationService.normalize(data, root) ?? this.reportMovementCovidPlaceholderService.getData();

    return normalizeObject(data, (data) => ({
      ...extendsData,
      FullName: normalizeOptionalString(data.FullName, `${root}.FullName`),
      Birthday: normalizeOptionalString(data.Birthday, `${root}.Birthday`),
      Inn: normalizeOptionalString(data.Inn, `${root}.Inn`),
      Passport: normalizeOptionalString(data.Passport, `${root}.Passport`),
      Email: normalizeOptionalString(data.Email, `${root}.Email`),
      PhoneNumber: normalizeOptionalString(data.PhoneNumber, `${root}.PhoneNumber`),
    }), root);
  }
}
