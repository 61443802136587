import { injectable, inject } from 'inversify';
import { LegalEntitiesCompanyHistoryAddress } from '~/types/legal-entities/companyHistoryAddress';
import { IPrimitiveTypesNormalizationServiceKey, IPrimitiveTypesNormalizationService } from '../common/primitiveTypesNormalizationService.interfaces';
import { INormalizationService, IStringArrayNormalizationServiceKey } from '../common/reportNormalizationService.interfaces';

@injectable()
export class LegalEntitiesCompanyHistoryAddressNormalizationService implements INormalizationService<LegalEntitiesCompanyHistoryAddress> {
  constructor(
    @inject(IStringArrayNormalizationServiceKey as symbol) private stringArrayNormalizationService: INormalizationService<string>,

    @inject(IPrimitiveTypesNormalizationServiceKey as symbol) private primitiveTypesNormalizationService: IPrimitiveTypesNormalizationService,
  ) {}

  normalize(data: any, root: string): LegalEntitiesCompanyHistoryAddress | undefined {
    const {
      normalizeString,
      normalizeOptionalString,
      normalizeOptionalBoolean,
      normalizeArray,
      normalizeObject
    } = this.primitiveTypesNormalizationService;

    return normalizeObject(data, (data) => ({
      Address: normalizeString(data.Address, `${root}.Address`),
      DateStart: normalizeOptionalString(data.DateStart, `${root}.DateStart`),
      DateEnd: normalizeOptionalString(data.DateEnd, `${root}.DateEnd`),
      CompanyStatus: normalizeArray(data.CompanyStatus, (item, field) => this.stringArrayNormalizationService.normalize(item, field), `${root}.CompanyStatus`),
      UnreliabilityCode: normalizeString(data.UnreliabilityCode, `${root}.UnreliabilityCode`),
      UnreliabilityDate: normalizeOptionalString(data.UnreliabilityDate, `${root}.UnreliabilityDate`),
      IsRegionChanged: normalizeOptionalBoolean(data.IsRegionChanged, `${root}.IsRegionChanged`),
    }), root);
  }
}
