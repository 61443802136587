import { InjectionKey } from 'vue';
import { ElementsExtractor } from '~/types/nodes/elementsExtractor';
import { NodeLayoutSnapshot } from '~/models/nodes/snapshots/nodeLayoutSnapshot';

export interface INodeLayoutSnapshotsServiceBuildSnapshotOptions {
  root?: HTMLElement
  elementsExtractor: ElementsExtractor
}

export interface INodeLayoutSnapshotsService {
  buildSnapshot(options: INodeLayoutSnapshotsServiceBuildSnapshotOptions): NodeLayoutSnapshot
}

export const INodeLayoutSnapshotsServiceKey: InjectionKey<INodeLayoutSnapshotsService> = Symbol('INodeLayoutSnapshotsService');
