import { injectable } from 'inversify';
import { IIndividualMilitaryRecordAdapterService } from '~/services/adapters/individual/individualAdapterService.interfaces';
import { IndividualMilitaryRecord } from '~/types/individual/militaryRecord';
import { IndividualMilitaryRecordDTO } from '~/types/dto/individual/militaryRecordDTO';
import { StringUtils } from '~/utils/stringUtils';

@injectable()
export class IndividualMilitaryRecordAdapterService implements IIndividualMilitaryRecordAdapterService {
  getData(initData: IndividualMilitaryRecord[]): IndividualMilitaryRecordDTO[] {
    return (initData ?? []).map((item) => ({
      IsMilitaryDuty: item.IsMilitaryDuty ?? false,
      Series: StringUtils.quoteReplacement(item.Series),
      Number: StringUtils.quoteReplacement(item.Number),
      StockCategory: StringUtils.quoteReplacement(item.StockCategory),
      MilitaryRank: StringUtils.quoteReplacement(item.MilitaryRank),
      MilitaryPersonnel: StringUtils.quoteReplacement(item.MilitaryPersonnel),
      MilitarySpecialty: StringUtils.quoteReplacement(item.MilitarySpecialty),
      MilitaryShelfLife: StringUtils.quoteReplacement(item.MilitaryShelfLife),
      RecruitmentOffice: StringUtils.quoteReplacement(item.RecruitmentOffice),
      MilitaryRegistration: StringUtils.quoteReplacement(item.MilitaryRegistration),
      HasMobilizationOrder: item.HasMobilizationOrder ?? false,
      PartyTeamNumber: StringUtils.quoteReplacement(item.PartyTeamNumber),
      BookingOrganization: StringUtils.quoteReplacement(item.BookingOrganization)
    }));
  }
}
