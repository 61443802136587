import { injectable, inject } from 'inversify';
import {
  IReportColleagueAdapterService, IReportSocialNetworkIdAdapterService,
  IReportSocialNetworkIdAdapterServiceKey
} from '~/services/adapters/common/reportAdapterService.interfeces';
import { ReportColleague } from '~/types/common/reportColleague';
import { ReportColleagueDTO } from '~/types/dto/common/reportColleagueDTO';
import { StringUtils } from '~/utils/stringUtils';
import { DateUtils } from '~/utils/date';

@injectable()
export class ReportColleagueAdapterService implements IReportColleagueAdapterService {
  constructor(
    @inject(IReportSocialNetworkIdAdapterServiceKey as symbol) private reportSocialNetworkIdAdapterService: IReportSocialNetworkIdAdapterService
  ) {}
  getData(initData: ReportColleague[]): ReportColleagueDTO[] {
    return (initData ?? []).map((item) => ({
      Fullname: StringUtils.quoteReplacement(item.Fullname),
      Birthday: DateUtils.formatDate(item.Birthday),
      SocialNetworks: this.reportSocialNetworkIdAdapterService.getData(item.SocialNetworks)
    }));
  }
}
