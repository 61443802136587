import { get } from 'lodash-es'
import { CommonUtils } from "./common";

export module ListUtils {
  export function compareLists<T>(left: T[], right: T[], comparator?: (left: T, right: T) => boolean) {
    const defaultedComparator = comparator ?? ((l, r) => l === r);
    return left.length == right.length && left.every((leftItem, idx) => defaultedComparator(leftItem, right[idx]));
  }

  export function duplicateList<T>(list: T[], amount: number) {
    const result = new Array<T>();

    for (let i = 0; i < amount; i++) { result.push(...list); }

    return result;
  }

  export function isNotEmpty<T>(arr: T[]) {
    return arr && arr.length > 0;
  }

  export function buildMap<T, K>(list: T[], mapKeyFn: (item: T) => K): Map<K, T[]> {
    const mappedList = new Map<K, T[]>();

    list.forEach((item) => {
      const key = mapKeyFn(item);

      if (mappedList.get(key) === undefined) {
        mappedList.set(key, []);
      }

      mappedList.get(key)?.push(item);
    });

    return mappedList;
  }

  export async function someAsync<T>(list: T[], predicate: (item: T) => Promise<boolean>) {
    for (const item of list) {
      if (await predicate(item))
        return true;
    }

    return false;
  }

  export function sliceList<T>(list: T[], endIndex: number = 3) { 
    return list.slice(0, endIndex);
  }

  export function getRandomElements<T>(list: T[], count: number): T[] {
    const oldList = [...list];
    const newList: T[] = [];
    
    for (let i = 0; i < count && oldList.length > 0; ++i) {
      const randomIndex = CommonUtils.getRandomNumber(0, oldList.length - 1);
      
      const [element] = oldList.splice(randomIndex, 1);

      newList.push(element);
    }

    return newList;
  }
}
