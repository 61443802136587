import { injectable } from 'inversify';
import { ReportVehicleRisks } from '~/types/common/reportVehicleRisks';
import { IPlaceholderDataService } from './reportPlaceholderService.interfaces';

@injectable()
export class ReportVehicleRisksPlaceholderService implements IPlaceholderDataService<ReportVehicleRisks> {
  getData(): ReportVehicleRisks {
    return {
      HasRoadAccidents: false,
      IsInPledgesRegistry: false,
      HasRestricts: false,
      HasCanceledFines: false,
      HasActiveFines: false,
    };
  }
}
