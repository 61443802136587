import { injectable } from 'inversify';

import { LegalEntitiesFineAndTaxDebt } from '~/types/legal-entities/fineAndTaxDebt';
import { IPlaceholderDataService } from '../common/reportPlaceholderService.interfaces';

@injectable()
export class LegalEntitiesFineAndTaxDebtPlaceholderDataService implements IPlaceholderDataService<LegalEntitiesFineAndTaxDebt> {
  getData(): LegalEntitiesFineAndTaxDebt {
    return {
      Name: '',
      Sum: 0,
      Year: 0,
    };
  }
}
