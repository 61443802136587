import { IContainerBinder } from '~/configuration/inversify/containerAccessor.interface';
import {
  ILegalEntitiesAuthorizedCapitalConfigServiceKey
} from '~/modules/legalEntities/changeHistory/legalEntitiesAuthorizedCapital/services/legalEntitiesAuthorizedCapitalConfigService.interfaces';
import {
  LegalEntitiesAuthorizedCapitalConfigService
} from '~/modules/legalEntities/changeHistory/legalEntitiesAuthorizedCapital/services/legalEntitiesAuthorizedCapitalConfigService';
import {
  ILegalEntitiesAuthorizedCapitalServiceKey
} from '~/modules/legalEntities/changeHistory/legalEntitiesAuthorizedCapital/services/legalEntitiesAuthorizedCapitalService.interfaces';
import {
  LegalEntitiesAuthorizedCapitalService
} from '~/modules/legalEntities/changeHistory/legalEntitiesAuthorizedCapital/services/legalEntitiesAuthorizedCapitalService';
import {
  ILegalEntitiesAuthorizedCapitalLastIndexServiceKey
} from '~/modules/legalEntities/changeHistory/legalEntitiesAuthorizedCapital/services/legalEntitiesAuthorizedCapitalLastIndexService.interfaces';
import {
  ILegalEntitiesAuthorizedCapitalSortServiceKey
} from '~/modules/legalEntities/changeHistory/legalEntitiesAuthorizedCapital/services/legalEntitiesAuthorizedCapitalSortService.interfaces';
import {
  LegalEntitiesAuthorizedCapitalLastIndexService
} from '~/modules/legalEntities/changeHistory/legalEntitiesAuthorizedCapital/services/legalEntitiesAuthorizedCapitalLastIndexService';
import {
  LegalEntitiesAuthorizedCapitalSortService
} from '~/modules/legalEntities/changeHistory/legalEntitiesAuthorizedCapital/services/legalEntitiesAuthorizedCapitalSortService';

export function bindLegalEntitiesAuthorizedCapitalServices(bind: IContainerBinder) {
  bind(ILegalEntitiesAuthorizedCapitalConfigServiceKey).to(LegalEntitiesAuthorizedCapitalConfigService);
  bind(ILegalEntitiesAuthorizedCapitalServiceKey).to(LegalEntitiesAuthorizedCapitalService);
  bind(ILegalEntitiesAuthorizedCapitalLastIndexServiceKey).to(LegalEntitiesAuthorizedCapitalLastIndexService);
  bind(ILegalEntitiesAuthorizedCapitalSortServiceKey).to(LegalEntitiesAuthorizedCapitalSortService);
}
