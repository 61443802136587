import { InjectionKey } from 'vue';
import { IndividualDataBlobData } from '~/types/blobs/individual-data-blob-data';
import { IndividualAccrual } from '~/types/individual/accrual';
import { IndividualAviaPerson } from '~/types/individual/aviaPerson';
import { IndividualBankruptcy } from '~/types/individual/bankruptcy';
import { IndividualBusinessTrip } from '~/types/individual/businessTrip';
import { IndividualCommonInfo } from '~/types/individual/commonInfo';
import { IndividualCommonNegativeInfo } from '~/types/individual/commonNegativeInfo';
import { IndividualCriminalPenalties } from '~/types/individual/criminalPenalties';
import { IndividualDriverLicense } from '~/types/individual/driverLicense';
import { IndividualEnforcementProceeding } from '~/types/individual/enforcementProceeding';
import { IndividualFamilyRelation } from '~/types/individual/familyRelation';
import { IndividualFinancialScoring } from '~/types/individual/financialScoring';
import { IndividualFullNameChange } from '~/types/individual/fullNameChange';
import { IndividualGroup } from '~/types/individual/group';
import { IndividualHoliday } from '~/types/individual/holiday';
import { IndividualJobRecommendation } from '~/types/individual/jobRecommendation';
import { IndividualLegalEntitiesParticipationRestriction } from '~/types/individual/legalEntitiesParticipationRestriction';
import { IndividualMaritalStatusHistory } from '~/types/individual/maritalStatusHistory';
import { IndividualMarkers } from '~/types/individual/markers';
import { IndividualMessageAboutBankruptcy } from '~/types/individual/messageAboutBankruptcy';
import { IndividualMilitaryRecord } from '~/types/individual/militaryRecord';
import { IndividualMovementBus } from '~/types/individual/movementBus';
import { IndividualPhone } from '~/types/individual/phone';
import { IndividualSkype } from '~/types/individual/skype';
import { IndividualSpecialSigns } from '~/types/individual/specialSigns';
import { IndividualStageBankruptcy } from '~/types/individual/stageBankruptcy';
import { IndividualTerroristsAndExtremistsRecord } from '~/types/individual/terroristsAndExtremistsRecord';
import { IndividualType } from '~/types/individual/type';
import { IndividualUserList } from '~/types/individual/userList';
import { IndividualWanted } from '~/types/individual/wanted';
import { IndividualWantedFsin } from '~/types/individual/wantedFsin';
import { IndividualWantedFssp } from '~/types/individual/wantedFssp';
import { IndividualWantedMvd } from '~/types/individual/wantedMvd';
import { INormalizationService } from '../common/reportNormalizationService.interfaces';
import { IndividualTrustScoring } from '~/types/individual/trustScoring';

export const IIndividualDataBlobDataNormalizationServiceKey: InjectionKey<INormalizationService<IndividualDataBlobData>> = Symbol('INormalizationService<IndividualDataBlobData>');
export const IIndividualCommonInfoNormalizationServiceKey: InjectionKey<INormalizationService<IndividualCommonInfo>> = Symbol('INormalizationService<IndividualCommonInfo>');
export const IIndividualCommonNegativeInfoNormalizationServiceKey: InjectionKey<INormalizationService<IndividualCommonNegativeInfo>> = Symbol('INormalizationService<IndividualCommonNegativeInfo>');
export const IIndividualSpecialSignsNormalizationServiceKey: InjectionKey<INormalizationService<IndividualSpecialSigns>> = Symbol('INormalizationService<IndividualSpecialSigns>');
export const IIndividualMarkersNormalizationServiceKey: InjectionKey<INormalizationService<IndividualMarkers>> = Symbol('INormalizationService<IndividualMarkers>');
export const IIndividualGroupNormalizationServiceKey: InjectionKey<INormalizationService<IndividualGroup>> = Symbol('INormalizationService<IndividualGroup>');
export const IIndividualUserListNormalizationServiceKey: InjectionKey<INormalizationService<IndividualUserList>> = Symbol('INormalizationService<IndividualUserList>');
export const IIndividualMilitaryRecordNormalizationServiceKey: InjectionKey<INormalizationService<IndividualMilitaryRecord>> = Symbol('INormalizationService<IndividualMilitaryRecord>');
export const IIndividualPhoneNormalizationServiceKey: InjectionKey<INormalizationService<IndividualPhone>> = Symbol('INormalizationService<IndividualPhone>');
export const IIndividualSkypeNormalizationServiceKey: InjectionKey<INormalizationService<IndividualSkype>> = Symbol('INormalizationService<IndividualSkype>');
export const IIndividualDriverLicenseNormalizationServiceKey: InjectionKey<INormalizationService<IndividualDriverLicense>> = Symbol('INormalizationService<IndividualDriverLicense>');
export const IIndividualJobRecommendationNormalizationServiceKey: InjectionKey<INormalizationService<IndividualJobRecommendation>> = Symbol('INormalizationService<IndividualJobRecommendation>');
export const IIndividualBusinessTripNormalizationServiceKey: InjectionKey<INormalizationService<IndividualBusinessTrip>> = Symbol('INormalizationService<IndividualBusinessTrip>');
export const IIndividualHolidayNormalizationServiceKey: InjectionKey<INormalizationService<IndividualHoliday>> = Symbol('INormalizationService<IndividualHoliday>');
export const IIndividualLegalEntitiesParticipationRestrictionNormalizationServiceKey: InjectionKey<INormalizationService<IndividualLegalEntitiesParticipationRestriction>> = Symbol('INormalizationService<IndividualLegalEntitiesParticipationRestriction>');
export const IIndividualBankruptcyNormalizationServiceKey: InjectionKey<INormalizationService<IndividualBankruptcy>> = Symbol('INormalizationService<IndividualBankruptcy>');
export const IIndividualStageBankruptcyNormalizationServiceKey: InjectionKey<INormalizationService<IndividualStageBankruptcy>> = Symbol('INormalizationService<IndividualStageBankruptcy>');
export const IIndividualMessageAboutBankruptcyNormalizationServiceKey: InjectionKey<INormalizationService<IndividualMessageAboutBankruptcy>> = Symbol('INormalizationService<IndividualMessageAboutBankruptcy>');
export const IIndividualCriminalPenaltiesNormalizationServiceKey: InjectionKey<INormalizationService<IndividualCriminalPenalties>> = Symbol('INormalizationService<IndividualCriminalPenalties>');
export const IIndividualFinancialScoringNormalizationServiceKey: InjectionKey<INormalizationService<IndividualFinancialScoring>> = Symbol('INormalizationService<IndividualFinancialScoring>');
export const IIndividualTrustScoringNormalizationServiceKey: InjectionKey<INormalizationService<IndividualTrustScoring>> = Symbol('INormalizationService<IndividualTrustScoring>');
export const IIndividualEnforcementProceedingNormalizationServiceKey: InjectionKey<INormalizationService<IndividualEnforcementProceeding>> = Symbol('INormalizationService<IndividualEnforcementProceeding>');
export const IIndividualWantedMvdNormalizationServiceKey: InjectionKey<INormalizationService<IndividualWantedMvd>> = Symbol('INormalizationService<IndividualWantedMvd>');
export const IIndividualWantedFsinNormalizationServiceKey: InjectionKey<INormalizationService<IndividualWantedFsin>> = Symbol('INormalizationService<IndividualWantedFsin>');
export const IIndividualWantedFsspNormalizationServiceKey: InjectionKey<INormalizationService<IndividualWantedFssp>> = Symbol('INormalizationService<IndividualWantedFssp>');
export const IIndividualFullNameChangeNormalizationServiceKey: InjectionKey<INormalizationService<IndividualFullNameChange>> = Symbol('INormalizationService<IndividualFullNameChange>');
export const IIndividualTypeNormalizationServiceKey: InjectionKey<INormalizationService<IndividualType>> = Symbol('INormalizationService<IndividualType>');
export const IIndividualTerroristsAndExtremistsRecordNormalizationServiceKey: InjectionKey<INormalizationService<IndividualTerroristsAndExtremistsRecord>> = Symbol('INormalizationService<IndividualTerroristsAndExtremistsRecord>');
export const IIndividualAccrualNormalizationServiceKey: InjectionKey<INormalizationService<IndividualAccrual>> = Symbol('INormalizationService<IndividualAccrual>');
export const IIndividualWantedNormalizationServiceKey: InjectionKey<INormalizationService<IndividualWanted>> = Symbol('INormalizationService<IndividualWanted>');
export const IIndividualAviaPersonNormalizationServiceKey: InjectionKey<INormalizationService<IndividualAviaPerson>> = Symbol('INormalizationService<IndividualAviaPerson>');
export const IIndividualMovementBusNormalizationServiceKey: InjectionKey<INormalizationService<IndividualMovementBus>> = Symbol('INormalizationService<IndividualMovementBus>');
export const IIndividualFamilyRelationNormalizationServiceKey: InjectionKey<INormalizationService<IndividualFamilyRelation>> = Symbol('INormalizationService<IndividualFamilyRelation>');
export const IIndividualMaritalStatusHistoryNormalizationServiceKey: InjectionKey<INormalizationService<IndividualMaritalStatusHistory>> = Symbol('INormalizationService<IndividualMaritalStatusHistory>');
