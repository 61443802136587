import { injectable, inject } from 'inversify';
import { ReportMainEntityFile } from '~/types/common/reportMainEntityFile';
import { ReportSocialNetworkTrack } from '~/types/common/reportSocialNetworkTrack';
import { ReportUrl } from '~/types/common/reportUrl';
import { ReportSocialNetworkOtherPerson } from '~/types/common/reportSocialNetworkOtherPerson';
import {
  IPlaceholderDataService,
  IReportMainEntityFilePlaceholderServiceKey,
  IReportSocialNetworkTrackPlaceholderServiceKey,
  IReportUrlPlaceholderServiceKey
} from './reportPlaceholderService.interfaces';

@injectable()
export class ReportSocialNetworkOtherPersonPlaceholderService implements IPlaceholderDataService<ReportSocialNetworkOtherPerson> {
  constructor(
    @inject(IReportUrlPlaceholderServiceKey as symbol) private reportUrlPlaceholderService: IPlaceholderDataService<ReportUrl>,
    @inject(IReportMainEntityFilePlaceholderServiceKey as symbol) private reportMainEntityFilePlaceholderService: IPlaceholderDataService<ReportMainEntityFile>,
    @inject(IReportSocialNetworkTrackPlaceholderServiceKey as symbol) private reportSocialNetworkTrackPlaceholderService: IPlaceholderDataService<ReportSocialNetworkTrack>,
  ) {}

  getData(): ReportSocialNetworkOtherPerson {
    return {
      FirstName: '',
      LastName: '',
      NickName: '',
      Birthday: '',
      Url: this.reportUrlPlaceholderService.getData(),
      Photo: this.reportMainEntityFilePlaceholderService.getData(),
      City: '',
      SocialNetworkTrack: this.reportSocialNetworkTrackPlaceholderService.getData(),
      SocialNetworks: [],
      PhoneNumber: '',
    };
  }
}
