import { InjectionKey } from 'vue';
import ISplittable from '~/types/splittables/splittable';
import { CompositeNodeSizeSnapshot } from '~/models/nodes/snapshots/compositeNodeSizeSnapshot';

export interface IFakeSplittablesFactory {
  create<T>(
    snapshot: CompositeNodeSizeSnapshot,
    data: T | undefined
  ): ISplittable<T>
}

export const IFakeSplittablesFactoryKey: InjectionKey<IFakeSplittablesFactory> = Symbol('IFakeSplittablesFactory');
