import { injectable, inject } from 'inversify';
import { ReportPossibleBirthdays } from '~/types/common/reportPossibleBirthdays';
import { IPrimitiveTypesNormalizationServiceKey, IPrimitiveTypesNormalizationService } from './primitiveTypesNormalizationService.interfaces';
import { INormalizationService } from './reportNormalizationService.interfaces';

@injectable()
export class ReportPossibleBirthdaysNormalizationService implements INormalizationService<ReportPossibleBirthdays> {
  constructor(
    @inject(IPrimitiveTypesNormalizationServiceKey as symbol) private primitiveTypesNormalizationService: IPrimitiveTypesNormalizationService,
  ) {}

  normalize(data: any, root: string): ReportPossibleBirthdays | undefined {
    const {
      normalizeObject,
      normalizeString,
      normalizeNumber,
      normalizeOptionalString
    } = this.primitiveTypesNormalizationService;

    return normalizeObject(data, (data) => ({
      Birthday: normalizeOptionalString(data.Birthday, `${root}.Birthday`),
      Credibility: normalizeNumber(data.Credibility, `${root}.Credibility`),
      CredibilityStr: normalizeString(data.CredibilityStr, `${root}.CredibilityStr`),
    }), root);
  }
}
