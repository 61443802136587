import { injectable, inject } from 'inversify';

import { LegalEntitiesTotalScore } from '~/types/legal-entities/totalScore';
import { IPrimitiveTypesNormalizationServiceKey, IPrimitiveTypesNormalizationService } from '../common/primitiveTypesNormalizationService.interfaces';
import { INormalizationService } from '../common/reportNormalizationService.interfaces';

@injectable()
export class LegalEntitiesTotalScoreNormalizationService implements INormalizationService<LegalEntitiesTotalScore> {
  constructor(
    @inject(IPrimitiveTypesNormalizationServiceKey as symbol) private primitiveTypesNormalizationService: IPrimitiveTypesNormalizationService,
  ) {}

  normalize(data: any, root: string): LegalEntitiesTotalScore | undefined {
    const {
      normalizeBoolean,
      normalizeOptionalInteger,
      normalizeOptionalString,
      normalizeObject
    } = this.primitiveTypesNormalizationService;

    return normalizeObject(data, (data) => ({
      IsExists: normalizeBoolean(data.IsExists, `${root}.IsExists`),
      TotalScore: normalizeOptionalString(data.TotalScore, `${root}.TotalScore`),
      TotalScoreConclusion: normalizeOptionalString(data.TotalScoreConclusion, `${root}.TotalScoreConclusion`),
      Year: normalizeOptionalInteger(data.Year, `${root}.Year`),
    }), root);
  }
}
