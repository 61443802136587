import { injectable, inject } from 'inversify';

import { ReportUrl } from '~/types/common/reportUrl';
import { LegalEntitiesSocial } from '~/types/legal-entities/social';
import { IPrimitiveTypesNormalizationServiceKey, IPrimitiveTypesNormalizationService } from '../common/primitiveTypesNormalizationService.interfaces';
import { INormalizationService, IReportUrlNormalizationServiceKey } from '../common/reportNormalizationService.interfaces';

@injectable()
export class LegalEntitiesSocialNormalizationService implements INormalizationService<LegalEntitiesSocial> {
  constructor(
    @inject(IReportUrlNormalizationServiceKey as symbol) private reportUrlNormalizationService: INormalizationService<ReportUrl>,

    @inject(IPrimitiveTypesNormalizationServiceKey as symbol) private primitiveTypesNormalizationService: IPrimitiveTypesNormalizationService,
  ) {}

  normalize(data: any, root: string): LegalEntitiesSocial | undefined {
    const { normalizeString, normalizeArray, normalizeObject } = this.primitiveTypesNormalizationService;

    return normalizeObject(data, (data) => ({
      Text: normalizeString(data.Text, `${root}.Text`),
      Links: normalizeArray(data.Links, (item, field) => this.reportUrlNormalizationService.normalize(item, field), `${root}.Links`)
    }), root);
  }
}
