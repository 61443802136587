import { Direction } from '~/types/field-list/direction';

export const FAMILY_FIELD_LIST_OPTIONS = {
  fieldsWidth: '100%',
  fieldsDirection: 'divider-row' as Direction,
  fieldsValueWidth: '350px',
  isBeginningDivider: true,
  rowGap: '8px'
};

export const FAMILY_MARITAL_STATUS_FIELD_LIST_OPTIONS = {
  fieldsWidth: '100%',
  fieldsDirection: 'divider-row' as Direction,
  fieldsValueWidth: 'auto',
  isBeginningDivider: true,
  rowGap: '8px'
};
