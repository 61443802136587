/* eslint-disable class-methods-use-this, max-len */

import { injectable } from 'inversify';
import { StringUtils } from '~/utils/stringUtils';
import { LegalEntitiesCertificateSummary } from '~/types/legal-entities/certificateSummary';
import { LegalEntitiesCertificateSummaryDTO } from '~/types/dto/legalEntities/certificateSummaryDTO';
import {
  ILegalEntitiesCertificateSummaryAdapterService
} from '~/services/adapters/legalEntities/legalEntitiesAdapterService.interfaces';

@injectable()
export class LegalEntitiesCertificateSummaryAdapterService implements ILegalEntitiesCertificateSummaryAdapterService {
  getData(initData: LegalEntitiesCertificateSummary[]): LegalEntitiesCertificateSummaryDTO[] {
    return (initData ?? []).map((item) => ({
      ActualCount: item.ActualCount,
      CertificateType: StringUtils.quoteReplacement(item.CertificateType ?? ''),
      TotalCount: item.TotalCount,
    }));
  }
}
