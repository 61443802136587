import { InjectionKey } from 'vue';
import { IConfigService } from '~/services/configService.interface';
import { ReportMessageBoardRecordDTO } from '~/types/dto/common/messageBoardsDTO';

export type IMessageBoardsGeneralInfoConfigService = IConfigService<ReportMessageBoardRecordDTO>;
export type IMessageBoardsDescriptionInfoConfigService = IConfigService<ReportMessageBoardRecordDTO>;
export type IMessageBoardsInfoConfigService = IConfigService<ReportMessageBoardRecordDTO>;

export const IMessageBoardsGeneralInfoConfigServiceKey: InjectionKey<IMessageBoardsGeneralInfoConfigService> = Symbol('IMessageBoardsGeneralInfoConfigService');
export const IMessageBoardsDescriptionInfoConfigServiceKey: InjectionKey<IMessageBoardsDescriptionInfoConfigService> = Symbol('IMessageBoardsDescriptionInfoConfigService');
export const IMessageBoardsInfoConfigServiceKey: InjectionKey<IMessageBoardsInfoConfigService> = Symbol('IMessageBoardsInfoConfigService');
