/* eslint-disable class-methods-use-this, max-len */

import { injectable, inject } from 'inversify';
import { LegalEntitiesMarkersDTO } from '~/types/dto/legalEntities/markers';
import { LegalEntitiesMarkers } from '~/types/legal-entities/markers';
import {
  ILegalEntitiesMarkersAdapterService
} from '~/services/adapters/legalEntities/legalEntitiesAdapterService.interfaces';
import {
  IReportMarkerAdapterService,
  IReportMarkerAdapterServiceKey
} from '~/services/adapters/common/reportAdapterService.interfeces';

@injectable()
export class LegalEntitiesMarkersAdapterService implements ILegalEntitiesMarkersAdapterService {
  constructor(
    @inject(IReportMarkerAdapterServiceKey as symbol) private reportMarkerAdapterService: IReportMarkerAdapterService
  ) {}
  getData(initData: LegalEntitiesMarkers): LegalEntitiesMarkersDTO {
    return {
      MarkersBad: this.reportMarkerAdapterService.getData(initData.MarkersBad),
      MarkersWarning: this.reportMarkerAdapterService.getData(initData.MarkersWarning),
      MarkersGood: this.reportMarkerAdapterService.getData(initData.MarkersGood),
      MarkersRate: initData.Rate,
      MarkersRiskIndicator: initData.RiskIndicator,
    };
  }
}
