import { injectable } from 'inversify';
import { IndividualMaritalStatusHistory } from '~/types/individual/maritalStatusHistory';
import { IPlaceholderDataService } from '../common/reportPlaceholderService.interfaces';

@injectable()
export class IndividualMaritalStatusHistoryPlaceholderService implements IPlaceholderDataService<IndividualMaritalStatusHistory> {
  getData(): IndividualMaritalStatusHistory {
    return {
      MaritalStatus: '',
      ChangeDate: '',
    };
  }
}
