import { injectable } from 'inversify';

import { ReportVehicleOwnership } from '~/types/common/reportVehicleOwnership';
import { IPlaceholderDataService } from './reportPlaceholderService.interfaces';

@injectable()
export class ReportVehicleOwnershipPlaceholderService implements IPlaceholderDataService<ReportVehicleOwnership> {
  getData(): ReportVehicleOwnership {
    return {
      OwnerType: '',
      RegDate: '',
      RegDateRaw: '',
      DeregistrationDate: '',
    };
  }
}
