/* eslint-disable max-len, class-methods-use-this */
import { injectable, inject } from 'inversify';
import { LegalEntitiesBriefInfoDTO } from '~/types/dto/legalEntities/briefInfo';
import { StringUtils } from '~/utils/stringUtils';
import { LegalEntitiesBriefInfo } from '~/types/legal-entities/briefInfo';
import {
  ILegalEntitiesActivitySummaryAdapterService,
  ILegalEntitiesActivitySummaryAdapterServiceKey,
  ILegalEntitiesAverageHeadcountAdapterService,
  ILegalEntitiesAverageHeadcountAdapterServiceKey,
  ILegalEntitiesBankAccountAdapterService,
  ILegalEntitiesBankAccountAdapterServiceKey,
  ILegalEntitiesBriefInfoAdapterService,
  ILegalEntitiesCertificateSummaryAdapterService,
  ILegalEntitiesCertificateSummaryAdapterServiceKey,
  ILegalEntitiesCompanyActualAddressAdapterService,
  ILegalEntitiesCompanyActualAddressAdapterServiceKey,
  ILegalEntitiesCompanyEmployeeInfoAdapterService,
  ILegalEntitiesCompanyEmployeeInfoAdapterServiceKey,
  ILegalEntitiesCompanyFounderAdapterService,
  ILegalEntitiesCompanyFounderAdapterServiceKey,
  ILegalEntitiesCompanyHeadFlAdapterService,
  ILegalEntitiesCompanyHeadFlAdapterServiceKey,
  ILegalEntitiesCompanyHistoryAddressAdapterService,
  ILegalEntitiesCompanyHistoryAddressAdapterServiceKey,
  ILegalEntitiesCompanyManagementCompaniesAdapterService,
  ILegalEntitiesCompanyManagementCompaniesAdapterServiceKey,
  ILegalEntitiesCompanyNameAdapterService,
  ILegalEntitiesCompanyNameAdapterServiceKey,
  ILegalEntitiesCompanyPhoneAdapterService,
  ILegalEntitiesCompanyPhoneAdapterServiceKey,
  ILegalEntitiesCompanySiteAdapterService,
  ILegalEntitiesCompanySiteAdapterServiceKey,
  ILegalEntitiesCompanyStatedCapitalAdapterService,
  ILegalEntitiesCompanyStatedCapitalAdapterServiceKey,
  ILegalEntitiesCompanyStatusAdapterService,
  ILegalEntitiesCompanyStatusAdapterServiceKey,
  ILegalEntitiesCompanyTaxRegimesAdapterService,
  ILegalEntitiesCompanyTaxRegimesAdapterServiceKey,
  ILegalEntitiesContractsInfoByYearAdapterService,
  ILegalEntitiesContractsInfoByYearAdapterServiceKey,
  ILegalEntitiesCourtCasesByYearAdapterService,
  ILegalEntitiesCourtCasesByYearAdapterServiceKey,
  ILegalEntitiesEmailsAdapterService,
  ILegalEntitiesEmailsAdapterServiceKey,
  ILegalEntitiesEnforcementProceedingsInfoBySubjectAdapterService,
  ILegalEntitiesEnforcementProceedingsInfoBySubjectAdapterServiceKey,
  ILegalEntitiesFineAndTaxDebtAdapterService,
  ILegalEntitiesFineAndTaxDebtAdapterServiceKey,
  ILegalEntitiesGovernmentControlAdapterService,
  ILegalEntitiesGovernmentControlAdapterServiceKey,
  ILegalEntitiesInsuranceFundInfoAdapterService,
  ILegalEntitiesInsuranceFundInfoAdapterServiceKey,
  ILegalEntitiesLesseeSummaryAdapterService,
  ILegalEntitiesLesseeSummaryAdapterServiceKey,
  ILegalEntitiesLicenseSummaryAdapterService,
  ILegalEntitiesLicenseSummaryAdapterServiceKey,
  ILegalEntitiesPredecessorAdapterService,
  ILegalEntitiesPredecessorAdapterServiceKey,
  ILegalEntitiesRegistrarOfShareholdersAdapterService,
  ILegalEntitiesRegistrarOfShareholdersAdapterServiceKey,
  ILegalEntitiesRelatedEgrulAdapterService,
  ILegalEntitiesRelatedEgrulAdapterServiceKey,
  ILegalEntitiesReorganisationMemberAdapterService,
  ILegalEntitiesReorganisationMemberAdapterServiceKey,
  ILegalEntitiesSelfRegulatoryOrganizationAdapterService,
  ILegalEntitiesSelfRegulatoryOrganizationAdapterServiceKey,
  ILegalEntitiesShareholderAdapterService,
  ILegalEntitiesShareholderAdapterServiceKey,
  ILegalEntitiesSocialsAdapterService,
  ILegalEntitiesSocialsAdapterServiceKey,
  ILegalEntitiesSubCompanyInfoAdapterService,
  ILegalEntitiesSubCompanyInfoAdapterServiceKey,
  ILegalEntitiesSuccessorAdapterService,
  ILegalEntitiesSuccessorAdapterServiceKey,
  ILegalEntitiesTaxAdapterService,
  ILegalEntitiesTaxAdapterServiceKey,
  ILegalEntitiesTaxesPenaltiesAdapterService,
  ILegalEntitiesTaxesPenaltiesAdapterServiceKey,
  ILegalEntitiesTaxModeAdapterService,
  ILegalEntitiesTaxModeAdapterServiceKey,
  ILegalEntitiesTaxServiceInfoAdapterService,
  ILegalEntitiesTaxServiceInfoAdapterServiceKey,
  ILegalEntitiesTrademarkAdapterService,
  ILegalEntitiesTrademarkAdapterServiceKey
} from '~/services/adapters/legalEntities/legalEntitiesAdapterService.interfaces';
import {
  IReportConnectionRelatedAdapterService,
  IReportConnectionRelatedAdapterServiceKey,
  IReportLegalEntitiesParticipationAdapterService,
  IReportLegalEntitiesParticipationAdapterServiceKey,
  IReportVehicleAdapterService, IReportVehicleAdapterServiceKey
} from '~/services/adapters/common/reportAdapterService.interfeces';

@injectable()
export class LegalEntitiesBriefInfoAdapterService implements ILegalEntitiesBriefInfoAdapterService {
  constructor(
    @inject(ILegalEntitiesCompanyTaxRegimesAdapterServiceKey as symbol) private legalEntitiesCompanyTaxRegimesAdapterService: ILegalEntitiesCompanyTaxRegimesAdapterService,
    @inject(ILegalEntitiesBankAccountAdapterServiceKey as symbol) private legalEntitiesBankAccountAdapterService: ILegalEntitiesBankAccountAdapterService,
    @inject(ILegalEntitiesCompanyStatusAdapterServiceKey as symbol) private legalEntitiesCompanyStatusAdapterService: ILegalEntitiesCompanyStatusAdapterService,
    @inject(ILegalEntitiesCompanyHeadFlAdapterServiceKey as symbol) private legalEntitiesCompanyHeadFlAdapterService: ILegalEntitiesCompanyHeadFlAdapterService,
    @inject(ILegalEntitiesCompanyPhoneAdapterServiceKey as symbol) private legalEntitiesCompanyPhoneAdapterService: ILegalEntitiesCompanyPhoneAdapterService,
    @inject(ILegalEntitiesEmailsAdapterServiceKey as symbol) private legalEntitiesEmailsAdapterService: ILegalEntitiesEmailsAdapterService,
    @inject(ILegalEntitiesCompanySiteAdapterServiceKey as symbol) private legalEntitiesCompanySiteAdapterService: ILegalEntitiesCompanySiteAdapterService,
    @inject(ILegalEntitiesCompanyActualAddressAdapterServiceKey as symbol) private legalEntitiesCompanyActualAddressAdapterService: ILegalEntitiesCompanyActualAddressAdapterService,
    @inject(ILegalEntitiesCourtCasesByYearAdapterServiceKey as symbol) private legalEntitiesCourtCasesByYearAdapterService: ILegalEntitiesCourtCasesByYearAdapterService,
    @inject(ILegalEntitiesEnforcementProceedingsInfoBySubjectAdapterServiceKey as symbol) private legalEntitiesEnforcementProceedingsInfoBySubjectAdapterService: ILegalEntitiesEnforcementProceedingsInfoBySubjectAdapterService,
    @inject(ILegalEntitiesCompanyFounderAdapterServiceKey as symbol) private legalEntitiesCompanyFounderAdapterService: ILegalEntitiesCompanyFounderAdapterService,
    @inject(ILegalEntitiesTaxServiceInfoAdapterServiceKey as symbol) private legalEntitiesTaxServiceInfoAdapterService: ILegalEntitiesTaxServiceInfoAdapterService,
    @inject(ILegalEntitiesCompanyHistoryAddressAdapterServiceKey as symbol) private legalEntitiesCompanyHistoryAddressAdapterService: ILegalEntitiesCompanyHistoryAddressAdapterService,
    @inject(ILegalEntitiesInsuranceFundInfoAdapterServiceKey as symbol) private legalEntitiesInsuranceFundInfoAdapterService: ILegalEntitiesInsuranceFundInfoAdapterService,
    @inject(ILegalEntitiesCompanyEmployeeInfoAdapterServiceKey as symbol) private legalEntitiesCompanyEmployeeInfoAdapterService: ILegalEntitiesCompanyEmployeeInfoAdapterService,
    @inject(ILegalEntitiesTaxAdapterServiceKey as symbol) private legalEntitiesTaxAdapterService: ILegalEntitiesTaxAdapterService,
    @inject(ILegalEntitiesTaxModeAdapterServiceKey as symbol) private legalEntitiesTaxModeAdapterService: ILegalEntitiesTaxModeAdapterService,
    @inject(ILegalEntitiesFineAndTaxDebtAdapterServiceKey as symbol) private legalEntitiesFineAndTaxDebtAdapterService: ILegalEntitiesFineAndTaxDebtAdapterService,
    @inject(ILegalEntitiesTaxesPenaltiesAdapterServiceKey as symbol) private legalEntitiesTaxesPenaltiesAdapterService: ILegalEntitiesTaxesPenaltiesAdapterService,
    @inject(ILegalEntitiesLesseeSummaryAdapterServiceKey as symbol) private legalEntitiesLesseeSummaryAdapterService: ILegalEntitiesLesseeSummaryAdapterService,
    @inject(ILegalEntitiesLicenseSummaryAdapterServiceKey as symbol) private legalEntitiesLicenseSummaryAdapterService: ILegalEntitiesLicenseSummaryAdapterService,
    @inject(ILegalEntitiesContractsInfoByYearAdapterServiceKey as symbol) private legalEntitiesContractsInfoByYearAdapterService: ILegalEntitiesContractsInfoByYearAdapterService,
    @inject(ILegalEntitiesGovernmentControlAdapterServiceKey as symbol) private legalEntitiesGovernmentControlAdapterService: ILegalEntitiesGovernmentControlAdapterService,
    @inject(ILegalEntitiesCertificateSummaryAdapterServiceKey as symbol) private legalEntitiesCertificateSummaryAdapterService: ILegalEntitiesCertificateSummaryAdapterService,
    @inject(ILegalEntitiesSelfRegulatoryOrganizationAdapterServiceKey as symbol) private legalEntitiesSelfRegulatoryOrganizationAdapterService: ILegalEntitiesSelfRegulatoryOrganizationAdapterService,
    @inject(ILegalEntitiesTrademarkAdapterServiceKey as symbol) private legalEntitiesTrademarkAdapterService: ILegalEntitiesTrademarkAdapterService,
    @inject(ILegalEntitiesSubCompanyInfoAdapterServiceKey as symbol) private legalEntitiesSubCompanyInfoAdapterService: ILegalEntitiesSubCompanyInfoAdapterService,
    @inject(ILegalEntitiesRegistrarOfShareholdersAdapterServiceKey as symbol) private legalEntitiesRegistrarOfShareholdersAdapterService: ILegalEntitiesRegistrarOfShareholdersAdapterService,
    @inject(IReportLegalEntitiesParticipationAdapterServiceKey as symbol) private reportLegalEntitiesParticipationAdapterService: IReportLegalEntitiesParticipationAdapterService,
    @inject(ILegalEntitiesCompanyManagementCompaniesAdapterServiceKey as symbol) private legalEntitiesCompanyManagementCompaniesAdapterService: ILegalEntitiesCompanyManagementCompaniesAdapterService,
    @inject(ILegalEntitiesShareholderAdapterServiceKey as symbol) private legalEntitiesShareholderAdapterService: ILegalEntitiesShareholderAdapterService,
    @inject(ILegalEntitiesCompanyNameAdapterServiceKey as symbol) private legalEntitiesCompanyNameAdapterService: ILegalEntitiesCompanyNameAdapterService,
    @inject(ILegalEntitiesCompanyStatedCapitalAdapterServiceKey as symbol) private legalEntitiesCompanyStatedCapitalAdapterService: ILegalEntitiesCompanyStatedCapitalAdapterService,
    @inject(ILegalEntitiesPredecessorAdapterServiceKey as symbol) private legalEntitiesPredecessorAdapterService: ILegalEntitiesPredecessorAdapterService,
    @inject(ILegalEntitiesSuccessorAdapterServiceKey as symbol) private legalEntitiesSuccessorAdapterService: ILegalEntitiesSuccessorAdapterService,
    @inject(ILegalEntitiesReorganisationMemberAdapterServiceKey as symbol) private legalEntitiesReorganisationMemberAdapterService: ILegalEntitiesReorganisationMemberAdapterService,
    @inject(ILegalEntitiesRelatedEgrulAdapterServiceKey as symbol) private legalEntitiesRelatedEgrulAdapterService: ILegalEntitiesRelatedEgrulAdapterService,
    @inject(IReportConnectionRelatedAdapterServiceKey as symbol) private reportConnectionRelatedAdapterService: IReportConnectionRelatedAdapterService,
    @inject(ILegalEntitiesActivitySummaryAdapterServiceKey as symbol) private legalEntitiesActivitySummaryAdapterService: ILegalEntitiesActivitySummaryAdapterService,
    @inject(IReportVehicleAdapterServiceKey as symbol) private reportVehicleAdapterService: IReportVehicleAdapterService,
    @inject(ILegalEntitiesSocialsAdapterServiceKey as symbol) private legalEntitiesSocialsAdapterService: ILegalEntitiesSocialsAdapterService,
    @inject(ILegalEntitiesAverageHeadcountAdapterServiceKey as symbol) private legalEntitiesAverageHeadcountAdapterService: ILegalEntitiesAverageHeadcountAdapterService,
  ) {}

  getData(initData: LegalEntitiesBriefInfo): LegalEntitiesBriefInfoDTO {
    return { 
      CompanyTaxRegimes: this.legalEntitiesCompanyTaxRegimesAdapterService.getData(initData.CompanyTaxRegimes),
      BankAccounts: this.legalEntitiesBankAccountAdapterService.getData(initData.BankAccounts),
      CompanyStatuses: this.legalEntitiesCompanyStatusAdapterService.getData(initData.CompanyStatuses),
      IscClaims: initData.IscClaims ?? 0,
      IscObligation: initData.IscObligation ?? 0,
      FsspDept: initData.FsspDept ?? 0,
      CompanyFlHeads: this.legalEntitiesCompanyHeadFlAdapterService.getData(initData.CompanyFlHeads),
      CompanyStatus: StringUtils.quoteReplacement(initData.CompanyStatus ?? ''),
      CompanyLiquidationDate: StringUtils.quoteReplacement(initData.CompanyLiquidationDate ?? ''),
      CompanyRegistrationDate: StringUtils.quoteReplacement(initData.CompanyRegistrationDate ?? ''),
      CompanyAgeInYears: initData.CompanyAgeInYears ?? 0,
      CompanyMainActivityText: StringUtils.quoteReplacement(initData.CompanyMainActivityText),
      CompanyStatedCapitalSum: initData.CompanyStatedCapitalSum ?? 0,
      MspCategory: StringUtils.quoteReplacement(initData.MspCategory),
      Phones: this.legalEntitiesCompanyPhoneAdapterService.getData(initData.Phones),
      Emails: this.legalEntitiesEmailsAdapterService.getData(initData.Emails),
      Sites: this.legalEntitiesCompanySiteAdapterService.getData(initData.Sites),
      CompanyAddress: this.legalEntitiesCompanyActualAddressAdapterService.getData(initData.CompanyAddress),
      EmployeeAvgCount: initData.EmployeeAvgCount ?? 0,
      FilialsCount: initData.FilialsCount ?? 0,
      CourtCasesByYearsPlaintiff: this.legalEntitiesCourtCasesByYearAdapterService.getData(initData.CourtCasesByYearsPlaintiff),
      CourtCasesByYearsDefendant: this.legalEntitiesCourtCasesByYearAdapterService.getData(initData.CourtCasesByYearsDefendant),
      CourtCasesByYearsThirdParty: this.legalEntitiesCourtCasesByYearAdapterService.getData(initData.CourtCasesByYearsThirdParty),
      NonCompleatedCasesBySubject: this.legalEntitiesEnforcementProceedingsInfoBySubjectAdapterService.getData(initData.NonCompleatedCasesBySubject),
      CompleatedCasesBySubject: this.legalEntitiesEnforcementProceedingsInfoBySubjectAdapterService.getData(initData.CompleatedCasesBySubject),
      HistoryOfCompanyFounders: this.legalEntitiesCompanyFounderAdapterService.getData(initData.HistoryOfCompanyFounders),
      RegistrationTaxService: this.legalEntitiesTaxServiceInfoAdapterService.getData(initData.RegistrationTaxService),
      CurrentTaxService: this.legalEntitiesTaxServiceInfoAdapterService.getData(initData.RegistrationTaxService),
      DateGetOgrn: StringUtils.quoteReplacement(initData.DateGetOgrn ?? ''),
      CompanyAddressStory: this.legalEntitiesCompanyHistoryAddressAdapterService.getData(initData.CompanyAddressStory),
      PensionInsuranceFundRecords: this.legalEntitiesInsuranceFundInfoAdapterService.getData(initData.PensionInsuranceFundRecords),
      MedicalInsuranceFundRecords: this.legalEntitiesInsuranceFundInfoAdapterService.getData(initData.MedicalInsuranceFundRecords),
      SocialInsuranceFundRecords: this.legalEntitiesInsuranceFundInfoAdapterService.getData(initData.SocialInsuranceFundRecords),
      RelatedEmployees: this.legalEntitiesCompanyEmployeeInfoAdapterService.getData(initData.RelatedEmployees),
      TaxesAndFees: this.legalEntitiesTaxAdapterService.getData(initData.TaxesAndFees),
      TaxMode: this.legalEntitiesTaxModeAdapterService.getData(initData.TaxMode),
      FinesAndTaxDebts: this.legalEntitiesFineAndTaxDebtAdapterService.getData(initData.FinesAndTaxDebts),
      TaxesPenalties: this.legalEntitiesTaxesPenaltiesAdapterService.getData(initData.TaxesPenalties),
      LesseeSummary: this.legalEntitiesLesseeSummaryAdapterService.getData(initData.LesseeSummary),
      LicensesSummary: this.legalEntitiesLicenseSummaryAdapterService.getData(initData.LicensesSummary),
      ContractsInfoByParticipator: this.legalEntitiesContractsInfoByYearAdapterService.getData(initData.ContractsInfoByParticipator),
      GovernmentControl: this.legalEntitiesGovernmentControlAdapterService.getData(initData.GovernmentControl),
      CertificatesSummary: this.legalEntitiesCertificateSummaryAdapterService.getData(initData.CertificatesSummary),
      SelfRegulatoryOrganizations: this.legalEntitiesSelfRegulatoryOrganizationAdapterService.getData(initData.SelfRegulatoryOrganizations),
      Trademarks: this.legalEntitiesTrademarkAdapterService.getData(initData.Trademarks),
      Filials: this.legalEntitiesSubCompanyInfoAdapterService.getData(initData.Filials),
      RegistrarOfShareholders: this.legalEntitiesRegistrarOfShareholdersAdapterService.getData(initData.RegistrarOfShareholders),
      Participations: this.reportLegalEntitiesParticipationAdapterService.getData(initData.Participations),
      ManagementCompanies: this.legalEntitiesCompanyManagementCompaniesAdapterService.getData(initData.ManagementCompanies),
      CompanyFounders: this.legalEntitiesCompanyFounderAdapterService.getData(initData.CompanyFounders),
      Shareholders: this.legalEntitiesShareholderAdapterService.getData(initData.Shareholders),
      HistoryOfCompanyNames: this.legalEntitiesCompanyNameAdapterService.getData(initData.HistoryOfCompanyNames),
      HistoryOfManagementCompanies: this.legalEntitiesCompanyManagementCompaniesAdapterService.getData(initData.HistoryOfManagementCompanies),
      HistoryOfCompanyHeads: this.legalEntitiesCompanyHeadFlAdapterService.getData(initData.HistoryOfCompanyHeads),
      HistoryOfShareholders: this.legalEntitiesShareholderAdapterService.getData(initData.HistoryOfShareholders),
      HistoryOfCompanyStatedCapital: this.legalEntitiesCompanyStatedCapitalAdapterService.getData(initData.HistoryOfCompanyStatedCapital),
      Predecessors: this.legalEntitiesPredecessorAdapterService.getData(initData.Predecessors),
      Successors: this.legalEntitiesSuccessorAdapterService.getData(initData.Successors),
      ReorganisationMembers: this.legalEntitiesReorganisationMemberAdapterService.getData(initData.ReorganisationMembers),
      EgrulRelations: this.legalEntitiesRelatedEgrulAdapterService.getData(initData.EgrulRelations),
      ContractsInfoByCustomer: this.legalEntitiesContractsInfoByYearAdapterService.getData(initData.ContractsInfoByCustomer),
      ActivitySummary: this.legalEntitiesActivitySummaryAdapterService.getData(initData.ActivitySummary),
      SubCompaniesCount: initData.SubCompaniesCount ?? 0,
      Vehicles: this.reportVehicleAdapterService.getData(initData.Vehicles),
      RelationsWithEmployees: this.reportConnectionRelatedAdapterService.getData(initData.RelationsWithEmployees),
      RelationsWithBlacklisted: this.reportConnectionRelatedAdapterService.getData(initData.RelationsWithBlacklisted),
      RelationsWithCounterparties: this.reportConnectionRelatedAdapterService.getData(initData.RelationsWithCounterparties),
      RelationsWithCompetitors: this.reportConnectionRelatedAdapterService.getData(initData.RelationsWithCompetitors),
      FoundRelations: this.reportConnectionRelatedAdapterService.getData(initData?.FoundRelations),
      Socials: this.legalEntitiesSocialsAdapterService.getData(initData.Socials),
      ChangeAverageNumberEmployee: this.legalEntitiesAverageHeadcountAdapterService.getData(initData.ChangeAverageNumberEmployee)
    };
  }
}
