import { StyleExtractor } from '~/types/nodes/styleExtractor';

function defaultStyleExtractor(value: string) {
  return value;
}

export module NodeUtils {
    export function isHTMLElement(node: Node): node is HTMLElement {
      return node.nodeType == Node.ELEMENT_NODE && node instanceof HTMLElement;
    }

    export function getStyle<T = string>(element: Element, propertyName: string, extractor?: StyleExtractor<T>): T
    export function getStyle<T = string>(element: Element, propertyName: string, extractor = defaultStyleExtractor) {
      const styles = window.getComputedStyle(element);
      const strValue = styles.getPropertyValue(propertyName);
        
      return extractor(strValue);
    }
    
    export function getTopLevelElements<T extends Element>(elements: T[]): T[] {
      return elements.filter(element => !elements.some(x => x !== element && x.contains(element)));
    }
}
