import { injectable, inject } from 'inversify';
import { ReportColleague } from '~/types/common/reportColleague';
import { ReportJob } from '~/types/common/reportJob';
import { ReportPayment } from '~/types/common/reportPayment';
import { IPrimitiveTypesNormalizationServiceKey, IPrimitiveTypesNormalizationService } from './primitiveTypesNormalizationService.interfaces';
import { INormalizationService, IReportColleagueNormalizationServiceKey, IReportPaymentNormalizationServiceKey } from './reportNormalizationService.interfaces';

@injectable()
export class ReportJobNormalizationService implements INormalizationService<ReportJob> {
  constructor(
    @inject(IReportPaymentNormalizationServiceKey as symbol) private reportPaymentNormalizationService: INormalizationService<ReportPayment>,
    @inject(IReportColleagueNormalizationServiceKey as symbol) private reportColleagueNormalizationService: INormalizationService<ReportColleague>,

    @inject(IPrimitiveTypesNormalizationServiceKey as symbol) private primitiveTypesNormalizationService: IPrimitiveTypesNormalizationService,
  ) {}

  normalize(data: any, root: string): ReportJob | undefined {
    const {
      normalizeObject,
      normalizeString,
      normalizeOptionalString,
      normalizeArray
    } = this.primitiveTypesNormalizationService;

    return normalizeObject(data, (data) => ({
      CompanyName: normalizeOptionalString(data.CompanyName, `${root}.CompanyName`),
      CompanyInn: normalizeOptionalString(data.CompanyInn, `${root}.CompanyInn`),
      DepartmentName: normalizeOptionalString(data.DepartmentName, `${root}.DepartmentName`),
      Position: normalizeOptionalString(data.Position, `${root}.Position`),
      StartDate: normalizeOptionalString(data.StartDate, `${root}.StartDate`),
      EndDate: normalizeOptionalString(data.EndDate, `${root}.EndDate`),
      CompanyAddress: normalizeOptionalString(data.CompanyAddress, `${root}.CompanyAddress`),
      CompanyPhone: normalizeOptionalString(data.CompanyPhone, `${root}.CompanyPhone`),
      CompanyHeadPhone: normalizeOptionalString(data.CompanyHeadPhone, `${root}.CompanyHeadPhone`),
      CompanyBookkeeperPhone: normalizeOptionalString(data.CompanyBookkeeperPhone, `${root}.CompanyBookkeeperPhone`),
      ContractType: normalizeString(data.ContractType, `${root}.ContractType`),
      ArticleDissmissal: normalizeOptionalString(data.ArticleDissmissal, `${root}.ArticleDissmissal`),
      Payments: normalizeArray(data.Payments, (item, field) => this.reportPaymentNormalizationService.normalize(item, field), `${root}.Payments`),
      Colleagues: normalizeArray(data.Colleagues, (item, field) => this.reportColleagueNormalizationService.normalize(item, field), `${root}.Colleagues`),
    }), root);
  }
}
