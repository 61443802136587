import { injectable, inject } from 'inversify';
import {
  ILegalEntitiesReportFinancialIndicatorBaseAdapterService,
  ILegalEntitiesReportFinancialIndicatorBaseAdapterServiceKey,
  ILegalEntitiesReportFinancialIndicatorWithConclusionsAdapterService
} from '~/services/adapters/legalEntities/legalEntitiesAdapterService.interfaces';
import {
  IReportArrayStringAdapterService,
  IReportArrayStringAdapterServiceKey
} from '~/services/adapters/common/reportAdapterService.interfeces';
import {
  LegalEntitiesReportFinancialIndicatorWithConclusions
} from '~/types/legal-entities/reportFinancialIndicatorWithConclusions';
import {
  LegalEntitiesReportFinancialIndicatorWithConclusionsDTO
} from '~/types/dto/legalEntities/reportFinancialIndicatorWithConclusionsDTO';

@injectable()
export class LegalEntitiesReportFinancialIndicatorWithConclusionsAdapterService implements ILegalEntitiesReportFinancialIndicatorWithConclusionsAdapterService {
  constructor(
    @inject(IReportArrayStringAdapterServiceKey as symbol) private reportArrayStringAdapterService: IReportArrayStringAdapterService,
    @inject(ILegalEntitiesReportFinancialIndicatorBaseAdapterServiceKey as symbol) private legalEntitiesReportFinancialIndicatorBaseAdapterService: ILegalEntitiesReportFinancialIndicatorBaseAdapterService,
  ) {}
  getData(initData: LegalEntitiesReportFinancialIndicatorWithConclusions): LegalEntitiesReportFinancialIndicatorWithConclusionsDTO {
    const legalEntitiesReportFinancialIndicatorBase = this.legalEntitiesReportFinancialIndicatorBaseAdapterService.getData(initData);
    return {
      Conclusions: this.reportArrayStringAdapterService.getData(initData.Conclusions),
      ...legalEntitiesReportFinancialIndicatorBase
    };
  }
}
