import { injectable } from 'inversify';

import { LegalEntitiesSelfRegulatoryOrganization } from '~/types/legal-entities/selfRegulatoryOrganization';
import { IPlaceholderDataService } from '../common/reportPlaceholderService.interfaces';

@injectable()
export class LegalEntitiesSelfRegulatoryOrganizationPlaceholderDataService implements IPlaceholderDataService<LegalEntitiesSelfRegulatoryOrganization> {
  getData(): LegalEntitiesSelfRegulatoryOrganization {
    return {
      SroName: '',
      SroInn: '',
      SroRegistryName: '',
      SroRegistryNumber: '',
      ParticipantRegistrationDate: '',
      ParticipantTerminationDate: '',
    };
  }
}
