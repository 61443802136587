import { injectable } from 'inversify';
import { LegalEntitiesBankAccount } from '~/types/legal-entities/bankAccount';
import { LegalEntitiesBankAccountDTO } from '~/types/dto/legalEntities/bankAccount';
import {
  ILegalEntitiesBankAccountAdapterService
} from '~/services/adapters/legalEntities/legalEntitiesAdapterService.interfaces';
import { DateUtils } from '~/utils/date';

@injectable()
export class LegalEntitiesBankAccountAdapterService implements ILegalEntitiesBankAccountAdapterService {
  getData(initData: LegalEntitiesBankAccount[]): LegalEntitiesBankAccountDTO[] {
    return (initData ?? []).map((data) => ({
      AccountNumber: data.AccountNumber ?? '',
      Bic: data.Bic ?? '',
      Name: data.Name ?? '',
      Source: data.Source ?? '',
      UpdateDate: new Date(data.UpdateDate ?? ''),
      UpdateDateSource: DateUtils.formatDate(data.UpdateDate ?? '')
    }));
  }
} 
