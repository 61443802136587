﻿export enum IndividualFamilyRelationType
{
    Unknown = 0,
    Wife = 1,
    Husband = 2,
    Mother = 3,
    Father = 4,
    Sister = 5,
    Brother = 6,
    Grandmother = 7,
    Grandfather = 8,
    Son = 9,
    Daughter = 10,
    Grandson = 11,
    Granddaughter = 12,
    Uncle = 13,
    Aunt = 14,
    Nephew = 15,
    Niece = 16,
    Stepfather = 17,
    Stepdaughter = 18
}