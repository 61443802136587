import { InjectionKey } from 'vue';
import { ISplittablesHub } from '~/services/splittables/splittablesHub.interfaces';
import { ISplittableSection } from '~/types/splittables/splittableSection';
import { ISplittableContext } from '~/types/splittables/splittableContext';
import NodesPagingRow from '~/models/nodes/paging/nodesPagingRow';

export interface ISplittableRowLayoutingServiceLayoutOptions {
  pageHeight: (pageIdx: number) => number
}

export interface ISplittableRowLayoutingServiceInitialLayoutOptions {
  pageHeight: number
}

export interface ISplittableRowLayoutingService {
  layoutAsync<T>(hub: ISplittablesHub, row: NodesPagingRow<T>, options: ISplittableRowLayoutingServiceLayoutOptions): Promise<ISplittableSection<T>[]>
  getInitialLayoutAsync<T>(hub: ISplittablesHub, row: NodesPagingRow<T>, options: ISplittableRowLayoutingServiceInitialLayoutOptions): Promise<ISplittableContext<T>>
}

export const ISplittableRowLayoutingServiceKey: InjectionKey<ISplittableRowLayoutingService> = Symbol('ISplittableRowLayoutingService');
