import { injectable, inject } from 'inversify';
import { IDataBlobReportOriginalInputFileInfoPlaceholderServiceKey, IDataBlobInputDataListPlaceholderServiceKey } from '~/services/placeholder/blob/dataBlobPlaceholderService.interfaces';
import { IPlaceholderDataService } from '~/services/placeholder/common/reportPlaceholderService.interfaces';
import { DataBlobReportOriginalInput } from '~/types/blobs/data-blob-report-original-input';
import { GenericDataBlob } from '~/types/blobs/generic-data-blob';
import { GenericDataBlobData } from '~/types/blobs/generic-data-blob-data';
import { ListDataBlobWrapperReportData } from '~/types/blobs/list-data-blob-wrapper';
import { IUniversalPlaceholderService } from './dataBlobDataWrapperPlaceholderService.interfaces';
import { UIReportType } from '~/types/reportType';
import { IndividualDataBlobData } from '~/types/blobs/individual-data-blob-data';
import { LegalEntitiesDataBlobData } from '~/types/blobs/legal-entities-data-blob-data';
import { VehiclesDataBlobData } from '~/types/blobs/vehicles-data-blob-data';
import { IIndividualDataBlobDataPlaceholderServiceKey } from '../individual/individualPlaceholderDataService.interfaces';
import { ILegalEntitiesDataBlobDataPlaceholderServiceKey } from '../legalEntities/legalEntitiesPlaceholderDataService.interfaces';
import { IVehiclesDataBlobDataPlaceholderServiceKey } from '../vehicles/vehiclesPlaceholderService.interfaces';
import { ErrorMessages } from '~/resources/errorMessages';
import { PhoneNumbersDataBlobData } from '~/types/blobs/phone-numbers-data-blob-data';
import { IPhoneNumbersDataBlobDataPlaceholderServiceKey } from '../phoneNumber/phoneNumberPlaceholderService.interface';

@injectable()
export class DataBlobDataWrapperPlaceholderService implements IUniversalPlaceholderService<GenericDataBlob> {
  constructor(
    @inject(ILegalEntitiesDataBlobDataPlaceholderServiceKey as symbol) private legalEntitiesDataBlobDataPlaceholderService: IPlaceholderDataService<LegalEntitiesDataBlobData>,
    @inject(IIndividualDataBlobDataPlaceholderServiceKey as symbol) private individualDataBloDataPlaceholderService: IPlaceholderDataService<IndividualDataBlobData>,
    @inject(IVehiclesDataBlobDataPlaceholderServiceKey as symbol) private vehiclesDataBlobDataPlaceholderService: IPlaceholderDataService<VehiclesDataBlobData>,
    @inject(IPhoneNumbersDataBlobDataPlaceholderServiceKey as symbol) private phoneNumbersDataBlobDataPlaceholderService: IPlaceholderDataService<PhoneNumbersDataBlobData>,

    @inject(IDataBlobReportOriginalInputFileInfoPlaceholderServiceKey as symbol) private dataBlobReportOriginalInputFileInfoPlaceholderService: IPlaceholderDataService<DataBlobReportOriginalInput>,
    @inject(IDataBlobInputDataListPlaceholderServiceKey as symbol) private dataBlobInputDataListPlaceholderService: IPlaceholderDataService<ListDataBlobWrapperReportData<GenericDataBlobData>>
  ) {}
  getData(type: UIReportType): GenericDataBlob {
    return {
      ReportInputData: this.getPlaceholderData(type),
      ReportData: this.dataBlobInputDataListPlaceholderService.getData(),
      ReportType: 0,
      MainEntitiesIds: [],
      ReportGenerationDateTime: '',
      ReportTypeRus: '',
      OriginalInput: this.dataBlobReportOriginalInputFileInfoPlaceholderService.getData()
    };
  }

  private getPlaceholderData(type: UIReportType): GenericDataBlobData {
    switch (type) {
      case UIReportType.ul:
        return this.legalEntitiesDataBlobDataPlaceholderService.getData();
      case UIReportType.fl:
        return this.individualDataBloDataPlaceholderService.getData();
      case UIReportType.vehicle:
        return this.vehiclesDataBlobDataPlaceholderService.getData();
      case UIReportType.phoneNumber:
        return this.phoneNumbersDataBlobDataPlaceholderService.getData();

      default: 
        throw new Error(ErrorMessages.NORMALIZE_TYPE_NOTE_FOUND);
    }
  }
}
