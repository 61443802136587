import { injectable } from 'inversify';
import { StringUtils } from '~/utils/stringUtils';
import { DateUtils } from '~/utils/date';
import { ReportIndividualEntrepreneurDTO } from '~/types/dto/common/reportIndividualEntrepreneurDTO';
import { ReportIndividualEntrepreneur } from '~/types/common/reportIndividualEntrepreneur';
import { IReportIndividualEntrepreneurAdapterService } from './reportAdapterService.interfeces';

@injectable()
export class ReportIndividualEntrepreneurAdapterService implements IReportIndividualEntrepreneurAdapterService {
  getData(initData: ReportIndividualEntrepreneur[]): ReportIndividualEntrepreneurDTO[] {
    return (initData ?? []).map((item) => ({
      Status: StringUtils.quoteReplacement(item.Status),
      PrincipalActivity: StringUtils.quoteReplacement(item.PrincipalActivity).trim(),
      RegistrationDate: DateUtils.formatDate(item.RegistrationDate),
      LiquidationDate: DateUtils.formatDate(item.LiquidationDate),
      Inn: item.Inn ?? '',
      Ogrn: item.Ogrn,
      Name: StringUtils.quoteReplacement(item.Name ?? ''),
    }));
  }
}
