import { injectable } from 'inversify';
import { IPlaceholderDataService } from '../common/reportPlaceholderService.interfaces';
import { ReportVettingInfo } from '~/types/common/vettingInfo';

@injectable()
export class ReportVettingInfoPlaceholderService implements IPlaceholderDataService<ReportVettingInfo> {
  getData(): ReportVettingInfo {
    return {
      StartDate: '',
      EndDate: '',
      RootType: 0,
      InputType: 0,
      Decision: 0,
      Comment: '',
      UserId: '',
      User: ''
    };
  }
}
