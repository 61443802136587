import { InjectionKey } from 'vue';
import { IConfigService } from '~/services/configService.interface';
import { LegalEntitiesBriefInfoDTO } from '~/types/dto/legalEntities/briefInfo';
import { LegalEntitiesDataBlobDataDTO } from '~/types/dto/blobs/legalEntitiesDataBlobDataDTO';
import { LegalEntitiesCompanyManagementCompaniesDTO } from '~/types/dto/legalEntities/companyManagementCompaniesDTO';
import { LegalEntitiesCompanyHeadFlDTO } from '~/types/dto/legalEntities/companyHeadFlDTO';

export type ILegalEntitiesGeneralInformationBaseConfigService = IConfigService<LegalEntitiesBriefInfoDTO>;
export type ILegalEntitiesGeneralInformationRegistrationDataConfigService = IConfigService<LegalEntitiesDataBlobDataDTO>;
export type ILegalEntitiesGeneralInformationManagementCompanyConfigService = IConfigService<LegalEntitiesCompanyManagementCompaniesDTO>;
export type ILegalEntitiesGeneralInformationManagementCompanyInfoConfigService = IConfigService<LegalEntitiesCompanyManagementCompaniesDTO>;
export type ILegalEntitiesGeneralInformationLeaderConfigService = IConfigService<LegalEntitiesCompanyHeadFlDTO>;

export const ILegalEntitiesGeneralInformationManagementCompanyConfigServiceKey: InjectionKey<ILegalEntitiesGeneralInformationManagementCompanyConfigService> = Symbol('ILegalEntitiesGeneralInformationManagementCompanyConfigService');
export const ILegalEntitiesGeneralInformationManagementCompanyInfoConfigServiceKey: InjectionKey<ILegalEntitiesGeneralInformationManagementCompanyInfoConfigService> = Symbol('ILegalEntitiesGeneralInformationManagementCompanyInfoConfigService');
export const ILegalEntitiesGeneralInformationBaseConfigServiceKey: InjectionKey<ILegalEntitiesGeneralInformationBaseConfigService> = Symbol('ILegalEntitiesGeneralInformationBaseConfigService');
export const ILegalEntitiesGeneralInformationRegistrationDataConfigServiceKey: InjectionKey<ILegalEntitiesGeneralInformationRegistrationDataConfigService> = Symbol('ILegalEntitiesGeneralInformationRegistrationDataConfigService');
export const ILegalEntitiesGeneralInformationLeaderConfigServiceKey: InjectionKey<ILegalEntitiesGeneralInformationLeaderConfigService> = Symbol('ILegalEntitiesGeneralInformationLeaderConfigService');
