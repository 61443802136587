import { injectable, inject } from 'inversify';

import { LegalEntitiesReportFinancialIndicatorWithConclusions } from '~/types/legal-entities/reportFinancialIndicatorWithConclusions';
import { LegalEntitiesReportFinancialStability } from '~/types/legal-entities/reportFinancialStability';
import { IPlaceholderDataService } from '../common/reportPlaceholderService.interfaces';
import { ILegalEntitiesReportFinancialIndicatorWithConclusionsPlaceholderDataServiceKey } from './legalEntitiesPlaceholderDataService.interfaces';

@injectable()
export class LegalEntitiesReportFinancialStabilityPlaceholderDataService implements IPlaceholderDataService<LegalEntitiesReportFinancialStability> {
  constructor(
    @inject(ILegalEntitiesReportFinancialIndicatorWithConclusionsPlaceholderDataServiceKey as symbol) private reportFinancialIndicatorWithConclusionsPlaceholderDataService: IPlaceholderDataService<LegalEntitiesReportFinancialIndicatorWithConclusions>,
  ) {}

  getData(): LegalEntitiesReportFinancialStability {
    return {
      NetAssets: this.reportFinancialIndicatorWithConclusionsPlaceholderDataService.getData(),
      RatioOfConcentrationOfEquity: this.reportFinancialIndicatorWithConclusionsPlaceholderDataService.getData(),
      LevelOfFinancialLeverage: this.reportFinancialIndicatorWithConclusionsPlaceholderDataService.getData(),
      RatioOfSecurityOfInterestToPayment: this.reportFinancialIndicatorWithConclusionsPlaceholderDataService.getData(),
      RatioOfTotalDebtToProfitFromSales: this.reportFinancialIndicatorWithConclusionsPlaceholderDataService.getData(),
    };
  }
}
