/* eslint-disable max-len, class-methods-use-this */

import { injectable, inject } from 'inversify';
import { LegalEntitiesFinancialStateDTO } from '~/types/dto/legalEntities/financialStateDTO';
import {
  ILegalEntitiesFinancialCommonAdapterService,
  ILegalEntitiesFinancialCommonAdapterServiceKey,
  ILegalEntitiesFinancialStateAdapterService,
  ILegalEntitiesObjectReportDataBuhFormAdapterService,
  ILegalEntitiesObjectReportDataBuhFormAdapterServiceKey,
  ILegalEntitiesReportDataBuhFormAdapterService,
  ILegalEntitiesReportDataBuhFormAdapterServiceKey
} from '~/services/adapters/legalEntities/legalEntitiesAdapterService.interfaces';
import { LegalEntitiesFinancialState } from '~/types/legal-entities/financialState';
import {
  IReportUrlAdapterService,
  IReportUrlAdapterServiceKey
} from '~/services/adapters/common/reportAdapterService.interfeces';
import {
  IPlaceholderDataService,
  IReportUrlPlaceholderServiceKey
} from '~/services/placeholder/common/reportPlaceholderService.interfaces';
import { ReportUrl } from '~/types/common/reportUrl';
import {
  ILegalEntitiesReportDataBuhFormPlaceholderDataServiceKey
} from '~/services/placeholder/legalEntities/legalEntitiesPlaceholderDataService.interfaces';
import { LegalEntitiesReportDataBuhForm } from '~/types/legal-entities/reportDataBuhForm';
import {
  IArrayAdapterService,
  IReportArrayAdapterServiceKey
} from '~/services/adapters/common/reportArrayAdapterService.interfaces';

@injectable()
export class LegalEntitiesFinancialAdapterService implements ILegalEntitiesFinancialStateAdapterService {
  constructor(
    @inject(IReportArrayAdapterServiceKey as symbol) private reportArrayAdapterService: IArrayAdapterService,
    @inject(IReportUrlAdapterServiceKey as symbol) private reportUrlAdapterService: IReportUrlAdapterService,
    @inject(IReportUrlPlaceholderServiceKey as symbol) private reportUrlPlaceholderService: IPlaceholderDataService<ReportUrl>,
    @inject(ILegalEntitiesReportDataBuhFormPlaceholderDataServiceKey as symbol) private legalEntitiesReportDataBuhFormPlaceholderDataService: IPlaceholderDataService<LegalEntitiesReportDataBuhForm>,
      
    @inject(ILegalEntitiesFinancialCommonAdapterServiceKey as symbol) private legalEntitiesFinancialCommonAdapterService: ILegalEntitiesFinancialCommonAdapterService,
    @inject(ILegalEntitiesReportDataBuhFormAdapterServiceKey as symbol) private legalEntitiesReportDataBuhFormAdapterService: ILegalEntitiesReportDataBuhFormAdapterService,
    @inject(ILegalEntitiesObjectReportDataBuhFormAdapterServiceKey as symbol) private legalEntitiesObjectReportDataBuhFormAdapterService: ILegalEntitiesObjectReportDataBuhFormAdapterService,
  
  ) {}
  getData(initData: LegalEntitiesFinancialState): LegalEntitiesFinancialStateDTO {
    const { mapData } = this.reportArrayAdapterService;
    return {
      ListFinancialCommon: this.legalEntitiesFinancialCommonAdapterService.getData(initData.ListFinancialCommon),
      ListDebts: this.legalEntitiesFinancialCommonAdapterService.getData(initData.ListDebts),
      ListAssets: this.legalEntitiesFinancialCommonAdapterService.getData(initData.ListAssets),
      ListProfitability: this.legalEntitiesFinancialCommonAdapterService.getData(initData.ListProfitability),
      ListLiquidity: this.legalEntitiesFinancialCommonAdapterService.getData(initData.ListLiquidity),
      ListBalanceAsset: this.legalEntitiesFinancialCommonAdapterService.getData(initData.ListBalanceAsset),
      ListBalancePassive: this.legalEntitiesFinancialCommonAdapterService.getData(initData.ListBalancePassive),
      IncomeAndExpenses: this.legalEntitiesFinancialCommonAdapterService.getData(initData.IncomeAndExpenses),
      IncomeAndExpensesOperations: this.legalEntitiesFinancialCommonAdapterService.getData(initData.IncomeAndExpensesOperations),
      CoefListAssets: this.legalEntitiesFinancialCommonAdapterService.getData(initData.CoefListAssets),
      CoefListDebts: this.legalEntitiesFinancialCommonAdapterService.getData(initData.CoefListDebts),
      CoefListLiquidity: this.legalEntitiesFinancialCommonAdapterService.getData(initData.CoefListLiquidity),
      CoefListProfitability: this.legalEntitiesFinancialCommonAdapterService.getData(initData.CoefListProfitability),
      Coef105: this.legalEntitiesFinancialCommonAdapterService.getData(initData.Coef105),
      CoefOther: this.legalEntitiesFinancialCommonAdapterService.getData(initData.CoefOther),
      DataFNS: this.legalEntitiesFinancialCommonAdapterService.getData(initData.DataFNS),
      FirstRecordDate: initData.FirstRecordDate ?? '',
      BuhFormLastYear: this.legalEntitiesObjectReportDataBuhFormAdapterService.getData(initData.BuhFormLastYear ?? this.legalEntitiesReportDataBuhFormPlaceholderDataService.getData()),
      BuhFormLast2Year: this.legalEntitiesReportDataBuhFormAdapterService.getData(initData.BuhFormLast2Year),
      FinancialCommonLinks: mapData(initData.FinancialCommonLinks, (item) => this.reportUrlAdapterService.getData(item)),
      LiquidityLinks: mapData(initData.LiquidityLinks, (item) => this.reportUrlAdapterService.getData(item)),
      FinancialExpressAllPeriodUrl: this.reportUrlAdapterService.getData(initData.FinancialExpressAllPeriodUrl ?? this.reportUrlPlaceholderService.getData()),
      FinancialCoefAllPeriodUrl: this.reportUrlAdapterService.getData(initData.FinancialCoefAllPeriodUrl ?? this.reportUrlPlaceholderService.getData()),
      CompanySize: initData.CompanySize,
    };
  }
}
