import { InjectionKey } from 'vue';
import { DataBlobReportOriginalInput } from '~/types/blobs/data-blob-report-original-input';
import { DataBlobReportOriginalInputFileInfo } from '~/types/blobs/data-blob-report-original-input-file-info';
import { GenericDataBlobData } from '~/types/blobs/generic-data-blob-data';
import { ListDataBlobWrapperReportData } from '~/types/blobs/list-data-blob-wrapper';
import { IPlaceholderDataService } from '../common/reportPlaceholderService.interfaces';

export const IDataBlobReportOriginalInputPlaceholderServiceKey: InjectionKey<IPlaceholderDataService<DataBlobReportOriginalInput>> = Symbol();
export const IDataBlobReportOriginalInputFileInfoPlaceholderServiceKey: InjectionKey<IPlaceholderDataService<DataBlobReportOriginalInputFileInfo>> = Symbol();
export const IDataBlobInputDataListPlaceholderServiceKey: InjectionKey<IPlaceholderDataService<ListDataBlobWrapperReportData<GenericDataBlobData>>> = Symbol();
