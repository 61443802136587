import { injectable, inject } from 'inversify';
import { LegalEntitiesReportFinancialIndicatorWithConclusions } from '~/types/legal-entities/reportFinancialIndicatorWithConclusions';

import { LegalEntitiesReportLiquidityAndSolvency } from '~/types/legal-entities/reportLiquidityAndSolvency';
import { IPlaceholderDataService } from '../common/reportPlaceholderService.interfaces';
import { ILegalEntitiesReportFinancialIndicatorWithConclusionsPlaceholderDataServiceKey } from './legalEntitiesPlaceholderDataService.interfaces';

@injectable()
export class LegalEntitiesReportLiquidityAndSolvencyPlaceholderDataService implements IPlaceholderDataService<LegalEntitiesReportLiquidityAndSolvency> {
  constructor(
    @inject(ILegalEntitiesReportFinancialIndicatorWithConclusionsPlaceholderDataServiceKey as symbol) private reportFinancialIndicatorWithConclusionsPlaceholderDataService: IPlaceholderDataService<LegalEntitiesReportFinancialIndicatorWithConclusions>,
  ) {}

  getData(): LegalEntitiesReportLiquidityAndSolvency {
    return {
      OwnWorkingCapital: this.reportFinancialIndicatorWithConclusionsPlaceholderDataService.getData(),
      RatioOfCurrentLiquidity: this.reportFinancialIndicatorWithConclusionsPlaceholderDataService.getData(),
      RatioOfSolvency: this.reportFinancialIndicatorWithConclusionsPlaceholderDataService.getData(),
      DegreeOfSolvency: this.reportFinancialIndicatorWithConclusionsPlaceholderDataService.getData(),
      RatioOfReceivablesToTotalAssets: this.reportFinancialIndicatorWithConclusionsPlaceholderDataService.getData(),
    };
  }
}
