import { inject, injectable } from 'inversify';
import { get } from 'lodash-es';
import { IOwnWorkingCapitalTableItemConfigService } from '~/modules/legalEntities/financialAnalytics/legalEntitiesLiquidityAndSolvency/services/legalEntitiesLiquidityAndSolvencyTableItemConfigService.interfaces';
import { LegalEntitiesReportFinancialIndicatorWithConclusionsDTO } from '~/types/dto/legalEntities/reportFinancialIndicatorWithConclusionsDTO';
import { TableItem } from '~/modules/table/types/tableItem';
import {
  IFinancialAnalysisCommonTitlesService,
  IFinancialAnalysisCommonTitlesServiceKey
} from '~/modules/legalEntities/financialAnalytics/common/services/financialAnalysisCommonTitlesService.interfaces';
import { FINANCIAL_ANALYSIS_EXTENDED_TITLES } from '~/modules/legalEntities/financialAnalytics/common/configuration/constants/financialAnalysisExtendedTitles';
import {
  IConvertingPrimitiveTableTypes,
  IConvertingPrimitiveTableTypesKey
} from '~/modules/legalEntities/financialAnalytics/common/services/convertingPrimitiveTableTypes.interfaces';

@injectable()
export class OwnWorkingCapitalTableItemConfigService implements IOwnWorkingCapitalTableItemConfigService {
  constructor(
    @inject(IFinancialAnalysisCommonTitlesServiceKey as symbol) private commonTitlesService: IFinancialAnalysisCommonTitlesService,
    @inject(IConvertingPrimitiveTableTypesKey as symbol) private convertingPrimitiveTableTypes: IConvertingPrimitiveTableTypes
  ) {}
  getItem(initData: LegalEntitiesReportFinancialIndicatorWithConclusionsDTO): TableItem {
    const calculationObject = [
      initData.TwoYearsAgo,
      initData.CurrentYear,
      initData.LastYear
    ];

    const maxValue = Math.max(...[Math.abs(initData.TwoYearsAgo), Math.abs(initData.CurrentYear), Math.abs(initData.LastYear)]);

    return {
      TwoYearsAgo: { value: this.convertingPrimitiveTableTypes.currencyConversion(initData.TwoYearsAgo, maxValue), color: this.getColor(initData.TwoYearsAgo) },
      LastYear: { value: this.convertingPrimitiveTableTypes.currencyConversion(initData.LastYear, maxValue), color: this.getColor(initData.LastYear) },
      CurrentYear: { value: this.convertingPrimitiveTableTypes.currencyConversion(initData.CurrentYear, maxValue), color: this.getColor(initData.CurrentYear) },
      Conclusions: get(initData.Conclusions, 0, '—'),
      indicator: {
        title: this.commonTitlesService.getTitle(FINANCIAL_ANALYSIS_EXTENDED_TITLES.OwnWorkingCapital, calculationObject),
      },
      referenceValue: '> 0',
    };
  }

  private getColor(value: number): string {
    return value > 0 ? 'green' : 'red';
  }
}
