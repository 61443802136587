import { COMPANY_QUOTE_REPLACEMENT_REGEX } from '~/regex/companyQuoteReplacement';
import { CONTAINS_ONLY_DIGITS_REGEX } from '~/regex/containsOnlyDigits';
import { REMOVE_NEWLINE_REGEX } from '~/regex/removeNewLine';
import { REMOVE_SPACES_REGEX } from '~/regex/removeSpaces';
import { REMOVE_TAGS_REGEX } from '~/regex/removeTags';
import { REMOVE_COMA_SPACE_REGEX } from '~/regex/removeComaSpace';
import { REMOVE_TAB_REGEX } from '~/regex/removeTab';
import { REMOVE_CARRIAGE_RETURN_REGEX } from '~/regex/removeCarriageReturn';
import { REMOVE_BACKSLASH_REGEX } from '~/regex/removeBackslash';

export module StringUtils {
  export function CapitalizeString(unformattedString: string) {
    let formattedString = unformattedString;

    if (formattedString.length === 1) {
      return formattedString.toUpperCase();
    }

    if (formattedString.length > 1) {
      formattedString = formattedString[0].toUpperCase()
        + formattedString.slice(1).toLowerCase();
    }

    return formattedString;
  }

  export function replaceCommas(value: string) {
    return value.replaceAll(/,{2,}/g, ',');
  }

  export function quoteReplacement(value: string) {
    return value.replace(/(``|<<|>>|['`«»])/g, '"');
  }

  export function companyQuoteReplacement(value: string) {
    return value.replace(COMPANY_QUOTE_REPLACEMENT_REGEX, '«$1»').replace(/"/g, '');
  }

  export function capitalizeCase(str: string) {
    return str
      .toLowerCase()
      .replace(/(^[a-zа-яё]{1})|(\s+[a-zа-яё]{1})/gi, (letter) => letter.toUpperCase());
  }

  export function normalizePassport(str: string | null | undefined) {
    return (str ?? '').length > 4 ? `${str?.slice(0, 4)} №${str?.slice(4)}` : '';
  }

  export function isStringNotEmpty(value: string | null | undefined): value is string {
    return value !== null && value !== '' && value !== undefined;
  }

  export function lowerCaseFirstLetter(value: string) {
    if (value.length > 1) {
      return value[0].toLowerCase() + value.slice(1);
    }
    return value[0].toLowerCase();
  }

  export function isString(str: unknown): str is string {
    return typeof str === 'string';
  }

  export function truncateString(str: string, maxLength: number): string {
    if (str.length > maxLength) {
      const midpoint = Math.ceil(str.length / 2);
      const toRemove = str.length - maxLength + 3;
      const left = str.slice(0, midpoint - Math.ceil(toRemove / 2));
      const right = str.slice(midpoint + Math.floor(toRemove / 2));
      return `${left}...${right}`;
    }
    return str;
  }

  export function toPluralInNominative(lenght: number, singular: string, plural: string) {
    return lenght > 1 ? plural : singular;
  }

  export function toPlural(number: number, singular: string, genitive: string, plural: string) {
    let count = number % 100;

    if (count >= 5 && count <= 20) return plural;
    else {
      count %= 10;

      if (count == 1) return singular;
      else if (count >= 2 && count <= 4) return genitive;
      else return plural;
    }
  }

  export function cyrillicToTranslit(text: string) {
    return text
      .replaceAll('а', 'a')
      .replaceAll('А', 'A')
      .replaceAll('в', 'b')
      .replaceAll('В', 'B')
      .replaceAll('с', 'c')
      .replaceAll('С', 'C')
      .replaceAll('е', 'e')
      .replaceAll('Е', 'E')
      .replaceAll('т', 't')
      .replaceAll('Т', 'T')
      .replaceAll('о', 'o')
      .replaceAll('О', 'O')
      .replaceAll('р', 'p')
      .replaceAll('Р', 'P')
      .replaceAll('н', 'h')
      .replaceAll('Н', 'H')
      .replaceAll('к', 'k')
      .replaceAll('К', 'k')
      .replaceAll('х', 'x')
      .replaceAll('Х', 'X')
      .replaceAll('м', 'm')
      .replaceAll('М', 'M');
  }

  export function replaceTags(text: string, to: string) {
    return text.replaceAll(REMOVE_TAGS_REGEX, to);
  }

  export function cleanString(inputString: string) {
    // убираем теги
    const stringWithoutTags = inputString.replace(REMOVE_TAGS_REGEX, '');
    // убираем символы новой строки
    const stringWithoutNewlines = stringWithoutTags.replace(REMOVE_NEWLINE_REGEX, '');

    const stringWithoutSpaceComma = stringWithoutNewlines.replace(REMOVE_COMA_SPACE_REGEX, '');

    // убираем лишние пробелы
    return stringWithoutSpaceComma.replace(REMOVE_SPACES_REGEX, ' ').trim();
  }
  
  export function preparedToEqualString(str: string) {
    return str.toLowerCase().replaceAll('ё', 'е');
  }

  export function isEqualStrings(firstStr: string, secondStr: string) {
    const firstPreparedStr = preparedToEqualString(firstStr);
    const secondPreparedStr = preparedToEqualString(secondStr);

    const hasPersonInRelation = firstPreparedStr.includes(secondPreparedStr) && secondPreparedStr.length > 1;
    const hasRelationInPerson = secondPreparedStr.includes(firstPreparedStr) && firstPreparedStr.length > 1;

    return hasPersonInRelation || hasRelationInPerson;
  }

  export function containsOnlyDigits(inputString: string): boolean {
    return CONTAINS_ONLY_DIGITS_REGEX.test(inputString);
  }

  export function removeEscapeCharacters(inputString: string): string {
    return inputString
      .replace(REMOVE_NEWLINE_REGEX, '')
      .replace(REMOVE_CARRIAGE_RETURN_REGEX, '')
      .replace(REMOVE_TAB_REGEX, '')
      .replace(REMOVE_BACKSLASH_REGEX, '');
  }
}
