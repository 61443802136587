import { InjectionKey } from 'nuxt/dist/app/compat/capi';
import { UIReportType } from '~/types/reportType';
import { LegalEntitiesDataBlob } from '~/types/blobs/legal-entities-data-blob';
import { IndividualDataBlob } from '~/types/blobs/individual-data-blob';
import { VehiclesDataBlob } from '~/types/blobs/vehicles-data-blob';
import { PhoneNumbersDataBlob } from '~/types/blobs/phone-numbers-data-blob';
import { GenericDataBlob } from '~/types/blobs/generic-data-blob';

export interface IDataService {
  getDataAsync(type: UIReportType.ul): Promise<LegalEntitiesDataBlob>;
  getDataAsync(type: UIReportType.fl): Promise<IndividualDataBlob>;
  getDataAsync(type: UIReportType.vehicle): Promise<VehiclesDataBlob>;
  getDataAsync(type: UIReportType.phoneNumber): Promise<PhoneNumbersDataBlob>;
  getDataAsync(type: UIReportType): Promise<GenericDataBlob>;
}

export const IDataServiceKey: InjectionKey<IDataService> = Symbol('IDataService');
