import { injectable, inject } from 'inversify';
import { LegalEntitiesCertificateSummary } from '~/types/legal-entities/certificateSummary';
import { IPrimitiveTypesNormalizationServiceKey, IPrimitiveTypesNormalizationService } from '../common/primitiveTypesNormalizationService.interfaces';
import { INormalizationService } from '../common/reportNormalizationService.interfaces';

@injectable()
export class LegalEntitiesCertificateSummaryNormalizationService implements INormalizationService<LegalEntitiesCertificateSummary> {
  constructor(
    @inject(IPrimitiveTypesNormalizationServiceKey as symbol) private primitiveTypesNormalizationService: IPrimitiveTypesNormalizationService,
  ) {}

  normalize(data: any, root: string): LegalEntitiesCertificateSummary | undefined {
    const { normalizeNumber, normalizeString, normalizeObject } = this.primitiveTypesNormalizationService;

    return normalizeObject(data, (data) => ({
      CertificateType: normalizeString(data.CertificateType, `${root}.CertificateType`),
      TotalCount: normalizeNumber(data.TotalCount, `${root}.TotalCount`),
      ActualCount: normalizeNumber(data.ActualCount, `${root}.ActualCount`),
    }), root);
  }
}
