import { injectable } from 'inversify';
import { IReportPossibleBirthdaysAdapterService } from '~/services/adapters/common/reportAdapterService.interfeces';
import { ReportPossibleBirthdays } from '~/types/common/reportPossibleBirthdays';
import { ReportPossibleBirthdaysDTO } from '~/types/dto/common/reportPossibleBirthdaysDTO';
import { StringUtils } from '~/utils/stringUtils';
import { DateUtils } from '~/utils/date';

@injectable()
export class ReportPossibleBirthdaysAdapterService implements IReportPossibleBirthdaysAdapterService {
  getData(initData: ReportPossibleBirthdays[]): ReportPossibleBirthdaysDTO[] {
    return (initData ?? []).map((item) => ({
      Birthday: DateUtils.isValidFormatDate(item.Birthday ?? '') ? item.Birthday ?? '' : '',
      Credibility: item.Credibility,
      CredibilityStr: StringUtils.quoteReplacement(item.CredibilityStr)
    })).filter((item) => DateUtils.isValidDate(DateUtils.formatDate(item.Birthday)));
  }
}
