import { injectable } from 'inversify';

import { ReportWantedCar } from '~/types/common/reportWantedCar';
import { IPlaceholderDataService } from './reportPlaceholderService.interfaces';

@injectable()
export class ReportWantedCarPlaceholderService implements IPlaceholderDataService<ReportWantedCar> {
  getData(): ReportWantedCar {
    return {
      StartDate: ''
    };
  }
}
