import { injectable, inject } from 'inversify';
import { IndividualStageBankruptcy } from '~/types/individual/stageBankruptcy';
import { IPrimitiveTypesNormalizationServiceKey, IPrimitiveTypesNormalizationService } from '../common/primitiveTypesNormalizationService.interfaces';
import { INormalizationService } from '../common/reportNormalizationService.interfaces';

@injectable()
export class IndividualStageBankruptcyNormalizationService implements INormalizationService<IndividualStageBankruptcy> {
  constructor(
    @inject(IPrimitiveTypesNormalizationServiceKey as symbol) private primitiveTypesNormalizationService: IPrimitiveTypesNormalizationService,
  ) {}

  normalize(data: any, root: string): IndividualStageBankruptcy | undefined {
    const { normalizeObject, normalizeString, normalizeOptionalString } = this.primitiveTypesNormalizationService;

    return normalizeObject(data, (data) => ({
      MessageId: normalizeString(data.MessageId, `${root}.MessageId`),
      Stage: normalizeString(data.Stage, `${root}.Stage`),
      PubDate: normalizeOptionalString(data.PubDate, `${root}.PubDate`),
      DecisionDate: normalizeOptionalString(data.DecisionDate, `${root}.DecisionDate`),
    }), root);
  }
}
