import { Environment } from '@/types/Environment';

export default function useEnvironment() {
  if (process.server) {
    return Environment.server;
  }

  if (process.client) {
    return Environment.client;
  }

  return Environment.unknown;
}
