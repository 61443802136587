import { injectable } from 'inversify';
import { GenericDataBlob } from '~/types/blobs/generic-data-blob';
import { GenericDataBlobData } from '~/types/blobs/generic-data-blob-data';
import { IDataListAdapter } from './dataListAdapterServise.interface';

@injectable()
export class DataListAdapter implements IDataListAdapter {
  getDataList(data: GenericDataBlob): GenericDataBlobData[] {
    const inputDataList = data.ReportData?.ReportInputDataList ?? [];
    const inputData = data.ReportInputData;

    if (inputDataList.length > 0) return inputDataList;
    if (inputData != undefined) return [inputData];
    return [];
  }
}
