import { injectable, inject } from 'inversify';
import { ReportSocialNetworkLifePosition } from '~/types/common/reportSocialNetworkLifePosition';
import { IPrimitiveTypesNormalizationServiceKey, IPrimitiveTypesNormalizationService } from './primitiveTypesNormalizationService.interfaces';
import { INormalizationService } from './reportNormalizationService.interfaces';

@injectable()
export class ReportSocialNetworkLifePositionNormalizationService implements INormalizationService<ReportSocialNetworkLifePosition> {
  constructor(
    @inject(IPrimitiveTypesNormalizationServiceKey as symbol) private primitiveTypesNormalizationService: IPrimitiveTypesNormalizationService,
  ) {}

  normalize(data: any, root: string): ReportSocialNetworkLifePosition | undefined {
    const { normalizeObject, normalizeString } = this.primitiveTypesNormalizationService;

    return normalizeObject(data, (data) => ({
      PoliticalPreferences: normalizeString(data.PoliticalPreferences, `${root}.PoliticalPreferences`),
      WorldView: normalizeString(data.WorldView, `${root}.WorldView`),
      MainThingInLife: normalizeString(data.MainThingInLife, `${root}.MainThingInLife`),
      MainThingInPeople: normalizeString(data.MainThingInPeople, `${root}.MainThingInPeople`),
      SmokingView: normalizeString(data.SmokingView, `${root}.SmokingView`),
      AlcoholView: normalizeString(data.AlcoholView, `${root}.AlcoholView`),
      Inspires: normalizeString(data.Inspires, `${root}.Inspires`),
    }), root);
  }
}
