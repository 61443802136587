import { injectable } from 'inversify';
import {
  IReportVehicleRestrictAdapterService
} from '~/services/adapters/common/reportAdapterService.interfeces';
import { ReportVehicleRestrictDTO } from '~/types/dto/common/reportVehicleRestrictDTO';
import { ReportVehicleRestrict } from '~/types/common/reportVehicleRestrict';
import { StringUtils } from '~/utils/stringUtils';

@injectable()
export class ReportVehicleRestrictAdapterService implements IReportVehicleRestrictAdapterService {
  getData(initData: ReportVehicleRestrict[]): ReportVehicleRestrictDTO[] {
    return (initData ?? []).map((restrict) => {
      const [document, fio, spi, ip] = restrict.Reason.match(/([^Документ: ][^СПИ: ][^ИП: ][^,]+)/g) ?? [];

      return {
        Type: StringUtils.quoteReplacement(restrict.Type ?? ''),
        Region: StringUtils.quoteReplacement(restrict.Region ?? ''),
        Document: StringUtils.quoteReplacement(document ?? ''),
        Fio: StringUtils.quoteReplacement(fio ?? ''),
        Spi: StringUtils.quoteReplacement(spi ?? ''),
        Ip: StringUtils.quoteReplacement(ip ?? ''),
        Reason: StringUtils.quoteReplacement(restrict.Reason ?? ''),
      };
    });
  }
}
