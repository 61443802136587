import {
  IReportUrlPlaceholderServiceKey,
  IReportMainEntityFilePlaceholderServiceKey,
  IReportLegalEntitiesParticipationPlaceholderServiceKey,
  IReportVehicleOwnershipPlaceholderServiceKey,
  IReportVehicleRelationPlaceholderServiceKey,
  IReportVehicleInsurancePlaceholderServiceKey,
  IReportPhotosUrlPlaceholderServiceKey,
  IReportVehicleRestrictPlaceholderServiceKey,
  IReportMainEntityFileUrlPlaceholderServiceKey,
  IReportRoadAccidentPlaceholderServiceKey,
  IReportWantedCarPlaceholderServiceKey,
  IReportVehiclePhonePlaceholderServiceKey,
  IReportPledgesRegistryPlaceholderServiceKey,
  IReportVehicleRisksPlaceholderServiceKey,
  IReportVehiclePlaceholderServiceKey,
  IReportMentionPlaceholderServiceKey,
  IReportVehicleParkingPlaceholderServiceKey,
  IReportMarkerPlaceholderServiceKey,
  IReportAnalysisOfAbsoluteLiquidityPlaceholderServiceKey,
  IReportIndicatorsOfCashFlowPlaceholderServiceKey,
  IReportIndicatorsOfProfitabilityPlaceholderServiceKey,
  IReportFinePlaceholderServiceKey,
  IReportMovementCovidPlaceholderServiceKey,
  IReportSocialNetworkTrackPlaceholderServiceKey,
  IReportSocialNetworkSchoolPlaceholderServiceKey,
  IReportSocialNetworkSecondaryEducationPlaceholderServiceKey,
  IReportSocialNetworkHighEducationPlaceholderServiceKey,
  IReportSocialNetworkMilitaryRecordPlaceholderServiceKey,
  IReportSocialNetworkJobPlaceholderServiceKey,
  IReportPersonInnPlaceholderServiceKey,
  IReportPersonSnilsPlaceholderServiceKey,
  IReportPassportPlaceholderServiceKey,
  IReportBoundedPersonPhonePlaceholderServiceKey,
  IReportTagPlaceholderServiceKey,
  IReportEmailPlaceholderServiceKey,
  IReportPersonSitePlaceholderServiceKey,
  IReportSocialNetworkIdPlaceholderServiceKey,
  IReportEducationPlaceholderServiceKey,
  IReportJobPlaceholderServiceKey,
  IReportPaymentPlaceholderServiceKey,
  IReportColleaguePlaceholderServiceKey,
  IReportIndividualEntrepreneurPlaceholderServiceKey,
  IReportPossibleFullNamePlaceholderServiceKey,
  IReportPossibleBirthdaysPlaceholderServiceKey,
  IReportMovementTaxiPlaceholderServiceKey,
  IReportMessageBoardRecordPlaceholderServiceKey,
  IReportMovementCovidForVehiclePlaceholderServiceKey,
  IReportMessengerInfoPlaceholderServiceKey,
  IReportVettingInfoPlaceholderServiceKey,
  IReportConnectionRelatedPlaceholderDataServiceKey,
  IReportAddressPlaceholderServiceKey,
  IReportSocialNetworkAboutInfoPlaceholderServiceKey,
  IReportSocialNetworkLifePositionPlaceholderServiceKey,
  IReportSocialNetworkOtherPersonPlaceholderServiceKey,
  IReportSocialNetworkPlaceholderServiceKey,
} from '~/services/placeholder/common/reportPlaceholderService.interfaces';
import { ReportAnalysisOfAbsoluteLiquidityPlaceholderService } from '~/services/placeholder/common/reportAnalysisOfAbsoluteLiquidityPlaceholderService';
import { ReportIndicatorsOfCashFlowPlaceholderService } from '~/services/placeholder/common/reportIndicatorsOfCashFlowPlaceholderService';
import { ReportIndicatorsOfProfitabilityPlaceholderService } from '~/services/placeholder/common/reportIndicatorsOfProfitabilityPlaceholderService';
import { ReportLegalEntitiesParticipationPlaceholderService } from '~/services/placeholder/common/reportLegalEntitiesParticipationPlaceholderService';
import { ReportMainEntityFilePlaceholderService } from '~/services/placeholder/common/reportMainEntityFilePlaceholderService';
import { ReportMainEntityFileUrlPlaceholderService } from '~/services/placeholder/common/reportMainEntityFileUrlPlaceholderService';
import { ReportMarkerPlaceholderService } from '~/services/placeholder/common/reportMarkerPlaceholderService';
import { ReportMentionPlaceholderService } from '~/services/placeholder/common/reportMentionPlaceholderService';
import { ReportPhotosUrlPlaceholderService } from '~/services/placeholder/common/reportPhotosUrlPlaceholderService';
import { ReportPledgesRegistryPlaceholderService } from '~/services/placeholder/common/reportPledgesRegistryPlaceholderService';
import { ReportRoadAccidentPlaceholderService } from '~/services/placeholder/common/reportRoadAccidentPlaceholderService';
import { ReportUrlPlaceholderService } from '~/services/placeholder/common/reportUrlPlaceholderService';
import { ReportVehicleInsurancePlaceholderService } from '~/services/placeholder/common/reportVehicleInsurancePlaceholderService';
import { ReportVehicleOwnershipPlaceholderService } from '~/services/placeholder/common/reportVehicleOwnershipParticipationPlaceholderService';
import { ReportVehicleParkingPlaceholderService } from '~/services/placeholder/common/reportVehicleParkingPlaceholderService';
import { ReportVehiclePhonePlaceholderService } from '~/services/placeholder/common/reportVehiclePhonePlaceholderService';
import { ReportVehiclePlaceholderService } from '~/services/placeholder/common/reportVehiclePlaceholderService';
import { ReportVehicleRelationPlaceholderService } from '~/services/placeholder/common/reportVehicleRelationPlaceholderService';
import { ReportVehicleRestrictPlaceholderService } from '~/services/placeholder/common/reportVehicleRestrictPlaceholderService';
import { ReportVehicleRisksPlaceholderService } from '~/services/placeholder/common/reportVehicleRisksPlaceholderService';
import { ReportWantedCarPlaceholderService } from '~/services/placeholder/common/reportWantedCarPlaceholderService';
import { IContainerBinder } from './containerAccessor.interface';
import { ReportSocialNetworkTrackPlaceholderService } from '~/services/placeholder/common/reportSocialNetworkTrackPlaceholderService';
import { ReportSocialNetworkSchoolPlaceholderService } from '~/services/placeholder/common/reportSocialNetworkSchoolPlaceholderService';
import { ReportSocialNetworkSecondaryEducationPlaceholderService } from '~/services/placeholder/common/reportSocialNetworkSecondaryEducationPlaceholderService';
import { ReportSocialNetworkHighEducationPlaceholderService } from '~/services/placeholder/common/reportSocialNetworkHighEducationPlaceholderService';
import { ReportSocialNetworkMilitaryRecordPlaceholderService } from '~/services/placeholder/common/reportSocialNetworkMilitaryRecordPlaceholderService';
import { ReportSocialNetworkJobPlaceholderService } from '~/services/placeholder/common/reportSocialNetworkJobPlaceholderService';
import { ReportPersonInnPlaceholderService } from '~/services/placeholder/common/reportPersonInnPlaceholderService';
import { ReportPersonSnilsPlaceholderService } from '~/services/placeholder/common/reportPersonSnilsPlaceholderService';
import { ReportPassportPlaceholderService } from '~/services/placeholder/common/reportPassportPlaceholderService';
import { ReportBoundedPersonPhonePlaceholderService } from '~/services/placeholder/common/reportBoundedPersonPhonePlaceholderService';
import { ReportTagPlaceholderService } from '~/services/placeholder/common/reportTagPlaceholderService';
import { ReportEmailPlaceholderService } from '~/services/placeholder/common/reportEmailPlaceholderService';
import { ReportPersonSitePlaceholderService } from '~/services/placeholder/common/reportPersonSitePlaceholderService';
import { ReportSocialNetworkIdPlaceholderService } from '~/services/placeholder/common/reportSocialNetworkIdPlaceholderService';
import { ReportEducationPlaceholderService } from '~/services/placeholder/common/reportEducationPlaceholderService';
import { ReportJobPlaceholderService } from '~/services/placeholder/common/reportJobPlaceholderService';
import { ReportPaymentPlaceholderService } from '~/services/placeholder/common/reportPaymentPlaceholderService';
import { ReportColleaguePlaceholderService } from '~/services/placeholder/common/reportColleaguePlaceholderService';
import { ReportIndividualEntrepreneurPlaceholderService } from '~/services/placeholder/common/reportIndividualEntrepreneurPlaceholderService';
import { ReportFinePlaceholderService } from '~/services/placeholder/common/reportFinePlaceholderService';
import { ReportPossibleFullNamePlaceholderService } from '~/services/placeholder/common/reportPossibleFullNamePlaceholderService';
import { ReportPossibleBirthdaysPlaceholderService } from '~/services/placeholder/common/reportPossibleBirthdaysPlaceholderService';
import { ReportMovementCovidPlaceholderService } from '~/services/placeholder/common/reportMovementCovidPlaceholderService';
import { ReportMovementTaxiPlaceholderService } from '~/services/placeholder/common/reportMovementTaxiPlaceholderService';
import { ReportMessageBoardRecordPlaceholderService } from '~/services/placeholder/common/reportMessageBoardRecordPlaceholderService';
import { ReportMovementCovidForVehiclePlaceholderService } from '~/services/placeholder/common/reportMovementCovidForVehiclePlaceholderService';
import { ReportMessengerInfoPlaceholderService } from '~/services/placeholder/common/reportMessengerInfoPlaceholderService';
import { ReportVettingInfoPlaceholderService } from '~/services/placeholder/legalEntities/legalEntitiesVettingInfoPlaceholderService';
import {
  ReportConnectionRelatedPlaceholderDataService
} from '~/services/placeholder/common/reportConnectionRelatedPlaceholderDataService';
import { ReportAddressPlaceholderService } from '~/services/placeholder/common/reportAddressPlaceholderService';
import { ReportMessengerInfoAdapterService } from '~/services/adapters/common/reportMessengerInfoAdapterService';
import { IReportMessengerInfoAdapterServiceKey } from '~/services/adapters/common/reportAdapterService.interfeces';
import { ReportSocialNetworkAboutInfoPlaceholderService } from '~/services/placeholder/common/reportSocialNetworkAboutInfoPlaceholderService';
import { ReportSocialNetworkLifePositionPlaceholderService } from '~/services/placeholder/common/reportSocialNetworkLifePositionPlaceholderService';
import { ReportSocialNetworkOtherPersonPlaceholderService } from '~/services/placeholder/common/reportSocialNetworkOtherPersonPlaceholderService';
import { ReportSocialNetworkPlaceholderService } from '~/services/placeholder/common/reportSocialNetworkPlaceholderService';

export function bindCommonPlaceholderServices(bind: IContainerBinder) {
  bind(IReportUrlPlaceholderServiceKey).to(ReportUrlPlaceholderService);
  bind(IReportMainEntityFilePlaceholderServiceKey).to(ReportMainEntityFilePlaceholderService);
  bind(IReportLegalEntitiesParticipationPlaceholderServiceKey).to(ReportLegalEntitiesParticipationPlaceholderService);
  bind(IReportVehicleOwnershipPlaceholderServiceKey).to(ReportVehicleOwnershipPlaceholderService);
  bind(IReportVehicleRelationPlaceholderServiceKey).to(ReportVehicleRelationPlaceholderService);
  bind(IReportVehicleInsurancePlaceholderServiceKey).to(ReportVehicleInsurancePlaceholderService);
  bind(IReportPhotosUrlPlaceholderServiceKey).to(ReportPhotosUrlPlaceholderService);
  bind(IReportVehicleRestrictPlaceholderServiceKey).to(ReportVehicleRestrictPlaceholderService);
  bind(IReportMainEntityFileUrlPlaceholderServiceKey).to(ReportMainEntityFileUrlPlaceholderService);
  bind(IReportRoadAccidentPlaceholderServiceKey).to(ReportRoadAccidentPlaceholderService);
  bind(IReportWantedCarPlaceholderServiceKey).to(ReportWantedCarPlaceholderService);
  bind(IReportVehiclePhonePlaceholderServiceKey).to(ReportVehiclePhonePlaceholderService);
  bind(IReportPledgesRegistryPlaceholderServiceKey).to(ReportPledgesRegistryPlaceholderService);
  bind(IReportVehicleRisksPlaceholderServiceKey).to(ReportVehicleRisksPlaceholderService);
  bind(IReportVehiclePlaceholderServiceKey).to(ReportVehiclePlaceholderService);
  bind(IReportMentionPlaceholderServiceKey).to(ReportMentionPlaceholderService);
  bind(IReportVehicleParkingPlaceholderServiceKey).to(ReportVehicleParkingPlaceholderService);
  bind(IReportMarkerPlaceholderServiceKey).to(ReportMarkerPlaceholderService);
  bind(IReportAnalysisOfAbsoluteLiquidityPlaceholderServiceKey).to(ReportAnalysisOfAbsoluteLiquidityPlaceholderService);
  bind(IReportIndicatorsOfCashFlowPlaceholderServiceKey).to(ReportIndicatorsOfCashFlowPlaceholderService);
  bind(IReportIndicatorsOfProfitabilityPlaceholderServiceKey).to(ReportIndicatorsOfProfitabilityPlaceholderService);
  bind(IReportSocialNetworkTrackPlaceholderServiceKey).to(ReportSocialNetworkTrackPlaceholderService);
  bind(IReportSocialNetworkSchoolPlaceholderServiceKey).to(ReportSocialNetworkSchoolPlaceholderService);
  bind(IReportSocialNetworkSecondaryEducationPlaceholderServiceKey).to(ReportSocialNetworkSecondaryEducationPlaceholderService);
  bind(IReportSocialNetworkHighEducationPlaceholderServiceKey).to(ReportSocialNetworkHighEducationPlaceholderService);
  bind(IReportSocialNetworkMilitaryRecordPlaceholderServiceKey).to(ReportSocialNetworkMilitaryRecordPlaceholderService);
  bind(IReportSocialNetworkJobPlaceholderServiceKey).to(ReportSocialNetworkJobPlaceholderService);
  bind(IReportPersonInnPlaceholderServiceKey).to(ReportPersonInnPlaceholderService);
  bind(IReportPersonSnilsPlaceholderServiceKey).to(ReportPersonSnilsPlaceholderService);
  bind(IReportPassportPlaceholderServiceKey).to(ReportPassportPlaceholderService);
  bind(IReportBoundedPersonPhonePlaceholderServiceKey).to(ReportBoundedPersonPhonePlaceholderService);
  bind(IReportTagPlaceholderServiceKey).to(ReportTagPlaceholderService);
  bind(IReportEmailPlaceholderServiceKey).to(ReportEmailPlaceholderService);
  bind(IReportPersonSitePlaceholderServiceKey).to(ReportPersonSitePlaceholderService);
  bind(IReportSocialNetworkIdPlaceholderServiceKey).to(ReportSocialNetworkIdPlaceholderService);
  bind(IReportEducationPlaceholderServiceKey).to(ReportEducationPlaceholderService);
  bind(IReportJobPlaceholderServiceKey).to(ReportJobPlaceholderService);
  bind(IReportPaymentPlaceholderServiceKey).to(ReportPaymentPlaceholderService);
  bind(IReportColleaguePlaceholderServiceKey).to(ReportColleaguePlaceholderService);
  bind(IReportIndividualEntrepreneurPlaceholderServiceKey).to(ReportIndividualEntrepreneurPlaceholderService);
  bind(IReportFinePlaceholderServiceKey).to(ReportFinePlaceholderService);
  bind(IReportPossibleFullNamePlaceholderServiceKey).to(ReportPossibleFullNamePlaceholderService);
  bind(IReportPossibleBirthdaysPlaceholderServiceKey).to(ReportPossibleBirthdaysPlaceholderService);
  bind(IReportMovementCovidPlaceholderServiceKey).to(ReportMovementCovidPlaceholderService);
  bind(IReportMovementTaxiPlaceholderServiceKey).to(ReportMovementTaxiPlaceholderService);
  bind(IReportMessageBoardRecordPlaceholderServiceKey).to(ReportMessageBoardRecordPlaceholderService);
  bind(IReportMovementCovidForVehiclePlaceholderServiceKey).to(ReportMovementCovidForVehiclePlaceholderService);
  bind(IReportMessengerInfoPlaceholderServiceKey).to(ReportMessengerInfoPlaceholderService);
  bind(IReportVettingInfoPlaceholderServiceKey).to(ReportVettingInfoPlaceholderService);
  bind(IReportConnectionRelatedPlaceholderDataServiceKey).to(ReportConnectionRelatedPlaceholderDataService);
  bind(IReportAddressPlaceholderServiceKey).to(ReportAddressPlaceholderService);
  bind(IReportMessengerInfoAdapterServiceKey).to(ReportMessengerInfoAdapterService);
  bind(IReportSocialNetworkAboutInfoPlaceholderServiceKey).to(ReportSocialNetworkAboutInfoPlaceholderService);
  bind(IReportSocialNetworkLifePositionPlaceholderServiceKey).to(ReportSocialNetworkLifePositionPlaceholderService);
  bind(IReportSocialNetworkPlaceholderServiceKey).to(ReportSocialNetworkPlaceholderService);
  bind(IReportSocialNetworkOtherPersonPlaceholderServiceKey).to(ReportSocialNetworkOtherPersonPlaceholderService);
}
