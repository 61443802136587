import { injectable, inject } from 'inversify';
import { LegalEntitiesCompanyManagementCompanies } from '~/types/legal-entities/companyManagementCompanies';
import { IPrimitiveTypesNormalizationServiceKey, IPrimitiveTypesNormalizationService } from '../common/primitiveTypesNormalizationService.interfaces';
import { INormalizationService } from '../common/reportNormalizationService.interfaces';

@injectable()
export class LegalEntitiesCompanyManagementCompaniesNormalizationService implements INormalizationService<LegalEntitiesCompanyManagementCompanies> {
  constructor(
    @inject(IPrimitiveTypesNormalizationServiceKey as symbol) private primitiveTypesNormalizationService: IPrimitiveTypesNormalizationService,
  ) {}

  normalize(data: any, root: string): LegalEntitiesCompanyManagementCompanies | undefined {
    const {
      normalizeString,
      normalizeOptionalString,
      normalizeOptionalBoolean,
      normalizeObject
    } = this.primitiveTypesNormalizationService;

    return normalizeObject(data, (data) => ({
      Name: normalizeString(data.Name, `${root}.Name`),
      Inn: normalizeString(data.Inn, `${root}.Inn`),
      Ogrn: normalizeString(data.Ogrn, `${root}.Ogrn`),
      Date: normalizeOptionalString(data.Date, `${root}.Date`),
      FirstDate: normalizeOptionalString(data.FirstDate, `${root}.FirstDate`),
      IsInaccuracy: normalizeOptionalBoolean(data.IsInaccuracy, `${root}.IsInaccuracy`),
      InaccuracyDate: normalizeOptionalString(data.InaccuracyDate, `${root}.InaccuracyDate`),
    }), root);
  }
}
