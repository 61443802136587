import { injectable } from 'inversify';

import { LegalEntitiesRelatedReason } from '~/types/legal-entities/relatedReason';
import { IPlaceholderDataService } from '../common/reportPlaceholderService.interfaces';

@injectable()
export class LegalEntitiesRelatedReasonPlaceholderDataService implements IPlaceholderDataService<LegalEntitiesRelatedReason> {
  getData() {
    return {
      Name: '',
      Description: '',
      Type: '',
    };
  }
}
