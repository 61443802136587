import { IContainerBinder } from '~/configuration/inversify/containerAccessor.interface';
import {
  IIndividualAviasAdapterServiceKey,
  IIndividualBusinessTripAdapterServiceKey,
  IIndividualCommonInfoAdapterServiceKey,
  IIndividualDataBlobDataServiceKey,
  IIndividualDriverLicenseAdapterServiceKey,
  IIndividualGroupAdapterServiceKey,
  IIndividualLegalEntitiesParticipationRestrictionsAdapterServiceKey,
  IIndividualHolidayAdapterServiceKey,
  IIndividualJobRecommendationAdapterServiceKey,
  IIndividualMilitaryRecordAdapterServiceKey,
  IIndividualPhoneAdapterServiceKey,
  IIndividualSkypeAdapterServiceKey,
  IIndividualEnforcementProceedingsAdapterServiceKey,
  IIndividualFamilyListAdapterServiceKey,
  IIndividualUserListAdapterServiceKey,
  IIndividualSpecialSignsAdapterServiceKey,
  IIndividualCommonNegativeInfoAdapterServiceKey,
  IIndividualBankruptcyAdapterServiceKey,
  IIndividualStageBankruptcyAdapterServiceKey,
  IIndividualMessageAboutBankruptcyAdapterServiceKey,
  IIndividualCriminalPenaltiesAdapterServiceKey,
  IIndividualFinancialScoringAdapterServiceKey,
  IIndividualTrustScoringAdapterServiceKey,
  IIndividualWantedMvdAdapterServiceKey,
  IIndividualWantedFsinAdapterServiceKey,
  IIndividualWantedFsspAdapterServiceKey,
  IIndividualFullNameChangeAdapterServiceKey,
  IIndividualTypeAdapterServiceKey,
  IIndividualTerroristsAndExtremistsRecordAdapterServiceKey,
  IIndividualAccrualAdapterServiceKey,
  IIndividualWantedAdapterServiceKey,
  IIndividualMovementBusAdapterServiceKey,
  IIndividualMaritalStatusHistoryAdapterServiceKey, IIndividualMarkersAdapterServiceKey
} from '~/services/adapters/individual/individualAdapterService.interfaces';
import { IndividualCommonInfoAdapterService } from '~/services/adapters/individual/individualCommonInfoAdapterService';
import { IndividualDataBlobAdapterService } from '~/services/adapters/individual/individualDataBlobAdapterService';
import { IndividualGroupAdapterService } from '~/services/adapters/individual/individualGroupAdapterService';
import { IndividualUserListAdapterService } from '~/services/adapters/individual/individualUserListAdapterService';
import { IndividualMilitaryRecordAdapterService } from '~/services/adapters/individual/individualMilitaryRecordAdapterService';
import { IndividualPhoneAdapterService } from '~/services/adapters/individual/individualPhoneAdapterService';
import { IndividualSkypeAdapterService } from '~/services/adapters/individual/individualSkypeAdapterService';
import { IndividualDriverLicenseAdapterService } from '~/services/adapters/individual/individualDriverLicenseAdapterService';
import { IndividualLegalEntitiesParticipationRestrictionsAdapterService } from '~/services/adapters/individual/individualLegalEntitiesParticipationRestrictionsAdapterService';
import { IndividualJobRecommendationAdapterService } from '~/services/adapters/individual/individualJobRecommendationAdapterService';
import { IndividualBusinessTripAdapterService } from '~/services/adapters/individual/individualBusinessTripAdapterService';
import { IndividualHolidayAdapterService } from '~/services/adapters/individual/individualHolidayAdapterService';
import { IndividualEnforcementProceedingsAdapterService } from '~/services/adapters/individual/individualEnforcementProceedingsAdapterService';
import { IndividualAviasAdapterService } from '~/services/adapters/individual/individualAviasAdapterService';
import { IndividualSpecialSignsAdapterService } from '~/services/adapters/individual/individualSpecialSignsAdapterService';
import { IndividualCommonNegativeInfoAdapterService } from '~/services/adapters/individual/individualCommonNegativeInfoAdapterService';
import { IndividualBankruptcyAdapterService } from '~/services/adapters/individual/individualBankruptcyAdapterService';
import { IndividualStageBankruptcyAdapterService } from '~/services/adapters/individual/individualStageBankruptcyAdapterService';
import { IndividualMessageAboutBankruptcyAdapterService } from '~/services/adapters/individual/individualMessageAboutBankruptcyAdapterService';
import { IndividualCriminalPenaltiesAdapterService } from '~/services/adapters/individual/individualCriminalPenaltiesAdapterService';
import { IndividualFinancialScoringAdapterService } from '~/services/adapters/individual/individualFinancialScoringAdapterService';
import { IndividualTrustScoringAdapterService } from '~/services/adapters/individual/individualTrustScoringAdapterService';
import { IndividualFamilyListAdapterService } from '~/services/adapters/individual/individualFamilyListAdapterService';
import { IndividualWantedMvdAdapterService } from '~/services/adapters/individual/individualWantedMvdAdapterService';
import { IndividualWantedFsinAdapterService } from '~/services/adapters/individual/individualWantedFsinAdapterService';
import { IndividualWantedFsspAdapterService } from '~/services/adapters/individual/individualWantedFsspAdapterService';
import { IndividualFullNameChangeAdapterService } from '~/services/adapters/individual/individualFullNameChangeAdapterService';
import { IndividualTypeAdapterService } from '~/services/adapters/individual/individualTypeAdapterService';
import {
  IndividualTerroristsAndExtremistsRecordAdapterService
} from '~/services/adapters/individual/individualTerroristsAndExtremistsRecordAdapterService';
import { IndividualAccrualAdapterService } from '~/services/adapters/individual/individualAccrualAdapterService';
import { IndividualWantedAdapterService } from '~/services/adapters/individual/individualWantedAdapterService';
import { IndividualMovementBusAdapterService } from '~/services/adapters/individual/individualMovementBusAdapterService';
import {
  IndividualMaritalStatusHistoryAdapterService
} from '~/services/adapters/individual/individualMaritalStatusHistoryAdapterService';
import { IndividualMarkersAdapterService } from '~/services/adapters/individual/individualMarkersAdapterService';

export function bindIndividualAdapterServices(bind: IContainerBinder) {
  bind(IIndividualDataBlobDataServiceKey).to(IndividualDataBlobAdapterService);
  bind(IIndividualCommonInfoAdapterServiceKey).to(IndividualCommonInfoAdapterService);
  bind(IIndividualGroupAdapterServiceKey).to(IndividualGroupAdapterService);
  bind(IIndividualUserListAdapterServiceKey).to(IndividualUserListAdapterService);
  bind(IIndividualMilitaryRecordAdapterServiceKey).to(IndividualMilitaryRecordAdapterService);
  bind(IIndividualPhoneAdapterServiceKey).to(IndividualPhoneAdapterService);
  bind(IIndividualSkypeAdapterServiceKey).to(IndividualSkypeAdapterService);
  bind(IIndividualDriverLicenseAdapterServiceKey).to(IndividualDriverLicenseAdapterService);
  bind(IIndividualLegalEntitiesParticipationRestrictionsAdapterServiceKey).to(IndividualLegalEntitiesParticipationRestrictionsAdapterService);
  bind(IIndividualJobRecommendationAdapterServiceKey).to(IndividualJobRecommendationAdapterService);
  bind(IIndividualBusinessTripAdapterServiceKey).to(IndividualBusinessTripAdapterService);
  bind(IIndividualHolidayAdapterServiceKey).to(IndividualHolidayAdapterService);
  bind(IIndividualAviasAdapterServiceKey).to(IndividualAviasAdapterService);
  bind(IIndividualSpecialSignsAdapterServiceKey).to(IndividualSpecialSignsAdapterService);
  bind(IIndividualCommonNegativeInfoAdapterServiceKey).to(IndividualCommonNegativeInfoAdapterService);
  bind(IIndividualBankruptcyAdapterServiceKey).to(IndividualBankruptcyAdapterService);
  bind(IIndividualFamilyListAdapterServiceKey).to(IndividualFamilyListAdapterService);
  bind(IIndividualStageBankruptcyAdapterServiceKey).to(IndividualStageBankruptcyAdapterService);
  bind(IIndividualMessageAboutBankruptcyAdapterServiceKey).to(IndividualMessageAboutBankruptcyAdapterService);
  bind(IIndividualCriminalPenaltiesAdapterServiceKey).to(IndividualCriminalPenaltiesAdapterService);
  bind(IIndividualFinancialScoringAdapterServiceKey).to(IndividualFinancialScoringAdapterService);
  bind(IIndividualTrustScoringAdapterServiceKey).to(IndividualTrustScoringAdapterService);
  bind(IIndividualEnforcementProceedingsAdapterServiceKey).to(IndividualEnforcementProceedingsAdapterService);
  bind(IIndividualWantedMvdAdapterServiceKey).to(IndividualWantedMvdAdapterService);
  bind(IIndividualWantedFsinAdapterServiceKey).to(IndividualWantedFsinAdapterService);
  bind(IIndividualWantedFsspAdapterServiceKey).to(IndividualWantedFsspAdapterService);
  bind(IIndividualFullNameChangeAdapterServiceKey).to(IndividualFullNameChangeAdapterService);
  bind(IIndividualTypeAdapterServiceKey).to(IndividualTypeAdapterService);
  bind(IIndividualTerroristsAndExtremistsRecordAdapterServiceKey).to(IndividualTerroristsAndExtremistsRecordAdapterService);
  bind(IIndividualAccrualAdapterServiceKey).to(IndividualAccrualAdapterService);
  bind(IIndividualWantedAdapterServiceKey).to(IndividualWantedAdapterService);
  bind(IIndividualMovementBusAdapterServiceKey).to(IndividualMovementBusAdapterService);
  bind(IIndividualMaritalStatusHistoryAdapterServiceKey).to(IndividualMaritalStatusHistoryAdapterService);
  bind(IIndividualMarkersAdapterServiceKey).to(IndividualMarkersAdapterService);
}
