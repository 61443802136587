import { injectable, inject } from 'inversify';
import {
  IReportSocialNetworkHighEducationAdapterService,
  IReportSocialNetworkHighEducationAdapterServiceKey,
  IReportSocialNetworkJobAdapterService,
  IReportSocialNetworkJobAdapterServiceKey,
  IReportSocialNetworkMilitaryRecordAdapterService,
  IReportSocialNetworkMilitaryRecordAdapterServiceKey,
  IReportSocialNetworkSchoolAdapterService,
  IReportSocialNetworkSchoolAdapterServiceKey,
  IReportSocialNetworkSecondaryEducationAdapterService,
  IReportSocialNetworkSecondaryEducationAdapterServiceKey,
  IReportSocialNetworkTrackAdapterService
} from '~/services/adapters/common/reportAdapterService.interfeces';
import { ReportSocialNetworkTrack } from '~/types/common/reportSocialNetworkTrack';
import { ReportSocialNetworkTrackDTO } from '~/types/dto/common/reportSocialNetworkTrackDTO';

@injectable()
export class ReportSocialNetworkTrackAdapterService implements IReportSocialNetworkTrackAdapterService {
  constructor(
    @inject(IReportSocialNetworkSchoolAdapterServiceKey as symbol) private reportSocialNetworkSchoolAdapterService: IReportSocialNetworkSchoolAdapterService,
    @inject(IReportSocialNetworkSecondaryEducationAdapterServiceKey as symbol) private reportSocialNetworkSecondaryEducationAdapterService: IReportSocialNetworkSecondaryEducationAdapterService,
    @inject(IReportSocialNetworkHighEducationAdapterServiceKey as symbol) private reportSocialNetworkHighEducationAdapterService: IReportSocialNetworkHighEducationAdapterService,
    @inject(IReportSocialNetworkMilitaryRecordAdapterServiceKey as symbol) private reportSocialNetworkMilitaryRecordAdapterService: IReportSocialNetworkMilitaryRecordAdapterService,
    @inject(IReportSocialNetworkJobAdapterServiceKey as symbol) private reportSocialNetworkJobAdapterService: IReportSocialNetworkJobAdapterService
  ) {}
  getData(initData: ReportSocialNetworkTrack): ReportSocialNetworkTrackDTO {
    return {
      SocialNetworkSchools: this.reportSocialNetworkSchoolAdapterService.getData(initData.SocialNetworkSchools),
      SocialNetworkSecondaryEducations: this.reportSocialNetworkSecondaryEducationAdapterService.getData(initData.SocialNetworkSecondaryEducations),
      SocialNetworkHighEducations: this.reportSocialNetworkHighEducationAdapterService.getData(initData.SocialNetworkHighEducations),
      SocialNetworkMilitaryRecords: this.reportSocialNetworkMilitaryRecordAdapterService.getData(initData.SocialNetworkMilitaryRecords),
      SocialNetworkJobs: this.reportSocialNetworkJobAdapterService.getData(initData.SocialNetworkJobs),
      IsExists: initData.IsExists
    };
  }
}
