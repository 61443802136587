import { injectable, inject } from 'inversify';
import { sortBy, uniqWith } from 'lodash-es';
import { IIndividualPhoneAdapterService } from '~/services/adapters/individual/individualAdapterService.interfaces';
import { IndividualPhone } from '~/types/individual/phone';
import { IndividualPhoneDTO } from '~/types/dto/individual/phoneDTO';
import { StringUtils } from '~/utils/stringUtils';
import { PhoneUtils } from '~/utils/phone';
import { DateUtils } from '~/utils/date';
import {
  IReportMentionAdapterService,
  IReportMentionAdapterServiceKey,
  IReportMessengerInfoAdapterService,
  IReportMessengerInfoAdapterServiceKey
} from '~/services/adapters/common/reportAdapterService.interfeces';

@injectable()
export class IndividualPhoneAdapterService implements IIndividualPhoneAdapterService {
  constructor(
    @inject(IReportMessengerInfoAdapterServiceKey as symbol) private individualMessengerInfoAdapterService: IReportMessengerInfoAdapterService,
    @inject(IReportMentionAdapterServiceKey as symbol) private individualMentionAdapterService: IReportMentionAdapterService
  ) {}

  getData(initData: IndividualPhone[]): IndividualPhoneDTO[] {
    const phonesList = (initData ?? []).map((item) => ({
      PhoneType: StringUtils.quoteReplacement(item.PhoneType),
      Value: PhoneUtils.getIsValidPhoneNumber(item.Value) ? PhoneUtils.normalizePhoneNumber(item.Value) : '',
      Country: StringUtils.quoteReplacement(item.Country ?? ''),
      Provider: StringUtils.quoteReplacement(item.Provider ?? ''),
      TimeZone: StringUtils.quoteReplacement(item.TimeZone ?? ''),
      IsVerified: item.IsVerified,
      Messengers: this.individualMessengerInfoAdapterService.getData(item.Messengers),
      ChangeDate: DateUtils.formatDate(item.ChangeDate),
      DeviceModel: StringUtils.quoteReplacement(item.DeviceModel),
      VerificationCode: item.VerificationCode,
      Mentions: this.individualMentionAdapterService.getData(item.Mentions)
    }))
      .filter((phone) => phone.PhoneType !== 'Не определен');

    const uniqPhonesList = uniqWith(phonesList, (arrVal, othVal) => arrVal.Value === othVal.Value);

    return sortBy(
      uniqPhonesList
        .sort((a, b) => a.PhoneType.localeCompare(b.PhoneType))
        .sort((a) => (a.PhoneType.toLowerCase().includes('мобильный') || a.PhoneType !== 'Стационарный') ? -1 : 1)
        .sort((a) => {
          if (a.PhoneType.toLowerCase().includes('мобильный')) {
            return -1;
          }
          if (a.PhoneType !== 'Стационарный') {
            return 1;
          }
          return 0;
        })
        .sort((a, b) => Number(b.IsVerified) - Number(a.IsVerified)),
      (phone) => {
        if (phone.Mentions.length > 0) {
          if (phone.IsVerified) return 0;
          return 50;
        }
        return 100;
      }
    );
  }
}
