import { injectable } from 'inversify';
import { IMessageBoardsGeneralInfoConfigService } from '~/modules/reportsCommon/reportMessageBoardRecord/services/messageBoardsConfigService.interfaces';
import { ReportMessageBoardRecordDTO } from '~/types/dto/common/messageBoardsDTO';
import { Config } from '~/types/field-list/config';
import { MessageBoardsUtils } from '~/modules/reportsCommon/reportMessageBoardRecord/utils/messageBoardsUtils';

@injectable()
export class MessageBoardsGeneralInfoConfigService implements IMessageBoardsGeneralInfoConfigService {
  getConfig(initData: ReportMessageBoardRecordDTO): Config {
    const messageSourceType = MessageBoardsUtils.getMessageBoardsTypeFromUrl(initData.MessageSourceType);
    const socialName = MessageBoardsUtils.formatMessageBoardsType(messageSourceType) || initData.MessageSourceType;
    
    return {
      fieldsDirection: 'column',
      columnGap: '40px',
      fields: [
        {
          key: 'contactface',
          label: 'Автор объявления',
          value: { text: initData.Contactface, width: '346px' },
        },
        {
          key: 'requestNumber',
          label: 'Абонентский номер',
          value: { text: initData.RequestNumber, width: '274px' },
        },
        {
          key: 'socialName',
          label: 'Площадка',
          value: { text: socialName, width: '274px' },
        },

      ].filter((item) => item.value)
    };
  }
}
