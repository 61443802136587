import { injectable, inject } from 'inversify';
import { IMetaDataAdapterService } from './metaDataAdapterService.interface';
import { DataBlobMetadataDTO } from '~/types/dto/common/dataBlobMetadata';
import { GenericDataBlob } from '~/types/blobs/generic-data-blob';
import { IMetaDataBlobReportOriginalInputAdapterService, IMetaDataBlobReportOriginalInputAdapterServiceKey } from './metaDataBlobReportOriginalInputAdapterService.interfaces';

@injectable()
export class MetaDataAdapterService implements IMetaDataAdapterService {
  constructor(
    @inject(IMetaDataBlobReportOriginalInputAdapterServiceKey as symbol) private metaDataBlobReportOriginalInputAdapterService: IMetaDataBlobReportOriginalInputAdapterService
  ) {}
  
  getData(dataBlob: GenericDataBlob | undefined): DataBlobMetadataDTO {
    return {
      InputReportType: dataBlob?.OriginalInput?.InputReportType ?? 0,
      ReportGenerationDateTime: dataBlob?.ReportGenerationDateTime ?? '',
      OriginalInput: this.metaDataBlobReportOriginalInputAdapterService.getData(dataBlob?.OriginalInput),
    };
  }
}
