import { injectable } from 'inversify';
import { ILegalEntitiesReportAnalysisOfAbsoluteLiquidityIndicatorInfoService } from '~/modules/legalEntities/financialAnalytics/legalEntitiesReportAnalysisOfAbsoluteLiquidity/services/legalEntitiesReportAnalysisOfAbsoluteLiquidityIndicatorInfoService.interfaces';
import { LegalEntitiesReportAnalysisOfAbsoluteLiquidityDTO } from '~/types/dto/legalEntities/reportAnalysisOfAbsoluteLiquidity';
import { LegalEntitiesReportAnalysisOfAbsoluteLiquidityIndicatorInfo } from '~/modules/legalEntities/financialAnalytics/legalEntitiesReportAnalysisOfAbsoluteLiquidity/types/legalEntitiesReportAnalysisOfAbsoluteLiquidityIndicatorInfo';

@injectable()
export class LegalEntitiesReportAnalysisOfAbsoluteLiquidityIndicatorInfoService implements ILegalEntitiesReportAnalysisOfAbsoluteLiquidityIndicatorInfoService {
  getDataInfo(initData: LegalEntitiesReportAnalysisOfAbsoluteLiquidityDTO): LegalEntitiesReportAnalysisOfAbsoluteLiquidityIndicatorInfo {
    return {
      MostLiquidAssetsToMostUrgentLiabilities: {
        ...initData.MostLiquidAssetsToMostUrgentLiabilities,
        AssetsDescription: 'Наиболее ликвидные активы - денежные средства, краткосрочные финансовые вложения',
        LiabilitiesDescription: 'Наиболее срочные обязательства - кредиторская задолженность'
      },
      MarketableAssetsToOneYearLoanObligations: {
        ...initData.MarketableAssetsToOneYearLoanObligations,
        AssetsDescription: 'Быстрореализуемые активы - дебиторская задолженность',
        LiabilitiesDescription: 'Заемные обязательства, подлежащие погашению в течение 12 мес.'
      },
      SlowSellingAssetsToLongTermBorrowings: {
        ...initData.SlowSellingAssetsToLongTermBorrowings,
        AssetsDescription: 'Медленно реализуемые активы - Запасы, НДС',
        LiabilitiesDescription: 'Долгосрочные заемные средства'
      },
      HardSellingAssetsToCapitalAndReserves: {
        ...initData.HardSellingAssetsToCapitalAndReserves,
        AssetsDescription: 'Труднореализуемые активы - внеоборотные активы за исключением финансовых вложений',
        LiabilitiesDescription: 'Долгосрочные заемные средства'
      }
    };
  }
}
