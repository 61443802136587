import { injectable } from 'inversify';
import { IIndividualSkypeAdapterService } from '~/services/adapters/individual/individualAdapterService.interfaces';
import { IndividualSkype } from '~/types/individual/skype';
import { IndividualSkypeDTO } from '~/types/dto/individual/skypeDTO';
import { StringUtils } from '~/utils/stringUtils';

@injectable()
export class IndividualSkypeAdapterService implements IIndividualSkypeAdapterService {
  getData(initData: IndividualSkype[]): IndividualSkypeDTO[] {
    return (initData ?? '').map((item) => ({
      Value: StringUtils.quoteReplacement(item.Value)
    }));
  }
}
