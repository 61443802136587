import { injectable } from 'inversify';
import { IIndividualJobRecommendationAdapterService } from '~/services/adapters/individual/individualAdapterService.interfaces';
import { IndividualJobRecommendation } from '~/types/individual/jobRecommendation';
import { IndividualJobRecommendationDTO } from '~/types/dto/individual/jobRecommendationDTO';
import { StringUtils } from '~/utils/stringUtils';

@injectable()
export class IndividualJobRecommendationAdapterService implements IIndividualJobRecommendationAdapterService {
  getData(initData: IndividualJobRecommendation[]): IndividualJobRecommendationDTO[] {
    return (initData ?? []).map((item) => ({
      RecommenderFullName: StringUtils.quoteReplacement(item.RecommenderFullName),
      RecommenderCompanyName: StringUtils.quoteReplacement(item.RecommenderCompanyName),
      RecommenderCompanyOpf: StringUtils.quoteReplacement(item.RecommenderCompanyOpf),
      RecommenderCompanyInn: StringUtils.quoteReplacement(item.RecommenderCompanyInn),
      RecommenderPosition: StringUtils.quoteReplacement(item.RecommenderPosition),
      RecommenderPhone: StringUtils.quoteReplacement(item.RecommenderPhone),
    }));
  }
}
