import { injectable, inject } from 'inversify';
import { IndividualJobRecommendation } from '~/types/individual/jobRecommendation';
import { IPrimitiveTypesNormalizationServiceKey, IPrimitiveTypesNormalizationService } from '../common/primitiveTypesNormalizationService.interfaces';
import { INormalizationService } from '../common/reportNormalizationService.interfaces';

@injectable()
export class IndividualJobRecommendationNormalizationService implements INormalizationService<IndividualJobRecommendation> {
  constructor(
    @inject(IPrimitiveTypesNormalizationServiceKey as symbol) private primitiveTypesNormalizationService: IPrimitiveTypesNormalizationService,
  ) {}

  normalize(data: any, root: string): IndividualJobRecommendation | undefined {
    const { normalizeObject, normalizeString } = this.primitiveTypesNormalizationService;

    return normalizeObject(data, (data) => ({
      RecommenderFullName: normalizeString(data.RecommenderFullName, `${root}.RecommenderFullName`),
      RecommenderCompanyName: normalizeString(data.RecommenderCompanyName, `${root}.RecommenderCompanyName`),
      RecommenderCompanyOpf: normalizeString(data.RecommenderCompanyOpf, `${root}.RecommenderCompanyOpf`),
      RecommenderCompanyInn: normalizeString(data.RecommenderCompanyInn, `${root}.RecommenderCompanyInn`),
      RecommenderPosition: normalizeString(data.RecommenderPosition, `${root}.RecommenderPosition`),
      RecommenderPhone: normalizeString(data.RecommenderPhone, `${root}.RecommenderPhone`),
    }), root);
  }
}
