import { injectable } from 'inversify';
import { IndividualWanted } from '~/types/individual/wanted';
import { IPlaceholderDataService } from '../common/reportPlaceholderService.interfaces';

@injectable()
export class IndividualWantedPlaceholderService implements IPlaceholderDataService<IndividualWanted> {
  getData(): IndividualWanted {
    return {
      WantedDateStart: '',
      WantedBasis: '',
      WantedSearchType: '',
      WantedSearchCategory: '',
      WantedPreventiveMeasure: '',
      WantedSearchInitiator: '',
      WantedDateStartCriminal: '',
      WantedDateEndCriminal: '',
      WantedDateTerminationSearch: '',
      WantedArmed: '',
      WantedPreviouslyJudged: '',
      WantedNote: '',
    };
  }
}
