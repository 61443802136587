import { inject, injectable } from 'inversify';
import { IFinancialStabilityLineChartService } from '~/modules/legalEntities/financialAnalytics/lineCharts/financialStabilityLineChart/services/financialAnalysisExtendedService.interfaces';
import { LegalEntitiesFinancialAnalysisExtendedDTO } from '~/types/dto/legalEntities/financialAnalysisExtended';
import { DataOptionLineChart } from '~/modules/legalEntities/financialAnalytics/lineCharts/common/types/DataOptionLineChart';
import {
  IDataOptionsPropertiesLineChartService,
  IDataOptionsPropertiesLineChartServiceKey
} from '~/modules/legalEntities/financialAnalytics/lineCharts/common/services/dataOptionsPropertiesLineChartService.interfaces';
import { LINE_CHART_COLOR_PALETTE } from '~/modules/legalEntities/financialAnalytics/lineCharts/common/configuration/constants/colorPalette';
import { LINE_CHART_GRADIENT_PALETTE } from '~/modules/legalEntities/financialAnalytics/lineCharts/common/configuration/constants/gradientPalette';
import {
  IFinancialAnalysisCommonTitlesService,
  IFinancialAnalysisCommonTitlesServiceKey
} from '~/modules/legalEntities/financialAnalytics/common/services/financialAnalysisCommonTitlesService.interfaces';
import { FINANCIAL_STABILITY_TITLES } from '~/modules/legalEntities/financialAnalytics/common/configuration/constants/financialStabilityTitles';

@injectable()
export class FinancialStabilityLineChartService implements IFinancialStabilityLineChartService {
  constructor(
    @inject(IDataOptionsPropertiesLineChartServiceKey as symbol) private dataOptionsPropertiesLineChartService: IDataOptionsPropertiesLineChartService,
    @inject(IFinancialAnalysisCommonTitlesServiceKey as symbol) private commonTitlesService: IFinancialAnalysisCommonTitlesService
  ) {}

  private readonly FILL_GRADIENT = {
    NetAssets: LINE_CHART_GRADIENT_PALETTE.orange,
    RatioOfConcentrationOfEquity: LINE_CHART_GRADIENT_PALETTE.red,
    LevelOfFinancialLeverage: LINE_CHART_GRADIENT_PALETTE.blue,
    RatioOfSecurityOfInterestToPayment: LINE_CHART_GRADIENT_PALETTE.green,
    RatioOfTotalDebtToProfitFromSales: LINE_CHART_GRADIENT_PALETTE.turquoise,
  };

  private readonly BORDER_COLOR = {
    NetAssets: LINE_CHART_COLOR_PALETTE.orange,
    RatioOfConcentrationOfEquity: LINE_CHART_COLOR_PALETTE.red,
    LevelOfFinancialLeverage: LINE_CHART_COLOR_PALETTE.blue,
    RatioOfSecurityOfInterestToPayment: LINE_CHART_COLOR_PALETTE.green,
    RatioOfTotalDebtToProfitFromSales: LINE_CHART_COLOR_PALETTE.turquoise,
  };
  getDataOptions(initData: LegalEntitiesFinancialAnalysisExtendedDTO): Array<DataOptionLineChart> {
    const { FinancialStability } = initData;

    return Object.keys(FinancialStability).map((key) => {
      const typedKey = key as keyof typeof FinancialStability;

      const data = this.dataOptionsPropertiesLineChartService.getData(FinancialStability[typedKey]);

      return {
        title: this.commonTitlesService.getTitle(FINANCIAL_STABILITY_TITLES[typedKey], data),
        colors: this.FILL_GRADIENT[typedKey],
        borderColors: this.BORDER_COLOR[typedKey],
        labels: this.dataOptionsPropertiesLineChartService.getLabels(initData.TwoYearsAgo, initData.LastYear, initData.CurrentYear),
        data
      };
    });
  }
}
