import { InjectionKey } from 'vue';
import { DataBlobReportOriginalInput } from '~/types/blobs/data-blob-report-original-input';
import { DataBlobReportOriginalInputFileInfo } from '~/types/blobs/data-blob-report-original-input-file-info';
import { GenericDataBlobData } from '~/types/blobs/generic-data-blob-data';
import { ListDataBlobWrapperReportData } from '~/types/blobs/list-data-blob-wrapper';
import { INormalizationService } from '../common/reportNormalizationService.interfaces';
import { IDataBlobDataWrapperNormalizationService } from './dataBlobDataWrapperNormalizationService.interfaces';

export const IDataBlobReportOriginalInputNormalizationServiceKey: InjectionKey<INormalizationService<DataBlobReportOriginalInput>> = Symbol('INormalizationService<DataBlobReportOriginalInput>');
export const IDataBlobReportOriginalInputFileInfoNormalizationServiceKey: InjectionKey<INormalizationService<DataBlobReportOriginalInputFileInfo>> = Symbol('INormalizationService<DataBlobReportOriginalInputFileInfo>');
export const IDataBlobInputDataListNormalizationServiceKey: InjectionKey<IDataBlobDataWrapperNormalizationService<ListDataBlobWrapperReportData<GenericDataBlobData>>> = Symbol('IDataBlobDataWrapperNormalizationService<ListDataBlobWrapperReportData<GenericDataBlobData>>');
