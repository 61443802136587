import { injectable, inject } from 'inversify';
import { StringUtils } from '~/utils/stringUtils';
import {
  IReportMainEntityFileAdapterService,
  IReportMainEntityFileAdapterServiceKey,
  IReportSocialNetworkIdAdapterService,
  IReportSocialNetworkIdAdapterServiceKey,
  IReportSocialNetworkOtherPersonAdapterService,
  IReportSocialNetworkTrackAdapterService,
  IReportSocialNetworkTrackAdapterServiceKey,
  IReportUrlAdapterService,
  IReportUrlAdapterServiceKey,
} from '~/services/adapters/common/reportAdapterService.interfeces';
import { ReportSocialNetworkOtherPerson } from '~/types/common/reportSocialNetworkOtherPerson';
import { ReportSocialNetworkOtherPersonDTO } from '~/types/dto/common/reportSocialNetworkOtherPersonDTO';

@injectable()
export class ReportSocialNetworkOtherPersonAdapterService implements IReportSocialNetworkOtherPersonAdapterService {
  constructor(
    @inject(IReportUrlAdapterServiceKey as symbol) private reportUrlAdapterService: IReportUrlAdapterService,
    @inject(IReportMainEntityFileAdapterServiceKey as symbol) private reportMainEntityFileAdapterService: IReportMainEntityFileAdapterService,
    @inject(IReportSocialNetworkTrackAdapterServiceKey as symbol) private reportSocialNetworkTrackAdapterService: IReportSocialNetworkTrackAdapterService,
    @inject(IReportSocialNetworkIdAdapterServiceKey as symbol) private reportSocialNetworkIdAdapterService: IReportSocialNetworkIdAdapterService
  ) {}
  getData(initData: ReportSocialNetworkOtherPerson[]): ReportSocialNetworkOtherPersonDTO[] {
    return (initData ?? []).map((item) => ({
      FirstName: StringUtils.quoteReplacement(item.FirstName),
      LastName: StringUtils.quoteReplacement(item.LastName),
      NickName: StringUtils.quoteReplacement(item.NickName),
      Birthday: item.Birthday ?? '',
      Url: this.reportUrlAdapterService.getData(item.Url),
      Photo: this.reportMainEntityFileAdapterService.getData(item.Photo),
      City: StringUtils.quoteReplacement(item.City),
      SocialNetworkTrack: this.reportSocialNetworkTrackAdapterService.getData(item.SocialNetworkTrack),
      SocialNetworks: this.reportSocialNetworkIdAdapterService.getData(item.SocialNetworks),
      PhoneNumber: StringUtils.quoteReplacement(item.PhoneNumber)
    }));
  }
}
