import { ISplittableContext } from '~/types/splittables/splittableContext';
import { ISplittableSection } from '~/types/splittables/splittableSection';

export default class SplittableRowContext<T = unknown> implements ISplittableContext<T> {
  constructor(private splittableContexts: ISplittableContext<T>[]) { }

  async applyLayoutAsync(): Promise<ISplittableSection<T>[]> {
    const columnTasks = this.splittableContexts.map(c => c.applyLayoutAsync());
    const columns = await Promise.all(columnTasks);
    const rowsCount = Math.max(...columns.map(column => column.length));
    const result = new Array<ISplittableSection<T>>();

    for (let rowIdx = 0; rowIdx < rowsCount; rowIdx++) {
      const rawCells = columns
        .map(column => column[rowIdx])
        .filter(column => column != undefined);

      const top = Math.min(...rawCells.map(rawCell => rawCell.top));
      const bottom = Math.max(...rawCells.map(rawCell => rawCell.bottom));
      const height = bottom - top;
      const cells = rawCells.flatMap(rawCell => rawCell.cells);

      const section: ISplittableSection<T> = { top, bottom, height, cells };
      result.push(section);
    }

    return result;
  }

  async createSectionAsync(availableHeight: number, forceFit?: boolean): Promise<boolean> {
    const tasks = this.splittableContexts.map(context => context.createSectionAsync(availableHeight, forceFit));
    const flags = await Promise.all(tasks);
    
    return flags.some(v => v);
  }

  hasFreeContent(): boolean {
    return this.splittableContexts.some(s => s.hasFreeContent());
  }
  
  isSplittable(): boolean {
    return this.splittableContexts.some(x => x.isSplittable());
  }
}
