import { injectable } from 'inversify';
import { IReportSubscriberNumberMessengerConfigService } from './reportSubscriberNumberMessengerConfigService.interface';
import { Config } from '~/types/field-list/config';
import { ReportMessengerInfoDTO } from '~/types/dto/common/reportMessengerInfoDTO';
import { StringUtils } from '~/utils/stringUtils';

@injectable()
export class ReportSubscriberNumberMessengerConfigService implements IReportSubscriberNumberMessengerConfigService {
  getConfig(initData: ReportMessengerInfoDTO): Config {
    const nicknameOrIdLabel = StringUtils.containsOnlyDigits(initData.MessengerId) ? 'id' : 'Никнейм';

    return {
      fieldsDirection: 'divider-row',
      fieldsWidth: '100%',
      fieldsValueWidth: '350px',
      isBeginningDivider: true,
      fields: [
        {
          key: 'lastActivity',
          label: 'Последняя активность',
          value: initData.OnlineDate
        },
        {
          key: 'nickname',
          label: nicknameOrIdLabel,
          value: initData.MessengerId
        },
        {
          key: 'status',
          label: 'Статус',
          value: initData.Status,
        },
      ]
    };
  }
}
