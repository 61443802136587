import { injectable, inject } from 'inversify';
import { IndividualUserList } from '~/types/individual/userList';
import { IPrimitiveTypesNormalizationServiceKey, IPrimitiveTypesNormalizationService } from '../common/primitiveTypesNormalizationService.interfaces';
import { INormalizationService } from '../common/reportNormalizationService.interfaces';

@injectable()
export class IndividualUserListNormalizationService implements INormalizationService<IndividualUserList> {
  constructor(
    @inject(IPrimitiveTypesNormalizationServiceKey as symbol) private primitiveTypesNormalizationService: IPrimitiveTypesNormalizationService,
  ) {}

  normalize(data: any, root: string): IndividualUserList | undefined {
    const { normalizeObject, normalizeString } = this.primitiveTypesNormalizationService;

    return normalizeObject(data, (data) => ({
      ListName: normalizeString(data.ListName, `${root}.ListName`),
      ListType: normalizeString(data.ListType, `${root}.ListType`),
    }), root);
  }
}
