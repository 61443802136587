import { injectable } from 'inversify';
import { IIndividualFullNameChangeAdapterService } from '~/services/adapters/individual/individualAdapterService.interfaces';
import { IndividualFullNameChange } from '~/types/individual/fullNameChange';
import { IndividualFullNameChangeDTO } from '~/types/dto/individual/fullNameChangeDTO';
import { StringUtils } from '~/utils/stringUtils';

@injectable()
export class IndividualFullNameChangeAdapterService implements IIndividualFullNameChangeAdapterService {
  getData(initData: IndividualFullNameChange[]): IndividualFullNameChangeDTO[] {
    return (initData ?? []).map((item) => ({
      FirstName: StringUtils.quoteReplacement(item.FirstName),
      LastName: StringUtils.quoteReplacement(item.LastName),
      Patronymic: StringUtils.quoteReplacement(item.Patronymic),
      FullName: StringUtils.quoteReplacement(item.FullName),
      ChangeDate: item.ChangeDate ?? '',
      ChangeReason: StringUtils.quoteReplacement(item.ChangeReason),
    }));
  }
}
