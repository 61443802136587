import { injectable, inject } from 'inversify';
import { ReportPassport } from '~/types/common/reportPassport';
import { IPrimitiveTypesNormalizationServiceKey, IPrimitiveTypesNormalizationService } from './primitiveTypesNormalizationService.interfaces';
import { INormalizationService } from './reportNormalizationService.interfaces';

@injectable()
export class ReportPassportNormalizationService implements INormalizationService<ReportPassport> {
  constructor(
    @inject(IPrimitiveTypesNormalizationServiceKey as symbol) private primitiveTypesNormalizationService: IPrimitiveTypesNormalizationService,
  ) {}

  normalize(data: any, root: string): ReportPassport | undefined {
    const {
      normalizeObject,
      normalizeNumber,
      normalizeString,
      normalizeOptionalString,
      normalizeOptionalBoolean
    } = this.primitiveTypesNormalizationService;

    return normalizeObject(data, (data) => ({
      Type: normalizeNumber(data.Type, `${root}.Type`),
      IsActual: normalizeOptionalBoolean(data.IsActual, `${root}.IsActual`),
      Series: normalizeString(data.Series, `${root}.Series`),
      Number: normalizeString(data.Number, `${root}.Number`),
      IssuedDate: normalizeOptionalString(data.IssuedDate, `${root}.IssuedDate`),
      IssuedBy: normalizeOptionalString(data.IssuedBy, `${root}.IssuedBy`),
      DepartmentCode: normalizeOptionalString(data.DepartmentCode, `${root}.DepartmentCode`),
    }), root);
  }
}
