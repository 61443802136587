/* eslint-disable class-methods-use-this, max-len */
import { injectable } from 'inversify';
import { LegalEntitiesInsuranceFundInfo } from '~/types/legal-entities/insuranceFundInfo';
import {
  ILegalEntitiesInsuranceFundInfoAdapterService
} from '~/services/adapters/legalEntities/legalEntitiesAdapterService.interfaces';
import { LegalEntitiesInsuranceFundInfoDTO } from '~/types/dto/legalEntities/insuranceFundInfoDTO';

@injectable()
export class LegalEntitiesInsuranceFundInfoAdapterService implements ILegalEntitiesInsuranceFundInfoAdapterService {
  getData(initData: LegalEntitiesInsuranceFundInfo[]): LegalEntitiesInsuranceFundInfoDTO[] {
    return (initData ?? []).map((legalEntity) => ({
      DepartmentCode: legalEntity.DepartmentCode ?? '',
      DepartmentName: legalEntity.DepartmentName ?? '',
      RegNumber: legalEntity.RegNumber,
      RegDate: legalEntity.RegDate ?? '',
    }));
  }
}
