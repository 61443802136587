import { injectable, inject } from 'inversify';
import { uniq } from 'lodash-es';
import { IIndividualBoundedPhonesRelationsService } from './individualBoundedPhonesRelationsService.interface';
import { RelationBoundedPhoneType } from '../types/relationBoundedPhoneType';
import { ReportBoundedPersonPhoneDTO } from '~/types/dto/common/boundedPersonPhoneDTO';
import { IIndividualBoundedPhonesNumberConfigServiceKey } from './individualBoundedPhonesNumberConfigService.interface';
import { IndividualBoundedPhonesNumberConfigService } from './individualBoundedPhonesNumberConfigService';

@injectable()
export class IndividualBoundedPhonesRelationsService implements IIndividualBoundedPhonesRelationsService {
  constructor(
    @inject(IIndividualBoundedPhonesNumberConfigServiceKey as symbol) private individualBoundedPhonesNumberConfigService: IndividualBoundedPhonesNumberConfigService,
  ) {}
  private getUniqRelations(boundedPhones: Array<ReportBoundedPersonPhoneDTO>): Array<string> {
    return uniq(boundedPhones.map((item) => item.OriginalRequestData));
  }
  
  getData(boundedPhones: Array<ReportBoundedPersonPhoneDTO>): Array<RelationBoundedPhoneType> {
    const originalRequestData = this.getUniqRelations(boundedPhones); 
    return originalRequestData.map((item) => ({
      related: this.individualBoundedPhonesNumberConfigService.getConfig(item),
      boundedPhones: boundedPhones.filter((boundedPhone) => boundedPhone.OriginalRequestData === item)
    }));
  }
}
