import { injectable } from 'inversify';
import { IPlaceholderDataService } from '~/services/placeholder/common/reportPlaceholderService.interfaces';
import { LegalEntitiesAverageHeadcount } from '~/types/legal-entities/averageHeadcount';

@injectable()
export class LegalEntitiesAverageHeadcountPlaceholderService implements IPlaceholderDataService<LegalEntitiesAverageHeadcount> {
  getData(): LegalEntitiesAverageHeadcount {
    return {
      Count: 0,
      DateStart: '',
      DateEnd: '',
      IsHistoric: false,
    };
  }
}
