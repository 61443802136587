import { injectable } from 'inversify';

import { IDataOptionsPropertiesLineChartService } from '~/modules/legalEntities/financialAnalytics/lineCharts/common/services/dataOptionsPropertiesLineChartService.interfaces';

import { LegalEntitiesReportFinancialIndicatorWithConclusionsDTO } from '~/types/dto/legalEntities/reportFinancialIndicatorWithConclusionsDTO';

@injectable()
export class DataOptionsPropertiesLineChartService implements IDataOptionsPropertiesLineChartService {
  getLabels(twoYearsAgo: number, lastYear: number, currentYear: number): Array<string> {
    return [`${twoYearsAgo}`, `${lastYear}`, `${currentYear}`];
  }

  getData(initData: LegalEntitiesReportFinancialIndicatorWithConclusionsDTO): Array<number> {
    const { TwoYearsAgo, LastYear, CurrentYear } = initData;

    return [TwoYearsAgo, LastYear, CurrentYear];
  }
}
