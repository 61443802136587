import { NodeUtils } from '~/utils/nodeUtils';

export type NodeMatcher = (element: Element) => boolean;
export function useNodesHiding(rootOrSelector: string | Element, matcher: NodeMatcher) {
  let observer: MutationObserver | undefined;

  function unwrapElement(element: Element): Element[] { 
    return [...element.children].flatMap(element => {
      const result = new Array<Element>();

      if (matcher(element)) result.push(element);

      result.push(...unwrapElement(element));

      return result;
    });
  }
    
  function getElements(root: Element) {
    return [...root.children].flatMap(element => unwrapElement(element));
  }
    
  function update(root: Element) {
    const elements = getElements(root);

    elements.forEach(element => {
      if (NodeUtils.isHTMLElement(element)) {
        console.warn('useNodesHiding: found element to hide', element);
        element.style.display = 'none';
      }
    });
  }    
    
  function getRoot(rootOrSelector: string | Element) {
    return typeof rootOrSelector === 'string' 
      ? document.querySelector(rootOrSelector) 
      : rootOrSelector;
  }
    
  function setupObserver(root: Element) {
    const observer = new MutationObserver(mutations => mutations.forEach(mutation => mutation.addedNodes.forEach(node => {
      if (NodeUtils.isHTMLElement(node))
        update(node);
    })));

    observer.observe(root, { childList: true, subtree: true });
        
    return observer;
  }
    
  onMounted(() => {
    const root = getRoot(rootOrSelector);
        
    if (root != undefined) {
      observer = setupObserver(root);
      update(root);
    }
  });

  onUnmounted(() => observer?.disconnect());
}
