import { InjectionKey } from 'vue';
import { LegalEntitiesCompanyStatedCapitalDTO } from '~/types/dto/legalEntities/companyStatedCapitalDTO';

export interface ILegalEntitiesAuthorizedCapitalLastIndex {
  getLastIndex(initData: Array<LegalEntitiesCompanyStatedCapitalDTO>): number;
}

export type ILegalEntitiesAuthorizedCapitalLastIndexService = ILegalEntitiesAuthorizedCapitalLastIndex;

export const ILegalEntitiesAuthorizedCapitalLastIndexServiceKey: InjectionKey<ILegalEntitiesAuthorizedCapitalLastIndexService> = Symbol('ILegalEntitiesAuthorizedCapitalLastIndexService');
