import { InjectionKey } from 'vue';
import { IConfigService } from '~/services/configService.interface';
import { ReportSocialNetworkDTO } from '~/types/dto/common/reportSocialNetworkDTO';
import { ReportSocialNetworkLifePositionDTO } from '~/types/dto/common/reportSocialNetworkLifePositionDTO';
import { ReportSocialNetworkAboutInfoDTO } from '~/types/dto/common/reportSocialNetworkAboutInfoDTO';
import { ReportSocialNetworkHighEducationDTO } from '~/types/dto/common/reportSocialNetworkHighEducationDTO';
import { ReportSocialNetworkSchoolDTO } from '~/types/dto/common/reportSocialNetworkSchoolDTO';
import { ReportSocialNetworkSecondaryEducationDTO } from '~/types/dto/common/reportSocialNetworkSecondaryEducationDTO';
import { ReportSocialNetworkJobDTO } from '~/types/dto/common/reportSocialNetworkJobDTO';

export type IReportSocialNetworkSchoolConfigService = IConfigService<ReportSocialNetworkSchoolDTO>
export type IReportSocialNetworkSecondaryEducationConfigService = IConfigService<ReportSocialNetworkSecondaryEducationDTO>
export type IReportSocialNetworkHighEducationConfigService = IConfigService<ReportSocialNetworkHighEducationDTO>
export type IReportSocialNetworkInterestsConfigService = IConfigService<ReportSocialNetworkAboutInfoDTO>
export type IReportSocialNetworkLifePositionConfigService = IConfigService<ReportSocialNetworkLifePositionDTO>
export type IReportSocialNetworkConfigService = IConfigService<ReportSocialNetworkDTO>
export type IReportSocialNetworkJobConfigService = IConfigService<ReportSocialNetworkJobDTO>
export type IReportSocialNetworkHistoryMaritalStatus = IConfigService<any>; // Нужен тип когда появится

export const IReportSocialNetworkSchoolConfigServiceKey: InjectionKey<IReportSocialNetworkSchoolConfigService> = Symbol('IReportSocialNetworkSchoolConfigService');
export const IReportSocialNetworkSecondaryEducationConfigServiceKey: InjectionKey<IReportSocialNetworkSecondaryEducationConfigService> = Symbol('IReportSocialNetworkSecondaryEducationConfigService');
export const IReportSocialNetworkHighEducationConfigServiceKey: InjectionKey<IReportSocialNetworkHighEducationConfigService> = Symbol('IReportSocialNetworkHighEducationConfigService');
export const IReportSocialNetworkInterestsConfigServiceKey: InjectionKey<IReportSocialNetworkInterestsConfigService> = Symbol('IReportSocialNetworkInterestsConfigService');
export const IReportSocialNetworkLifePositionConfigServiceKey: InjectionKey<IReportSocialNetworkLifePositionConfigService> = Symbol('IReportSocialNetworkLifePositionConfigService');
export const IReportSocialNetworkConfigServiceKey: InjectionKey<IReportSocialNetworkConfigService> = Symbol('IReportSocialNetworkConfigService');
export const IReportSocialNetworkJobConfigServiceKey: InjectionKey<IReportSocialNetworkJobConfigService> = Symbol('IReportSocialNetworkJobConfigService');
export const IReportSocialNetworkHistoryMaritalStatusKey: InjectionKey<IReportSocialNetworkHistoryMaritalStatus> = Symbol('IReportSocialNetworkHistoryMaritalStatus');