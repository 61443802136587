import { injectable } from 'inversify';

import { LegalEntitiesMarkers } from '~/types/legal-entities/markers';
import { IPlaceholderDataService } from '../common/reportPlaceholderService.interfaces';

@injectable()
export class LegalEntitiesMarkersPlaceholderDataService implements IPlaceholderDataService<LegalEntitiesMarkers> {
  getData(): LegalEntitiesMarkers {
    return {
      MarkersGood: [],
      MarkersWarning: [],
      MarkersBad: [],
      Rate: 0,
      RiskIndicator: 0,
    };
  }
}
