/* eslint-disable class-methods-use-this, max-len */

import { injectable } from 'inversify';
import { LegalEntitiesTotalScoreInfoDTO } from '~/types/dto/legalEntities/totalScoreDTO';
import { ILegalEntitiesTotalScoreAdapterService } from '~/services/adapters/legalEntities/legalEntitiesAdapterService.interfaces';
import { LegalEntitiesTotalScore } from '~/types/legal-entities/totalScore';

@injectable()
export class LegalEntitiesTotalScoreAdapterService implements ILegalEntitiesTotalScoreAdapterService {
  getData(initData: LegalEntitiesTotalScore): LegalEntitiesTotalScoreInfoDTO {
    return {
      TotalScore: initData.TotalScore ?? '',
      IsExists: initData.IsExists,
      TotalScoreConclusion: initData.TotalScoreConclusion ?? '',
      Year: initData.Year ?? 0,
    };
  }
}
