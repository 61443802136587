import { injectable } from 'inversify';
import { LegalEntitiesCompanyFounderShare } from '~/types/legal-entities/companyFounderShare';
import { IPlaceholderDataService } from '../common/reportPlaceholderService.interfaces';

@injectable()
export class LegalEntitiesCompanyFounderSharePlaceholderDataService implements IPlaceholderDataService<LegalEntitiesCompanyFounderShare> {
  getData(): LegalEntitiesCompanyFounderShare {
    return {
      Sum: 0,
      PercentagePlain: 0,
      PercentageNominator: 0,
      PercentageDenominator: 0
    };
  }
}
