import { injectable, inject } from 'inversify';
import { ReportPayment } from '~/types/common/reportPayment';
import { IPrimitiveTypesNormalizationServiceKey, IPrimitiveTypesNormalizationService } from './primitiveTypesNormalizationService.interfaces';
import { INormalizationService } from './reportNormalizationService.interfaces';

@injectable()
export class ReportPaymentNormalizationService implements INormalizationService<ReportPayment> {
  constructor(
    @inject(IPrimitiveTypesNormalizationServiceKey as symbol) private primitiveTypesNormalizationService: IPrimitiveTypesNormalizationService,
  ) {}

  normalize(data: any, root: string): ReportPayment | undefined {
    const {
      normalizeObject,
      normalizeString,
      normalizeNumber,
      normalizeOptionalString
    } = this.primitiveTypesNormalizationService;

    return normalizeObject(data, (data) => ({
      Type: normalizeString(data.Type, `${root}.Type`),
      Month: normalizeOptionalString(data.Month, `${root}.Month`),
      Amount: normalizeNumber(data.Amount, `${root}.Amount`)
    }), root);
  }
}
