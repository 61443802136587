import { injectable, inject } from 'inversify';

import { LegalEntitiesSubCompanyInfo } from '~/types/legal-entities/subCompanyInfo';
import { IPrimitiveTypesNormalizationServiceKey, IPrimitiveTypesNormalizationService } from '../common/primitiveTypesNormalizationService.interfaces';
import { INormalizationService } from '../common/reportNormalizationService.interfaces';

@injectable()
export class LegalEntitiesSubCompanyInfoNormalizationService implements INormalizationService<LegalEntitiesSubCompanyInfo> {
  constructor(
    @inject(IPrimitiveTypesNormalizationServiceKey as symbol) private primitiveTypesNormalizationService: IPrimitiveTypesNormalizationService,
  ) {}

  normalize(data: any, root: string): LegalEntitiesSubCompanyInfo | undefined {
    const {
      normalizeString,
      normalizeOptionalString,
      normalizeOptionalInteger,
      normalizeObject
    } = this.primitiveTypesNormalizationService;

    return normalizeObject(data, (data) => ({
      CompanyName: normalizeString(data.CompanyName, `${root}.CompanyName`),
      CompanyAddress: normalizeString(data.CompanyAddress, `${root}.CompanyAddress`),
      PercentCapital: normalizeOptionalInteger(data.PercentCapital, `${root}.PercentCapital`),
      Source: normalizeString(data.Source, `${root}.Source`),
      FirstDate: normalizeOptionalString(data.FirstDate, `${root}.FirstDate`),
    }), root);
  }
}
