import { injectable, inject } from 'inversify';
import { LegalEntitiesCompanyEmployeeInfo } from '~/types/legal-entities/companyEmployeeInfo';
import { LegalEntitiesReportSocialNetworkId } from '~/types/legal-entities/reportSocialNetworkId';
import { IPrimitiveTypesNormalizationServiceKey, IPrimitiveTypesNormalizationService } from '../common/primitiveTypesNormalizationService.interfaces';
import { INormalizationService } from '../common/reportNormalizationService.interfaces';
import { ILegalEntitiesReportSocialNetworkIdNormalizationServiceKey } from './legalEntitiesNormalizationService.interfaces';

@injectable()
export class LegalEntitiesCompanyEmployeeInfoNormalizationService implements INormalizationService<LegalEntitiesCompanyEmployeeInfo> {
  constructor(
    @inject(ILegalEntitiesReportSocialNetworkIdNormalizationServiceKey as symbol) private reportSocialNetworkIdNormalizationService: INormalizationService<LegalEntitiesReportSocialNetworkId>,

    @inject(IPrimitiveTypesNormalizationServiceKey as symbol) private primitiveTypesNormalizationService: IPrimitiveTypesNormalizationService,
  ) {}

  normalize(data: any, root: string): LegalEntitiesCompanyEmployeeInfo | undefined {
    const {
      normalizeString,
      normalizeOptionalString,
      normalizeArray,
      normalizeObject
    } = this.primitiveTypesNormalizationService;

    return normalizeObject(data, (data) => ({
      FullName: normalizeString(data.FullName, `${root}.FullName`),
      BirthDate: normalizeOptionalString(data.BirthDate, `${root}.BirthDate`),
      Snils: normalizeOptionalString(data.Snils, `${root}.Snils`),
      Inn: normalizeOptionalString(data.Inn, `${root}.Inn`),
      IdentityDocument: normalizeOptionalString(data.IdentityDocument, `${root}.IdentityDocument`),
      PhoneNumber: normalizeOptionalString(data.PhoneNumber, `${root}.PhoneNumber`),
      SocialNetworks: normalizeArray(data.SocialNetworks, (item, field) => this.reportSocialNetworkIdNormalizationService.normalize(item, field), `${root}.SocialNetworks`),
      Email: normalizeOptionalString(data.Email, `${root}.Email`),
      Address: normalizeOptionalString(data.Address, `${root}.Address`),
      CarNumber: normalizeOptionalString(data.CarNumber, `${root}.CarNumber`),
    }), root);
  }
}
