import { injectable } from 'inversify';
import { IReportJobActivityConfigService } from '~/modules/reportsCommon/reportJobs/services/reportJobConfigService.interfaces';
import { ReportJobDTO } from '~/types/dto/common/reportJobDTO';
import { Config } from '~/types/field-list/config';
import { StringUtils } from '~/utils/stringUtils';
import { DateUtils } from '~/utils/date';

@injectable()
export class ReportJobActivityConfigService implements IReportJobActivityConfigService {
  getConfig(item: ReportJobDTO): Config {
    return {
      fieldsWidth: '100%',
      fieldsDirection: 'divider-row',
      fieldsValueWidth: '350px',
      isBeginningDivider: true,
      fields: [
        {
          key: 'CompanyInn',
          label: 'ИНН',
          value: item.CompanyInn 
        },
        {
          key: 'DepartmentName',
          label: 'Подразделение',
          value: item.DepartmentName 
        },
        {
          key: 'Position',
          label: 'Должность',
          value: StringUtils.CapitalizeString(item.Position) 
        },
        {
          key: 'StartDate',
          label: 'Дата приема',
          value: DateUtils.formatDate(item.StartDate) 
        },
        {
          key: 'EndDate',
          label: 'Дата увольнения',
          value: DateUtils.formatDate(item.EndDate) 
        },
        {
          key: 'ArticleDissmissal',
          label: 'Причина увольнения',
          value: item.ArticleDissmissal 
        },
        {
          key: 'CompanyAddress',
          label: 'Адрес',
          value: item.CompanyAddress 
        },
      ].filter((item) => item.value)
    };
  }
}
