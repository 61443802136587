import { injectable, inject } from 'inversify';

import { ReportIndicatorsOfCashFlow } from '~/types/blobs/report-indicators-of-cash-flow';
import { LegalEntitiesReportFinancialIndicatorWithDynamic } from '~/types/legal-entities/reportFinancialIndicatorWithDynamic';
import { ILegalEntitiesReportFinancialIndicatorWithDynamicPlaceholderDataServiceKey } from '../legalEntities/legalEntitiesPlaceholderDataService.interfaces';
import { IPlaceholderDataService } from './reportPlaceholderService.interfaces';

@injectable()
export class ReportIndicatorsOfCashFlowPlaceholderService implements IPlaceholderDataService<ReportIndicatorsOfCashFlow> {
  constructor(
    @inject(ILegalEntitiesReportFinancialIndicatorWithDynamicPlaceholderDataServiceKey as symbol) private reportFinancialIndicatorWithDynamicPlaceholderDataService: IPlaceholderDataService<LegalEntitiesReportFinancialIndicatorWithDynamic>,
  ) {}

  getData(): ReportIndicatorsOfCashFlow {
    return {
      OperatingCashFlow: this.reportFinancialIndicatorWithDynamicPlaceholderDataService.getData(),
      CashFlowAvailableForDebtService: this.reportFinancialIndicatorWithDynamicPlaceholderDataService.getData(),
      InvestmentCashFlow: this.reportFinancialIndicatorWithDynamicPlaceholderDataService.getData(),
      FinancialCashFlow: this.reportFinancialIndicatorWithDynamicPlaceholderDataService.getData(),
      DebtServiceCoverageRatio: this.reportFinancialIndicatorWithDynamicPlaceholderDataService.getData(),
    };
  }
}
