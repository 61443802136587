import { inject, injectable } from 'inversify';
import {
  IPrimitiveTypesNormalizationService,
  IPrimitiveTypesNormalizationServiceKey
} from './primitiveTypesNormalizationService.interfaces';
import { INormalizationService } from './reportNormalizationService.interfaces';
import { ReportConnectionRelatedRelation } from '~/types/common/reportConnectionRelatedRelation';

@injectable()
export class ReportConnectionRelatedRelationNormalizationService implements INormalizationService<ReportConnectionRelatedRelation> {
  constructor(
    @inject(IPrimitiveTypesNormalizationServiceKey as symbol) private primitiveTypesNormalizationService: IPrimitiveTypesNormalizationService,
  ) {}

  normalize(data: any, root: string): ReportConnectionRelatedRelation | undefined {
    const {
      normalizeOptionalString,
      normalizeNumber,
      normalizeObject,
      normalizeOptionalInteger,
      normalizeOptionalBoolean,
    } = this.primitiveTypesNormalizationService;

    return normalizeObject(data, (data) => ({
      DisplayName: normalizeOptionalString(data.DisplayName, `${root}.DisplayName`),
      DisplayType: normalizeOptionalString(data.DisplayType, `${root}.DisplayType`),
      Type: normalizeNumber(data.Type, `${root}.Type`),
      Depth: normalizeOptionalInteger(data.Depth, `${root}.Depth`),
      Comparison: normalizeOptionalInteger(data.Comparison, `${root}.Comparison`),
      HasMlData: normalizeOptionalBoolean(data.HasMlData, `${root}.HasMlData`)
    }), root);
  }
}
