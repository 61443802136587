/* eslint-disable class-methods-use-this, max-len */

import { injectable } from 'inversify';
import { StringUtils } from '~/utils/stringUtils';
import { LegalEntitiesTaxMode } from '~/types/legal-entities/taxMode';
import { LegalEntitiesTaxModeDTO } from '~/types/dto/legalEntities/taxMode';
import {
  ILegalEntitiesTaxModeAdapterService
} from '~/services/adapters/legalEntities/legalEntitiesAdapterService.interfaces';

@injectable()
export class LegalEntitiesTaxModeAdapterService implements ILegalEntitiesTaxModeAdapterService {
  getData(initData: LegalEntitiesTaxMode): LegalEntitiesTaxModeDTO {
    return {
      Name: StringUtils.quoteReplacement(initData.Name ?? ''),
    };
  }
}
