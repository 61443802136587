import { injectable } from 'inversify';
import { ReportIndividualEntrepreneur } from '~/types/common/reportIndividualEntrepreneur';
import { IPlaceholderDataService } from './reportPlaceholderService.interfaces';

@injectable()
export class ReportIndividualEntrepreneurPlaceholderService implements IPlaceholderDataService<ReportIndividualEntrepreneur> {
  getData(): ReportIndividualEntrepreneur {
    return {
      Ogrn: '',
      PrincipalActivity: '',
      Status: '',
      RegistrationDate: '',
      LiquidationDate: '',
      Name: '',
      Inn: '',
    };
  }
}
