import { injectable } from 'inversify';

import { LegalEntitiesCompanyName } from '~/types/legal-entities/companyName';
import { IPlaceholderDataService } from '../common/reportPlaceholderService.interfaces';

@injectable()
export class LegalEntitiesCompanyNamePlaceholderDataService implements IPlaceholderDataService<LegalEntitiesCompanyName> {
  getData(): LegalEntitiesCompanyName {
    return {
      Name: '',
      From: '',
    };
  }
}
