import { injectable, inject } from 'inversify';
import { IPlaceholderDataService } from '~/services/placeholder/common/reportPlaceholderService.interfaces';
import { ILegalEntitiesReportFinancialIndicatorWithConclusionsPlaceholderDataServiceKey } from '~/services/placeholder/legalEntities/legalEntitiesPlaceholderDataService.interfaces';
import { LegalEntitiesReportFinancialIndicatorWithConclusions } from '~/types/legal-entities/reportFinancialIndicatorWithConclusions';
import { LegalEntitiesReportFinancialIndicatorWithDynamic } from '~/types/legal-entities/reportFinancialIndicatorWithDynamic';
import { IPrimitiveTypesNormalizationServiceKey, IPrimitiveTypesNormalizationService } from '../common/primitiveTypesNormalizationService.interfaces';
import { INormalizationService } from '../common/reportNormalizationService.interfaces';
import { ILegalEntitiesReportFinancialIndicatorWithConclusionsNormalizationServiceKey } from './legalEntitiesNormalizationService.interfaces';

@injectable()
export class LegalEntitiesReportFinancialIndicatorWithDynamicNormalizationService implements INormalizationService<LegalEntitiesReportFinancialIndicatorWithDynamic> {
  constructor(
    @inject(ILegalEntitiesReportFinancialIndicatorWithConclusionsNormalizationServiceKey as symbol) private reportFinancialIndicatorWithConclusionsNormalizationService: INormalizationService<LegalEntitiesReportFinancialIndicatorWithConclusions>,
    @inject(ILegalEntitiesReportFinancialIndicatorWithConclusionsPlaceholderDataServiceKey as symbol) private reportFinancialIndicatorWithConclusionsPlaceholderDataService: IPlaceholderDataService<LegalEntitiesReportFinancialIndicatorWithConclusions>,

    @inject(IPrimitiveTypesNormalizationServiceKey as symbol) private primitiveTypesNormalizationService: IPrimitiveTypesNormalizationService,
  ) {}

  normalize(data: any, root: string): LegalEntitiesReportFinancialIndicatorWithDynamic | undefined {
    const { normalizeOptionalInteger, normalizeObject } = this.primitiveTypesNormalizationService;
    const extendsData = this.reportFinancialIndicatorWithConclusionsNormalizationService.normalize(data, root) ?? this.reportFinancialIndicatorWithConclusionsPlaceholderDataService.getData();

    return normalizeObject(data, (data) => ({
      ...extendsData,
      TwoYearsAgoToLastYear: normalizeOptionalInteger(data.TwoYearsAgoToLastYear, `${root}.TwoYearsAgoToLastYear`),
      LastYearToCurrentYear: normalizeOptionalInteger(data.LastYearToCurrentYear, `${root}.LastYearToCurrentYear`),
    }), root);
  }
}
