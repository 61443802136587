import { injectable } from 'inversify';
import { Config } from '~/types/field-list/config';
import { ReportSocialNetworkSecondaryEducationDTO } from '~/types/dto/common/reportSocialNetworkSecondaryEducationDTO';
import { IReportSocialNetworkSecondaryEducationConfigService } from './reportSocialNetworkConfigService.interfaces';
import { FIELD_LIST_SOCIAL_EDUCATION_OPTIONS } from '../../configuration/constants';

@injectable()
export class ReportSocialNetworkSecondaryEducationConfigService implements IReportSocialNetworkSecondaryEducationConfigService {
  getConfig(educationItem: ReportSocialNetworkSecondaryEducationDTO): Config {
    return {
      ...FIELD_LIST_SOCIAL_EDUCATION_OPTIONS,
      fields: [
        {
          key: 'EducationView',
          label: 'Вид образования',
          value: 'Среднее',
        },
        {
          key: 'SpecialtyName',
          label: 'Специальность',
          value: educationItem.SpecialtyName,
        },
        {
          key: 'StartDate',
          label: 'Год начала обучения',
          value: educationItem.StartDate,
        },
        {
          key: 'DiplomaDate',
          label: 'Дата выдачи документа',
          value: educationItem.DiplomaDate,
        },
      ].filter(item => item.value)
    };
  }
}
