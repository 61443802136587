import { injectable, inject } from 'inversify';
import { IndividualDataBlobData } from '~/types/blobs/individual-data-blob-data';
import { ReportSocialNetworkTrack } from '~/types/common/reportSocialNetworkTrack';
import { IndividualCommonInfo } from '~/types/individual/commonInfo';
import { IndividualCommonNegativeInfo } from '~/types/individual/commonNegativeInfo';
import { IndividualMarkers } from '~/types/individual/markers';
import {
  IPlaceholderDataService,
  IReportConnectionRelatedPlaceholderDataServiceKey,
  IReportSocialNetworkTrackPlaceholderServiceKey,
  IReportVettingInfoPlaceholderServiceKey
} from '../common/reportPlaceholderService.interfaces';
import { IIndividualCommonInfoPlaceholderServiceKey, IIndividualCommonNegativeInfoPlaceholderServiceKey, IIndividualMarkersPlaceholderServiceKey } from './individualPlaceholderDataService.interfaces';
import { ReportVettingInfo } from '~/types/common/vettingInfo';
import { ReportConnectionRelated } from '~/types/common/reportConnectionRelated';

@injectable()
export class IndividualDataBlobDataPlaceholderService implements IPlaceholderDataService<IndividualDataBlobData> {
  constructor(
    @inject(IIndividualCommonInfoPlaceholderServiceKey as symbol) private commonInfoPlaceholderService: IPlaceholderDataService<IndividualCommonInfo>,
    @inject(IReportSocialNetworkTrackPlaceholderServiceKey as symbol) private reportSocialNetworkTrackPlaceholderService: IPlaceholderDataService<ReportSocialNetworkTrack>,
    @inject(IIndividualCommonNegativeInfoPlaceholderServiceKey as symbol) private commonNegativeInfoPlaceholderService: IPlaceholderDataService<IndividualCommonNegativeInfo>,
    @inject(IIndividualMarkersPlaceholderServiceKey as symbol) private markersPlaceholderServiceKey: IPlaceholderDataService<IndividualMarkers>,
    @inject(IReportVettingInfoPlaceholderServiceKey as symbol) private vettingInfoPlaceholderService: IPlaceholderDataService<ReportVettingInfo>,
    @inject(IReportConnectionRelatedPlaceholderDataServiceKey as symbol) private reportConnectionRelatedPlaceholderDataService: IPlaceholderDataService<ReportConnectionRelated>,
  ) {}

  getData(): IndividualDataBlobData {
    return {
      Common: this.commonInfoPlaceholderService.getData(),
      PersonGroups: [],
      Inns: [],
      Snilses: [],
      UsersLists: [],
      Passports: [],
      MilitaryRecords: [],
      Phones: [],
      BoundedPersonPhones: [],
      Skypes: [],
      Emails: [],
      Sites: [],
      SocialNetworks: [],
      Addresses: [],
      Realties: [],
      DriverLicenses: [],
      Vehicles: [],
      VehicleParkings: [],
      Educations: [],
      Jobs: [],
      JobRecommendations: [],
      BusinessTrips: [],
      Holidays: [],
      LegalEntitiesParticipation: [],
      LegalEntitiesParticipationRestrictions: [],
      IndividualEntrepreneurs: [],
      SocialNetworkTrack: this.reportSocialNetworkTrackPlaceholderService.getData(),
      CommonNegativeInfo: this.commonNegativeInfoPlaceholderService.getData(),
      Bankruptcies: [],
      StagesBankruptcy: [],
      MessagesAboutBankruptcy: [],
      CriminalPenalties: [],
      FinancialScoring: [],
      TrustScoring: [],
      EnforcementProceedings: [],
      Fines: [],
      WantedMvd: [],
      WantedFsin: [],
      WantedFssp: [],
      FiosChanges: [],
      PossibleFios: [],
      PossibleBirthdays: [],
      IndividualTypes: [],
      TerroristsAndExtremistsList: [],
      TaxDebtsAccruals: [],
      FineAccruals: [],
      StateDutyAccruals: [],
      LegalDebtAccruals: [],
      ServiceBillAccruals: [],
      PossiblePasswords: [],
      Wanted: [],
      Avias: [],
      CovidMovements: [],
      TaxiMovements: [],
      BusMovements: [],
      FamilyList: [],
      MaritalStatusHistory: [],
      RelationsWithCounterparties: this.reportConnectionRelatedPlaceholderDataService.getData(),
      RelationsWithCompetitors: this.reportConnectionRelatedPlaceholderDataService.getData(),
      RelationsWithBlackListed: this.reportConnectionRelatedPlaceholderDataService.getData(),
      RelationsWithEmployees: this.reportConnectionRelatedPlaceholderDataService.getData(),
      FoundRelations: this.reportConnectionRelatedPlaceholderDataService.getData(),
      Markers: this.markersPlaceholderServiceKey.getData(),
      Files: [],
      Photos: [],
      MessageBoards: [],
      VettingInfo: this.vettingInfoPlaceholderService.getData()
    };
  }
}
