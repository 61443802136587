import { injectable, inject } from 'inversify';
import { get } from 'lodash-es';
import { ILegalEntitiesCashFlowPerformanceTableConfigService } from '~/modules/legalEntities/financialAnalytics/legalEntitiesCashFlowPerformance/services/legalEntitiesCashFlowPerformanceTableConfigService.interface';
import { LegalEntitiesReportFinancialIndicatorBaseDTO } from '~/types/dto/legalEntities/reportFinancialIndicatorBaseDTO';
import { TableHeader } from '~/modules/table/types/tableHeader';
import { LegalEntitiesReportIndicatorsOfCashFlowDTO } from '~/types/dto/legalEntities/reportIndicatorsOfCashFlowDTO';
import { TableItem } from '~/modules/table/types/tableItem';
import {
  ICashFlowAvailableForDebtServiceTableItemConfig,
  ICashFlowAvailableForDebtServiceTableItemConfigKey,
  IDebtServiceCoverageRatioTableItemConfig,
  IDebtServiceCoverageRatioTableItemConfigKey,
  IFinancialCashFlowTableItemConfig,
  IFinancialCashFlowTableItemConfigKey,
  IInvestmentCashFlowTableItemConfig,
  IInvestmentCashFlowTableItemConfigKey,
  IOperatingCashFlowTableItemConfig,
  IOperatingCashFlowTableItemConfigKey
} from '~/modules/legalEntities/financialAnalytics/legalEntitiesCashFlowPerformance/services/legalEntitiesCashFlowPerformanceTableItemConfig.interfaces';

@injectable()
export class LegalEntitiesCashFlowPerformanceTableConfigService implements ILegalEntitiesCashFlowPerformanceTableConfigService {
  constructor(
    @inject(IOperatingCashFlowTableItemConfigKey as symbol) private operatingCashFlowTableItemConfig: IOperatingCashFlowTableItemConfig,
    @inject(ICashFlowAvailableForDebtServiceTableItemConfigKey as symbol) private cashFlowAvailableForDebtServiceTableItemConfig: ICashFlowAvailableForDebtServiceTableItemConfig,
    @inject(IInvestmentCashFlowTableItemConfigKey as symbol) private investmentCashFlowTableItemConfig: IInvestmentCashFlowTableItemConfig,
    @inject(IFinancialCashFlowTableItemConfigKey as symbol) private financialCashFlowTableItemConfig: IFinancialCashFlowTableItemConfig,
    @inject(IDebtServiceCoverageRatioTableItemConfigKey as symbol) private debtServiceCoverageRatioTableItemConfig: IDebtServiceCoverageRatioTableItemConfig
  ) {
  }
  getHeaders(initData: Array<LegalEntitiesReportFinancialIndicatorBaseDTO>): Array<TableHeader> {
    return [
      {
        label: 'Показатель',
        key: 'indicator',
        width: '356px',
      },
      {
        label: get(initData, '0.TwoYearsAgo', ''),
        key: 'TwoYearsAgo',
        width: '106px',
      },
      {
        label: get(initData, '0.LastYear', ''),
        key: 'LastYear',
        width: '156px',
      },
      {
        label: `${get(initData, '0.LastYear', '')}/${get(initData, '0.TwoYearsAgo', '')}`,
        key: 'TwoYearsAgoToLastYear',
        width: '144px',
      },
      {
        label: get(initData, '0.CurrentYear', ''),
        key: 'CurrentYear',
        width: '106px',
      },
      {
        label: `${get(initData, '0.CurrentYear', '')}/${get(initData, '0.LastYear', '')}`,
        key: 'LastYearToCurrentYear',
        width: '88px',
      },
    ];
  }

  getItems(initData: Array<LegalEntitiesReportIndicatorsOfCashFlowDTO>): Array<TableItem> {
    return initData.map((tableItem) => [
      this.operatingCashFlowTableItemConfig.getItem(tableItem.OperatingCashFlow),
      this.cashFlowAvailableForDebtServiceTableItemConfig.getItem(tableItem.CashFlowAvailableForDebtService),
      this.investmentCashFlowTableItemConfig.getItem(tableItem.InvestmentCashFlow),
      this.financialCashFlowTableItemConfig.getItem(tableItem.FinancialCashFlow),
      this.debtServiceCoverageRatioTableItemConfig.getItem(tableItem.DebtServiceCoverageRatio)
    ]);
  }
}
