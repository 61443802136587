import { injectable } from 'inversify';
import { orderBy } from 'lodash-es';
import { IReportMovementTaxiAdapterService } from '~/services/adapters/common/reportAdapterService.interfeces';
import { ReportMovementTaxi } from '~/types/common/reportMovementTaxi';
import { ReportMovementTaxiDTO } from '~/types/dto/common/reportMovementTaxiDTO';
import { StringUtils } from '~/utils/stringUtils';

@injectable()
export class ReportMovementTaxiAdapterService implements IReportMovementTaxiAdapterService {
  getData(initData: ReportMovementTaxi[]): ReportMovementTaxiDTO[] {
    return orderBy(initData ?? [], ['MovementDate'], ['desc']).map((item) => ({
      MovementFrom: StringUtils.quoteReplacement(item.MovementFrom ?? ''),
      MovementTo: StringUtils.quoteReplacement(item.MovementTo ?? ''),
      MovementDate: item.MovementDate ?? ''
    }));
  }
}
