import { IContainerBinder } from '~/configuration/inversify/containerAccessor.interface';
import { ReportParkedBoundedPersonPhonesMergeService } from '../../common/services/reportParkedBoundedPersonPhonesMergeService';
import { IReportParkedBoundedPersonPhonesMergeServiceKey } from '../../common/services/reportParkedBoundedPersonPhonesMergeService.interfaces';
import { ReportParkedBoundedPersonPhonesConfigService } from '../../common/services/reportParkedBoundedPersonPhonesConfigService';
import { IReportParkedBoundedPersonPhonesConfigServiceKey } from '../../common/services/reportParkedBoundedPersonPhonesConfigService.interface';
import { IReportParkedBoundedPersonPhonesServiceKey } from '../../common/services/reportParkedBoundedPersonPhonesService.interface';
import { ReportParkedBoundedPersonPhonesService } from '../../common/services/reportParkedBoundedPersonPhonesService';

export function bindReportParkedPhoneNumbersService(bind: IContainerBinder) {
  bind(IReportParkedBoundedPersonPhonesMergeServiceKey).to(ReportParkedBoundedPersonPhonesMergeService);
  bind(IReportParkedBoundedPersonPhonesConfigServiceKey).to(ReportParkedBoundedPersonPhonesConfigService);
  bind(IReportParkedBoundedPersonPhonesServiceKey).to(ReportParkedBoundedPersonPhonesService);
}
