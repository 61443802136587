import { injectable, inject } from 'inversify';
import { LegalEntitiesRelatedEgrul } from '~/types/legal-entities/relatedEgrul';
import { IPrimitiveTypesNormalizationServiceKey, IPrimitiveTypesNormalizationService } from '../common/primitiveTypesNormalizationService.interfaces';
import { INormalizationService } from '../common/reportNormalizationService.interfaces';

@injectable()
export class LegalEntitiesRelatedEgrulNormalizationService implements INormalizationService<LegalEntitiesRelatedEgrul> {
  constructor(
    @inject(IPrimitiveTypesNormalizationServiceKey as symbol) private primitiveTypesNormalizationService: IPrimitiveTypesNormalizationService,
  ) {}

  normalize(data: any, root: string): LegalEntitiesRelatedEgrul | undefined {
    const { normalizeString, normalizeOptionalString, normalizeObject } = this.primitiveTypesNormalizationService;

    return normalizeObject(data, (data) => ({
      RelationType: normalizeString(data.RelationType, `${root}.RelationType`),
      RelationObject: normalizeString(data.RelationObject, `${root}.RelationObject`),
      ObjectName: normalizeOptionalString(data.ObjectName, `${root}.ObjectName`),
      ObjectInn: normalizeOptionalString(data.ObjectInn, `${root}.ObjectInn`),
      ObjectOgrn: normalizeString(data.ObjectOgrn, `${root}.ObjectOgrn`),
      ObjectStatus: normalizeString(data.ObjectStatus, `${root}.ObjectStatus`),
      ObjectRegistrationDate: normalizeOptionalString(data.ObjectRegistrationDate, `${root}.ObjectRegistrationDate`),
      ObjectLiquidationDate: normalizeOptionalString(data.ObjectLiquidationDate, `${root}.ObjectLiquidationDate`),
      ObjectLegalAddress: normalizeOptionalString(data.ObjectLegalAddress, `${root}.ObjectLegalAddress`),
      ObjectMainActivity: normalizeOptionalString(data.ObjectMainActivity, `${root}.ObjectMainActivity`),
    }), root);
  }
}
