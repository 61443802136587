/* eslint-disable class-methods-use-this, max-len */

import { injectable } from 'inversify';
import { LegalEntitiesAverageHeadcountDTO } from '~/types/dto/legalEntities/averageHeadcount';
import {
  ILegalEntitiesAverageHeadcountAdapterService
} from '~/services/adapters/legalEntities/legalEntitiesAdapterService.interfaces';
import { LegalEntitiesAverageHeadcount } from '~/types/legal-entities/averageHeadcount';

@injectable()
export class LegalEntitiesAverageHeadcountAdapterService implements ILegalEntitiesAverageHeadcountAdapterService {
  getData(initData: LegalEntitiesAverageHeadcount[]): LegalEntitiesAverageHeadcountDTO[] {
    return (initData ?? []).map((averageHeadcount) => ({
      Count: averageHeadcount.Count,
      DateStart: averageHeadcount.DateStart,
      DateEnd: averageHeadcount.DateEnd ?? '',
      IsHistoric: averageHeadcount.IsHistoric ?? false
    }));
  }
}
