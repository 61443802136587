import NodesPagingPage from '~/models/nodes/paging/nodesPagingPage';
import NodesPagingRow from '~/models/nodes/paging/nodesPagingRow';
import { NodeRowSnapshot } from '~/models/nodes/snapshots/nodeRowSnapshot';
import NodesPagingCell from '../paging/nodesPagingCell';

export class NodeLayoutSnapshot {
  height = 0
  rowsHeight = 0
  rowsWrapperHeight = 0
  
  constructor(
    private root: HTMLElement | undefined, 
    public rowSnapshots: NodeRowSnapshot[]
  ) {
    this.update();
  }
  
  public update() {
    let height = 0;
    let rowsHeight = 0;
    let rowsWrapperHeight = 0;
    
    if (this.root != undefined) {
      const rect = this.root.getBoundingClientRect();
      height = rect.height;

      const rows = this.rowSnapshots.map(rowSnapshot => new NodesPagingRow(rowSnapshot.snapshots.map(x => new NodesPagingCell(x, undefined))));
      const page = new NodesPagingPage({ height: 0 });

      for (const row of rows) 
        page.addRow(row);

      rowsHeight = page.height;
      rowsWrapperHeight = height - rowsHeight;
    }

    this.height = height;
    this.rowsHeight = rowsHeight;
    this.rowsWrapperHeight = rowsWrapperHeight;
  }
}
