import { injectable } from 'inversify';
import {
  ILegalEntitiesFinancialCommonAdapterService
} from '~/services/adapters/legalEntities/legalEntitiesAdapterService.interfaces';
import { LegalEntitiesFinancialCommon } from '~/types/legal-entities/financialCommon';
import { LegalEntitiesFinancialCommonDTO } from '~/types/dto/legalEntities/financialCommonDTO';

@injectable()
export class LegalEntitiesFinancialCommonAdapterService implements ILegalEntitiesFinancialCommonAdapterService {
  getData(initData: LegalEntitiesFinancialCommon[]): LegalEntitiesFinancialCommonDTO[] {
    return (initData ?? []).map((item) => ({
      Year: item.Year,
      SectorName: item.SectorName,
      ValuePrevious: item.ValuePrevious,
      ValueCurrent: item.ValueCurrent,
      Dynamics: item.Dynamics
    }));
  }
}
