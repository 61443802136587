import { IContainerBinder } from '~/configuration/inversify/containerAccessor.interface';
import {
  ILegalEntitiesCompanyTaxRegimesMergeServiceKey
} from '~/modules/legalEntities/changeHistory/legalEntitiesCompanyTaxRegimes/services/legalEntitiesTaxRegimesMergeService.interface';
import {
  LegalEntitiesCompanyTaxRegimesMergeService
} from '~/modules/legalEntities/changeHistory/legalEntitiesCompanyTaxRegimes/services/legalEntitiesTaxRegimesMergeService';
import {
  ILegalEntitiesCompanyTaxRegimesTableConfigServiceKey
} from '~/modules/legalEntities/changeHistory/legalEntitiesCompanyTaxRegimes/services/legalEntitiesTaxRegimesTableConfigService.interface';
import {
  LegalEntitiesCompanyTaxRegimesTableConfigService
} from '~/modules/legalEntities/changeHistory/legalEntitiesCompanyTaxRegimes/services/legalEntitiesTaxRegimesTableConfigService';

export function bindLegalEntitiesTaxRegimesServices(bind: IContainerBinder) {
  bind(ILegalEntitiesCompanyTaxRegimesTableConfigServiceKey).to(LegalEntitiesCompanyTaxRegimesTableConfigService);
  bind(ILegalEntitiesCompanyTaxRegimesMergeServiceKey).to(LegalEntitiesCompanyTaxRegimesMergeService);
}
