import { injectable } from 'inversify';
import { IReportMainEntityFileAdapterService } from '~/services/adapters/common/reportAdapterService.interfeces';
import { ReportMainEntityFile } from '~/types/common/reportMainEntityFile';
import { ReportMainEntityFileDTO } from '~/types/dto/common/reportMainEntityFileDTO';

@injectable()
export class ReportMainEntityFileAdapterService implements IReportMainEntityFileAdapterService {
  getData(initData: ReportMainEntityFile): ReportMainEntityFileDTO {
    return {
      FileData: initData.FileData ?? '',
      DataLength: initData.DataLength,
      FileHash: initData.FileHash ?? '',
      FileUrl: initData.FileUrl ?? '',
      Filename: initData.Filename ?? '',
      FilenameFull: initData.FilenameFull ?? '',
      Extension: initData.Extension,
      Comment: initData.Comment ?? '',
      PostDate: initData.PostDate ?? '',
      IsExists: initData.IsExists
    };
  }
}
