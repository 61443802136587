import { injectable } from 'inversify';
import { LegalEntitiesCompanyPhone } from '~/types/legal-entities/companyPhone';
import { IPlaceholderDataService } from '../common/reportPlaceholderService.interfaces';

@injectable()
export class LegalEntitiesCompanyPhonePlaceholderDataService implements IPlaceholderDataService<LegalEntitiesCompanyPhone> {
  getData(): LegalEntitiesCompanyPhone {
    return {
      Phone: '',
      Name: '',
      Hashtags: [],
      CompaniesWithThisPhone: 0,
    };
  }
}
