import { injectable } from 'inversify';
import { ReportSocialNetworkJobDTO } from '~/types/dto/common/reportSocialNetworkJobDTO';
import { Config } from '~/types/field-list/config';
import { FIELD_LIST_SOCIAL_OPTIONS } from '../../configuration/constants';
import { IReportSocialNetworkJobConfigService } from './reportSocialNetworkConfigService.interfaces';

@injectable()
export class ReportSocialNetworkJobConfigService implements IReportSocialNetworkJobConfigService {
  getConfig(jobItem: ReportSocialNetworkJobDTO): Config {
    return {
      ...FIELD_LIST_SOCIAL_OPTIONS,
      fields: [
        {
          key: 'CompanyName',
          label: 'Место работы',
          value: jobItem.CompanyName,
        },
        {
          key: 'Position',
          label: 'Должность',
          value: jobItem.Position,
        },
        {
          key: 'City',
          label: 'Город',
          value: jobItem.City,
        },
        {
          key: 'StartDate',
          label: 'Год начала работы',
          value: jobItem.StartDate,
        },
        {
          key: 'StartDate',
          label: 'Год окончания работы',
          value: jobItem.EndDate,
        },
      ].filter(item => item.value)
    };
  }
}
