import { IContainerBinder } from '~/configuration/inversify/containerAccessor.interface';
import { LegalEntitiesPhoneNumberSiftingService } from '~/modules/legalEntities/commonInfo/legalEntitiesPhoneNumbers/services/legalEntitiesPhoneNumberSiftingService';
import { ILegalEntitiesPhoneNumberSiftingServiceKey } from '~/modules/legalEntities/commonInfo/legalEntitiesPhoneNumbers/services/legalEntitiesPhoneNumberSiftingService.interfaces';
import { ILegalEntitiesPhonesMergeServiceKey } from '~/modules/legalEntities/commonInfo/legalEntitiesPhoneNumbers/services/legalEntitiesPhonesMergeService.interfaces';
import { LegalEntitiesPhonesMergeService } from '~/modules/legalEntities/commonInfo/legalEntitiesPhoneNumbers/services/legalEntitiesPhonesMergeService';

export function bindLegalEntitiesCompanyPhoneService(bind: IContainerBinder) {
  bind(ILegalEntitiesPhoneNumberSiftingServiceKey).to(LegalEntitiesPhoneNumberSiftingService);
  bind(ILegalEntitiesPhonesMergeServiceKey).to(LegalEntitiesPhonesMergeService);
}
