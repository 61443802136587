import { IContainerBinder } from '~/configuration/inversify/containerAccessor.interface';
import { LegalEntitiesGeneralInformationFirstDateAppointmentServices } from '~/modules/legalEntities/commonInfo/legalEntitiesGeneralInformation/services/legalEntitiesGeneralInformationFirstDateAppointmentServices';
import { ILegalEntitiesGeneralInformationFirstDateAppointmentServicesKey } from '~/modules/legalEntities/commonInfo/legalEntitiesGeneralInformation/services/legalEntitiesGeneralInformationFirstDateAppointmentServices.interface';
import {
  ILegalEntitiesGeneralInformationBaseConfigServiceKey,
  ILegalEntitiesGeneralInformationLeaderConfigServiceKey,
  ILegalEntitiesGeneralInformationManagementCompanyConfigServiceKey,
  ILegalEntitiesGeneralInformationManagementCompanyInfoConfigServiceKey,
  ILegalEntitiesGeneralInformationRegistrationDataConfigServiceKey
} from '~/modules/legalEntities/commonInfo/legalEntitiesGeneralInformation/services/legalEntitiesGeneralInformationConfigServices.interfaces';
import { LegalEntitiesGeneralInformationBaseConfigService } from '~/modules/legalEntities/commonInfo/legalEntitiesGeneralInformation/services/legalEntitiesGeneralInformationBaseConfigService';
import { LegalEntitiesGeneralInformationRegistrationDataConfigService } from '~/modules/legalEntities/commonInfo/legalEntitiesGeneralInformation/services/legalEntitiesGeneralInformationRegistrationDataConfigService';
import { LegalEntitiesGeneralInformationLeaderConfigService } from '~/modules/legalEntities/commonInfo/legalEntitiesGeneralInformation/services/legalEntitiesGeneralInformationLeaderConfigService';
import { LegalEntitiesGeneralInformationManagementCompanyConfigService } from '~/modules/legalEntities/commonInfo/legalEntitiesGeneralInformation/services/legalEntitiesGeneralInformationManagementCompanyConfigService';
import { LegalEntitiesGeneralInformationLeaderInfoConfigService } from '~/modules/legalEntities/commonInfo/legalEntitiesGeneralInformation/services/legalEntitiesGeneralInformationLeaderInfoConfigService';
import { ILegalEntitiesGeneralInformationLeaderInfoConfigServiceKey } from '~/modules/legalEntities/commonInfo/legalEntitiesGeneralInformation/services/legalEntitiesGeneralInformationLeaderInfoConfigService.interfaces';
import { LegalEntitiesGeneralInformationManagementCompanyInfoConfigService } from '~/modules/legalEntities/commonInfo/legalEntitiesGeneralInformation/services/legalEntitiesGeneralInformationManagementCompanyInfoConfigService';
import {
  ILegalEntitiesHasGeneralInformationServiceKey
} from '~/modules/legalEntities/commonInfo/legalEntitiesGeneralInformation/services/legalEntitiesHasGeneralInformationServices.interface';
import {
  LegalEntitiesHasGeneralInformationService
} from '~/modules/legalEntities/commonInfo/legalEntitiesGeneralInformation/services/legalEntitiesHasGeneralInformationServices';

export function bindLegalEntitiesGeneralInformationService(bind: IContainerBinder) {
  bind(ILegalEntitiesGeneralInformationFirstDateAppointmentServicesKey).to(LegalEntitiesGeneralInformationFirstDateAppointmentServices);
  bind(ILegalEntitiesGeneralInformationBaseConfigServiceKey).to(LegalEntitiesGeneralInformationBaseConfigService);
  bind(ILegalEntitiesGeneralInformationRegistrationDataConfigServiceKey).to(LegalEntitiesGeneralInformationRegistrationDataConfigService);

  bind(ILegalEntitiesGeneralInformationManagementCompanyConfigServiceKey).to(LegalEntitiesGeneralInformationManagementCompanyConfigService);
  bind(ILegalEntitiesGeneralInformationManagementCompanyInfoConfigServiceKey).to(LegalEntitiesGeneralInformationManagementCompanyInfoConfigService);
  bind(ILegalEntitiesGeneralInformationLeaderConfigServiceKey).to(LegalEntitiesGeneralInformationLeaderConfigService);
  bind(ILegalEntitiesGeneralInformationLeaderInfoConfigServiceKey).to(LegalEntitiesGeneralInformationLeaderInfoConfigService);

  bind(ILegalEntitiesHasGeneralInformationServiceKey).to(LegalEntitiesHasGeneralInformationService);
}
