import { injectable } from 'inversify';
import { ILegalEntitiesHasClaimsAndDebtsService } from '~/modules/legalEntities/commonInfo/legalEntitiesClaimsAndDebts/services/legalEntitiesHasClaimsAndDebtsService.interface';
import { LegalEntitiesBriefInfoDTO } from '~/types/dto/legalEntities/briefInfo';

@injectable()
export class LegalEntitiesHasClaimsAndDebtsService implements ILegalEntitiesHasClaimsAndDebtsService {
  checkHasClaimsAndDebts(initData: LegalEntitiesBriefInfoDTO): boolean {
    return initData.IscClaims !== 0 || initData.IscObligation !== 0 || initData.FsspDept !== 0;
  }
}
