import { injectable } from 'inversify';
import { LegalEntitiesCompanySite } from '~/types/legal-entities/companySite';
import { IPlaceholderDataService } from '../common/reportPlaceholderService.interfaces';

@injectable()
export class LegalEntitiesCompanySitePlaceholderService implements IPlaceholderDataService<LegalEntitiesCompanySite> {
  getData(): LegalEntitiesCompanySite {
    return {
      Value: ''
    };
  }
}
