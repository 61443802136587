import { injectable, inject } from 'inversify';
import { PhoneNumbersDataBlobData } from '~/types/blobs/phone-numbers-data-blob-data';
import { ReportMention } from '~/types/common/reportMention';
import { ReportMessengerInfo } from '~/types/common/reportMessengerInfo';
import { PhoneNumberPhoneCommon } from '~/types/phone-number/phoneCommon';
import { PhoneNumberRelatedPhonePerson } from '~/types/phone-number/relatedPhonePerson';
import { IPlaceholderDataService } from '~/services/placeholder/common/reportPlaceholderService.interfaces';
import { IPhoneNumberPhoneCommonNormalizationServiceKey, IPhoneNumberRelatedPhonePersonNormalizationServiceKey } from './phoneNumberNormalizationService.interface';
import { IPhoneNumberPhoneCommonPlaceholderServiceKey } from '~/services/placeholder/phoneNumber/phoneNumberPlaceholderService.interface';
import { IPrimitiveTypesNormalizationServiceKey, IPrimitiveTypesNormalizationService } from '../common/primitiveTypesNormalizationService.interfaces';
import {
  INormalizationService,
  IReportMessengerInfoNormalizationServiceKey,
  IReportMentionNormalizationServiceKey,
  IReportPassportNormalizationServiceKey
} from '../common/reportNormalizationService.interfaces';
import { ReportPassport } from '~/types/common/reportPassport';

@injectable()
export class PhoneNumbersDataBlobDataNormalizationService implements INormalizationService<PhoneNumbersDataBlobData> {
  constructor(
    @inject(IPhoneNumberPhoneCommonNormalizationServiceKey as symbol) private phoneNumberPhoneCommonNormalizationService: INormalizationService<PhoneNumberPhoneCommon>,
    @inject(IReportMessengerInfoNormalizationServiceKey as symbol) private reportMessengerInfoNormalizationService: INormalizationService<ReportMessengerInfo>,
    @inject(IReportMentionNormalizationServiceKey as symbol) private reportMentionNormalizationService: INormalizationService<ReportMention>,
    @inject(IPhoneNumberRelatedPhonePersonNormalizationServiceKey as symbol) private phoneNumberRelatedPhonePersonNormalizationService: INormalizationService<PhoneNumberRelatedPhonePerson>,
    @inject(IReportPassportNormalizationServiceKey as symbol) private reportPassportNormalizationService: INormalizationService<ReportPassport>,

    @inject(IPhoneNumberPhoneCommonPlaceholderServiceKey as symbol) private phoneNumberPhoneCommonPlaceholderService: IPlaceholderDataService<PhoneNumberPhoneCommon>,

    @inject(IPrimitiveTypesNormalizationServiceKey as symbol) private primitiveTypesNormalizationService: IPrimitiveTypesNormalizationService,
  ) {}

  normalize(data: any, root: string): PhoneNumbersDataBlobData | undefined {
    const { normalizeObject, normalizeArray } = this.primitiveTypesNormalizationService;

    return normalizeObject(data, (data) => ({
      Common: this.phoneNumberPhoneCommonNormalizationService.normalize(data.Common, `${root}.Common`) ?? this.phoneNumberPhoneCommonPlaceholderService.getData(),
      Messengers: normalizeArray(data.Messengers, (item, field) => this.reportMessengerInfoNormalizationService.normalize(item, field), `${root}.Messengers`),
      Mentions: normalizeArray(data.Mentions, (item, field) => this.reportMentionNormalizationService.normalize(item, field), `${root}.Mentions`),
      RelatedPersons: normalizeArray(data.RelatedPersons, (item, field) => this.phoneNumberRelatedPhonePersonNormalizationService.normalize(item, field), `${root}.RelatedPersons`),
      Passports: normalizeArray(data.Passports, (item, field) => this.reportPassportNormalizationService.normalize(item, field), `${root}.Passports`),
    }), root);
  }
}
