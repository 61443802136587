import { injectable, inject } from 'inversify';
import { IDataBlobDataWrapperPlaceholderServiceKey, IUniversalPlaceholderService } from '~/services/placeholder/blob/dataBlobDataWrapperPlaceholderService.interfaces';
import { GenericDataBlob } from '~/types/blobs/generic-data-blob';
import { UIReportType } from '~/types/reportType';
import { IGenericDataBlobNormalizationService } from './genericDataBlobNormalizationService.interfaces';
import { IDataBlobDataWrapperNormalizationServiceKey, IDataBlobDataWrapperNormalizationService } from './dataBlobDataWrapperNormalizationService.interfaces';

@injectable()
export class GenericDataBlobNormalizationService implements IGenericDataBlobNormalizationService {
  constructor(
    @inject(IDataBlobDataWrapperNormalizationServiceKey as symbol) private dataBlobDataWrapperNormalizationService: IDataBlobDataWrapperNormalizationService<GenericDataBlob>,

    @inject(IDataBlobDataWrapperPlaceholderServiceKey as symbol) private dataBlobDataWrapperPlaceholderService: IUniversalPlaceholderService<GenericDataBlob>,
  ) {}

  normalize(data: any, type: UIReportType): GenericDataBlob {
    try {
      return this.dataBlobDataWrapperNormalizationService.normalize(type, data)
        ?? this.dataBlobDataWrapperPlaceholderService.getData(type);
    } catch (error) {
      return data;
    }
  }
}
