import { injectable } from 'inversify';
import { MergeService } from '~/services/merge/merge-service';
import { ReportPossibleFullNameDTO } from '~/types/dto/common/reportPossibleFullNameDTO';

@injectable()
export class PossibleFioMergeService extends MergeService<ReportPossibleFullNameDTO> {
  protected canMerge(leftItem: ReportPossibleFullNameDTO, rightItem: ReportPossibleFullNameDTO): boolean {
    return leftItem.FullName === rightItem.FullName;
  }

  protected mergeFields(leftItem: ReportPossibleFullNameDTO, rightItem: ReportPossibleFullNameDTO): ReportPossibleFullNameDTO {
    return {
      FirstName: rightItem.FirstName || leftItem.FirstName,
      LastName: rightItem.LastName || leftItem.LastName,
      Patronymic: rightItem.Patronymic || leftItem.Patronymic,
      FullName: rightItem.FullName || leftItem.FullName
    };
  }
}
