import { injectable, inject } from 'inversify';
import { VehiclesDataBlobData } from '~/types/blobs/vehicles-data-blob-data';
import { ReportBoundedPersonPhone } from '~/types/common/reportBoundedPersonPhone';
import { ReportFine } from '~/types/common/reportFine';
import { ReportMessageBoardRecord } from '~/types/common/reportMessageBoardRecord';
import { ReportRoadAccident } from '~/types/common/reportRoadAccident';
import { ReportVehicle } from '~/types/common/reportVehicle';
import { ReportVehicleParking } from '~/types/common/reportVehicleParking';
import { ReportMovementCovidForVehicle } from '~/types/common/reportMovementCovidForVehicle';
import { IPrimitiveTypesNormalizationService, IPrimitiveTypesNormalizationServiceKey } from '../common/primitiveTypesNormalizationService.interfaces';
import {
  INormalizationService,
  IReportBoundedPersonPhoneNormalizationServiceKey,
  IReportFineNormalizationServiceKey,
  IReportMessageBoardRecordNormalizationServiceKey,
  IReportMovementCovidForVehicleNormalizationServiceKey,
  IReportRoadAccidentNormalizationServiceKey,
  IReportVehicleNormalizationServiceKey,
  IReportVehicleParkingNormalizationServiceKey
} from '../common/reportNormalizationService.interfaces';

@injectable()
export class VehiclesDataBlobDataNormalizationService implements INormalizationService<VehiclesDataBlobData> {
  constructor(
    @inject(IReportVehicleNormalizationServiceKey as symbol) private reportVehicleNormalizationService: INormalizationService<ReportVehicle>,
    @inject(IReportVehicleParkingNormalizationServiceKey as symbol) private reportVehicleParkingNormalizationService: INormalizationService<ReportVehicleParking>,
    @inject(IReportFineNormalizationServiceKey as symbol) private reportFineNormalizationService: INormalizationService<ReportFine>,
    @inject(IReportMovementCovidForVehicleNormalizationServiceKey as symbol) private reportMovementCovidForVehicleNormalizationService: INormalizationService<ReportMovementCovidForVehicle>,
    @inject(IReportRoadAccidentNormalizationServiceKey as symbol) private reportRoadAccidentNormalizationService: INormalizationService<ReportRoadAccident>,
    @inject(IReportBoundedPersonPhoneNormalizationServiceKey as symbol) private reportBoundedPersonPhoneNormalizationService: INormalizationService<ReportBoundedPersonPhone>,
    @inject(IReportMessageBoardRecordNormalizationServiceKey as symbol) private reportMessageBoardRecordNormalizationService: INormalizationService<ReportMessageBoardRecord>,

    @inject(IPrimitiveTypesNormalizationServiceKey as symbol) private primitiveTypesNormalizationService: IPrimitiveTypesNormalizationService,
  ) {}

  normalize(data: any, root: string): VehiclesDataBlobData | undefined {
    const { normalizeObject, normalizeArray } = this.primitiveTypesNormalizationService;

    return normalizeObject(data, (data) => ({
      Vehicles: normalizeArray(data.Vehicles, (item, field) => this.reportVehicleNormalizationService.normalize(item, field), `${root}.Vehicles`),
      VehicleParkings: normalizeArray(data.VehicleParkings, (item, field) => this.reportVehicleParkingNormalizationService.normalize(item, field), `${root}.VehicleParkings`),
      Fines: normalizeArray(data.Fines, (item, field) => this.reportFineNormalizationService.normalize(item, field), `${root}.Fines`),
      CovidMovements: normalizeArray(data.CovidMovements, (item, field) => this.reportMovementCovidForVehicleNormalizationService.normalize(item, field), `${root}.CovidMovements`),
      RoadAccidents: normalizeArray(data.RoadAccidents, (item, field) => this.reportRoadAccidentNormalizationService.normalize(item, field), `${root}.RoadAccidents`),
      BoundedPersonPhones: normalizeArray(data.BoundedPersonPhones, (item, field) => this.reportBoundedPersonPhoneNormalizationService.normalize(item, field), `${root}.BoundedPersonPhones`),
      MessageBoards: normalizeArray(data.MessageBoards, (item, field) => this.reportMessageBoardRecordNormalizationService.normalize(item, field), `${root}.MessageBoards`),
    }), root);
  }
}
