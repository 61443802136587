import { injectable, inject } from 'inversify';
import { IPrimitiveTypesNormalizationServiceKey, IPrimitiveTypesNormalizationService } from '../common/primitiveTypesNormalizationService.interfaces';
import { INormalizationService } from '../common/reportNormalizationService.interfaces';
import { LegalEntitiesBankAccount } from '~/types/legal-entities/bankAccount';

@injectable()
export class LegalEntitiesBankAccountNormalizationService implements INormalizationService<LegalEntitiesBankAccount> {
  constructor(
    @inject(IPrimitiveTypesNormalizationServiceKey as symbol) private primitiveTypesNormalizationService: IPrimitiveTypesNormalizationService
  ) {}

  normalize(data: any, root: string): LegalEntitiesBankAccount | undefined {
    const { normalizeOptionalString, normalizeObject, normalizeFieldObject } = this.primitiveTypesNormalizationService;
    
    const normalizeFieldData = normalizeFieldObject(data);

    return normalizeObject(normalizeFieldData, (data) => ({
      AccountNumber: normalizeOptionalString(data.AccountNumber, `${root}.AccountNumber`),
      Bic: normalizeOptionalString(data.Bic, `${root}.Bic`),
      Name: normalizeOptionalString(data.Name, `${root}.Name`),
      Source: normalizeOptionalString(data.Source, `${root}.Source`),
      UpdateDate: normalizeOptionalString(data.UpdateDate, `${root}.UpdateDate`)
    }), root);
  }
}
