import { injectable, inject } from 'inversify';
import { IIndividualTrustScoringConversionDataService } from '~/modules/individual/scoringScores/individualTrustScoring/service/IndividualTrustScoringConversionService.inteface';
import { IndividualTrustScoringDTO } from '~/types/dto/individual/trustScoringDTO';
import { IndividualTrustScoringConversion } from '~/modules/individual/scoringScores/individualTrustScoring/types/IndividualTrustScoringConversion';
import {
  IIndividualTrustScoringConversionItemService,
  IIndividualTrustScoringConversionItemServiceKey
} from '~/modules/individual/scoringScores/individualTrustScoring/service/individualTrustScoringConversionItem.interface';

@injectable()
export class IndividualTrustScoringConversionService implements IIndividualTrustScoringConversionDataService {
  constructor(
    @inject(IIndividualTrustScoringConversionItemServiceKey as symbol) private individualTrustScoringConversionItemService: IIndividualTrustScoringConversionItemService,
  ) {}

  getData(initDta: Array<IndividualTrustScoringDTO>): Array<IndividualTrustScoringConversion> {
    return initDta.map((item) => ({
      IncidentTypeOne: this.individualTrustScoringConversionItemService.getData(item.IncidentTypeOne, item.IncidentColorOne),
      IncidentTypeTwo: this.individualTrustScoringConversionItemService.getData(item.IncidentTypeTwo, item.IncidentColorTwo),
      IncidentTypeThree: this.individualTrustScoringConversionItemService.getData(item.IncidentTypeThree, item.IncidentColorThree),
      IncidentTypeFour: this.individualTrustScoringConversionItemService.getData(item.IncidentTypeFour, item.IncidentColorFour),
      IncidentTypeFive: this.individualTrustScoringConversionItemService.getData(item.IncidentTypeFive, item.IncidentColorFive),
      IncidentTypeSix: this.individualTrustScoringConversionItemService.getData(item.IncidentTypeSix, item.IncidentColorSix),
    }));
  }
}
