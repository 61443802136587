import { injectable } from 'inversify';
import { IndividualDriverLicense } from '~/types/individual/driverLicense';
import { IPlaceholderDataService } from '../common/reportPlaceholderService.interfaces';

@injectable()
export class IndividualDriverLicensePlaceholderService implements IPlaceholderDataService<IndividualDriverLicense> {
  getData(): IndividualDriverLicense {
    return {
      Series: '',
      Number: '',
      IssueDate: '',
      IssuePlace: '',
      DrivingExperience: 0,
      ExpireDate: '',
      Categories: '',
      IsWanted: false,
      DeprivateDecision: '',
    };
  }
}
