import { IContainerBinder } from '~/configuration/inversify/containerAccessor.interface';
import { bindFinancialAnalysisExtendedLineChartService, } from '~/modules/legalEntities/financialAnalytics/lineCharts/financialAnalysisExtendedLineChart/configuration/inversify/container.financialAnalysisExtendedLineChart';
import { bindFinancialAnalysisShortLineChartServices } from '~/modules/legalEntities/financialAnalytics/lineCharts/financialAnalysisShortLineChart/configuration/inversify/container.financialAnalysisShortLineChart';
import { bindFinancialStabilityLineChartServices } from '~/modules/legalEntities/financialAnalytics/lineCharts/financialStabilityLineChart/configuration/inversify/container.financialAnalysisExtended';
import { bindIndicatorsOfProfitabilityLineChartServices } from '~/modules/legalEntities/financialAnalytics/lineCharts/indicatorsOfProfitabilityLineChart/configuration/inversify/container.indicatorsOfProfitabilityLineChart';
import { bindIndicatorsOfCashFlowLineChartServices } from '~/modules/legalEntities/financialAnalytics/lineCharts/indicatorsOfCashFlowLineChart/configuration/inversify/container.indicatorsOfCashFlowLineChart';
import { DataOptionsPropertiesLineChartService } from '~/modules/legalEntities/financialAnalytics/lineCharts/common/services/dataOptionsPropertiesLineChartService';
import { IDataOptionsPropertiesLineChartServiceKey } from '~/modules/legalEntities/financialAnalytics/lineCharts/common/services/dataOptionsPropertiesLineChartService.interfaces';

export function bindLineChartServices(bind: IContainerBinder) {
  bindFinancialAnalysisShortLineChartServices(bind);
  bindFinancialAnalysisExtendedLineChartService(bind);
  bindFinancialStabilityLineChartServices(bind);
  bindIndicatorsOfProfitabilityLineChartServices(bind);
  bindIndicatorsOfCashFlowLineChartServices(bind);

  bind(IDataOptionsPropertiesLineChartServiceKey).to(DataOptionsPropertiesLineChartService);
}
