import { injectable } from 'inversify';
import { StringUtils } from '~/utils/stringUtils';
import { ReportVettingInfo } from '~/types/common/vettingInfo';
import { ReportVettingInfoDTO } from '~/types/dto/common/vettingInfo';
import { IReportVettingInfoAdapterService } from '../reportAdapterService.interface';
import { DateUtils } from '~/utils/date';

@injectable()
export class ReportVettingInfoAdapterService implements IReportVettingInfoAdapterService {
  getData(initData: ReportVettingInfo): ReportVettingInfoDTO {
    return {
      StartDate: initData.StartDate ?? '',
      EndDate: DateUtils.formatDateToDateAndTime(initData.EndDate ?? ''),
      RootType: initData.RootType,
      InputType: initData.InputType ?? 0,
      Decision: initData.Decision,
      Comment: StringUtils.quoteReplacement(initData.Comment ?? ''),
      UserId: StringUtils.quoteReplacement(initData.UserId ?? ''),
      User: StringUtils.quoteReplacement(initData.User ?? ''),
      UserFullName: StringUtils.quoteReplacement(initData.UserFullName ?? ''),
      UserPosition: StringUtils.quoteReplacement(initData.UserPosition ?? ''),
    };
  }
}
