import { injectable } from 'inversify';

import { ReportPledgesRegistry } from '~/types/common/reportPledgesRegistry';
import { IPlaceholderDataService } from './reportPlaceholderService.interfaces';

@injectable()
export class ReportPledgesRegistryPlaceholderService implements IPlaceholderDataService<ReportPledgesRegistry> {
  getData(): ReportPledgesRegistry {
    return {
      RegistrationDate: '',
      ExclusionDate: '',
      RegistrationNumber: '',
      Property: '',
      Pledger: '',
      Pledgee: '',
    };
  }
}
