import { injectable } from 'inversify';
import { ReportSocialNetworkMilitaryRecord } from '~/types/common/reportSocialNetworkMilitaryRecord';

import { IPlaceholderDataService } from './reportPlaceholderService.interfaces';

@injectable()
export class ReportSocialNetworkMilitaryRecordPlaceholderService implements IPlaceholderDataService<ReportSocialNetworkMilitaryRecord> {
  getData(): ReportSocialNetworkMilitaryRecord {
    return {
      Type: 0,
      SocialNetworkId: '',
      LastName: '',
      FirstName: '',
      Birthday: '',
      EducationName: '',
      City: '',
      MilitaryUnit: '',
      StartDate: '',
      EndDate: '',
      IsServingNow: false
    };
  }
}
