import { InjectionKey } from 'vue';

import { IndividualDataBlobData } from '~/types/blobs/individual-data-blob-data';
import { IndividualAccrual } from '~/types/individual/accrual';
import { IndividualAviaPerson } from '~/types/individual/aviaPerson';
import { IndividualBankruptcy } from '~/types/individual/bankruptcy';
import { IndividualBusinessTrip } from '~/types/individual/businessTrip';
import { IndividualCommonInfo } from '~/types/individual/commonInfo';
import { IndividualCommonNegativeInfo } from '~/types/individual/commonNegativeInfo';
import { IndividualCriminalPenalties } from '~/types/individual/criminalPenalties';
import { IndividualDriverLicense } from '~/types/individual/driverLicense';
import { IndividualEnforcementProceeding } from '~/types/individual/enforcementProceeding';
import { IndividualFamilyRelation } from '~/types/individual/familyRelation';
import { IndividualFinancialScoring } from '~/types/individual/financialScoring';
import { IndividualFullNameChange } from '~/types/individual/fullNameChange';
import { IndividualGroup } from '~/types/individual/group';
import { IndividualHoliday } from '~/types/individual/holiday';
import { IndividualJobRecommendation } from '~/types/individual/jobRecommendation';
import { IndividualLegalEntitiesParticipationRestriction } from '~/types/individual/legalEntitiesParticipationRestriction';
import { IndividualMaritalStatusHistory } from '~/types/individual/maritalStatusHistory';
import { IndividualMarkers } from '~/types/individual/markers';
import { IndividualMessageAboutBankruptcy } from '~/types/individual/messageAboutBankruptcy';
import { IndividualMilitaryRecord } from '~/types/individual/militaryRecord';
import { IndividualMovementBus } from '~/types/individual/movementBus';
import { IndividualPhone } from '~/types/individual/phone';
import { IndividualSkype } from '~/types/individual/skype';
import { IndividualSpecialSigns } from '~/types/individual/specialSigns';
import { IndividualStageBankruptcy } from '~/types/individual/stageBankruptcy';
import { IndividualTerroristsAndExtremistsRecord } from '~/types/individual/terroristsAndExtremistsRecord';
import { IndividualType } from '~/types/individual/type';
import { IndividualUserList } from '~/types/individual/userList';
import { IndividualWanted } from '~/types/individual/wanted';
import { IndividualWantedFsin } from '~/types/individual/wantedFsin';
import { IndividualWantedFssp } from '~/types/individual/wantedFssp';
import { IndividualWantedMvd } from '~/types/individual/wantedMvd';
import { IPlaceholderDataService } from '../common/reportPlaceholderService.interfaces';

export const IIndividualDataBlobDataPlaceholderServiceKey: InjectionKey<IPlaceholderDataService<IndividualDataBlobData>> = Symbol('IPlaceholderDataService<IndividualDataBlobData>');
export const IIndividualCommonInfoPlaceholderServiceKey: InjectionKey<IPlaceholderDataService<IndividualCommonInfo>> = Symbol('IPlaceholderDataService<IndividualCommonInfo>');
export const IIndividualCommonNegativeInfoPlaceholderServiceKey: InjectionKey<IPlaceholderDataService<IndividualCommonNegativeInfo>> = Symbol('IPlaceholderDataService<IndividualCommonNegativeInfo>');
export const IIndividualSpecialSignsPlaceholderServiceKey: InjectionKey<IPlaceholderDataService<IndividualSpecialSigns>> = Symbol('IPlaceholderDataService<IndividualSpecialSigns>');
export const IIndividualMarkersPlaceholderServiceKey: InjectionKey<IPlaceholderDataService<IndividualMarkers>> = Symbol('IPlaceholderDataService<IndividualMarkers>');
export const IIndividualGroupPlaceholderServiceKey: InjectionKey<IPlaceholderDataService<IndividualGroup>> = Symbol('IPlaceholderDataService<IndividualGroup>');
export const IIndividualUserListPlaceholderServiceKey: InjectionKey<IPlaceholderDataService<IndividualUserList>> = Symbol('IPlaceholderDataService<IndividualUserList>');
export const IIndividualMilitaryRecordPlaceholderServiceKey: InjectionKey<IPlaceholderDataService<IndividualMilitaryRecord>> = Symbol('IPlaceholderDataService<IndividualMilitaryRecord>');
export const IIndividualPhonePlaceholderServiceKey: InjectionKey<IPlaceholderDataService<IndividualPhone>> = Symbol('IPlaceholderDataService<IndividualPhone>');
export const IIndividualSkypePlaceholderServiceKey: InjectionKey<IPlaceholderDataService<IndividualSkype>> = Symbol('IPlaceholderDataService<IndividualSkype>');
export const IIndividualDriverLicensePlaceholderServiceKey: InjectionKey<IPlaceholderDataService<IndividualDriverLicense>> = Symbol('IPlaceholderDataService<IndividualDriverLicense>');
export const IIndividualJobRecommendationPlaceholderServiceKey: InjectionKey<IPlaceholderDataService<IndividualJobRecommendation>> = Symbol('IPlaceholderDataService<IndividualJobRecommendation>');
export const IIndividualBusinessTripPlaceholderServiceKey: InjectionKey<IPlaceholderDataService<IndividualBusinessTrip>> = Symbol('IPlaceholderDataService<IndividualBusinessTrip>');
export const IIndividualHolidayPlaceholderServiceKey: InjectionKey<IPlaceholderDataService<IndividualHoliday>> = Symbol('IPlaceholderDataService<IndividualHoliday>');
export const IIndividualLegalEntitiesParticipationRestrictionPlaceholderServiceKey: InjectionKey<IPlaceholderDataService<IndividualLegalEntitiesParticipationRestriction>> = Symbol('IPlaceholderDataService<IndividualLegalEntitiesParticipationRestriction>');
export const IIndividualBankruptcyPlaceholderServiceKey: InjectionKey<IPlaceholderDataService<IndividualBankruptcy>> = Symbol('IPlaceholderDataService<IndividualBankruptcy>');
export const IIndividualStageBankruptcyPlaceholderServiceKey: InjectionKey<IPlaceholderDataService<IndividualStageBankruptcy>> = Symbol('IPlaceholderDataService<IndividualStageBankruptcy>');
export const IIndividualMessageAboutBankruptcyPlaceholderServiceKey: InjectionKey<IPlaceholderDataService<IndividualMessageAboutBankruptcy>> = Symbol('IPlaceholderDataService<IndividualMessageAboutBankruptcy>');
export const IIndividualCriminalPenaltiesPlaceholderServiceKey: InjectionKey<IPlaceholderDataService<IndividualCriminalPenalties>> = Symbol('IPlaceholderDataService<IndividualCriminalPenalties>');
export const IIndividualFinancialScoringPlaceholderServiceKey: InjectionKey<IPlaceholderDataService<IndividualFinancialScoring>> = Symbol('IPlaceholderDataService<IndividualFinancialScoring>');
export const IIndividualEnforcementProceedingPlaceholderServiceKey: InjectionKey<IPlaceholderDataService<IndividualEnforcementProceeding>> = Symbol('IPlaceholderDataService<IndividualEnforcementProceeding>');
export const IIndividualWantedMvdPlaceholderServiceKey: InjectionKey<IPlaceholderDataService<IndividualWantedMvd>> = Symbol('IPlaceholderDataService<IndividualWantedMvd>');
export const IIndividualWantedFsinPlaceholderServiceKey: InjectionKey<IPlaceholderDataService<IndividualWantedFsin>> = Symbol('IPlaceholderDataService<IndividualWantedFsin>');
export const IIndividualWantedFsspPlaceholderServiceKey: InjectionKey<IPlaceholderDataService<IndividualWantedFssp>> = Symbol('IPlaceholderDataService<IndividualWantedFssp>');
export const IIndividualFullNameChangePlaceholderServiceKey: InjectionKey<IPlaceholderDataService<IndividualFullNameChange>> = Symbol('IPlaceholderDataService<IndividualFullNameChange>');
export const IIndividualTypePlaceholderServiceKey: InjectionKey<IPlaceholderDataService<IndividualType>> = Symbol('IPlaceholderDataService<IndividualType>');
export const IIndividualTerroristsAndExtremistsRecordPlaceholderServiceKey: InjectionKey<IPlaceholderDataService<IndividualTerroristsAndExtremistsRecord>> = Symbol('IPlaceholderDataService<IndividualTerroristsAndExtremistsRecord>');
export const IIndividualAccrualPlaceholderServiceKey: InjectionKey<IPlaceholderDataService<IndividualAccrual>> = Symbol('IPlaceholderDataService<IndividualAccrual>');
export const IIndividualWantedPlaceholderServiceKey: InjectionKey<IPlaceholderDataService<IndividualWanted>> = Symbol('IPlaceholderDataService<IndividualWanted>');
export const IIndividualAviaPersonPlaceholderServiceKey: InjectionKey<IPlaceholderDataService<IndividualAviaPerson>> = Symbol('IPlaceholderDataService<IndividualAviaPerson>');
export const IIndividualMovementBusPlaceholderServiceKey: InjectionKey<IPlaceholderDataService<IndividualMovementBus>> = Symbol('IPlaceholderDataService<IndividualMovementBus>');
export const IIndividualFamilyRelationPlaceholderServiceKey: InjectionKey<IPlaceholderDataService<IndividualFamilyRelation>> = Symbol('IPlaceholderDataService<IndividualFamilyRelation>');
export const IIndividualMaritalStatusHistoryPlaceholderServiceKey: InjectionKey<IPlaceholderDataService<IndividualMaritalStatusHistory>> = Symbol('IPlaceholderDataService<IndividualMaritalStatusHistory>');
