import { injectable, inject } from 'inversify';
import { ICompanyHeadService } from './getIsFirstDateService.interfaces';
import { CheckIsFirstDateService } from './checkIsFirstDateService/checkIsFirstDateService';
import { ICheckIsFirstDateServiceKey } from './checkIsFirstDateService/checkIsFirstDateService.interface';
import { LegalEntitiesCompanyHeadFlDTO } from '~/types/dto/legalEntities/companyHeadFlDTO';

@injectable()
export class CompanyHeadService implements ICompanyHeadService {
  constructor(
    @inject(ICheckIsFirstDateServiceKey as symbol) private checkIsFirstDateService: CheckIsFirstDateService,
  ) {}
  getIsFirstDate(array: Array<LegalEntitiesCompanyHeadFlDTO>): Array<(LegalEntitiesCompanyHeadFlDTO & { isFirstDate: boolean, })> {
    return this.checkIsFirstDateService.checkIsFirstDate(
      array,
      'Innfl' as keyof LegalEntitiesCompanyHeadFlDTO,
      'Fio' as keyof LegalEntitiesCompanyHeadFlDTO,
      'FirstDate' as keyof LegalEntitiesCompanyHeadFlDTO,
      'Date' as keyof LegalEntitiesCompanyHeadFlDTO
    );
  }
}
