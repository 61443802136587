import { injectable } from 'inversify';
import { NodeRowSnapshot } from '~/models/nodes/snapshots/nodeRowSnapshot';
import { ISplittableSection } from '~/types/splittables/splittableSection';
import { ISplittableCell } from '~/types/splittables/splittableCell';
import { ISplittableSectionsFactory } from '~/factories/splittables/splittableSectionsFactory.interfaces';

@injectable()
export default class SplittableSectionsFactory implements ISplittableSectionsFactory {
  buildSections<T>(rowSnapshots: NodeRowSnapshot[], sectionsData: T[]): ISplittableSection<T>[] {
    const sections = new Array<ISplittableSection<T>>();
    let sectionIdx = 0;

    for (const { snapshots } of rowSnapshots) {
      const top = Math.min(...snapshots.map((x) => x.top));
      const bottom = Math.max(...snapshots.map((x) => x.bottom));
      const height = bottom - top;
      const cells = new Array<ISplittableCell<T>>();

      for (const snapshot of snapshots) {
        cells.push({
          elements: snapshot.elements,
          top: snapshot.top,
          bottom: snapshot.bottom,
          height: snapshot.height,
          data: sectionsData[sectionIdx]
        });

        sectionIdx++;
      }

      sections.push({
        cells,
        height,
        top,
        bottom
      });
    }

    return sections;
  }
}
