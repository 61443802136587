import { injectable, inject } from 'inversify';
import { PhoneNumberPhoneCommon } from '~/types/phone-number/phoneCommon';
import { IPrimitiveTypesNormalizationServiceKey, IPrimitiveTypesNormalizationService } from '../common/primitiveTypesNormalizationService.interfaces';
import { INormalizationService } from '../common/reportNormalizationService.interfaces';

@injectable()
export class PhoneNumberPhoneCommonNormalizationService implements INormalizationService<PhoneNumberPhoneCommon> {
  constructor(
    @inject(IPrimitiveTypesNormalizationServiceKey as symbol) private primitiveTypesNormalizationService: IPrimitiveTypesNormalizationService,
  ) {}

  normalize(data: any, root: string): PhoneNumberPhoneCommon | undefined {
    const { normalizeObject, normalizeString, normalizeNumber } = this.primitiveTypesNormalizationService;

    return normalizeObject(data, (data) => ({
      PhoneType: normalizeString(data.PhoneType, `${root}.PhoneType`),
      PhoneNumber: normalizeString(data.PhoneNumber, `${root}.PhoneNumber`),
      Country: normalizeString(data.Country, `${root}.Country`),
      Provider: normalizeString(data.Provider, `${root}.Provider`),
      TimeZone: normalizeString(data.TimeZone, `${root}.TimeZone`),
      DeviceModel: normalizeString(data.DeviceModel, `${root}.DeviceModel`),
      VerificationCode: normalizeNumber(data.VerificationCode, `${root}.DeviceModel`),
    }), root);
  }
}
