import { injectable, inject } from 'inversify';
import { LegalEntitiesRelatedReason } from '~/types/legal-entities/relatedReason';
import { IPrimitiveTypesNormalizationServiceKey, IPrimitiveTypesNormalizationService } from '../common/primitiveTypesNormalizationService.interfaces';
import { INormalizationService } from '../common/reportNormalizationService.interfaces';

@injectable()
export class LegalEntitiesRelatedReasonNormalizationService implements INormalizationService<LegalEntitiesRelatedReason> {
  constructor(
    @inject(IPrimitiveTypesNormalizationServiceKey as symbol) private primitiveTypesNormalizationService: IPrimitiveTypesNormalizationService,
  ) {}

  normalize(data: any, root: string): LegalEntitiesRelatedReason | undefined {
    const { normalizeString, normalizeObject } = this.primitiveTypesNormalizationService;

    return normalizeObject(data, (data) => ({
      Name: normalizeString(data.Name, `${root}.Name`),
      Description: normalizeString(data.Description, `${root}.Description`),
      Type: normalizeString(data.Type, `${root}.Type`),
    }), root);
  }
}
