import { injectable, inject } from 'inversify';
import { ReportIndividualEntrepreneur } from '~/types/common/reportIndividualEntrepreneur';
import { IPrimitiveTypesNormalizationServiceKey, IPrimitiveTypesNormalizationService } from './primitiveTypesNormalizationService.interfaces';
import { INormalizationService } from './reportNormalizationService.interfaces';

@injectable()
export class ReportIndividualEntrepreneurNormalizationService implements INormalizationService<ReportIndividualEntrepreneur> {
  constructor(
    @inject(IPrimitiveTypesNormalizationServiceKey as symbol) private primitiveTypesNormalizationService: IPrimitiveTypesNormalizationService,
  ) {}

  normalize(data: any, root: string): ReportIndividualEntrepreneur | undefined {
    const { normalizeObject, normalizeString, normalizeOptionalString } = this.primitiveTypesNormalizationService;
    return normalizeObject(data, (data) => ({
      Ogrn: normalizeString(data.Ogrn, `${root}.Ogrn`),
      PrincipalActivity: normalizeString(data.PrincipalActivity, `${root}.PrincipalActivity`),
      Status: normalizeString(data.Status, `${root}.Status`),
      RegistrationDate: normalizeOptionalString(data.RegistrationDate, `${root}.RegistrationDate`),
      LiquidationDate: normalizeOptionalString(data.LiquidationDate, `${root}.LiquidationDate`),
      Name: normalizeOptionalString(data.Name, `${root}.Name`),
      Inn: normalizeOptionalString(data.Inn, `${root}.Inn`),
    }), root);
  }
}
