/* eslint-disable class-methods-use-this, max-len */

import { injectable, inject } from 'inversify';
import { StringUtils } from '~/utils/stringUtils';
import { LegalEntitiesCompanyFounder } from '~/types/legal-entities/companyFounder';
import { LegalEntitiesCompanyFounderDTO } from '~/types/dto/legalEntities/companyFounder';

import {
  ILegalEntitiesCompanyFounderAdapterService,
  ILegalEntitiesCompanyFounderShareAdapterService,
  ILegalEntitiesCompanyFounderShareAdapterServiceKey
} from '~/services/adapters/legalEntities/legalEntitiesAdapterService.interfaces';

@injectable()
export class LegalEntitiesCompanyFounderAdapterService implements ILegalEntitiesCompanyFounderAdapterService {
  constructor(
    @inject(ILegalEntitiesCompanyFounderShareAdapterServiceKey as symbol) private legalEntitiesCompanyFounderShareAdapterService: ILegalEntitiesCompanyFounderShareAdapterService,
  ) {} 

  getData(initData: LegalEntitiesCompanyFounder[]): LegalEntitiesCompanyFounderDTO[] {
    return (initData ?? []).map((item) => ({
      Capital: item.Capital ?? 0,
      Country: StringUtils.quoteReplacement(item.Country ?? ''),
      FounderType: StringUtils.quoteReplacement(item.FounderType ?? ''),
      Fullname: StringUtils.quoteReplacement(item.Fullname ?? ''),
      Inn: StringUtils.quoteReplacement(item.Inn ?? ''),
      Percent: item.Percent ?? 0,
      Share: this.legalEntitiesCompanyFounderShareAdapterService.getData(item.Share),
      Date: item.Date ?? '',
      FirstDate: StringUtils.quoteReplacement(item.FirstDate ?? ''),
      InaccuracyDate: item.InaccuracyDate ?? '',
      IsInaccuracy: item.IsInaccuracy ?? false,
    })).filter((item) => item.Fullname);
  }
}
