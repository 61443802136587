import { injectable, inject } from 'inversify';
import { IPlaceholderDataService } from '~/services/placeholder/common/reportPlaceholderService.interfaces';
import { ILegalEntitiesReportFinancialIndicatorBasePlaceholderDataServiceKey, ILegalEntitiesReportAverageIndustryIndicatorPlaceholderDataServiceKey } from '~/services/placeholder/legalEntities/legalEntitiesPlaceholderDataService.interfaces';
import { LegalEntitiesReportAverageIndustryIndicator } from '~/types/legal-entities/reportAverageIndustryIndicator';
import { LegalEntitiesReportFinancialIndicatorBase } from '~/types/legal-entities/reportFinancialIndicatorBase';
import { ReportIndicatorsOfProfitability } from '~/types/legal-entities/reportIndicatorsOfProfitability';
import { ILegalEntitiesReportFinancialIndicatorBaseNormalizationServiceKey, ILegalEntitiesReportAverageIndustryIndicatorNormalizationServiceKey } from '../legalEntities/legalEntitiesNormalizationService.interfaces';
import { IPrimitiveTypesNormalizationService, IPrimitiveTypesNormalizationServiceKey } from './primitiveTypesNormalizationService.interfaces';
import { INormalizationService, IStringArrayNormalizationServiceKey } from './reportNormalizationService.interfaces';

@injectable()
export class ReportIndicatorsOfProfitabilityNormalizationService implements INormalizationService<ReportIndicatorsOfProfitability> {
  constructor(
    @inject(ILegalEntitiesReportFinancialIndicatorBaseNormalizationServiceKey as symbol) private reportFinancialIndicatorBaseNormalizationService: INormalizationService<LegalEntitiesReportFinancialIndicatorBase>,
    @inject(ILegalEntitiesReportAverageIndustryIndicatorNormalizationServiceKey as symbol) private reportAverageIndustryIndicatorNormalizationService: INormalizationService<LegalEntitiesReportAverageIndustryIndicator>,

    @inject(ILegalEntitiesReportFinancialIndicatorBasePlaceholderDataServiceKey as symbol) private reportFinancialIndicatorBasePlaceholderDataService: IPlaceholderDataService<LegalEntitiesReportFinancialIndicatorBase>,
    @inject(ILegalEntitiesReportAverageIndustryIndicatorPlaceholderDataServiceKey as symbol) private reportAverageIndustryIndicatorPlaceholderDataService: IPlaceholderDataService<LegalEntitiesReportAverageIndustryIndicator>,

    @inject(IPrimitiveTypesNormalizationServiceKey as symbol) private primitiveTypesNormalizationService: IPrimitiveTypesNormalizationService,
    @inject(IStringArrayNormalizationServiceKey as symbol) private stringArrayNormalizationService: INormalizationService<string>,
  ) {}

  normalize(data: any, root: string): ReportIndicatorsOfProfitability | undefined {
    const { normalizeObject, normalizeArray } = this.primitiveTypesNormalizationService;

    return normalizeObject(data, (data) => ({
      ProfitabilityByProfitFromSales: this.reportFinancialIndicatorBaseNormalizationService.normalize(data.ProfitabilityByProfitFromSales, `${root}.ProfitabilityByProfitFromSales`) ?? this.reportFinancialIndicatorBasePlaceholderDataService.getData(),
      ProfitabilityByEbit: this.reportFinancialIndicatorBaseNormalizationService.normalize(data.ProfitabilityByEbit, `${root}.ProfitabilityByEbit`) ?? this.reportFinancialIndicatorBasePlaceholderDataService.getData(),
      NormOfNetProfit: this.reportFinancialIndicatorBaseNormalizationService.normalize(data.NormOfNetProfit, `${root}.NormOfNetProfit`) ?? this.reportFinancialIndicatorBasePlaceholderDataService.getData(),
      BasicEarningPower: this.reportFinancialIndicatorBaseNormalizationService.normalize(data.BasicEarningPower, `${root}.BasicEarningPower`) ?? this.reportFinancialIndicatorBasePlaceholderDataService.getData(),
      ReturnOnAssets: this.reportFinancialIndicatorBaseNormalizationService.normalize(data.ReturnOnAssets, `${root}.ReturnOnAssets`) ?? this.reportFinancialIndicatorBasePlaceholderDataService.getData(),
      ReturnOnInvestedCapital: this.reportFinancialIndicatorBaseNormalizationService.normalize(data.ReturnOnInvestedCapital, `${root}.ReturnOnInvestedCapital`) ?? this.reportFinancialIndicatorBasePlaceholderDataService.getData(),
      ReturnOnEquity: this.reportFinancialIndicatorBaseNormalizationService.normalize(data.ReturnOnEquity, `${root}.ReturnOnEquity`) ?? this.reportFinancialIndicatorBasePlaceholderDataService.getData(),
      ProfitabilityOfSalesRatio: this.reportAverageIndustryIndicatorNormalizationService.normalize(data.ProfitabilityOfSalesRatio, `${root}.ProfitabilityOfSalesRatio`) ?? this.reportAverageIndustryIndicatorPlaceholderDataService.getData(),
      ReturnOnAssetsRatio: this.reportAverageIndustryIndicatorNormalizationService.normalize(data.ReturnOnAssetsRatio, `${root}.ReturnOnAssetsRatio`) ?? this.reportAverageIndustryIndicatorPlaceholderDataService.getData(),
      Conclusions: normalizeArray(data.Conclusions, (item, field) => this.stringArrayNormalizationService.normalize(item, field), `${root}.Conclusions`),
    }), root);
  }
}
