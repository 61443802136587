import { IContainerBinder } from './containerAccessor.interface';

import {
  IIndividualAccrualNormalizationServiceKey,
  IIndividualAviaPersonNormalizationServiceKey,
  IIndividualBankruptcyNormalizationServiceKey,
  IIndividualBusinessTripNormalizationServiceKey,
  IIndividualCommonInfoNormalizationServiceKey,
  IIndividualCommonNegativeInfoNormalizationServiceKey,
  IIndividualCriminalPenaltiesNormalizationServiceKey,
  IIndividualDataBlobDataNormalizationServiceKey,
  IIndividualDriverLicenseNormalizationServiceKey,
  IIndividualEnforcementProceedingNormalizationServiceKey,
  IIndividualFamilyRelationNormalizationServiceKey,
  IIndividualFinancialScoringNormalizationServiceKey,
  IIndividualFullNameChangeNormalizationServiceKey,
  IIndividualGroupNormalizationServiceKey,
  IIndividualHolidayNormalizationServiceKey,
  IIndividualJobRecommendationNormalizationServiceKey,
  IIndividualLegalEntitiesParticipationRestrictionNormalizationServiceKey,
  IIndividualMaritalStatusHistoryNormalizationServiceKey,
  IIndividualMarkersNormalizationServiceKey,
  IIndividualMessageAboutBankruptcyNormalizationServiceKey,
  IIndividualMilitaryRecordNormalizationServiceKey,
  IIndividualMovementBusNormalizationServiceKey,
  IIndividualPhoneNormalizationServiceKey,
  IIndividualSkypeNormalizationServiceKey,
  IIndividualSpecialSignsNormalizationServiceKey,
  IIndividualStageBankruptcyNormalizationServiceKey,
  IIndividualTerroristsAndExtremistsRecordNormalizationServiceKey,
  IIndividualTrustScoringNormalizationServiceKey,
  IIndividualTypeNormalizationServiceKey,
  IIndividualUserListNormalizationServiceKey,
  IIndividualWantedFsinNormalizationServiceKey,
  IIndividualWantedFsspNormalizationServiceKey,
  IIndividualWantedMvdNormalizationServiceKey,
  IIndividualWantedNormalizationServiceKey
} from '~/services/normalization/individual/individualNormalizationService.interfaces';
import { IndividualDataBlobDataNormalizationService } from '~/services/normalization/individual/individualDataBlobDataNormalizationService';
import { IndividualCommonInfoNormalizationService } from '~/services/normalization/individual/individualCommonInfoNormalizationService';
import { IndividualCommonNegativeInfoNormalizationService } from '~/services/normalization/individual/individualCommonNegativeInfoNormalizationService';
import { IndividualSpecialSignsNormalizationService } from '~/services/normalization/individual/individualSpecialSignsNormalizationService';
import { IndividualMarkersNormalizationService } from '~/services/normalization/individual/individualMarkersNormalizationService';
import { IndividualGroupNormalizationService } from '~/services/normalization/individual/individualGroupNormalizationService';
import { IndividualUserListNormalizationService } from '~/services/normalization/individual/individualUserListNormalizationService';
import { IndividualMilitaryRecordNormalizationService } from '~/services/normalization/individual/individualMilitaryRecordNormalizationService';
import { IndividualPhoneNormalizationService } from '~/services/normalization/individual/individualPhoneNormalizationService';
import { IndividualSkypeNormalizationService } from '~/services/normalization/individual/individualSkypeNormalizationService';
import { IndividualDriverLicenseNormalizationService } from '~/services/normalization/individual/individualDriverLicenseNormalizationService';
import { IndividualJobRecommendationNormalizationService } from '~/services/normalization/individual/individualJobRecommendationNormalizationService';
import { IndividualBusinessTripNormalizationService } from '~/services/normalization/individual/individualBusinessTripNormalizationService';
import { IndividualHolidayNormalizationService } from '~/services/normalization/individual/individualHolidayNormalizationService';
import { IndividualLegalEntitiesParticipationRestrictionNormalizationService } from '~/services/normalization/individual/individualLegalEntitiesParticipationRestrictionNormalizationService';
import { IndividualBankruptcyNormalizationService } from '~/services/normalization/individual/individualBankruptcyNormalizationService';
import { IndividualStageBankruptcyNormalizationService } from '~/services/normalization/individual/individualStageBankruptcyNormalizationService';
import { IndividualMessageAboutBankruptcyNormalizationService } from '~/services/normalization/individual/individualMessageAboutBankruptcyNormalizationService';
import { IndividualCriminalPenaltiesNormalizationService } from '~/services/normalization/individual/individualCriminalPenaltiesNormalizationService';
import { IndividualFinancialScoringNormalizationService } from '~/services/normalization/individual/individualFinancialScoringNormalizationService';
import { IndividualEnforcementProceedingNormalizationService } from '~/services/normalization/individual/individualEnforcementProceedingNormalizationService';
import { IndividualWantedMvdNormalizationService } from '~/services/normalization/individual/individualWantedMvdNormalizationService';
import { IndividualWantedFsinNormalizationService } from '~/services/normalization/individual/individualWantedFsinNormalizationService';
import { IndividualWantedFsspNormalizationService } from '~/services/normalization/individual/individualWantedFsspNormalizationService';
import { IndividualFullNameChangeNormalizationService } from '~/services/normalization/individual/individualFullNameChangeNormalizationService';
import { IndividualTypeNormalizationService } from '~/services/normalization/individual/individualTypeNormalizationService';
import { IndividualTerroristsAndExtremistsRecordNormalizationServiceKey } from '~/services/normalization/individual/individualTerroristsAndExtremistsRecordNormalizationService';
import { IndividualAccrualNormalizationService } from '~/services/normalization/individual/individualAccrualNormalizationService';
import { IndividualWantedNormalizationService } from '~/services/normalization/individual/individualWantedNormalizationService';
import { IndividualAviaPersonNormalizationService } from '~/services/normalization/individual/individualAviaPersonNormalizationService';
import { IndividualMovementBusNormalizationService } from '~/services/normalization/individual/individualMovementBusNormalizationService';
import { IndividualFamilyRelationNormalizationService } from '~/services/normalization/individual/individualFamilyRelationNormalizationService';
import { IndividualMaritalStatusHistoryNormalizationService } from '~/services/normalization/individual/individualMaritalStatusHistoryNormalizationService';
import { IndividualTrustScoringNormalizationService } from '~/services/normalization/individual/individualTrustScoringNormalizationService';

export function bindIndividualNormalizationServices(bind: IContainerBinder) {
  bind(IIndividualDataBlobDataNormalizationServiceKey).to(IndividualDataBlobDataNormalizationService);
  bind(IIndividualCommonInfoNormalizationServiceKey).to(IndividualCommonInfoNormalizationService);
  bind(IIndividualCommonNegativeInfoNormalizationServiceKey).to(IndividualCommonNegativeInfoNormalizationService);
  bind(IIndividualSpecialSignsNormalizationServiceKey).to(IndividualSpecialSignsNormalizationService);
  bind(IIndividualMarkersNormalizationServiceKey).to(IndividualMarkersNormalizationService);
  bind(IIndividualGroupNormalizationServiceKey).to(IndividualGroupNormalizationService);
  bind(IIndividualUserListNormalizationServiceKey).to(IndividualUserListNormalizationService);
  bind(IIndividualMilitaryRecordNormalizationServiceKey).to(IndividualMilitaryRecordNormalizationService);
  bind(IIndividualPhoneNormalizationServiceKey).to(IndividualPhoneNormalizationService);
  bind(IIndividualSkypeNormalizationServiceKey).to(IndividualSkypeNormalizationService);
  bind(IIndividualDriverLicenseNormalizationServiceKey).to(IndividualDriverLicenseNormalizationService);
  bind(IIndividualJobRecommendationNormalizationServiceKey).to(IndividualJobRecommendationNormalizationService);
  bind(IIndividualBusinessTripNormalizationServiceKey).to(IndividualBusinessTripNormalizationService);
  bind(IIndividualHolidayNormalizationServiceKey).to(IndividualHolidayNormalizationService);
  bind(IIndividualLegalEntitiesParticipationRestrictionNormalizationServiceKey).to(IndividualLegalEntitiesParticipationRestrictionNormalizationService);
  bind(IIndividualBankruptcyNormalizationServiceKey).to(IndividualBankruptcyNormalizationService);
  bind(IIndividualStageBankruptcyNormalizationServiceKey).to(IndividualStageBankruptcyNormalizationService);
  bind(IIndividualMessageAboutBankruptcyNormalizationServiceKey).to(IndividualMessageAboutBankruptcyNormalizationService);
  bind(IIndividualCriminalPenaltiesNormalizationServiceKey).to(IndividualCriminalPenaltiesNormalizationService);
  bind(IIndividualFinancialScoringNormalizationServiceKey).to(IndividualFinancialScoringNormalizationService);
  bind(IIndividualTrustScoringNormalizationServiceKey).to(IndividualTrustScoringNormalizationService);
  bind(IIndividualEnforcementProceedingNormalizationServiceKey).to(IndividualEnforcementProceedingNormalizationService);
  bind(IIndividualWantedMvdNormalizationServiceKey).to(IndividualWantedMvdNormalizationService);
  bind(IIndividualWantedFsinNormalizationServiceKey).to(IndividualWantedFsinNormalizationService);
  bind(IIndividualWantedFsspNormalizationServiceKey).to(IndividualWantedFsspNormalizationService);
  bind(IIndividualFullNameChangeNormalizationServiceKey).to(IndividualFullNameChangeNormalizationService);
  bind(IIndividualTypeNormalizationServiceKey).to(IndividualTypeNormalizationService);
  bind(IIndividualTerroristsAndExtremistsRecordNormalizationServiceKey).to(IndividualTerroristsAndExtremistsRecordNormalizationServiceKey);
  bind(IIndividualAccrualNormalizationServiceKey).to(IndividualAccrualNormalizationService);
  bind(IIndividualWantedNormalizationServiceKey).to(IndividualWantedNormalizationService);
  bind(IIndividualAviaPersonNormalizationServiceKey).to(IndividualAviaPersonNormalizationService);
  bind(IIndividualMovementBusNormalizationServiceKey).to(IndividualMovementBusNormalizationService);
  bind(IIndividualFamilyRelationNormalizationServiceKey).to(IndividualFamilyRelationNormalizationService);
  bind(IIndividualMaritalStatusHistoryNormalizationServiceKey).to(IndividualMaritalStatusHistoryNormalizationService);
}
