import { injectable } from 'inversify';

import { LegalEntitiesSuccessor } from '~/types/legal-entities/successor';
import { IPlaceholderDataService } from '../common/reportPlaceholderService.interfaces';

@injectable()
export class LegalEntitiesSuccessorPlaceholderDataService implements IPlaceholderDataService<LegalEntitiesSuccessor> {
  getData(): LegalEntitiesSuccessor {
    return {
      OrganisationName: '',
      Inn: '',
      Ogrn: '',
      Date: '',
      Status: '',
    };
  }
}
