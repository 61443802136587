import { injectable } from 'inversify';
import { IIndividualFinancialScoringBasedDataConfigService } from '~/modules/individual/scoringScores/individualFinancialScoring/services/individualFinancialScoringConfigService.interfaces';
import { IndividualFinancialScoringDTO } from '~/types/dto/individual/financialScoringDTO';
import { Config } from '~/types/field-list/config';

@injectable()
export class IndividualFinancialScoringBasedDataConfigService implements IIndividualFinancialScoringBasedDataConfigService {
  getConfig(initData: IndividualFinancialScoringDTO): Config {
    return {
      fieldsDirection: 'divider-row',
      fieldsWidth: '100%',
      fieldsValueWidth: '350px',
      isBeginningDivider: true,
      fields: [
        {
          label: 'ФИО',
          key: 'checkedFio',
          value: initData.CheckedFio
        },
        {
          label: 'Номер телефона',
          key: 'checkedPhone',
          value: initData.CheckedPhone
        },
        {
          label: 'Дата рождения',
          key: 'checkedBirthday',
          value: initData.CheckedBirthday
        },
        {
          label: 'Номер паспорта',
          key: 'checkedPassportNumber',
          value: initData.CheckedPassportNumber
        },
        {
          label: 'Дата выдачи паспорта',
          key: 'checkedPassportIssueDate',
          value: initData.CheckedPassportIssueDate
        },
      ]
    };
  }
}
