import { inject, injectable } from 'inversify';
import { IIndicatorsOfProfitabilityLineChartService } from '~/modules/legalEntities/financialAnalytics/lineCharts/indicatorsOfProfitabilityLineChart/services/indicatorsOfProfitabilityLineChartService.interfaces';
import { LegalEntitiesFinancialAnalysisExtendedDTO } from '~/types/dto/legalEntities/financialAnalysisExtended';
import { DataOptionLineChart } from '~/modules/legalEntities/financialAnalytics/lineCharts/common/types/DataOptionLineChart';
import {
  IDataOptionsPropertiesLineChartService,
  IDataOptionsPropertiesLineChartServiceKey
} from '~/modules/legalEntities/financialAnalytics/lineCharts/common/services/dataOptionsPropertiesLineChartService.interfaces';
import { LINE_CHART_COLOR_PALETTE } from '~/modules/legalEntities/financialAnalytics/lineCharts/common/configuration/constants/colorPalette';
import { LINE_CHART_GRADIENT_PALETTE } from '~/modules/legalEntities/financialAnalytics/lineCharts/common/configuration/constants/gradientPalette';
import { INDICATORS_OF_PROFITABILITY } from '~/modules/legalEntities/financialAnalytics/common/configuration/constants/indicatorsOfProfitability';

@injectable()
export class IndicatorsOfProfitabilityLineChartService implements IIndicatorsOfProfitabilityLineChartService {
  constructor(
    @inject(IDataOptionsPropertiesLineChartServiceKey as symbol) private dataOptionsPropertiesLineChartService: IDataOptionsPropertiesLineChartService
  ) {}

  private readonly FILL_GRADIENT = {
    ProfitabilityByProfitFromSales: LINE_CHART_GRADIENT_PALETTE.turquoise,
    ProfitabilityByEbit: LINE_CHART_GRADIENT_PALETTE.orange,
    NormOfNetProfit: LINE_CHART_GRADIENT_PALETTE.red,
    BasicEarningPower: LINE_CHART_GRADIENT_PALETTE.green,
    ReturnOnAssets: LINE_CHART_GRADIENT_PALETTE.blue,
    ReturnOnInvestedCapital: LINE_CHART_GRADIENT_PALETTE.purple,
    ReturnOnEquity: LINE_CHART_GRADIENT_PALETTE.yellow,
  };

  private readonly BORDER_COLOR = {
    ProfitabilityByProfitFromSales: LINE_CHART_COLOR_PALETTE.turquoise,
    ProfitabilityByEbit: LINE_CHART_COLOR_PALETTE.orange,
    NormOfNetProfit: LINE_CHART_COLOR_PALETTE.red,
    BasicEarningPower: LINE_CHART_COLOR_PALETTE.green,
    ReturnOnAssets: LINE_CHART_COLOR_PALETTE.blue,
    ReturnOnInvestedCapital: LINE_CHART_COLOR_PALETTE.purple,
    ReturnOnEquity: LINE_CHART_COLOR_PALETTE.yellow,
  };
  getDataOptions(initData: LegalEntitiesFinancialAnalysisExtendedDTO): Array<DataOptionLineChart> {
    const { IndicatorsOfProfitability } = initData;
    const dataProfitabilityChart = {
      ProfitabilityByProfitFromSales: IndicatorsOfProfitability.ProfitabilityByProfitFromSales,
      ProfitabilityByEbit: IndicatorsOfProfitability.ProfitabilityByEbit,
      NormOfNetProfit: IndicatorsOfProfitability.NormOfNetProfit,
      BasicEarningPower: IndicatorsOfProfitability.BasicEarningPower,
      ReturnOnAssets: IndicatorsOfProfitability.ReturnOnAssets,
      ReturnOnInvestedCapital: IndicatorsOfProfitability.ReturnOnInvestedCapital,
      ReturnOnEquity: IndicatorsOfProfitability.ReturnOnEquity,
    };

    return Object.keys(dataProfitabilityChart).map((key) => {
      const typedKey = key as keyof typeof dataProfitabilityChart;
          
      return {
        title: INDICATORS_OF_PROFITABILITY[typedKey],
        colors: this.FILL_GRADIENT[typedKey],
        borderColors: this.BORDER_COLOR[typedKey],
        data: this.dataOptionsPropertiesLineChartService.getData(dataProfitabilityChart[typedKey]),
        labels: this.dataOptionsPropertiesLineChartService.getLabels(initData.TwoYearsAgo, initData.LastYear, initData.CurrentYear)
      };
    });
  }
}
