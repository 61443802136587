import { ReportScoreRating } from '~/types/common/reportScoreRating';

export module IndividualTrustScoringUtils {
  export function getNumberOfIncidentsByColor(value: string): ReportScoreRating {
    switch (value) {
      case 'RED':
        return {
          value: 'Найден 1 либо 2 инцидента',
          color: 'orange',
        };
      case 'HIGH_RED':
        return {
          value: 'Найдено 3 и более инцидентов',
          color: 'red',
        };
      default:
        return {
          value: 'Инциденты отсутствуют',
          color: 'aqua',
        };
    }
  }
}
