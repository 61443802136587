import { injectable } from 'inversify';
import { orderBy } from 'lodash-es';
import { IIndividualLegalEntitiesParticipationRestrictionsAdapterService } from '~/services/adapters/individual/individualAdapterService.interfaces';
import { IndividualLegalEntitiesParticipationRestriction } from '~/types/individual/legalEntitiesParticipationRestriction';
import { DateUtils } from '~/utils/date';
import { IndividualLegalEntitiesParticipationRestrictionDTO } from '~/types/dto/individual/legalEntitiesParticipationRestrictionDTO';

@injectable()
export class IndividualLegalEntitiesParticipationRestrictionsAdapterService implements IIndividualLegalEntitiesParticipationRestrictionsAdapterService {
  getData(initData: IndividualLegalEntitiesParticipationRestriction[]): IndividualLegalEntitiesParticipationRestrictionDTO[] {
    return orderBy((initData ?? []).map((item) => ({
      ParticipationType: item.ParticipationType ?? '',
      CompanyName: item.CompanyName ?? '',
      Inn: item.Inn ?? '',
      Ogrn: item.Ogrn ?? '',
      Status: item.Status ?? '',
      PrincipalActivity: item.PrincipalActivity ?? '',
      RiskIndicator: item.RiskIndicator ?? '',
      Address: item.Address ?? '',
      RegistrationDate: DateUtils.formatDate(item.RegistrationDate),
      LiquidationDate: DateUtils.formatDate(item.LiquidationDate),
      Position: item.Position ?? '',
      IsHistoric: item.IsHistoric ?? false,
    })), ['ParticipationType'], ['desc']);
  }
}
