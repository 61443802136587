import { injectable } from 'inversify';
import { IIpInnRequisiteMatchingService } from '~/services/matching/ipInnRequisiteMatchingService.interfaces';

@injectable()
export class IpInnRequisiteMatchingService implements IIpInnRequisiteMatchingService {
  matchPartial(value: string): boolean {
    const regExpInnInclude = /^\d{13}$/;
    const result = value.match(regExpInnInclude);
    return Array.isArray(result);
  }
}
