import { IContainerBinder } from '~/configuration/inversify/containerAccessor.interface';
import { LegalEntitiesAuthorityToSignConfigService } from '~/modules/legalEntities/changeHistory/LegalEntitiesAuthorityToSignChangeHistory/services/legalEntitiesAuthorityToSignConfigService';
import { ILegalEntitiesAuthorityToSignMergeServiceKey } from '~/modules/legalEntities/changeHistory/LegalEntitiesAuthorityToSignChangeHistory/services/legalEntitiesAuthorityToSignMergeService.interfaces';
import { LegalEntitiesAuthorityToSignMergeService } from '~/modules/legalEntities/changeHistory/LegalEntitiesAuthorityToSignChangeHistory/services/legalEntitiesAuthorityToSignMergeService';
import { ILegalEntitiesAuthorityToSignConfigServiceKey } from '~/modules/legalEntities/changeHistory/LegalEntitiesAuthorityToSignChangeHistory/services/legalEntitiesAuthorityToSignConfigService.interfaces';
import { LegalEntitiesAuthorityToSignSiftingService } from '~/modules/legalEntities/changeHistory/LegalEntitiesAuthorityToSignChangeHistory/services/legalEntitiesAuthorityToSignSiftingService';
import { ILegalEntitiesAuthorityToSignSiftingServiceKey } from '~/modules/legalEntities/changeHistory/LegalEntitiesAuthorityToSignChangeHistory/services/LegalEntitiesAuthorityToSignSiftingService.interface';

export function bindLegalEntitiesAuthorityToSignServices(bind: IContainerBinder) {
  bind(ILegalEntitiesAuthorityToSignConfigServiceKey).to(LegalEntitiesAuthorityToSignConfigService);
  bind(ILegalEntitiesAuthorityToSignMergeServiceKey).to(LegalEntitiesAuthorityToSignMergeService);
  bind(ILegalEntitiesAuthorityToSignSiftingServiceKey).to(LegalEntitiesAuthorityToSignSiftingService);
}
