import { injectable, inject } from 'inversify';

import { LegalEntitiesReportSocialNetworkId } from '~/types/legal-entities/reportSocialNetworkId';
import { IPrimitiveTypesNormalizationServiceKey, IPrimitiveTypesNormalizationService } from '../common/primitiveTypesNormalizationService.interfaces';
import { INormalizationService } from '../common/reportNormalizationService.interfaces';

@injectable()
export class LegalEntitiesReportSocialNetworkIdNormalizationService implements INormalizationService<LegalEntitiesReportSocialNetworkId> {
  constructor(
    @inject(IPrimitiveTypesNormalizationServiceKey as symbol) private primitiveTypesNormalizationService: IPrimitiveTypesNormalizationService,
  ) {}

  normalize(data: any, root: string): LegalEntitiesReportSocialNetworkId | undefined {
    const { normalizeString, normalizeNumber, normalizeObject } = this.primitiveTypesNormalizationService;

    return normalizeObject(data, (data) => ({
      Type: normalizeNumber(data.Type, `${root}.Type`),
      SocialId: normalizeString(data.SocialId, `${root}.SocialId`),
    }), root);
  }
}
