import { injectable } from 'inversify';
import { IReportPersonSiteAdapterService } from '~/services/adapters/common/reportAdapterService.interfeces';
import { ReportPersonSite } from '~/types/common/reportPersonSite';
import { ReportPersonSiteDTO } from '~/types/dto/common/reportPersonSiteDTO';
import { StringUtils } from '~/utils/stringUtils';

@injectable()
export class ReportPersonSiteAdapterService implements IReportPersonSiteAdapterService {
  getData(initData: ReportPersonSite[]): ReportPersonSiteDTO[] {
    return (initData ?? []).map((item) => ({
      DomainName: StringUtils.quoteReplacement(item.DomainName),
      Status: StringUtils.quoteReplacement(item.Status),
      Administrator: StringUtils.quoteReplacement(item.Administrator),
      RegistrationDate: item.RegistrationDate ?? '',
      ExpirationDate: item.ExpirationDate ?? ''
    }));
  }
}
