import { injectable, inject } from 'inversify';
import { ReportVehiclePhone } from '~/types/common/reportVehiclePhone';
import { IPrimitiveTypesNormalizationServiceKey, IPrimitiveTypesNormalizationService } from './primitiveTypesNormalizationService.interfaces';
import { INormalizationService } from './reportNormalizationService.interfaces';

@injectable()
export class ReportVehiclePhoneNormalizationService implements INormalizationService<ReportVehiclePhone> {
  constructor(
    @inject(IPrimitiveTypesNormalizationServiceKey as symbol) private primitiveTypesNormalizationService: IPrimitiveTypesNormalizationService,
  ) {}

  normalize(data: any, root: string): ReportVehiclePhone | undefined {
    const { normalizeString, normalizeObject } = this.primitiveTypesNormalizationService;

    return normalizeObject(data, (data) => ({
      Phone: normalizeString(data.Phone, `${root}.Phone`)
    }), root);
  }
}
