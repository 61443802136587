import { injectable } from 'inversify';
import {
  IReportConnectionRelatedRelationsAdapterService
} from '~/services/adapters/common/reportAdapterService.interfeces';
import { ReportConnectionRelatedRelation } from '~/types/common/reportConnectionRelatedRelation';
import { ReportConnectionRelatedRelationDTO } from '~/types/dto/common/reportConnectionRelatedRelationDTO';

@injectable()
export class ReportConnectionRelatedRelationsAdapterService implements IReportConnectionRelatedRelationsAdapterService {
  getData(Relations: Array<ReportConnectionRelatedRelation>): Array<ReportConnectionRelatedRelationDTO> {
    return (Relations ?? []).map((relation) => ({
      DisplayName: relation.DisplayName ?? '',
      DisplayType: relation.DisplayType ?? '',
      Type: relation.Type,
      Depth: relation.Depth ?? 0,
      Comparison: relation.Comparison ?? 0,
      HasMlData: relation.HasMlData ?? false
    }));
  }
}
