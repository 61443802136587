/* eslint-disable class-methods-use-this */

import { injectable } from 'inversify';
import { IPrimitiveTypesMergeService } from '@/services/merge/primitive-types-merge-service.interface';

@injectable()
export class PrimitiveTypesMergeService implements IPrimitiveTypesMergeService {
  mergeStrings(left: string, right: string): string {
    return left.length > 0 ? left : right;
  }

  mergeOptionalStrings(left?: string, right?: string): string | undefined {
    if (left != undefined || right != undefined) {
      return this.mergeStrings(left ?? '', right ?? '');
    }

    return undefined;
  }
}
