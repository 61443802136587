import { injectable } from 'inversify';
import { PhoneNumberRelatedPhonePersonCommon } from '~/types/phone-number/relatedPhonePersonCommon';
import { IPlaceholderDataService } from '~/services/placeholder/common/reportPlaceholderService.interfaces';

@injectable()
export class PhoneNumberRelatedPhonePersonCommonPlaceholderService implements IPlaceholderDataService<PhoneNumberRelatedPhonePersonCommon> {
  getData(): PhoneNumberRelatedPhonePersonCommon {
    return {
      Birthday: '',
      BirthPlace: '',
      Citizenship: '',
      Nationality: '',
    };
  }
}
