import { injectable } from 'inversify';
import { LegalEntitiesCompanyEmail } from '~/types/legal-entities/companyEmail';
import { IPlaceholderDataService } from '../common/reportPlaceholderService.interfaces';

@injectable()
export class LegalEntitiesCompanyEmailPlaceholderService implements IPlaceholderDataService<LegalEntitiesCompanyEmail> {
  getData(): LegalEntitiesCompanyEmail {
    return {
      Value: '',
      CompaniesWithThisEmail: 0,
    };
  }
}
