import { injectable } from 'inversify';

import { ReportMainEntityFileUrl } from '~/types/common/reportMainEntityFileUrl';
import { IPlaceholderDataService } from './reportPlaceholderService.interfaces';

@injectable()
export class ReportMainEntityFileUrlPlaceholderService implements IPlaceholderDataService<ReportMainEntityFileUrl> {
  getData(): ReportMainEntityFileUrl {
    return {
      Text: '',
      Value: ''
    };
  }
}
