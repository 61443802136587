import { IContainerBinder } from '~/configuration/inversify/containerAccessor.interface';
import { LegalEntitiesCompanyFounderConfigService } from '~/modules/legalEntities/common/legalEntitiesCompanyFounder/services/legalEntitiesCompanyFounderConfigService';
import { LegalEntitiesCompanyFounderFullNameConfigService } from '~/modules/legalEntities/common/legalEntitiesCompanyFounder/services/legalEntitiesCompanyFounderFullNameConfigService';
import {
  ILegalEntitiesCompanyFounderConfigServiceKey,
  ILegalEntitiesCompanyFounderFullNameConfigServiceKey
} from '~/modules/legalEntities/common/legalEntitiesCompanyFounder/services/legalEntitiesCompanyFounderConfigService.interfaces';
import { ILegalEntitiesCompanyFoundersMergeServiceKey } from '~/modules/legalEntities/common/legalEntitiesCompanyFounder/services/legalEntitiesCompanyFoundersMergeService.interfaces';
import { LegalEntitiesCompanyFoundersMergeService } from '~/modules/legalEntities/common/legalEntitiesCompanyFounder/services/legalEntitiesCompanyFoundersMergeService';

export function bindLegalEntitiesCompanyFounderServices(bind: IContainerBinder) {
  bind(ILegalEntitiesCompanyFounderConfigServiceKey).to(LegalEntitiesCompanyFounderConfigService);
  bind(ILegalEntitiesCompanyFounderFullNameConfigServiceKey).to(LegalEntitiesCompanyFounderFullNameConfigService);

  bind(ILegalEntitiesCompanyFoundersMergeServiceKey).to(LegalEntitiesCompanyFoundersMergeService);
}
