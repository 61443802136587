import { IContainerBinder } from './containerAccessor.interface';
import { PhoneNumberPhoneCommonPlaceholderService } from '~/services/placeholder/phoneNumber/phoneNumberPhoneCommonPlaceholderService';
import { PhoneNumberRelatedPhonePersonCommonPlaceholderService } from '~/services/placeholder/phoneNumber/phoneNumberRelatedPhonePersonCommonPlaceholderService';
import { PhoneNumberRelatedPhonePersonPlaceholderService } from '~/services/placeholder/phoneNumber/phoneNumberRelatedPhonePersonPlaceholderService';
import { PhoneNumbersDataBlobDataPlaceholderService } from '~/services/placeholder/phoneNumber/phoneNumbersDataBlobDataPlaceholderService';
import {
  IPhoneNumberPhoneCommonPlaceholderServiceKey, 
  IPhoneNumbersDataBlobDataPlaceholderServiceKey,
  IPhoneNumberRelatedPhonePersonCommonPlaceholderServiceKey,
  IPhoneNumberRelatedPhonePersonPlaceholderServiceKey,
} from '~/services/placeholder/phoneNumber/phoneNumberPlaceholderService.interface';

export function bindPhoneNumberPlaceholderService(bind: IContainerBinder) {
  bind(IPhoneNumberPhoneCommonPlaceholderServiceKey).to(PhoneNumberPhoneCommonPlaceholderService);
  bind(IPhoneNumbersDataBlobDataPlaceholderServiceKey).to(PhoneNumbersDataBlobDataPlaceholderService);
  bind(IPhoneNumberRelatedPhonePersonCommonPlaceholderServiceKey).to(PhoneNumberRelatedPhonePersonCommonPlaceholderService);
  bind(IPhoneNumberRelatedPhonePersonPlaceholderServiceKey).to(PhoneNumberRelatedPhonePersonPlaceholderService);
}
