import { injectable, inject } from 'inversify';
import {
  ILegalEntitiesReportAverageIndustryIndicatorAdapterService,
  ILegalEntitiesReportAverageIndustryIndicatorAdapterServiceKey,
  ILegalEntitiesReportFinancialIndicatorBaseAdapterService,
  ILegalEntitiesReportFinancialIndicatorBaseAdapterServiceKey,
  ILegalEntitiesReportIndicatorsOfProfitabilityAdapterService,
} from '~/services/adapters/legalEntities/legalEntitiesAdapterService.interfaces';
import {
  IReportArrayStringAdapterService,
  IReportArrayStringAdapterServiceKey
} from '~/services/adapters/common/reportAdapterService.interfeces';
import { ReportIndicatorsOfProfitability } from '~/types/legal-entities/reportIndicatorsOfProfitability';
import { LegalEntitiesReportIndicatorsOfProfitabilityDTO } from '~/types/dto/legalEntities/reportIndicatorsOfProfitability';

@injectable()
export class LegalEntitiesReportIndicatorsOfProfitabilityAdapterService implements ILegalEntitiesReportIndicatorsOfProfitabilityAdapterService {
  constructor(
    @inject(IReportArrayStringAdapterServiceKey as symbol) private reportArrayStringAdapterService: IReportArrayStringAdapterService,
    @inject(ILegalEntitiesReportFinancialIndicatorBaseAdapterServiceKey as symbol) private legalEntitiesReportFinancialIndicatorBase: ILegalEntitiesReportFinancialIndicatorBaseAdapterService,
    @inject(ILegalEntitiesReportAverageIndustryIndicatorAdapterServiceKey as symbol) private legalEntitiesReportAverageIndustryIndicatorAdapterService: ILegalEntitiesReportAverageIndustryIndicatorAdapterService,
  ) {}
  getData(initData: ReportIndicatorsOfProfitability): LegalEntitiesReportIndicatorsOfProfitabilityDTO {
    return {
      ProfitabilityByProfitFromSales: this.legalEntitiesReportFinancialIndicatorBase.getData(initData.ProfitabilityByProfitFromSales),
      ProfitabilityByEbit: this.legalEntitiesReportFinancialIndicatorBase.getData(initData.ProfitabilityByEbit),
      NormOfNetProfit: this.legalEntitiesReportFinancialIndicatorBase.getData(initData.NormOfNetProfit),
      BasicEarningPower: this.legalEntitiesReportFinancialIndicatorBase.getData(initData.BasicEarningPower),
      ReturnOnAssets: this.legalEntitiesReportFinancialIndicatorBase.getData(initData.ReturnOnAssets),
      ReturnOnInvestedCapital: this.legalEntitiesReportFinancialIndicatorBase.getData(initData.ReturnOnInvestedCapital),
      ReturnOnEquity: this.legalEntitiesReportFinancialIndicatorBase.getData(initData.ReturnOnEquity),
      ProfitabilityOfSalesRatio: this.legalEntitiesReportAverageIndustryIndicatorAdapterService.getData(initData.ProfitabilityOfSalesRatio),
      ReturnOnAssetsRatio: this.legalEntitiesReportAverageIndustryIndicatorAdapterService.getData(initData.ReturnOnAssetsRatio),
      Conclusions: this.reportArrayStringAdapterService.getData(initData.Conclusions)
    };
  }
}
