import { injectable } from 'inversify';
import {
  IPossibleNamesSiftingService
} from '~/modules/reportsCommon/reportPossible/individual/servises/possibleNamesSiftingService.interfaces';
import { ReportPossibleFullNameDTO } from '~/types/dto/common/reportPossibleFullNameDTO';

@injectable()
export class PossibleNamesSiftingService implements IPossibleNamesSiftingService {
  getSiftedData(initData: Array<ReportPossibleFullNameDTO>, fullName: string) {
    return initData.filter((item) => item.FullName !== fullName);
  }
}
