import { injectable } from 'inversify';
import { IndividualUserList } from '~/types/individual/userList';
import { IPlaceholderDataService } from '../common/reportPlaceholderService.interfaces';

@injectable()
export class IndividualUserListPlaceholderService implements IPlaceholderDataService<IndividualUserList> {
  getData(): IndividualUserList {
    return {
      ListName: '',
      ListType: '',
    };
  }
}
