import { IContainerBinder } from '~/configuration/inversify/containerAccessor.interface';
import { IIndividualFinancialScoringMergeServiceKey } from '../../services/individualFinancialScoringMergeService.interfaces';
import { IndividualFinancialScoringMergeService } from '../../services/individualFinancialScoringMergeService';
import { IndividualFinancialScoringBasedDataConfigService } from '~/modules/individual/scoringScores/individualFinancialScoring/services/individualFinancialScoringBasedDataConfigService';
import { IIndividualFinancialScoringBasedDataConfigServiceKey } from '~/modules/individual/scoringScores/individualFinancialScoring/services/individualFinancialScoringConfigService.interfaces';

export function bindIndividualFinancialScoringServices(bind: IContainerBinder) {
  bind(IIndividualFinancialScoringMergeServiceKey).to(IndividualFinancialScoringMergeService);

  bind(IIndividualFinancialScoringBasedDataConfigServiceKey).to(IndividualFinancialScoringBasedDataConfigService);
}
