import { injectable } from 'inversify';
import { IIndividualHolidayAdapterService } from '~/services/adapters/individual/individualAdapterService.interfaces';
import { IndividualHoliday } from '~/types/individual/holiday';
import { IndividualHolidayDTO } from '~/types/dto/individual/holidayDTO';
import { StringUtils } from '~/utils/stringUtils';

@injectable()
export class IndividualHolidayAdapterService implements IIndividualHolidayAdapterService {
  getData(initData: IndividualHoliday[]): IndividualHolidayDTO[] {
    return (initData ?? []).map((item) => ({
      Type: StringUtils.quoteReplacement(item.Type),
      StartDate: item.StartDate ?? '',
      EndDate: item.EndDate ?? '',
    }));
  }
}
