import { injectable, inject } from 'inversify';
import { IndividualTrustScoring } from '~/types/individual/trustScoring';
import { IPrimitiveTypesNormalizationServiceKey, IPrimitiveTypesNormalizationService } from '../common/primitiveTypesNormalizationService.interfaces';
import { INormalizationService } from '../common/reportNormalizationService.interfaces';

@injectable()
export class IndividualTrustScoringNormalizationService implements INormalizationService<IndividualTrustScoring> {
  constructor(
    @inject(IPrimitiveTypesNormalizationServiceKey as symbol) private primitiveTypesNormalizationService: IPrimitiveTypesNormalizationService,
  ) {}

  normalize(data: any, root: string): IndividualTrustScoring | undefined {
    const { normalizeOptionalString, normalizeObject } = this.primitiveTypesNormalizationService;

    return normalizeObject(data, (data) => ({
      CheckedFio: normalizeOptionalString(data.CheckedFio, `${root}.CheckedFio`),
      CheckedBirthday: normalizeOptionalString(data.CheckedBirthday, `${root}.CheckedBirthday`),
      IncidentColorOne: normalizeOptionalString(data.IncidentColorOne, `${root}.IncidentColorOne`),
      IncidentTypeOne: normalizeOptionalString(data.IncidentTypeOne, `${root}.IncidentTypeOne`),
      IncidentColorTwo: normalizeOptionalString(data.IncidentColorTwo, `${root}.IncidentColorTwo`),
      IncidentTypeTwo: normalizeOptionalString(data.IncidentTypeTwo, `${root}.IncidentTypeTwo`),
      IncidentColorThree: normalizeOptionalString(data.IncidentColorThree, `${root}.IncidentColorThree`),
      IncidentTypeThree: normalizeOptionalString(data.IncidentTypeThree, `${root}.IncidentTypeThree`),
      IncidentColorFour: normalizeOptionalString(data.IncidentColorFour, `${root}.IncidentColorFour`),
      IncidentTypeFour: normalizeOptionalString(data.IncidentTypeFour, `${root}.IncidentTypeFour`),
      IncidentColorFive: normalizeOptionalString(data.IncidentColorFive, `${root}.IncidentColorFive`),
      IncidentTypeFive: normalizeOptionalString(data.IncidentTypeFive, `${root}.IncidentTypeFive`),
      IncidentColorSix: normalizeOptionalString(data.IncidentColorSix, `${root}.IncidentColorSix`),
      IncidentTypeSix: normalizeOptionalString(data.IncidentTypeSix, `${root}.IncidentTypeSix`),
    }), root);
  }
}
