import { injectable, inject } from 'inversify';
import { PhoneNumbersDataBlobData } from '~/types/blobs/phone-numbers-data-blob-data';
import { PhoneNumberPhoneCommon } from '~/types/phone-number/phoneCommon';
import { IPhoneNumberPhoneCommonPlaceholderServiceKey } from './phoneNumberPlaceholderService.interface';
import { IPlaceholderDataService } from '~/services/placeholder/common/reportPlaceholderService.interfaces';

@injectable()
export class PhoneNumbersDataBlobDataPlaceholderService implements IPlaceholderDataService<PhoneNumbersDataBlobData> {
  constructor(
    @inject(IPhoneNumberPhoneCommonPlaceholderServiceKey as symbol) private phoneNumberPhoneCommonPlaceholderService: IPlaceholderDataService<PhoneNumberPhoneCommon>,
  ) {}

  getData(): PhoneNumbersDataBlobData {
    return {
      Common: this.phoneNumberPhoneCommonPlaceholderService.getData(),
      Messengers: [],
      Mentions: [],
      RelatedPersons: [],
      Passports: [],
    };
  }
}
