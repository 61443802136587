import { injectable } from 'inversify';
import { DateUtils } from '~/utils/date';
import { Config } from '~/types/field-list/config';
import {
  ILegalEntitiesAuthorizedCapitalConfigService
} from '~/modules/legalEntities/changeHistory/legalEntitiesAuthorizedCapital/services/legalEntitiesAuthorizedCapitalConfigService.interfaces';
import {
  ILegalEntitiesAuthorizedCapitalWithIndex
} from '~/modules/legalEntities/changeHistory/legalEntitiesAuthorizedCapital/types/legalEntitiesAuthorizedCapitalWithIndex';
import { Field } from '~/types/field-list/field';
import { NumberUtils } from '~/utils/number';

@injectable()
export class LegalEntitiesAuthorizedCapitalConfigService implements ILegalEntitiesAuthorizedCapitalConfigService {
  getConfig(initData: ILegalEntitiesAuthorizedCapitalWithIndex): Config {
    const fields: Field[] = [];

    const to = initData.legalEntitiesCompanyStatedCapital.To;
    const from = initData.legalEntitiesCompanyStatedCapital.From;
    const sum = initData.legalEntitiesCompanyStatedCapital.Sum;
    const { lastIndex } = initData;
    const { index } = initData;

    const oneDateText = lastIndex === index ? 'Дата внесения сведений' : 'Дата внесения изменений';

    if (from !== to && from && to) {
      fields.push(
        {
          key: 'startDate',
          label: 'Дата первого внесения сведений',
          value: DateUtils.formatDate(from),
        },
        {
          key: 'endDate',
          label: 'Дата последнего внесения изменений',
          value: DateUtils.formatDate(to),
        }
      );
    } else {
      fields.push({
        key: 'startDate',
        label: oneDateText,
        value: DateUtils.formatDate(from || to),
      });
    }

    return {
      fieldsDirection: 'divider-row',
      fieldsWidth: '100%',
      fieldsValueWidth: '350px',
      isBeginningDivider: true,
      fields: [
        {
          key: 'sum',
          label: 'Сумма, \u{20BD}',
          value: NumberUtils.formatNumberDigits(sum)
        },
        ...fields
      ]
    };
  }
}
