import {
  ILegalEntitiesDataBlobDataPlaceholderServiceKey,
  ILegalEntitiesCommonPlaceholderDataServiceKey,
  ILegalEntitiesBriefInfoPlaceholderDataServiceKey,
  ILegalEntitiesTaxServicePlaceholderServiceKey,
  ILegalEntitiesCompanyActualAddressPlaceholderServiceKey,
  ILegalEntitiesConditionAssessmentPlaceholderDataServiceKey,
  ILegalEntitiesTaxModePlaceholderDataServiceKey,
  ILegalEntitiesRegistrarOfShareholdersPlaceholderDataServiceKey,
  ILegalEntitiesLesseeSummaryPlaceholderDataServiceKey,
  ILegalEntitiesActivitySummaryPlaceholderDataServiceKey,
  ILegalEntitiesCompanySitePlaceholderDataServiceKey,
  ILegalEntitiesCompanyPhonePlaceholderDataServiceKey,
  ILegalEntitiesCompanyEmailPlaceholderDataServiceKey,
  ILegalEntitiesSocialPlaceholderDataServiceKey,
  ILegalEntitiesCompanyStatedCapitalPlaceholderDataServiceKey,
  ILegalEntitiesCompanyHistoryAddressPlaceholderDataServiceKey,
  ILegalEntitiesCompanyHeadFlPlaceholderDataServiceKey,
  ILegalEntitiesCompanyManagementCompaniesPlaceholderDataServiceKey,
  ILegalEntitiesCompanyFounderSharePlaceholderDataServiceKey,
  ILegalEntitiesCompanyFounderPlaceholderDataServiceKey,
  ILegalEntitiesCourtCasesByYearPlaceholderDataServiceKey,
  ILegalEntitiesGovernmentControlPlaceholderDataServiceKey,
  ILegalEntitiesEnforcementProceedingsInfoBySubjectPlaceholderDataServiceKey,
  ILegalEntitiesRelatedCompanyPlaceholderDataServiceKey,
  ILegalEntitiesRelatedEgrulPlaceholderDataServiceKey,
  ILegalEntitiesPersonPlaceholderDataServiceKey,
  ILegalEntitiesRelatedReasonPlaceholderDataServiceKey,
  ILegalEntitiesConflictSignPlaceholderDataServiceKey,
  ILegalEntitiesTaxPlaceholderDataServiceKey,
  ILegalEntitiesFineAndTaxDebtPlaceholderDataServiceKey,
  ILegalEntitiesContractsInfoByYearPlaceholderDataServiceKey,
  ILegalEntitiesSubCompanyInfoPlaceholderDataServiceKey,
  ILegalEntitiesPredecessorPlaceholderDataServiceKey,
  ILegalEntitiesSuccessorPlaceholderDataServiceKey,
  ILegalEntitiesReorganizationMemberPlaceholderDataServiceKey,
  ILegalEntitiesShareholderPlaceholderDataServiceKey,
  ILegalEntitiesEgrRecordPlaceholderDataServiceKey,
  ILegalEntitiesCertificateSummaryPlaceholderDataServiceKey,
  ILegalEntitiesLicenseSummaryPlaceholderDataServiceKey,
  ILegalEntitiesSelfRegulatoryOrganizationPlaceholderDataServiceKey,
  ILegalEntitiesTrademarkPlaceholderDataServiceKey,
  ILegalEntitiesCompanyNamePlaceholderDataServiceKey,
  ILegalEntitiesInsuranceFundInfoPlaceholderDataServiceKey,
  ILegalEntitiesReportSocialNetworkIdPlaceholderDataServiceKey,
  ILegalEntitiesCompanyEmployeeInfoPlaceholderDataServiceKey,
  ILegalEntitiesFinancialStatePlaceholderDataServiceKey,
  ILegalEntitiesReportDataBuhFormDetailPlaceholderDataServiceKey,
  ILegalEntitiesReportDataBuhFormPlaceholderDataServiceKey,
  ILegalEntitiesFinancialCommonPlaceholderDataServiceKey,
  ILegalEntitiesMarkersPlaceholderDataServiceKey,
  ILegalEntitiesFinancialAnalysisShortPlaceholderDataServiceKey,
  ILegalEntitiesReportFinancialIndicatorWithDynamicPlaceholderDataServiceKey,
  ILegalEntitiesReportFinancialIndicatorWithConclusionsPlaceholderDataServiceKey,
  ILegalEntitiesReportFinancialIndicatorBasePlaceholderDataServiceKey,
  ILegalEntitiesFinancialAnalysisExtendedPlaceholderDataServiceKey,
  ILegalEntitiesReportLiquidityAndSolvencyPlaceholderDataServiceKey,
  ILegalEntitiesReportFinancialStabilityPlaceholderDataServiceKey,
  ILegalEntitiesReportAnalysisOfAbsoluteLiquidityIndicatorPlaceholderDataServiceKey,
  ILegalEntitiesReportAverageIndustryIndicatorPlaceholderDataServiceKey,
  ILegalEntitiesTotalScorePlaceholderDataServiceKey,
  ILegalEntitiesAverageHeadcountPlaceholderDataServiceKey, ILegalEntitiesAnalyticsIndicatorsPlaceholderDataServiceKey,
} from '~/services/placeholder/legalEntities/legalEntitiesPlaceholderDataService.interfaces';
import { LegalEntitiesActivitySummaryPlaceholderDataService } from '~/services/placeholder/legalEntities/legalEntitiesActivitySummaryPlaceholderDataService';
import { LegalEntitiesBriefInfoPlaceholderService } from '~/services/placeholder/legalEntities/legalEntitiesBriefInfoPlaceholderService';
import { LegalEntitiesCertificateSummaryPlaceholderDataService } from '~/services/placeholder/legalEntities/legalEntitiesCertificateSummaryPlaceholderDataService';
import { LegalEntitiesCommonPlaceholderDataService } from '~/services/placeholder/legalEntities/legalEntitiesCommonPlaceholderDataService';
import { LegalEntitiesCompanyActualAddressPlaceholderService } from '~/services/placeholder/legalEntities/legalEntitiesCompanyActualAddressPlaceholderService';
import { LegalEntitiesCompanyEmailPlaceholderService } from '~/services/placeholder/legalEntities/legalEntitiesCompanyEmailPlaceholderDataService';
import { LegalEntitiesCompanyEmployeeInfoPlaceholderDataService } from '~/services/placeholder/legalEntities/legalEntitiesCompanyEmployeeInfoPlaceholderDataService';
import { LegalEntitiesCompanyFounderPlaceholderDataService } from '~/services/placeholder/legalEntities/legalEntitiesCompanyFounderPlaceholderDataService';
import { LegalEntitiesCompanyFounderSharePlaceholderDataService } from '~/services/placeholder/legalEntities/legalEntitiesCompanyFounderSharePlaceholderDataService';
import { LegalEntitiesCompanyHeadFlPlaceholderDataService } from '~/services/placeholder/legalEntities/legalEntitiesCompanyHeadFlPlaceholderDataService';
import { LegalEntitiesCompanyHistoryAddressPlaceholderDataService } from '~/services/placeholder/legalEntities/legalEntitiesCompanyHistoryAddressPlaceholderDataService';
import { LegalEntitiesCompanyManagementCompaniesPlaceholderDataService } from '~/services/placeholder/legalEntities/legalEntitiesCompanyManagementCompaniesPlaceholderDataService';
import { LegalEntitiesCompanyNamePlaceholderDataService } from '~/services/placeholder/legalEntities/legalEntitiesCompanyNamePlaceholderDataService';
import { LegalEntitiesCompanyPhonePlaceholderDataService } from '~/services/placeholder/legalEntities/legalEntitiesCompanyPhonePlaceholderDataService';
import { LegalEntitiesCompanySitePlaceholderService } from '~/services/placeholder/legalEntities/legalEntitiesCompanySitePlaceholderService';
import { LegalEntitiesCompanyStatedCapitalPlaceholderDataService } from '~/services/placeholder/legalEntities/legalEntitiesCompanyStatedCapitalPlaceholderDataService';
import { LegalEntitiesConditionAssessmentPlaceholderService } from '~/services/placeholder/legalEntities/legalEntitiesConditionAssessmentPlaceholderService';
import { LegalEntitiesConflictSignPlaceholderDataService } from '~/services/placeholder/legalEntities/legalEntitiesConflictSignPlaceholderDataService';
import { LegalEntitiesContractsInfoByYearPlaceholderDataService } from '~/services/placeholder/legalEntities/legalEntitiesContractsInfoByYearPlaceholderDataService';
import { LegalEntitiesCourtCasesByYearPlaceholderDataService } from '~/services/placeholder/legalEntities/legalEntitiesCourtCasesByYearPlaceholderDataService';
import { LegalEntitiesEgrRecordPlaceholderDataService } from '~/services/placeholder/legalEntities/legalEntitiesEgrRecordPlaceholderDataService';
import { LegalEntitiesEnforcementProceedingsInfoBySubjectPlaceholderDataService } from '~/services/placeholder/legalEntities/legalEntitiesEnforcementProceedingsInfoBySubjectPlaceholderDataService';
import { LegalEntitiesFinancialStatePlaceholderDataService } from '~/services/placeholder/legalEntities/legalEntitiesFinancialStatePlaceholderDataService';
import { LegalEntitiesFineAndTaxDebtPlaceholderDataService } from '~/services/placeholder/legalEntities/legalEntitiesFineAndTaxDebtPlaceholderDataService';
import { LegalEntitiesGovernmentControlPlaceholderDataService } from '~/services/placeholder/legalEntities/legalEntitiesGovernmentControlPlaceholderDataService';
import { LegalEntitiesInsuranceFundInfoPlaceholderDataService } from '~/services/placeholder/legalEntities/legalEntitiesInsuranceFundInfoPlaceholderDataService';
import { LegalEntitiesLesseeSummaryPlaceholderDataService } from '~/services/placeholder/legalEntities/legalEntitiesLesseeSummaryPlaceholderDataService';
import { LegalEntitiesLicenseSummaryPlaceholderDataService } from '~/services/placeholder/legalEntities/legalEntitiesLicenseSummaryPlaceholderDataService';
import { LegalEntitiesPersonPlaceholderDataService } from '~/services/placeholder/legalEntities/legalEntitiesPersonPlaceholderDataService';
import { LegalEntitiesDataBlobDataPlaceholderService } from '~/services/placeholder/legalEntities/legalEntitiesDataBlobDataPlaceholderService';
import { LegalEntitiesPredecessorPlaceholderDataService } from '~/services/placeholder/legalEntities/legalEntitiesPredecessorPlaceholderDataService';
import { LegalEntitiesRegistrarOfShareholdersPlaceholderService } from '~/services/placeholder/legalEntities/legalEntitiesRegistrarOfShareholdersPlaceholderDataService';
import { LegalEntitiesRelatedCompanyPlaceholderDataService } from '~/services/placeholder/legalEntities/legalEntitiesRelatedCompanyPlaceholderDataService';
import { LegalEntitiesRelatedEgrulPlaceholderDataService } from '~/services/placeholder/legalEntities/legalEntitiesRelatedEgrulPlaceholderDataService';
import { LegalEntitiesRelatedReasonPlaceholderDataService } from '~/services/placeholder/legalEntities/legalEntitiesRelatedReasonPlaceholderDataService';
import { LegalEntitiesReorganizationMemberPlaceholderDataService } from '~/services/placeholder/legalEntities/legalEntitiesReorganizationMemberPlaceholderDataService';
import { LegalEntitiesReportDataBuhFormDetailPlaceholderDataService } from '~/services/placeholder/legalEntities/legalEntitiesReportDataBuhFormDetailPlaceholderDataService';
import { LegalEntitiesReportDataBuhFormPlaceholderDataService } from '~/services/placeholder/legalEntities/legalEntitiesReportDataBuhFormPlaceholderDataService';
import { LegalEntitiesReportSocialNetworkIdPlaceholderDataService } from '~/services/placeholder/legalEntities/legalEntitiesReportSocialNetworkIdPlaceholderDataService';
import { LegalEntitiesSelfRegulatoryOrganizationPlaceholderDataService } from '~/services/placeholder/legalEntities/legalEntitiesSelfRegulatoryOrganizationPlaceholderDataService';
import { LegalEntitiesShareholderPlaceholderDataService } from '~/services/placeholder/legalEntities/legalEntitiesShareholderPlaceholderDataService';
import { LegalEntitiesSocialPlaceholderDataService } from '~/services/placeholder/legalEntities/legalEntitiesSocialPlaceholderDataService';
import { LegalEntitiesSubCompanyInfoPlaceholderDataService } from '~/services/placeholder/legalEntities/legalEntitiesSubCompanyInfoPlaceholderDataService';
import { LegalEntitiesSuccessorPlaceholderDataService } from '~/services/placeholder/legalEntities/legalEntitiesSuccessorPlaceholderDataService';
import { LegalEntitiesTaxModePlaceholderService } from '~/services/placeholder/legalEntities/legalEntitiesTaxModePlaceholderService';
import { LegalEntitiesTaxPlaceholderDataService } from '~/services/placeholder/legalEntities/legalEntitiesTaxPlaceholderDataService';
import { LegalEntitiesTaxServicePlaceholderService } from '~/services/placeholder/legalEntities/legalEntitiesTaxServicePlaceholderService';
import { LegalEntitiesTrademarkPlaceholderDataService } from '~/services/placeholder/legalEntities/legalEntitiesTrademarkPlaceholderDataService';
import { LegalEntitiesFinancialCommonPlaceholderDataService } from '~/services/placeholder/legalEntities/legalEntitiesFinancialCommonPlaceholderDataService';
import { LegalEntitiesMarkersPlaceholderDataService } from '~/services/placeholder/legalEntities/legalEntitiesMarkersPlaceholderDataService';
import { LegalEntitiesFinancialAnalysisShortPlaceholderDataService } from '~/services/placeholder/legalEntities/legalEntitiesFinancialAnalysisShortPlaceholderDataService';
import { LegalEntitiesReportFinancialIndicatorWithDynamicPlaceholderDataService } from '~/services/placeholder/legalEntities/legalEntitiesReportFinancialIndicatorWithDynamicPlaceholderDataService';
import { LegalEntitiesReportFinancialIndicatorWithConclusionsPlaceholderDataService } from '~/services/placeholder/legalEntities/legalEntitiesReportFinancialIndicatorWithConclusionsPlaceholderDataService';
import { LegalEntitiesReportFinancialIndicatorBasePlaceholderDataService } from '~/services/placeholder/legalEntities/legalEntitiesReportFinancialIndicatorBasePlaceholderDataService';
import { LegalEntitiesFinancialAnalysisExtendedPlaceholderDataService } from '~/services/placeholder/legalEntities/legalEntitiesFinancialAnalysisExtendedPlaceholderDataService';
import { LegalEntitiesReportLiquidityAndSolvencyPlaceholderDataService } from '~/services/placeholder/legalEntities/legalEntitiesReportLiquidityAndSolvencyPlaceholderDataService';
import { LegalEntitiesReportFinancialStabilityPlaceholderDataService } from '~/services/placeholder/legalEntities/legalEntitiesReportFinancialStabilityPlaceholderDataService';
import { LegalEntitiesReportAnalysisOfAbsoluteLiquidityIndicatorPlaceholderDataService } from '~/services/placeholder/legalEntities/legalEntitiesReportAnalysisOfAbsoluteLiquidityIndicatorPlaceholderDataService';
import { LegalEntitiesReportAverageIndustryIndicatorPlaceholderDataService } from '~/services/placeholder/legalEntities/legalEntitiesReportAverageIndustryIndicatorPlaceholderDataService';
import { LegalEntitiesTotalScorePlaceholderDataService } from '~/services/placeholder/legalEntities/legalEntitiesTotalScorePlaceholderDataService';
import { IContainerBinder } from './containerAccessor.interface';
import {
  LegalEntitiesAverageHeadcountPlaceholderService
} from '~/services/placeholder/legalEntities/legalEntitiesAverageHeadcountPlaceholderService';
import {
  LegalEntitiesAnalyticsIndicatorsPlaceholderDataService
} from '~/services/placeholder/legalEntities/legalEntitiesAnalyticsIndicatorsPlaceholderDataService';

export function bindLegalEntitiesPlaceholderServices(bind: IContainerBinder) {
  bind(ILegalEntitiesDataBlobDataPlaceholderServiceKey).to(LegalEntitiesDataBlobDataPlaceholderService);
  bind(ILegalEntitiesCommonPlaceholderDataServiceKey).to(LegalEntitiesCommonPlaceholderDataService);
  bind(ILegalEntitiesBriefInfoPlaceholderDataServiceKey).to(LegalEntitiesBriefInfoPlaceholderService);
  bind(ILegalEntitiesTaxServicePlaceholderServiceKey).to(LegalEntitiesTaxServicePlaceholderService);
  bind(ILegalEntitiesCompanyActualAddressPlaceholderServiceKey).to(LegalEntitiesCompanyActualAddressPlaceholderService);
  bind(ILegalEntitiesConditionAssessmentPlaceholderDataServiceKey).to(LegalEntitiesConditionAssessmentPlaceholderService);
  bind(ILegalEntitiesTaxModePlaceholderDataServiceKey).to(LegalEntitiesTaxModePlaceholderService);
  bind(ILegalEntitiesRegistrarOfShareholdersPlaceholderDataServiceKey).to(LegalEntitiesRegistrarOfShareholdersPlaceholderService);
  bind(ILegalEntitiesLesseeSummaryPlaceholderDataServiceKey).to(LegalEntitiesLesseeSummaryPlaceholderDataService);
  bind(ILegalEntitiesActivitySummaryPlaceholderDataServiceKey).to(LegalEntitiesActivitySummaryPlaceholderDataService);
  bind(ILegalEntitiesCompanySitePlaceholderDataServiceKey).to(LegalEntitiesCompanySitePlaceholderService);
  bind(ILegalEntitiesCompanyPhonePlaceholderDataServiceKey).to(LegalEntitiesCompanyPhonePlaceholderDataService);
  bind(ILegalEntitiesCompanyEmailPlaceholderDataServiceKey).to(LegalEntitiesCompanyEmailPlaceholderService);
  bind(ILegalEntitiesSocialPlaceholderDataServiceKey).to(LegalEntitiesSocialPlaceholderDataService);
  bind(ILegalEntitiesCompanyStatedCapitalPlaceholderDataServiceKey).to(LegalEntitiesCompanyStatedCapitalPlaceholderDataService);
  bind(ILegalEntitiesCompanyHistoryAddressPlaceholderDataServiceKey).to(LegalEntitiesCompanyHistoryAddressPlaceholderDataService);
  bind(ILegalEntitiesCompanyHeadFlPlaceholderDataServiceKey).to(LegalEntitiesCompanyHeadFlPlaceholderDataService);
  bind(ILegalEntitiesCompanyManagementCompaniesPlaceholderDataServiceKey).to(LegalEntitiesCompanyManagementCompaniesPlaceholderDataService);
  bind(ILegalEntitiesCompanyFounderSharePlaceholderDataServiceKey).to(LegalEntitiesCompanyFounderSharePlaceholderDataService);
  bind(ILegalEntitiesCompanyFounderPlaceholderDataServiceKey).to(LegalEntitiesCompanyFounderPlaceholderDataService);
  bind(ILegalEntitiesCourtCasesByYearPlaceholderDataServiceKey).to(LegalEntitiesCourtCasesByYearPlaceholderDataService);
  bind(ILegalEntitiesGovernmentControlPlaceholderDataServiceKey).to(LegalEntitiesGovernmentControlPlaceholderDataService);
  bind(ILegalEntitiesEnforcementProceedingsInfoBySubjectPlaceholderDataServiceKey).to(LegalEntitiesEnforcementProceedingsInfoBySubjectPlaceholderDataService);
  bind(ILegalEntitiesRelatedCompanyPlaceholderDataServiceKey).to(LegalEntitiesRelatedCompanyPlaceholderDataService);
  bind(ILegalEntitiesRelatedEgrulPlaceholderDataServiceKey).to(LegalEntitiesRelatedEgrulPlaceholderDataService);
  bind(ILegalEntitiesPersonPlaceholderDataServiceKey).to(LegalEntitiesPersonPlaceholderDataService);
  bind(ILegalEntitiesRelatedReasonPlaceholderDataServiceKey).to(LegalEntitiesRelatedReasonPlaceholderDataService);
  bind(ILegalEntitiesConflictSignPlaceholderDataServiceKey).to(LegalEntitiesConflictSignPlaceholderDataService);
  bind(ILegalEntitiesTaxPlaceholderDataServiceKey).to(LegalEntitiesTaxPlaceholderDataService);
  bind(ILegalEntitiesFineAndTaxDebtPlaceholderDataServiceKey).to(LegalEntitiesFineAndTaxDebtPlaceholderDataService);
  bind(ILegalEntitiesContractsInfoByYearPlaceholderDataServiceKey).to(LegalEntitiesContractsInfoByYearPlaceholderDataService);
  bind(ILegalEntitiesSubCompanyInfoPlaceholderDataServiceKey).to(LegalEntitiesSubCompanyInfoPlaceholderDataService);
  bind(ILegalEntitiesPredecessorPlaceholderDataServiceKey).to(LegalEntitiesPredecessorPlaceholderDataService);
  bind(ILegalEntitiesSuccessorPlaceholderDataServiceKey).to(LegalEntitiesSuccessorPlaceholderDataService);
  bind(ILegalEntitiesReorganizationMemberPlaceholderDataServiceKey).to(LegalEntitiesReorganizationMemberPlaceholderDataService);
  bind(ILegalEntitiesShareholderPlaceholderDataServiceKey).to(LegalEntitiesShareholderPlaceholderDataService);
  bind(ILegalEntitiesEgrRecordPlaceholderDataServiceKey).to(LegalEntitiesEgrRecordPlaceholderDataService);
  bind(ILegalEntitiesCertificateSummaryPlaceholderDataServiceKey).to(LegalEntitiesCertificateSummaryPlaceholderDataService);
  bind(ILegalEntitiesLicenseSummaryPlaceholderDataServiceKey).to(LegalEntitiesLicenseSummaryPlaceholderDataService);
  bind(ILegalEntitiesSelfRegulatoryOrganizationPlaceholderDataServiceKey).to(LegalEntitiesSelfRegulatoryOrganizationPlaceholderDataService);
  bind(ILegalEntitiesTrademarkPlaceholderDataServiceKey).to(LegalEntitiesTrademarkPlaceholderDataService);
  bind(ILegalEntitiesCompanyNamePlaceholderDataServiceKey).to(LegalEntitiesCompanyNamePlaceholderDataService);
  bind(ILegalEntitiesInsuranceFundInfoPlaceholderDataServiceKey).to(LegalEntitiesInsuranceFundInfoPlaceholderDataService);
  bind(ILegalEntitiesReportSocialNetworkIdPlaceholderDataServiceKey).to(LegalEntitiesReportSocialNetworkIdPlaceholderDataService);
  bind(ILegalEntitiesCompanyEmployeeInfoPlaceholderDataServiceKey).to(LegalEntitiesCompanyEmployeeInfoPlaceholderDataService);
  bind(ILegalEntitiesFinancialStatePlaceholderDataServiceKey).to(LegalEntitiesFinancialStatePlaceholderDataService);
  bind(ILegalEntitiesReportDataBuhFormDetailPlaceholderDataServiceKey).to(LegalEntitiesReportDataBuhFormDetailPlaceholderDataService);
  bind(ILegalEntitiesReportDataBuhFormPlaceholderDataServiceKey).to(LegalEntitiesReportDataBuhFormPlaceholderDataService);
  bind(ILegalEntitiesFinancialCommonPlaceholderDataServiceKey).to(LegalEntitiesFinancialCommonPlaceholderDataService);
  bind(ILegalEntitiesMarkersPlaceholderDataServiceKey).to(LegalEntitiesMarkersPlaceholderDataService);
  bind(ILegalEntitiesFinancialAnalysisShortPlaceholderDataServiceKey).to(LegalEntitiesFinancialAnalysisShortPlaceholderDataService);
  bind(ILegalEntitiesReportFinancialIndicatorWithDynamicPlaceholderDataServiceKey).to(LegalEntitiesReportFinancialIndicatorWithDynamicPlaceholderDataService);
  bind(ILegalEntitiesReportFinancialIndicatorWithConclusionsPlaceholderDataServiceKey).to(LegalEntitiesReportFinancialIndicatorWithConclusionsPlaceholderDataService);
  bind(ILegalEntitiesReportFinancialIndicatorBasePlaceholderDataServiceKey).to(LegalEntitiesReportFinancialIndicatorBasePlaceholderDataService);
  bind(ILegalEntitiesFinancialAnalysisExtendedPlaceholderDataServiceKey).to(LegalEntitiesFinancialAnalysisExtendedPlaceholderDataService);
  bind(ILegalEntitiesReportLiquidityAndSolvencyPlaceholderDataServiceKey).to(LegalEntitiesReportLiquidityAndSolvencyPlaceholderDataService);
  bind(ILegalEntitiesReportFinancialStabilityPlaceholderDataServiceKey).to(LegalEntitiesReportFinancialStabilityPlaceholderDataService);
  bind(ILegalEntitiesReportAnalysisOfAbsoluteLiquidityIndicatorPlaceholderDataServiceKey).to(LegalEntitiesReportAnalysisOfAbsoluteLiquidityIndicatorPlaceholderDataService);
  bind(ILegalEntitiesReportAverageIndustryIndicatorPlaceholderDataServiceKey).to(LegalEntitiesReportAverageIndustryIndicatorPlaceholderDataService);
  bind(ILegalEntitiesTotalScorePlaceholderDataServiceKey).to(LegalEntitiesTotalScorePlaceholderDataService);
  bind(ILegalEntitiesAverageHeadcountPlaceholderDataServiceKey).to(LegalEntitiesAverageHeadcountPlaceholderService);
  bind(ILegalEntitiesAnalyticsIndicatorsPlaceholderDataServiceKey).to(LegalEntitiesAnalyticsIndicatorsPlaceholderDataService);
}
