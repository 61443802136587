import { injectable } from 'inversify';
import { Config } from '~/types/field-list/config';
import { IIndividualEntrepreneursConfigService } from './individualEntrepreneursConfigService.interface';
import { ReportIndividualEntrepreneurDTO } from '~/types/dto/common/reportIndividualEntrepreneurDTO';

@injectable()
export class IndividualEntrepreneursConfigService implements IIndividualEntrepreneursConfigService {
  getConfig(item: ReportIndividualEntrepreneurDTO): Config {
    return {
      fieldsDirection: 'divider-row',
      fieldsWidth: '100%',
      fieldsValueWidth: '350px',
      isBeginningDivider: true,
      fields: [
        {
          label: 'Индивидуальный предприниматель',
          key: 'Name',
          value: {
            text: item.Name,
            width: '100%'
          },
          direction: 'column',
        },
        {
          label: 'ОГРНИП',
          key: 'Ogrn', 
          value: item.Ogrn 
        },
        {
          label: 'ИНН',
          key: 'Inn',
          value: item.Inn 
        },
        {
          label: 'Дата регистрации',
          key: 'RegistrationDate',
          value: item.RegistrationDate 
        },
        {
          label: 'Дата прекращения деятельности',
          key: 'LiquidationDate',
          value: item.LiquidationDate 
        },
        {
          label: 'Статус',
          key: 'Status',
          value: item.Status 
        },
        {
          label: {
            text: 'Основной вид деятельности ОКВЭД',
            width: '100%'
          },
          key: 'PrincipalActivity',
          value: {
            text: item.PrincipalActivity,
            width: '100%'
          },
          direction: 'column',
        },
      ]
    };
  }
}
