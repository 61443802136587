import { injectable, inject } from 'inversify';
import { IndividualCriminalPenalties } from '~/types/individual/criminalPenalties';
import { IPrimitiveTypesNormalizationServiceKey, IPrimitiveTypesNormalizationService } from '../common/primitiveTypesNormalizationService.interfaces';
import { INormalizationService } from '../common/reportNormalizationService.interfaces';

@injectable()
export class IndividualCriminalPenaltiesNormalizationService implements INormalizationService<IndividualCriminalPenalties> {
  constructor(
    @inject(IPrimitiveTypesNormalizationServiceKey as symbol) private primitiveTypesNormalizationService: IPrimitiveTypesNormalizationService,
  ) {}

  normalize(data: any, root: string): IndividualCriminalPenalties | undefined {
    const { normalizeObject, normalizeString, normalizeOptionalString } = this.primitiveTypesNormalizationService;

    return normalizeObject(data, (data) => ({
      Article: normalizeOptionalString(data.Article, `${root}.Article`),
      Part: normalizeOptionalString(data.Part, `${root}.Part`),
      Point: normalizeOptionalString(data.Point, `${root}.Point`),
      ArticleContent: normalizeOptionalString(data.ArticleContent, `${root}.ArticleContent`),
      CaseInitiationDate: normalizeOptionalString(data.CaseInitiationDate, `${root}.CaseInitiationDate`),
      ConvictionDate: normalizeOptionalString(data.ConvictionDate, `${root}.ConvictionDate`),
      CaseNumber: normalizeOptionalString(data.CaseNumber, `${root}.CaseNumber`),
      Comment: normalizeString(data.Comment, `${root}.Comment`),
    }), root);
  }
}
