import { injectable } from 'inversify';
import { IndividualMovementBus } from '~/types/individual/movementBus';
import { IPlaceholderDataService } from '../common/reportPlaceholderService.interfaces';

@injectable()
export class IndividualMovementBusPlaceholderService implements IPlaceholderDataService<IndividualMovementBus> {
  getData(): IndividualMovementBus {
    return {
      MovementFrom: '',
      MovementTo: '',
      MovementDate: '',
      ArrivalDate: '',
      SeatNumber: '',
    };
  }
}
