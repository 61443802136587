import { injectable } from 'inversify';
import { isEqual } from 'lodash-es';
import { MergeService } from '~/services/merge/merge-service';
import { LegalEntitiesCompanyFounderDTO } from '~/types/dto/legalEntities/companyFounder';

@injectable()
export class LegalEntitiesCompanyFoundersMergeService extends MergeService<LegalEntitiesCompanyFounderDTO> {
  protected canMerge(leftItem: LegalEntitiesCompanyFounderDTO, rightItem: LegalEntitiesCompanyFounderDTO): boolean {
    return isEqual(leftItem, rightItem);
  }

  protected mergeFields(leftItem: LegalEntitiesCompanyFounderDTO, rightItem: LegalEntitiesCompanyFounderDTO): LegalEntitiesCompanyFounderDTO {
    return {
      Inn: leftItem.Inn || rightItem.Inn,
      Country: leftItem.Country || rightItem.Country,
      Fullname: leftItem.Fullname || rightItem.Fullname,
      FounderType: leftItem.FounderType || rightItem.FounderType,
      Share: leftItem.Share || rightItem.Share,
      Capital: leftItem.Capital || rightItem.Capital,
      Percent: leftItem.Percent || rightItem.Percent,
      Date: leftItem.Date || rightItem.Date,
      FirstDate: leftItem.FirstDate || rightItem.FirstDate,
      IsInaccuracy: leftItem.IsInaccuracy || rightItem.IsInaccuracy,
      InaccuracyDate: leftItem.InaccuracyDate || rightItem.InaccuracyDate
    };
  }
}
