import { injectable } from 'inversify';
import { IndividualFinancialScoring } from '~/types/individual/financialScoring';
import { IPlaceholderDataService } from '../common/reportPlaceholderService.interfaces';

@injectable()
export class IndividualFinancialScoringPlaceholderService implements IPlaceholderDataService<IndividualFinancialScoring> {
  getData(): IndividualFinancialScoring {
    return {
      Score: 0,
      Reason: '',
      CheckedFio: '',
      CheckedPhone: '',
      CheckedBirthday: '',
      CheckedPassportNumber: '',
      CheckedPassportIssueDate: '',
      FinancialScoringType: 100,
    };
  }
}
