import fs from 'fs';
import { injectable, inject } from 'inversify';
import { AxiosInstance } from 'axios';
import useEnvironment from '@/composables/useEnvironment';
import { mockFiles } from '~/configuration/mocks';
import { Environment } from '@/types/Environment';
import { nuxtEndpointKey } from '@/endpoints/useNuxtEndpoint';
import { GenericDataBlob } from '~/types/blobs/generic-data-blob';
import { IMocksService } from './mocks-service.interfaces';
import { UIReportType } from '~/types/reportType';
import { MockDataFile } from '~/types/mockDataFile';
import { appSettings } from '~/settings';

@injectable()
export class MocksService implements IMocksService {
  constructor(@inject(nuxtEndpointKey as symbol) private api: AxiosInstance) {}

  async loadMockDataAsync(name: string): Promise<GenericDataBlob> {
    const environment = useEnvironment();
    
    if (environment === Environment.server) {
      return new Promise<GenericDataBlob>((resolve, reject) => {
        fs.readFile(`${process.cwd()}/public/mocks/${name}`, (err, data) => {
          if (err) reject(err);

          try {
            const json = data.toString();
            resolve(JSON.parse(json));
          } catch (ex) {
            reject(ex);
          }
        });
      });
    }

    const { data: response } = await this.api.get<GenericDataBlob>(
      `/api/mocks?name=${name}`
    );
    return response;
  }

  getMockFiles(reportType: UIReportType): MockDataFile[] {
    return appSettings.shouldUseMockData ? mockFiles[reportType] : [];
  }

  async getMockDataByNameAsync(
    reportType: UIReportType,
    mockName?: string
  ): Promise<GenericDataBlob | undefined> {
    const reportFiles = mockFiles[reportType];

    if (reportFiles != undefined && reportFiles.length > 0) {
      let file = mockName != undefined
        ? reportFiles.find((file) => file.name == mockName)
        : undefined;

      if (file == undefined) [file] = reportFiles;

      const mockData = await this.loadMockDataAsync(file.name);
      return mockData;
    }

    return undefined;
  }
}
