import { injectable } from 'inversify';
import { IReportPhotosUrlDTOAdapterService } from '~/services/adapters/common/reportAdapterService.interfeces';
import { ReportPhotosUrl } from '~/types/common/reportVehiclePhotosUrl';
import { ReportPhotosUrlDTO } from '~/types/dto/common/ReportPhotosUrlDTO';

@injectable()
export class ReportPhotosUrlDTOAdapterService implements IReportPhotosUrlDTOAdapterService {
  getData(initData: ReportPhotosUrl[]): ReportPhotosUrlDTO[] {
    return (initData ?? []).map((photo) => ({
      Text: photo.Text,
      Value: photo.Value,
    }));
  }
}
