import { InjectionKey } from 'vue';
import { LegalEntitiesDataBlobData } from '~/types/blobs/legal-entities-data-blob-data';
import { LegalEntitiesActivitySummary } from '~/types/legal-entities/activitySummary';
import { LegalEntitiesBriefInfo } from '~/types/legal-entities/briefInfo';
import { LegalEntitiesCertificateSummary } from '~/types/legal-entities/certificateSummary';
import { LegalEntitiesCommonInfo } from '~/types/legal-entities/commonInfo';
import { LegalEntitiesCompanyActualAddress } from '~/types/legal-entities/companyActualAddress';
import { LegalEntitiesCompanyEmail } from '~/types/legal-entities/companyEmail';
import { LegalEntitiesCompanyEmployeeInfo } from '~/types/legal-entities/companyEmployeeInfo';
import { LegalEntitiesCompanyFounder } from '~/types/legal-entities/companyFounder';
import { LegalEntitiesCompanyFounderShare } from '~/types/legal-entities/companyFounderShare';
import { LegalEntitiesCompanyHeadFl } from '~/types/legal-entities/companyHeadFl';
import { LegalEntitiesCompanyHistoryAddress } from '~/types/legal-entities/companyHistoryAddress';
import { LegalEntitiesCompanyManagementCompanies } from '~/types/legal-entities/companyManagementCompanies';
import { LegalEntitiesCompanyName } from '~/types/legal-entities/companyName';
import { LegalEntitiesCompanyPhone } from '~/types/legal-entities/companyPhone';
import { LegalEntitiesCompanySite } from '~/types/legal-entities/companySite';
import { LegalEntitiesCompanyStatedCapital } from '~/types/legal-entities/companyStatedCapital';
import { LegalEntitiesConditionAssessment } from '~/types/legal-entities/conditionAssessment';
import { LegalEntitiesConflictSign } from '~/types/legal-entities/conflictSign';
import { LegalEntitiesContractsInfoByYear } from '~/types/legal-entities/contractsInfoByYear';
import { LegalEntitiesCourtCasesByYear } from '~/types/legal-entities/courtCasesByYear';
import { LegalEntitiesEgrRecord } from '~/types/legal-entities/egrRecord';
import { LegalEntitiesEnforcementProceedingsInfoBySubject } from '~/types/legal-entities/enforcementProceedingsInfoBySubject';
import { LegalEntitiesFinancialAnalysisExtended } from '~/types/legal-entities/financialAnalysisExtended';
import { LegalEntitiesFinancialAnalysisShort } from '~/types/legal-entities/financialAnalysisShort';
import { LegalEntitiesFinancialCommon } from '~/types/legal-entities/financialCommon';
import { LegalEntitiesFinancialState } from '~/types/legal-entities/financialState';
import { LegalEntitiesFineAndTaxDebt } from '~/types/legal-entities/fineAndTaxDebt';
import { LegalEntitiesGovernmentControl } from '~/types/legal-entities/governmentControl';
import { LegalEntitiesInsuranceFundInfo } from '~/types/legal-entities/insuranceFundInfo';
import { LegalEntitiesLesseeSummary } from '~/types/legal-entities/lesseeSummary';
import { LegalEntitiesLicenseSummary } from '~/types/legal-entities/licenseSummary';
import { LegalEntitiesMarkers } from '~/types/legal-entities/markers';
import { LegalEntitiesPerson } from '~/types/legal-entities/person';
import { LegalEntitiesPredecessor } from '~/types/legal-entities/predecessor';
import { LegalEntitiesRegistrarOfShareholders } from '~/types/legal-entities/registrarOfShareholders';
import { LegalEntitiesRelatedCompany } from '~/types/legal-entities/relatedCompany';
import { LegalEntitiesRelatedEgrul } from '~/types/legal-entities/relatedEgrul';
import { LegalEntitiesRelatedReason } from '~/types/legal-entities/relatedReason';
import { LegalEntitiesReorganisationMember } from '~/types/legal-entities/reorganisationMember';
import { LegalEntitiesReportAnalysisOfAbsoluteLiquidityIndicator } from '~/types/legal-entities/reportAnalysisOfAbsoluteLiquidityIndicator';
import { LegalEntitiesReportAverageIndustryIndicator } from '~/types/legal-entities/reportAverageIndustryIndicator';
import { LegalEntitiesReportDataBuhForm } from '~/types/legal-entities/reportDataBuhForm';
import { LegalEntitiesReportDataBuhFormDetail } from '~/types/legal-entities/reportDataBuhFormDetail';
import { LegalEntitiesReportFinancialIndicatorBase } from '~/types/legal-entities/reportFinancialIndicatorBase';
import { LegalEntitiesReportFinancialIndicatorWithConclusions } from '~/types/legal-entities/reportFinancialIndicatorWithConclusions';
import { LegalEntitiesReportFinancialIndicatorWithDynamic } from '~/types/legal-entities/reportFinancialIndicatorWithDynamic';
import { LegalEntitiesReportFinancialStability } from '~/types/legal-entities/reportFinancialStability';
import { LegalEntitiesReportLiquidityAndSolvency } from '~/types/legal-entities/reportLiquidityAndSolvency';
import { LegalEntitiesReportSocialNetworkId } from '~/types/legal-entities/reportSocialNetworkId';
import { LegalEntitiesSelfRegulatoryOrganization } from '~/types/legal-entities/selfRegulatoryOrganization';
import { LegalEntitiesShareholder } from '~/types/legal-entities/shareholder';
import { LegalEntitiesSocial } from '~/types/legal-entities/social';
import { LegalEntitiesSubCompanyInfo } from '~/types/legal-entities/subCompanyInfo';
import { LegalEntitiesSuccessor } from '~/types/legal-entities/successor';
import { LegalEntitiesTax } from '~/types/legal-entities/tax';
import { LegalEntitiesTaxMode } from '~/types/legal-entities/taxMode';
import { LegalEntitiesTaxServiceInfo } from '~/types/legal-entities/taxServiceInfo';
import { LegalEntitiesTotalScore } from '~/types/legal-entities/totalScore';
import { LegalEntitiesTrademark } from '~/types/legal-entities/trademark';
import { IPlaceholderDataService } from '../common/reportPlaceholderService.interfaces';
import { LegalEntitiesAverageHeadcount } from '~/types/legal-entities/averageHeadcount';
import { LegalEntitiesAnalyticsIndicators } from '~/types/legal-entities/analyticsIndicators';

export const ILegalEntitiesDataBlobDataPlaceholderServiceKey: InjectionKey<IPlaceholderDataService<LegalEntitiesDataBlobData>> = Symbol('IPlaceholderDataService<LegalEntitiesDataBlobData>');
export const ILegalEntitiesCommonPlaceholderDataServiceKey: InjectionKey<IPlaceholderDataService<LegalEntitiesCommonInfo>> = Symbol('IPlaceholderDataService<LegalEntitiesCommonInfo>');
export const ILegalEntitiesBriefInfoPlaceholderDataServiceKey: InjectionKey<IPlaceholderDataService<LegalEntitiesBriefInfo>> = Symbol('IPlaceholderDataService<LegalEntitiesBriefInfo>');
export const ILegalEntitiesTaxServicePlaceholderServiceKey: InjectionKey<IPlaceholderDataService<LegalEntitiesTaxServiceInfo>> = Symbol('IPlaceholderDataService<LegalEntitiesTaxServiceInfo>');
export const ILegalEntitiesCompanyActualAddressPlaceholderServiceKey: InjectionKey<IPlaceholderDataService<LegalEntitiesCompanyActualAddress>> = Symbol('IPlaceholderDataService<LegalEntitiesCompanyActualAddress>');
export const ILegalEntitiesConditionAssessmentPlaceholderDataServiceKey: InjectionKey<IPlaceholderDataService<LegalEntitiesConditionAssessment>> = Symbol('IPlaceholderDataService<LegalEntitiesConditionAssessment>');
export const ILegalEntitiesTaxModePlaceholderDataServiceKey: InjectionKey<IPlaceholderDataService<LegalEntitiesTaxMode>> = Symbol('IPlaceholderDataService<LegalEntitiesTaxMode>');
export const ILegalEntitiesRegistrarOfShareholdersPlaceholderDataServiceKey: InjectionKey<IPlaceholderDataService<LegalEntitiesRegistrarOfShareholders>> = Symbol('IPlaceholderDataService<LegalEntitiesRegistrarOfShareholders>');
export const ILegalEntitiesLesseeSummaryPlaceholderDataServiceKey: InjectionKey<IPlaceholderDataService<LegalEntitiesLesseeSummary>> = Symbol('IPlaceholderDataService<LegalEntitiesLesseeSummary>');
export const ILegalEntitiesActivitySummaryPlaceholderDataServiceKey: InjectionKey<IPlaceholderDataService<LegalEntitiesActivitySummary>> = Symbol('IPlaceholderDataService<LegalEntitiesActivitySummary>');
export const ILegalEntitiesCompanySitePlaceholderDataServiceKey: InjectionKey<IPlaceholderDataService<LegalEntitiesCompanySite>> = Symbol('IPlaceholderDataService<LegalEntitiesCompanySite>');
export const ILegalEntitiesCompanyPhonePlaceholderDataServiceKey: InjectionKey<IPlaceholderDataService<LegalEntitiesCompanyPhone>> = Symbol('IPlaceholderDataService<LegalEntitiesCompanyPhone>');
export const ILegalEntitiesCompanyEmailPlaceholderDataServiceKey: InjectionKey<IPlaceholderDataService<LegalEntitiesCompanyEmail>> = Symbol('IPlaceholderDataService<LegalEntitiesCompanyEmail>');
export const ILegalEntitiesSocialPlaceholderDataServiceKey: InjectionKey<IPlaceholderDataService<LegalEntitiesSocial>> = Symbol('IPlaceholderDataService<LegalEntitiesSocial>');
export const ILegalEntitiesCompanyStatedCapitalPlaceholderDataServiceKey: InjectionKey<IPlaceholderDataService<LegalEntitiesCompanyStatedCapital>> = Symbol('IPlaceholderDataService<LegalEntitiesCompanyStatedCapital>');
export const ILegalEntitiesCompanyHistoryAddressPlaceholderDataServiceKey: InjectionKey<IPlaceholderDataService<LegalEntitiesCompanyHistoryAddress>> = Symbol('IPlaceholderDataService<LegalEntitiesCompanyHistoryAddress>');
export const ILegalEntitiesCompanyHeadFlPlaceholderDataServiceKey: InjectionKey<IPlaceholderDataService<LegalEntitiesCompanyHeadFl>> = Symbol('IPlaceholderDataService<LegalEntitiesCompanyHeadFl>');
export const ILegalEntitiesCompanyManagementCompaniesPlaceholderDataServiceKey: InjectionKey<IPlaceholderDataService<LegalEntitiesCompanyManagementCompanies>> = Symbol('IPlaceholderDataService<LegalEntitiesCompanyManagementCompanies>');
export const ILegalEntitiesCompanyFounderSharePlaceholderDataServiceKey: InjectionKey<IPlaceholderDataService<LegalEntitiesCompanyFounderShare>> = Symbol('IPlaceholderDataService<LegalEntitiesCompanyFounderShare>');
export const ILegalEntitiesCompanyFounderPlaceholderDataServiceKey: InjectionKey<IPlaceholderDataService<LegalEntitiesCompanyFounder>> = Symbol('IPlaceholderDataService<LegalEntitiesCompanyFounder>');
export const ILegalEntitiesCourtCasesByYearPlaceholderDataServiceKey: InjectionKey<IPlaceholderDataService<LegalEntitiesCourtCasesByYear>> = Symbol('IPlaceholderDataService<LegalEntitiesCourtCasesByYear>');
export const ILegalEntitiesGovernmentControlPlaceholderDataServiceKey: InjectionKey<IPlaceholderDataService<LegalEntitiesGovernmentControl>> = Symbol('IPlaceholderDataService<LegalEntitiesGovernmentControl>');
export const ILegalEntitiesEnforcementProceedingsInfoBySubjectPlaceholderDataServiceKey: InjectionKey<IPlaceholderDataService<LegalEntitiesEnforcementProceedingsInfoBySubject>> = Symbol('IPlaceholderDataService<LegalEntitiesEnforcementProceedingsInfoBySubject>');
export const ILegalEntitiesRelatedCompanyPlaceholderDataServiceKey: InjectionKey<IPlaceholderDataService<LegalEntitiesRelatedCompany>> = Symbol('IPlaceholderDataService<LegalEntitiesRelatedCompany>');
export const ILegalEntitiesRelatedEgrulPlaceholderDataServiceKey: InjectionKey<IPlaceholderDataService<LegalEntitiesRelatedEgrul>> = Symbol('IPlaceholderDataService<LegalEntitiesRelatedEgrul>');
export const ILegalEntitiesPersonPlaceholderDataServiceKey: InjectionKey<IPlaceholderDataService<LegalEntitiesPerson>> = Symbol('IPlaceholderDataService<LegalEntitiesPerson>');
export const ILegalEntitiesRelatedReasonPlaceholderDataServiceKey: InjectionKey<IPlaceholderDataService<LegalEntitiesRelatedReason>> = Symbol('IPlaceholderDataService<LegalEntitiesRelatedReason>');
export const ILegalEntitiesConflictSignPlaceholderDataServiceKey: InjectionKey<IPlaceholderDataService<LegalEntitiesConflictSign>> = Symbol('IPlaceholderDataService<LegalEntitiesConflictSign>');
export const ILegalEntitiesTaxPlaceholderDataServiceKey: InjectionKey<IPlaceholderDataService<LegalEntitiesTax>> = Symbol('IPlaceholderDataService<LegalEntitiesTax>');
export const ILegalEntitiesFineAndTaxDebtPlaceholderDataServiceKey: InjectionKey<IPlaceholderDataService<LegalEntitiesFineAndTaxDebt>> = Symbol('IPlaceholderDataService<LegalEntitiesFineAndTaxDebt>');
export const ILegalEntitiesContractsInfoByYearPlaceholderDataServiceKey: InjectionKey<IPlaceholderDataService<LegalEntitiesContractsInfoByYear>> = Symbol('IPlaceholderDataService<LegalEntitiesContractsInfoByYear>');
export const ILegalEntitiesSubCompanyInfoPlaceholderDataServiceKey: InjectionKey<IPlaceholderDataService<LegalEntitiesSubCompanyInfo>> = Symbol('IPlaceholderDataService<LegalEntitiesSubCompanyInfo>');
export const ILegalEntitiesPredecessorPlaceholderDataServiceKey: InjectionKey<IPlaceholderDataService<LegalEntitiesPredecessor>> = Symbol('IPlaceholderDataService<LegalEntitiesPredecessor>');
export const ILegalEntitiesSuccessorPlaceholderDataServiceKey: InjectionKey<IPlaceholderDataService<LegalEntitiesSuccessor>> = Symbol('IPlaceholderDataService<LegalEntitiesSuccessor>');
export const ILegalEntitiesReorganizationMemberPlaceholderDataServiceKey: InjectionKey<IPlaceholderDataService<LegalEntitiesReorganisationMember>> = Symbol('IPlaceholderDataService<LegalEntitiesReorganisationMember>');
export const ILegalEntitiesShareholderPlaceholderDataServiceKey: InjectionKey<IPlaceholderDataService<LegalEntitiesShareholder>> = Symbol('IPlaceholderDataService<LegalEntitiesShareholder>');
export const ILegalEntitiesEgrRecordPlaceholderDataServiceKey: InjectionKey<IPlaceholderDataService<LegalEntitiesEgrRecord>> = Symbol('IPlaceholderDataService<LegalEntitiesEgrRecord>');
export const ILegalEntitiesCertificateSummaryPlaceholderDataServiceKey: InjectionKey<IPlaceholderDataService<LegalEntitiesCertificateSummary>> = Symbol('IPlaceholderDataService<LegalEntitiesCertificateSummary>');
export const ILegalEntitiesLicenseSummaryPlaceholderDataServiceKey: InjectionKey<IPlaceholderDataService<LegalEntitiesLicenseSummary>> = Symbol('IPlaceholderDataService<LegalEntitiesLicenseSummary>');
export const ILegalEntitiesSelfRegulatoryOrganizationPlaceholderDataServiceKey: InjectionKey<IPlaceholderDataService<LegalEntitiesSelfRegulatoryOrganization>> = Symbol('IPlaceholderDataService<LegalEntitiesSelfRegulatoryOrganization>');
export const ILegalEntitiesTrademarkPlaceholderDataServiceKey: InjectionKey<IPlaceholderDataService<LegalEntitiesTrademark>> = Symbol('IPlaceholderDataService<LegalEntitiesTrademark>');
export const ILegalEntitiesCompanyNamePlaceholderDataServiceKey: InjectionKey<IPlaceholderDataService<LegalEntitiesCompanyName>> = Symbol('IPlaceholderDataService<LegalEntitiesCompanyName>');
export const ILegalEntitiesInsuranceFundInfoPlaceholderDataServiceKey: InjectionKey<IPlaceholderDataService<LegalEntitiesInsuranceFundInfo>> = Symbol('IPlaceholderDataService<LegalEntitiesInsuranceFundInfo>');
export const ILegalEntitiesReportSocialNetworkIdPlaceholderDataServiceKey: InjectionKey<IPlaceholderDataService<LegalEntitiesReportSocialNetworkId>> = Symbol('IPlaceholderDataService<LegalEntitiesReportSocialNetworkId>');
export const ILegalEntitiesCompanyEmployeeInfoPlaceholderDataServiceKey: InjectionKey<IPlaceholderDataService<LegalEntitiesCompanyEmployeeInfo>> = Symbol('IPlaceholderDataService<LegalEntitiesCompanyEmployeeInfo>');
export const ILegalEntitiesFinancialStatePlaceholderDataServiceKey: InjectionKey<IPlaceholderDataService<LegalEntitiesFinancialState>> = Symbol('IPlaceholderDataService<LegalEntitiesFinancialState>');
export const ILegalEntitiesReportDataBuhFormDetailPlaceholderDataServiceKey: InjectionKey<IPlaceholderDataService<LegalEntitiesReportDataBuhFormDetail>> = Symbol('IPlaceholderDataService<LegalEntitiesReportDataBuhFormDetail>');
export const ILegalEntitiesReportDataBuhFormPlaceholderDataServiceKey: InjectionKey<IPlaceholderDataService<LegalEntitiesReportDataBuhForm>> = Symbol('IPlaceholderDataService<LegalEntitiesReportDataBuhForm>');
export const ILegalEntitiesFinancialCommonPlaceholderDataServiceKey: InjectionKey<IPlaceholderDataService<LegalEntitiesFinancialCommon>> = Symbol('IPlaceholderDataService<LegalEntitiesFinancialCommon>');
export const ILegalEntitiesMarkersPlaceholderDataServiceKey: InjectionKey<IPlaceholderDataService<LegalEntitiesMarkers>> = Symbol('IPlaceholderDataService<LegalEntitiesMarkers>');
export const ILegalEntitiesFinancialAnalysisShortPlaceholderDataServiceKey: InjectionKey<IPlaceholderDataService<LegalEntitiesFinancialAnalysisShort>> = Symbol('IPlaceholderDataService<LegalEntitiesFinancialAnalysisShort>');
export const ILegalEntitiesReportFinancialIndicatorWithDynamicPlaceholderDataServiceKey: InjectionKey<IPlaceholderDataService<LegalEntitiesReportFinancialIndicatorWithDynamic>> = Symbol('IPlaceholderDataService<LegalEntitiesReportFinancialIndicatorWithDynamic>');
export const ILegalEntitiesReportFinancialIndicatorWithConclusionsPlaceholderDataServiceKey: InjectionKey<IPlaceholderDataService<LegalEntitiesReportFinancialIndicatorWithConclusions>> = Symbol('IPlaceholderDataService<LegalEntitiesReportFinancialIndicatorWithConclusions>');
export const ILegalEntitiesReportFinancialIndicatorBasePlaceholderDataServiceKey: InjectionKey<IPlaceholderDataService<LegalEntitiesReportFinancialIndicatorBase>> = Symbol('IPlaceholderDataService<LegalEntitiesReportFinancialIndicatorBase>');
export const ILegalEntitiesFinancialAnalysisExtendedPlaceholderDataServiceKey: InjectionKey<IPlaceholderDataService<LegalEntitiesFinancialAnalysisExtended>> = Symbol('IPlaceholderDataService<LegalEntitiesFinancialAnalysisExtended>');
export const ILegalEntitiesReportLiquidityAndSolvencyPlaceholderDataServiceKey: InjectionKey<IPlaceholderDataService<LegalEntitiesReportLiquidityAndSolvency>> = Symbol('IPlaceholderDataService<LegalEntitiesReportLiquidityAndSolvency>');
export const ILegalEntitiesReportFinancialStabilityPlaceholderDataServiceKey: InjectionKey<IPlaceholderDataService<LegalEntitiesReportFinancialStability>> = Symbol('IPlaceholderDataService<LegalEntitiesReportFinancialStability>');
export const ILegalEntitiesReportAnalysisOfAbsoluteLiquidityIndicatorPlaceholderDataServiceKey: InjectionKey<IPlaceholderDataService<LegalEntitiesReportAnalysisOfAbsoluteLiquidityIndicator>> = Symbol('IPlaceholderDataService<LegalEntitiesReportAnalysisOfAbsoluteLiquidityIndicator>');
export const ILegalEntitiesReportAverageIndustryIndicatorPlaceholderDataServiceKey: InjectionKey<IPlaceholderDataService<LegalEntitiesReportAverageIndustryIndicator>> = Symbol('IPlaceholderDataService<LegalEntitiesReportAverageIndustryIndicator>');
export const ILegalEntitiesTotalScorePlaceholderDataServiceKey: InjectionKey<IPlaceholderDataService<LegalEntitiesTotalScore>> = Symbol('IPlaceholderDataService<LegalEntitiesTotalScore>');
export const ILegalEntitiesAverageHeadcountPlaceholderDataServiceKey: InjectionKey<IPlaceholderDataService<LegalEntitiesAverageHeadcount>> = Symbol('IPlaceholderDataService<LegalEntitiesAverageHeadcount>');
export const ILegalEntitiesAnalyticsIndicatorsPlaceholderDataServiceKey: InjectionKey<IPlaceholderDataService<LegalEntitiesAnalyticsIndicators>> = Symbol('IPlaceholderDataService<LegalEntitiesAnalyticsIndicators>');
