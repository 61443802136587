import { InjectionKey } from 'vue';
import { LegalEntitiesDataBlobData } from '~/types/blobs/legal-entities-data-blob-data';
import { IAdapterService } from '../adapterService.interfaces';
import { LegalEntitiesDataBlobDataDTO } from '~/types/dto/blobs/legalEntitiesDataBlobDataDTO';
import { LegalEntitiesCommonInfo } from '~/types/legal-entities/commonInfo';
import { LegalEntitiesCommonInfoDTO } from '~/types/dto/legalEntities/commonInfo';
import { LegalEntitiesBriefInfo } from '~/types/legal-entities/briefInfo';
import { LegalEntitiesBriefInfoDTO } from '~/types/dto/legalEntities/briefInfo';
import { LegalEntitiesCompanyTaxRegimes } from '~/types/legal-entities/companyTaxRegimes';
import { LegalEntitiesCompanyTaxRegimesDTO } from '~/types/dto/legalEntities/companyTaxRegimesDTO';
import { LegalEntitiesBankAccount } from '~/types/legal-entities/bankAccount';
import { LegalEntitiesBankAccountDTO } from '~/types/dto/legalEntities/bankAccount';
import { LegalEntitiesCompanyStatus } from '~/types/legal-entities/LegalEntitiesCompanyStatus';
import { LegalEntitiesCompanyStatusDTO } from '~/types/dto/legalEntities/legalEntitiesCompanyStatusDTO';
import { LegalEntitiesCompanyHeadFl } from '~/types/legal-entities/companyHeadFl';
import { LegalEntitiesCompanyHeadFlDTO } from '~/types/dto/legalEntities/companyHeadFlDTO';
import { LegalEntitiesCompanyPhone } from '~/types/legal-entities/companyPhone';
import { LegalEntitiesCompanyPhoneDTO } from '~/types/dto/legalEntities/companyPhoneDTO';
import { LegalEntitiesHashtag } from '~/types/legal-entities/hashtag';
import { LegalEntitiesHashtagDTO } from '~/types/dto/legalEntities/legalEntitiesHashtagDTO';
import { LegalEntitiesCompanyEmail } from '~/types/legal-entities/companyEmail';
import { LegalEntitiesCompanySite } from '~/types/legal-entities/companySite';
import { LegalEntitiesCompanyEmailDTO } from '~/types/dto/common/legalEntitiesCompanyEmailDTO';
import { LegalEntitiesCompanySiteDTO } from '~/types/dto/legalEntities/сompanySiteDTO';
import { LegalEntitiesCompanyActualAddress } from '~/types/legal-entities/companyActualAddress';
import { LegalEntitiesCompanyActualAddressDTO } from '~/types/dto/legalEntities/companyActualAddressDTO';
import { LegalEntitiesCourtCasesByYear } from '~/types/legal-entities/courtCasesByYear';
import { LegalEntitiesCourtCasesByYearDTO } from '~/types/dto/legalEntities/courtCasesByYear';
import {
  LegalEntitiesEnforcementProceedingsInfoBySubject
} from '~/types/legal-entities/enforcementProceedingsInfoBySubject';
import {
  LegalEntitiesEnforcementProceedingsInfoBySubjectDTO
} from '~/types/dto/legalEntities/enforcementProceedingsInfoBySubject';
import { LegalEntitiesCompanyFounder } from '~/types/legal-entities/companyFounder';
import { LegalEntitiesCompanyFounderDTO } from '~/types/dto/legalEntities/companyFounder';
import { LegalEntitiesCompanyFounderShare } from '~/types/legal-entities/companyFounderShare';
import { LegalEntitiesCompanyFounderShareDTO } from '~/types/dto/legalEntities/companyFounderShare';
import { LegalEntitiesTaxServiceInfo } from '~/types/legal-entities/taxServiceInfo';
import { LegalEntitiesTaxServiceInfoDTO } from '~/types/dto/legalEntities/taxServiceInfoDTO';
import { LegalEntitiesCompanyHistoryAddress } from '~/types/legal-entities/companyHistoryAddress';
import { LegalEntitiesCompanyHistoryAddressDTO } from '~/types/dto/legalEntities/legalEntitiesCompanyHistoryAddress';
import { LegalEntitiesInsuranceFundInfo } from '~/types/legal-entities/insuranceFundInfo';
import { LegalEntitiesInsuranceFundInfoDTO } from '~/types/dto/legalEntities/insuranceFundInfoDTO';
import { LegalEntitiesCompanyEmployeeInfo } from '~/types/legal-entities/companyEmployeeInfo';
import { LegalEntitiesCompanyEmployeeInfoDTO } from '~/types/dto/legalEntities/companyEmployeeInfoDTO';
import { LegalEntitiesTax } from '~/types/legal-entities/tax';
import { LegalEntitiesTaxMode } from '~/types/legal-entities/taxMode';
import { LegalEntitiesTaxModeDTO } from '~/types/dto/legalEntities/taxMode';
import { LegalEntitiesFineAndTaxDebt } from '~/types/legal-entities/fineAndTaxDebt';
import { LegalEntitiesFineAndTaxDebtDTO } from '~/types/dto/legalEntities/fineAndTaxDebtDTO';
import { LegalEntitiesTaxesPenalties } from '~/types/legal-entities/taxesPenalties';
import { LegalEntitiesTaxesPenaltiesDTO } from '~/types/dto/legalEntities/taxesPenaltiesDTO';
import { LegalEntitiesLesseeSummary } from '~/types/legal-entities/lesseeSummary';
import { LegalEntitiesLesseeSummaryDTO } from '~/types/dto/legalEntities/lesseeSummaryDTO';
import { LegalEntitiesLicenseSummary } from '~/types/legal-entities/licenseSummary';
import { LegalEntitiesLicenseSummaryDTO } from '~/types/dto/legalEntities/licenseSummaryDTO';
import { LegalEntitiesContractsInfoByYear } from '~/types/legal-entities/contractsInfoByYear';
import { LegalEntitiesContractsInfoByYearDTO } from '~/types/dto/legalEntities/contractsInfoByYearDTO';
import { LegalEntitiesGovernmentControl } from '~/types/legal-entities/governmentControl';
import { LegalEntitiesGovernmentControlDTO } from '~/types/dto/legalEntities/governmentControlDTO';
import { LegalEntitiesCertificateSummary } from '~/types/legal-entities/certificateSummary';
import { LegalEntitiesCertificateSummaryDTO } from '~/types/dto/legalEntities/certificateSummaryDTO';
import { LegalEntitiesSelfRegulatoryOrganization } from '~/types/legal-entities/selfRegulatoryOrganization';
import { LegalEntitiesSelfRegulatoryOrganizationDTO } from '~/types/dto/legalEntities/selfRegulatoryOrganizationDTO';
import { LegalEntitiesTrademark } from '~/types/legal-entities/trademark';
import { LegalEntitiesTrademarkDTO } from '~/types/dto/legalEntities/trademarkDTO';
import { LegalEntitiesSubCompanyInfo } from '~/types/legal-entities/subCompanyInfo';
import { LegalEntitiesSubCompanyInfoDTO } from '~/types/dto/legalEntities/subCompanyInfo';
import { LegalEntitiesRegistrarOfShareholders } from '~/types/legal-entities/registrarOfShareholders';
import { LegalEntitiesRegistrarOfShareholdersDTO } from '~/types/dto/legalEntities/registrarOfShareholdersDTO';
import { LegalEntitiesCompanyManagementCompanies } from '~/types/legal-entities/companyManagementCompanies';
import { LegalEntitiesCompanyManagementCompaniesDTO } from '~/types/dto/legalEntities/companyManagementCompaniesDTO';
import { LegalEntitiesShareholder } from '~/types/legal-entities/shareholder';
import { LegalEntitiesShareholderDTO } from '~/types/dto/legalEntities/shareholderDTO';
import { LegalEntitiesCompanyName } from '~/types/legal-entities/companyName';
import { LegalEntitiesCompanyNameDTO } from '~/types/dto/legalEntities/companyNameDTO';
import { LegalEntitiesCompanyStatedCapital } from '~/types/legal-entities/companyStatedCapital';
import { LegalEntitiesCompanyStatedCapitalDTO } from '~/types/dto/legalEntities/companyStatedCapitalDTO';
import { LegalEntitiesPredecessor } from '~/types/legal-entities/predecessor';
import { LegalEntitiesPredecessorDTO } from '~/types/dto/legalEntities/predecessorDTO';
import { LegalEntitiesSuccessor } from '~/types/legal-entities/successor';
import { LegalEntitiesSuccessorDTO } from '~/types/dto/legalEntities/successorDTO';
import { LegalEntitiesReorganisationMember } from '~/types/legal-entities/reorganisationMember';
import { LegalEntitiesReorganisationMemberDTO } from '~/types/dto/legalEntities/reorganisationMemberDTO';
import { LegalEntitiesRelatedEgrul } from '~/types/legal-entities/relatedEgrul';
import { LegalEntitiesRelatedEgrulDTO } from '~/types/dto/legalEntities/relatedEgrulDTO';
import { LegalEntitiesActivitySummary } from '~/types/legal-entities/activitySummary';
import { LegalEntitiesActivitySummaryDTO } from '~/types/dto/legalEntities/activitySummaryDTO';
import { LegalEntitiesSocial } from '~/types/legal-entities/social';
import { LegalEntitiesSocialDTO } from '~/types/dto/legalEntities/socialDTO';

import { LegalEntitiesAverageHeadcountDTO } from '~/types/dto/legalEntities/averageHeadcount';
import { LegalEntitiesAverageHeadcount } from '~/types/legal-entities/averageHeadcount';
import { LegalEntitiesMarkersDTO } from '~/types/dto/legalEntities/markers';
import { LegalEntitiesMarkers } from '~/types/legal-entities/markers';
import { LegalEntitiesFinancialStateDTO } from '~/types/dto/legalEntities/financialStateDTO';
import { LegalEntitiesFinancialState } from '~/types/legal-entities/financialState';
import { LegalEntitiesFinancialCommon } from '~/types/legal-entities/financialCommon';
import { LegalEntitiesFinancialCommonDTO } from '~/types/dto/legalEntities/financialCommonDTO';
import { LegalEntitiesReportDataBuhFormDTO } from '~/types/dto/legalEntities/reportDataBuhFormDTO';
import { LegalEntitiesReportDataBuhFormDetailDTO } from '~/types/dto/legalEntities/reportDataBuhFormDetailDTO';
import { LegalEntitiesReportDataBuhFormDetail } from '~/types/legal-entities/reportDataBuhFormDetail';
import { LegalEntitiesReportDataBuhForm } from '~/types/legal-entities/reportDataBuhForm';
import { LegalEntitiesFinancialAnalysisShortDTO } from '~/types/dto/legalEntities/financialAnalysisShort';
import { LegalEntitiesFinancialAnalysisShort } from '~/types/legal-entities/financialAnalysisShort';
import { LegalEntitiesReportFinancialIndicatorBase } from '~/types/legal-entities/reportFinancialIndicatorBase';
import { LegalEntitiesReportFinancialIndicatorBaseDTO } from '~/types/dto/legalEntities/reportFinancialIndicatorBaseDTO';
import {
  LegalEntitiesReportFinancialIndicatorWithConclusionsDTO
} from '~/types/dto/legalEntities/reportFinancialIndicatorWithConclusionsDTO';
import {
  LegalEntitiesReportFinancialIndicatorWithConclusions
} from '~/types/legal-entities/reportFinancialIndicatorWithConclusions';
import {
  LegalEntitiesReportFinancialIndicatorWithDynamicDTO
} from '~/types/dto/legalEntities/reportFinancialIndicatorWithDynamicDTO';
import {
  LegalEntitiesReportFinancialIndicatorWithDynamic
} from '~/types/legal-entities/reportFinancialIndicatorWithDynamic';
import { LegalEntitiesFinancialAnalysisExtendedDTO } from '~/types/dto/legalEntities/financialAnalysisExtended';
import { LegalEntitiesFinancialAnalysisExtended } from '~/types/legal-entities/financialAnalysisExtended';
import { LegalEntitiesReportLiquidityAndSolvency } from '~/types/legal-entities/reportLiquidityAndSolvency';
import { LegalEntitiesReportLiquidityAndSolvencyDTO } from '~/types/dto/legalEntities/reportLiquidityAndSolvency';
import {
  LegalEntitiesReportAnalysisOfAbsoluteLiquidityIndicator
} from '~/types/legal-entities/reportAnalysisOfAbsoluteLiquidityIndicator';
import {
  LegalEntitiesReportAnalysisOfAbsoluteLiquidityIndicatorDTO
} from '~/types/dto/legalEntities/reportAnalysisOfAbsoluteLiquidityIndicator';
import { ReportAnalysisOfAbsoluteLiquidity } from '~/types/legal-entities/reportAnalysisOfAbsoluteLiquidity';
import {
  LegalEntitiesReportAnalysisOfAbsoluteLiquidityDTO
} from '~/types/dto/legalEntities/reportAnalysisOfAbsoluteLiquidity';
import { LegalEntitiesReportFinancialStability } from '~/types/legal-entities/reportFinancialStability';
import { LegalEntitiesReportFinancialStabilityDTO } from '~/types/dto/legalEntities/reportFinancialStabilityDTO';
import {
  LegalEntitiesReportIndicatorsOfProfitabilityDTO
} from '~/types/dto/legalEntities/reportIndicatorsOfProfitability';
import { ReportIndicatorsOfProfitability } from '~/types/legal-entities/reportIndicatorsOfProfitability';
import { LegalEntitiesReportAverageIndustryIndicator } from '~/types/legal-entities/reportAverageIndustryIndicator';
import {
  LegalEntitiesReportAverageIndustryIndicatorDTO
} from '~/types/dto/legalEntities/reportAverageIndustryIndicatorDTO';
import { LegalEntitiesReportIndicatorsOfCashFlowDTO } from '~/types/dto/legalEntities/reportIndicatorsOfCashFlowDTO';
import { ReportIndicatorsOfCashFlow } from '~/types/blobs/report-indicators-of-cash-flow';
import { LegalEntitiesTotalScoreInfoDTO } from '~/types/dto/legalEntities/totalScoreDTO';
import { LegalEntitiesTotalScore } from '~/types/legal-entities/totalScore';
import { LegalEntitiesAnalyticsIndicatorsDTO } from '~/types/dto/legalEntities/analyticsIndicators';
import { LegalEntitiesAnalyticsIndicators } from '~/types/legal-entities/analyticsIndicators';

export type ILegalEntitiesDataBlobDataService = IAdapterService<LegalEntitiesDataBlobData, LegalEntitiesDataBlobDataDTO>;
export type ILegalEntitiesCommonInfoAdapterService = IAdapterService<LegalEntitiesCommonInfo, LegalEntitiesCommonInfoDTO>;
export type ILegalEntitiesBriefInfoAdapterService = IAdapterService<LegalEntitiesBriefInfo, LegalEntitiesBriefInfoDTO>
export type ILegalEntitiesCompanyTaxRegimesAdapterService = IAdapterService<LegalEntitiesCompanyTaxRegimes[], LegalEntitiesCompanyTaxRegimesDTO[]>
export type ILegalEntitiesBankAccountAdapterService = IAdapterService<LegalEntitiesBankAccount[], LegalEntitiesBankAccountDTO[]>
export type ILegalEntitiesCompanyStatusAdapterService = IAdapterService<LegalEntitiesCompanyStatus[], LegalEntitiesCompanyStatusDTO[]>
export type ILegalEntitiesCompanyHeadFlAdapterService = IAdapterService<LegalEntitiesCompanyHeadFl[], LegalEntitiesCompanyHeadFlDTO[]>
export type ILegalEntitiesCompanyPhoneAdapterService = IAdapterService<LegalEntitiesCompanyPhone[], LegalEntitiesCompanyPhoneDTO[]>
export type ILegalEntitiesHashtagAdapterService = IAdapterService<LegalEntitiesHashtag[], LegalEntitiesHashtagDTO[]>;
export type ILegalEntitiesEmailsAdapterService = IAdapterService<LegalEntitiesCompanyEmail[], LegalEntitiesCompanyEmailDTO[]>;
export type ILegalEntitiesCompanySiteAdapterService = IAdapterService<LegalEntitiesCompanySite[], LegalEntitiesCompanySiteDTO[]>;
export type ILegalEntitiesCompanyActualAddressAdapterService = IAdapterService<LegalEntitiesCompanyActualAddress, LegalEntitiesCompanyActualAddressDTO>;
export type ILegalEntitiesCourtCasesByYearAdapterService = IAdapterService<LegalEntitiesCourtCasesByYear[], LegalEntitiesCourtCasesByYearDTO[]>;
export type ILegalEntitiesEnforcementProceedingsInfoBySubjectAdapterService = IAdapterService<LegalEntitiesEnforcementProceedingsInfoBySubject[], LegalEntitiesEnforcementProceedingsInfoBySubjectDTO[]>
export type ILegalEntitiesCompanyFounderAdapterService = IAdapterService<LegalEntitiesCompanyFounder[], LegalEntitiesCompanyFounderDTO[]>
export type ILegalEntitiesCompanyFounderShareAdapterService = IAdapterService<LegalEntitiesCompanyFounderShare, LegalEntitiesCompanyFounderShareDTO>
export type ILegalEntitiesTaxServiceInfoAdapterService = IAdapterService<LegalEntitiesTaxServiceInfo, LegalEntitiesTaxServiceInfoDTO>
export type ILegalEntitiesCompanyHistoryAddressAdapterService = IAdapterService<LegalEntitiesCompanyHistoryAddress[], LegalEntitiesCompanyHistoryAddressDTO[]>;
export type ILegalEntitiesInsuranceFundInfoAdapterService = IAdapterService<LegalEntitiesInsuranceFundInfo[], LegalEntitiesInsuranceFundInfoDTO[]>
export type ILegalEntitiesCompanyEmployeeInfoAdapterService = IAdapterService<LegalEntitiesCompanyEmployeeInfo[], LegalEntitiesCompanyEmployeeInfoDTO[]>
export type ILegalEntitiesTaxAdapterService = IAdapterService<LegalEntitiesTax[], LegalEntitiesTax[]>
export type ILegalEntitiesTaxModeAdapterService = IAdapterService<LegalEntitiesTaxMode, LegalEntitiesTaxModeDTO>
export type ILegalEntitiesFineAndTaxDebtAdapterService = IAdapterService<LegalEntitiesFineAndTaxDebt[], LegalEntitiesFineAndTaxDebtDTO[]>
export type ILegalEntitiesTaxesPenaltiesAdapterService = IAdapterService<LegalEntitiesTaxesPenalties[], LegalEntitiesTaxesPenaltiesDTO[]>
export type ILegalEntitiesLesseeSummaryAdapterService = IAdapterService<LegalEntitiesLesseeSummary, LegalEntitiesLesseeSummaryDTO>
export type ILegalEntitiesLicenseSummaryAdapterService = IAdapterService<LegalEntitiesLicenseSummary[], LegalEntitiesLicenseSummaryDTO[]>
export type ILegalEntitiesContractsInfoByYearAdapterService = IAdapterService<LegalEntitiesContractsInfoByYear[], LegalEntitiesContractsInfoByYearDTO[]>
export type ILegalEntitiesGovernmentControlAdapterService = IAdapterService<LegalEntitiesGovernmentControl[], LegalEntitiesGovernmentControlDTO[]>
export type ILegalEntitiesCertificateSummaryAdapterService = IAdapterService<LegalEntitiesCertificateSummary[], LegalEntitiesCertificateSummaryDTO[]>
export type ILegalEntitiesSelfRegulatoryOrganizationAdapterService = IAdapterService<LegalEntitiesSelfRegulatoryOrganization[], LegalEntitiesSelfRegulatoryOrganizationDTO[]>
export type ILegalEntitiesTrademarkAdapterService = IAdapterService<LegalEntitiesTrademark[], LegalEntitiesTrademarkDTO[]>
export type ILegalEntitiesSubCompanyInfoAdapterService = IAdapterService<LegalEntitiesSubCompanyInfo[], LegalEntitiesSubCompanyInfoDTO[]>
export type ILegalEntitiesRegistrarOfShareholdersAdapterService = IAdapterService<LegalEntitiesRegistrarOfShareholders, LegalEntitiesRegistrarOfShareholdersDTO>;
export type ILegalEntitiesCompanyManagementCompaniesAdapterService = IAdapterService<LegalEntitiesCompanyManagementCompanies[], LegalEntitiesCompanyManagementCompaniesDTO[]>
export type ILegalEntitiesShareholderAdapterService = IAdapterService<LegalEntitiesShareholder[], LegalEntitiesShareholderDTO[]>
export type ILegalEntitiesCompanyNameAdapterService = IAdapterService<LegalEntitiesCompanyName[], LegalEntitiesCompanyNameDTO[]>
export type ILegalEntitiesCompanyStatedCapitalAdapterService = IAdapterService<LegalEntitiesCompanyStatedCapital[], LegalEntitiesCompanyStatedCapitalDTO[]>
export type ILegalEntitiesPredecessorAdapterService = IAdapterService<LegalEntitiesPredecessor[], LegalEntitiesPredecessorDTO[]>
export type ILegalEntitiesSuccessorAdapterService = IAdapterService<LegalEntitiesSuccessor[], LegalEntitiesSuccessorDTO[]>
export type ILegalEntitiesReorganisationMemberAdapterService = IAdapterService<LegalEntitiesReorganisationMember[], LegalEntitiesReorganisationMemberDTO[]>
export type ILegalEntitiesRelatedEgrulAdapterService = IAdapterService<LegalEntitiesRelatedEgrul[], LegalEntitiesRelatedEgrulDTO[]>
export type ILegalEntitiesActivitySummaryAdapterService = IAdapterService<LegalEntitiesActivitySummary, LegalEntitiesActivitySummaryDTO>
export type ILegalEntitiesSocialsAdapterService = IAdapterService<LegalEntitiesSocial[], LegalEntitiesSocialDTO[]>
export type ILegalEntitiesAverageHeadcountAdapterService = IAdapterService<LegalEntitiesAverageHeadcount[], LegalEntitiesAverageHeadcountDTO[]>
export type ILegalEntitiesMarkersAdapterService = IAdapterService<LegalEntitiesMarkers, LegalEntitiesMarkersDTO>;
export type ILegalEntitiesFinancialStateAdapterService = IAdapterService<LegalEntitiesFinancialState, LegalEntitiesFinancialStateDTO>
export type ILegalEntitiesFinancialCommonAdapterService = IAdapterService<LegalEntitiesFinancialCommon[], LegalEntitiesFinancialCommonDTO[]>;
export type ILegalEntitiesReportDataBuhFormAdapterService = IAdapterService<LegalEntitiesReportDataBuhForm[], LegalEntitiesReportDataBuhFormDTO[]>;
export type ILegalEntitiesReportDataBuhFormDetailAdapterService = IAdapterService<LegalEntitiesReportDataBuhFormDetail[], LegalEntitiesReportDataBuhFormDetailDTO[]>;
export type ILegalEntitiesObjectReportDataBuhFormAdapterService = IAdapterService<LegalEntitiesReportDataBuhForm, LegalEntitiesReportDataBuhFormDTO>;
export type ILegalEntitiesFinancialAnalysisShortAdapterService = IAdapterService<LegalEntitiesFinancialAnalysisShort, LegalEntitiesFinancialAnalysisShortDTO>
export type ILegalEntitiesReportFinancialIndicatorBaseAdapterService = IAdapterService<LegalEntitiesReportFinancialIndicatorBase, LegalEntitiesReportFinancialIndicatorBaseDTO>;
export type ILegalEntitiesReportFinancialIndicatorWithConclusionsAdapterService = IAdapterService<LegalEntitiesReportFinancialIndicatorWithConclusions, LegalEntitiesReportFinancialIndicatorWithConclusionsDTO>;
export type ILegalEntitiesReportFinancialIndicatorWithDynamicAdapterService = IAdapterService<LegalEntitiesReportFinancialIndicatorWithDynamic, LegalEntitiesReportFinancialIndicatorWithDynamicDTO>;
export type ILegalEntitiesFinancialAnalysisExtendedAdapterService = IAdapterService<LegalEntitiesFinancialAnalysisExtended, LegalEntitiesFinancialAnalysisExtendedDTO>
export type ILegalEntitiesReportLiquidityAndSolvencyAdapterService = IAdapterService<LegalEntitiesReportLiquidityAndSolvency, LegalEntitiesReportLiquidityAndSolvencyDTO>
export type ILegalEntitiesReportAnalysisOfAbsoluteLiquidityIndicatorAdapterService = IAdapterService<LegalEntitiesReportAnalysisOfAbsoluteLiquidityIndicator, LegalEntitiesReportAnalysisOfAbsoluteLiquidityIndicatorDTO>
export type ILegalEntitiesReportAnalysisOfAbsoluteLiquidityAdapterService = IAdapterService<ReportAnalysisOfAbsoluteLiquidity, LegalEntitiesReportAnalysisOfAbsoluteLiquidityDTO>
export type ILegalEntitiesReportFinancialStabilityAdapterService = IAdapterService<LegalEntitiesReportFinancialStability, LegalEntitiesReportFinancialStabilityDTO>
export type ILegalEntitiesReportIndicatorsOfProfitabilityAdapterService = IAdapterService<ReportIndicatorsOfProfitability, LegalEntitiesReportIndicatorsOfProfitabilityDTO>
export type ILegalEntitiesReportAverageIndustryIndicatorAdapterService = IAdapterService<LegalEntitiesReportAverageIndustryIndicator, LegalEntitiesReportAverageIndustryIndicatorDTO>
export type ILegalEntitiesReportIndicatorsOfCashFlowAdapterService = IAdapterService<ReportIndicatorsOfCashFlow, LegalEntitiesReportIndicatorsOfCashFlowDTO>
export type ILegalEntitiesTotalScoreAdapterService = IAdapterService<LegalEntitiesTotalScore, LegalEntitiesTotalScoreInfoDTO>
export type ILegalEntitiesAnalyticsIndicatorsAdapterService = IAdapterService<LegalEntitiesAnalyticsIndicators, LegalEntitiesAnalyticsIndicatorsDTO>

export const ILegalEntitiesAnalyticsIndicatorsAdapterServiceKey: InjectionKey<ILegalEntitiesAnalyticsIndicatorsAdapterService> = Symbol('ILegalEntitiesAnalyticsIndicatorsAdapterService');
export const ILegalEntitiesTotalScoreAdapterServiceKey: InjectionKey<ILegalEntitiesTotalScoreAdapterService> = Symbol('ILegalEntitiesTotalScoreAdapterService');
export const ILegalEntitiesReportIndicatorsOfCashFlowAdapterServiceKey: InjectionKey<ILegalEntitiesReportIndicatorsOfCashFlowAdapterService> = Symbol('IReportIndicatorsOfCashFlowAdapterService');
export const ILegalEntitiesReportAverageIndustryIndicatorAdapterServiceKey: InjectionKey<ILegalEntitiesReportAverageIndustryIndicatorAdapterService> = Symbol('ILegalEntitiesReportAverageIndustryIndicatorAdapterService');
export const ILegalEntitiesReportIndicatorsOfProfitabilityAdapterServiceKey: InjectionKey<ILegalEntitiesReportIndicatorsOfProfitabilityAdapterService> = Symbol('ILegalEntitiesReportIndicatorsOfProfitabilityAdapterService');
export const ILegalEntitiesReportFinancialStabilityAdapterServiceKey: InjectionKey<ILegalEntitiesReportFinancialStabilityAdapterService> = Symbol('ILegalEntitiesReportFinancialStabilityAdapterService');
export const ILegalEntitiesReportAnalysisOfAbsoluteLiquidityAdapterServiceKey: InjectionKey<ILegalEntitiesReportAnalysisOfAbsoluteLiquidityAdapterService> = Symbol('ILegalEntitiesReportAnalysisOfAbsoluteLiquidityAdapterService');
export const ILegalEntitiesReportAnalysisOfAbsoluteLiquidityIndicatorAdapterServiceKey: InjectionKey<ILegalEntitiesReportAnalysisOfAbsoluteLiquidityIndicatorAdapterService> = Symbol('ILegalEntitiesReportAnalysisOfAbsoluteLiquidityIndicatorAdapterService');
export const ILegalEntitiesReportLiquidityAndSolvencyAdapterServiceKey: InjectionKey<ILegalEntitiesReportLiquidityAndSolvencyAdapterService> = Symbol('ILegalEntitiesReportLiquidityAndSolvencyAdapterService');
export const ILegalEntitiesFinancialAnalysisExtendedAdapterServiceKey: InjectionKey<ILegalEntitiesFinancialAnalysisExtendedAdapterService> = Symbol('ILegalEntitiesFinancialAnalysisExtendedAdapterService');
export const ILegalEntitiesReportFinancialIndicatorWithDynamicAdapterServiceKey: InjectionKey<ILegalEntitiesReportFinancialIndicatorWithDynamicAdapterService> = Symbol('ILegalEntitiesReportFinancialIndicatorWithDynamicAdapterService');
export const ILegalEntitiesReportFinancialIndicatorWithConclusionsAdapterServiceKey: InjectionKey<ILegalEntitiesReportFinancialIndicatorWithConclusionsAdapterService> = Symbol('ILegalEntitiesReportFinancialIndicatorWithConclusionsAdapterService');
export const ILegalEntitiesReportFinancialIndicatorBaseAdapterServiceKey: InjectionKey<ILegalEntitiesReportFinancialIndicatorBaseAdapterService> = Symbol('ILegalEntitiesReportFinancialIndicatorBaseAdapterService');
export const ILegalEntitiesFinancialAnalysisShortAdapterServiceKey: InjectionKey<ILegalEntitiesFinancialAnalysisShortAdapterService> = Symbol('ILegalEntitiesFinancialAnalysisShortAdapterService');
export const ILegalEntitiesObjectReportDataBuhFormAdapterServiceKey: InjectionKey<ILegalEntitiesObjectReportDataBuhFormAdapterService> = Symbol('ILegalEntitiesObjectReportDataBuhFormAdapterService');
export const ILegalEntitiesReportDataBuhFormDetailAdapterServiceKey: InjectionKey<ILegalEntitiesReportDataBuhFormDetailAdapterService> = Symbol('ILegalEntitiesReportDataBuhFormDetailAdapterService');
export const ILegalEntitiesReportDataBuhFormAdapterServiceKey: InjectionKey<ILegalEntitiesReportDataBuhFormAdapterService> = Symbol('ILegalEntitiesReportDataBuhFormAdapterService');
export const ILegalEntitiesFinancialCommonAdapterServiceKey: InjectionKey<ILegalEntitiesFinancialCommonAdapterService> = Symbol('ILegalEntitiesFinancialCommonAdapterService');
export const ILegalEntitiesFinancialStateAdapterServiceKey: InjectionKey<ILegalEntitiesFinancialStateAdapterService> = Symbol('ILegalEntitiesFinancialStateAdapterService');
export const ILegalEntitiesMarkersAdapterServiceKey: InjectionKey<ILegalEntitiesMarkersAdapterService> = Symbol('ILegalEntitiesMarkersAdapterService');
export const ILegalEntitiesAverageHeadcountAdapterServiceKey: InjectionKey<ILegalEntitiesAverageHeadcountAdapterService> = Symbol('ILegalEntitiesAverageHeadcountAdapterService');
export const ILegalEntitiesSocialsAdapterServiceKey: InjectionKey<ILegalEntitiesSocialsAdapterService> = Symbol('ILegalEntitiesSocialsAdapterService');
export const ILegalEntitiesActivitySummaryAdapterServiceKey: InjectionKey<ILegalEntitiesActivitySummaryAdapterService> = Symbol('ILegalEntitiesActivitySummaryAdapterService');
export const ILegalEntitiesRelatedEgrulAdapterServiceKey: InjectionKey<ILegalEntitiesRelatedEgrulAdapterService> = Symbol('ILegalEntitiesRelatedEgrulAdapterService');
export const ILegalEntitiesReorganisationMemberAdapterServiceKey: InjectionKey<ILegalEntitiesReorganisationMemberAdapterService> = Symbol('ILegalEntitiesReorganisationMemberAdapterService');
export const ILegalEntitiesSuccessorAdapterServiceKey: InjectionKey<ILegalEntitiesSuccessorAdapterService> = Symbol('ILegalEntitiesSuccessorAdapterService');
export const ILegalEntitiesPredecessorAdapterServiceKey: InjectionKey<ILegalEntitiesPredecessorAdapterService> = Symbol('ILegalEntitiesPredecessorAdapterService');
export const ILegalEntitiesCompanyStatedCapitalAdapterServiceKey: InjectionKey<ILegalEntitiesCompanyStatedCapitalAdapterService> = Symbol('ILegalEntitiesCompanyStatedCapitalAdapterService');
export const ILegalEntitiesCompanyNameAdapterServiceKey: InjectionKey<ILegalEntitiesCompanyNameAdapterService> = Symbol('ILegalEntitiesCompanyNameAdapterService');
export const ILegalEntitiesShareholderAdapterServiceKey: InjectionKey<ILegalEntitiesShareholderAdapterService> = Symbol('ILegalEntitiesShareholderAdapterService');
export const ILegalEntitiesCompanyManagementCompaniesAdapterServiceKey: InjectionKey<ILegalEntitiesCompanyManagementCompaniesAdapterService> = Symbol('ILegalEntitiesCompanyManagementCompaniesAdapterService');
export const ILegalEntitiesRegistrarOfShareholdersAdapterServiceKey: InjectionKey<ILegalEntitiesRegistrarOfShareholdersAdapterService> = Symbol('ILegalEntitiesRegistrarOfShareholdersAdapterService');
export const ILegalEntitiesSubCompanyInfoAdapterServiceKey: InjectionKey<ILegalEntitiesSubCompanyInfoAdapterService> = Symbol('ILegalEntitiesSubCompanyInfoAdapterService');
export const ILegalEntitiesTrademarkAdapterServiceKey: InjectionKey<ILegalEntitiesTrademarkAdapterService> = Symbol('ILegalEntitiesTrademarkAdapterService');
export const ILegalEntitiesHashtagAdapterServiceKey: InjectionKey<ILegalEntitiesHashtagAdapterService> = Symbol('ILegalEntitiesHashtagAdapterService');
export const ILegalEntitiesDataBlobDataServiceKey: InjectionKey<ILegalEntitiesDataBlobDataService> = Symbol('ILegalEntitiesDataBlobDataService');
export const ILegalEntitiesCommonInfoAdapterServiceKey: InjectionKey<ILegalEntitiesCommonInfoAdapterService> = Symbol('ILegalEntitiesCommonInfoAdapterService');
export const ILegalEntitiesBriefInfoAdapterServiceKey: InjectionKey<ILegalEntitiesBriefInfoAdapterService> = Symbol('ILegalEntitiesBriefInfoAdapterService');
export const ILegalEntitiesCompanyTaxRegimesAdapterServiceKey: InjectionKey<ILegalEntitiesCompanyTaxRegimesAdapterService> = Symbol('ILegalEntitiesCompanyStatusAdapterService');
export const ILegalEntitiesBankAccountAdapterServiceKey: InjectionKey<ILegalEntitiesBankAccountAdapterService> = Symbol('ILegalEntitiesBankAccountAdapterService');
export const ILegalEntitiesCompanyStatusAdapterServiceKey: InjectionKey<ILegalEntitiesCompanyStatusAdapterService> = Symbol('ILegalEntitiesCompanyStatusAdapterService');
export const ILegalEntitiesCompanyHeadFlAdapterServiceKey: InjectionKey<ILegalEntitiesCompanyHeadFlAdapterService> = Symbol('ILegalEntitiesCompanyHeadFlAdapterService');
export const ILegalEntitiesCompanyPhoneAdapterServiceKey: InjectionKey<ILegalEntitiesCompanyPhoneAdapterService> = Symbol('ILegalEntitiesCompanyPhoneAdapterService');
export const ILegalEntitiesEmailsAdapterServiceKey: InjectionKey<ILegalEntitiesEmailsAdapterService> = Symbol('ILegalEntitiesEmailsAdapterService');
export const ILegalEntitiesCompanySiteAdapterServiceKey: InjectionKey<ILegalEntitiesCompanySiteAdapterService> = Symbol('ILegalEntitiesCompanySiteAdapterService');
export const ILegalEntitiesCompanyActualAddressAdapterServiceKey: InjectionKey<ILegalEntitiesCompanyActualAddressAdapterService> = Symbol('ILegalEntitiesCompanyActualAddressAdapterService');
export const ILegalEntitiesCourtCasesByYearAdapterServiceKey: InjectionKey<ILegalEntitiesCourtCasesByYearAdapterService> = Symbol('ILegalEntitiesCourtCasesByYearAdapterService');
export const ILegalEntitiesEnforcementProceedingsInfoBySubjectAdapterServiceKey: InjectionKey<ILegalEntitiesEnforcementProceedingsInfoBySubjectAdapterService> = Symbol('ILegalEntitiesEnforcementProceedingsInfoBySubjectAdapterService');
export const ILegalEntitiesCompanyFounderAdapterServiceKey: InjectionKey<ILegalEntitiesCompanyFounderAdapterService> = Symbol('ILegalEntitiesCompanyFounderAdapterService');
export const ILegalEntitiesCompanyFounderShareAdapterServiceKey: InjectionKey<ILegalEntitiesCompanyFounderShareAdapterService> = Symbol('ILegalEntitiesCompanyFounderShareAdapterService');
export const ILegalEntitiesTaxServiceInfoAdapterServiceKey: InjectionKey<ILegalEntitiesTaxServiceInfoAdapterService> = Symbol('ILegalEntitiesTaxServiceInfoAdapterService');
export const ILegalEntitiesCompanyHistoryAddressAdapterServiceKey: InjectionKey<ILegalEntitiesCompanyHistoryAddressAdapterService> = Symbol('ILegalEntitiesCompanyHistoryAddressAdapterService');
export const ILegalEntitiesInsuranceFundInfoAdapterServiceKey: InjectionKey<ILegalEntitiesInsuranceFundInfoAdapterService> = Symbol('ILegalEntitiesInsuranceFundInfoAdapterService');
export const ILegalEntitiesCompanyEmployeeInfoAdapterServiceKey: InjectionKey<ILegalEntitiesCompanyEmployeeInfoAdapterService> = Symbol('ILegalEntitiesCompanyEmployeeInfoAdapterService');
export const ILegalEntitiesTaxAdapterServiceKey: InjectionKey<ILegalEntitiesTaxAdapterService> = Symbol('ILegalEntitiesTaxAdapterService');
export const ILegalEntitiesTaxModeAdapterServiceKey: InjectionKey<ILegalEntitiesTaxModeAdapterService> = Symbol('ILegalEntitiesTaxModeAdapterService');
export const ILegalEntitiesFineAndTaxDebtAdapterServiceKey: InjectionKey<ILegalEntitiesFineAndTaxDebtAdapterService> = Symbol('ILegalEntitiesFineAndTaxDebtAdapterService');
export const ILegalEntitiesTaxesPenaltiesAdapterServiceKey: InjectionKey<ILegalEntitiesTaxesPenaltiesAdapterService> = Symbol('ILegalEntitiesTaxesPenaltiesAdapterService');
export const ILegalEntitiesLesseeSummaryAdapterServiceKey: InjectionKey<ILegalEntitiesLesseeSummaryAdapterService> = Symbol('ILegalEntitiesLesseeSummaryAdapterService');
export const ILegalEntitiesLicenseSummaryAdapterServiceKey: InjectionKey<ILegalEntitiesLicenseSummaryAdapterService> = Symbol('ILegalEntitiesLicenseSummaryAdapterService');
export const ILegalEntitiesContractsInfoByYearAdapterServiceKey: InjectionKey<ILegalEntitiesContractsInfoByYearAdapterService> = Symbol('ILegalEntitiesContractsInfoByYearAdapterService');
export const ILegalEntitiesGovernmentControlAdapterServiceKey: InjectionKey<ILegalEntitiesGovernmentControlAdapterService> = Symbol('ILegalEntitiesGovernmentControlAdapterService');
export const ILegalEntitiesCertificateSummaryAdapterServiceKey: InjectionKey<ILegalEntitiesCertificateSummaryAdapterService> = Symbol('ILegalEntitiesCertificateSummaryAdapterService');
export const ILegalEntitiesSelfRegulatoryOrganizationAdapterServiceKey: InjectionKey<ILegalEntitiesSelfRegulatoryOrganizationAdapterService> = Symbol('ILegalEntitiesSelfRegulatoryOrganizationAdapterService');
