/* eslint-disable class-methods-use-this, max-len */
import { injectable } from 'inversify';
import { StringUtils } from '~/utils/stringUtils';
import { LegalEntitiesCompanyActualAddress } from '~/types/legal-entities/companyActualAddress';
import {
  ILegalEntitiesCompanyActualAddressAdapterService
} from '~/services/adapters/legalEntities/legalEntitiesAdapterService.interfaces';
import { LegalEntitiesCompanyActualAddressDTO } from '~/types/dto/legalEntities/companyActualAddressDTO';

@injectable()
export class LegalEntitiesCompanyActualAddressAdapterService implements ILegalEntitiesCompanyActualAddressAdapterService {
  getData(initData: LegalEntitiesCompanyActualAddress): LegalEntitiesCompanyActualAddressDTO {
    return ({
      Address: StringUtils.quoteReplacement(initData.Address),
      CompaniesWithThisAddress: initData.CompaniesWithThisAddress,
      UnreliabilityCode: StringUtils.quoteReplacement(initData.UnreliabilityCode),
      IsRegionChanged: initData.IsRegionChanged ?? false,
      UnreliabilityDate: StringUtils.quoteReplacement(initData.UnreliabilityDate ?? ''),
    });
  }
}
