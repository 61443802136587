import { injectable, inject } from 'inversify';
import { IndividualMilitaryRecord } from '~/types/individual/militaryRecord';
import { IPrimitiveTypesNormalizationServiceKey, IPrimitiveTypesNormalizationService } from '../common/primitiveTypesNormalizationService.interfaces';
import { INormalizationService } from '../common/reportNormalizationService.interfaces';

@injectable()
export class IndividualMilitaryRecordNormalizationService implements INormalizationService<IndividualMilitaryRecord> {
  constructor(
    @inject(IPrimitiveTypesNormalizationServiceKey as symbol) private primitiveTypesNormalizationService: IPrimitiveTypesNormalizationService,
  ) {}

  normalize(data: any, root: string): IndividualMilitaryRecord | undefined {
    const { normalizeObject, normalizeOptionalBoolean, normalizeString } = this.primitiveTypesNormalizationService;

    return normalizeObject(data, (data) => ({
      IsMilitaryDuty: normalizeOptionalBoolean(data.IsMilitaryDuty, `${root}.IsMilitaryDuty`),
      Series: normalizeString(data.Series, `${root}.Series`),
      Number: normalizeString(data.Number, `${root}.Number`),
      StockCategory: normalizeString(data.StockCategory, `${root}.StockCategory`),
      MilitaryRank: normalizeString(data.MilitaryRank, `${root}.MilitaryRank`),
      MilitaryPersonnel: normalizeString(data.MilitaryPersonnel, `${root}.MilitaryPersonnel`),
      MilitarySpecialty: normalizeString(data.MilitarySpecialty, `${root}.MilitarySpecialty`),
      MilitaryShelfLife: normalizeString(data.MilitaryShelfLife, `${root}.MilitaryShelfLife`),
      RecruitmentOffice: normalizeString(data.RecruitmentOffice, `${root}.RecruitmentOffice`),
      MilitaryRegistration: normalizeString(data.MilitaryRegistration, `${root}.MilitaryRegistration`),
      HasMobilizationOrder: normalizeOptionalBoolean(data.HasMobilizationOrder, `${root}.HasMobilizationOrder`),
      PartyTeamNumber: normalizeString(data.PartyTeamNumber, `${root}.PartyTeamNumber`),
      BookingOrganization: normalizeString(data.BookingOrganization, `${root}.BookingOrganization`),
    }), root);
  }
}
