import { injectable } from 'inversify';
import { Config } from '~/types/field-list/config';
import { ReportLegalEntitiesParticipationDTO } from '~/types/dto/common/reportLegalEntitiesParticipationDTO';
import { ILegalEntitiesRoleParticipationConfigService } from './legalEntitiesRoleParticipationConfigService.interfaces';

@injectable()
export class LegalEntitiesRoleParticipationConfigService implements ILegalEntitiesRoleParticipationConfigService {
  getConfig(initData: ReportLegalEntitiesParticipationDTO): Config {    
    return {
      fieldsWidth: '100%',
      fieldsDirection: 'column',
      fields: [
        {
          label: 'Наименование юридического лица',
          key: 'companyName',
          value: initData.CompanyName,
          isInner: true
        }
      ]
    };
  }
}
