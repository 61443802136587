import { IContainerBinder } from '~/configuration/inversify/containerAccessor.interface';
import {
  ICompanyFounderServiceKey,
  ICompanyHeadServiceKey,
  ICompanyManagementServiceKey,
  IPredecessorServiceKey,
  IReorganisationMemberServiceKey,
  ISuccessorServiceKey
} from '~/modules/legalEntities/changeHistory/common/services/getIsFirstDateServices/getIsFirstDateService.interfaces';
import {
  CompanyFounderService
} from '~/modules/legalEntities/changeHistory/common/services/getIsFirstDateServices/companyFounderService';
import {
  CompanyHeadService
} from '~/modules/legalEntities/changeHistory/common/services/getIsFirstDateServices/companyHeadService';
import {
  ICheckIsFirstDateServiceKey
} from '~/modules/legalEntities/changeHistory/common/services/getIsFirstDateServices/checkIsFirstDateService/checkIsFirstDateService.interface';
import {
  CheckIsFirstDateService
} from '~/modules/legalEntities/changeHistory/common/services/getIsFirstDateServices/checkIsFirstDateService/checkIsFirstDateService';
import {
  CompanyManagementService
} from '~/modules/legalEntities/changeHistory/common/services/getIsFirstDateServices/companyManagementService';
import {
  PredecessorService
} from '~/modules/legalEntities/changeHistory/common/services/getIsFirstDateServices/predecessorService';
import {
  ReorganisationMemberService
} from '~/modules/legalEntities/changeHistory/common/services/getIsFirstDateServices/reorganisationMemberService';
import {
  SuccessorService
} from '~/modules/legalEntities/changeHistory/common/services/getIsFirstDateServices/successorService';

export function bindLegalEntitiesChangeHistoryServices(bind: IContainerBinder) {
  bind(ICompanyFounderServiceKey).to(CompanyFounderService);
  bind(ICompanyHeadServiceKey).to(CompanyHeadService);
  bind(ICheckIsFirstDateServiceKey).to(CheckIsFirstDateService);
  bind(ICompanyManagementServiceKey).to(CompanyManagementService);
  bind(IPredecessorServiceKey).to(PredecessorService);
  bind(IReorganisationMemberServiceKey).to(ReorganisationMemberService);
  bind(ISuccessorServiceKey).to(SuccessorService);
}
