import { injectable, inject } from 'inversify';

import { LegalEntitiesReportAnalysisOfAbsoluteLiquidityIndicator } from '~/types/legal-entities/reportAnalysisOfAbsoluteLiquidityIndicator';
import { IPrimitiveTypesNormalizationServiceKey, IPrimitiveTypesNormalizationService } from '../common/primitiveTypesNormalizationService.interfaces';
import { INormalizationService } from '../common/reportNormalizationService.interfaces';

@injectable()
export class LegalEntitiesReportAnalysisOfAbsoluteLiquidityIndicatorNormalizationService implements INormalizationService<LegalEntitiesReportAnalysisOfAbsoluteLiquidityIndicator> {
  constructor(
    @inject(IPrimitiveTypesNormalizationServiceKey as symbol) private primitiveTypesNormalizationService: IPrimitiveTypesNormalizationService,
  ) {}

  normalize(data: any, root: string): LegalEntitiesReportAnalysisOfAbsoluteLiquidityIndicator | undefined {
    const { normalizeOptionalBoolean, normalizeOptionalInteger, normalizeObject } = this.primitiveTypesNormalizationService;

    return normalizeObject(data, (data) => ({
      AssetsValue: normalizeOptionalInteger(data.AssetsValue, `${root}.AssetsValue`),
      LiabilitiesValue: normalizeOptionalInteger(data.LiabilitiesValue, `${root}.LiabilitiesValue`),
      ActualRatio: normalizeOptionalBoolean(data.ActualRatio, `${root}.ActualRatio`),
    }), root);
  }
}
