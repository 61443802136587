import { InjectionKey } from 'vue';
import { PhoneNumbersDataBlobData } from '~/types/blobs/phone-numbers-data-blob-data';
import { PhoneNumberPhoneCommon } from '~/types/phone-number/phoneCommon';
import { PhoneNumberRelatedPhonePerson } from '~/types/phone-number/relatedPhonePerson';
import { PhoneNumberRelatedPhonePersonCommon } from '~/types/phone-number/relatedPhonePersonCommon';
import { INormalizationService } from '../common/reportNormalizationService.interfaces';

export const IPhoneNumbersDataBlobDataNormalizationServiceKey: InjectionKey<INormalizationService<PhoneNumbersDataBlobData>> = Symbol('INormalizationService<PhoneNumbersDataBlobData>');
export const IPhoneNumberPhoneCommonNormalizationServiceKey: InjectionKey<INormalizationService<PhoneNumberPhoneCommon>> = Symbol('INormalizationService<PhoneNumberPhoneCommon>');
export const IPhoneNumberRelatedPhonePersonNormalizationServiceKey: InjectionKey<INormalizationService<PhoneNumberRelatedPhonePerson>> = Symbol('INormalizationService<PhoneNumberRelatedPhonePerson>');
export const IPhoneNumberRelatedPhonePersonCommonNormalizationServiceKey: InjectionKey<INormalizationService<PhoneNumberRelatedPhonePersonCommon>> = Symbol('INormalizationService<PhoneNumberRelatedPhonePersonCommon>');
