import {
  IReportUrlNormalizationServiceKey,
  IReportMainEntityFileNormalizationServiceKey,
  IReportLegalEntitiesParticipationNormalizationServiceKey,
  IReportVehicleOwnershipNormalizationServiceKey,
  IReportVehicleRelationNormalizationServiceKey,
  IReportVehicleInsuranceNormalizationServiceKey,
  IReportPhotosUrlNormalizationServiceKey,
  IReportVehicleRestrictNormalizationServiceKey,
  IReportMainEntityFileUrlNormalizationServiceKey,
  IReportRoadAccidentNormalizationServiceKey,
  IReportWantedCarNormalizationServiceKey,
  IReportVehiclePhoneNormalizationServiceKey,
  IReportPledgesRegistryNormalizationServiceKey,
  IReportVehicleRisksNormalizationServiceKey,
  IReportVehicleNormalizationServiceKey,
  IReportMentionNormalizationServiceKey,
  IReportVehicleParkingNormalizationServiceKey,
  IStringArrayNormalizationServiceKey,
  IReportMarkerNormalizationServiceKey,
  IReportAnalysisOfAbsoluteLiquidityNormalizationServiceKey,
  IReportIndicatorsOfCashFlowNormalizationServiceKey,
  IReportIndicatorsOfProfitabilityNormalizationServiceKey,
  IReportFineNormalizationServiceKey,
  IReportMovementCovidNormalizationServiceKey,
  IReportSocialNetworkTrackNormalizationServiceKey,
  IReportSocialNetworkSchoolNormalizationServiceKey,
  IReportSocialNetworkSecondaryEducationNormalizationServiceKey,
  IReportSocialNetworkHighEducationNormalizationServiceKey,
  IReportSocialNetworkMilitaryRecordNormalizationServiceKey,
  IReportSocialNetworkJobNormalizationServiceKey,
  IReportPersonInnNormalizationServiceKey,
  IReportPersonSnilsNormalizationServiceKey,
  IReportPassportNormalizationServiceKey,
  IReportBoundedPersonPhoneNormalizationServiceKey,
  IReportTagNormalizationServiceKey,
  IReportEmailNormalizationServiceKey,
  IReportPersonSiteNormalizationServiceKey,
  IReportSocialNetworkIdNormalizationServiceKey,
  IReportEducationNormalizationServiceKey,
  IReportJobNormalizationServiceKey,
  IReportPaymentNormalizationServiceKey,
  IReportColleagueNormalizationServiceKey,
  IReportIndividualEntrepreneurNormalizationServiceKey,
  IReportPossibleFullNameNormalizationServiceKey,
  IReportPossibleBirthdaysNormalizationServiceKey,
  IReportMovementTaxiNormalizationServiceKey,
  IReportMessageBoardRecordNormalizationServiceKey,
  IReportMovementCovidForVehicleNormalizationServiceKey,
  IReportMessengerInfoNormalizationServiceKey,
  IReportVettingInfoNormalizationServiceKey,
  IReportConnectionRelatedNormalizationServiceKey,
  IReportConnectionRelatedChainNormalizationServiceKey,
  IReportConnectionRelatedEntityNormalizationServiceKey,
  IReportConnectionRelatedRelationNormalizationServiceKey,
  IReportConnectionRelatedEntityDisplayNormalizationServiceKey, IReportAddressNormalizationServiceKey, IReportSocialNetworkAboutInfoNormalizationServiceKey, IReportSocialNetworkLifePositionNormalizationServiceKey, IReportSocialNetworkNormalizationServiceKey, IReportSocialNetworkOtherPersonNormalizationServiceKey,
} from '~/services/normalization/common/reportNormalizationService.interfaces';
import { PrimitiveTypesNormalizationService } from '~/services/normalization/common/primitiveTypesNormalizationService';
import { IPrimitiveTypesNormalizationServiceKey } from '~/services/normalization/common/primitiveTypesNormalizationService.interfaces';
import { ReportLegalEntitiesParticipationNormalizationService } from '~/services/normalization/common/reportLegalEntitiesParticipationNormalizationService';
import { ReportMainEntityFileNormalizationService } from '~/services/normalization/common/reportMainEntityFileNormalizationService';
import { ReportMainEntityFileUrlNormalizationService } from '~/services/normalization/common/reportMainEntityFileUrlNormalizationService';
import { ReportMentionNormalizationService } from '~/services/normalization/common/reportMentionNormalizationService';
import { ReportPhotosUrlNormalizationService } from '~/services/normalization/common/reportPhotosUrlNormalizationService';
import { ReportPledgesRegistryNormalizationService } from '~/services/normalization/common/reportPledgesRegistryNormalizationService';
import { ReportRoadAccidentNormalizationService } from '~/services/normalization/common/reportRoadAccidentNormalizationService';
import { ReportUrlNormalizationService } from '~/services/normalization/common/reportUrlNormalizationService';
import { ReportVehicleInsuranceNormalizationService } from '~/services/normalization/common/reportVehicleInsuranceNormalizationService';
import { ReportVehicleNormalizationService } from '~/services/normalization/common/reportVehicleNormalizationService';
import { ReportVehicleOwnershipNormalizationService } from '~/services/normalization/common/reportVehicleOwnershipNormalizationService';
import { ReportVehicleParkingNormalizationService } from '~/services/normalization/common/reportVehicleParkingNormalizationService';
import { ReportVehiclePhoneNormalizationService } from '~/services/normalization/common/reportVehiclePhoneNormalizationService';
import { ReportVehicleRelationNormalizationService } from '~/services/normalization/common/reportVehicleRelationNormalizationService';
import { ReportVehicleRestrictNormalizationService } from '~/services/normalization/common/reportVehicleRestrictNormalizationService';
import { ReportVehicleRisksNormalizationService } from '~/services/normalization/common/reportVehicleRisksNormalizationService';
import { ReportWantedCarNormalizationService } from '~/services/normalization/common/reportWantedCarNormalizationService';
import { StringArrayNormalizationService } from '~/services/normalization/common/stringArrayServiceNormalizeService';
import { ReportMarkerNormalizationService } from '~/services/normalization/common/reportMarkerNormalizationService';
import { ReportAnalysisOfAbsoluteLiquidityNormalizationService } from '~/services/normalization/common/reportAnalysisOfAbsoluteLiquidityNormalizationService';
import { ReportIndicatorsOfCashFlowNormalizationService } from '~/services/normalization/common/reportIndicatorsOfCashFlowNormalizationService';
import { ReportIndicatorsOfProfitabilityNormalizationService } from '~/services/normalization/common/reportIndicatorsOfProfitabilityNormalizationService';
import { IContainerBinder } from './containerAccessor.interface';
import { ReportSocialNetworkTrackNormalizationService } from '~/services/normalization/common/reportSocialNetworkTrackNormalizationService';
import { ReportSocialNetworkSchoolNormalizationService } from '~/services/normalization/common/reportSocialNetworkSchoolNormalizationService';
import { ReportSocialNetworkSecondaryEducationNormalizationService } from '~/services/normalization/common/reportSocialNetworkSecondaryEducationNormalizationService';
import { ReportSocialNetworkHighEducationNormalizationService } from '~/services/normalization/common/reportSocialNetworkHighEducationNormalizationService';
import { ReportSocialNetworkMilitaryRecordNormalizationService } from '~/services/normalization/common/reportSocialNetworkMilitaryRecordNormalizationService';
import { ReportSocialNetworkJobNormalizationService } from '~/services/normalization/common/reportSocialNetworkJobNormalizationService';
import { ReportPersonInnNormalizationService } from '~/services/normalization/common/reportPersonInnNormalizationService';
import { ReportPersonSnilsNormalizationService } from '~/services/normalization/common/reportPersonSnilsNormalizationService';
import { ReportPassportNormalizationService } from '~/services/normalization/common/reportPassportNormalizationService';
import { ReportBoundedPersonPhoneNormalizationService } from '~/services/normalization/common/reportBoundedPersonPhoneNormalizationService';
import { ReportTagNormalizationService } from '~/services/normalization/common/reportTagNormalizationService';
import { ReportEmailNormalizationService } from '~/services/normalization/common/reportEmailNormalizationService';
import { ReportPersonSiteNormalizationService } from '~/services/normalization/common/reportPersonSiteNormalizationService';
import { ReportSocialNetworkIdNormalizationService } from '~/services/normalization/common/reportSocialNetworkIdNormalizationService';
import { ReportEducationNormalizationService } from '~/services/normalization/common/reportEducationNormalizationService';
import { ReportJobNormalizationService } from '~/services/normalization/common/reportJobNormalizationService';
import { ReportPaymentNormalizationService } from '~/services/normalization/common/reportPaymentNormalizationService';
import { ReportColleagueNormalizationService } from '~/services/normalization/common/reportColleagueNormalizationService';
import { ReportIndividualEntrepreneurNormalizationService } from '~/services/normalization/common/reportIndividualEntrepreneurNormalizationService';
import { ReportFineNormalizationService } from '~/services/normalization/common/reportFineNormalizationService';
import { ReportPossibleFullNameNormalizationService } from '~/services/normalization/common/reportPossibleFullNameNormalizationService';
import { ReportPossibleBirthdaysNormalizationService } from '~/services/normalization/common/reportPossibleBirthdaysNormalizationService';
import { ReportMovementCovidNormalizationService } from '~/services/normalization/common/reportMovementCovidNormalizationService';
import { ReportMovementTaxiNormalizationService } from '~/services/normalization/common/reportMovementTaxiNormalizationService';
import { ReportMessageBoardRecordNormalizationService } from '~/services/normalization/common/reportMessageBoardRecordNormalizationService';
import { ReportMovementCovidForVehicleNormalizationService } from '~/services/normalization/common/reportMovementCovidForVehicleNormalizationService';
import { ReportMessengerInfoNormalizationService } from '~/services/normalization/common/reportMessengerInfoNormalizationService';
import { ReportVettingInfoNormalizationService } from '~/services/normalization/common/reportVettingInfoNormalizationService';
import { ReportConnectionRelatedNormalizationService } from '~/services/normalization/common/reportConnectionRelatedNormalizationService';
import { ReportConnectionRelatedChainNormalizationsService } from '~/services/normalization/common/reportConnectionRelatedChainNormalizationsService';
import { ReportConnectionRelatedEntityNormalizationService } from '~/services/normalization/common/reportConnectionRelatedEntityNormalizationService';
import { ReportConnectionRelatedEntityDisplayNormalizationService } from '~/services/normalization/common/reportConnectionRelatedEntityDisplayNormalizationService';
import { ReportConnectionRelatedRelationNormalizationService } from '~/services/normalization/common/reportConnectionRelatedRelationNormalizationService';
import { ReportAddressNormalizationService } from '~/services/normalization/common/reportAddressNormalizationService';
import { ReportSocialNetworkAboutInfoNormalizationService } from '~/services/normalization/common/reportSocialNetworkAboutInfoNormalizationService';
import { ReportSocialNetworkLifePositionNormalizationService } from '~/services/normalization/common/reportSocialNetworkLifePositionNormalizationService';
import { ReportSocialNetworkNormalizationService } from '~/services/normalization/common/reportSocialNetworkNormalizationService';
import { ReportSocialNetworkOtherPersonNormalizationService } from '~/services/normalization/common/reportSocialNetworkOtherPersonNormalizationService';

export function bindCommonNormalizationServices(bind: IContainerBinder) {
  bind(IReportUrlNormalizationServiceKey).to(ReportUrlNormalizationService);
  bind(IReportMainEntityFileNormalizationServiceKey).to(ReportMainEntityFileNormalizationService);
  bind(IReportLegalEntitiesParticipationNormalizationServiceKey).to(ReportLegalEntitiesParticipationNormalizationService);
  bind(IReportVehicleOwnershipNormalizationServiceKey).to(ReportVehicleOwnershipNormalizationService);
  bind(IReportVehicleRelationNormalizationServiceKey).to(ReportVehicleRelationNormalizationService);
  bind(IReportVehicleInsuranceNormalizationServiceKey).to(ReportVehicleInsuranceNormalizationService);
  bind(IReportPhotosUrlNormalizationServiceKey).to(ReportPhotosUrlNormalizationService);
  bind(IReportVehicleRestrictNormalizationServiceKey).to(ReportVehicleRestrictNormalizationService);
  bind(IReportMainEntityFileUrlNormalizationServiceKey).to(ReportMainEntityFileUrlNormalizationService);
  bind(IReportRoadAccidentNormalizationServiceKey).to(ReportRoadAccidentNormalizationService);
  bind(IReportWantedCarNormalizationServiceKey).to(ReportWantedCarNormalizationService);
  bind(IReportVehiclePhoneNormalizationServiceKey).to(ReportVehiclePhoneNormalizationService);
  bind(IReportPledgesRegistryNormalizationServiceKey).to(ReportPledgesRegistryNormalizationService);
  bind(IReportVehicleRisksNormalizationServiceKey).to(ReportVehicleRisksNormalizationService);
  bind(IReportVehicleNormalizationServiceKey).to(ReportVehicleNormalizationService);
  bind(IReportMentionNormalizationServiceKey).to(ReportMentionNormalizationService);
  bind(IReportVehicleParkingNormalizationServiceKey).to(ReportVehicleParkingNormalizationService);
  bind(IReportMarkerNormalizationServiceKey).to(ReportMarkerNormalizationService);
  bind(IReportAnalysisOfAbsoluteLiquidityNormalizationServiceKey).to(ReportAnalysisOfAbsoluteLiquidityNormalizationService);
  bind(IReportIndicatorsOfCashFlowNormalizationServiceKey).to(ReportIndicatorsOfCashFlowNormalizationService);
  bind(IReportIndicatorsOfProfitabilityNormalizationServiceKey).to(ReportIndicatorsOfProfitabilityNormalizationService);
  bind(IReportSocialNetworkTrackNormalizationServiceKey).to(ReportSocialNetworkTrackNormalizationService);
  bind(IReportSocialNetworkSchoolNormalizationServiceKey).to(ReportSocialNetworkSchoolNormalizationService);
  bind(IReportSocialNetworkSecondaryEducationNormalizationServiceKey).to(ReportSocialNetworkSecondaryEducationNormalizationService);
  bind(IReportSocialNetworkHighEducationNormalizationServiceKey).to(ReportSocialNetworkHighEducationNormalizationService);
  bind(IReportSocialNetworkMilitaryRecordNormalizationServiceKey).to(ReportSocialNetworkMilitaryRecordNormalizationService);
  bind(IReportSocialNetworkJobNormalizationServiceKey).to(ReportSocialNetworkJobNormalizationService);
  bind(IReportPersonInnNormalizationServiceKey).to(ReportPersonInnNormalizationService);
  bind(IReportPersonSnilsNormalizationServiceKey).to(ReportPersonSnilsNormalizationService);
  bind(IReportPassportNormalizationServiceKey).to(ReportPassportNormalizationService);
  bind(IReportBoundedPersonPhoneNormalizationServiceKey).to(ReportBoundedPersonPhoneNormalizationService);
  bind(IReportTagNormalizationServiceKey).to(ReportTagNormalizationService);
  bind(IReportEmailNormalizationServiceKey).to(ReportEmailNormalizationService);
  bind(IReportPersonSiteNormalizationServiceKey).to(ReportPersonSiteNormalizationService);
  bind(IReportSocialNetworkIdNormalizationServiceKey).to(ReportSocialNetworkIdNormalizationService);
  bind(IReportEducationNormalizationServiceKey).to(ReportEducationNormalizationService);
  bind(IReportJobNormalizationServiceKey).to(ReportJobNormalizationService);
  bind(IReportPaymentNormalizationServiceKey).to(ReportPaymentNormalizationService);
  bind(IReportColleagueNormalizationServiceKey).to(ReportColleagueNormalizationService);
  bind(IReportIndividualEntrepreneurNormalizationServiceKey).to(ReportIndividualEntrepreneurNormalizationService);
  bind(IReportFineNormalizationServiceKey).to(ReportFineNormalizationService);
  bind(IReportPossibleFullNameNormalizationServiceKey).to(ReportPossibleFullNameNormalizationService);
  bind(IReportPossibleBirthdaysNormalizationServiceKey).to(ReportPossibleBirthdaysNormalizationService);
  bind(IReportMovementCovidNormalizationServiceKey).to(ReportMovementCovidNormalizationService);
  bind(IReportMovementTaxiNormalizationServiceKey).to(ReportMovementTaxiNormalizationService);
  bind(IReportMessageBoardRecordNormalizationServiceKey).to(ReportMessageBoardRecordNormalizationService);
  bind(IReportMovementCovidForVehicleNormalizationServiceKey).to(ReportMovementCovidForVehicleNormalizationService);
  bind(IReportMessengerInfoNormalizationServiceKey).to(ReportMessengerInfoNormalizationService);
  bind(IReportVettingInfoNormalizationServiceKey).to(ReportVettingInfoNormalizationService);
  bind(IReportConnectionRelatedNormalizationServiceKey).to(ReportConnectionRelatedNormalizationService);
  bind(IReportConnectionRelatedChainNormalizationServiceKey).to(ReportConnectionRelatedChainNormalizationsService);
  bind(IReportConnectionRelatedEntityNormalizationServiceKey).to(ReportConnectionRelatedEntityNormalizationService);
  bind(IReportConnectionRelatedEntityDisplayNormalizationServiceKey).to(ReportConnectionRelatedEntityDisplayNormalizationService);
  bind(IReportConnectionRelatedRelationNormalizationServiceKey).to(ReportConnectionRelatedRelationNormalizationService);
  bind(IReportAddressNormalizationServiceKey).to(ReportAddressNormalizationService);
  bind(IReportSocialNetworkAboutInfoNormalizationServiceKey).to(ReportSocialNetworkAboutInfoNormalizationService);
  bind(IReportSocialNetworkLifePositionNormalizationServiceKey).to(ReportSocialNetworkLifePositionNormalizationService);
  bind(IReportSocialNetworkNormalizationServiceKey).to(ReportSocialNetworkNormalizationService);
  bind(IReportSocialNetworkOtherPersonNormalizationServiceKey).to(ReportSocialNetworkOtherPersonNormalizationService);

  bind(IPrimitiveTypesNormalizationServiceKey).to(PrimitiveTypesNormalizationService);
  bind(IStringArrayNormalizationServiceKey).to(StringArrayNormalizationService);
}
