import { injectable } from 'inversify';

import { LegalEntitiesPerson } from '~/types/legal-entities/person';
import { IPlaceholderDataService } from '../common/reportPlaceholderService.interfaces';

@injectable()
export class LegalEntitiesPersonPlaceholderDataService implements IPlaceholderDataService<LegalEntitiesPerson> {
  getData(): LegalEntitiesPerson {
    return {
      Fio: '',
      Role: '',
      CompanyName: '',
    };
  }
}
