import { injectable } from "inversify";
import { IReportVehicleRisksAdapterService } from "./reportAdapterService.interfeces";
import { ReportVehicleRisks } from "~/types/common/reportVehicleRisks";
import { ReportVehicleRisksDTO } from "~/types/dto/common/reportVehicleRisksDTO";

@injectable()
export class ReportVehicleRisksAdapterService implements IReportVehicleRisksAdapterService {
  getData(initData: ReportVehicleRisks): ReportVehicleRisksDTO {
    return {
      HasActiveFines: initData.HasActiveFines ?? false,
      HasCanceledFines: initData.HasCanceledFines ?? false,
      HasRoadAccidents: initData.HasRoadAccidents ?? false,
      IsInPledgesRegistry: initData.IsInPledgesRegistry ?? false,
      HasRestricts: initData.HasRestricts ?? false,
    }
  }
}