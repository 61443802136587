import { get, isEqual, orderBy } from 'lodash-es';
import { injectable } from 'inversify';
import { ILegalEntitiesGeneralInformationFirstDateAppointmentServices } from '~/modules/legalEntities/commonInfo/legalEntitiesGeneralInformation/services/legalEntitiesGeneralInformationFirstDateAppointmentServices.interface';
import { LegalEntitiesCompanyHeadFlDTO } from '~/types/dto/legalEntities/companyHeadFlDTO';

@injectable()
export class LegalEntitiesGeneralInformationFirstDateAppointmentServices implements ILegalEntitiesGeneralInformationFirstDateAppointmentServices {
  getFirstDateAppointment(initData: LegalEntitiesCompanyHeadFlDTO, headFl: Array<LegalEntitiesCompanyHeadFlDTO>): string {
    const filteredByInnHistory = headFl.filter((head) => isEqual(head.Innfl, initData.Innfl));

    if (filteredByInnHistory.length > 0) {
      const orderedHistory = orderBy(filteredByInnHistory, ['FirstDate'], ['asc']);
      const dateFromHistory = get(orderedHistory, '0.FirstDate', '');

      return dateFromHistory.length > 0 ? dateFromHistory : initData.FirstDate;
    }

    return initData.FirstDate;
  }
}
