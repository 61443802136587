import { injectable, inject } from 'inversify';
import { IPrimitiveTypesNormalizationServiceKey, IPrimitiveTypesNormalizationService } from '../common/primitiveTypesNormalizationService.interfaces';
import { INormalizationService } from '../common/reportNormalizationService.interfaces';
import { LegalEntitiesCompanyTaxRegimes } from '~/types/legal-entities/companyTaxRegimes';

@injectable()
export class LegalEntitiesCompanyTaxRegimesNormalizationService implements INormalizationService<LegalEntitiesCompanyTaxRegimes> {
  constructor(
    @inject(IPrimitiveTypesNormalizationServiceKey as symbol) private primitiveTypesNormalizationService: IPrimitiveTypesNormalizationService
  ) {}

  normalize(data: any, root: string): LegalEntitiesCompanyTaxRegimes | undefined {
    const {
      normalizeObject,
      normalizeOptionalString,
      normalizeOptionalInteger,
      normalizeBoolean,
      normalizeFieldObject
    } = this.primitiveTypesNormalizationService;

    const normalizeFieldData = normalizeFieldObject(data);

    return normalizeObject(normalizeFieldData, (data) => ({
      IsHistoric: normalizeBoolean(data.IsHistoric, `${root}.IsHistoric`),
      KgnMember: normalizeOptionalString(data.KgnMember, `${root}.KgnMember`),
      TaxRegimeName: normalizeOptionalString(data.TaxRegimeName, `${root}.TaxRegimeName`),
      Year: normalizeOptionalInteger(data.Year, `${root}.Year`)
    }), root);
  }
}
