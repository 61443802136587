import { inject, injectable } from 'inversify';
import {
  IRequisiteOwnerFormatterService
} from '~/components/legalEntities/companyStructure/services/requisiteOwnerFormatterService.interfaces';
import { LegalEntitiesOwnerType } from '~/types/legal-entities/ownerType';
import {
  ICompanyInnRequisiteMatchingService,
  ICompanyInnRequisiteMatchingServiceKey
} from '~/services/matching/companyInnRequisiteMatchingService.interfaces';
import {
  IIpInnRequisiteMatchingService,
  IIpInnRequisiteMatchingServiceKey
} from '~/services/matching/ipInnRequisiteMatchingService.interfaces';

@injectable()
export class RequisiteOwnerFormatterService implements IRequisiteOwnerFormatterService {
  constructor(
      @inject(ICompanyInnRequisiteMatchingServiceKey as symbol) private companyInnRequisiteMatchingService: ICompanyInnRequisiteMatchingService,
      @inject(IIpInnRequisiteMatchingServiceKey as symbol) private ipInnRequisiteMatchingService: IIpInnRequisiteMatchingService,
  ) {
  }
  getRequisiteType(requisite: string): LegalEntitiesOwnerType {
    const isCompanyInn = this.companyInnRequisiteMatchingService.matchPartial(requisite);
    const isIpInn = this.ipInnRequisiteMatchingService.matchPartial(requisite);

    switch (Boolean(requisite)) {
      case isCompanyInn: return LegalEntitiesOwnerType.legalEntities;
      case isIpInn: return LegalEntitiesOwnerType.individual;
      default: return LegalEntitiesOwnerType.unknown;
    }
  }
}
