import { InjectionKey } from 'vue';
import { LegalEntitiesCompanyFounderDTO } from '~/types/dto/legalEntities/companyFounder';
import { LegalEntitiesCompanyManagementCompaniesDTO } from '~/types/dto/legalEntities/companyManagementCompaniesDTO';
import { LegalEntitiesPredecessor } from '~/types/legal-entities/predecessor';
import { LegalEntitiesReorganisationMember } from '~/types/legal-entities/reorganisationMember';
import { LegalEntitiesSuccessor } from '~/types/legal-entities/successor';
import { LegalEntitiesCompanyHeadFlDTO } from '~/types/dto/legalEntities/companyHeadFlDTO';

export interface IGetIsFirstDate<T> {
  getIsFirstDate(array: Array<T>): Array<T & { isFirstDate: boolean,}>;
}

export type ICompanyFounderService = IGetIsFirstDate<LegalEntitiesCompanyFounderDTO>;
export type ICompanyHeadService = IGetIsFirstDate<LegalEntitiesCompanyHeadFlDTO>;
export type ICompanyManagementService = IGetIsFirstDate<LegalEntitiesCompanyManagementCompaniesDTO>;
export type IPredecessorService = IGetIsFirstDate<LegalEntitiesPredecessor>;
export type IReorganisationMemberService = IGetIsFirstDate<LegalEntitiesReorganisationMember>;
export type ISuccessorService = IGetIsFirstDate<LegalEntitiesSuccessor>;

export const ICompanyFounderServiceKey: InjectionKey<ICompanyFounderService> = Symbol('ICompanyFounderService');
export const ICompanyHeadServiceKey: InjectionKey<ICompanyHeadService> = Symbol('ICompanyHeadService');
export const ICompanyManagementServiceKey: InjectionKey<ICompanyManagementService> = Symbol('ICompanyManagementService');
export const IPredecessorServiceKey: InjectionKey<IPredecessorService> = Symbol('IPredecessorService');
export const IReorganisationMemberServiceKey: InjectionKey<IReorganisationMemberService> = Symbol('IReorganisationMemberService');
export const ISuccessorServiceKey: InjectionKey<ISuccessorService> = Symbol('ISuccessorService');
