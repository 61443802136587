import { injectable } from 'inversify';
import { IIndividualAccrualAdapterService } from '~/services/adapters/individual/individualAdapterService.interfaces';
import { IndividualAccrual } from '~/types/individual/accrual';
import { IndividualAccrualDTO } from '~/types/dto/individual/accrualDTO';
import { StringUtils } from '~/utils/stringUtils';
import { DateUtils } from '~/utils/date';

@injectable()
export class IndividualAccrualAdapterService implements IIndividualAccrualAdapterService {
  getData(initData: IndividualAccrual[]): IndividualAccrualDTO[] {
    return (initData ?? []).map((item) => ({
      Description: StringUtils.quoteReplacement(item.Description),
      StartDate: DateUtils.formatDate(item.StartDate),
      DebtSum: item.DebtSum ?? 0,
      DebtType: StringUtils.quoteReplacement(item.DebtType)
    }));
  }
}
