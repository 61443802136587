import { injectable } from 'inversify';
import {
  ILegalEntitiesRegistrarOfShareholdersAdapterService
} from '~/services/adapters/legalEntities/legalEntitiesAdapterService.interfaces';
import { LegalEntitiesRegistrarOfShareholders } from '~/types/legal-entities/registrarOfShareholders';
import { LegalEntitiesRegistrarOfShareholdersDTO } from '~/types/dto/legalEntities/registrarOfShareholdersDTO';
import { StringUtils } from '~/utils/stringUtils';

@injectable()
export class LegalEntitiesRegistrarOfShareholdersAdapterService implements ILegalEntitiesRegistrarOfShareholdersAdapterService {
  getData(initData: LegalEntitiesRegistrarOfShareholders): LegalEntitiesRegistrarOfShareholdersDTO {
    return {
      Name: StringUtils.quoteReplacement(initData.Name ?? ''),
      Inn: StringUtils.quoteReplacement(initData.Inn ?? ''),
      Ogrn: initData.Ogrn,
      Date: initData.Date ?? '',
      FirstDate: initData.FirstDate ?? '',
      Status: StringUtils.quoteReplacement(initData.Status ?? ''),
    };
  }
}
