import { DataBlobReportOriginalInputFileInfo } from "~/types/blobs/data-blob-report-original-input-file-info";
import { DataBlobReportOriginalInputFileInfoDTO } from "~/types/dto/common/dataBlobReportOriginalInputFileInfoDTO";
import { IDataBlobReportOriginalInputFileInfoAdapterService } from "./dataBlobReportOriginalInputFileInfoAdapterService.interfaces";
import { injectable } from "inversify";

@injectable()
export class DataBlobReportOriginalInputFileInfoAdapterService implements IDataBlobReportOriginalInputFileInfoAdapterService {
  getData(initData: DataBlobReportOriginalInputFileInfo | undefined): DataBlobReportOriginalInputFileInfoDTO {
    return {
      DataLength: initData?.DataLength ?? 0,
      FileName: initData?.FileName ?? '',
      FileNameWithoutExtension: initData?.FileNameWithoutExtension ?? '',
    }
  }
}