import { injectable, inject } from 'inversify';
import { LegalEntitiesCompanyEmail } from '~/types/legal-entities/companyEmail';
import { IPrimitiveTypesNormalizationServiceKey, IPrimitiveTypesNormalizationService } from '../common/primitiveTypesNormalizationService.interfaces';
import { INormalizationService } from '../common/reportNormalizationService.interfaces';

@injectable()
export class LegalEntitiesCompanyEmailNormalizeService implements INormalizationService<LegalEntitiesCompanyEmail> {
  constructor(
    @inject(IPrimitiveTypesNormalizationServiceKey as symbol) private primitiveTypesNormalizationService: IPrimitiveTypesNormalizationService,
  ) {}

  normalize(data: any, root: string): LegalEntitiesCompanyEmail | undefined {
    const { normalizeNumber, normalizeString, normalizeObject } = this.primitiveTypesNormalizationService;

    return normalizeObject(data, (data) => ({
      Value: normalizeString(data.Value, `${root}.Value`),
      CompaniesWithThisEmail: normalizeNumber(data.CompaniesWithThisEmail, `${root}.CompaniesWithThisEmail`),
    }), root);
  }
}
