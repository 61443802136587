import { InjectionKey } from 'vue';
import { IConfigService } from '~/services/configService.interface';
import { ReportMovementCovidDTO } from '~/types/dto/common/reportMovementCovidDTO';
import { ReportMovementTaxiDTO } from '~/types/dto/common/reportMovementTaxiDTO';

export type IReportCovidMovementsConfigService = IConfigService<ReportMovementCovidDTO>;
export type IReportTaxiMovementsConfigService = IConfigService<ReportMovementTaxiDTO>;

export const IReportCovidMovementsConfigServiceKey: InjectionKey<IReportCovidMovementsConfigService> = Symbol('IReportCovidMovementsConfigService');
export const IReportTaxiMovementsConfigServiceKey: InjectionKey<IReportTaxiMovementsConfigService> = Symbol('IReportTaxiMovementsConfigService');
