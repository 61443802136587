import { injectable, inject } from 'inversify';
import { IPlaceholderDataService } from '~/services/placeholder/common/reportPlaceholderService.interfaces';
import { ILegalEntitiesReportFinancialIndicatorWithConclusionsPlaceholderDataServiceKey } from '~/services/placeholder/legalEntities/legalEntitiesPlaceholderDataService.interfaces';

import { LegalEntitiesReportFinancialIndicatorWithConclusions } from '~/types/legal-entities/reportFinancialIndicatorWithConclusions';
import { LegalEntitiesReportFinancialStability } from '~/types/legal-entities/reportFinancialStability';
import { IPrimitiveTypesNormalizationServiceKey, IPrimitiveTypesNormalizationService } from '../common/primitiveTypesNormalizationService.interfaces';
import { INormalizationService } from '../common/reportNormalizationService.interfaces';
import { ILegalEntitiesReportFinancialIndicatorWithConclusionsNormalizationServiceKey } from './legalEntitiesNormalizationService.interfaces';

@injectable()
export class LegalEntitiesReportFinancialStabilityNormalizationService implements INormalizationService<LegalEntitiesReportFinancialStability> {
  constructor(
    @inject(ILegalEntitiesReportFinancialIndicatorWithConclusionsNormalizationServiceKey as symbol) private reportFinancialIndicatorWithConclusionsNormalizationService: INormalizationService<LegalEntitiesReportFinancialIndicatorWithConclusions>,

    @inject(ILegalEntitiesReportFinancialIndicatorWithConclusionsPlaceholderDataServiceKey as symbol) private reportFinancialIndicatorWithConclusionsPlaceholderDataService: IPlaceholderDataService<LegalEntitiesReportFinancialIndicatorWithConclusions>,

    @inject(IPrimitiveTypesNormalizationServiceKey as symbol) private primitiveTypesNormalizationService: IPrimitiveTypesNormalizationService,
  ) {}

  normalize(data: any, root: string): LegalEntitiesReportFinancialStability | undefined {
    const { normalizeObject } = this.primitiveTypesNormalizationService;

    return normalizeObject(data, (data) => ({
      NetAssets: this.reportFinancialIndicatorWithConclusionsNormalizationService.normalize(data.NetAssets, `${root}.NetAssets`) ?? this.reportFinancialIndicatorWithConclusionsPlaceholderDataService.getData(),
      RatioOfConcentrationOfEquity: this.reportFinancialIndicatorWithConclusionsNormalizationService.normalize(data.RatioOfConcentrationOfEquity, `${root}.RatioOfConcentrationOfEquity`) ?? this.reportFinancialIndicatorWithConclusionsPlaceholderDataService.getData(),
      LevelOfFinancialLeverage: this.reportFinancialIndicatorWithConclusionsNormalizationService.normalize(data.LevelOfFinancialLeverage, `${root}.LevelOfFinancialLeverage`) ?? this.reportFinancialIndicatorWithConclusionsPlaceholderDataService.getData(),
      RatioOfSecurityOfInterestToPayment: this.reportFinancialIndicatorWithConclusionsNormalizationService.normalize(data.RatioOfSecurityOfInterestToPayment, `${root}.RatioOfSecurityOfInterestToPayment`) ?? this.reportFinancialIndicatorWithConclusionsPlaceholderDataService.getData(),
      RatioOfTotalDebtToProfitFromSales: this.reportFinancialIndicatorWithConclusionsNormalizationService.normalize(data.RatioOfTotalDebtToProfitFromSales, `${root}.RatioOfTotalDebtToProfitFromSales`) ?? this.reportFinancialIndicatorWithConclusionsPlaceholderDataService.getData(),
    }), root);
  }
}
