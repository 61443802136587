import { injectable, inject } from 'inversify';

import { LegalEntitiesReportFinancialIndicatorWithConclusions } from '~/types/legal-entities/reportFinancialIndicatorWithConclusions';
import { LegalEntitiesReportFinancialIndicatorWithDynamic } from '~/types/legal-entities/reportFinancialIndicatorWithDynamic';
import { IPlaceholderDataService } from '../common/reportPlaceholderService.interfaces';
import { ILegalEntitiesReportFinancialIndicatorWithConclusionsPlaceholderDataServiceKey } from './legalEntitiesPlaceholderDataService.interfaces';

@injectable()
export class LegalEntitiesReportFinancialIndicatorWithDynamicPlaceholderDataService implements IPlaceholderDataService<LegalEntitiesReportFinancialIndicatorWithDynamic> {
  constructor(
    @inject(ILegalEntitiesReportFinancialIndicatorWithConclusionsPlaceholderDataServiceKey as symbol) private reportFinancialIndicatorWithConclusionsPlaceholderDataService: IPlaceholderDataService<LegalEntitiesReportFinancialIndicatorWithConclusions>,
  ) {}

  getData(): LegalEntitiesReportFinancialIndicatorWithDynamic {
    return {
      ...this.reportFinancialIndicatorWithConclusionsPlaceholderDataService.getData(),
      TwoYearsAgoToLastYear: 0,
      LastYearToCurrentYear: 0,
    };
  }
}
