import { injectable, inject } from 'inversify';

import { LegalEntitiesReportDataBuhForm } from '~/types/legal-entities/reportDataBuhForm';
import { LegalEntitiesReportDataBuhFormDetail } from '~/types/legal-entities/reportDataBuhFormDetail';
import { IPrimitiveTypesNormalizationServiceKey, IPrimitiveTypesNormalizationService } from '../common/primitiveTypesNormalizationService.interfaces';
import { INormalizationService } from '../common/reportNormalizationService.interfaces';
import { ILegalEntitiesReportDataBuhFormDetailNormalizationServiceKey } from './legalEntitiesNormalizationService.interfaces';

@injectable()
export class LegalEntitiesReportDataBuhFormNormalizationService implements INormalizationService<LegalEntitiesReportDataBuhForm> {
  constructor(
    @inject(ILegalEntitiesReportDataBuhFormDetailNormalizationServiceKey as symbol) private reportDataBuhFormDetailNormalizationService: INormalizationService<LegalEntitiesReportDataBuhFormDetail>,
    @inject(IPrimitiveTypesNormalizationServiceKey as symbol) private primitiveTypesNormalizationService: IPrimitiveTypesNormalizationService,
  ) {}

  normalize(data: any, root: string): LegalEntitiesReportDataBuhForm | undefined {
    const {
      normalizeNumber,
      normalizeString,
      normalizeArray,
      normalizeObject
    } = this.primitiveTypesNormalizationService;

    return normalizeObject(data, (data) => ({
      Year: normalizeNumber(data.Year, `${root}.Year`),
      OrganizationType: normalizeString(data.OrganizationType, `${root}.OrganizationType`),
      Form1: normalizeArray(data.Form1, (item, field) => this.reportDataBuhFormDetailNormalizationService.normalize(item, field), `${root}.Form1`),
      Form2: normalizeArray(data.Form2, (item, field) => this.reportDataBuhFormDetailNormalizationService.normalize(item, field), `${root}.Form2`),
    }), root);
  }
}
