import { injectable, inject } from 'inversify';
import { IPlaceholderDataService } from '~/services/placeholder/common/reportPlaceholderService.interfaces';
import { ILegalEntitiesPersonPlaceholderDataServiceKey } from '~/services/placeholder/legalEntities/legalEntitiesPlaceholderDataService.interfaces';
import { LegalEntitiesConflictSign } from '~/types/legal-entities/conflictSign';
import { LegalEntitiesPerson } from '~/types/legal-entities/person';
import { LegalEntitiesRelatedReason } from '~/types/legal-entities/relatedReason';
import { IPrimitiveTypesNormalizationServiceKey, IPrimitiveTypesNormalizationService } from '../common/primitiveTypesNormalizationService.interfaces';
import { INormalizationService } from '../common/reportNormalizationService.interfaces';
import { ILegalEntitiesPersonNormalizationServiceKey, ILegalEntitiesRelatedReasonNormalizationServiceKey } from './legalEntitiesNormalizationService.interfaces';

@injectable()
export class LegalEntitiesConflictSignNormalizationService implements INormalizationService<LegalEntitiesConflictSign> {
  constructor(
    @inject(ILegalEntitiesPersonNormalizationServiceKey as symbol) private personNormalizationService: INormalizationService<LegalEntitiesPerson>,
    @inject(ILegalEntitiesRelatedReasonNormalizationServiceKey as symbol) private relatedReasonNormalizationService: INormalizationService<LegalEntitiesRelatedReason>,

    @inject(ILegalEntitiesPersonPlaceholderDataServiceKey as symbol) private personPlaceholderDataService: IPlaceholderDataService<LegalEntitiesPerson>,

    @inject(IPrimitiveTypesNormalizationServiceKey as symbol) private primitiveTypesNormalizationService: IPrimitiveTypesNormalizationService,
  ) {}

  normalize(data: any, root: string): LegalEntitiesConflictSign | undefined {
    const { normalizeArray, normalizeObject } = this.primitiveTypesNormalizationService;

    return normalizeObject(data, (data) => ({
      Person1: this.personNormalizationService.normalize(data.Person1, `${root}.Person1`) ?? this.personPlaceholderDataService.getData(),
      Person2: this.personNormalizationService.normalize(data.Person2, `${root}.Person2`) ?? this.personPlaceholderDataService.getData(),
      Reasons: normalizeArray(data.Reasons, (item, field) => this.relatedReasonNormalizationService.normalize(item, field), `${root}.Reasons`)
    }), root);
  }
}
