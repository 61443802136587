import { injectable } from 'inversify';
import { get } from 'lodash-es';
import { IDebtServiceCoverageRatioTableItemConfig } from '~/modules/legalEntities/financialAnalytics/legalEntitiesCashFlowPerformance/services/legalEntitiesCashFlowPerformanceTableItemConfig.interfaces';
import { TableItem } from '~/modules/table/types/tableItem';
import { LegalEntitiesReportFinancialIndicatorWithDynamicDTO } from '~/types/dto/legalEntities/reportFinancialIndicatorWithDynamicDTO';
import { NumberUtils } from '~/utils/number';
import { INDICATORS_OF_CASH_FLOW } from '~/modules/legalEntities/financialAnalytics/common/configuration/constants/indicatorsOfCashFlow';

@injectable()
export class DebtServiceCoverageRatioTableItemConfig implements IDebtServiceCoverageRatioTableItemConfig {
  getItem(initData: LegalEntitiesReportFinancialIndicatorWithDynamicDTO): TableItem {
    return ({
      TwoYearsAgo: { value: NumberUtils.formatNumberDigits(initData.TwoYearsAgo, 2, '—'), color: this.getColor(initData.TwoYearsAgo) },
      CurrentYear: { value: NumberUtils.formatNumberDigits(initData.CurrentYear, 2, '—'), color: this.getColor(initData.CurrentYear) },
      LastYear: { value: NumberUtils.formatNumberDigits(initData.LastYear, 2, '—'), color: this.getColor(initData.LastYear) },
      TwoYearsAgoToLastYear: { value: NumberUtils.formatNumberDigits(initData.TwoYearsAgoToLastYear, 2, '—'), color: this.getColor(initData.TwoYearsAgoToLastYear ?? 2) },
      LastYearToCurrentYear: { value: NumberUtils.formatNumberDigits(initData.LastYearToCurrentYear, 2, '—'), color: this.getColor(initData.LastYearToCurrentYear ?? 2) },
      Conclusions: get(initData.Conclusions, 0, '—'),
      indicator: INDICATORS_OF_CASH_FLOW.DebtServiceCoverageRatio,
    });
  }

  private getColor(value: number) {
    return value < 1.1 ? 'red' : 'black';
  }
}
