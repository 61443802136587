import { injectable } from 'inversify';

import { ReportSocialNetworkSecondaryEducation } from '~/types/common/reportSocialNetworkSecondaryEducation';
import { IPlaceholderDataService } from './reportPlaceholderService.interfaces';

@injectable()
export class ReportSocialNetworkSecondaryEducationPlaceholderService implements IPlaceholderDataService<ReportSocialNetworkSecondaryEducation> {
  getData(): ReportSocialNetworkSecondaryEducation {
    return {
      Type: 0,
      SocialNetworkId: '',
      LastName: '',
      FirstName: '',
      Birthday: '',
      EducationName: '',
      SpecialtyName: '',
      StartDate: '',
      DiplomaDate: '',
    };
  }
}
