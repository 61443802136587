import { injectable } from 'inversify';

import { LegalEntitiesReportDataBuhFormDetail } from '~/types/legal-entities/reportDataBuhFormDetail';
import { IPlaceholderDataService } from '../common/reportPlaceholderService.interfaces';

@injectable()
export class LegalEntitiesReportDataBuhFormDetailPlaceholderDataService implements IPlaceholderDataService<LegalEntitiesReportDataBuhFormDetail> {
  getData(): LegalEntitiesReportDataBuhFormDetail {
    return {
      Code: 0,
      Name: '',
      StartValue: 0,
      EndValue: 0,
    };
  }
}
