import { injectable } from 'inversify';
import { ReportFineDTO } from '~/types/dto/common/fineDTO';
import { Config } from '~/types/field-list/config';
import { NumberUtils } from '~/utils/number';
import { FinesUtils } from '~/modules/reportsCommon/reportFine/utils/finesUtils';
import { IFineGeneralInfoConfigService } from '~/modules/reportsCommon/reportFine/services/fines.interfaces';

@injectable()
export class FineGeneralInfoConfigService implements IFineGeneralInfoConfigService {
  getConfig(initData: Array<ReportFineDTO>): Config {
    const totalSum = FinesUtils.getTotalSum(initData);
    const restFinesCount = initData.filter(fine => fine.RestSum !== 0).length;

    const paidFinesCount = initData.length - restFinesCount;
    const totalAmount = NumberUtils.formatNumberDigits(totalSum);

    return {
      fieldsWidth: '100%',
      fieldsDirection: 'divider-row',
      fieldsValueWidth: '350px',
      isBeginningDivider: true,
      fields: [
        {
          label: 'Найденных штрафов',
          key: 'FinesCount',
          value: initData.length,
        },
        {
          label: 'Оплачено штрафов',
          key: 'Paid',
          value: paidFinesCount,
        },
        {
          label: 'Не оплачено штрафов',
          key: 'NotPaid',
          value: restFinesCount,
        },
        {
          label: 'Общая сумма, ₽',
          key: 'TotalAmount',
          value: totalAmount,
        }
      ].filter((item) => item.value),
    };
  }
}
