import { inject, injectable } from 'inversify';
import {
  IPrimitiveTypesNormalizationService,
  IPrimitiveTypesNormalizationServiceKey
} from './primitiveTypesNormalizationService.interfaces';
import { INormalizationService } from './reportNormalizationService.interfaces';
import { ReportConnectionRelatedEntityDisplay } from '~/types/common/reportConnectionRelatedEntityDisplay';

@injectable()
export class ReportConnectionRelatedEntityDisplayNormalizationService implements INormalizationService<ReportConnectionRelatedEntityDisplay> {
  constructor(
    @inject(IPrimitiveTypesNormalizationServiceKey as symbol) private primitiveTypesNormalizationService: IPrimitiveTypesNormalizationService,
  ) {}

  normalize(data: any, root: string): ReportConnectionRelatedEntityDisplay | undefined {
    const {
      normalizeOptionalString,
      normalizeObject
    } = this.primitiveTypesNormalizationService;

    return normalizeObject(data, (data) => ({
      Company: normalizeOptionalString(data.Company, `${root}.Company`),
      Position: normalizeOptionalString(data.Position, `${root}.Position`),
      Department: normalizeOptionalString(data.Department, `${root}.Department`),
    }), root);
  }
}
