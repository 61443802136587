import { injectable } from 'inversify';

import { LegalEntitiesReportAverageIndustryIndicator } from '~/types/legal-entities/reportAverageIndustryIndicator';
import { IPlaceholderDataService } from '../common/reportPlaceholderService.interfaces';

@injectable()
export class LegalEntitiesReportAverageIndustryIndicatorPlaceholderDataService implements IPlaceholderDataService<LegalEntitiesReportAverageIndustryIndicator> {
  getData(): LegalEntitiesReportAverageIndustryIndicator {
    return {
      CurrentYear: 0,
      AverageIndustry: 0,
      Deviation: 0,
    };
  }
}
