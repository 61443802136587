import { IContainerBinder } from '~/configuration/inversify/containerAccessor.interface';
import { bindNegativeInformationServices } from '~/modules/individual/negativeInformation/IndividualSpecialSigns/configuration/inversify/container.negativeInformationService';
import { bindLegalEntitiesRestrictionsServices } from '~/modules/individual/negativeInformation/IndividualLegalEntitiesRestrictions/configuration/inversify/container.legalEntitiesRestrictions';
import { bindIndividualEnforcementProceedingConfigServices } from '~/modules/individual/negativeInformation/individualEnforcementProceeding/configuration/inversify/container.individualEnforcementProceedingConfigServices';
import { bindIndividualFamilyServices } from '~/modules/individual/family/individualFamilyList/configuration/inversify/container.individualFamilyServices';
import { bindIndividualEntrepreneursServices } from '~/modules/individual/lifePath/individualEntrepreneurs/configuration/inversify/container.individualEntrepreneurs';
import { bindIndividualTrustScoringServices } from '~/modules/individual/scoringScores/individualTrustScoring/configuration/inversify/container.individualTrustScoring';
import { bindIndividualWantedOtherServices } from '~/modules/individual/negativeInformation/IndividualWantedOther/configurations/inversify/container.individualWantedOtherServices';
import { bindIndividualFinancialScoringServices } from '~/modules/individual/scoringScores/individualFinancialScoring/configuration/inversify/container.individualFinancialScoring';

export function bindIndividualModulesServices(bind: IContainerBinder) {
  bindNegativeInformationServices(bind);
  bindLegalEntitiesRestrictionsServices(bind);
  bindIndividualEnforcementProceedingConfigServices(bind);
  bindIndividualFamilyServices(bind);
  bindIndividualEntrepreneursServices(bind);
  bindIndividualFinancialScoringServices(bind);
  bindIndividualTrustScoringServices(bind);
  bindIndividualWantedOtherServices(bind);
}
