import { LegalEntitiesOwnerType } from '~/types/legal-entities/ownerType';

const TYPE_ANOTHER = 'Другой';
const TYPE_LEGAL_ENTITIES_FULL = 'Юридическое лицо';
const TYPE_INDIVIDUAL_FULL = 'Физическое лицо';

export module LegalEntitiesCompanyFounderUtils {
  export function convertLegalEntitiesFoundersToString(riskIndicator: LegalEntitiesOwnerType): string {
    switch (riskIndicator) {
      case LegalEntitiesOwnerType.legalEntities: return 'Наименование';
      case LegalEntitiesOwnerType.individual: return 'ФИО';
      case LegalEntitiesOwnerType.another:
      default: return 'Другое';
    }
  }

  export function convertStringToLegalEntitiesFoundersType(str: string): LegalEntitiesOwnerType {
    switch (str.toLowerCase()) {
      case TYPE_LEGAL_ENTITIES_FULL.toLowerCase(): return LegalEntitiesOwnerType.legalEntities;
      case TYPE_INDIVIDUAL_FULL.toLowerCase(): return LegalEntitiesOwnerType.individual;
      case TYPE_ANOTHER.toLowerCase(): return LegalEntitiesOwnerType.another;
      default: return LegalEntitiesOwnerType.unknown;
    }
  }
}
