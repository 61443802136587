import { injectable, inject } from 'inversify';
import { IIndividualWantedMvdAdapterService } from '~/services/adapters/individual/individualAdapterService.interfaces';
import {
  IReportMainEntityFileAdapterService,
  IReportMainEntityFileAdapterServiceKey
} from '~/services/adapters/common/reportAdapterService.interfeces';
import { IndividualWantedMvd } from '~/types/individual/wantedMvd';
import { IndividualWantedMvdDTO } from '~/types/dto/individual/wantedMvdDTO';
import { StringUtils } from '~/utils/stringUtils';

@injectable()
export class IndividualWantedMvdAdapterService implements IIndividualWantedMvdAdapterService {
  constructor(
    @inject(IReportMainEntityFileAdapterServiceKey as symbol) private reportMainEntityFileAdapterService: IReportMainEntityFileAdapterService
  ) {}
  getData(initData: IndividualWantedMvd[]): IndividualWantedMvdDTO[] {
    return (initData ?? []).map((item) => ({
      Photo: this.reportMainEntityFileAdapterService.getData(item.Photo),
      Region: StringUtils.quoteReplacement(item.Region),
      Reason: StringUtils.quoteReplacement(item.Reason),
      SettingData: item.SettingData,
      ContactInfo: StringUtils.quoteReplacement(item.ContactInfo)
    }));
  }
}
