import { injectable } from 'inversify';
import {
  IIndividualMaritalStatusHistoryAdapterService
} from '~/services/adapters/individual/individualAdapterService.interfaces';
import { IndividualMaritalStatusHistory } from '~/types/individual/maritalStatusHistory';
import { IndividualMaritalStatusHistoryDTO } from '~/types/dto/individual/maritalStatusHistoryDTO';
import { StringUtils } from '~/utils/stringUtils';

@injectable()
export class IndividualMaritalStatusHistoryAdapterService implements IIndividualMaritalStatusHistoryAdapterService {
  getData(initData: IndividualMaritalStatusHistory[]): IndividualMaritalStatusHistoryDTO[] {
    return (initData ?? []).map((item) => ({
      MaritalStatus: StringUtils.quoteReplacement(item.MaritalStatus),
      ChangeDate: item.ChangeDate ?? ''
    }));
  }
}
