import { injectable } from 'inversify';
import { ReportTag } from '~/types/common/reportTag';
import { IPlaceholderDataService } from './reportPlaceholderService.interfaces';

@injectable()
export class ReportTagPlaceholderService implements IPlaceholderDataService<ReportTag> {
  getData(): ReportTag {
    return {
      Tag: ''
    };
  }
}
