import { ReportTagDTO } from '~/types/dto/common/reportTagDTO';
import { MergeService } from './merge-service';

export class ReportTagMergeService extends MergeService<ReportTagDTO> {
  protected canMerge(leftItem: ReportTagDTO, rightItem: ReportTagDTO): boolean {
    return leftItem.Tag === rightItem.Tag;
  }

  protected mergeFields(leftItem: ReportTagDTO, rightItem: ReportTagDTO): ReportTagDTO {
    return {
      Tag: leftItem.Tag || rightItem.Tag,
    };
  }
}
