import { injectable, inject } from 'inversify';
import { ReportSocialNetworkId } from '~/types/common/reportSocialNetworkId';
import { IPrimitiveTypesNormalizationServiceKey, IPrimitiveTypesNormalizationService } from './primitiveTypesNormalizationService.interfaces';
import { INormalizationService } from './reportNormalizationService.interfaces';

@injectable()
export class ReportSocialNetworkIdNormalizationService implements INormalizationService<ReportSocialNetworkId> {
  constructor(
    @inject(IPrimitiveTypesNormalizationServiceKey as symbol) private primitiveTypesNormalizationService: IPrimitiveTypesNormalizationService,
  ) {}

  normalize(data: any, root: string): ReportSocialNetworkId | undefined {
    const { normalizeObject, normalizeNumber, normalizeString } = this.primitiveTypesNormalizationService;

    return normalizeObject(data, (data) => ({
      Type: normalizeNumber(data.Type, `${root}.Type`),
      SocialId: normalizeString(data.SocialId, `${root}.SocialId`)
    }), root);
  }
}
