import { injectable, inject } from 'inversify';

import { IndividualCommonNegativeInfo } from '~/types/individual/commonNegativeInfo';
import { IndividualSpecialSigns } from '~/types/individual/specialSigns';
import { IPlaceholderDataService } from '../common/reportPlaceholderService.interfaces';
import { IIndividualSpecialSignsPlaceholderServiceKey } from './individualPlaceholderDataService.interfaces';

@injectable()
export class IndividualCommonNegativeInfoPlaceholderService implements IPlaceholderDataService<IndividualCommonNegativeInfo> {
  constructor(
    @inject(IIndividualSpecialSignsPlaceholderServiceKey as symbol) private specialSignsPlaceholderService: IPlaceholderDataService<IndividualSpecialSigns>
  ) {}

  getData(): IndividualCommonNegativeInfo {
    return {
      HasPrejudicedAdministrativePenalties: false,
      HasGunsNegative: false,
      SpecialSigns: this.specialSignsPlaceholderService.getData(),
      IncidentsInfo: [],
      ArchiveEnforcementProceedings: [],
      OuterBlackLists: [],
      SpecialAccounting: [],
      InInnerBlackList: false,
      InterestInNegativeContent: [],
      OtherNegativeRecords: [],
    };
  }
}
