import { injectable } from 'inversify';
import {
  ILegalEntitiesReportAnalysisOfAbsoluteLiquidityIndicatorAdapterService
} from '~/services/adapters/legalEntities/legalEntitiesAdapterService.interfaces';
import {
  LegalEntitiesReportAnalysisOfAbsoluteLiquidityIndicator
} from '~/types/legal-entities/reportAnalysisOfAbsoluteLiquidityIndicator';
import {
  LegalEntitiesReportAnalysisOfAbsoluteLiquidityIndicatorDTO
} from '~/types/dto/legalEntities/reportAnalysisOfAbsoluteLiquidityIndicator';

@injectable()
export class LegalEntitiesReportAnalysisOfAbsoluteLiquidityIndicatorAdapterService implements ILegalEntitiesReportAnalysisOfAbsoluteLiquidityIndicatorAdapterService {
  getData(initData: LegalEntitiesReportAnalysisOfAbsoluteLiquidityIndicator): LegalEntitiesReportAnalysisOfAbsoluteLiquidityIndicatorDTO {
    return {
      AssetsValue: initData.AssetsValue ?? 0,
      LiabilitiesValue: initData.LiabilitiesValue ?? 0,
      ActualRatio: initData.ActualRatio ?? false
    };
  }
}
