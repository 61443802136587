import { injectable } from 'inversify';
import { isEqual } from 'lodash-es';
import { MergeService } from '~/services/merge/merge-service';
import { ReportSocialNetworkDTO } from '~/types/dto/common/reportSocialNetworkDTO';

@injectable()
export class ReportSocialNetworksMergeService extends MergeService<ReportSocialNetworkDTO> {
  protected canMerge(leftItem: ReportSocialNetworkDTO, rightItem: ReportSocialNetworkDTO): boolean {
    return isEqual(leftItem, rightItem);
  }

  protected mergeFields(leftItem: ReportSocialNetworkDTO, rightItem: ReportSocialNetworkDTO): ReportSocialNetworkDTO {
    return {
      Type: leftItem.Type || rightItem.Type,
      SocialId: leftItem.SocialId || rightItem.SocialId,
      FullName: leftItem.FullName || rightItem.FullName,
      Birthday: leftItem.Birthday || rightItem.Birthday,
      IsVerified: leftItem.IsVerified || rightItem.IsVerified,
      IsPrivateAccount: leftItem.IsPrivateAccount || rightItem.IsPrivateAccount,
      LastDevice: leftItem.LastDevice || rightItem.LastDevice,
      Status: leftItem.Status || rightItem.Status,
      Languages: leftItem.Languages || rightItem.Languages,
      Subscribers: leftItem.Subscribers || rightItem.Subscribers,
      Friends: leftItem.Friends || rightItem.Friends,
      WhoLikes: leftItem.WhoLikes || rightItem.WhoLikes,
      WhoComments: leftItem.WhoComments || rightItem.WhoComments,
      WhoReposts: leftItem.WhoReposts || rightItem.WhoReposts,
      WhoMentions: leftItem.WhoMentions || rightItem.WhoMentions,
      WhoIsLikedByAccountOwner: leftItem.WhoIsLikedByAccountOwner || rightItem.WhoIsLikedByAccountOwner,
      WhoIsRepostedByAccountOwner: leftItem.WhoIsRepostedByAccountOwner || rightItem.WhoIsRepostedByAccountOwner,
      WhoIsCommentedByAccountOwner: leftItem.WhoIsCommentedByAccountOwner || rightItem.WhoIsCommentedByAccountOwner,
      WhoIsMentionedByAccountOwner: leftItem.WhoIsMentionedByAccountOwner || rightItem.WhoIsMentionedByAccountOwner,
      Photos: leftItem.Photos || rightItem.Photos,
      Avatar: leftItem.Avatar || rightItem.Avatar,
      SocialNetworkAboutInfos: leftItem.SocialNetworkAboutInfos || rightItem.SocialNetworkAboutInfos,
      SocialNetworkLifePosition: leftItem.SocialNetworkLifePosition || rightItem.SocialNetworkLifePosition,
      SocialGroups: leftItem.SocialGroups || rightItem.SocialGroups,
      VideoUrls: leftItem.VideoUrls || rightItem.VideoUrls,
      AudioUrls: leftItem.AudioUrls || rightItem.AudioUrls
    };
  }
}
