import { inject, injectable } from 'inversify';
import { ISplittablesFactory } from './splittablesFactory.interfaces';
import ISplittable from '~/types/splittables/splittable';
import {
  ISplittableRowLayoutingService, ISplittableRowLayoutingServiceKey
} from '~/services/splittables/splittableRowLayoutingService.interfaces';
import { SplittableListContextOptions } from '~/models/splittables/contexts/splittableListContext.interfaces';
import { IFakeSplittablesFactory, IFakeSplittablesFactoryKey } from './fakeSplittablesFactory.interfaces';
import SplittableListContext from '~/models/splittables/contexts/splittableListContext';

@injectable()
export default class SplittablesFactory implements ISplittablesFactory {
  constructor(
    @inject(ISplittableRowLayoutingServiceKey as symbol) private splittableRowLayoutingService: ISplittableRowLayoutingService,
    @inject(IFakeSplittablesFactoryKey as symbol) private fakeSplittablesFactory: IFakeSplittablesFactory
  ) {
  }

  createListSplittable<T>(optionsFactory: () => SplittableListContextOptions<any, T> | Promise<SplittableListContextOptions<any, T>>): ISplittable<T> {
    return {
      createContextAsync: async () => new SplittableListContext<any, T>(this.splittableRowLayoutingService, await optionsFactory())
    };
  }
}
