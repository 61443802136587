/* eslint-disable class-methods-use-this, max-len */

import { injectable, inject } from 'inversify';
import { LegalEntitiesSocial } from '~/types/legal-entities/social';
import {
  ILegalEntitiesSocialsAdapterService
} from '~/services/adapters/legalEntities/legalEntitiesAdapterService.interfaces';
import { LegalEntitiesSocialDTO } from '~/types/dto/legalEntities/socialDTO';
import {
  IReportUrlAdapterService,
  IReportUrlAdapterServiceKey
} from '~/services/adapters/common/reportAdapterService.interfeces';
import {
  IArrayAdapterService,
  IReportArrayAdapterServiceKey
} from '~/services/adapters/common/reportArrayAdapterService.interfaces';

@injectable()
export class LegalEntitiesSocialsAdapterService implements ILegalEntitiesSocialsAdapterService {
  constructor(
    @inject(IReportArrayAdapterServiceKey as symbol) private reportArrayAdapterService: IArrayAdapterService,
    @inject(IReportUrlAdapterServiceKey as symbol) private reportUrlAdapterService: IReportUrlAdapterService,
  ) {}
  getData(initData: LegalEntitiesSocial[]): LegalEntitiesSocialDTO[] {
    const { mapData } = this.reportArrayAdapterService;
    return (initData ?? []).map((item) => ({
      Links: mapData(item.Links, (item) => this.reportUrlAdapterService.getData(item)),
      Text: item.Text
    }));
  }
}
