import { injectable } from 'inversify';
import { IReportMentionAdapterService } from '~/services/adapters/common/reportAdapterService.interfeces';
import { ReportMention } from '~/types/common/reportMention';
import { ReportMentionDTO } from '~/types/dto/common/reportMentionDTO';
import { StringUtils } from '~/utils/stringUtils';

@injectable()
export class ReportMentionAdapterService implements IReportMentionAdapterService {
  getData(initData: ReportMention[]): ReportMentionDTO[] {
    return (initData ?? []).map((item) => ({
      Source: StringUtils.quoteReplacement(item.Source),
      FullName: StringUtils.quoteReplacement(item.FullName),
      OriginalFullName: StringUtils.quoteReplacement(item.OriginalFullName)
    }));
  }
}
