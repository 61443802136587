import { InjectionKey } from 'vue';
import { GenericDataBlob } from '~/types/blobs/generic-data-blob';
import { GenericDataBlobData } from '~/types/blobs/generic-data-blob-data';
import { UIReportType } from '~/types/reportType';

export interface IDataBlobDataWrapperNormalizationService<T> {
  normalize(
    type: UIReportType,
    data: any,
    dataNormalize?: (data: any, field: string) => GenericDataBlobData | undefined,
  ): T | undefined
}

export const IDataBlobDataWrapperNormalizationServiceKey: InjectionKey<IDataBlobDataWrapperNormalizationService<GenericDataBlob>> = Symbol('IDataBlobDataWrapperNormalizationService<GenericDataBlob>');
