import { injectable, inject } from 'inversify';
import { ReportMovementCovid } from '~/types/common/reportMovementCovid';
import { ReportMovementCovidForVehicle } from '~/types/common/reportMovementCovidForVehicle';
import { IPlaceholderDataService, IReportMovementCovidPlaceholderServiceKey } from './reportPlaceholderService.interfaces';

@injectable()
export class ReportMovementCovidForVehiclePlaceholderService implements IPlaceholderDataService<ReportMovementCovidForVehicle> {
  constructor(
    @inject(IReportMovementCovidPlaceholderServiceKey as symbol) private reportMovementCovidPlaceholderService: IPlaceholderDataService<ReportMovementCovid>
  ) {}

  getData(): ReportMovementCovidForVehicle {
    return {
      ...this.reportMovementCovidPlaceholderService.getData(),
      FullName: '',
      Birthday: '',
      Inn: '',
      Passport: '',
      Email: '',
      PhoneNumber: '',
    };
  }
}
