import { injectable, inject } from 'inversify';
import { get } from 'lodash-es';
import { LegalEntitiesReportFinancialIndicatorBaseDTO } from '~/types/dto/legalEntities/reportFinancialIndicatorBaseDTO';
import { TableItem } from '~/types/tableItem';
import { LegalEntitiesReportFinancialStabilityDTO } from '~/types/dto/legalEntities/reportFinancialStabilityDTO';
import { ILegalEntitiesFinancialStabilityTableConfigService } from '~/modules/legalEntities/financialAnalytics/legalEntitiesFinancialStability/services/legalEntitiesFinancialStabilityTableConfigService.interface';
import {
  ILevelOfFinancialLeverageItemConfigService,
  ILevelOfFinancialLeverageItemConfigServiceKey,
  INetAssetsTableItemConfigService,
  INetAssetsTableItemConfigServiceKey,
  IRatioOfConcentrationOfEquityItemConfigService,
  IRatioOfConcentrationOfEquityItemConfigServiceKey,
  IRatioOfSecurityOfInterestToPaymentItemConfigService,
  IRatioOfSecurityOfInterestToPaymentItemConfigServiceKey,
  IRatioOfTotalDebtToProfitFromSalesItemConfigService,
  IRatioOfTotalDebtToProfitFromSalesItemConfigServiceKey
} from '~/modules/legalEntities/financialAnalytics/legalEntitiesFinancialStability/services/legalEntitiesFinancialStabilityTableItemConfigService.interfaces';
import { TableHeader } from '~/modules/table/types/tableHeader';

@injectable()
export class LegalEntitiesFinancialStabilityTableConfigService implements ILegalEntitiesFinancialStabilityTableConfigService {
  constructor(
    @inject(INetAssetsTableItemConfigServiceKey as symbol) private netAssetsTableItemConfigService: INetAssetsTableItemConfigService,
    @inject(IRatioOfConcentrationOfEquityItemConfigServiceKey as symbol) private ratioOfConcentrationOfEquityItemConfigService: IRatioOfConcentrationOfEquityItemConfigService,
    @inject(ILevelOfFinancialLeverageItemConfigServiceKey as symbol) private levelOfFinancialLeverageItemConfigService: ILevelOfFinancialLeverageItemConfigService,
    @inject(IRatioOfSecurityOfInterestToPaymentItemConfigServiceKey as symbol) private ratioOfSecurityOfInterestToPaymentItemConfigService: IRatioOfSecurityOfInterestToPaymentItemConfigService,
    @inject(IRatioOfTotalDebtToProfitFromSalesItemConfigServiceKey as symbol) private ratioOfTotalDebtToProfitFromSalesItemConfigService: IRatioOfTotalDebtToProfitFromSalesItemConfigService
  ) {
  }
  getHeaders(initData: Array<LegalEntitiesReportFinancialIndicatorBaseDTO>): Array<TableHeader> {
    return [
      {
        label: 'Показатель',
        key: 'indicator',
        width: '36%'
      },
      {
        label: String(get(initData, '0.TwoYearsAgo', '')),
        key: 'TwoYearsAgo',
        width: '12%'
      },
      {
        label: String(get(initData, '0.LastYear', '')),
        key: 'LastYear',
        width: '12%'
      },
      {
        label: String(get(initData, '0.CurrentYear', '')),
        key: 'CurrentYear',
        width: '12%'
      },
      {
        label: 'Референсное значение',
        key: 'referenceValue',
        width: '18%'
      },
    ];
  }

  getItems(initData: Array< LegalEntitiesReportFinancialStabilityDTO>): Array<TableItem> {
    return initData.map((item) => [
      this.netAssetsTableItemConfigService.getItem(item.NetAssets),
      this.ratioOfConcentrationOfEquityItemConfigService.getItem(item.RatioOfConcentrationOfEquity),
      this.levelOfFinancialLeverageItemConfigService.getItem(item.LevelOfFinancialLeverage),
      this.ratioOfSecurityOfInterestToPaymentItemConfigService.getItem(item.RatioOfSecurityOfInterestToPayment),
      this.ratioOfTotalDebtToProfitFromSalesItemConfigService.getItem(item.RatioOfTotalDebtToProfitFromSales)
    ]);
  }
}
