import { injectable, inject } from 'inversify';
import { ReportSocialNetworkId } from '~/types/common/reportSocialNetworkId';
import { IndividualFamilyRelation } from '~/types/individual/familyRelation';
import { IPrimitiveTypesNormalizationServiceKey, IPrimitiveTypesNormalizationService } from '../common/primitiveTypesNormalizationService.interfaces';
import { INormalizationService, IReportSocialNetworkIdNormalizationServiceKey } from '../common/reportNormalizationService.interfaces';

@injectable()
export class IndividualFamilyRelationNormalizationService implements INormalizationService<IndividualFamilyRelation> {
  constructor(
    @inject(IReportSocialNetworkIdNormalizationServiceKey as symbol) private reportSocialNetworkIdNormalizationService: INormalizationService<ReportSocialNetworkId>,

    @inject(IPrimitiveTypesNormalizationServiceKey as symbol) private primitiveTypesNormalizationService: IPrimitiveTypesNormalizationService,
  ) {}

  normalize(data: any, root: string): IndividualFamilyRelation | undefined {
    const {
      normalizeObject,
      normalizeOptionalString,
      normalizeArray,
      normalizeOptionalInteger,
      normalizeOptionalBoolean,
      normalizeFieldObject
    } = this.primitiveTypesNormalizationService;

    const newData = normalizeFieldObject(data);

    return normalizeObject(newData, (data) => ({
      FamilyRelationType: normalizeOptionalInteger(data.FamilyRelationType, `${root}.FamilyRelationType`),
      FirstName: normalizeOptionalString(data.FirstName, `${root}.FirstName`),
      LastName: normalizeOptionalString(data.LastName, `${root}.LastName`),
      Patronymic: normalizeOptionalString(data.Patronymic, `${root}.Patronymic`),
      Birthday: normalizeOptionalString(data.Birthday, `${root}.Birthday`),
      Inn: normalizeOptionalString(data.Inn, `${root}.Inn`),
      SocialNetworks: normalizeArray(data.SocialNetworks, (item, field) => this.reportSocialNetworkIdNormalizationService.normalize(item, field), `${root}.SocialNetworks`),
      birthdayOnlyYear: normalizeOptionalBoolean(data.birthdayOnlyYear, `${root}.birthdayOnlyYear`)
    }), root);
  }
}
