import { injectable } from 'inversify';
import { orderBy } from 'lodash-es';
import { ReportWantedCar } from '~/types/common/reportWantedCar';
import { IReportWantedCarAdapterService } from '~/services/adapters/common/reportAdapterService.interfeces';
import { ReportWantedCarDTO } from '~/types/dto/common/reportWantedCarDTO';
import { DateUtils } from '~/utils/date';

@injectable()
export class ReportWantedCarAdapterService implements IReportWantedCarAdapterService {
  getData(initData: ReportWantedCar[]): ReportWantedCarDTO[] {
    return orderBy(initData ?? [], ['StartDate'], ['desc']).map((item) => ({
      StartDate: DateUtils.formatDate(item.StartDate)
    }));
  }
}
