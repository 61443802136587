import { IContainerBinder } from '~/configuration/inversify/containerAccessor.interface';
import { bindLegalEntitiesGeneralInformationService } from '~/modules/legalEntities/commonInfo/legalEntitiesGeneralInformation/configurations/inversify/container.legalEntitiesGeneralInformation';
import { bindLegalEntitiesCompanyFounderServices } from '~/modules/legalEntities/common/legalEntitiesCompanyFounder/configurations/inversify/container.legalEntitiesCompanyFounderServices';
import { bindLegalEntitiesTaxModeServices } from '~/modules/legalEntities/commonInfo/legalEntitiesTaxMode/configuration/inversify/container.legalEntitiesTaxModeServices';
import { bindLegalEntitiesCompanyPhoneService } from '~/modules/legalEntities/commonInfo/legalEntitiesPhoneNumbers/configurations/inversify/container.legalEntitiesCompanyPhone';
import { bindLegalEntitiesChangeHistoryModulesServices } from '~/modules/legalEntities/changeHistory/common/configuration/inversify/container.legalEntitiesChangeHistoryModulesServices';
import { bindLegalEntitiesRelatedEgrulService } from '~/modules/legalEntities/connection/legalEntitiesRelatedEgrul/configurations/container.legalEntitiesRelatedEgrul';
import { bindLegalEntitiesClaimsAndDebtsService } from '~/modules/legalEntities/commonInfo/legalEntitiesClaimsAndDebts/configurations/inversify/container.legalEntitiesClaimsAndDebts';
import { bindLegalEntitiesContractsInfoByYear } from '~/modules/legalEntities/companyActivities/legalEntitiesStateProcurements/configurations/inversify/container.legalEntitiesContractsInfoByYear';
import { bindLegalEntitiesInformationCreditOrganizationsService } from '~/modules/legalEntities/registrationData/legalEntitiesInformationCreditOrganizations/configurations/inversify/container.legalEntitiesInformationCreditOrganizations';
import { bindAppServices } from '~/modules/app/configurations/inversify/container.appServices';
import { bindLegalEntitiesFinancialStabilityServices } from '~/modules/legalEntities/financialAnalytics/legalEntitiesFinancialStability/configuration/inversify/container.legalEntitiesFinancialStability';
import { bindLegalEntitiesLiquidityAndSolvencyServices } from '~/modules/legalEntities/financialAnalytics/legalEntitiesLiquidityAndSolvency/configuration/inversify/container.legalEntitiesLiquidityAndSolvency';
import { bindLegalEntitiesCashFlowPerformanceServices } from '~/modules/legalEntities/financialAnalytics/legalEntitiesCashFlowPerformance/configuration/inversify/container.legalEntitiesCashFlowPerformance';
import { bindLineChartServices } from '~/modules/legalEntities/financialAnalytics/lineCharts/common/configuration/inversify/container.lineCarts';
import { bindFinancialAnalyticsCommonServices } from '~/modules/legalEntities/financialAnalytics/common/configuration/inversify/container.financialAnalyticsCommon';
import { bindLegalEntitiesFinancialAnalysisShortServices } from '~/modules/legalEntities/financialAnalytics/legalEntitiesFinancialAnalysisShort/configuration/inversify/container.legalEntitiesFinancialAnalysisShort';
import { bindLegalEntitiesReportAnalysisOfAbsoluteLiquidityServices } from '~/modules/legalEntities/financialAnalytics/legalEntitiesReportAnalysisOfAbsoluteLiquidity/configuration/inversify/container.legalEntitiesReportAnalysisOfAbsoluteLiquidity';
import { bindLegalEntitiesExecutiveProductionServices } from '~/modules/legalEntities/companyActivities/legalEntitiesExecutiveProduction/configurations/inversify/container.legalEntitiesExecutiveProduction';

export function bindLegalEntitiesModulesServices(bind: IContainerBinder) {
  bindLegalEntitiesGeneralInformationService(bind);
  bindLegalEntitiesCompanyFounderServices(bind);
  bindLegalEntitiesTaxModeServices(bind);
  bindLegalEntitiesCompanyPhoneService(bind);
  bindLegalEntitiesChangeHistoryModulesServices(bind);
  bindLegalEntitiesRelatedEgrulService(bind);
  bindLegalEntitiesClaimsAndDebtsService(bind);
  bindLegalEntitiesContractsInfoByYear(bind);
  bindLegalEntitiesInformationCreditOrganizationsService(bind);
  bindAppServices(bind);
  bindLegalEntitiesFinancialStabilityServices(bind);
  bindLegalEntitiesLiquidityAndSolvencyServices(bind);
  bindLegalEntitiesCashFlowPerformanceServices(bind);
  bindLineChartServices(bind);
  bindFinancialAnalyticsCommonServices(bind);
  bindLegalEntitiesFinancialAnalysisShortServices(bind);
  bindLegalEntitiesReportAnalysisOfAbsoluteLiquidityServices(bind);
  bindLegalEntitiesExecutiveProductionServices(bind);
}
