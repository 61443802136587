import { injectable } from 'inversify';
import { IReportMarkerAdapterService } from '~/services/adapters/common/reportAdapterService.interfeces';
import { ReportMarker } from '~/types/common/reportMarker';
import { ReportMarkerDTO } from '~/types/dto/common/reportMarkerDTO';

@injectable()
export class ReportMarkerAdapterService implements IReportMarkerAdapterService {
  getData(initData: ReportMarker[]): ReportMarkerDTO[] {
    return (initData ?? []).map((item) => ({
      Name: item.Name
    }));
  }
}
