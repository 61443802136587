import { injectable } from 'inversify';
import { LegalEntitiesSubCompanyInfo } from '~/types/legal-entities/subCompanyInfo';
import { IPlaceholderDataService } from '../common/reportPlaceholderService.interfaces';

@injectable()
export class LegalEntitiesSubCompanyInfoPlaceholderDataService implements IPlaceholderDataService<LegalEntitiesSubCompanyInfo> {
  getData(): LegalEntitiesSubCompanyInfo {
    return {
      CompanyName: '',
      CompanyAddress: '',
      PercentCapital: 0,
      Source: '',
      FirstDate: '',
    };
  }
}
