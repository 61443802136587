import { injectable } from 'inversify';
import { IPaginationService, Ellipsis } from './pagination-service.interfaces';

@injectable()
export class PaginationService implements IPaginationService {
  private createRanges(currentPage: number, totalPages: number, maxPagesDisplayed: number) {
    let leftRange = currentPage - Math.floor(maxPagesDisplayed / 2) + 2;
    let rightRange = currentPage + Math.ceil(maxPagesDisplayed / 2) - 1;

    if (leftRange <= 2) {
      rightRange = maxPagesDisplayed;
    }

    if (rightRange >= totalPages) {
      leftRange = totalPages - maxPagesDisplayed + 2;
    }

    return { leftRange: Math.max(leftRange, 0), rightRange: Math.min(rightRange, totalPages) };
  }

  getPagination(currentPage: number, totalPages: number, maxPagesDisplayed: number): Array<number | Ellipsis> {
    const pages: Array<number | Ellipsis> = [];
    const ELLIPSIS = '...';

    pages.push(1);

    const { leftRange, rightRange } = this.createRanges(currentPage, totalPages, maxPagesDisplayed);

    if (leftRange > 1) {
      if (leftRange === 2) {
        pages.push(2);
      } else {
        pages.push(ELLIPSIS);
      }
    }
    for (let i = Math.max(2, leftRange); i < currentPage; ++i) {
      if (!pages.includes(i)) {
        pages.push(i);
      }
    }
    
    if (currentPage !== 1 && currentPage !== totalPages && !pages.includes(currentPage)) {
      pages.push(currentPage);
    }

    for (let i = currentPage + 1; i < Math.min(totalPages, rightRange); i++) {
      if (!pages.includes(i)) {
        pages.push(i);
      }
    }

    if (rightRange < totalPages) {
      pages.push(ELLIPSIS);
    }

    if (totalPages > 1) {
      pages.push(totalPages);
    } 

    return pages;
  }
}
