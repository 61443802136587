import { ISplittableContext } from '~/types/splittables/splittableContext';
import { ISplittableSection } from '~/types/splittables/splittableSection';
import NodesPagingPage from '~/models/nodes/paging/nodesPagingPage';
import NodesPagingRow from '~/models/nodes/paging/nodesPagingRow';
import { NodeRowSnapshot } from '~/models/nodes/snapshots/nodeRowSnapshot';
import { SplittableListContextOptions } from '~/models/splittables/contexts/splittableListContext.interfaces';
import { ISplittablesHub } from '~/services/splittables/splittablesHub.interfaces';
import { ISplittableRowLayoutingService } from '~/services/splittables/splittableRowLayoutingService.interfaces';
import NodesPagingCell from '~/models/nodes/paging/nodesPagingCell';
import { CompositeNodeSizeSnapshot } from '~/models/nodes/snapshots/compositeNodeSizeSnapshot';

let idCounter = 0;

function buildItemsMap<T>(rowSnapshots: NodeRowSnapshot[], items: T[]) {
  const resultMap = new Map<CompositeNodeSizeSnapshot, T>();

  let itemIdx = 0;

  for (const rowSnapshot of rowSnapshots) {
    for (const snapshot of rowSnapshot.snapshots) {
      resultMap.set(snapshot, items[itemIdx]);
      itemIdx += 1;
    }
  }

  return resultMap;
}

export default class SplittableListContext<T, R> implements ISplittableContext<R> {
  private readonly id = idCounter++;
  private readonly rowSnapshots: NodeRowSnapshot[];
  private readonly itemsMap: Map<CompositeNodeSizeSnapshot, T>;
  private readonly hub?: ISplittablesHub;

  private pages = new Array<NodesPagingPage<T>>();
  private readonly splittableContextPages = new Array<NodesPagingPage<T>>();

  private rowIdx = 0;
  private splittableContext?: ISplittableContext<T>;

  constructor(
    private splittableRowLayoutingService: ISplittableRowLayoutingService,
    private options: SplittableListContextOptions<T, R>
  ) {
    this.rowSnapshots = [...options.snapshots];
    this.hub = options.hub;

    this.itemsMap = buildItemsMap(this.rowSnapshots, this.options.items);
  }

  async applyLayoutAsync(): Promise<ISplittableSection<R>[]> {
    this.finilizeLayout();

    return await this.options.applyLayout(this.pages);
  }

  async createSectionAsync(availableHeight: number, forceFit = true): Promise<boolean> {
    if (this.splittableContext != undefined) {
      if (await this.processSplittableContextAsync(this.splittableContext, availableHeight)) {
        const lastPage = this.getLastPage();
        
        if (lastPage != undefined)
          return await this.processPageAsync(lastPage, forceFit);
      }
      
      return true;
    }

    return await this.createSectionInternalAsync(availableHeight, forceFit);
  }

  hasFreeContent(): boolean {
    return this.rowIdx < this.rowSnapshots.length;
  }
  
  isSplittable(): boolean {
    return this.rowSnapshots.length > 1;
  }

  private async createSectionInternalAsync(availableHeight: number, forceFit: boolean): Promise<boolean> {
    if (this.hasFreeContent()) {
      const page = this.createPage(availableHeight);      
      return await this.processPageAsync(page, forceFit);      
    }

    return false;
  }
  
  private async processPageAsync(page: NodesPagingPage<T>, forceFit: boolean) {
    let isFinished = false;

    while (this.rowIdx < this.rowSnapshots.length && !isFinished) {
      const rowSnapshot = this.rowSnapshots[this.rowIdx];
      rowSnapshot.update();
      
      const cells = rowSnapshot.snapshots.map(s => new NodesPagingCell(s, this.itemsMap.get(s)));
      const pageRow = new NodesPagingRow(cells);

      if (!page.tryAddRow(pageRow)) {
        if (this.hub != undefined && this.rowSnapshots.length > 1) {
          const initialOffset = page.isEmpty ? 0 : pageRow.top - page.bottom;

          const splittableContext = await this.splittableRowLayoutingService.getInitialLayoutAsync(this.hub, pageRow, {
            pageHeight: page.freeSpace - initialOffset
          });

          if (splittableContext.isSplittable()) {
            this.splittableContext = splittableContext;
            this.splittableContextPages.push(page);
            return true;
          }
        }

        if (page.isEmpty && forceFit) {
          page.addRow(pageRow);
        } else {
          isFinished = true;
        }
      }

      if (!isFinished)
        this.rowIdx += 1;
    }

    if (!page.isEmpty) {
      this.addPage(page);
      return true;
    }
    
    return false;
  }

  private async processSplittableContextAsync(splittableContext: ISplittableContext<T>, availableHeight: number): Promise<boolean> {
    const splittableContextPage = this.createPage(availableHeight);
    this.splittableContextPages.push(splittableContextPage);

    const isSectionCreated = await splittableContext.createSectionAsync(splittableContextPage.freeSpace);
    const finalizationRequired = !isSectionCreated || !splittableContext.hasFreeContent();

    if (finalizationRequired) {
      await this.finalizeSplittableContextAsync(splittableContext);
      return true;
    }
    
    return false;
  }

  private finilizeLayout() {
    if (this.hasFreeContent()) {
      const page = new NodesPagingPage<T>({ height: 0 });

      while (this.rowIdx < this.rowSnapshots.length) {
        const rowSnapshot = this.rowSnapshots[this.rowIdx];
        const cells = rowSnapshot.snapshots.map((s) => new NodesPagingCell(s, this.itemsMap.get(s)));
        page.addRow(new NodesPagingRow(cells));
        this.rowIdx += 1;
      }

      this.addPage(page);
    }
    
    this.pages = this.pages.filter(page => !page.isEmpty);
  }

  private async finalizeSplittableContextAsync(splittableContext: ISplittableContext<T>) {
    let page = this.pages[this.pages.length - 1];

    if (page == undefined)
      page = this.loadNextSplittableContextPage();

    const sections = await splittableContext.applyLayoutAsync();
    
    for (const section of sections) {
      const cells = section.cells.map(cell => new NodesPagingCell(new CompositeNodeSizeSnapshot(cell.elements), cell.data));

      const pageRow = new NodesPagingRow(cells);

      while (!page.tryAddRow(pageRow) && (!page.isEmpty || this.splittableContextPages.length > 0))
        page = this.loadNextSplittableContextPage();
      
      if (page.isEmpty)
        page.addRow(pageRow);
    }

    this.splittableContext = undefined;
    this.rowIdx += 1;
  }

  private normalizePageHeight(height: number) {
    return height > 0 ? height : 0;
  }
  
  private addPage(page: NodesPagingPage<T>) {
    if (!this.pages.includes(page))
      this.pages.push(page);
  }

  private createPage(availableHeight: number) {
    const pageIdx = this.pages.length + this.splittableContextPages.length;
    const pageHeight = this.normalizePageHeight(this.options.pageHeight(availableHeight, pageIdx));    

    return new NodesPagingPage<T>({ height: pageHeight });
  }
  
  private getLastPage(): NodesPagingPage<T> | undefined {
    return this.pages[this.pages.length - 1];
  }

  private loadNextSplittableContextPage() {
    const page = this.splittableContextPages.shift()
      ?? this.pages[this.pages.length - 1]?.clone()
      ?? new NodesPagingPage<T>({ height: 0 });
    
    this.addPage(page);

    return page;
  }
}
