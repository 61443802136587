import { injectable, inject } from 'inversify';
import {
  IPhoneNumberDataBlobDataAdapterService,
  IPhoneNumberPhoneCommonAdapterService,
  IPhoneNumberPhoneCommonAdapterServiceKey,
  IPhoneNumberRelatedPhonePersonAdapterServices,
  IPhoneNumberRelatedPhonePersonAdapterServicesKey
} from '~/services/adapters/phoneNimber/phoneNumberAdapterService.interfaces';
import { PhoneNumbersDataBlobData } from '~/types/blobs/phone-numbers-data-blob-data';
import { PhoneNumbersDataBlobDataDTO } from '~/types/dto/blobs/phoneNumbersDataBlobDataDTO';
import {
  IReportMentionAdapterService,
  IReportMentionAdapterServiceKey,
  IReportMessengerInfoAdapterService,
  IReportMessengerInfoAdapterServiceKey,
  IReportPassportAdapterService,
  IReportPassportAdapterServiceKey
} from '~/services/adapters/common/reportAdapterService.interfeces';

@injectable()
export class PhoneNumberDataBlobDataAdapterService implements IPhoneNumberDataBlobDataAdapterService {
  constructor(
    @inject(IPhoneNumberPhoneCommonAdapterServiceKey as symbol) private phoneNumberPhoneCommonAdapterService: IPhoneNumberPhoneCommonAdapterService,
    @inject(IPhoneNumberRelatedPhonePersonAdapterServicesKey as symbol) private phoneNumberRelatedPhonePersonAdapterServices: IPhoneNumberRelatedPhonePersonAdapterServices,

    @inject(IReportMessengerInfoAdapterServiceKey as symbol) private reportMessengerInfoAdapterService: IReportMessengerInfoAdapterService,
    @inject(IReportMentionAdapterServiceKey as symbol) private reportMentionAdapterService: IReportMentionAdapterService,
    @inject(IReportPassportAdapterServiceKey as symbol) private reportPassportAdapterService: IReportPassportAdapterService,
  ) {}
  getData(initData: PhoneNumbersDataBlobData): PhoneNumbersDataBlobDataDTO {
    return {
      Common: this.phoneNumberPhoneCommonAdapterService.getData(initData.Common),
      Messengers: this.reportMessengerInfoAdapterService.getData(initData.Messengers),
      Mentions: this.reportMentionAdapterService.getData(initData.Mentions),
      RelatedPersons: this.phoneNumberRelatedPhonePersonAdapterServices.getData(initData.RelatedPersons),
      Passports: this.reportPassportAdapterService.getData(initData.Passports)
    };
  }
}
