import { InjectionKey } from 'vue';
import { IAdapterService } from '~/services/adapters/adapterService.interfaces';
import { PhoneNumbersDataBlobData } from '~/types/blobs/phone-numbers-data-blob-data';
import { PhoneNumbersDataBlobDataDTO } from '~/types/dto/blobs/phoneNumbersDataBlobDataDTO';
import { PhoneNumberPhoneCommonDTO } from '~/types/dto/phone-number/phoneCommonDTO';
import { PhoneNumberPhoneCommon } from '~/types/phone-number/phoneCommon';
import { PhoneNumberRelatedPhonePersonDTO } from '~/types/dto/phone-number/relatedPersonDTO';
import { PhoneNumberRelatedPhonePerson } from '~/types/phone-number/relatedPhonePerson';
import { PhoneNumberRelatedPersonCommonDTO } from '~/types/dto/phone-number/relatedPersonCommonDTO';
import { PhoneNumberRelatedPhonePersonCommon } from '~/types/phone-number/relatedPhonePersonCommon';

export type IPhoneNumberDataBlobDataAdapterService = IAdapterService<PhoneNumbersDataBlobData, PhoneNumbersDataBlobDataDTO>
export type IPhoneNumberPhoneCommonAdapterService = IAdapterService<PhoneNumberPhoneCommon, PhoneNumberPhoneCommonDTO>
export type IPhoneNumberRelatedPhonePersonAdapterServices = IAdapterService<PhoneNumberRelatedPhonePerson[], PhoneNumberRelatedPhonePersonDTO[]>
export type IPhoneNumberRelatedPersonCommonAdapterServices = IAdapterService<PhoneNumberRelatedPhonePersonCommon, PhoneNumberRelatedPersonCommonDTO>

export const IPhoneNumberDataBlobDataAdapterServiceKey: InjectionKey<IPhoneNumberDataBlobDataAdapterService> = Symbol('IPhoneNumberDataBlobDataAdapterService');
export const IPhoneNumberPhoneCommonAdapterServiceKey: InjectionKey<IPhoneNumberPhoneCommonAdapterService> = Symbol('IPhoneNumberPhoneCommonAdapterService');
export const IPhoneNumberRelatedPhonePersonAdapterServicesKey: InjectionKey<IPhoneNumberRelatedPhonePersonAdapterServices> = Symbol('IPhoneNumberRelatedPhonePersonAdapterServices');
export const IPhoneNumberRelatedPersonCommonAdapterServicesKey: InjectionKey<IPhoneNumberRelatedPersonCommonAdapterServices> = Symbol('IPhoneNumberRelatedPersonCommonAdapterServices');
