import { injectable } from 'inversify';
import { LegalEntitiesEnforcementProceedingsInfoBySubject } from '~/types/legal-entities/enforcementProceedingsInfoBySubject';
import { IPlaceholderDataService } from '../common/reportPlaceholderService.interfaces';

@injectable()
export class LegalEntitiesEnforcementProceedingsInfoBySubjectPlaceholderDataService implements IPlaceholderDataService<LegalEntitiesEnforcementProceedingsInfoBySubject> {
  getData(): LegalEntitiesEnforcementProceedingsInfoBySubject {
    return {
      SectorName: '',
      CasesCount: 0,
      Sum: 0,
    };
  }
}
