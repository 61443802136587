/* eslint-disable no-dupe-class-members, class-methods-use-this */

import { injectable, inject } from 'inversify';
import { appSettings } from '~/settings';
import { UIReportType } from '~/types/reportType';
import { GenericDataBlob } from '~/types/blobs/generic-data-blob';
import { IDataService } from './data-service.interface';
import { LegalEntitiesDataBlob } from '~/types/blobs/legal-entities-data-blob';
import { IndividualDataBlob } from '~/types/blobs/individual-data-blob';
import { VehiclesDataBlob } from '~/types/blobs/vehicles-data-blob';
import { PhoneNumbersDataBlob } from '~/types/blobs/phone-numbers-data-blob';
import { IAPIAsyncService, IAPIAsyncServiceKey } from '@/services/api-async-service.interface';
import { IGenericDataBlobNormalizationService, IGenericDataBlobNormalizationServiceKey } from './normalization/blob/genericDataBlobNormalizationService.interfaces';
import { useInjectable } from '~/hooks/useInjectable';
import { IMocksServiceKey } from './mocks-service.interfaces';

@injectable()
export class DataService implements IDataService {
  private apiAsyncService: IAPIAsyncService;

  constructor(
    @inject(IAPIAsyncServiceKey as symbol) apiAsyncService: IAPIAsyncService,
    @inject(IGenericDataBlobNormalizationServiceKey as symbol) private genericDataBlobNormalizationService: IGenericDataBlobNormalizationService,
  ) {
    this.apiAsyncService = apiAsyncService;
  }

  getDataAsync(type: UIReportType.ul): Promise<LegalEntitiesDataBlob>;

  getDataAsync(type: UIReportType.fl): Promise<IndividualDataBlob>;

  getDataAsync(type: UIReportType.vehicle): Promise<VehiclesDataBlob>;

  getDataAsync(type: UIReportType.phoneNumber): Promise<PhoneNumbersDataBlob>;

  async getDataAsync(type: UIReportType): Promise<GenericDataBlob> {
    const mocksService = useInjectable(IMocksServiceKey);
    const result = appSettings.shouldUseMockData
      ? await mocksService.getMockDataByNameAsync(type, appSettings.targetMock)
      : await this.apiAsyncService.loadDataFromAPIAsync();
    return this.genericDataBlobNormalizationService.normalize(result, type);
  }
}
