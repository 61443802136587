import { injectable } from 'inversify';
import { IIndividualStageBankruptcyAdapterService } from '~/services/adapters/individual/individualAdapterService.interfaces';
import { IndividualStageBankruptcy } from '~/types/individual/stageBankruptcy';
import { IndividualStageBankruptcyDTO } from '~/types/dto/individual/stageBankruptcyDTO';
import { StringUtils } from '~/utils/stringUtils';

@injectable()
export class IndividualStageBankruptcyAdapterService implements IIndividualStageBankruptcyAdapterService {
  getData(initData: IndividualStageBankruptcy[]): IndividualStageBankruptcyDTO[] {
    return (initData ?? []).map((item) => ({
      MessageId: StringUtils.quoteReplacement(item.MessageId),
      Stage: StringUtils.quoteReplacement(item.Stage),
      PubDate: item.PubDate ?? '',
      DecisionDate: item.DecisionDate ?? ''
    }));
  }
}
