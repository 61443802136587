import { injectable, inject } from 'inversify';
import { IPlaceholderDataService } from '~/services/placeholder/common/reportPlaceholderService.interfaces';
import { ILegalEntitiesCompanyFounderSharePlaceholderDataServiceKey } from '~/services/placeholder/legalEntities/legalEntitiesPlaceholderDataService.interfaces';
import { LegalEntitiesCompanyFounder } from '~/types/legal-entities/companyFounder';
import { LegalEntitiesCompanyFounderShare } from '~/types/legal-entities/companyFounderShare';
import { IPrimitiveTypesNormalizationServiceKey, IPrimitiveTypesNormalizationService } from '../common/primitiveTypesNormalizationService.interfaces';
import { INormalizationService } from '../common/reportNormalizationService.interfaces';
import { ILegalEntitiesCompanyFounderShareNormalizationServiceKey } from './legalEntitiesNormalizationService.interfaces';

@injectable()
export class LegalEntitiesCompanyFounderNormalizationService implements INormalizationService<LegalEntitiesCompanyFounder> {
  constructor(
    @inject(ILegalEntitiesCompanyFounderShareNormalizationServiceKey as symbol) private companyFounderShareNormalizationService: INormalizationService<LegalEntitiesCompanyFounderShare>,

    @inject(ILegalEntitiesCompanyFounderSharePlaceholderDataServiceKey as symbol) private companyFounderSharePlaceholderDataService: IPlaceholderDataService<LegalEntitiesCompanyFounderShare>,

    @inject(IPrimitiveTypesNormalizationServiceKey as symbol) private primitiveTypesNormalizationService: IPrimitiveTypesNormalizationService,
  ) {}

  normalize(data: any, root: string): LegalEntitiesCompanyFounder | undefined {
    const {
      normalizeString,
      normalizeOptionalInteger,
      normalizeOptionalString,
      normalizeOptionalBoolean,
      normalizeObject
    } = this.primitiveTypesNormalizationService;

    return normalizeObject(data, (data) => ({
      Inn: normalizeOptionalString(data.Inn, `${root}.Inn`),
      Fullname: normalizeString(data.Fullname, `${root}.Fullname`),
      Country: normalizeOptionalString(data.Country, `${root}.Country`),
      FounderType: normalizeString(data.FounderType, `${root}.FounderType`),
      Share: this.companyFounderShareNormalizationService.normalize(data.Share, `${root}.Share`) ?? this.companyFounderSharePlaceholderDataService.getData(),
      Date: normalizeOptionalString(data.Date, `${root}.Date`),
      FirstDate: normalizeOptionalString(data.FirstDate, `${root}.FirstDate`),
      IsInaccuracy: normalizeOptionalBoolean(data.IsInaccuracy, `${root}.IsInaccuracy`),
      InaccuracyDate: normalizeOptionalString(data.InaccuracyDate, `${root}.InaccuracyDate`),
      Capital: normalizeOptionalInteger(data.Capital, `${root}.Capital`),
      Percent: normalizeOptionalInteger(data.Percent, `${root}.Percent`),
    }), root);
  }
}
