import { injectable, inject } from 'inversify';
import { IPlaceholderDataService } from '~/services/placeholder/common/reportPlaceholderService.interfaces';
import { ILegalEntitiesReportFinancialIndicatorWithConclusionsPlaceholderDataServiceKey } from '~/services/placeholder/legalEntities/legalEntitiesPlaceholderDataService.interfaces';
import { LegalEntitiesReportFinancialIndicatorWithConclusions } from '~/types/legal-entities/reportFinancialIndicatorWithConclusions';
import { LegalEntitiesReportLiquidityAndSolvency } from '~/types/legal-entities/reportLiquidityAndSolvency';
import { IPrimitiveTypesNormalizationServiceKey, IPrimitiveTypesNormalizationService } from '../common/primitiveTypesNormalizationService.interfaces';
import { INormalizationService } from '../common/reportNormalizationService.interfaces';
import { ILegalEntitiesReportFinancialIndicatorWithConclusionsNormalizationServiceKey } from './legalEntitiesNormalizationService.interfaces';

@injectable()
export class LegalEntitiesReportLiquidityAndSolvencyNormalizationService implements INormalizationService<LegalEntitiesReportLiquidityAndSolvency> {
  constructor(
    @inject(ILegalEntitiesReportFinancialIndicatorWithConclusionsNormalizationServiceKey as symbol) private reportFinancialIndicatorWithConclusionsNormalizationService: INormalizationService<LegalEntitiesReportFinancialIndicatorWithConclusions>,

    @inject(ILegalEntitiesReportFinancialIndicatorWithConclusionsPlaceholderDataServiceKey as symbol) private reportFinancialIndicatorWithConclusionsPlaceholderDataService: IPlaceholderDataService<LegalEntitiesReportFinancialIndicatorWithConclusions>,

    @inject(IPrimitiveTypesNormalizationServiceKey as symbol) private primitiveTypesNormalizationService: IPrimitiveTypesNormalizationService,
  ) {}

  normalize(data: any, root: string): LegalEntitiesReportLiquidityAndSolvency | undefined {
    const { normalizeObject } = this.primitiveTypesNormalizationService;

    return normalizeObject(data, (data) => ({
      OwnWorkingCapital: this.reportFinancialIndicatorWithConclusionsNormalizationService.normalize(data.OwnWorkingCapital, `${root}.OwnWorkingCapital`) ?? this.reportFinancialIndicatorWithConclusionsPlaceholderDataService.getData(),
      RatioOfCurrentLiquidity: this.reportFinancialIndicatorWithConclusionsNormalizationService.normalize(data.RatioOfCurrentLiquidity, `${root}.RatioOfCurrentLiquidity`) ?? this.reportFinancialIndicatorWithConclusionsPlaceholderDataService.getData(),
      RatioOfSolvency: this.reportFinancialIndicatorWithConclusionsNormalizationService.normalize(data.RatioOfSolvency, `${root}.RatioOfSolvency`) ?? this.reportFinancialIndicatorWithConclusionsPlaceholderDataService.getData(),
      DegreeOfSolvency: this.reportFinancialIndicatorWithConclusionsNormalizationService.normalize(data.DegreeOfSolvency, `${root}.DegreeOfSolvency`) ?? this.reportFinancialIndicatorWithConclusionsPlaceholderDataService.getData(),
      RatioOfReceivablesToTotalAssets: this.reportFinancialIndicatorWithConclusionsNormalizationService.normalize(data.RatioOfReceivablesToTotalAssets, `${root}.RatioOfReceivablesToTotalAssets`) ?? this.reportFinancialIndicatorWithConclusionsPlaceholderDataService.getData(),
    }), root);
  }
}
