import { injectable } from 'inversify';

import { LegalEntitiesCompanyEmployeeInfo } from '~/types/legal-entities/companyEmployeeInfo';
import { IPlaceholderDataService } from '../common/reportPlaceholderService.interfaces';

@injectable()
export class LegalEntitiesCompanyEmployeeInfoPlaceholderDataService implements IPlaceholderDataService<LegalEntitiesCompanyEmployeeInfo> {
  getData(): LegalEntitiesCompanyEmployeeInfo {
    return {
      FullName: '',
      BirthDate: '',
      Snils: '',
      Inn: '',
      IdentityDocument: '',
      PhoneNumber: '',
      SocialNetworks: [],
      Email: '',
      Address: '',
      CarNumber: '',
    };
  }
}
