<template>
  <path d="M20.6526 3.16728C20.1001 2.67295 17.8901 1.04454 12.9176 1.01547C12.9176 1.01547 7.07284 0.666519 4.22314 3.28359C2.62382 4.88292 2.07132 7.18013 2.01316 10.0589C1.95501 12.9377 1.86778 18.3172 7.07285 19.8003V22.0393C7.07285 22.0393 7.04377 22.9407 7.62534 23.1152C8.35231 23.3478 8.7594 22.65 9.45729 21.923C9.83531 21.5159 10.3587 20.9343 10.7367 20.4691C14.2843 20.7598 17.0177 20.091 17.3376 19.9747C18.0646 19.7421 22.1065 19.2187 22.7753 13.8391C23.4732 8.25604 22.4845 4.73753 20.6526 3.16728V3.16728ZM21.2632 13.403C20.7107 17.9101 17.4248 18.2009 16.8142 18.3754C16.5525 18.4626 14.168 19.0442 11.1439 18.8697C11.1439 18.8697 8.9048 21.574 8.17783 22.301C8.06152 22.4173 7.9452 22.4464 7.85797 22.4464C7.74165 22.4173 7.71257 22.2719 7.71257 22.0684C7.71257 21.7776 7.74165 18.3463 7.74165 18.3463C3.35078 17.125 3.61249 12.5306 3.64157 10.1171C3.69973 7.70354 4.13591 5.75527 5.47353 4.41766C7.88705 2.23676 12.8595 2.55663 12.8595 2.55663C17.0468 2.58571 19.0532 3.83609 19.5185 4.27227C21.0306 5.60988 21.8157 8.77946 21.2632 13.403V13.403Z" fill="#655CAE" />
  <path
    d="M14.9532 9.94263C14.8951 8.8376 14.3426 8.256 13.2666 8.19785"
    stroke="#655CAE"
    stroke-width="0.829258"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
  <path
    d="M16.4073 10.4079C16.4364 9.39014 16.1165 8.51775 15.5058 7.84892C14.8661 7.15101 13.9937 6.77297 12.8887 6.68573"
    stroke="#655CAE"
    stroke-width="0.829258"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
  <path
    d="M17.8611 10.9896C17.8611 9.21573 17.3086 7.81991 16.2617 6.80212C15.2148 5.78433 13.9062 5.26089 12.3359 5.26089"
    stroke="#655CAE"
    stroke-width="0.829258"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
  <path d="M13.325 13.6937C13.325 13.6937 13.7321 13.7228 13.9357 13.4611L14.3428 12.9377C14.5463 12.676 15.0116 12.5015 15.5059 12.7632C15.7676 12.9086 16.2329 13.1994 16.5237 13.432C16.8435 13.6646 17.4833 14.188 17.4833 14.188C17.8031 14.4497 17.8613 14.8278 17.6577 15.2349C17.4542 15.6129 17.1634 15.9618 16.7854 16.2817C16.4946 16.5434 16.2038 16.6597 15.913 16.7179H15.7967C15.6804 16.7179 15.535 16.6888 15.4187 16.6597C14.9825 16.5434 14.2555 16.2235 13.0052 15.5547C12.22 15.1186 11.5512 14.6533 10.9987 14.2171C10.7079 13.9845 10.3881 13.7228 10.0973 13.4029L9.98098 13.2866C9.66112 12.9667 9.39941 12.676 9.16678 12.3852C8.7306 11.8327 8.26534 11.1639 7.82916 10.3787C7.16035 9.15743 6.84049 8.43047 6.72418 7.96522C6.6951 7.8489 6.66602 7.73259 6.66602 7.5872V7.47088C6.69509 7.18009 6.84049 6.8893 7.10219 6.59852C7.42206 6.24957 7.771 5.95879 8.14903 5.72616C8.55613 5.52261 8.93415 5.58077 9.19586 5.90063C9.19586 5.90063 9.71927 6.54037 9.9519 6.86023C10.1555 7.15102 10.4462 7.61628 10.6207 7.87798C10.8824 8.34324 10.7079 8.83758 10.4753 9.04113L9.9519 9.44823C9.6902 9.65178 9.71927 10.0589 9.71927 10.0589C9.71927 10.0589 10.4462 12.9377 13.325 13.6937V13.6937Z" fill="#655CAE" />
</template>
