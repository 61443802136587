import { injectable } from 'inversify';
import { IPlaceholderDataService } from '~/services/placeholder/common/reportPlaceholderService.interfaces';
import { VehiclesDataBlobData } from '~/types/blobs/vehicles-data-blob-data';

@injectable()
export class VehiclesDataBlobDataPlaceholderService implements IPlaceholderDataService<VehiclesDataBlobData> {
  getData(): VehiclesDataBlobData {
    return {
      Vehicles: [],
      VehicleParkings: [],
      Fines: [],
      CovidMovements: [],
      RoadAccidents: [],
      BoundedPersonPhones: [],
      MessageBoards: [],
    };
  }
}
