import { IContainerBinder } from '~/configuration/inversify/containerAccessor.interface';
import { FinancialAnalysisCommonTitlesService } from '~/modules/legalEntities/financialAnalytics/common/services/financialAnalysisCommonTitlesService';
import { IFinancialAnalysisCommonTitlesServiceKey } from '~/modules/legalEntities/financialAnalytics/common/services/financialAnalysisCommonTitlesService.interfaces';
import { ConvertingPrimitiveTableTypes } from '~/modules/legalEntities/financialAnalytics/common/services/convertingPrimitiveTableTypes';
import { IConvertingPrimitiveTableTypesKey } from '~/modules/legalEntities/financialAnalytics/common/services/convertingPrimitiveTableTypes.interfaces';
import { InterestPeriodTableIDataService } from '~/modules/legalEntities/financialAnalytics/common/services/interestPeriodTableIDataService';
import { IInterestPeriodTableIDataServiceKey } from '~/modules/legalEntities/financialAnalytics/common/services/interestPeriodTableIDataService.interfaces';

export function bindFinancialAnalyticsCommonServices(bind: IContainerBinder) {
  bind(IFinancialAnalysisCommonTitlesServiceKey).to(FinancialAnalysisCommonTitlesService);

  bind(IConvertingPrimitiveTableTypesKey).to(ConvertingPrimitiveTableTypes);
  bind(IInterestPeriodTableIDataServiceKey).to(InterestPeriodTableIDataService);
}
