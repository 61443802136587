import { injectable } from 'inversify';
import { ILegalEntitiesInformationCreditOrganizationsConfigService } from '~/modules/legalEntities/registrationData/legalEntitiesInformationCreditOrganizations/services/legalEntitiesInformationCreditOrganizationsConfigService.interface';
import { LegalEntitiesCommonInfoDTO } from '~/types/dto/legalEntities/commonInfo';
import { Config } from '~/types/field-list/config';

@injectable()
export class LegalEntitiesInformationCreditOrganizationsConfigService implements ILegalEntitiesInformationCreditOrganizationsConfigService {
  getConfig(initData: LegalEntitiesCommonInfoDTO): Config {
    const bankType = !initData.BankOpfType.toLocaleLowerCase()
      .includes('неизвестный') ? initData.BankOpfType : undefined;
    return {
      fieldsWidth: '100%',
      fieldsDirection: 'divider-row',
      fieldsValueWidth: '350px',
      fields: [
        {
          key: 'bic',
          label: 'БИК',
          value: initData.Bic,
        },
        {
          key: 'swift',
          label: 'Swift',
          value: initData.Swift,
        },
        {
          key: 'bankRegistrationNumber',
          label: 'Рег. номер в ЦБ РФ',
          value: initData.BankRegistrationNumber,
        },
        {
          key: 'bankCorrespondentAccount',
          label: 'Кор. номер в ЦБ РФ',
          value: initData.BankCorrespondentAccount,
        },
        {
          key: 'bankOpfType',
          label: 'Тип кредитной организации',
          value: bankType,
        },
      ],
    };
  }
}
