import { injectable } from 'inversify';
import { ReportColleague } from '~/types/common/reportColleague';
import { IPlaceholderDataService } from './reportPlaceholderService.interfaces';

@injectable()
export class ReportColleaguePlaceholderService implements IPlaceholderDataService<ReportColleague> {
  getData(): ReportColleague {
    return {
      Fullname: '',
      Birthday: '',
      SocialNetworks: []
    };
  }
}
