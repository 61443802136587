import { InjectionKey } from 'vue';
import { ReportIndicatorsOfCashFlow } from '~/types/blobs/report-indicators-of-cash-flow';
import { ReportFine } from '~/types/common/reportFine';
import { ReportBoundedPersonPhone } from '~/types/common/reportBoundedPersonPhone';
import { ReportColleague } from '~/types/common/reportColleague';
import { ReportEducation } from '~/types/common/reportEducation';
import { ReportEmail } from '~/types/common/reportEmail';
import { ReportIndividualEntrepreneur } from '~/types/common/reportIndividualEntrepreneur';
import { ReportJob } from '~/types/common/reportJob';
import { ReportLegalEntitiesParticipation } from '~/types/common/reportLegalEntitiesParticipation';
import { ReportMainEntityFile } from '~/types/common/reportMainEntityFile';
import { ReportMainEntityFileUrl } from '~/types/common/reportMainEntityFileUrl';
import { ReportMarker } from '~/types/common/reportMarker';
import { ReportMention } from '~/types/common/reportMention';
import { ReportMovementCovid } from '~/types/common/reportMovementCovid';
import { ReportMessageBoardRecord } from '~/types/common/reportMessageBoardRecord';
import { ReportMovementTaxi } from '~/types/common/reportMovementTaxi';
import { ReportPassport } from '~/types/common/reportPassport';
import { ReportPayment } from '~/types/common/reportPayment';
import { ReportPersonInn } from '~/types/common/reportPersonInn';
import { ReportPersonSite } from '~/types/common/reportPersonSite';
import { ReportPersonSnils } from '~/types/common/reportPersonSnils';
import { ReportPledgesRegistry } from '~/types/common/reportPledgesRegistry';
import { ReportPossibleBirthdays } from '~/types/common/reportPossibleBirthdays';
import { ReportPossibleFullName } from '~/types/common/reportPossibleFullName';
import { ReportRoadAccident } from '~/types/common/reportRoadAccident';
import { ReportSocialNetworkHighEducation } from '~/types/common/reportSocialNetworkHighEducation';
import { ReportSocialNetworkId } from '~/types/common/reportSocialNetworkId';
import { ReportSocialNetworkJob } from '~/types/common/reportSocialNetworkJob';
import { ReportSocialNetworkMilitaryRecord } from '~/types/common/reportSocialNetworkMilitaryRecord';
import { ReportSocialNetworkSchool } from '~/types/common/reportSocialNetworkSchool';
import { ReportSocialNetworkSecondaryEducation } from '~/types/common/reportSocialNetworkSecondaryEducation';
import { ReportSocialNetworkTrack } from '~/types/common/reportSocialNetworkTrack';
import { ReportTag } from '~/types/common/reportTag';
import { ReportUrl } from '~/types/common/reportUrl';
import { ReportVehicle } from '~/types/common/reportVehicle';
import { ReportVehicleInsurance } from '~/types/common/reportVehicleInsurance';
import { ReportVehicleOwnership } from '~/types/common/reportVehicleOwnership';
import { ReportVehicleParking } from '~/types/common/reportVehicleParking';
import { ReportVehiclePhone } from '~/types/common/reportVehiclePhone';
import { ReportPhotosUrl } from '~/types/common/reportVehiclePhotosUrl';
import { ReportVehicleRelation } from '~/types/common/reportVehicleRelation';
import { ReportVehicleRestrict } from '~/types/common/reportVehicleRestrict';
import { ReportVehicleRisks } from '~/types/common/reportVehicleRisks';
import { ReportWantedCar } from '~/types/common/reportWantedCar';
import { ReportAnalysisOfAbsoluteLiquidity } from '~/types/legal-entities/reportAnalysisOfAbsoluteLiquidity';
import { ReportIndicatorsOfProfitability } from '~/types/legal-entities/reportIndicatorsOfProfitability';
import { ReportMovementCovidForVehicle } from '~/types/common/reportMovementCovidForVehicle';
import { ReportMessengerInfo } from '~/types/common/reportMessengerInfo';
import { ReportVettingInfo } from '~/types/common/vettingInfo';
import { ReportConnectionRelated } from '~/types/common/reportConnectionRelated';
import { ReportAddress } from '~/types/common/address';
import { ReportSocialNetwork } from '~/types/common/reportSocialNetwork';
import { ReportSocialNetworkAboutInfo } from '~/types/common/reportSocialNetworkAboutInfo';
import { ReportSocialNetworkLifePosition } from '~/types/common/reportSocialNetworkLifePosition';
import { ReportSocialNetworkOtherPerson } from '~/types/common/reportSocialNetworkOtherPerson';

export interface IPlaceholderDataService<T> {
  getData(): T;
}

export const IReportMovementCovidForVehiclePlaceholderServiceKey: InjectionKey<IPlaceholderDataService<ReportMovementCovidForVehicle>> = Symbol('IPlaceholderDataService<ReportMovementCovidForVehicle>');
export const IReportUrlPlaceholderServiceKey: InjectionKey<IPlaceholderDataService<ReportUrl>> = Symbol('IPlaceholderDataService<ReportUrl>');
export const IReportMainEntityFilePlaceholderServiceKey: InjectionKey<IPlaceholderDataService<ReportMainEntityFile>> = Symbol('IPlaceholderDataService<ReportMainEntityFile>');
export const IReportLegalEntitiesParticipationPlaceholderServiceKey: InjectionKey<IPlaceholderDataService<ReportLegalEntitiesParticipation>> = Symbol('IPlaceholderDataService<ReportLegalEntitiesParticipation>');
export const IReportVehicleOwnershipPlaceholderServiceKey: InjectionKey<IPlaceholderDataService<ReportVehicleOwnership>> = Symbol('IPlaceholderDataService<ReportVehicleOwnership>');
export const IReportVehicleRelationPlaceholderServiceKey: InjectionKey<IPlaceholderDataService<ReportVehicleRelation>> = Symbol('IPlaceholderDataService<ReportVehicleRelation>');
export const IReportVehicleInsurancePlaceholderServiceKey: InjectionKey<IPlaceholderDataService<ReportVehicleInsurance>> = Symbol('IPlaceholderDataService<ReportVehicleInsurance>');
export const IReportPhotosUrlPlaceholderServiceKey: InjectionKey<IPlaceholderDataService<ReportPhotosUrl>> = Symbol('IPlaceholderDataService<ReportPhotosUrl>');
export const IReportVehicleRestrictPlaceholderServiceKey: InjectionKey<IPlaceholderDataService<ReportVehicleRestrict>> = Symbol('IPlaceholderDataService<ReportVehicleRestrict>');
export const IReportMainEntityFileUrlPlaceholderServiceKey: InjectionKey<IPlaceholderDataService<ReportMainEntityFileUrl>> = Symbol('IPlaceholderDataService<ReportMainEntityFileUrl>');
export const IReportRoadAccidentPlaceholderServiceKey: InjectionKey<IPlaceholderDataService<ReportRoadAccident>> = Symbol('IPlaceholderDataService<ReportRoadAccident>');
export const IReportWantedCarPlaceholderServiceKey: InjectionKey<IPlaceholderDataService<ReportWantedCar>> = Symbol('IPlaceholderDataService<ReportWantedCar>');
export const IReportVehiclePhonePlaceholderServiceKey: InjectionKey<IPlaceholderDataService<ReportVehiclePhone>> = Symbol('IPlaceholderDataService<ReportVehiclePhone>');
export const IReportPledgesRegistryPlaceholderServiceKey: InjectionKey<IPlaceholderDataService<ReportPledgesRegistry>> = Symbol('IPlaceholderDataService<ReportPledgesRegistry>');
export const IReportVehicleRisksPlaceholderServiceKey: InjectionKey<IPlaceholderDataService<ReportVehicleRisks>> = Symbol('IPlaceholderDataService<ReportVehicleRisks>');
export const IReportVehiclePlaceholderServiceKey: InjectionKey<IPlaceholderDataService<ReportVehicle>> = Symbol('IPlaceholderDataService<ReportVehicle>');
export const IReportMentionPlaceholderServiceKey: InjectionKey<IPlaceholderDataService<ReportMention>> = Symbol('IPlaceholderDataService<ReportMention>');
export const IReportVehicleParkingPlaceholderServiceKey: InjectionKey<IPlaceholderDataService<ReportVehicleParking>> = Symbol('IPlaceholderDataService<ReportVehicleParking>');
export const IReportMarkerPlaceholderServiceKey: InjectionKey<IPlaceholderDataService<ReportMarker>> = Symbol('IPlaceholderDataService<ReportMarker>');
export const IReportAnalysisOfAbsoluteLiquidityPlaceholderServiceKey: InjectionKey<IPlaceholderDataService<ReportAnalysisOfAbsoluteLiquidity>> = Symbol('IPlaceholderDataService<ReportAnalysisOfAbsoluteLiquidity>');
export const IReportIndicatorsOfProfitabilityPlaceholderServiceKey: InjectionKey<IPlaceholderDataService<ReportIndicatorsOfProfitability>> = Symbol('IPlaceholderDataService<ReportIndicatorsOfProfitability>');
export const IReportIndicatorsOfCashFlowPlaceholderServiceKey: InjectionKey<IPlaceholderDataService<ReportIndicatorsOfCashFlow>> = Symbol('IPlaceholderDataService<ReportIndicatorsOfCashFlow>');
export const IReportSocialNetworkTrackPlaceholderServiceKey: InjectionKey<IPlaceholderDataService<ReportSocialNetworkTrack>> = Symbol('IPlaceholderDataService<ReportSocialNetworkTrack>');
export const IReportSocialNetworkSchoolPlaceholderServiceKey: InjectionKey<IPlaceholderDataService<ReportSocialNetworkSchool>> = Symbol('IPlaceholderDataService<ReportSocialNetworkSchool>');
export const IReportSocialNetworkSecondaryEducationPlaceholderServiceKey: InjectionKey<IPlaceholderDataService<ReportSocialNetworkSecondaryEducation>> = Symbol('IPlaceholderDataService<ReportSocialNetworkSecondaryEducation>');
export const IReportSocialNetworkHighEducationPlaceholderServiceKey: InjectionKey<IPlaceholderDataService<ReportSocialNetworkHighEducation>> = Symbol('IPlaceholderDataService<ReportSocialNetworkHighEducation>');
export const IReportSocialNetworkMilitaryRecordPlaceholderServiceKey: InjectionKey<IPlaceholderDataService<ReportSocialNetworkMilitaryRecord>> = Symbol('IPlaceholderDataService<ReportSocialNetworkMilitaryRecord>');
export const IReportSocialNetworkJobPlaceholderServiceKey: InjectionKey<IPlaceholderDataService<ReportSocialNetworkJob>> = Symbol('IPlaceholderDataService<ReportSocialNetworkJob>');
export const IReportPersonInnPlaceholderServiceKey: InjectionKey<IPlaceholderDataService<ReportPersonInn>> = Symbol('IPlaceholderDataService<ReportPersonInn>');
export const IReportPersonSnilsPlaceholderServiceKey: InjectionKey<IPlaceholderDataService<ReportPersonSnils>> = Symbol('IPlaceholderDataService<ReportPersonSnils>');
export const IReportPassportPlaceholderServiceKey: InjectionKey<IPlaceholderDataService<ReportPassport>> = Symbol('IPlaceholderDataService<ReportPassport>');
export const IReportBoundedPersonPhonePlaceholderServiceKey: InjectionKey<IPlaceholderDataService<ReportBoundedPersonPhone>> = Symbol('IPlaceholderDataService<ReportBoundedPersonPhone>');
export const IReportTagPlaceholderServiceKey: InjectionKey<IPlaceholderDataService<ReportTag>> = Symbol('IPlaceholderDataService<ReportTag>');
export const IReportEmailPlaceholderServiceKey: InjectionKey<IPlaceholderDataService<ReportEmail>> = Symbol('IPlaceholderDataService<ReportEmail>');
export const IReportPersonSitePlaceholderServiceKey: InjectionKey<IPlaceholderDataService<ReportPersonSite>> = Symbol('IPlaceholderDataService<ReportPersonSite>');
export const IReportSocialNetworkIdPlaceholderServiceKey: InjectionKey<IPlaceholderDataService<ReportSocialNetworkId>> = Symbol('IPlaceholderDataService<ReportSocialNetworkId>');
export const IReportEducationPlaceholderServiceKey: InjectionKey<IPlaceholderDataService<ReportEducation>> = Symbol('IPlaceholderDataService<ReportEducation>');
export const IReportJobPlaceholderServiceKey: InjectionKey<IPlaceholderDataService<ReportJob>> = Symbol('IPlaceholderDataService<ReportJob>');
export const IReportPaymentPlaceholderServiceKey: InjectionKey<IPlaceholderDataService<ReportPayment>> = Symbol('IPlaceholderDataService<ReportPayment>');
export const IReportColleaguePlaceholderServiceKey: InjectionKey<IPlaceholderDataService<ReportColleague>> = Symbol('IPlaceholderDataService<ReportColleague>');
export const IReportIndividualEntrepreneurPlaceholderServiceKey: InjectionKey<IPlaceholderDataService<ReportIndividualEntrepreneur>> = Symbol('IPlaceholderDataService<ReportIndividualEntrepreneur>');
export const IReportFinePlaceholderServiceKey: InjectionKey<IPlaceholderDataService<ReportFine>> = Symbol('IPlaceholderDataService<ReportFine>');
export const IReportPossibleFullNamePlaceholderServiceKey: InjectionKey<IPlaceholderDataService<ReportPossibleFullName>> = Symbol('IPlaceholderDataService<ReportPossibleFullName>');
export const IReportPossibleBirthdaysPlaceholderServiceKey: InjectionKey<IPlaceholderDataService<ReportPossibleBirthdays>> = Symbol('IPlaceholderDataService<ReportPossibleBirthdays>');
export const IReportMovementCovidPlaceholderServiceKey: InjectionKey<IPlaceholderDataService<ReportMovementCovid>> = Symbol('IPlaceholderDataService<ReportMovementCovid>');
export const IReportMovementTaxiPlaceholderServiceKey: InjectionKey<IPlaceholderDataService<ReportMovementTaxi>> = Symbol('IPlaceholderDataService<ReportMovementTaxi>');
export const IReportMessageBoardRecordPlaceholderServiceKey: InjectionKey<IPlaceholderDataService<ReportMessageBoardRecord>> = Symbol('IPlaceholderDataService<ReportMessageBoardRecord>');
export const IReportMessengerInfoPlaceholderServiceKey: InjectionKey<IPlaceholderDataService<ReportMessengerInfo>> = Symbol('IPlaceholderDataService<ReportMessengerInfo>');
export const IReportVettingInfoPlaceholderServiceKey: InjectionKey<IPlaceholderDataService<ReportVettingInfo>> = Symbol('IPlaceholderDataService<ReportVettingInfo>');
export const IReportConnectionRelatedPlaceholderDataServiceKey: InjectionKey<IPlaceholderDataService<ReportConnectionRelated>> = Symbol('IPlaceholderDataService<ReportConnectionRelated>');
export const IReportAddressPlaceholderServiceKey: InjectionKey<IPlaceholderDataService<ReportAddress>> = Symbol('IPlaceholderDataService<ReportAddress>');
export const IReportSocialNetworkPlaceholderServiceKey: InjectionKey<IPlaceholderDataService<ReportSocialNetwork>> = Symbol('IPlaceholderDataService<ReportSocialNetwork>');
export const IReportSocialNetworkAboutInfoPlaceholderServiceKey: InjectionKey<IPlaceholderDataService<ReportSocialNetworkAboutInfo>> = Symbol('IPlaceholderDataService<ReportSocialNetworkAboutInfo>');
export const IReportSocialNetworkLifePositionPlaceholderServiceKey: InjectionKey<IPlaceholderDataService<ReportSocialNetworkLifePosition>> = Symbol('IPlaceholderDataService<ReportSocialNetworkLifePosition>');
export const IReportSocialNetworkOtherPersonPlaceholderServiceKey: InjectionKey<IPlaceholderDataService<ReportSocialNetworkOtherPerson>> = Symbol('IPlaceholderDataService<ReportSocialNetworkOtherPerson>');
