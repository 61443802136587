import { IContainerBinder } from '~/configuration/inversify/containerAccessor.interface';
import { ILegalEntitiesParticipationMergeServiceKey } from '../../services/legalEntitiesParticipationMergeService.interfaces';
import { LegalEntitiesParticipationMergeService } from '../../services/legalEntitiesParticipationMergeService';
import { ILegalEntitiesParticipationConfigServiceKey } from '../../services/legalEntitiesParticipationConfigService.interfaces';
import { LegalEntitiesParticipationConfigService } from '../../services/legalEntitiesParticipationConfigService';
import { LegalEntitiesRoleParticipationConfigService } from '../../services/legalEntitiesRoleParticipationConfigService';
import { ILegalEntitiesRoleParticipationConfigServiceKey } from '../../services/legalEntitiesRoleParticipationConfigService.interfaces';

export function bindLegalEntitiesParticipationServices(bind: IContainerBinder) {
  bind(ILegalEntitiesParticipationMergeServiceKey).to(LegalEntitiesParticipationMergeService);
  bind(ILegalEntitiesParticipationConfigServiceKey).to(LegalEntitiesParticipationConfigService);
  bind(ILegalEntitiesRoleParticipationConfigServiceKey).to(LegalEntitiesRoleParticipationConfigService);
}
