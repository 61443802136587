import { AsYouType, parsePhoneNumberFromString } from 'libphonenumber-js';
import { IndividualPhoneVerificationCode } from '~/types/individual/phoneVerificationCode';

import { DELETE_NUMBERS_BEFORE_PLUS_REGEX } from '~/regex/phoneDeletingNumbersBeforePlus';
import { PHONE_SECTIONS_REGEX } from '~/regex/phoneSectionSeparation';

export module PhoneUtils {
  export function addPlus(phone: string) {
    if (phone.length === 0) return phone;

    if (phone[0] === '+') return phone;

    return `+${phone}`;
  }

  export function formatPhoneNumber(phone: string) {
    const asYouType = new AsYouType();

    let unrefPhone = unref(phone);
    if (!unrefPhone) return '';

    unrefPhone = addPlus(phone);

    asYouType.input(unrefPhone);

    if (asYouType.country?.toLowerCase() === 'ru') {
      // @ts-ignore
      const newPhone: string = asYouType.formattedOutput;

      const splitPhone = newPhone.split(' ');
      splitPhone[1] = `(${splitPhone[1]})`;

      return splitPhone.join(' ');
    }

    // @ts-ignore
    return asYouType.formattedOutput;
  }

  export function formatPhoneNumberWithDash(phone: string):string {
    const asYouType = new AsYouType();

    let unrefPhone = phone;
    if (!unrefPhone) return '';

    unrefPhone = addPlus(phone);

    asYouType.input(unrefPhone);

    // @ts-ignore
    const newPhone: string = asYouType.formattedOutput;

    const splitPhone = newPhone.split(' ');

    const splitPhoneRightPart = `(${splitPhone[1]}) ${splitPhone[2]}-${splitPhone[3]}-${splitPhone[4]}`;

    return `${splitPhone[0]} ${splitPhoneRightPart}`;
  }

  export function normalizePhoneNumber(phone: string): string {
    if (!phone) return '';

    const formattedPhone = parsePhoneNumberFromString(phone, 'RU')?.formatInternational();

    if (formattedPhone) {
      return formattedPhone?.replace(PHONE_SECTIONS_REGEX, '$1($2)$3');
    }

    return phone.replace(DELETE_NUMBERS_BEFORE_PLUS_REGEX, '');
  }

  export function getIsValidPhoneNumber(phone: string): boolean {
    const phoneNumber = normalizePhoneNumber(phone);
    const parsedPhoneNumber = parsePhoneNumberFromString(phoneNumber);

    return parsedPhoneNumber ? parsedPhoneNumber.isValid() && parsedPhoneNumber.country == 'RU' : false;
  }
}
