import { injectable } from 'inversify';
import { IIndividualMovementsAviasConfigService } from '~/modules/reportsCommon/reportMovement/individual/services/individualMovementsConfigService.interfaces';
import { IndividualAviasDTO } from '~/types/dto/individual/aviasDTO';
import { MOVEMENTS_FIELD_LIST_OPTIONS } from '~/modules/reportsCommon/reportMovement/common/configuration/constants';
import { Config } from '~/types/field-list/config';

@injectable()
export class IndividualMovementsAviasConfigService implements IIndividualMovementsAviasConfigService {
  getConfig(aviasMovements: IndividualAviasDTO): Config {
    return {
      ...MOVEMENTS_FIELD_LIST_OPTIONS,
      fields: [
        {
          key: 'FlightDate',
          label: 'Дата вылета',
          value: aviasMovements.FlightDate 
        },
        {
          key: 'FlightStartTime',
          label: 'Время вылета',
          value: aviasMovements.FlightStartTime 
        },
        {
          key: 'From',
          label: 'Аэропорт вылета',
          value: aviasMovements.From 
        },
        {
          key: 'To',
          label: 'Аэропорт прилета',
          value: aviasMovements.To 
        },
        {
          key: 'Airline',
          label: 'Авиакомпания',
          value: aviasMovements.Airline 
        },
        {
          key: 'FlightNumber',
          label: aviasMovements.FlightNumber.length < 10 ? 'Номер рейса' : 'Информация о рейсе',
          value: aviasMovements.FlightNumber
        },
        {
          key: 'AirlineCode', 
          label: 'Код авиакомпании',
          value: aviasMovements.AirlineCode 
        }
      ]
    };
  }
}
