import { InjectionKey } from 'vue';
import { RelationBoundedPhoneType } from '../types/relationBoundedPhoneType';
import { ReportBoundedPersonPhoneDTO } from '~/types/dto/common/boundedPersonPhoneDTO';

export interface IIndividualBoundedPhonesRelations {
  getData(boundedPhones: Array<ReportBoundedPersonPhoneDTO>): Array<RelationBoundedPhoneType>;
}

export type IIndividualBoundedPhonesRelationsService = IIndividualBoundedPhonesRelations;

export const IIndividualBoundedPhonesRelationsServiceKey: InjectionKey<IIndividualBoundedPhonesRelationsService> = Symbol('IIndividualBoundedPhonesRelationsService');
