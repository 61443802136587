import { injectable } from 'inversify';
import {
  IIndividualTerroristsAndExtremistsRecordAdapterService
} from '~/services/adapters/individual/individualAdapterService.interfaces';
import { IndividualTerroristsAndExtremistsRecord } from '~/types/individual/terroristsAndExtremistsRecord';
import { IndividualTerroristsAndExtremistsRecordDTO } from '~/types/dto/individual/terroristsAndExtremistsRecordDTO';
import { StringUtils } from '~/utils/stringUtils';

@injectable()
export class IndividualTerroristsAndExtremistsRecordAdapterService implements IIndividualTerroristsAndExtremistsRecordAdapterService {
  getData(initData: IndividualTerroristsAndExtremistsRecord[]): IndividualTerroristsAndExtremistsRecordDTO[] {
    return (initData ?? []).map((item) => ({
      Description: StringUtils.quoteReplacement(item.Description),
      LastName: StringUtils.quoteReplacement(item.LastName),
      FirstName: StringUtils.quoteReplacement(item.FirstName),
      Patronymic: StringUtils.quoteReplacement(item.Patronymic),
      OtherInfo: StringUtils.quoteReplacement(item.OtherInfo)
    }));
  }
}
