import { injectable, inject } from 'inversify';
import { ReportMarker } from '~/types/common/reportMarker';
import { IndividualMarkers } from '~/types/individual/markers';
import { IPrimitiveTypesNormalizationServiceKey, IPrimitiveTypesNormalizationService } from '../common/primitiveTypesNormalizationService.interfaces';
import { INormalizationService, IReportMarkerNormalizationServiceKey } from '../common/reportNormalizationService.interfaces';

@injectable()
export class IndividualMarkersNormalizationService implements INormalizationService<IndividualMarkers> {
  constructor(
    @inject(IReportMarkerNormalizationServiceKey as symbol) private reportMarkerNormalizationService: INormalizationService<ReportMarker>,

    @inject(IPrimitiveTypesNormalizationServiceKey as symbol) private primitiveTypesNormalizationService: IPrimitiveTypesNormalizationService,
  ) {}

  normalize(data: any, root: string): IndividualMarkers | undefined {
    const { normalizeObject, normalizeNumber, normalizeArray } = this.primitiveTypesNormalizationService;

    return normalizeObject(data, (data) => ({
      Markers: normalizeArray(data.Markers, (item, field) => this.reportMarkerNormalizationService.normalize(item, field), `${root}.Markers`),
      Rate: normalizeNumber(data.Rate, `${root}.Rate`),
      RateIndicator: normalizeNumber(data.RateIndicator, `${root}.RateIndicator`)
    }), root);
  }
}
