import { injectable, inject } from 'inversify';
import { get } from 'lodash-es';
import { ILegalEntitiesLiquidityAndSolvencyTableConfigService } from '~/modules/legalEntities/financialAnalytics/legalEntitiesLiquidityAndSolvency/services/legalEntitiesLiquidityAndSolvencyTableConfigService.interface';
import { LegalEntitiesReportFinancialIndicatorBaseDTO } from '~/types/dto/legalEntities/reportFinancialIndicatorBaseDTO';
import { LegalEntitiesReportLiquidityAndSolvencyDTO } from '~/types/dto/legalEntities/reportLiquidityAndSolvency';
import { TableHeader } from '~/modules/table/types/tableHeader';
import { TableItem } from '~/modules/table/types/tableItem';
import {
  IDegreeOfSolvencyTableItemConfigService,
  IDegreeOfSolvencyTableItemConfigServiceKey,
  IOwnWorkingCapitalTableItemConfigService,
  IOwnWorkingCapitalTableItemConfigServiceKey,
  IRatioOfCurrentLiquidityTableItemConfigService,
  IRatioOfCurrentLiquidityTableItemConfigServiceKey,
  IRatioOfReceivablesToTotalAssetsTableItemConfigService,
  IRatioOfReceivablesToTotalAssetsTableItemConfigServiceKey,
  IRatioOfSolvencyTableItemConfigService,
  IRatioOfSolvencyTableItemConfigServiceKey
} from '~/modules/legalEntities/financialAnalytics/legalEntitiesLiquidityAndSolvency/services/legalEntitiesLiquidityAndSolvencyTableItemConfigService.interfaces';

@injectable()
export class LegalEntitiesLiquidityAndSolvencyTableConfigService implements ILegalEntitiesLiquidityAndSolvencyTableConfigService {
  constructor(
    @inject(IOwnWorkingCapitalTableItemConfigServiceKey as symbol) private ownWorkingCapitalTableItemConfigService: IOwnWorkingCapitalTableItemConfigService,
    @inject(IRatioOfCurrentLiquidityTableItemConfigServiceKey as symbol) private ratioOfCurrentLiquidityTableItemConfigService: IRatioOfCurrentLiquidityTableItemConfigService,
    @inject(IRatioOfSolvencyTableItemConfigServiceKey as symbol) private ratioOfSolvencyTableItemConfigService: IRatioOfSolvencyTableItemConfigService,
    @inject(IDegreeOfSolvencyTableItemConfigServiceKey as symbol) private degreeOfSolvencyTableItemConfigService: IDegreeOfSolvencyTableItemConfigService,
    @inject(IRatioOfReceivablesToTotalAssetsTableItemConfigServiceKey as symbol) private ratioOfReceivablesToTotalAssetsTableItemConfigService: IRatioOfReceivablesToTotalAssetsTableItemConfigService
  ) {
  }
  getHeaders(initData: Array<LegalEntitiesReportFinancialIndicatorBaseDTO>): Array<TableHeader> {
    return [
      {
        label: 'Показатель',
        key: 'indicator',
        width: '37%'
      },
      {
        label: String(get(initData, '0.TwoYearsAgo', '')),
        key: 'TwoYearsAgo',
        width: '14%'
      },
      {
        label: String(get(initData, '0.LastYear', '')),
        key: 'LastYear',
        width: '14%'
      },
      {
        label: String(get(initData, '0.CurrentYear', '')),
        key: 'CurrentYear',
        width: '14%'
      },
      {
        label: 'Референсное значение',
        key: 'referenceValue',
        width: '18%'
      },
    ];
  }

  getItems(initData: Array<LegalEntitiesReportLiquidityAndSolvencyDTO>): Array<TableItem> {
    return initData.map((tableItem) => [
      this.ownWorkingCapitalTableItemConfigService.getItem(tableItem.OwnWorkingCapital),
      this.ratioOfCurrentLiquidityTableItemConfigService.getItem(tableItem.RatioOfCurrentLiquidity),
      this.ratioOfSolvencyTableItemConfigService.getItem(tableItem.RatioOfSolvency),
      this.degreeOfSolvencyTableItemConfigService.getItem(tableItem.DegreeOfSolvency),
      this.ratioOfReceivablesToTotalAssetsTableItemConfigService.getItem(tableItem.RatioOfReceivablesToTotalAssets)
    ]);
  }
}
