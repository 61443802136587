import { uniq } from 'lodash-es';
import { injectable } from 'inversify';
import { MergeService } from '~/services/merge/merge-service';
import { IndividualAviasDTO } from '~/types/dto/individual/aviasDTO';

@injectable()
export class IndividualAviasMergeService extends MergeService<IndividualAviasDTO> {
  protected canMerge(leftItem: IndividualAviasDTO, rightItem: IndividualAviasDTO): boolean {
    return leftItem.FlightDate === rightItem.FlightDate
        && leftItem.From === rightItem.From
        && leftItem.To === rightItem.To
        && leftItem.Airline === rightItem.Airline;
  }

  protected mergeFields(leftItem: IndividualAviasDTO, rightItem: IndividualAviasDTO): IndividualAviasDTO {
    const companionsUniq = uniq([...leftItem.Companions, ...rightItem.Companions]);
    const accompanyingPersons = uniq([...leftItem.AccompanyingPersons, ...rightItem.AccompanyingPersons]);

    return {
      FlightDate: leftItem.FlightDate || rightItem.FlightDate,
      SourceFlightDate: leftItem.SourceFlightDate || rightItem.SourceFlightDate,
      FlightStartTime: leftItem.FlightStartTime || rightItem.FlightStartTime,
      From: leftItem.From || rightItem.From,
      To: leftItem.To || rightItem.To,
      FlightNumber: leftItem.FlightNumber || rightItem.FlightNumber,
      Airline: leftItem.Airline || rightItem.Airline,
      AirlineCode: leftItem.AirlineCode || rightItem.AirlineCode,
      Companions: companionsUniq,
      AccompanyingPersons: accompanyingPersons,
      FullName: leftItem.FullName || rightItem.FullName,
      Passport: leftItem.Passport || leftItem.Passport,
    };
  }
}
