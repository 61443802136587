import { injectable, inject } from 'inversify';
import { LegalEntitiesPredecessor } from '~/types/legal-entities/predecessor';
import { IPrimitiveTypesNormalizationServiceKey, IPrimitiveTypesNormalizationService } from '../common/primitiveTypesNormalizationService.interfaces';
import { INormalizationService } from '../common/reportNormalizationService.interfaces';

@injectable()
export class LegalEntitiesPredecessorNormalizationService implements INormalizationService<LegalEntitiesPredecessor> {
  constructor(
    @inject(IPrimitiveTypesNormalizationServiceKey as symbol) private primitiveTypesNormalizationService: IPrimitiveTypesNormalizationService,
  ) {}

  normalize(data: any, root: string): LegalEntitiesPredecessor | undefined {
    const {
      normalizeString,
      normalizeOptionalString,
      normalizeObject
    } = this.primitiveTypesNormalizationService;

    return normalizeObject(data, (data) => ({
      OrganisationName: normalizeString(data.OrganisationName, `${root}.OrganisationName`),
      Inn: normalizeOptionalString(data.Inn, `${root}.Inn`),
      Ogrn: normalizeString(data.Ogrn, `${root}.Ogrn`),
      Date: normalizeOptionalString(data.Date, `${root}.Date`),
      Status: normalizeOptionalString(data.Status, `${root}.Status`),
    }), root);
  }
}
