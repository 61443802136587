import { injectable, inject } from 'inversify';
import { IPlaceholderDataService } from '~/services/placeholder/common/reportPlaceholderService.interfaces';
import { ILegalEntitiesReportFinancialIndicatorWithDynamicPlaceholderDataServiceKey, ILegalEntitiesReportFinancialIndicatorWithConclusionsPlaceholderDataServiceKey, ILegalEntitiesReportFinancialIndicatorBasePlaceholderDataServiceKey } from '~/services/placeholder/legalEntities/legalEntitiesPlaceholderDataService.interfaces';
import { LegalEntitiesFinancialAnalysisShort } from '~/types/legal-entities/financialAnalysisShort';
import { LegalEntitiesReportFinancialIndicatorBase } from '~/types/legal-entities/reportFinancialIndicatorBase';
import { LegalEntitiesReportFinancialIndicatorWithConclusions } from '~/types/legal-entities/reportFinancialIndicatorWithConclusions';
import { LegalEntitiesReportFinancialIndicatorWithDynamic } from '~/types/legal-entities/reportFinancialIndicatorWithDynamic';
import { IPrimitiveTypesNormalizationServiceKey, IPrimitiveTypesNormalizationService } from '../common/primitiveTypesNormalizationService.interfaces';
import { INormalizationService } from '../common/reportNormalizationService.interfaces';
import {
  ILegalEntitiesReportFinancialIndicatorBaseNormalizationServiceKey,
  ILegalEntitiesReportFinancialIndicatorWithConclusionsNormalizationServiceKey,
  ILegalEntitiesReportFinancialIndicatorWithDynamicNormalizationServiceKey
} from './legalEntitiesNormalizationService.interfaces';

@injectable()
export class LegalEntitiesFinancialAnalysisShortNormalizationService implements INormalizationService<LegalEntitiesFinancialAnalysisShort> {
  constructor(
    @inject(ILegalEntitiesReportFinancialIndicatorWithDynamicNormalizationServiceKey as symbol) private reportFinancialIndicatorWithDynamicNormalizationService: INormalizationService<LegalEntitiesReportFinancialIndicatorWithDynamic>,
    @inject(ILegalEntitiesReportFinancialIndicatorWithConclusionsNormalizationServiceKey as symbol) private reportFinancialIndicatorWithConclusionsNormalizationService: INormalizationService<LegalEntitiesReportFinancialIndicatorWithConclusions>,
    @inject(ILegalEntitiesReportFinancialIndicatorBaseNormalizationServiceKey as symbol) private reportFinancialIndicatorBaseNormalizationService: INormalizationService<LegalEntitiesReportFinancialIndicatorBase>,

    @inject(ILegalEntitiesReportFinancialIndicatorWithDynamicPlaceholderDataServiceKey as symbol) private reportFinancialIndicatorWithDynamicPlaceholderDataService: IPlaceholderDataService<LegalEntitiesReportFinancialIndicatorWithDynamic>,
    @inject(ILegalEntitiesReportFinancialIndicatorWithConclusionsPlaceholderDataServiceKey as symbol) private reportFinancialIndicatorWithConclusionsPlaceholderDataService: IPlaceholderDataService<LegalEntitiesReportFinancialIndicatorWithConclusions>,
    @inject(ILegalEntitiesReportFinancialIndicatorBasePlaceholderDataServiceKey as symbol) private reportFinancialIndicatorBasePlaceholderDataService: IPlaceholderDataService<LegalEntitiesReportFinancialIndicatorBase>,

    @inject(IPrimitiveTypesNormalizationServiceKey as symbol) private primitiveTypesNormalizationService: IPrimitiveTypesNormalizationService
  ) {}

  normalize(data: any, root: string): LegalEntitiesFinancialAnalysisShort | undefined {
    const {
      normalizeBoolean,
      normalizeNumber,
      normalizeOptionalInteger,
      normalizeObject
    } = this.primitiveTypesNormalizationService;

    return normalizeObject(data, (data) => ({
      IsExists: normalizeBoolean(data.IsExists, `${root}.IsExists`),
      TwoYearsAgo: normalizeNumber(data.TwoYearsAgo, `${root}.TwoYearsAgo`),
      LastYear: normalizeOptionalInteger(data.LastYear, `${root}.LastYear`),
      CurrentYear: normalizeOptionalInteger(data.CurrentYear, `${root}.CurrentYear`),
      Revenues: this.reportFinancialIndicatorWithDynamicNormalizationService.normalize(data.Revenues, `${root}.Revenues`) ?? this.reportFinancialIndicatorWithDynamicPlaceholderDataService.getData(),
      ProfitFromSales: this.reportFinancialIndicatorWithDynamicNormalizationService.normalize(data.ProfitFromSales, `${root}.ProfitFromSales`) ?? this.reportFinancialIndicatorWithDynamicPlaceholderDataService.getData(),
      Ebit: this.reportFinancialIndicatorWithDynamicNormalizationService.normalize(data.Ebit, `${root}.Ebit`) ?? this.reportFinancialIndicatorWithDynamicPlaceholderDataService.getData(),
      DebtShare: this.reportFinancialIndicatorWithDynamicNormalizationService.normalize(data.DebtShare, `${root}.DebtShare`) ?? this.reportFinancialIndicatorWithDynamicPlaceholderDataService.getData(),
      DebtEbit: this.reportFinancialIndicatorWithConclusionsNormalizationService.normalize(data.DebtEbit, `${root}.DebtEbit`) ?? this.reportFinancialIndicatorWithConclusionsPlaceholderDataService.getData(),
      EbitPercent: this.reportFinancialIndicatorWithConclusionsNormalizationService.normalize(data.EbitPercent, `${root}.EbitPercent`) ?? this.reportFinancialIndicatorWithConclusionsPlaceholderDataService.getData(),
      EbitMargin: this.reportFinancialIndicatorBaseNormalizationService.normalize(data.EbitMargin, `${root}.EbitMargin`) ?? this.reportFinancialIndicatorBasePlaceholderDataService.getData(),
      OwnCapital: this.reportFinancialIndicatorBaseNormalizationService.normalize(data.OwnCapital, `${root}.OwnCapital`) ?? this.reportFinancialIndicatorBasePlaceholderDataService.getData(),
    }), root);
  }
}
