import { injectable } from 'inversify';
import { IReportPledgesRegistryAdapterService } from '~/services/adapters/common/reportAdapterService.interfeces';
import { ReportPledgesRegistry } from '~/types/common/reportPledgesRegistry';
import { ReportPledgesRegistryDTO } from '~/types/dto/common/reportPledgesRegistry';
import { DateUtils } from '~/utils/date';
import { StringUtils } from '~/utils/stringUtils';

@injectable()
export class ReportPledgesRegistryAdapterService implements IReportPledgesRegistryAdapterService {
  getData(initData: ReportPledgesRegistry[]): ReportPledgesRegistryDTO[] {
    return (initData ?? []).map((pledge) => ({
      RegistrationDate: DateUtils.formatDate(pledge.RegistrationDate),
      ExclusionDate: DateUtils.formatDate(pledge.ExclusionDate),
      Property: StringUtils.quoteReplacement(pledge.Property ?? ''),
      RegistrationNumber: pledge.RegistrationNumber,
      Pledger: StringUtils.quoteReplacement(pledge.Pledger ?? ''),
      Pledgee: StringUtils.quoteReplacement(pledge.Pledgee ?? ''),
    }));
  }
}
