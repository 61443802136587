import { injectable, inject } from 'inversify';

import { ReportMainEntityFile } from '~/types/common/reportMainEntityFile';
import { IndividualCommonInfo } from '~/types/individual/commonInfo';
import { IPlaceholderDataService, IReportMainEntityFilePlaceholderServiceKey } from '../../placeholder/common/reportPlaceholderService.interfaces';
import { IPrimitiveTypesNormalizationServiceKey, IPrimitiveTypesNormalizationService } from '../common/primitiveTypesNormalizationService.interfaces';
import { INormalizationService, IReportMainEntityFileNormalizationServiceKey } from '../common/reportNormalizationService.interfaces';

@injectable()
export class IndividualCommonInfoNormalizationService implements INormalizationService<IndividualCommonInfo> {
  constructor(
    @inject(IPrimitiveTypesNormalizationServiceKey as symbol) private primitiveTypesNormalizationService: IPrimitiveTypesNormalizationService,

    @inject(IReportMainEntityFileNormalizationServiceKey as symbol) private reportMainEntityFileNormalizationService: INormalizationService<ReportMainEntityFile>,

    @inject(IReportMainEntityFilePlaceholderServiceKey as symbol) private reportMainEntityFilePlaceholderService: IPlaceholderDataService<ReportMainEntityFile>,
  ) {}

  normalize(data: any, root: string): IndividualCommonInfo | undefined {
    const { normalizeObject, normalizeString, normalizeOptionalString } = this.primitiveTypesNormalizationService;
    return normalizeObject(data, (data) => ({
      LastUpdate: normalizeOptionalString(data.LastUpdate, `${root}.LastUpdate`),
      FullName: normalizeString(data.FullName, `${root}.FullName`),
      Gender: normalizeString(data.Gender, `${root}.Gender`),
      Birthday: normalizeOptionalString(data.Birthday, `${root}.Birthday`),
      DeathDate: normalizeOptionalString(data.DeathDate, `${root}.DeathDate`),
      BirthPlace: normalizeString(data.BirthPlace, `${root}.BirthPlace`),
      Note: normalizeString(data.Note, `${root}.Note`),
      MainPhoto: this.reportMainEntityFileNormalizationService.normalize(data.MainPhoto, `${root}.MainPhoto`) ?? this.reportMainEntityFilePlaceholderService.getData(),
      Citizenship: normalizeString(data.Citizenship, `${root}.Citizenship`),
      Nationality: normalizeString(data.Nationality, `${root}.Nationality`),
      MaritalStatus: normalizeString(data.MaritalStatus, `${root}.MaritalStatus`),
    }), root);
  }
}
