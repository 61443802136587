import { StringUtils } from '~/utils/stringUtils';
import { MergeService } from './merge-service';
import { ReportVehicleDTO } from '~/types/dto/common/reportVehicleDTO';

export class LegalEntitiesVehiclesMergeService extends MergeService<ReportVehicleDTO> {
  protected canMerge(leftItem: ReportVehicleDTO, rightItem: ReportVehicleDTO): boolean {
    return StringUtils.cyrillicToTranslit(leftItem.Vin) === StringUtils.cyrillicToTranslit(rightItem.Vin);
  }
  
  protected mergeFields(leftItem: ReportVehicleDTO, rightItem: ReportVehicleDTO): ReportVehicleDTO {
    return {
      CarNumber: leftItem.CarNumber || rightItem.CarNumber,
      CarNumberRegion: leftItem.CarNumberRegion || rightItem.CarNumberRegion,
      Color: leftItem.Color || rightItem.Color,
      FullCarNumber: leftItem.FullCarNumber || rightItem.FullCarNumber,
      OwnersCount: leftItem.OwnersCount || rightItem.OwnersCount,
      OwnershipHistory: leftItem.OwnershipHistory || rightItem.OwnershipHistory,
      OwnerShipPeriod: leftItem.OwnerShipPeriod || rightItem.OwnerShipPeriod,
      PhotoUrls: leftItem.PhotoUrls || rightItem.PhotoUrls,
      PledgesRegistries: leftItem.PledgesRegistries || rightItem.PledgesRegistries,
      Relations: leftItem.Relations || rightItem.Relations,
      Restricts: leftItem.Restricts || rightItem.Restricts,
      RoadAccidents: leftItem.RoadAccidents || rightItem.RoadAccidents,
      VehicleInsurances: leftItem.VehicleInsurances || rightItem.VehicleInsurances,
      Vin: leftItem.Vin || rightItem.Vin,
      Wanted: leftItem.Wanted || rightItem.Wanted,
      Year: leftItem.Year || rightItem.Year,
      Brand: leftItem.Brand || rightItem.Brand,
      Model: leftItem.Model || rightItem.Model,
      Risks: leftItem.Risks || rightItem.Risks,
    };
  }
}
