import { injectable, inject } from 'inversify';
import { ReportSocialNetworkTrack } from '~/types/common/reportSocialNetworkTrack';
import { PhoneNumberRelatedPhonePerson } from '~/types/phone-number/relatedPhonePerson';
import { PhoneNumberRelatedPhonePersonCommon } from '~/types/phone-number/relatedPhonePersonCommon';
import { IPhoneNumberRelatedPhonePersonCommonPlaceholderServiceKey } from './phoneNumberPlaceholderService.interface';
import { IPlaceholderDataService, IReportSocialNetworkTrackPlaceholderServiceKey } from '~/services/placeholder/common/reportPlaceholderService.interfaces';

@injectable()
export class PhoneNumberRelatedPhonePersonPlaceholderService implements IPlaceholderDataService<PhoneNumberRelatedPhonePerson> {
  constructor(
    @inject(IPhoneNumberRelatedPhonePersonCommonPlaceholderServiceKey as symbol) private phoneNumberRelatedPhonePersonCommonPlaceholderService: IPlaceholderDataService<PhoneNumberRelatedPhonePersonCommon>,
    @inject(IReportSocialNetworkTrackPlaceholderServiceKey as symbol) private reportSocialNetworkTrackPlaceholderService: IPlaceholderDataService<ReportSocialNetworkTrack>
  ) {}

  getData(): PhoneNumberRelatedPhonePerson {
    return {
      Common: this.phoneNumberRelatedPhonePersonCommonPlaceholderService.getData(),
      PossibleFios: [],
      Inns: [],
      Snilses: [],
      Passports: [],
      Emails: [],
      Sites: [],
      SocialNetworks: [],
      Addresses: [],
      Vehicles: [],
      Educations: [],
      Jobs: [],
      LegalEntitiesParticipation: [],
      IndividualEntrepreneurs: [],
      SocialNetworkTrack: this.reportSocialNetworkTrackPlaceholderService.getData(),
      BoundedPersonPhones: [],
      VehicleParkings: [],
      CovidMovements: [],
      TaxiMovements: [],
      PhotoLinks: [],
      MessageBoards: [],
      PossibleBirthdays: [],
    };
  }
}
