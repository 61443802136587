/* eslint-disable class-methods-use-this, max-len */

import { injectable } from 'inversify';
import { LegalEntitiesActivitySummary } from '~/types/legal-entities/activitySummary';
import { LegalEntitiesActivitySummaryDTO } from '~/types/dto/legalEntities/activitySummaryDTO';
import {
  ILegalEntitiesActivitySummaryAdapterService
} from '~/services/adapters/legalEntities/legalEntitiesAdapterService.interfaces';

@injectable()
export class LegalEntitiesActivitySummaryAdapterService implements ILegalEntitiesActivitySummaryAdapterService {
  getData(initData: LegalEntitiesActivitySummary): LegalEntitiesActivitySummaryDTO {
    return {
      ActualCount: initData.ActualCount,
      ArchiveCount: initData.ArchiveCount
    };
  }
}
