import { inject, injectable } from 'inversify';
import { get } from 'lodash-es';
import { INetAssetsTableItemConfigService } from '~/modules/legalEntities/financialAnalytics/legalEntitiesFinancialStability/services/legalEntitiesFinancialStabilityTableItemConfigService.interfaces';
import { TableItem } from '~/types/tableItem';
import { NumberUtils } from '~/utils/number';
import { LegalEntitiesReportFinancialIndicatorWithConclusionsDTO } from '~/types/dto/legalEntities/reportFinancialIndicatorWithConclusionsDTO';
import {
  IFinancialAnalysisCommonTitlesService,
  IFinancialAnalysisCommonTitlesServiceKey
} from '~/modules/legalEntities/financialAnalytics/common/services/financialAnalysisCommonTitlesService.interfaces';
import {
  FINANCIAL_STABILITY_TITLES
} from '~/modules/legalEntities/financialAnalytics/common/configuration/constants/financialStabilityTitles';
import {
  IConvertingPrimitiveTableTypes,
  IConvertingPrimitiveTableTypesKey
} from '~/modules/legalEntities/financialAnalytics/common/services/convertingPrimitiveTableTypes.interfaces';

@injectable()
export class NetAssetsTableItemConfigService implements INetAssetsTableItemConfigService {
  constructor(
    @inject(IFinancialAnalysisCommonTitlesServiceKey as symbol) private commonTitlesService: IFinancialAnalysisCommonTitlesService,
    @inject(IConvertingPrimitiveTableTypesKey as symbol) private convertingPrimitiveTableTypes: IConvertingPrimitiveTableTypes
  ) {}

  getItem(initData: LegalEntitiesReportFinancialIndicatorWithConclusionsDTO): TableItem {
    const yearsValueList = [Math.abs(initData.TwoYearsAgo), Math.abs(initData.LastYear), Math.abs(initData.CurrentYear)];

    const maxValue = Math.max(...yearsValueList);

    return {
      TwoYearsAgo: { value: this.convertingPrimitiveTableTypes.currencyConversion(initData.TwoYearsAgo, maxValue), color: this.getColor(initData.TwoYearsAgo) },
      LastYear: { value: this.convertingPrimitiveTableTypes.currencyConversion(initData.LastYear, maxValue), color: this.getColor(initData.LastYear) },
      CurrentYear: { value: this.convertingPrimitiveTableTypes.currencyConversion(initData.CurrentYear, maxValue), color: this.getColor(initData.CurrentYear) },
      Conclusions: get(initData.Conclusions, 0, '—'),
      indicator: {
        title: this.commonTitlesService.getTitle(FINANCIAL_STABILITY_TITLES.NetAssets, yearsValueList),
      },
      referenceValue: '> 0',
    };
  }

  getColor(value: number):string {
    return value > 0 ? 'green' : 'red';
  }
}
