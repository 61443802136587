import { injectable } from "inversify";
import { PageType } from "../types/pageType";
import { IPageTypeService } from "./pageTypeService.interfaces";

@injectable()
export class PageTypeService implements IPageTypeService {
  getPageTypeByRouteName(routeName: string): PageType {
    switch (routeName) {
      case 'report-vehicle': return PageType.vehiclePage;
      case 'report-legal-entities': return PageType.legalEntitiesPage;
      case 'report-individual': return PageType.individualPage;
      case 'report-phone-number': return PageType.phoneNumberPage;
      case '404': return PageType.notFoundPage;
      default: return PageType.unknown;
    }
  }
}