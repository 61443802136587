import { get, isArray } from 'lodash-es';
import { NUMBER_DIGIT_GROUPS_EXTRACTION_REGEX } from '~/regex/numberDigitGroupsExtraction';
import { DECIMAL_SEARCH_REGEX } from '~/regex/numberDecimalSearch';
import { StringUtils } from './stringUtils';
import { Constants } from '~/utils/constants';

export module NumberUtils {

  export function findPostfixQualifiers(num: number) {
    return [...Constants.POSTFIX_QUALIFIERS].reverse().find((item) => num >= item.value);
  }

  export function getMaxAbs(numbers: Array<number>): number {
    return Math.max(...numbers.map((number) => Math.abs(number)));
  }

  export const getPostfix = (num: number) => findPostfixQualifiers(Math.abs(num))?.symbol || '';
  export const postfixFormat = (num: number, digits: number = 1): string => {
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    const item = findPostfixQualifiers(Math.abs(num));
    return item ? (num / item.value).toFixed(digits).replace(rx, '$1') + item.symbol : '0';
  };
  
  /**
  @deprecated use formatByMaxValue
   */
  export const formatByMax = (num: number, maxNum = num, digits = 1, roundBorderline = 0): number => {
    const item = findPostfixQualifiers(maxNum);
    let presentValue = num;

    if (item) {
      presentValue = num / item.value;
    }
    if (Math.abs(presentValue) < roundBorderline) {
      return presentValue;
    }
    return +presentValue.toFixed(item ? digits : 3);
  };

  export function formatByMaxValue(value: number, maxValue = value) {
    const lookupItem = findPostfixQualifiers(maxValue);

    if (lookupItem !== undefined) return value / lookupItem.value;
    return value;
  }

  /**
   @deprecated
   */
  export const getMaxFromObject = (obj?: Record<string, any>) => (obj
    ? Math.max(
      ...Object.values(obj)
        .filter((data) => data !== null && !Number.isNaN(Number(data)))
        .map((num) => Math.abs(Number(num)))
    )
    : 0);

  // Разделение целой части числа на группы рядов.
  export function formatNumberDigits(
    unformattedNumber: number | undefined | null,
    fractionDigits: number = 0,
    defaultValue: string = '0',
  ): string {
    if (unformattedNumber == undefined) return defaultValue;

    // Если пришло целое число, тогда отдаем как есть.
    // Если пришло дробное и число округления, то форматируем до указанного знака после запятой.
    const formattedValue = !Number.isInteger(unformattedNumber)
      ? String(unformattedNumber.toFixed(fractionDigits)) : String(unformattedNumber);
    // Получаем целую и дробную части
    const [_, integerValue, decimalValue]: string[] = formattedValue.match(DECIMAL_SEARCH_REGEX) ?? [];
    if (integerValue == undefined || decimalValue == undefined) return defaultValue;
    // Целую часть делим на группы рядов при помощи регулярки, после склеиваем число обратно
    const formattedIntegerValue = integerValue.replace(NUMBER_DIGIT_GROUPS_EXTRACTION_REGEX, ' ');

    return `${formattedIntegerValue}${decimalValue}`;
  }

  /**
   @deprecated
   */
  export function transformObject(
    obj: Record<string, string | number | string[] | undefined | null> | undefined,
    headers: Record<string | 'value', string | number>[],
    config?: { isPercent?: boolean, },
    digits?: number,
    checkDecimalValue?: boolean
  ) {
    if (!obj) return {};
    // получаем список полей для дальнейшей работы
    const headersToRound = checkDecimalValue 
      ? ['TwoYearsAgo', 'LastYear', 'CurrentYear'] 
      : ['TwoYearsAgoToLastYear', 'LastYearToCurrentYear', 'TwoYearsAgo', 'LastYear', 'CurrentYear'];

    // получаем максимальное число из объекта
    const max = NumberUtils.getMaxFromObject(obj);

    return [...headers.map(({ value }) => String(value)),
      // получаем массив ключей из объекта
      ...Object.keys(obj)].reduce<Record<keyof typeof obj, string>>((acc, key) => {
      // получаем значение каждого ключа, если null то устанавливаем как -
        const value = obj[key] ?? '—';

        // ??
        function currentEl(num: number): number | undefined {
          return headersToRound.includes(key) ? NumberUtils.formatByMax(num, max, digits) : num;
        }

        // регистрация нового значения
        let newValue: null | string | number = null;

        if (isArray(value) && value.length > 0 && get(value, 0) !== '') {
          acc[key] = value.join('\n');
          return acc;
        } else if (isArray(value)) {
          acc[key] = '—';
          return acc;
        }

        if (Number.isNaN(Number(value))) {
          newValue = String(value);
        } else if (typeof value === 'number' && value === 0) {
          newValue = '0';
        } else if (typeof value === 'number' && Math.abs(value) < 0.01) {
          newValue = '0.01<';
        } else {
          const valueTo = Number(config?.isPercent ? (Number(value) * 100) : value);
          newValue = Number.isInteger(Number(value))
            ? formatNumberDigits((currentEl(valueTo)), digits ?? 2, '—')
            : Number(currentEl(valueTo)).toFixed(digits ?? 2);
        }
        
        const isLessThenOne = newValue !== '0.01<';
        const resultValue = checkDecimalValue && isLessThenOne && typeof newValue === 'number' ? Number(newValue) : newValue; 

        acc[key] = String(resultValue) ?? '—';
        
        return acc;
      }, {});
  }

  export function formattedToNumber(value: string): number {
    if (StringUtils.isStringNotEmpty(value)) {
      const clearedValue = Number(value.replaceAll(' ', ''));
  
      return clearedValue ?? 0;
    }
    return 0;
  }

  export function toFixedAuto(value: number): string {
    const stringValue = String(value).toLowerCase();

    if (stringValue.includes('e-')) {
      const [_, fraction] = stringValue.split('e-');

      return value.toFixed(Number(fraction));
    }

    return stringValue;
  }
}
