/* eslint-disable class-methods-use-this, max-len */
import { injectable } from 'inversify';
import { LegalEntitiesCompanyStatedCapital } from '~/types/legal-entities/companyStatedCapital';
import { StringUtils } from '~/utils/stringUtils';
import {
  ILegalEntitiesCompanyStatedCapitalAdapterService
} from '~/services/adapters/legalEntities/legalEntitiesAdapterService.interfaces';
import { LegalEntitiesCompanyStatedCapitalDTO } from '~/types/dto/legalEntities/companyStatedCapitalDTO';

@injectable()
export class LegalEntitiesCompanyStatedCapitalAdapterService implements ILegalEntitiesCompanyStatedCapitalAdapterService {
  getData(initData: LegalEntitiesCompanyStatedCapital[]): LegalEntitiesCompanyStatedCapitalDTO[] {
    return (initData ?? []).map((item) => ({
      From: StringUtils.quoteReplacement(item.From ?? ''),
      Sum: item.Sum ?? 0,
      To: StringUtils.quoteReplacement(item.To ?? '')
    }));
  }
}
