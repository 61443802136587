import { injectable } from 'inversify';
import { IReportPersonSnilsAdapterService } from '~/services/adapters/common/reportAdapterService.interfeces';
import { ReportPersonSnils } from '~/types/common/reportPersonSnils';
import { ReportPersonSnilsDTO } from '~/types/dto/common/reportPersonSnilsDTO';
import { StringUtils } from '~/utils/stringUtils';

@injectable()
export class ReportPersonSnilsAdapterService implements IReportPersonSnilsAdapterService {
  getData(initData: ReportPersonSnils[]): ReportPersonSnilsDTO[] {
    return (initData ?? []).map((item) => ({
      Value: (item.Value ?? '').replace(/^(\d{3})(\d{3})(\d{3})(\d{2})$/, '$1-$2-$3-$4'),
      CorrespondingPerson: StringUtils.quoteReplacement(item.CorrespondingPerson)
    }))
      .filter((snils) => StringUtils.isStringNotEmpty(snils.Value));
  }
}
