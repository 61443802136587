import { injectable } from 'inversify';
import { ILegalEntitiesPhoneNumberSiftingService } from '~/modules/legalEntities/commonInfo/legalEntitiesPhoneNumbers/services/legalEntitiesPhoneNumberSiftingService.interfaces';
import { LegalEntitiesCompanyPhoneDTO } from '~/types/dto/legalEntities/companyPhoneDTO';

@injectable()
export class LegalEntitiesPhoneNumberSiftingService implements ILegalEntitiesPhoneNumberSiftingService {
  getSiftedData(initData: LegalEntitiesCompanyPhoneDTO[]): LegalEntitiesCompanyPhoneDTO[] {
    return initData.filter(phone => phone.Phone);
  }
}
