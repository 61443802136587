import { injectable, inject } from 'inversify';
import {
  IReportFineAdapterService,
  IReportMainEntityFileAdapterService,
  IReportMainEntityFileAdapterServiceKey
} from '~/services/adapters/common/reportAdapterService.interfeces';
import {
  IArrayAdapterService,
  IReportArrayAdapterServiceKey
} from '~/services/adapters/common/reportArrayAdapterService.interfaces';
import { ReportFine } from '~/types/common/reportFine';
import { ReportFineDTO } from '~/types/dto/common/fineDTO';
import { StringUtils } from '~/utils/stringUtils';

@injectable()
export class ReportFineAdapterService implements IReportFineAdapterService {
  constructor(
    @inject(IReportArrayAdapterServiceKey as symbol) private reportArrayAdapterService: IArrayAdapterService,
    @inject(IReportMainEntityFileAdapterServiceKey as symbol) private reportMainEntityFileAdapterService: IReportMainEntityFileAdapterService
  ) {}
  getData(initData: ReportFine[]): ReportFineDTO[] {
    const { mapData } = this.reportArrayAdapterService;
    return (initData ?? []).map((item) => ({
      Initiator: StringUtils.quoteReplacement(item.Initiator),
      Sum: item.Sum ?? 0,
      RestSum: item.RestSum ?? 0,
      SubjectOfExecution: StringUtils.quoteReplacement(item.SubjectOfExecution),
      KoapCode: item.KoapCode,
      Date: item.Date ?? '',
      Status: StringUtils.quoteReplacement(item.Status ?? ''),
      Photos: mapData(item.Photos, item => this.reportMainEntityFileAdapterService.getData(item))
    })).filter((item) => item.Sum);
  }
}
