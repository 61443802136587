import { injectable } from 'inversify';
import { ReportPayment } from '~/types/common/reportPayment';
import { IPlaceholderDataService } from './reportPlaceholderService.interfaces';

@injectable()
export class ReportPaymentPlaceholderService implements IPlaceholderDataService<ReportPayment> {
  getData(): ReportPayment {
    return {
      Type: '',
      Month: '',
      Amount: 0
    };
  }
}
