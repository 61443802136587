import { injectable } from 'inversify';

import { LegalEntitiesRelatedEgrul } from '~/types/legal-entities/relatedEgrul';
import { IPlaceholderDataService } from '../common/reportPlaceholderService.interfaces';

@injectable()
export class LegalEntitiesRelatedEgrulPlaceholderDataService implements IPlaceholderDataService<LegalEntitiesRelatedEgrul> {
  getData(): LegalEntitiesRelatedEgrul {
    return {
      RelationType: '',
      RelationObject: '',
      ObjectName: '',
      ObjectInn: '',
      ObjectOgrn: '',
      ObjectStatus: '',
      ObjectRegistrationDate: '',
      ObjectLiquidationDate: '',
      ObjectLegalAddress: '',
      ObjectMainActivity: '',
    };
  }
}
