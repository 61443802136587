import { injectable } from 'inversify';
import { MergeService } from '~/services/merge/merge-service';
import { LegalEntitiesCompanyEmailDTO } from '~/types/dto/common/legalEntitiesCompanyEmailDTO';

@injectable()
export class EmailsMergeService extends MergeService<LegalEntitiesCompanyEmailDTO> {
  protected canMerge(leftItem: LegalEntitiesCompanyEmailDTO, rightItem: LegalEntitiesCompanyEmailDTO): boolean {
    return leftItem.Value === rightItem.Value;
  }

  protected mergeFields(leftItem: LegalEntitiesCompanyEmailDTO, rightItem: LegalEntitiesCompanyEmailDTO): LegalEntitiesCompanyEmailDTO {
    return {
      Value: rightItem.Value || leftItem.Value,
      CompaniesWithThisEmail: rightItem.CompaniesWithThisEmail || leftItem.CompaniesWithThisEmail,
    };
  }
}
