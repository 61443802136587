import { injectable } from 'inversify';
import { ReportEmail } from '~/types/common/reportEmail';
import { IPlaceholderDataService } from './reportPlaceholderService.interfaces';

@injectable()
export class ReportEmailPlaceholderService implements IPlaceholderDataService<ReportEmail> {
  getData(): ReportEmail {
    return {
      Value: '',
      VerificationCode: 0,
      Type: 0,
      IsSuitableForSending: false
    };
  }
}
