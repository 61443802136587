import { injectable, inject } from 'inversify';
import { ReportMainEntityFile } from '~/types/common/reportMainEntityFile';
import { IndividualWantedFsin } from '~/types/individual/wantedFsin';
import { IPlaceholderDataService, IReportMainEntityFilePlaceholderServiceKey } from '../common/reportPlaceholderService.interfaces';

@injectable()
export class IndividualWantedFsinPlaceholderService implements IPlaceholderDataService<IndividualWantedFsin> {
  constructor(
    @inject(IReportMainEntityFilePlaceholderServiceKey as symbol) private reportMainEntityFilePlaceholderService: IPlaceholderDataService<ReportMainEntityFile>,
  ) {}

  getData(): IndividualWantedFsin {
    return {
      Photo: this.reportMainEntityFilePlaceholderService.getData(),
      WantedInfo: '',
      TerritorialDepartmentName: '',
      FederalDepartmentName: '',
    };
  }
}
