import { IContainerBinder } from '~/configuration/inversify/containerAccessor.interface';
import { ICompanyInnRequisiteMatchingServiceKey } from '~/services/matching/companyInnRequisiteMatchingService.interfaces';
import { CompanyInnRequisiteMatchingService } from '~/services/matching/companyInnRequisiteMatchingService';
import { IIpInnRequisiteMatchingServiceKey } from '~/services/matching/ipInnRequisiteMatchingService.interfaces';
import { IpInnRequisiteMatchingService } from '~/services/matching/ipInnRequisiteMatchingService';

export function bindMatchingServices(bind: IContainerBinder) {
  bind(ICompanyInnRequisiteMatchingServiceKey).to(CompanyInnRequisiteMatchingService);
  bind(IIpInnRequisiteMatchingServiceKey).to(IpInnRequisiteMatchingService);
}
