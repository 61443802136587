import { injectable } from 'inversify';
import { IReportPossibleFullNameAdapterService } from '~/services/adapters/common/reportAdapterService.interfeces';
import { ReportPossibleFullName } from '~/types/common/reportPossibleFullName';
import { ReportPossibleFullNameDTO } from '~/types/dto/common/reportPossibleFullNameDTO';
import { StringUtils } from '~/utils/stringUtils';

@injectable()
export class ReportPossibleFullNameAdapterService implements IReportPossibleFullNameAdapterService {
  getData(initData: ReportPossibleFullName[]): ReportPossibleFullNameDTO[] {
    return (initData ?? []).map((item) => ({
      FirstName: StringUtils.quoteReplacement(item.FirstName),
      LastName: StringUtils.quoteReplacement(item.LastName),
      Patronymic: StringUtils.quoteReplacement(item.Patronymic),
      FullName: StringUtils.quoteReplacement(item.FullName)
    })).filter((item) => item.FullName);
  }
}
