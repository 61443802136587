import { orderBy } from 'lodash-es';
import { injectable, inject } from 'inversify';

import { DateUtils } from '~/utils/date';
import { StringUtils } from '~/utils/stringUtils';

import { ReportVehicleParkingDTO } from '~/types/dto/common/vehicleParkingDTO';
import { ReportVehicleParking } from '~/types/common/reportVehicleParking';
import {
  IReportMentionAdapterService,
  IReportMentionAdapterServiceKey,
  IReportVehicleParkingAdapterService
} from '~/services/adapters/common/reportAdapterService.interfeces';
import { PhoneUtils } from '~/utils/phone';

@injectable()
export class ReportVehicleParkingAdapterService implements IReportVehicleParkingAdapterService {
  constructor(
    @inject(IReportMentionAdapterServiceKey as symbol) private reportMentionAdapterService: IReportMentionAdapterService,
  ) {}
  getData(initData: ReportVehicleParking[]): ReportVehicleParkingDTO[] {
    return orderBy(initData ?? [], ['StartDate'], ['desc']).map((item) => ({
      CarNumber: item.CarNumber,
      StartDate: item.StartDate ?? '',
      EndDate: DateUtils.formatDate(item.EndDate),
      Duration: StringUtils.quoteReplacement(item.Duration ?? ''),
      PlaceCode: StringUtils.quoteReplacement(item.PlaceCode ?? ''),
      Phone: PhoneUtils.getIsValidPhoneNumber(item.Phone) ? PhoneUtils.formatPhoneNumberWithDash(item.Phone) : '',
      SessionId: item.SessionId,
      Tags: this.reportMentionAdapterService.getData(item.Tags),
    }));
  }
}
