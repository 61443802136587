import { injectable } from 'inversify';
import { IndividualHoliday } from '~/types/individual/holiday';
import { IPlaceholderDataService } from '../common/reportPlaceholderService.interfaces';

@injectable()
export class IndividualHolidayPlaceholderService implements IPlaceholderDataService<IndividualHoliday> {
  getData(): IndividualHoliday {
    return {
      Type: '',
      StartDate: '',
      EndDate: '',
    };
  }
}
