import { injectable } from 'inversify';
import { IReportMainEntityFileUrlAdapterService } from '~/services/adapters/common/reportAdapterService.interfeces';
import { ReportMainEntityFileUrl } from '~/types/common/reportMainEntityFileUrl';
import { ReportMainEntityFileUrlDTO } from '~/types/dto/common/reportMainEntityFileUrlDTO';
import { StringUtils } from '~/utils/stringUtils';

@injectable()
export class ReportMainEntityFileUrlAdapterService implements IReportMainEntityFileUrlAdapterService {
  getData(initData: ReportMainEntityFileUrl): ReportMainEntityFileUrlDTO {
    return {
      Text: StringUtils.quoteReplacement(initData.Text ?? ''),
      Value: StringUtils.quoteReplacement(initData.Value ?? ''),
    };
  }
}
