import { injectable } from 'inversify';
import { get } from 'lodash-es';
import { IRatioOfSolvencyTableItemConfigService } from '~/modules/legalEntities/financialAnalytics/legalEntitiesLiquidityAndSolvency/services/legalEntitiesLiquidityAndSolvencyTableItemConfigService.interfaces';
import { TableItem } from '~/modules/table/types/tableItem';
import { LegalEntitiesReportFinancialIndicatorWithConclusionsDTO } from '~/types/dto/legalEntities/reportFinancialIndicatorWithConclusionsDTO';
import { NumberUtils } from '~/utils/number';
import { FINANCIAL_ANALYSIS_EXTENDED_TITLES } from '~/modules/legalEntities/financialAnalytics/common/configuration/constants/financialAnalysisExtendedTitles';

@injectable()
export class RatioOfSolvencyTableItemConfigService implements IRatioOfSolvencyTableItemConfigService {
  getItem(initData:LegalEntitiesReportFinancialIndicatorWithConclusionsDTO):TableItem {
    return {
      TwoYearsAgo: { value: NumberUtils.formatNumberDigits(initData.TwoYearsAgo, 2, '—'), color: 'black' },
      LastYear: { value: NumberUtils.formatNumberDigits(initData.LastYear, 2, '—'), color: 'black' },
      CurrentYear: { value: NumberUtils.formatNumberDigits(initData.CurrentYear, 2, '—'), color: 'black' },
      indicator: {
        title: FINANCIAL_ANALYSIS_EXTENDED_TITLES.RatioOfSolvency,
        subtitle: 'Cоотношение денежных средств и их эквивалентов, краткосрочных финансовых вложений и краткосрочных обязательств'
      },
      Conclusions: get(initData.Conclusions, 0, '—'),
      referenceValue: '0.05 - 0.1',
    };
  }
}
