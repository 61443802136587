import { injectable, inject } from 'inversify';
import { LegalEntitiesCourtCasesByYear } from '~/types/legal-entities/courtCasesByYear';
import { IPrimitiveTypesNormalizationServiceKey, IPrimitiveTypesNormalizationService } from '../common/primitiveTypesNormalizationService.interfaces';
import { INormalizationService } from '../common/reportNormalizationService.interfaces';

@injectable()
export class LegalEntitiesCourtCasesByYearNormalizationService implements INormalizationService<LegalEntitiesCourtCasesByYear> {
  constructor(
    @inject(IPrimitiveTypesNormalizationServiceKey as symbol) private primitiveTypesNormalizationService: IPrimitiveTypesNormalizationService,
  ) {}

  normalize(data: any, root: string): LegalEntitiesCourtCasesByYear | undefined {
    const { normalizeNumber, normalizeString, normalizeObject } = this.primitiveTypesNormalizationService;

    return normalizeObject(data, (data) => ({
      Year: normalizeNumber(data.Year, `${root}.Year`),
      Sum: normalizeNumber(data.Sum, `${root}.Sum`),
      CaseCount: normalizeNumber(data.CaseCount, `${root}.CaseCount`),
      CaseType: normalizeString(data.CaseType, `${root}.CaseType`),
    }), root);
  }
}
