import { injectable, inject } from 'inversify';
import { IReportUrlPlaceholderServiceKey, IPlaceholderDataService } from '~/services/placeholder/common/reportPlaceholderService.interfaces';
import { ILegalEntitiesReportDataBuhFormPlaceholderDataServiceKey } from '~/services/placeholder/legalEntities/legalEntitiesPlaceholderDataService.interfaces';
import { ReportUrl } from '~/types/common/reportUrl';
import { LegalEntitiesFinancialCommon } from '~/types/legal-entities/financialCommon';
import { LegalEntitiesFinancialState } from '~/types/legal-entities/financialState';
import { LegalEntitiesReportDataBuhForm } from '~/types/legal-entities/reportDataBuhForm';
import { IPrimitiveTypesNormalizationServiceKey, IPrimitiveTypesNormalizationService } from '../common/primitiveTypesNormalizationService.interfaces';
import { INormalizationService, IReportUrlNormalizationServiceKey } from '../common/reportNormalizationService.interfaces';
import { ILegalEntitiesFinancialCommonNormalizationServiceKey, ILegalEntitiesReportDataBuhFormNormalizationServiceKey } from './legalEntitiesNormalizationService.interfaces';

@injectable()
export class LegalEntitiesFinancialStateNormalizationService implements INormalizationService<LegalEntitiesFinancialState> {
  constructor(
    @inject(ILegalEntitiesReportDataBuhFormNormalizationServiceKey as symbol) private reportDataBuhFormNormalizationService: INormalizationService<LegalEntitiesReportDataBuhForm>,
    @inject(IReportUrlNormalizationServiceKey as symbol) private reportUrlNormalizationService: INormalizationService<ReportUrl>,
    @inject(ILegalEntitiesFinancialCommonNormalizationServiceKey as symbol) private financialCommonNormalizationService: INormalizationService<LegalEntitiesFinancialCommon>,

    @inject(IReportUrlPlaceholderServiceKey as symbol) private reportUrlPlaceholderService: IPlaceholderDataService<ReportUrl>,
    @inject(ILegalEntitiesReportDataBuhFormPlaceholderDataServiceKey as symbol) private reportDataBuhFormPlaceholderDataService: IPlaceholderDataService<LegalEntitiesReportDataBuhForm>,

    @inject(IPrimitiveTypesNormalizationServiceKey as symbol) private primitiveTypesNormalizationService: IPrimitiveTypesNormalizationService
  ) {}

  normalize(data: any, root: string): LegalEntitiesFinancialState | undefined {
    const {
      normalizeString,
      normalizeArray,
      normalizeObject,
      normalizeOptionalString,
      normalizeOptional
    } = this.primitiveTypesNormalizationService;

    return normalizeObject(data, (data) => ({
      ListFinancialCommon: normalizeArray(data.ListFinancialCommon, (item, field) => this.financialCommonNormalizationService.normalize(item, field), `${root}.ListFinancialCommon`),
      ListDebts: normalizeArray(data.ListDebts, (item, field) => this.financialCommonNormalizationService.normalize(item, field), `${root}.ListDebts`),
      ListAssets: normalizeArray(data.ListAssets, (item, field) => this.financialCommonNormalizationService.normalize(item, field), `${root}.ListAssets`),
      ListProfitability: normalizeArray(data.ListProfitability, (item, field) => this.financialCommonNormalizationService.normalize(item, field), `${root}.ListProfitability`),
      ListLiquidity: normalizeArray(data.ListLiquidity, (item, field) => this.financialCommonNormalizationService.normalize(item, field), `${root}.ListLiquidity`),
      ListBalanceAsset: normalizeArray(data.ListBalanceAsset, (item, field) => this.financialCommonNormalizationService.normalize(item, field), `${root}.ListBalanceAsset`),
      ListBalancePassive: normalizeArray(data.ListBalancePassive, (item, field) => this.financialCommonNormalizationService.normalize(item, field), `${root}.ListBalancePassive`),
      IncomeAndExpenses: normalizeArray(data.IncomeAndExpenses, (item, field) => this.financialCommonNormalizationService.normalize(item, field), `${root}.IncomeAndExpenses`),
      IncomeAndExpensesOperations: normalizeArray(data.IncomeAndExpensesOperations, (item, field) => this.financialCommonNormalizationService.normalize(item, field), `${root}.IncomeAndExpensesOperations`),
      CoefListAssets: normalizeArray(data.CoefListAssets, (item, field) => this.financialCommonNormalizationService.normalize(item, field), `${root}.CoefListAssets`),
      CoefListDebts: normalizeArray(data.CoefListDebts, (item, field) => this.financialCommonNormalizationService.normalize(item, field), `${root}.CoefListDebts`),
      CoefListLiquidity: normalizeArray(data.CoefListLiquidity, (item, field) => this.financialCommonNormalizationService.normalize(item, field), `${root}.CoefListLiquidity`),
      CoefListProfitability: normalizeArray(data.CoefListProfitability, (item, field) => this.financialCommonNormalizationService.normalize(item, field), `${root}.CoefListProfitability`),
      Coef105: normalizeArray(data.Coef105, (item, field) => this.financialCommonNormalizationService.normalize(item, field), `${root}.Coef105`),
      CoefOther: normalizeArray(data.CoefOther, (item, field) => this.financialCommonNormalizationService.normalize(item, field), `${root}.CoefOther`),
      DataFNS: normalizeArray(data.DataFNS, (item, field) => this.financialCommonNormalizationService.normalize(item, field), `${root}.DataFNS`),
      FirstRecordDate: normalizeOptionalString(data.FirstRecordDate, `${root}.FirstRecordDate`),
      BuhFormLastYear: normalizeOptional(data.BuhFormLastYear, (data, field) => this.reportDataBuhFormNormalizationService.normalize(data, field), `${root}.BuhFormLastYear`),
      BuhFormLast2Year: normalizeArray(data.BuhFormLast2Year, (item, field) => this.reportDataBuhFormNormalizationService.normalize(item, field), `${root}.BuhFormLast2Year`),
      ChistieActiviByYear: {},
      RevenueByYear: {},
      ProfitByYear: {},
      FinancialCommonLinks: normalizeArray(data.FinancialCommonLinks, (item, field) => this.reportUrlNormalizationService.normalize(item, field), `${root}.FinancialCommonLinks`),
      LiquidityLinks: normalizeArray(data.LiquidityLinks, (item, field) => this.reportUrlNormalizationService.normalize(item, field), `${root}.LiquidityLinks`),
      FinancialExpressAllPeriodUrl: normalizeOptional(data.FinancialExpressAllPeriodUrl, (data, field) => this.reportUrlNormalizationService.normalize(data, field), `${root}.FinancialExpressAllPeriodUrl`),
      FinancialCoefAllPeriodUrl: normalizeOptional(data.FinancialCoefAllPeriodUrl, (data, field) => this.reportUrlNormalizationService.normalize(data, field), `${root}.FinancialCoefAllPeriodUrl`),
      CompanySize: normalizeString(data.CompanySize, `${root}.CompanySize`),
    }), root);
  }
}
