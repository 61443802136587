import annotationPlugin from 'chartjs-plugin-annotation';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import {
  Chart as ChartJS,
  Title, 
  Tooltip, 
  Legend, 
  BarElement, 
  CategoryScale,
  LineController,
  LinearScale,
  Filler,
  LineElement,
  PointElement, 
} from 'chart.js';

export default () => {
  ChartJS.register(
    Title, 
    Tooltip, 
    Legend, 
    BarElement, 
    CategoryScale,
    LineController,
    LinearScale,
    LineElement,
    LinearScale,
    PointElement,
    CategoryScale,
    Filler,
    annotationPlugin,
    ChartDataLabels
  );
  
  ChartJS.defaults.plugins.legend.display = false;
};
