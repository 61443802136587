import { injectable } from 'inversify';

import { LegalEntitiesCompanyActualAddress } from '~/types/legal-entities/companyActualAddress';
import { IPlaceholderDataService } from '../common/reportPlaceholderService.interfaces';

@injectable()
export class LegalEntitiesCompanyActualAddressPlaceholderService implements IPlaceholderDataService<LegalEntitiesCompanyActualAddress> {
  getData(): LegalEntitiesCompanyActualAddress {
    return {
      Address: '',
      CompaniesWithThisAddress: 0,
      UnreliabilityCode: '',
      UnreliabilityDate: '',
      IsRegionChanged: false
    };
  }
}
