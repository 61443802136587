/* eslint-disable class-methods-use-this, max-len */

import { injectable } from 'inversify';
import { LegalEntitiesCompanyHeadFl } from '~/types/legal-entities/companyHeadFl';
import { StringUtils } from '~/utils/stringUtils';
import { LegalEntitiesCompanyHeadFlDTO } from '~/types/dto/legalEntities/companyHeadFlDTO';
import {
  ILegalEntitiesCompanyHeadFlAdapterService
} from '~/services/adapters/legalEntities/legalEntitiesAdapterService.interfaces';

@injectable()
export class LegalEntitiesCompanyHeadFlAdapterService implements ILegalEntitiesCompanyHeadFlAdapterService {
  getData(initData: LegalEntitiesCompanyHeadFl[]): LegalEntitiesCompanyHeadFlDTO[] {
    return (initData ?? []).map((companyFLHead) => ({
      Fio: StringUtils.quoteReplacement(companyFLHead.Fio ?? ''),
      Innfl: StringUtils.quoteReplacement(companyFLHead.Innfl ?? ''),
      Position: StringUtils.quoteReplacement(companyFLHead.Position ?? ''),
      Date: companyFLHead.Date ?? '',
      FirstDate: companyFLHead.FirstDate ?? '',
      InaccuracyDate: companyFLHead.InaccuracyDate ?? '',
      IsCompanyCoOwner: companyFLHead.IsCompanyCoOwner ?? false,
      IsInaccuracy: companyFLHead.IsInaccuracy ?? false,
      OwnershipPercent: companyFLHead.OwnershipPercent ?? 0
    }));
  }
}
