import { injectable } from 'inversify';
import { IReportPassportAdapterService } from '~/services/adapters/common/reportAdapterService.interfeces';
import { ReportPassport } from '~/types/common/reportPassport';
import { ReportPassportDTO } from '~/types/dto/common/reportPassportDTO';
import { DateUtils } from '~/utils/date';
import { StringUtils } from '~/utils/stringUtils';

@injectable()
export class ReportPassportAdapterService implements IReportPassportAdapterService {
  getData(initData: ReportPassport[]): ReportPassportDTO[] {
    
    return (initData ?? []).map((item) => {
      const issuedDate = item.IssuedDate ?? '';
      const formattedDate = DateUtils.formatDate(issuedDate);
      const isValidDate = DateUtils.isValidDate(formattedDate);

      return {
        Type: item.Type, 
        IsActual: item.IsActual ?? null,
        Series: StringUtils.quoteReplacement(item.Series),
        Number: StringUtils.quoteReplacement(item.Number),
        IssuedDate: isValidDate ? issuedDate : '',
        IssuedBy: StringUtils.quoteReplacement(item.IssuedBy ?? ''),
        DepartmentCode: StringUtils.quoteReplacement(item.DepartmentCode ?? '')
      }
    });
  }
}
