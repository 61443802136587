import { injectable, inject } from 'inversify';
import {
  ILegalEntitiesReportFinancialIndicatorWithConclusionsAdapterService,
  ILegalEntitiesReportFinancialIndicatorWithConclusionsAdapterServiceKey,
  ILegalEntitiesReportFinancialStabilityAdapterService
} from '~/services/adapters/legalEntities/legalEntitiesAdapterService.interfaces';
import { LegalEntitiesReportFinancialStability } from '~/types/legal-entities/reportFinancialStability';
import { LegalEntitiesReportFinancialStabilityDTO } from '~/types/dto/legalEntities/reportFinancialStabilityDTO';

@injectable()
export class LegalEntitiesReportFinancialStabilityAdapterService implements ILegalEntitiesReportFinancialStabilityAdapterService {
  constructor(
    @inject(ILegalEntitiesReportFinancialIndicatorWithConclusionsAdapterServiceKey as symbol) private legalEntitiesReportFinancialIndicatorWithConclusions: ILegalEntitiesReportFinancialIndicatorWithConclusionsAdapterService
  ) {}
  getData(initData: LegalEntitiesReportFinancialStability): LegalEntitiesReportFinancialStabilityDTO {
    return {
      NetAssets: this.legalEntitiesReportFinancialIndicatorWithConclusions.getData(initData.NetAssets),
      RatioOfConcentrationOfEquity: this.legalEntitiesReportFinancialIndicatorWithConclusions.getData(initData.RatioOfConcentrationOfEquity),
      LevelOfFinancialLeverage: this.legalEntitiesReportFinancialIndicatorWithConclusions.getData(initData.LevelOfFinancialLeverage),
      RatioOfSecurityOfInterestToPayment: this.legalEntitiesReportFinancialIndicatorWithConclusions.getData(initData.RatioOfSecurityOfInterestToPayment),
      RatioOfTotalDebtToProfitFromSales: this.legalEntitiesReportFinancialIndicatorWithConclusions.getData(initData.RatioOfTotalDebtToProfitFromSales),
    };
  }
}
