/* eslint-disable class-methods-use-this, max-len */

import { injectable } from 'inversify';
import { StringUtils } from '~/utils/stringUtils';
import { LegalEntitiesRelatedEgrul } from '~/types/legal-entities/relatedEgrul';
import { LegalEntitiesRelatedEgrulDTO } from '~/types/dto/legalEntities/relatedEgrulDTO';
import {
  ILegalEntitiesRelatedEgrulAdapterService
} from '~/services/adapters/legalEntities/legalEntitiesAdapterService.interfaces';

@injectable()
export class LegalEntitiesRelatedEgrulAdapterService implements ILegalEntitiesRelatedEgrulAdapterService {
  getData(initData: LegalEntitiesRelatedEgrul[]): LegalEntitiesRelatedEgrulDTO[] {
    return (initData ?? []).map((item) => ({
      ObjectOgrn: StringUtils.quoteReplacement(item.ObjectOgrn ?? ''),
      ObjectStatus: StringUtils.quoteReplacement(item.ObjectStatus ?? ''),
      RelationObject: StringUtils.quoteReplacement(item.RelationObject ?? ''),
      RelationType: item.RelationType,
      ObjectInn: StringUtils.quoteReplacement(item.ObjectInn ?? ''),
      ObjectLegalAddress: StringUtils.quoteReplacement(item.ObjectLegalAddress ?? ''),
      ObjectLiquidationDate: StringUtils.quoteReplacement(item.ObjectLiquidationDate ?? ''),
      ObjectMainActivity: StringUtils.quoteReplacement(item.ObjectMainActivity ?? ''),
      ObjectName: StringUtils.quoteReplacement(item.ObjectName ?? ''),
      ObjectRegistrationDate: StringUtils.quoteReplacement(item.ObjectRegistrationDate ?? ''),
    }));
  }
}
