import { IContainerBinder } from './containerAccessor.interface';
import {
  ILegalEntitiesActivitySummaryAdapterServiceKey,
  ILegalEntitiesAnalyticsIndicatorsAdapterServiceKey,
  ILegalEntitiesAverageHeadcountAdapterServiceKey,
  ILegalEntitiesBankAccountAdapterServiceKey,
  ILegalEntitiesBriefInfoAdapterServiceKey,
  ILegalEntitiesCertificateSummaryAdapterServiceKey,
  ILegalEntitiesCommonInfoAdapterServiceKey,
  ILegalEntitiesCompanyActualAddressAdapterServiceKey,
  ILegalEntitiesCompanyEmployeeInfoAdapterServiceKey,
  ILegalEntitiesCompanyFounderAdapterServiceKey,
  ILegalEntitiesCompanyFounderShareAdapterServiceKey,
  ILegalEntitiesCompanyHeadFlAdapterServiceKey,
  ILegalEntitiesCompanyHistoryAddressAdapterServiceKey,
  ILegalEntitiesCompanyManagementCompaniesAdapterServiceKey,
  ILegalEntitiesCompanyNameAdapterServiceKey,
  ILegalEntitiesCompanyPhoneAdapterServiceKey,
  ILegalEntitiesCompanySiteAdapterServiceKey,
  ILegalEntitiesCompanyStatedCapitalAdapterServiceKey,
  ILegalEntitiesCompanyStatusAdapterServiceKey,
  ILegalEntitiesCompanyTaxRegimesAdapterServiceKey,
  ILegalEntitiesContractsInfoByYearAdapterServiceKey,
  ILegalEntitiesCourtCasesByYearAdapterServiceKey,
  ILegalEntitiesDataBlobDataServiceKey,
  ILegalEntitiesEmailsAdapterServiceKey,
  ILegalEntitiesEnforcementProceedingsInfoBySubjectAdapterServiceKey,
  ILegalEntitiesFinancialAnalysisExtendedAdapterServiceKey,
  ILegalEntitiesFinancialAnalysisShortAdapterServiceKey,
  ILegalEntitiesFinancialCommonAdapterServiceKey,
  ILegalEntitiesFinancialStateAdapterServiceKey,
  ILegalEntitiesFineAndTaxDebtAdapterServiceKey,
  ILegalEntitiesGovernmentControlAdapterServiceKey,
  ILegalEntitiesHashtagAdapterServiceKey,
  ILegalEntitiesInsuranceFundInfoAdapterServiceKey,
  ILegalEntitiesLesseeSummaryAdapterServiceKey,
  ILegalEntitiesLicenseSummaryAdapterServiceKey,
  ILegalEntitiesMarkersAdapterServiceKey,
  ILegalEntitiesObjectReportDataBuhFormAdapterServiceKey,
  ILegalEntitiesPredecessorAdapterServiceKey,
  ILegalEntitiesRegistrarOfShareholdersAdapterServiceKey,
  ILegalEntitiesRelatedEgrulAdapterServiceKey,
  ILegalEntitiesReorganisationMemberAdapterServiceKey,
  ILegalEntitiesReportAnalysisOfAbsoluteLiquidityAdapterServiceKey,
  ILegalEntitiesReportAnalysisOfAbsoluteLiquidityIndicatorAdapterServiceKey,
  ILegalEntitiesReportAverageIndustryIndicatorAdapterServiceKey,
  ILegalEntitiesReportDataBuhFormAdapterServiceKey,
  ILegalEntitiesReportDataBuhFormDetailAdapterServiceKey,
  ILegalEntitiesReportFinancialIndicatorBaseAdapterServiceKey,
  ILegalEntitiesReportFinancialIndicatorWithConclusionsAdapterServiceKey,
  ILegalEntitiesReportFinancialIndicatorWithDynamicAdapterServiceKey,
  ILegalEntitiesReportFinancialStabilityAdapterServiceKey,
  ILegalEntitiesReportIndicatorsOfCashFlowAdapterServiceKey,
  ILegalEntitiesReportIndicatorsOfProfitabilityAdapterServiceKey,
  ILegalEntitiesReportLiquidityAndSolvencyAdapterServiceKey,
  ILegalEntitiesSelfRegulatoryOrganizationAdapterServiceKey,
  ILegalEntitiesShareholderAdapterServiceKey,
  ILegalEntitiesSocialsAdapterServiceKey,
  ILegalEntitiesSubCompanyInfoAdapterServiceKey,
  ILegalEntitiesSuccessorAdapterServiceKey,
  ILegalEntitiesTaxAdapterServiceKey,
  ILegalEntitiesTaxesPenaltiesAdapterServiceKey,
  ILegalEntitiesTaxModeAdapterServiceKey,
  ILegalEntitiesTaxServiceInfoAdapterServiceKey,
  ILegalEntitiesTotalScoreAdapterServiceKey,
  ILegalEntitiesTrademarkAdapterServiceKey
} from '~/services/adapters/legalEntities/legalEntitiesAdapterService.interfaces';
import { LegalEntitiesTaxAdapterService } from '~/services/adapters/legalEntities/legalEntitiesTaxAdapterService';
import { LegalEntitiesTaxModeAdapterService } from '~/services/adapters/legalEntities/legalEntitiesTaxModeAdapterService';
import { LegalEntitiesFineAndTaxDebtAdapterService } from '~/services/adapters/legalEntities/legalEntitiesFineAndTaxDebtAdapterService';
import { LegalEntitiesLesseeSummaryAdapterService } from '~/services/adapters/legalEntities/legalEntitiesLesseeSummary';
import { LegalEntitiesLicenseSummaryAdapterService } from '~/services/adapters/legalEntities/legalEntitiesLicenseSummaryAdapterService';
import { LegalEntitiesContractsInfoByYearAdapterService } from '~/services/adapters/legalEntities/legalEntitiesContractsInfoByYearAdapterService';
import { LegalEntitiesGovernmentControlAdapterService } from '~/services/adapters/legalEntities/legalEntitiesGovernmentControlAdapterService';
import { LegalEntitiesCertificateSummaryAdapterService } from '~/services/adapters/legalEntities/legalEntitiesCertificateSummaryAdapterService';
import { LegalEntitiesSelfRegulatoryOrganizationAdapterService } from '~/services/adapters/legalEntities/legalEntitiesSelfRegulatoryOrganizationAdapterService';
import { LegalEntitiesTrademarkAdapterService } from '~/services/adapters/legalEntities/legalEntitiesTrademarkAdapterService';
import { LegalEntitiesSubCompanyInfoAdapterService } from '~/services/adapters/legalEntities/legalEntitiesSubCompanyInfoAdapterService';
import { LegalEntitiesCompanyManagementCompaniesAdapterService } from '~/services/adapters/legalEntities/legalEntitiesCompanyManagementCompaniesAdapterService';
import { LegalEntitiesCompanyFounderAdapterService } from '~/services/adapters/legalEntities/legalEntitiesCompanyFounderAdapterService';
import { LegalEntitiesShareholderAdapterService } from '~/services/adapters/legalEntities/legalEntitiesShareholderAdapterService';
import { LegalEntitiesTaxServiceInfoAdapterService } from '~/services/adapters/legalEntities/legalEntitiesTaxServiceInfoAdapterService';
import { LegalEntitiesCompanyNameAdapterService } from '~/services/adapters/legalEntities/legalEntitiesCompanyNameAdapterService';
import { LegalEntitiesCompanyStatedCapitalAdapterService } from '~/services/adapters/legalEntities/legalEntitiesCompanyStatedCapitalAdapterService';
import { LegalEntitiesPredecessorAdapterService } from '~/services/adapters/legalEntities/legalEntitiesPredecessorAdapterService';
import { LegalEntitiesSuccessorAdapterService } from '~/services/adapters/legalEntities/legalEntitiesSuccessorAdapterService';
import { LegalEntitiesReorganisationMemberAdapterService } from '~/services/adapters/legalEntities/legalEntitiesReorganisationMemberAdapterService';
import { LegalEntitiesRelatedEgrulAdapterService } from '~/services/adapters/legalEntities/legalEntitiesRelatedEgrulAdapterService';
import { LegalEntitiesActivitySummaryAdapterService } from '~/services/adapters/legalEntities/legalEntitiesActivitySummary';
import { LegalEntitiesMarkersAdapterService } from '~/services/adapters/legalEntities/legalEntitiesMarkersAdapterService';
import { LegalEntitiesFinancialAnalysisShortAdapterService } from '~/services/adapters/legalEntities/legalEntitiesFinancialAnalysisShortAdapterService';
import { LegalEntitiesFinancialAnalysisExtendedAdapterService } from '~/services/adapters/legalEntities/legalEntitiesFinancialAnalysisExtendedAdapterService';
import { LegalEntitiesTotalScoreAdapterService } from '~/services/adapters/legalEntities/legalEntitiesTotalScoreAdapterService';
import { LegalEntitiesDataBlobDataAdapterService } from '~/services/adapters/legalEntities/legalEntitiesDataBlobDataAdapterService';
import { LegalEntitiesCompanyHeadFlAdapterService } from '~/services/adapters/legalEntities/legalEntitiesCompanyHeadFlAdapterService';
import { LegalEntitiesInsuranceFundInfoAdapterService } from '~/services/adapters/legalEntities/legalEntitiesInsuranceFundInfoAdapterService';
import { LegalEntitiesFinancialAdapterService } from '~/services/adapters/legalEntities/legalEntitiesFinancialInfoAdapterService';
import { LegalEntitiesBriefInfoAdapterService } from '~/services/adapters/legalEntities/legalEntitiesBriefInfoAdapterService';
import { LegalEntitiesSocialsAdapterService } from '~/services/adapters/legalEntities/legalEntitiesSocialsAdapter';
import { LegalEntitiesCompanyStatusAdapterService } from '~/services/adapters/legalEntities/legalEntitiesCompanyStatusAdapterService';
import { LegalEntitiesAverageHeadcountAdapterService } from '~/services/adapters/legalEntities/legalEntitiesAverageHeadcountAdapterService';
import { LegalEntitiesTaxesPenaltiesAdapterService } from '~/services/adapters/legalEntities/legalEntitiesTaxesPenaltiesAdapterService';
import { LegalEntitiesCompanyTaxRegimesAdapterService } from '~/services/adapters/legalEntities/legalEntitiesCompanyTaxRegimes';
import { LegalEntitiesCommonInfoAdapterService } from '~/services/adapters/legalEntities/legalEntitiesCommonInfoAdapterService';
import { LegalEntitiesBankAccountAdapterService } from '~/services/adapters/legalEntities/legalEntitiesBankAccountAdapterService';
import { LegalEntitiesCompanyPhoneAdapterService } from '~/services/adapters/legalEntities/legalEntitiesCompanyPhoneAdapterService';
import { LegalEntitiesHashtagAdapterService } from '~/services/adapters/legalEntities/legalEntitiesHashtagAdapterService';
import { LegalEntitiesCompanySiteAdapterService } from '~/services/adapters/legalEntities/legalEntitiesCompanySiteAdapterService';
import { LegalEntitiesEmailsAdapterService } from '~/services/adapters/legalEntities/legalEntitiesEmailsAdapterService';
import { LegalEntitiesCompanyActualAddressAdapterService } from '~/services/adapters/legalEntities/legalEntitiesCompanyActualAddressAdapterService';
import { LegalEntitiesCourtCasesByYearAdapterService } from '~/services/adapters/legalEntities/legalEntitiesCourtCasesByYearAdapterService';
import { LegalEntitiesEnforcementProceedingsInfoBySubjectAdapterService } from '~/services/adapters/legalEntities/legalEntitiesEnforcementProceedingsInfoBySubjectAdapterService';
import { LegalEntitiesCompanyFounderShareAdapterService } from '~/services/adapters/legalEntities/legalEntitiesCompanyFounderShareAdapterService';
import { LegalEntitiesCompanyHistoryAddressAdapterService } from '~/services/adapters/legalEntities/legalEntitiesCompanyHistoryAddressAdapterService';
import { LegalEntitiesCompanyEmployeeInfoAdapterService } from '~/services/adapters/legalEntities/legalEntitiesCompanyEmployeeInfoAdapterService';
import { LegalEntitiesRegistrarOfShareholdersAdapterService } from '~/services/adapters/legalEntities/legalEntitiesRegistrarOfShareholdersAdapterService';
import { LegalEntitiesFinancialCommonAdapterService } from '~/services/adapters/legalEntities/legalEntitiesFinancialCommonAdapterService';
import { LegalEntitiesReportDataBuhFormAdapterService } from '~/services/adapters/legalEntities/legalEntitiesReportDataBuhFormAdapterService';
import { LegalEntitiesReportDataBuhFormDetailAdapterService } from '~/services/adapters/legalEntities/legalEntitiesReportDataBuhFormDetailAdapterService';
import { LegalEntitiesObjectReportDataBuhFormAdapterService } from '~/services/adapters/legalEntities/legalEntitiesObjectReportDataBuhFormAdapterService';
import { LegalEntitiesReportFinancialIndicatorBaseAdapterService } from '~/services/adapters/legalEntities/legalEntitiesReportFinancialIndicatorBaseAdapterService';
import { LegalEntitiesReportFinancialIndicatorWithConclusionsAdapterService } from '~/services/adapters/legalEntities/legalEntitiesReportFinancialIndicatorWithConclusionsAdapterService';
import { LegalEntitiesReportFinancialIndicatorWithDynamicAdapterService } from '~/services/adapters/legalEntities/legalEntitiesReportFinancialIndicatorWithDynamicAdapterService';
import { LegalEntitiesReportLiquidityAndSolvencyAdapterService } from '~/services/adapters/legalEntities/legalEntitiesReportLiquidityAndSolvencyAdapterService';
import { LegalEntitiesReportAnalysisOfAbsoluteLiquidityAdapterService } from '~/services/adapters/legalEntities/legalEntitiesReportAnalysisOfAbsoluteLiquidityAdapterService';
import { LegalEntitiesReportAnalysisOfAbsoluteLiquidityIndicatorAdapterService } from '~/services/adapters/legalEntities/legalEntitiesReportAnalysisOfAbsoluteLiquidityIndicatorAdapterService';
import { LegalEntitiesReportFinancialStabilityAdapterService } from '~/services/adapters/legalEntities/legalEntitiesReportFinancialStabilityAdapterService';
import { LegalEntitiesReportIndicatorsOfProfitabilityAdapterService } from '~/services/adapters/legalEntities/legalEntitiesReportIndicatorsOfProfitabilityAdapterService';
import { LegalEntitiesReportAverageIndustryIndicatorAdapterService } from '~/services/adapters/legalEntities/legalEntitiesReportAverageIndustryIndicatorAdapterService';
import { LegalEntitiesReportIndicatorsOfCashFlowAdapterService } from '~/services/adapters/legalEntities/legalEntitiesReportIndicatorsOfCashFlowAdapterService';
import { LegalEntitiesAnalyticsIndicatorsAdapterService } from '~/services/adapters/legalEntities/legalEntitiesAnalyticsIndicatorsAdapterService';

export function bindLegalEntitiesAdapterServices(bind: IContainerBinder) {
  bind(ILegalEntitiesCompanyTaxRegimesAdapterServiceKey).to(LegalEntitiesCompanyTaxRegimesAdapterService);
  bind(ILegalEntitiesCompanyStatusAdapterServiceKey).to(LegalEntitiesCompanyStatusAdapterService);
  bind(ILegalEntitiesCompanyEmployeeInfoAdapterServiceKey).to(LegalEntitiesCompanyEmployeeInfoAdapterService);
  bind(ILegalEntitiesTaxAdapterServiceKey).to(LegalEntitiesTaxAdapterService);
  bind(ILegalEntitiesTaxModeAdapterServiceKey).to(LegalEntitiesTaxModeAdapterService);
  bind(ILegalEntitiesFineAndTaxDebtAdapterServiceKey).to(LegalEntitiesFineAndTaxDebtAdapterService);
  bind(ILegalEntitiesTaxesPenaltiesAdapterServiceKey).to(LegalEntitiesTaxesPenaltiesAdapterService);
  bind(ILegalEntitiesLesseeSummaryAdapterServiceKey).to(LegalEntitiesLesseeSummaryAdapterService);
  bind(ILegalEntitiesLicenseSummaryAdapterServiceKey).to(LegalEntitiesLicenseSummaryAdapterService);
  bind(ILegalEntitiesContractsInfoByYearAdapterServiceKey).to(LegalEntitiesContractsInfoByYearAdapterService);
  bind(ILegalEntitiesGovernmentControlAdapterServiceKey).to(LegalEntitiesGovernmentControlAdapterService);
  bind(ILegalEntitiesCertificateSummaryAdapterServiceKey).to(LegalEntitiesCertificateSummaryAdapterService);
  bind(ILegalEntitiesSelfRegulatoryOrganizationAdapterServiceKey).to(LegalEntitiesSelfRegulatoryOrganizationAdapterService);
  bind(ILegalEntitiesTrademarkAdapterServiceKey).to(LegalEntitiesTrademarkAdapterService);
  bind(ILegalEntitiesSubCompanyInfoAdapterServiceKey).to(LegalEntitiesSubCompanyInfoAdapterService);
  bind(ILegalEntitiesCompanyManagementCompaniesAdapterServiceKey).to(LegalEntitiesCompanyManagementCompaniesAdapterService);
  bind(ILegalEntitiesCompanyFounderAdapterServiceKey).to(LegalEntitiesCompanyFounderAdapterService);
  bind(ILegalEntitiesShareholderAdapterServiceKey).to(LegalEntitiesShareholderAdapterService);
  bind(ILegalEntitiesTaxServiceInfoAdapterServiceKey).to(LegalEntitiesTaxServiceInfoAdapterService);
  bind(ILegalEntitiesCompanyNameAdapterServiceKey).to(LegalEntitiesCompanyNameAdapterService);
  bind(ILegalEntitiesCompanyStatedCapitalAdapterServiceKey).to(LegalEntitiesCompanyStatedCapitalAdapterService);
  bind(ILegalEntitiesPredecessorAdapterServiceKey).to(LegalEntitiesPredecessorAdapterService);
  bind(ILegalEntitiesSuccessorAdapterServiceKey).to(LegalEntitiesSuccessorAdapterService);
  bind(ILegalEntitiesReorganisationMemberAdapterServiceKey).to(LegalEntitiesReorganisationMemberAdapterService);
  bind(ILegalEntitiesRelatedEgrulAdapterServiceKey).to(LegalEntitiesRelatedEgrulAdapterService);
  bind(ILegalEntitiesActivitySummaryAdapterServiceKey).to(LegalEntitiesActivitySummaryAdapterService);
  bind(ILegalEntitiesDataBlobDataServiceKey).to(LegalEntitiesDataBlobDataAdapterService);
  bind(ILegalEntitiesCommonInfoAdapterServiceKey).to(LegalEntitiesCommonInfoAdapterService);
  bind(ILegalEntitiesBriefInfoAdapterServiceKey).to(LegalEntitiesBriefInfoAdapterService);
  bind(ILegalEntitiesMarkersAdapterServiceKey).to(LegalEntitiesMarkersAdapterService);
  bind(ILegalEntitiesFinancialStateAdapterServiceKey).to(LegalEntitiesFinancialAdapterService);
  bind(ILegalEntitiesFinancialAnalysisShortAdapterServiceKey).to(LegalEntitiesFinancialAnalysisShortAdapterService);
  bind(ILegalEntitiesFinancialAnalysisExtendedAdapterServiceKey).to(LegalEntitiesFinancialAnalysisExtendedAdapterService);
  bind(ILegalEntitiesTotalScoreAdapterServiceKey).to(LegalEntitiesTotalScoreAdapterService);
  bind(ILegalEntitiesCompanyHeadFlAdapterServiceKey).to(LegalEntitiesCompanyHeadFlAdapterService);  
  bind(ILegalEntitiesInsuranceFundInfoAdapterServiceKey).to(LegalEntitiesInsuranceFundInfoAdapterService);
  bind(ILegalEntitiesSocialsAdapterServiceKey).to(LegalEntitiesSocialsAdapterService);
  bind(ILegalEntitiesAverageHeadcountAdapterServiceKey).to(LegalEntitiesAverageHeadcountAdapterService);
  bind(ILegalEntitiesBankAccountAdapterServiceKey).to(LegalEntitiesBankAccountAdapterService);
  bind(ILegalEntitiesHashtagAdapterServiceKey).to(LegalEntitiesHashtagAdapterService);
  bind(ILegalEntitiesCompanyPhoneAdapterServiceKey).to(LegalEntitiesCompanyPhoneAdapterService);
  bind(ILegalEntitiesCompanySiteAdapterServiceKey).to(LegalEntitiesCompanySiteAdapterService);
  bind(ILegalEntitiesEmailsAdapterServiceKey).to(LegalEntitiesEmailsAdapterService);
  bind(ILegalEntitiesCompanyActualAddressAdapterServiceKey).to(LegalEntitiesCompanyActualAddressAdapterService);
  bind(ILegalEntitiesCourtCasesByYearAdapterServiceKey).to(LegalEntitiesCourtCasesByYearAdapterService);
  bind(ILegalEntitiesEnforcementProceedingsInfoBySubjectAdapterServiceKey).to(LegalEntitiesEnforcementProceedingsInfoBySubjectAdapterService);
  bind(ILegalEntitiesCompanyFounderShareAdapterServiceKey).to(LegalEntitiesCompanyFounderShareAdapterService);
  bind(ILegalEntitiesCompanyHistoryAddressAdapterServiceKey).to(LegalEntitiesCompanyHistoryAddressAdapterService);
  bind(ILegalEntitiesRegistrarOfShareholdersAdapterServiceKey).to(LegalEntitiesRegistrarOfShareholdersAdapterService);
  bind(ILegalEntitiesFinancialCommonAdapterServiceKey).to(LegalEntitiesFinancialCommonAdapterService);
  bind(ILegalEntitiesReportDataBuhFormAdapterServiceKey).to(LegalEntitiesReportDataBuhFormAdapterService);
  bind(ILegalEntitiesReportDataBuhFormDetailAdapterServiceKey).to(LegalEntitiesReportDataBuhFormDetailAdapterService);
  bind(ILegalEntitiesObjectReportDataBuhFormAdapterServiceKey).to(LegalEntitiesObjectReportDataBuhFormAdapterService);
  bind(ILegalEntitiesReportFinancialIndicatorBaseAdapterServiceKey).to(LegalEntitiesReportFinancialIndicatorBaseAdapterService);
  bind(ILegalEntitiesReportFinancialIndicatorWithConclusionsAdapterServiceKey).to(LegalEntitiesReportFinancialIndicatorWithConclusionsAdapterService);
  bind(ILegalEntitiesReportFinancialIndicatorWithDynamicAdapterServiceKey).to(LegalEntitiesReportFinancialIndicatorWithDynamicAdapterService);
  bind(ILegalEntitiesReportLiquidityAndSolvencyAdapterServiceKey).to(LegalEntitiesReportLiquidityAndSolvencyAdapterService);
  bind(ILegalEntitiesReportAnalysisOfAbsoluteLiquidityAdapterServiceKey).to(LegalEntitiesReportAnalysisOfAbsoluteLiquidityAdapterService);
  bind(ILegalEntitiesReportAnalysisOfAbsoluteLiquidityIndicatorAdapterServiceKey).to(LegalEntitiesReportAnalysisOfAbsoluteLiquidityIndicatorAdapterService);
  bind(ILegalEntitiesReportFinancialStabilityAdapterServiceKey).to(LegalEntitiesReportFinancialStabilityAdapterService);
  bind(ILegalEntitiesReportIndicatorsOfProfitabilityAdapterServiceKey).to(LegalEntitiesReportIndicatorsOfProfitabilityAdapterService);
  bind(ILegalEntitiesReportAverageIndustryIndicatorAdapterServiceKey).to(LegalEntitiesReportAverageIndustryIndicatorAdapterService);
  bind(ILegalEntitiesReportIndicatorsOfCashFlowAdapterServiceKey).to(LegalEntitiesReportIndicatorsOfCashFlowAdapterService);
  bind(ILegalEntitiesAnalyticsIndicatorsAdapterServiceKey).to(LegalEntitiesAnalyticsIndicatorsAdapterService);
}
