import { injectable } from 'inversify';
import { ReportMovementCovid } from '~/types/common/reportMovementCovid';
import { IPlaceholderDataService } from './reportPlaceholderService.interfaces';

@injectable()
export class ReportMovementCovidPlaceholderService implements IPlaceholderDataService<ReportMovementCovid> {
  getData(): ReportMovementCovid {
    return {
      Email: '',
      PhoneNumber: '',
      Inn: '',
      Passport: '',
      FullName: '',
      MovementFrom: '',
      MovementTo: '',
      MovementVehicleNum: '',
      MovementSocialCardNum: '',
      MovementTroykaCardNum: '',
      MovementStrelkaCardNum: '',
      MovementDirection: '',
      MovementPurpose: '',
      Organisation: '',
      OrganisationInn: '',
    };
  }
}
