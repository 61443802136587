import {inject, injectable} from 'inversify';
import { LegalEntitiesCompanyStatus } from "~/types/legal-entities/LegalEntitiesCompanyStatus";
import { INormalizationService } from "../common/reportNormalizationService.interfaces";
import { IPrimitiveTypesNormalizationService, IPrimitiveTypesNormalizationServiceKey } from "../common/primitiveTypesNormalizationService.interfaces";

export type ILegalEntitiesCompanyStatusNormalizationService = INormalizationService<LegalEntitiesCompanyStatus>;

@injectable()
export class LegalEntitiesCompanyStatusNormalizationService implements ILegalEntitiesCompanyStatusNormalizationService {
  
  constructor(@inject(IPrimitiveTypesNormalizationServiceKey as symbol) private primitiveTypesNormalizationService: IPrimitiveTypesNormalizationService) {}

  normalize(data: any, root: string): LegalEntitiesCompanyStatus | undefined {
    const { normalizeObject, normalizeOptionalString } = this.primitiveTypesNormalizationService;
    
    return normalizeObject(data, (data) => ({
      EndDate: normalizeOptionalString(data.EndDate, `${root}.EndDate`),
      StartDate: normalizeOptionalString(data.StartDate, `${root}.StartDate`),
      StatusOnPeriodDate: normalizeOptionalString(data.StatusOnPeriodDate, `${root}.StatusOnPeriodDate`),
    }), root)
  }
}

export const ILegalEntitiesCompanyStatusNormalizationServiceKey = Symbol('INormalizationService<LegalEntitiesCompanyStatus>');