import { injectable } from 'inversify';

import { LegalEntitiesGovernmentControl } from '~/types/legal-entities/governmentControl';
import { IPlaceholderDataService } from '../common/reportPlaceholderService.interfaces';

@injectable()
export class LegalEntitiesGovernmentControlPlaceholderDataService implements IPlaceholderDataService<LegalEntitiesGovernmentControl> {
  getData(): LegalEntitiesGovernmentControl {
    return {
      Date: '',
      OrganName: '',
      ControlType: '',
      ViolationsCount: 0,
      Violations: '',
    };
  }
}
