import { IContainerBinder } from '~/configuration/inversify/containerAccessor.interface';
import { OwnWorkingCapitalTableItemConfigService } from '~/modules/legalEntities/financialAnalytics/legalEntitiesLiquidityAndSolvency/services/ownWorkingCapitalTableItemConfigService';
import {
  IDegreeOfSolvencyTableItemConfigServiceKey,
  IOwnWorkingCapitalTableItemConfigServiceKey,
  IRatioOfCurrentLiquidityTableItemConfigServiceKey,
  IRatioOfReceivablesToTotalAssetsTableItemConfigServiceKey,
  IRatioOfSolvencyTableItemConfigServiceKey
} from '~/modules/legalEntities/financialAnalytics/legalEntitiesLiquidityAndSolvency/services/legalEntitiesLiquidityAndSolvencyTableItemConfigService.interfaces';
import { RatioOfCurrentLiquidityTableItemConfigService } from '~/modules/legalEntities/financialAnalytics/legalEntitiesLiquidityAndSolvency/services/ratioOfCurrentLiquidityTableItemConfigService';
import { RatioOfSolvencyTableItemConfigService } from '~/modules/legalEntities/financialAnalytics/legalEntitiesLiquidityAndSolvency/services/ratioOfSolvencyTableItemConfigService';
import { DegreeOfSolvencyTableItemConfigService } from '~/modules/legalEntities/financialAnalytics/legalEntitiesLiquidityAndSolvency/services/degreeOfSolvencyTableItemConfigService';
import { RatioOfReceivablesToTotalAssetsTableItemConfigService } from '~/modules/legalEntities/financialAnalytics/legalEntitiesLiquidityAndSolvency/services/ratioOfReceivablesToTotalAssetsTableItemConfigService';
import { LegalEntitiesLiquidityAndSolvencyTableConfigService } from '~/modules/legalEntities/financialAnalytics/legalEntitiesLiquidityAndSolvency/services/legalEntitiesLiquidityAndSolvencyTableConfigService';
import { ILegalEntitiesLiquidityAndSolvencyTableConfigServiceKey } from '~/modules/legalEntities/financialAnalytics/legalEntitiesLiquidityAndSolvency/services/legalEntitiesLiquidityAndSolvencyTableConfigService.interface';

export function bindLegalEntitiesLiquidityAndSolvencyServices(bind: IContainerBinder) {
  bind(ILegalEntitiesLiquidityAndSolvencyTableConfigServiceKey).to(LegalEntitiesLiquidityAndSolvencyTableConfigService);

  bind(IOwnWorkingCapitalTableItemConfigServiceKey).to(OwnWorkingCapitalTableItemConfigService);
  bind(IRatioOfCurrentLiquidityTableItemConfigServiceKey).to(RatioOfCurrentLiquidityTableItemConfigService);
  bind(IRatioOfSolvencyTableItemConfigServiceKey).to(RatioOfSolvencyTableItemConfigService);
  bind(IDegreeOfSolvencyTableItemConfigServiceKey).to(DegreeOfSolvencyTableItemConfigService);
  bind(IRatioOfReceivablesToTotalAssetsTableItemConfigServiceKey).to(RatioOfReceivablesToTotalAssetsTableItemConfigService);
}
