import { injectable } from 'inversify';

import { LegalEntitiesEgrRecord } from '~/types/legal-entities/egrRecord';
import { IPlaceholderDataService } from '../common/reportPlaceholderService.interfaces';

@injectable()
export class LegalEntitiesEgrRecordPlaceholderDataService implements IPlaceholderDataService<LegalEntitiesEgrRecord> {
  getData(): LegalEntitiesEgrRecord {
    return {
      Grn: '',
      Date: '',
      Name: '',
      IsInvalid: false,
      InvalidSince: '',
      RegName: '',
      RegCode: '',
      DocumentName: '',
      DocumentDate: '',
      CertificateSerialNumber: '',
      CertificateDate: '',
    };
  }
}
