import { inject, injectable } from 'inversify';
import { Config } from '~/types/field-list/config';
import {
  ILegalEntitiesAuthorizedCapitalService
} from '~/modules/legalEntities/changeHistory/legalEntitiesAuthorizedCapital/services/legalEntitiesAuthorizedCapitalService.interfaces';
import { LegalEntitiesCompanyStatedCapitalDTO } from '~/types/dto/legalEntities/companyStatedCapitalDTO';
import {
  ILegalEntitiesAuthorizedCapitalLastIndexService,
  ILegalEntitiesAuthorizedCapitalLastIndexServiceKey
} from '~/modules/legalEntities/changeHistory/legalEntitiesAuthorizedCapital/services/legalEntitiesAuthorizedCapitalLastIndexService.interfaces';
import {
  ILegalEntitiesAuthorizedCapitalConfigService,
  ILegalEntitiesAuthorizedCapitalConfigServiceKey
} from '~/modules/legalEntities/changeHistory/legalEntitiesAuthorizedCapital/services/legalEntitiesAuthorizedCapitalConfigService.interfaces';

@injectable()
export class LegalEntitiesAuthorizedCapitalService implements ILegalEntitiesAuthorizedCapitalService {
  constructor(
    @inject(ILegalEntitiesAuthorizedCapitalConfigServiceKey as symbol) private legalEntitiesAuthorizedCapitalConfigService: ILegalEntitiesAuthorizedCapitalConfigService,
    @inject(ILegalEntitiesAuthorizedCapitalLastIndexServiceKey as symbol) private legalEntitiesAuthorizedCapitalLastIndexService: ILegalEntitiesAuthorizedCapitalLastIndexService
  ) {}
  
  getData(initData: Array<LegalEntitiesCompanyStatedCapitalDTO>): Array<Config> {
    const lastIndex = this.legalEntitiesAuthorizedCapitalLastIndexService.getLastIndex(initData);

    return initData
      .map((legalEntitiesCompanyStatedCapital, index) => this.legalEntitiesAuthorizedCapitalConfigService
        .getConfig({ index, lastIndex, legalEntitiesCompanyStatedCapital }));
  }
}
