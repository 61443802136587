import { injectable } from 'inversify';
import { Config } from '~/types/field-list/config';
import { ReportSocialNetworkAboutInfoDTO } from '~/types/dto/common/reportSocialNetworkAboutInfoDTO';
import { IReportSocialNetworkInterestsConfigService } from './reportSocialNetworkConfigService.interfaces';
import { FIELD_LIST_SOCIAL_OPTIONS } from '../../configuration/constants';

@injectable()
export class ReportSocialNetworkInterestsConfigService implements IReportSocialNetworkInterestsConfigService {
  getConfig(socialItem: ReportSocialNetworkAboutInfoDTO): Config {
    return {
      ...FIELD_LIST_SOCIAL_OPTIONS,
      fields: [
        {
          key: 'Activity',
          label: 'Деятельность',
          value: socialItem.Activity,
        },
        {
          key: 'Interests',
          label: 'Интересы',
          value: socialItem.Interests,
        },
        {
          key: 'FavoriteMusic',
          label: 'Любимая музыка',
          value: socialItem.FavoriteMusic,
        },
        {
          key: 'FavoriteFilms',
          label: 'Любимые фильмы',
          value: socialItem.FavoriteFilms,
        },
        {
          key: 'FavoriteTvShows',
          label: 'Любимые телешоу',
          value: socialItem.FavoriteTvShows,
        },
        {
          key: 'FavoriteBooks',
          label: 'Любимые книги',
          value: socialItem.FavoriteBooks,
        },
        {
          key: 'FavoriteQuotes',
          label: 'Любимые цитаты',
          value: socialItem.FavoriteQuotes,
        },
        {
          key: 'About',
          label: 'О себе',
          value: socialItem.About,
        },
      ].filter(item => item.value)
    };
  }
}
