import { IContainerBinder } from '~/configuration/inversify/containerAccessor.interface';
import { FineGeneralInfoConfigService } from '~/modules/reportsCommon/reportFine/services/fineGeneralInfoConfigService';
import {
  IFineGeneralInfoConfigServiceKey,
  IFinePrivateArticleInfoConfigServiceKey,
  IFinePrivateCashInfoConfigServiceKey
} from '~/modules/reportsCommon/reportFine/services/fines.interfaces';
import { FinePrivateCashInfoConfigService } from '~/modules/reportsCommon/reportFine/services/finePrivateCashInfoConfigService';
import { FinePrivateArticleInfoConfigService } from '~/modules/reportsCommon/reportFine/services/finePrivateArticleInfoConfigService';

export function bindFineServices(bind: IContainerBinder) {
  bind(IFineGeneralInfoConfigServiceKey).to(FineGeneralInfoConfigService);
  bind(IFinePrivateCashInfoConfigServiceKey).to(FinePrivateCashInfoConfigService);
  bind(IFinePrivateArticleInfoConfigServiceKey).to(FinePrivateArticleInfoConfigService);
}
