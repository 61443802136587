import { CompositeNodeSizeSnapshot } from '~/models/nodes/snapshots/compositeNodeSizeSnapshot';

export class NodeRowSnapshot {
  constructor(public snapshots: CompositeNodeSizeSnapshot[]) { }
    
  update() {
    for (const snapshot of this.snapshots)
      snapshot.update();
  }
}
