import { AxiosResponse, CancelToken } from 'axios';
import { InjectionKey } from 'vue';
import { ReportJsonResponse } from "~/types/report/reportJsonResponse";
import { ReportResponse } from "~/types/report/reportResponse";
import { ReportStatusModel } from "~/types/report/status";

export interface ITasksService {
  getTaskStatus(reportTaskId: string): Promise<AxiosResponse<ReportStatusModel>>;
  getTaskResult(reportTaskId: string): Promise<AxiosResponse<Blob>>;
  getTaskJson(reportTaskId: string): Promise<AxiosResponse<ReportJsonResponse>>;
  getTasks(pageSize: number, pageNum: number, cancelToken?: CancelToken): Promise<AxiosResponse<ReportResponse>>;
}

export const ITasksServiceKey: InjectionKey<ITasksService> = Symbol('ITasksService');