import { injectable } from 'inversify';
import { ILegalEntitiesCompanyFounderConfigService } from '~/modules/legalEntities/common/legalEntitiesCompanyFounder/services/legalEntitiesCompanyFounderConfigService.interfaces';
import { Config } from '~/types/field-list/config';
import { LegalEntitiesCompanyFounderDTO } from '~/types/dto/legalEntities/companyFounder';
import { NumberUtils } from '~/utils/number';
import { DateUtils } from '~/utils/date';
import { Field } from '~/types/field-list/field';

@injectable()
export class LegalEntitiesCompanyFounderConfigService implements ILegalEntitiesCompanyFounderConfigService {
  getConfig(initData: LegalEntitiesCompanyFounderDTO): Config {
    const date = initData.Date ?? initData.FirstDate;
    const country = String(initData.Country).toLowerCase()
      .includes('россия') ? undefined : initData.Country;
    const alert = initData.IsInaccuracy ? 'недостоверные сведения в ЕГРЮЛ' : '';
    const capitalValue = NumberUtils.formatNumberDigits(initData.Capital, 0, '');
    const capital = capitalValue && capitalValue !== '0' ? capitalValue : '';

    const fields: Array<Field> = [
      {
        label: 'ИНН',
        key: 'Inn',
        value: initData.Inn,
      },
      {
        label: 'Доля, \u{20BD}',
        key: 'Capital',
        value: capital,
      },
      {
        label: 'Доля, %',
        key: 'Percent',
        value: initData.Percent || '',
      },
      {
        label: 'Страна',
        key: 'Country',
        value: country,
      }
    ];
    if (initData.FirstDate !== date && initData.FirstDate && date) {
      fields.push(
        {
          label: 'Дата внесения сведений',
          key: 'firstDate',
          value: DateUtils.formatDate(initData.FirstDate),
        },
        {
          label: 'Дата последнего внесения изменений',
          key: 'date',
          value: DateUtils.formatDate(date),

        },
        {
          label: 'note/flash',
          key: 'alert',
          value: alert
        }
      );
    } else {
      fields.push(
        {
          label: 'Дата внесения сведений',
          key: 'firstDate',
          value: DateUtils.formatDate(initData.FirstDate),
        },
        {
          label: 'note/flash',
          key: 'alert',
          value: alert
        }
      );
    }
    return {
      fieldsDirection: 'divider-row',
      fieldsWidth: '100%',
      fieldsValueWidth: '350px',
      isBeginningDivider: true,
      fields
    };
  }
}
