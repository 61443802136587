import { IContainerBinder } from '~/configuration/inversify/containerAccessor.interface';
import {
  IPhoneNumberDataBlobDataAdapterServiceKey,
  IPhoneNumberPhoneCommonAdapterServiceKey,
  IPhoneNumberRelatedPersonCommonAdapterServicesKey,
  IPhoneNumberRelatedPhonePersonAdapterServicesKey
} from '~/services/adapters/phoneNimber/phoneNumberAdapterService.interfaces';
import { PhoneNumberPhoneCommonAdapterService } from '~/services/adapters/phoneNimber/phoneNumberPhoneCommonAdapterService';
import { PhoneNumberRelatedPersonCommonAdapterServices } from '~/services/adapters/phoneNimber/phoneNumberRelatedPersonCommonAdapterServices';
import { PhoneNumberRelatedPhonePersonAdapterServices } from '~/services/adapters/phoneNimber/phoneNumberRelatedPhonePersonAdapterServices';
import { PhoneNumberDataBlobDataAdapterService } from '~/services/adapters/phoneNimber/phoneNumberDataBlobDataAdapterService';

export function bindPhoneNumberAdapterServices(bind: IContainerBinder) {
  bind(IPhoneNumberPhoneCommonAdapterServiceKey).to(PhoneNumberPhoneCommonAdapterService);
  bind(IPhoneNumberRelatedPersonCommonAdapterServicesKey).to(PhoneNumberRelatedPersonCommonAdapterServices);
  bind(IPhoneNumberRelatedPhonePersonAdapterServicesKey).to(PhoneNumberRelatedPhonePersonAdapterServices);
  bind(IPhoneNumberDataBlobDataAdapterServiceKey).to(PhoneNumberDataBlobDataAdapterService);
}
