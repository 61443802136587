import { injectable } from 'inversify';
import { IndividualSkype } from '~/types/individual/skype';
import { IPlaceholderDataService } from '../common/reportPlaceholderService.interfaces';

@injectable()
export class IndividualSkypePlaceholderService implements IPlaceholderDataService<IndividualSkype> {
  getData(): IndividualSkype {
    return {
      Value: '',
    };
  }
}
