import { injectable, inject } from 'inversify';
import { LegalEntitiesRegistrarOfShareholders } from '~/types/legal-entities/registrarOfShareholders';
import { IPrimitiveTypesNormalizationServiceKey, IPrimitiveTypesNormalizationService } from '../common/primitiveTypesNormalizationService.interfaces';
import { INormalizationService } from '../common/reportNormalizationService.interfaces';

@injectable()
export class LegalEntitiesRegistrarOfShareholdersNormalizationService implements INormalizationService<LegalEntitiesRegistrarOfShareholders> {
  constructor(
    @inject(IPrimitiveTypesNormalizationServiceKey as symbol) private primitiveTypesNormalizationService: IPrimitiveTypesNormalizationService,
  ) {}

  normalize(data: any, root: string): LegalEntitiesRegistrarOfShareholders | undefined {
    const { normalizeString, normalizeOptionalString, normalizeObject } = this.primitiveTypesNormalizationService;

    return normalizeObject(data, (data) => ({
      Name: normalizeString(data.Name, `${root}.Name`),
      Inn: normalizeString(data.Inn, `${root}.Inn`),
      Ogrn: normalizeString(data.Ogrn, `${root}.Ogrn`),
      Date: normalizeOptionalString(data.Date, `${root}.Date`),
      FirstDate: normalizeOptionalString(data.FirstDate, `${root}.FirstDate`),
      Status: normalizeString(data.Status, `${root}.Status`)
    }), root);
  }
}
