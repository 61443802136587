/* eslint-disable class-methods-use-this, max-len */

import { injectable } from 'inversify';
import { punycodeUri } from '~/utils/punycodeUri';
import { LegalEntitiesCompanySite } from '~/types/legal-entities/companySite';
import {
  ILegalEntitiesCompanySiteAdapterService
} from '~/services/adapters/legalEntities/legalEntitiesAdapterService.interfaces';
import { LegalEntitiesCompanySiteDTO } from '~/types/dto/legalEntities/сompanySiteDTO';

@injectable()
export class LegalEntitiesCompanySiteAdapterService implements ILegalEntitiesCompanySiteAdapterService {
  getData(initData: LegalEntitiesCompanySite[]): LegalEntitiesCompanySiteDTO[] {
    return (initData ?? []).map((site) => {
      const unicodeUri = punycodeUri.toUnicode(site.Value);

      return {
        Value: unicodeUri.replace('www.', '')
      };
    });
  }
}
