/* eslint-disable class-methods-use-this, max-len */

import { injectable } from 'inversify';
import { StringUtils } from '~/utils/stringUtils';
import { LegalEntitiesFineAndTaxDebt } from '~/types/legal-entities/fineAndTaxDebt';
import { LegalEntitiesFineAndTaxDebtDTO } from '~/types/dto/legalEntities/fineAndTaxDebtDTO';
import {
  ILegalEntitiesFineAndTaxDebtAdapterService
} from '~/services/adapters/legalEntities/legalEntitiesAdapterService.interfaces';

@injectable()
export class LegalEntitiesFineAndTaxDebtAdapterService implements ILegalEntitiesFineAndTaxDebtAdapterService {
  getData(initData: LegalEntitiesFineAndTaxDebt[]): LegalEntitiesFineAndTaxDebtDTO[] {
    return (initData ?? []).map((taxDebt) => ({
      Name: StringUtils.quoteReplacement(taxDebt.Name),
      Sum: taxDebt.Sum,
      Year: taxDebt.Year
    }));
  }
}
