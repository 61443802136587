import { injectable } from 'inversify';
import { IndividualStageBankruptcy } from '~/types/individual/stageBankruptcy';
import { IPlaceholderDataService } from '../common/reportPlaceholderService.interfaces';

@injectable()
export class IndividualStageBankruptcyPlaceholderService implements IPlaceholderDataService<IndividualStageBankruptcy> {
  getData(): IndividualStageBankruptcy {
    return {
      MessageId: '',
      Stage: '',
      PubDate: '',
      DecisionDate: '',
    };
  }
}
