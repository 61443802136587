import { injectable } from 'inversify';

import { IndividualTrustScoringConversionItem } from '~/modules/individual/scoringScores/individualTrustScoring/types/IndividualTrustScoringConversionItem';
import { IndividualTrustScoringUtils } from '~/modules/individual/scoringScores/individualTrustScoring/utils/individualTrustScoringUtils';
import { IIndividualTrustScoringConversionItemService } from '~/modules/individual/scoringScores/individualTrustScoring/service/individualTrustScoringConversionItem.interface';

@injectable()
export class IndividualTrustScoringConversionItemService implements IIndividualTrustScoringConversionItemService {
  getData(initType :string, initColor: string): IndividualTrustScoringConversionItem {
    return {
      type: initType,
      value: IndividualTrustScoringUtils.getNumberOfIncidentsByColor(initColor),
    };
  }
}
