import { injectable } from 'inversify';
import {
  IPossibleBirthdaysSiftingService
} from '~/modules/reportsCommon/reportPossible/individual/servises/possibleBirthdaysSiftingService.interfaces';
import { ReportPossibleBirthdaysDTO } from '~/types/dto/common/reportPossibleBirthdaysDTO';

@injectable()
export class PossibleBirthdaysSiftingService implements IPossibleBirthdaysSiftingService {
  getSiftedData(initData: Array<ReportPossibleBirthdaysDTO>, birthday: string) {
    return initData.filter((item) => item.Birthday !== birthday);
  }
}
