import { injectable } from 'inversify';
import { IMergeService } from '@/services/merge/merge-service.interface';

@injectable()
export abstract class MergeService<T> implements IMergeService<T> {
  protected abstract mergeFields(leftItem: T, rightItem: T): T;

  protected abstract canMerge(leftItem: T, rightItem: T): boolean;

  merge(items: T[]): T[] {
    let iterableItems: T[] = [...items];
    const result: T[] = [];

    while (iterableItems.length > 0) {
      // eslint-disable-next-line prefer-const
      let [resultItem, ...itemsToProcess] = iterableItems;

      const unmergedItems = [];

      for (const item of itemsToProcess) {
        if (this.canMerge(resultItem, item)) {
          resultItem = this.mergeFields(resultItem, item);
        } else {
          unmergedItems.push(item);
        }
      }

      result.push(resultItem);
      iterableItems = unmergedItems;
    }

    return result;
  }
}
