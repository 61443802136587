import { injectable, inject } from 'inversify';
import { IndividualHoliday } from '~/types/individual/holiday';
import { IPrimitiveTypesNormalizationServiceKey, IPrimitiveTypesNormalizationService } from '../common/primitiveTypesNormalizationService.interfaces';
import { INormalizationService } from '../common/reportNormalizationService.interfaces';

@injectable()
export class IndividualHolidayNormalizationService implements INormalizationService<IndividualHoliday> {
  constructor(
    @inject(IPrimitiveTypesNormalizationServiceKey as symbol) private primitiveTypesNormalizationService: IPrimitiveTypesNormalizationService,
  ) {}

  normalize(data: any, root: string): IndividualHoliday | undefined {
    const { normalizeObject, normalizeString, normalizeOptionalString } = this.primitiveTypesNormalizationService;

    return normalizeObject(data, (data) => ({
      Type: normalizeString(data.Type, `${root}.Type`),
      StartDate: normalizeOptionalString(data.StartDate, `${root}.StartDate`),
      EndDate: normalizeOptionalString(data.EndDate, `${root}.EndDate`),
    }), root);
  }
}
