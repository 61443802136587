import { injectable } from 'inversify';
import { StringUtils } from '~/utils/stringUtils';
import { IReportArrayStringAdapterService } from '~/services/adapters/common/reportAdapterService.interfeces';

@injectable()
export class ReportArrayStringAdapterService implements IReportArrayStringAdapterService {
  getData(initData: string[]): string[] {
    return (initData ?? []).reduce<string[]>((acc, item) => {
      if (item.length === 0) return acc;
      return [
        ...acc,
        StringUtils.quoteReplacement(item),
      ];
    }, []);
  }
}
