import { inject, injectable } from 'inversify';
import { get } from 'lodash-es';
import { IRatioOfReceivablesToTotalAssetsTableItemConfigService } from '~/modules/legalEntities/financialAnalytics/legalEntitiesLiquidityAndSolvency/services/legalEntitiesLiquidityAndSolvencyTableItemConfigService.interfaces';
import { TableItem } from '~/modules/table/types/tableItem';
import { LegalEntitiesReportFinancialIndicatorWithConclusionsDTO } from '~/types/dto/legalEntities/reportFinancialIndicatorWithConclusionsDTO';
import { FINANCIAL_ANALYSIS_EXTENDED_TITLES } from '~/modules/legalEntities/financialAnalytics/common/configuration/constants/financialAnalysisExtendedTitles';
import {
  IConvertingPrimitiveTableTypes,
  IConvertingPrimitiveTableTypesKey
} from '~/modules/legalEntities/financialAnalytics/common/services/convertingPrimitiveTableTypes.interfaces';

@injectable()
export class RatioOfReceivablesToTotalAssetsTableItemConfigService implements IRatioOfReceivablesToTotalAssetsTableItemConfigService {
  constructor(
    @inject(IConvertingPrimitiveTableTypesKey as symbol) private convertingPrimitiveTableTypes: IConvertingPrimitiveTableTypes
  ) {}

  getItem(initData: LegalEntitiesReportFinancialIndicatorWithConclusionsDTO):TableItem {
    return {

      TwoYearsAgo: { value: this.convertingPrimitiveTableTypes.percentageConversion(initData.TwoYearsAgo), color: this.getColor(initData.TwoYearsAgo * 100) },
      LastYear: { value: this.convertingPrimitiveTableTypes.percentageConversion(initData.LastYear), color: this.getColor(initData.LastYear * 100) },
      CurrentYear: { value: this.convertingPrimitiveTableTypes.percentageConversion(initData.CurrentYear), color: this.getColor(initData.CurrentYear * 100) },

      Conclusions: get(initData.Conclusions, 0, '—'),
      indicator: {
        title: FINANCIAL_ANALYSIS_EXTENDED_TITLES.RatioOfReceivablesToTotalAssets,
      },
      referenceValue: '<= 50%',
    };
  }

  private getColor(value: number): string {
    return value <= 50 ? 'green' : 'red';
  }
}
