import { inject, injectable } from 'inversify';
import { get } from 'lodash-es';
import { IDebtShareTableItemConfigService } from '~/modules/legalEntities/financialAnalytics/legalEntitiesFinancialAnalysisShort/services/legalEntitiesFinancialAnalysisShortTableItemConfigService.interfaces';
import { TableItem } from '~/types/tableItem';
import { LegalEntitiesReportFinancialIndicatorWithDynamicDTO } from '~/types/dto/legalEntities/reportFinancialIndicatorWithDynamicDTO';
import { FINANCIAL_ANALYSIS_SHORT_TITLES } from '~/modules/legalEntities/financialAnalytics/common/configuration/constants/financialAnalysisShortTitles';
import {
  IConvertingPrimitiveTableTypes,
  IConvertingPrimitiveTableTypesKey
} from '~/modules/legalEntities/financialAnalytics/common/services/convertingPrimitiveTableTypes.interfaces';

@injectable()
export class DebtShareTableItemConfigService implements IDebtShareTableItemConfigService {
  constructor(
    @inject(IConvertingPrimitiveTableTypesKey as symbol) private convertingPrimitiveTableTypes: IConvertingPrimitiveTableTypes
  ) {}

  getItem(initData: LegalEntitiesReportFinancialIndicatorWithDynamicDTO): TableItem {
    return ({
      TwoYearsAgo: { value: this.convertingPrimitiveTableTypes.percentageConversion(initData.TwoYearsAgo), color: 'black' },
      CurrentYear: { value: this.convertingPrimitiveTableTypes.percentageConversion(initData.CurrentYear), color: 'black' },
      LastYear: { value: this.convertingPrimitiveTableTypes.percentageConversion(initData.LastYear), color: 'black' },
      TwoYearsAgoToLastYear: {
        value: this.convertingPrimitiveTableTypes.percentageConversion(initData.TwoYearsAgoToLastYear, true),
        color: this.getColor((initData.TwoYearsAgoToLastYear ?? 0) * 100) 
      },
      LastYearToCurrentYear: {
        value: this.convertingPrimitiveTableTypes.percentageConversion(initData.LastYearToCurrentYear, true),
        color: this.getColor((initData.LastYearToCurrentYear ?? 0) * 100) 
      },
      Conclusions: get(initData.Conclusions, 0, '—'),
      Indicator: FINANCIAL_ANALYSIS_SHORT_TITLES.DebtShare
    });
  }

  private getColor(value: number): string {
    if (value > 0) return 'green';
    if (value < 0) return 'red';
    return 'black';
  }
}
