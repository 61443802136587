import { get } from 'lodash-es';
import { IPtsService } from './ptsService.interfaces';
import { PTS_REGEX } from '~/modules/reportsCommon/reportVehicle/configuration/regex/ptsRegex';
import { injectable } from 'inversify';

@injectable()
export class PtsService implements IPtsService {
  getPtsFromString(string: string): string | undefined {
    const ptsList = string.match(PTS_REGEX);
    const pts = get(ptsList, 0, undefined);

    if (pts === undefined) return undefined;

    const ptsWithoutSpaces = pts.replaceAll(' ', '');

    return `${ptsWithoutSpaces.slice(0, 2)} ${ptsWithoutSpaces.slice(2, 4)} ${ptsWithoutSpaces.slice(4)}`;
  }
}