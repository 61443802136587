import { injectable, inject } from 'inversify';
import { IReorganisationMemberService } from './getIsFirstDateService.interfaces';
import { LegalEntitiesReorganisationMember } from '~/types/legal-entities/reorganisationMember';
import { CheckIsFirstDateService } from './checkIsFirstDateService/checkIsFirstDateService';
import { ICheckIsFirstDateServiceKey } from './checkIsFirstDateService/checkIsFirstDateService.interface';

@injectable()
export class ReorganisationMemberService implements IReorganisationMemberService {
  constructor(
    @inject(ICheckIsFirstDateServiceKey as symbol) private checkIsFirstDateService: CheckIsFirstDateService,
  ) {}
  getIsFirstDate(array: Array<LegalEntitiesReorganisationMember>): Array<(LegalEntitiesReorganisationMember & { isFirstDate: boolean, })> {
    return this.checkIsFirstDateService.checkIsFirstDate(
      array,
      'Inn' as keyof LegalEntitiesReorganisationMember,
      'OrganisationName' as keyof LegalEntitiesReorganisationMember,
      'Date' as keyof LegalEntitiesReorganisationMember
    );
  }
}
