import { injectable } from 'inversify';
import { IIndividualDriverLicenseAdapterService } from '~/services/adapters/individual/individualAdapterService.interfaces';
import { IndividualDriverLicense } from '~/types/individual/driverLicense';
import { IndividualDriverLicenseDTO } from '~/types/dto/individual/driverLicenseDTO';
import { StringUtils } from '~/utils/stringUtils';
import { DateUtils } from '~/utils/date';

@injectable()
export class IndividualDriverLicenseAdapterService implements IIndividualDriverLicenseAdapterService {
  getData(initData: IndividualDriverLicense[]): IndividualDriverLicenseDTO[] {
    return (initData ?? []).map((item) => {
      const issueTime = DateUtils.formatTime(item.IssueDate);
      const isIssueTime = issueTime === '00:00:00' ? '' : issueTime;
      const issueDate = `${DateUtils.formatDate(item.IssueDate)} ${isIssueTime}`;
      
      return {
        Series: StringUtils.quoteReplacement(item.Series),
        Number: StringUtils.quoteReplacement(item.Number),
        IssueDate: issueDate,
        IssuePlace: StringUtils.quoteReplacement(item.IssuePlace),
        DrivingExperience: item.DrivingExperience,
        ExpireDate: DateUtils.formatDate(item.ExpireDate),
        Categories: StringUtils.quoteReplacement(item.Categories ?? ''),
        IsWanted: item.IsWanted,
        DeprivateDecision: StringUtils.quoteReplacement(item.DeprivateDecision),
      }; 
    });
  }
}
