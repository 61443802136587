import { injectable } from 'inversify';
import { ILegalEntitiesAuthorityToSignSiftingService } from '~/modules/legalEntities/changeHistory/LegalEntitiesAuthorityToSignChangeHistory/services/LegalEntitiesAuthorityToSignSiftingService.interface';
import { LegalEntitiesCompanyHeadFlDTO } from '~/types/dto/legalEntities/companyHeadFlDTO';

@injectable()
export class LegalEntitiesAuthorityToSignSiftingService implements ILegalEntitiesAuthorityToSignSiftingService {
  getSiftedData(initData: Array<LegalEntitiesCompanyHeadFlDTO>): Array<LegalEntitiesCompanyHeadFlDTO> {
    return initData.filter((item) => item.Fio.length > 0);
  }
}
