import { injectable, inject } from 'inversify';

import { ReportMainEntityFile } from '~/types/common/reportMainEntityFile';
import { IndividualCommonInfo } from '~/types/individual/commonInfo';
import { IPlaceholderDataService, IReportMainEntityFilePlaceholderServiceKey } from '../common/reportPlaceholderService.interfaces';

@injectable()
export class IndividualCommonInfoPlaceholderService implements IPlaceholderDataService<IndividualCommonInfo> {
  constructor(
    @inject(IReportMainEntityFilePlaceholderServiceKey as symbol) private reportMainEntityFilePlaceholderService: IPlaceholderDataService<ReportMainEntityFile>
  ) {}

  getData(): IndividualCommonInfo {
    return {
      LastUpdate: '',
      FullName: '',
      Gender: '',
      Birthday: '',
      DeathDate: '',
      BirthPlace: '',
      Note: '',
      MainPhoto: this.reportMainEntityFilePlaceholderService.getData(),
      Citizenship: '',
      Nationality: '',
      MaritalStatus: '',
    };
  }
}
