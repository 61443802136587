import { injectable } from 'inversify';
import { IPlaceholderDataService } from './reportPlaceholderService.interfaces';
import { ReportConnectionRelated } from '~/types/common/reportConnectionRelated';

@injectable()
export class ReportConnectionRelatedPlaceholderDataService implements IPlaceholderDataService<ReportConnectionRelated> {
  getData(): ReportConnectionRelated {
    return {
      Chains: []
    };
  }
}
