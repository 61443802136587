import { injectable, inject } from 'inversify';
import {
  ILegalEntitiesReportDataBuhFormAdapterService, ILegalEntitiesReportDataBuhFormDetailAdapterService,
  ILegalEntitiesReportDataBuhFormDetailAdapterServiceKey
} from '~/services/adapters/legalEntities/legalEntitiesAdapterService.interfaces';
import { LegalEntitiesReportDataBuhForm } from '~/types/legal-entities/reportDataBuhForm';
import { LegalEntitiesReportDataBuhFormDTO } from '~/types/dto/legalEntities/reportDataBuhFormDTO';

@injectable()
export class LegalEntitiesReportDataBuhFormAdapterService implements ILegalEntitiesReportDataBuhFormAdapterService {
  constructor(
    @inject(ILegalEntitiesReportDataBuhFormDetailAdapterServiceKey as symbol) private legalEntitiesReportDataBuhFormDetailAdapterService: ILegalEntitiesReportDataBuhFormDetailAdapterService,
  ) {}

  getData(initData: LegalEntitiesReportDataBuhForm[]):LegalEntitiesReportDataBuhFormDTO[] {
    return (initData ?? []).map((item) => ({
      Year: item.Year,
      OrganizationType: item.OrganizationType,
      Form1: this.legalEntitiesReportDataBuhFormDetailAdapterService.getData(item.Form1),
      Form2: this.legalEntitiesReportDataBuhFormDetailAdapterService.getData(item.Form2),
    }));
  }
}
