/* eslint-disable class-methods-use-this, max-len */
import { injectable, inject } from 'inversify';
import { PhoneUtils } from '~/utils/phone';
import { StringUtils } from '~/utils/stringUtils';
import { LegalEntitiesCompanyPhone } from '~/types/legal-entities/companyPhone';
import { LegalEntitiesCompanyPhoneDTO } from '~/types/dto/legalEntities/companyPhoneDTO';
import {
  ILegalEntitiesCompanyPhoneAdapterService,
  ILegalEntitiesHashtagAdapterService,
  ILegalEntitiesHashtagAdapterServiceKey
} from '~/services/adapters/legalEntities/legalEntitiesAdapterService.interfaces';

@injectable()
export class LegalEntitiesCompanyPhoneAdapterService implements ILegalEntitiesCompanyPhoneAdapterService {
  constructor(
    @inject(ILegalEntitiesHashtagAdapterServiceKey as symbol) private legalEntitiesHashtagAdapterService: ILegalEntitiesHashtagAdapterService,
  ) {}
  getData(initData: LegalEntitiesCompanyPhone[]): LegalEntitiesCompanyPhoneDTO[] {
    return (initData ?? []).map((phone) => {
      const validPhoneNumber = PhoneUtils.getIsValidPhoneNumber(phone.Phone) ? PhoneUtils.formatPhoneNumberWithDash(phone.Phone) : '';
      return {
        CompaniesWithThisPhone: phone.CompaniesWithThisPhone,
        Name: StringUtils.quoteReplacement(phone.Name),
        Phone: validPhoneNumber,
        Hashtags: this.legalEntitiesHashtagAdapterService.getData(phone.Hashtags)
      };
    });
  }
}
