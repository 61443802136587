import { injectable } from 'inversify';
import { Config } from '~/types/field-list/config';
import { ReportSocialNetworkDTO } from '~/types/dto/common/reportSocialNetworkDTO';
import { FIELD_LIST_SOCIAL_OPTIONS } from '../../configuration/constants';
import { IReportSocialNetworkConfigService } from './reportSocialNetworkConfigService.interfaces';
import { ReportSocialNetworkType } from '~/types/common/reportSocialNetworkType';

@injectable()
export class ReportSocialNetworkConfigService implements IReportSocialNetworkConfigService {
  getConfig(socialItem: ReportSocialNetworkDTO): Config {
    const [surName, firstName] = socialItem.FullName.split(' ');
    const isVerified = socialItem.IsVerified ? 'Верифицирован' : 'Не верифицирован';
    const hasVerified = socialItem.Type === ReportSocialNetworkType.Vk && socialItem.FullName.length > 0;

    return {
      ...FIELD_LIST_SOCIAL_OPTIONS,
      fields: [
        {
          key: 'Surname',
          label: 'Фамилия',
          value: surName,
        },
        {
          key: 'FirstName',
          label: 'Имя',
          value: firstName,
        },
        {
          key: 'Birthday',
          label: 'Дата рождения',
          value: socialItem.Birthday,
        },
        ...(
          hasVerified ? [{
            key: 'Verification',
            label: 'Верификация',
            value: isVerified
          }] : []
        )
      ].filter(item => item.value)
    };
  }
}
