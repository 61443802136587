/* eslint-disable class-methods-use-this, max-len */

import { injectable } from 'inversify';
import { LegalEntitiesCompanyManagementCompanies } from '~/types/legal-entities/companyManagementCompanies';
import { StringUtils } from '~/utils/stringUtils';
import { LegalEntitiesCompanyManagementCompaniesDTO } from '~/types/dto/legalEntities/companyManagementCompaniesDTO';
import {
  ILegalEntitiesCompanyManagementCompaniesAdapterService
} from '~/services/adapters/legalEntities/legalEntitiesAdapterService.interfaces';

@injectable()
export class LegalEntitiesCompanyManagementCompaniesAdapterService implements ILegalEntitiesCompanyManagementCompaniesAdapterService {
  getData(initData: LegalEntitiesCompanyManagementCompanies[]): LegalEntitiesCompanyManagementCompaniesDTO[] {
    return (initData ?? []).map((item) => ({
      Inn: StringUtils.quoteReplacement(item.Inn ?? ''),
      Name: StringUtils.quoteReplacement(item.Name ?? ''),
      Ogrn: StringUtils.quoteReplacement(item.Ogrn ?? ''),
      Date: item.Date ?? '',
      FirstDate: item.FirstDate ?? '',
      InaccuracyDate: item.InaccuracyDate ?? '',
      IsInaccuracy: item.IsInaccuracy ?? false
    }));
  }
}
