import { injectable, inject } from 'inversify';
import { Config } from '~/types/field-list/config';
import { IReportBoundedPhonesConfigService } from './reportBoundedPhonesConfigService.interface';
import { ReportBoundedPersonPhoneDTO } from '~/types/dto/common/boundedPersonPhoneDTO';
import { ICommonEmailsMergeServiceKey } from '~/services/merge/commonEmailsMergeService.interfaces';
import { CommonEmailsMergeService } from '~/services/merge/commonEmailsMergeService';

@injectable()
export class ReportBoundedPhonesConfigService implements IReportBoundedPhonesConfigService {
  constructor(
    @inject(ICommonEmailsMergeServiceKey as symbol) private commonEmailsMergeService: CommonEmailsMergeService,
  ) {}

  getConfig(item: ReportBoundedPersonPhoneDTO): Config {
    const uniqEmails = this.commonEmailsMergeService.merge(item.Emails);
    const emails = uniqEmails.map((email) => email.Value).join(', ');
    
    const replacedSource = item.SourceStr.replace('Телефонная книга', 'Запись в телефонной книге');

    return {
      fieldsWidth: '100%',
      fieldsDirection: 'divider-row',
      fieldsValueWidth: '350px',
      isBeginningDivider: true,
      fields: [
        {
          key: 'FullName',
          label: 'ФИО',
          direction: 'column',
          value: {
            text: item.FullName,
            width: 'max-content'
          }, 
          width: '670px'
        },
        {
          key: 'PhoneNumber',
          label: 'Абонентский номер',
          value: {
            text: item.Value, 
            width: '288px'
          },
          direction: 'column',
          width: '288px'
        },
        {
          key: 'RelatedBirth',
          label: 'Дата рождения',
          value: item.RelatedBirth
        },
        {
          key: 'RelatedAddress',
          label: 'Адрес',
          value: item.RelatedAddress
        },
        {
          key: 'Emails',
          label: 'E-mail',
          value: emails
        },
        {
          key: 'Source',
          label: 'Тип связи',
          value: replacedSource 
        }
      ]
    };
  }
}
