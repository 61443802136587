import { defineAsyncComponent } from 'vue'
import { defineNuxtPlugin } from '#app/nuxt'

const components = {
  SvgIcon: defineAsyncComponent(() => import("/app/node_modules/@nuxtjs/svg-sprite/dist/runtime/components/svg-icon.vue" /* webpackChunkName: "components/svg-icon" */).then(c => c.default || c))
}

export default defineNuxtPlugin(nuxtApp => {
  for (const name in components) {
    nuxtApp.vueApp.component(name, components[name])
    nuxtApp.vueApp.component('Lazy' + name, components[name])
  }
})
