import { injectable, inject } from 'inversify';
import {
  ILegalEntitiesReportFinancialIndicatorWithDynamicAdapterService,
  ILegalEntitiesReportFinancialIndicatorWithDynamicAdapterServiceKey,
  ILegalEntitiesReportIndicatorsOfCashFlowAdapterService
} from '~/services/adapters/legalEntities/legalEntitiesAdapterService.interfaces';
import { ReportIndicatorsOfCashFlow } from '~/types/blobs/report-indicators-of-cash-flow';
import { LegalEntitiesReportIndicatorsOfCashFlowDTO } from '~/types/dto/legalEntities/reportIndicatorsOfCashFlowDTO';

@injectable()
export class LegalEntitiesReportIndicatorsOfCashFlowAdapterService implements ILegalEntitiesReportIndicatorsOfCashFlowAdapterService {
  constructor(
    @inject(ILegalEntitiesReportFinancialIndicatorWithDynamicAdapterServiceKey as symbol) private legalEntitiesReportFinancialIndicatorWithDynamic: ILegalEntitiesReportFinancialIndicatorWithDynamicAdapterService,
  ) {}
  getData(initData: ReportIndicatorsOfCashFlow): LegalEntitiesReportIndicatorsOfCashFlowDTO {
    return {
      OperatingCashFlow: this.legalEntitiesReportFinancialIndicatorWithDynamic.getData(initData.OperatingCashFlow),
      CashFlowAvailableForDebtService: this.legalEntitiesReportFinancialIndicatorWithDynamic.getData(initData.CashFlowAvailableForDebtService),
      InvestmentCashFlow: this.legalEntitiesReportFinancialIndicatorWithDynamic.getData(initData.InvestmentCashFlow),
      FinancialCashFlow: this.legalEntitiesReportFinancialIndicatorWithDynamic.getData(initData.FinancialCashFlow),
      DebtServiceCoverageRatio: this.legalEntitiesReportFinancialIndicatorWithDynamic.getData(initData.DebtServiceCoverageRatio),
    };
  }
}
