import { IContainerBinder } from '~/configuration/inversify/containerAccessor.interface';
import { ILegalEntitiesInformationCreditOrganizationsConfigServiceKey } from '~/modules/legalEntities/registrationData/legalEntitiesInformationCreditOrganizations/services/legalEntitiesInformationCreditOrganizationsConfigService.interface';
import { LegalEntitiesHasInformationCreditOrganizationsService } from '~/modules/legalEntities/registrationData/legalEntitiesInformationCreditOrganizations/services/legalEntitiesHasInformationCreditOrganizationsService';
import { ILegalEntitiesHasInformationCreditOrganizationsServiceKey } from '~/modules/legalEntities/registrationData/legalEntitiesInformationCreditOrganizations/services/legalEntitiesHasInformationCreditOrganizationsService.interface';
import { LegalEntitiesInformationCreditOrganizationsConfigService } from '~/modules/legalEntities/registrationData/legalEntitiesInformationCreditOrganizations/services/legalEntitiesHasInformationCreditOrganizationsConfigService';

export function bindLegalEntitiesInformationCreditOrganizationsService(bind: IContainerBinder) {
  bind(ILegalEntitiesInformationCreditOrganizationsConfigServiceKey).to(LegalEntitiesInformationCreditOrganizationsConfigService);

  bind(ILegalEntitiesHasInformationCreditOrganizationsServiceKey).to(LegalEntitiesHasInformationCreditOrganizationsService);
}
